import { Component, Inject, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-sintomas',
  templateUrl: './dialog-sintomas.component.html',
  styleUrls: ['./dialog-sintomas.component.scss']
})
export class DialogSintomasComponent implements OnInit {

  	displayedColumns: string[] = ['sintoma', 'actions'];
	dataSource: MatTableDataSource<any>;

	pieza: any;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogSintomasComponent>,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
	) {
		this.dataSource = new MatTableDataSource();
	}

	ngOnInit(): void {
		this.pieza = this.data.pieza;
		this.dataSource.data = this.data.sintomas;
	}

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 1000
		});
	}

	seleccionarSintoma(sintoma: any): void {
		this.dialogRef.close(sintoma);
	}

}
