<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 fxLayout="row" fxLayoutAlign="flex-start center" class="m-0 mb-8">
        <mat-icon class="mr-8">search</mat-icon>
        Buscar cliente
    </h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography" >
    <div fxLayout="column" >
        <form fxLayout="column" fxFlex="1" #mainForm="ngForm" [formGroup]="formBuscador" class="w-100-p">

            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" class="mr-120">
                    <h4 class="m-0 secondary-text font-weight-400">A continuación puede indicar los parámetros que necesite para buscar al cliente.</h4>
                    <h5 class="m-0 mb-4 secondary-text font-weight-600">Debe indicar al menos uno para poder realizar la búsqueda</h5>
                </div>

                <div fxLayout="row" fxLayoutAlign="flex-end center">
                    <button mat-button mat-stroked-button class="btn-mas-filtro mr-16" [matMenuTriggerFor]="availableFilters">
                        <mat-icon fontSet="fas" fontIcon="fa-plus-circle" class="mr-8"></mat-icon>
                        Filtros
                    </button>
                    <button mat-button mat-stroked-button class="btn-limpiar mr-16" (click)="resetForm()">Limpiar</button>
                    <button mat-button class="btn-aitana" [class.spinner]="loadingSearch" [disabled]="!formBuscador.valid"  (click)="buscarCliente()">Buscar</button>
                    
                    <mat-menu #availableFilters="matMenu" [overlapTrigger]="false" class="pb-0">
                        <button *ngFor="let item of filtrosDisponibles" mat-menu-item (click)="toogleFiltro(item)">
                            <fa-icon *ngIf="isFiltroActivo(item)" class="secondary-text mr-8" [icon]="['fas', 'check-square']"></fa-icon>
                            <fa-icon *ngIf="!isFiltroActivo(item)" class="secondary-text mr-8" [icon]="['far', 'square']"></fa-icon>
                            <span>{{ item.label }}</span>
                        </button>
                    </mat-menu>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center">
                <div fxLayout="row wrap" fxLayoutAlign="flex-start" class="w-90-p mb-12">
                    <ng-container *ngFor="let itemFiltro of filtrosActivos">
                        <mat-form-field *ngIf="itemFiltro.type === 'text'" appearance="standard" class="form-group mr-16">
                            <mat-label>{{ itemFiltro.label }}</mat-label>
                            <input matInput [formControlName]="itemFiltro.name" class="form-control" autocomplete="off">
                        </mat-form-field>
    
                        <mat-form-field *ngIf="itemFiltro.type === 'select' && itemFiltro.name === 'id_provincia'" appearance="standard" class="form-group mr-16">
                            <mat-label>{{ itemFiltro.label }}</mat-label>
                            <mat-select [formControlName]="itemFiltro.name" class="form-control">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="provinciaFilterCtrl"
                                    [placeholderLabel]="itemFiltro.label" noEntriesFoundLabel="No se encontró ningún valor"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let item of provinciasFiltered | async" [value]="item.id">{{item.name}}</mat-option>
                            </mat-select>
                            <button *ngIf="checkValue(itemFiltro.name)" mat-button matSuffix mat-icon-button (click)="clearSelect($event, itemFiltro.name)">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
        
                        <mat-form-field *ngIf="itemFiltro.type === 'select' && itemFiltro.name === 'id_localidad'" appearance="standard" class="form-group mr-16">
                            <mat-label>{{ itemFiltro.label }}</mat-label>
                            <mat-select [formControlName]="itemFiltro.name" class="form-control">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="localidadFilterCtrl" [searching]="searchingLocalidades"
                                    [placeholderLabel]="itemFiltro.label" noEntriesFoundLabel="No se encontro ningún valor"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let item of localidades" [value]="item.id">{{item.name}}</mat-option>
                            </mat-select>
                            <button *ngIf="checkValue(itemFiltro.name)" mat-button matSuffix mat-icon-button (click)="clearSelect($event, itemFiltro.name)">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        
                    </ng-container>
                </div>
            </div>

        </form>

        <div *ngIf="clientesEncontrados?.length === 0" class="primary-50-bg bloq-info">
            <h3 class="secondary-text m-0">No se ha encontrado ningún cliente</h3>
        </div>

        <div *ngIf="clientesEncontrados?.length > 0" class="table-container table-dispositivos">
            <table mat-table [dataSource]="dataSourceClientes" class="w-100-p bloque-form">
                <ng-container matColumnDef="info_cliente">
                    <th mat-header-cell *matHeaderCellDef> Cliente </th>
                    <td mat-cell *matCellDef="let row">
                        <div fxLayout="column" class="py-8">
                            <div fxLayout="row wrap" class="nombre-cliente mb-8">
                                <p class="m-0 mr-8 mb-4 secondary-text font-size-14 font-weight-600">{{row.nombre}}</p>
                                <p class="m-0 mb-4 secondary-text font-size-14 font-weight-600">{{row.apellido}}</p>
                            </div>
                            <p class="m-0 mb-4 secondary-text">NIF/CIF: {{row.cif_nif !== '' ? row.cif_nif : '-'}}</p>
                            <p *ngIf="row.garantia10 && row.garantia10.length > 0" class="m-0 mb-8 secondary-text">Nº contrato: {{ contratosCliente(row) }}</p>
                            <p *ngIf="row.email_contacto !== ''" fxLayot="row" fxLayoutAlign="flex-start center" class="m-0 mr-20 mb-4 secondary-text">
                                <mat-icon fontSet="fas" fontIcon="fa-envelope" class="mr-8"></mat-icon>
                                {{row.email_contacto}}
                            </p>
                            <p *ngIf="row.tlf_contacto !== ''" fxLayout="row" fxLayoutAlign="flex-start center" class="m-0 mb-4 secondary-text">
                                <mat-icon fontSet="fas" fontIcon="fa-phone-alt" class="mr-8"></mat-icon>
                                {{row.tlf_contacto}}
                            </p>
                        </div>
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="direcciones">
                    <th mat-header-cell *matHeaderCellDef> Direcciones </th>
                    <td mat-cell *matCellDef="let row">
                        <p *ngFor="let direccion of row.direcciones" fxLayout="row" fxLayoutAlign="flex-start center" class="m-0 ml-8 secondary-text text-direccion">
                            <span fxLayout="row" fxLayoutAlign="flex-start center" class="mr-8" style="min-width: 110px;">
                                <mat-icon [fontSet]="direccion.direccion_tipo?.iconLib" [fontIcon]="direccion.direccion_tipo?.iconName" class="mr-8"></mat-icon>
                                <span class="font-weight-400">{{ direccion.direccion_tipo?.tipo_direccion }}</span>
                            </span>
                            {{ formatDireccion(direccion) }}
                        </p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <div fxLayout="row wrap" fxLayoutAlign="center center">
                            <button mat-icon-button matTooltip="Seleccionar cliente" (click)="seleccionarCliente(row)">
                                <mat-icon fontSet="fas" fontIcon="fa-user-check"></mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsClientes, sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsClientes;"></tr>
            </table>
        </div>
    </div>
</mat-dialog-content>