<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">Seleccionar síntoma</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column" style="width: 60vw">

        <div fxLayout="row" class="p-12">
            <div fxLayout="column" class="title">
                <h6 class="m-0">Selecciona el síntoma para el que quieres solicitar la pieza {{ pieza ? pieza.description + '(' + pieza.pn + ')' : ''}}</h6>
            </div>
        </div>

        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="w-100-p">

                <ng-container matColumnDef="sintoma">
                    <th mat-header-cell *matHeaderCellDef> Síntoma </th>
                    <td mat-cell *matCellDef="let row">{{row.sintoma}}</td>
                </ng-container>

                <ng-container matColumnDef="actions" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button matTooltip="Seleccionar pieza" (click)="seleccionarSintoma(row)">
                            <mat-icon>playlist_add</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>

    </div>
</mat-dialog-content>