import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {

	@HostBinding('class.fileover') fileOver: boolean;
	@Output() fileDropped = new EventEmitter<any>();

	constructor() { }

	@HostListener('dragover', ['$event']) public onDragOver(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		this.fileOver = true;
		console.log("Drag over");
	}

	@HostListener('dragleave', ['$event']) public onDragLeave(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		
		this.fileOver = false;
		console.log("Drag leave");
	}

	@HostListener('drop', ['$event']) public onDrop(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		
		this.fileOver = false;
		const files = evt.dataTransfer.files;
		if (files.length > 0) {
			console.log('You dropped ${files.lenght} files');
			this.fileDropped.emit(files);
		}
	}

}
