<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">{{ titulo ? titulo : 'Ásistente de decisión' }}</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content id="asistente-content" class="mat-typography" fusePerfectScrollbar>
    <div fxLayout="column">
        <div *ngIf="questions.length == 0" fxLayout="row" fxLayoutAlign="center center">
            <h3 class="m-0 my-16 secondary-text">El asistente no ha encontrado datos suficientes</h3>
            <!-- <p class="m-0 mr-12 mb-4 secondary-text font-weight-600">{{ row.tipoCita ? row.tipoCita.name : ' - ' }}</p> -->
        </div>

        <ng-container *ngIf="questions.length > 0">
            <div *ngFor="let question of questions" fxLayout="column" class="w-100-p bloq-question">
                <p class="m-0 mb-4 secondary-text font-size-18 font-weight-600">{{ question.question }}</p>
                <p *ngIf="question.hint" class="m-0 mb-4 secondary-text">{{ question.hint }}</p>

                <ng-container *ngIf="question.type && question.type === 'filter'">
                    <div fxLayout="row">
                        <mat-form-field appearance="standard" class="form-group select-options w-90-p">
                            <mat-label>{{ question.name }}</mat-label>
                            <mat-select class="form-control" (selectionChange)="opcionSeleccionada($event, question.idQuestion)">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="filtersQuestion[question.idQuestion]"
                                    placeholderLabel="Escribe al menos 3 caracteres" noEntriesFoundLabel="No se encontró ningún valor"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let itemOption of optionsQuestion[question.idQuestion] | async" [value]="itemOption.id">{{itemOption.name}}</mat-option>
                            </mat-select>
                            <mat-hint *ngIf="hintOptionQuestion(question.idQuestion) !== ''">{{ hintOptionQuestion(question.idQuestion) }}</mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>

                <ng-container *ngIf="question.type === undefined || question.type !== 'filter'">
                    <ng-container *ngIf="question.options.length > 4">
                        <div fxLayout="row">
                            <mat-form-field appearance="standard" class="form-group select-options w-90-p">
                                <mat-label>{{ question.name }}</mat-label>
                                <mat-select class="form-control" (selectionChange)="opcionSeleccionada($event, question.idQuestion)">
                                    <mat-option *ngFor="let itemOption of question.options" [value]="itemOption.id">{{itemOption.name}}</mat-option>
                                </mat-select>
                                <mat-hint *ngIf="hintOptionQuestion(question.idQuestion) !== ''">{{ hintOptionQuestion(question.idQuestion) }}</mat-hint>
                            </mat-form-field>
                        </div>
                    </ng-container>
    
                    <ng-container *ngIf="question.options.length <= 4">
                        <div fxLayout="row wrap" fxLayoutAlign="space-around center" class="mt-8 px-8">
                            <button *ngFor="let itemOption of question.options" mat-button mat-stroked-button class="btn-options mx-4" [matTooltip]="itemOption.hint"
                                    [ngClass]="{'selected': isSelected(question.idQuestion, itemOption.id)}"
                                    (click)="selectOption(question.idQuestion, itemOption)" >
                                {{ itemOption.name }}
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="resultado">
            <div fxLayout="column" class="bloq-resultado mt-12">
                <p class="m-0 secondary-text font-size-18 font-weight-400">{{ resultado.text }}</p>

                <div *ngIf="resultado.data && resultado.data.piezas && resultado.data.piezas.length > 0" fxLayout="column" class="bloq-piezas mt-8">
                    <div fxLayout="row" fxLayoutAlign="flex-start center">
                        <mat-icon fontSet="fas" fontIcon="fa-puzzle-piece"></mat-icon>
                        <span class="m-0 secondary-text font-size-16 ml-8">Piezas necesarias</span>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="space-around center">
                        <div *ngFor="let itemPieza of resultado.data.piezas" fxLayout="column" class="item-pieza">
                            <div fxLayout="row" class="modelo">
                                <span class="m-0 font-size-16 font-weight-600 secondary-text">{{ itemPieza.modelo }}</span>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="flex-start center" class="mt-4">
                                <span class="m-0 font-size-14 secondary-text mr-8">PN:</span>
                                <div fxLayout="row wrap" class="piezas">
                                    <span *ngFor="let pn of itemPieza.pn" class="m-0 font-size-14 secondary-text">{{ pn }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="flex-end" class="mt-12">
                <button mat-button class="btn-aitana" (click)="aplicarResultado()">Aplicar resultado</button>
            </div>
        </ng-container>

    </div>
</mat-dialog-content>