import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dialog-selected-filtro',
  templateUrl: './dialog-selected-filtro.component.html',
  styleUrls: ['./dialog-selected-filtro.component.scss']
})
export class DialogSelectedFiltroInformeAiComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	customRanges: any;
	localeRangeDate: any;

	tituloDialog: string;
	datosFiltro: any;
	formFiltro: FormGroup;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogSelectedFiltroInformeAiComponent>,
		private formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private translateService: TranslateService
	) {
		this._unsubscribeAll = new Subject();
		moment.locale(this.translateService.currentLang);

		this.localeRangeDate = {
			applyLabel: 'OK', 
			format: 'DD-MM-YYYY', 
			customRangeLabel: this.translateService.instant('DATE_RANGES.ELEGIR_RANGO'),
			daysOfWeek: moment.weekdaysMin(),
    		monthNames: moment.monthsShort(),
		};
		
		const localeHoy = this.translateService.instant('DATE_RANGES.HOY');
		const localeAyer = this.translateService.instant('DATE_RANGES.AYER');
		const localeUlt7Days = this.translateService.instant('DATE_RANGES.ULTIMOS_7_DIAS');
		const localeUlt30Days = this.translateService.instant('DATE_RANGES.ULTIMOS_30_DIAS');
		const localeEsteMes = this.translateService.instant('DATE_RANGES.ESTE_MES');
		const localeMesAnterior = this.translateService.instant('DATE_RANGES.MES_ANTERIOR');
		this.customRanges = {};
		this.customRanges[localeHoy] = [moment(), moment()];
		this.customRanges[localeAyer] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
		this.customRanges[localeUlt7Days] = [moment().subtract(6, 'days'), moment()];
		this.customRanges[localeUlt30Days] = [moment().subtract(29, 'days'), moment()];
		this.customRanges[localeEsteMes] = [moment().startOf('month'), moment().endOf('month')];
		this.customRanges[localeMesAnterior] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

		this.tituloDialog = '';
	}

	ngOnInit(): void {
		if (this.data.datosFiltro) {
			this.datosFiltro = this.data.datosFiltro;
			if (this.datosFiltro.value) {
				this.tituloDialog = this.translateService.instant('COMUN.EDITAR_FILTRO');
			}
			else {
				this.tituloDialog = this.translateService.instant('COMUN.ANADIR_FILTRO');
			}

			let formControls = {};
			formControls[this.datosFiltro.name] = [''];
			this.formFiltro = this.formBuilder.group(formControls);

            if (this.datosFiltro.value) {
                this.formFiltro.get(this.datosFiltro.name).setValue(this.datosFiltro.value);
            }
		}
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}
	
	checkValue(formControlName: string) {
		let formControl = this.formFiltro.get(formControlName);
		if (formControl) {
			return formControl.value !== "";
		}
		else {
			return false;
		}
	}

	clearValue(formControlName: string, event?) {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		let formControl = this.formFiltro.get(formControlName);
		if (formControl) {
			formControl.setValue('');
		}
	}

	guardarDatos() {
		const formValue = this.formFiltro.getRawValue();
		let result = null;

		switch (this.datosFiltro.type) {
			case 'String':
            case 'Number':
				const value = formValue[this.datosFiltro.name];
				result = value !== '' ? value : null;
				break;
			case 'Date':
            case 'Datetime':
				const dateRange = formValue[this.datosFiltro.name];
				result = (dateRange !== '' && dateRange.startDate != null) ? dateRange : null;
				break;
		}
        
		this.dialogRef.close({ filterValue: result });
	}

}
