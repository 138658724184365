<form [formGroup]="empresaForm" (submit)="submit()">
    <h2 mat-dialog-title>Nueva Empresa</h2>
    <div mat-dialog-content>
        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="60%">
                <mat-form-field class="form-field">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="nombre" required>
                    <mat-error *ngIf="empresaForm.controls.nombre.touched && empresaForm.controls.nombre.invalid">
                        <span *ngIf="empresaForm.controls.nombre.errors.required">Este campo es obligatorio</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxFlex="40%">
                <mat-form-field class="form-field">
                    <mat-label>Teléfono</mat-label>
                    <input formControlName="tlf" type="number" matInput>
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="70%">
                <mat-form-field class="form-field">
                    <mat-label>Web</mat-label>
                    <input matInput formControlName="web">
                </mat-form-field>
            </div>
            
            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Dominio correo</mat-label>
                    <input matInput formControlName="dominio_correo">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="70%">
                <mat-form-field class="form-field">
                    <mat-label>Dirección</mat-label>
                    <input matInput formControlName="direccion">
                </mat-form-field>
            </div>

            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Código Postal</mat-label>
                    <input matInput type="number" formControlName="codigo_postal">
                </mat-form-field>
            </div>

        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="50%">
                <mat-form-field class="form-field">
                    <mat-label>Provincia</mat-label>
                    <input matInput formControlName="provincia">
                </mat-form-field>
            </div>

            <div fxFlex="50%">
                <mat-form-field class="form-field">
                    <mat-label>Pais</mat-label>
                    <input matInput formControlName="pais">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="100%">
                <mat-form-field class="form-field">
                    <mat-label>Descripción</mat-label>
                    <textarea formControlName="descripcion" matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-flat-button [mat-dialog-close]="true" type="button">Cancelar</button>
        <button *ngIf="buttonsEnable" mat-raised-button color="primary" type="submit">Guardar</button>
        <button *ngIf="!buttonsEnable" mat-raised-button color="primary" type="button">Guardar</button>
    </div>            
</form>