export const locale = {
    lang: 'es',
    data: {
        'AUTODIAGNOSIS_BOT': {
            'PROCESO_SELECCION_SINTOMAS': 'PROCESO DE SELECCION DE SÍNTOMAS',
            'INICIAR_DIAGNOSIS': 'INICIAR DIAGNOSIS',
            'INDICA_NUM_TICKET': 'Indica el nº de ticket sobre el que quieres realizar la diagnosis',
            'QUE_TIPO_ELEMENTO': '¿Que tipo de dispositivo quieres diagnosticar?',
            'SIN_SINTOMAS': 'SIN SINTOMAS',
            'DIAGNOSTICOS_ENCONTRADOS': 'No hay otros sintomas posibles, estos son los diagnósticos encontrados.',
            'SELECCIONA_DIAGNOSTICO': 'Selecciona el/los que crees que se ajustan mas a tu problema',
            'NINGUN_SINTOMA_MARCADO': 'Del listado de síntomas posibles no se ha marcando ninguno',
            'REINICIA_AUTODIAGNOSIS': 'Reinicia la autodiagnosis para volver a ver los síntomas posibles',
            'DIAGNOSTICOS_NO_ENCONTRADOS': 'No se ha podido encontrar ningún diagnostico para los sintomas seleccionados',
            'REINICIAR_DIAGNOSIS': 'REINICIAR DIAGNOSIS',
            'TICKET_NO_ENCONTRADO': 'No se ha encontrado el ticket indicado',
            'TIPO_ELEMENTO_NO_INDICADO': 'El tipo de elemento no esta indicado',
            'DESCRIPCION_PROBLEMA': '¿Quieres añadir una breve descripción del problema?',
            'DIAGNOSIS_FINALIZADA': 'La díagnosis a finalizado correctamente',
            'DIAGNOSIS_REGISTRADAS': 'Se han registrado las diagnosis para los sintomas y diagnosticos seleccionados',
            'DIAGNOSTICAR_NUEVO_DISPOSITIVO': '¿Quiere diagnosticar un nuevo dispositivo?',
            'NUEVA_DIAGNOSIS': 'NUEVA DIAGNOSIS',
            'SINTOMAS_REPARACIONES_NO_MARCADAS': 'Error: No se han encontrado sintomas y reparaciones seleccionados',
            'AUTODIAGNOSIS_BOT': 'AutoDiagnosis Bot',
            'TICKET': 'Ticket',
            'CONTINUEMOS': 'Continuemos',
            'DESCRIPCION': 'Descripción',
            'CREAR_DIAGNOSIS': 'Crear diagnosis',
            'PORCENTAJE_REPARACIONES': '{{porcentaje}}% de las reparaciones',
            'REPARACION_COMUN': 'Reparación común a los {{num_sintomas}} síntomas seleccionados',
            'REPARACION_SINTOMAS': 'Reparación para los síntomas:',
        }
    }
};
