import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Validators, FormGroup, FormControl, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';

import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject } from 'rxjs';
import { ColaboradoresService } from 'app/services/colaboradores/colaboradores.service';
import { ClienteService } from 'app/services/cliente/cliente.service';
import { PageEvent } from '@angular/material/paginator';
import { takeUntil } from 'rxjs/operators';
import { ProyectosService } from 'app/services/proyectos/proyectos.service';

@Component({
  selector: 'app-buscador-colaboradores',
  templateUrl: './buscador-colaboradores.component.html',
  styleUrls: ['./buscador-colaboradores.component.scss']
})
export class BuscadorColaboradoresComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	idTicket: number;
	idProyecto: number;
	idCliente: number;

	formBuscadorPartner: FormGroup;
	loadingSearch: boolean;
	colaboradoresEncontrados: Array<any>;

	estadosColaboradorSelected: Array<any>;
	estadosColaborador: Array<any>;
	coberturaProvinciaSelected: Array<any>;
	provincias: Array<any>;
	provinciasFiltered: ReplaySubject<any[]>;
	provinciaFilterCtrl: FormControl;
	coberturaPaisSelected: Array<any>;
	paises: Array<any>;
	paisesFiltered: ReplaySubject<any[]>;
	paisFilterCtrl: FormControl;

	valoresRealizaOnsite: Array<any>;
	filtroValoracion: number;
	filtrosCargados: any;
	onFiltrosCargados: Subject<any>;

	displayedColumnsColaborador: string[] = [
		'nombre_empresa', 'estado_colaborador', 'cobertura', 'cobertura_pais', 
		'realiza_onsite', 'valoracion', 'carga_trabajo', 'distancia', 'actions'
	];
	dataSourceColaboradores: MatTableDataSource<any>;

	numPage: number;
	pageSize: number;

	currentOrder: string;
	currentDirection: string;
	totalElementos: number;

	docComprobada: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<BuscadorColaboradoresComponent>,
		private dialog: MatDialog, 
		private formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private colaboradoresSvc: ColaboradoresService,
		private clienteSvc: ClienteService,
		private proyectosSvc: ProyectosService,
	) {
		moment.locale(this.translateService.currentLang);
		this._unsubscribeAll = new Subject();

		this.loadingSearch = false;
		this.colaboradoresEncontrados = [];

		this.formBuscadorPartner = this.formBuilder.group({
			nombre_empresa: [''],
			estado_colaborador: [''],
			cobertura_provincia: [''],
			cobertura_pais: [''],
			realiza_onsite: [''],
			valoracion: [''],
		});

		this.estadosColaborador = [];
		this.estadosColaboradorSelected = [];
		this.provincias = [];
		this.provinciasFiltered = new ReplaySubject<any[]>(1);
		this.provinciaFilterCtrl = new FormControl();
		this.coberturaProvinciaSelected = [];
		this.paises = [];
		this.paisesFiltered = new ReplaySubject<any[]>(1);
		this.paisFilterCtrl = new FormControl();
		this.coberturaPaisSelected = [];
		this.valoresRealizaOnsite = [
			{ id: 0, name: "NO"},
			{ id: 1, name: "SÍ"},
		];
		this.filtroValoracion = 0;
		this.filtrosCargados = {
			estados: false,
			provincias: false,
			paises: false
		};
		this.onFiltrosCargados = new Subject();

		this.dataSourceColaboradores = new MatTableDataSource();
		this.numPage = 0;
		this.pageSize = 5;
		this.totalElementos = 0;

		const estadoColaboradorCtrl = this.formBuscadorPartner.get('estado_colaborador');
		estadoColaboradorCtrl.valueChanges.subscribe((valueSelect) => {
			this.estadosColaboradorSelected = [];
			if (valueSelect) {
				for (const idEstado of valueSelect) {
					const estadoCol = this.estadosColaborador.find((item) => {
						return item.ID === idEstado;
					});
					this.estadosColaboradorSelected.push(estadoCol);
				}
			}
		});
		
		const coberturaProvinciaCtrl = this.formBuscadorPartner.get('cobertura_provincia');
		coberturaProvinciaCtrl.valueChanges.subscribe((valueSelect) => {
			this.coberturaProvinciaSelected = [];
			if (valueSelect) {
				for (const idProvincia of valueSelect) {
					const provincia = this.provincias.find((item) => {
						return item.id === idProvincia;
					});
					this.coberturaProvinciaSelected.push(provincia);
				}
			}
		});
		
		const coberturaPaisCtrl = this.formBuscadorPartner.get('cobertura_pais');
		coberturaPaisCtrl.valueChanges.subscribe((valueSelect) => {
			this.coberturaPaisSelected = [];
			if (valueSelect) {
				for (const idPais of valueSelect) {
					const pais = this.paises.find((item) => {
						return item.id === idPais;
					});
					this.coberturaPaisSelected.push(pais);
				}
			}
		});

		
	}

	ngOnInit(): void {
		if (this.data.onsite !== null) {
			const valueOnsite = this.data.onsite ? 1 : 0;
			this.setValueDataForm('realiza_onsite', valueOnsite);
		}

		if (this.data.idTicket) {
			this.idTicket = this.data.idTicket;
		}
		if (this.data.idProyecto) {
			this.idProyecto = this.data.idProyecto;
		}
		if (this.data.idCliente) {
			this.idCliente = this.data.idCliente;
		}

		this.onFiltrosCargados.subscribe(
			() => {
				console.log("onfiltros cargados", this.filtrosCargados);
				if (this.filtrosCargados.estados && this.filtrosCargados.provincias && this.filtrosCargados.paises) {
					this.filtrar();
				}
			}
		);

		this.loadEstadosColaborador();
		this.loadProvincias();
		this.loadPaises();


		this.provinciaFilterCtrl.valueChanges.subscribe((filterValue) => {
			if (!filterValue) {
				this.provinciasFiltered.next(this.provincias.slice());
				return;
			}

			this.provinciasFiltered.next(
				this.provincias.filter(item => {
					return item.name.toLowerCase().includes(filterValue.toLowerCase());
				})
			);
		});
		
		this.paisFilterCtrl.valueChanges.subscribe((filterValue) => {
			if (!filterValue) {
				this.paisesFiltered.next(this.paises.slice());
				return;
			}

			this.paisesFiltered.next(
				this.paises.filter(item => {
					return item.name.toLowerCase().includes(filterValue.toLowerCase());
				})
			);
		});
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	setValueDataForm(formControlName, value) {
		let formControl = this.formBuscadorPartner.get(formControlName);
		if (formControl) {
			if (value !== '' && value !== 0 && value !== null) {
				formControl.setValue(value);
			}
		}
	}

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}

	sortChange(event) {
		console.log("sortchange", event);
		if (event.direction !== "") {
			this.currentOrder = event.active;
			this.currentDirection = event.direction;
		}
		else {
			this.currentDirection = "";
			this.currentOrder = "";
		}
		this.filtrar();
	}

	pageChanged(event: PageEvent) {
		console.log("pageChange", event);
		this.numPage = event.pageIndex;
		this.pageSize = event.pageSize;
		this.filtrar();
	}

	loadEstadosColaborador() {
		this.colaboradoresSvc.getEstadosColaborador().subscribe(
			(data:any) => {
				this.estadosColaborador = data;

				if (this.data.estados) {
					this.setValueDataForm('estado_colaborador', this.data.estados);
				}
				this.filtrosCargados.estados = true;
				this.onFiltrosCargados.next();
			}
		)
	}
	loadPaises() {
		this.clienteSvc.getPaises().subscribe(
			(data: any) => {
				this.paises = data.map(item => {
					return { id: item.id, name: item.pais };
				});
				this.paisesFiltered.next(this.paises.slice());

				if (this.data.paises) {
					this.setValueDataForm('cobertura_pais', this.data.paises);
				}
				this.filtrosCargados.paises = true;
				this.onFiltrosCargados.next();
			},
			(err) => {
				this.showSnack(err);
			}
		);
	}
	loadProvincias() {
		this.clienteSvc.getProvincias().subscribe(
			(data: any) => {
				this.provincias = data.map(item => {
					return { id: item.idprovincia, name: item.provincia };
				});
				this.provinciasFiltered.next(this.provincias.slice());

				if (this.data.provincias) {
					this.setValueDataForm('cobertura_provincia', this.data.provincias);
				}
				this.filtrosCargados.provincias = true;
				this.onFiltrosCargados.next();
			},
			(err) => {
				this.showSnack(err);
			}
		);
	}

	deselectEstadoColaborador(dataEstado: any): void {
		const index = this.estadosColaboradorSelected.findIndex((item) => {
			return item.ID === dataEstado.ID;
		});

		if (index >= 0) {
			this.estadosColaboradorSelected.splice(index, 1);
		}
		let idEstados = this.estadosColaboradorSelected.map((item) => {
			return item.ID;
		});
		const estadosColaboradorCtrl = this.formBuscadorPartner.get('estado_colaborador');
		estadosColaboradorCtrl.setValue(idEstados);
	}
	clearEstadosColaborador(event?) {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.estadosColaboradorSelected = null;
		const estadosColaboradorCtrl = this.formBuscadorPartner.get('estado_colaborador');
		estadosColaboradorCtrl.setValue('');
	}
	
	deselectCoberturaProvincia(dataProv: any): void {
		const index = this.coberturaProvinciaSelected.findIndex((item) => {
			return item.id === dataProv.id;
		});

		if (index >= 0) {
			this.coberturaProvinciaSelected.splice(index, 1);
		}
		let idProvincias = this.coberturaProvinciaSelected.map((item) => {
			return item.id;
		});
		const coberturaProvinciaCtrl = this.formBuscadorPartner.get('cobertura_provincia');
		coberturaProvinciaCtrl.setValue(idProvincias);
	}
	clearCoberturaProvincia(event?) {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.coberturaProvinciaSelected = null;
		const coberturaProvinciaCtrl = this.formBuscadorPartner.get('cobertura_provincia');
		coberturaProvinciaCtrl.setValue('');
	}
	
	deselectCoberturaPais(dataPais: any): void {
		const index = this.coberturaPaisSelected.findIndex((item) => {
			return item.id === dataPais.id;
		});

		if (index >= 0) {
			this.coberturaPaisSelected.splice(index, 1);
		}
		let idPaises = this.coberturaPaisSelected.map((item) => {
			return item.id;
		});
		const coberturaPaisCtrl = this.formBuscadorPartner.get('cobertura_pais');
		coberturaPaisCtrl.setValue(idPaises);
	}
	clearCoberturaPais(event?) {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.coberturaPaisSelected = null;
		const coberturaPaisCtrl = this.formBuscadorPartner.get('cobertura_pais');
		coberturaPaisCtrl.setValue('');
	}

	changeFiltroValoracion(value) {
		console.log("changeFiltrovaloracion", value);
		this.filtroValoracion = value;
		this.setValueDataForm('valoracion', value);

		console.log("filtroValoracion", this.filtroValoracion, this.formBuscadorPartner.get('valoracion').value);
	}
	clearValoracion() {
		this.filtroValoracion = 0;
		this.formBuscadorPartner.get('valoracion').setValue('');

		console.log("clear filtroValoracion", this.filtroValoracion, this.formBuscadorPartner.get('valoracion').value);
	}

	checkValue(formControlName: string) {
		let formControl = this.formBuscadorPartner.get(formControlName);
		if (formControl) {
			return formControl.value !== "";
		}
		else {
			return false;
		}
	}

	clearSelect(event, formControlName: string) {
		event.preventDefault();
		event.stopPropagation();

		let formControl = this.formBuscadorPartner.get(formControlName);
		if (formControl) {
			formControl.setValue('');
		}
	}

	isFiltrosActivos(): boolean {
		let filtrosActivos = false;
		const formValue = this.formBuscadorPartner.value;

		if ((this.estadosColaboradorSelected && this.estadosColaboradorSelected.length > 0) || 
				(this.coberturaProvinciaSelected && this.coberturaProvinciaSelected.length > 0) ||
				(this.coberturaPaisSelected && this.coberturaPaisSelected.length > 0) || 
				formValue.nombre_empresa !== "" || formValue.realiza_onsite !== "" || formValue.valoracion !== "") {
			filtrosActivos = true;
		}

		return filtrosActivos;
	}

	resetFiltros() {
		this.formBuscadorPartner.reset();
		this.clearEstadosColaborador();
		this.clearCoberturaProvincia();
		this.clearCoberturaPais();
		this.clearValoracion();
	}

	formatMensaje(mensaje: string) {

		if(mensaje != ''){
			let msg = mensaje.replace(new RegExp('<b>', 'g'), '');
			msg = msg.replace(new RegExp('</b>', 'g'), '');
			msg = msg.replace(new RegExp('&#10;', 'g'), '');
			msg = msg.replace(new RegExp('<br />', 'g'), '\n');
			msg = msg.replace(new RegExp('<br/>', 'g'), '\n');
			return msg;
		}else{
			return mensaje;
		}
		
	}

	showIconMano(colaborador: any) {
		if (colaborador.documentacion) {
			let validacion = colaborador.documentacion.validacion;
			if (validacion.includes('caducados') || validacion.includes('nosubido') || validacion.includes('nocliente') || validacion.includes('sinpermiso')) {
				return true;
			}
		}else{
			return true;
		}
		return false;
	}
	showIconAviso(colaborador: any) {
		if (colaborador.documentacion) {
			let validacion = colaborador.documentacion.validacion;
			if ((validacion.includes('caducadoMenos5') || validacion.includes('nocliente5dias')) 
					&& !(validacion.includes('caducados') || validacion.includes('nosubido') || validacion.includes('nocliente') || validacion.includes('sinpermiso'))) {
				return true;
			}
		}
		return false;
	}
	showBotonActualizarDocumentacion(colaborador: any) {
		if (colaborador.documentacion && colaborador.documentacion.validacion.includes('sinpermiso')) {
			return true;
		}
		return false;
	}

	colaboradorSeleccionable(colaborador: any) {
		if (colaborador.documentacion) {
			let validacion = colaborador.documentacion.validacion;

			if (colaborador.doc_partner === 0 ||( colaborador.doc_partner == 1 && (colaborador.documentacion.validacion.includes('caducados') || colaborador.documentacion.validacion.includes('nosubido') || colaborador.documentacion.validacion.includes('nocliente') || colaborador.documentacion.validacion.includes('sinpermiso')))) {
				return false;
			}
			// if (colaborador.doc_partner === 0 || (colaborador.doc_partner == 1 && 
			// 		(!validacion.includes['caducados'] && !validacion.includes('nocliente') && !validacion.includes('nosubido') && !validacion.includes('sinpermiso')))) {
			// 	return true;
			// }
		}
		return true;
	}

	filtrar() {
		let formValue = this.formBuscadorPartner.value;
		formValue['idTicket'] = this.idTicket;
		formValue['idProyecto'] = this.idProyecto;
		console.log("datos buscador", formValue);

		this.loadingSearch = true;
		this.colaboradoresSvc.getColaboradores(this.numPage, this.pageSize, this.currentOrder, this.currentDirection, formValue).subscribe(
			(data:any) => {
				this.loadingSearch = false;
				this.totalElementos = data.totalElements;
				this.colaboradoresEncontrados = data.elements;
				this.dataSourceColaboradores.data = this.colaboradoresEncontrados.slice();

				this.validarDocumentacion();
				this.calcularCargaTrabajo();
				this.calcularDistancia();
			},
			(err) => {
				this.loadingSearch = false;
				this.showSnack(err);
			}
		);
	}

	validarDocumentacion() {
		this.proyectosSvc.getContrato(this.idProyecto).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(contrato: any) => {
				if (contrato) {
					for (const itemColab of this.colaboradoresEncontrados) {
						console.log('Colaborador ',itemColab);
						this.colaboradoresSvc.validarDocumentacionProyecto(itemColab.idUsuario, this.idProyecto, contrato.id, this.idCliente).pipe(takeUntil(this._unsubscribeAll)).subscribe(
							(data: any) => {
								const keysValidacion = Object.keys(data);
								const mensajes = keysValidacion.map((itemVal) => { return data[itemVal]; })
								const documentacion = {
									mensaje: mensajes.join(" "),
									validacion: keysValidacion
								}
								itemColab['documentacion'] = documentacion;
								itemColab['doc_partner'] = contrato.doc_partner;
								console.log('Documentacion',documentacion);
								this.dataSourceColaboradores.data = this.colaboradoresEncontrados;
								this.docComprobada = true;
							},
							(err) => {
								console.log("Error validarDocumentacion", itemColab.ID, err);
							}
						)
					}
				}else{
					console.log('Sin contrato');
				}
			},
			(err) => {
				console.log("Error getContrato", err);
			}
		)
	}

	calcularCargaTrabajo() {
		for (const itemColab of this.colaboradoresEncontrados) {
			this.colaboradoresSvc.getCargaTrabajo(itemColab.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
				(data: any) => {
					itemColab['carga_trabajo'] = data.cargaTrabajo;
					this.dataSourceColaboradores.data = this.colaboradoresEncontrados;
				},
				(err) => {
					console.log("Error validarDocumentacion", itemColab.ID, err);
				}
			)
		}
	}

	calcularDistancia() {

	}

	actualizaDocumentacion(colaborador) {

		console.log('Colaborador actualizar doc', colaborador, this.idProyecto);

		if(colaborador){
			this.colaboradoresSvc.actualizarDocProyecto(colaborador.idUsuario, this.idProyecto).subscribe(
				(data:any) => {
					this.showSnack('Se ha enviado la orden de validar la documentación correctamente. Espera a recibir la notificación una vez haya terminado la validación para volver a asignar el ticket.');
				},
				(err) => {
					this.showSnack('Se ha producido un error al enviar la solicitud');
				}
			);
		}
				
	}

	seleccionarColaborador(colaborador) {
		this.dialogRef.close(colaborador);
	}

}
