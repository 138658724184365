import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { TicketsService } from 'app/services/ticket/tickets.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import * as moment from 'moment';
import { Block } from 'notiflix';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DialogConfirmComponent } from 'app/componentes/util/dialog-confirm/dialog-confirm.component';

@Component({
	selector: 'app-dialog-adjuntos-ticket',
	templateUrl: './dialog-adjuntos-ticket.component.html',
	styleUrls: ['./dialog-adjuntos-ticket.component.scss']
})
export class DialogAdjuntosTicketComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	@ViewChild(MatPaginator) paginator: MatPaginator;

	ticket: any;
	adjuntos: Array<any>;

	files: any[];
	
	numPage: number;
	pageSize: number;
	pageSizeOptions: Array<any> = [5,10,20,30];
	totalElementos: number;
	
	displayedColumns: string[] = ['id', 'archivo', 'datosSubida', 'actions'];
	dataSource: MatTableDataSource<any>;

	tipoAdjunto: any;
	tiposAdjuntos: Array<any>;
	tipoAdjuntoSel: any;

	mostrarAdjuntarFichero: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogAdjuntosTicketComponent>,
		private dialog: MatDialog, 
		private sanitizer: DomSanitizer,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private ticketSvc: TicketsService,
		
	) {
		this._unsubscribeAll = new Subject();

		this.numPage = 0;
		this.pageSize = 5;
		this.totalElementos = 0;

		this.dataSource = new MatTableDataSource();
		this.adjuntos = [];
		this.files = [];	
		this.tiposAdjuntos = [];

	}

	ngOnInit(): void {
		if (this.data.ticket) {
			this.ticket = this.data.ticket;
			this.loadTiposAdjuntos();
			this.filtrar();
		}
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}

	initLoading(selector, textoLoading?: string) {
		// this.loading = true;
		Block.standard(selector, textoLoading ? textoLoading : '', {
			svgColor: '#666',
			messageColor: '#666',
			messageFontSize: '18px',
			backgroundColor: 'rgba(255,255,255,0.8)'
		});
	}

	stopLoading(selector) {
		// this.loading = false;
		Block.remove(selector);
	}

	pageChanged(event: PageEvent) {
		// console.log("pageChange", event);
		this.numPage = event.pageIndex;
		this.pageSize = event.pageSize;
		this.loadAdjuntos();
	}

	formatDate(date: string): string {
		let dateFormat = "";
		if (date) {
			let momentDate = moment(date);
			dateFormat = momentDate.format('DD/MM/YYYY HH:mm');
		}

		return dateFormat;
	}

	formatBytes(bytes, decimals) {
		if (bytes === 0) {
		  	return '0 Bytes';
		}
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals || 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	esImagen(adjunto: any) {
		let esImagen = false;

		const extension = adjunto.filename.split('.').pop().toUpperCase();
		switch(extension){
			case 'JPG':
			case 'JPEG':
				esImagen = true;
				break;
			default:
				esImagen = false;
				break;
		}
	
		
		return esImagen;
	}
	
	getIconFile(adjunto: any) {
		let iconData = {
			lib: 'fas',
			icon: 'fa-file-alt'
		}
		const extension = adjunto.filename.split('.').pop().toUpperCase();
		switch(extension){
			case 'XLS':
			case 'XLSX':
				iconData.icon = 'fa-file-excel';
				break;
			case 'JPG':
			case 'JPEG':
				iconData.icon = 'fa-file-image';
				break;
			case 'PDF':
				iconData.icon = 'fa-file-pdf';
				break;
			default:
				break;
		}

		return iconData;
	}

	getImageAdjunto(adjunto: any) {
		this.initLoading('.imagen-' + adjunto.ID);
		this.ticketSvc.descargarAdjunto(this.ticket, adjunto.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				this.stopLoading('.imagen-' + adjunto.ID);
				const linkSource = `data:${data.mime};base64,${data.base64}`;
				adjunto['base64'] = this.sanitizer.bypassSecurityTrustResourceUrl(linkSource);
			},
			(err) => {
				this.stopLoading('.imagen-' + adjunto.ID);				
			}
		);
	}

	loadTiposAdjuntos(): void {
		
		if (this.ticket) {
			this.ticketSvc.getTiposAdjuntosTicket(this.ticket).pipe(takeUntil(this._unsubscribeAll)).subscribe(
				(data: any) => {
					console.log('Tipos de Adjuntos', data);
					this.tiposAdjuntos = data;

				},
				(err) => {
					this.showSnack(err);
				}
			);
		}
		else {
			this.showSnack("Ticket no encontrado");
		}
	}
	filtrar() {
		this.numPage = 0;
		if (this.paginator) {
			this.paginator.firstPage();
		}
		this.loadAdjuntos();
	}

	loadAdjuntos() {		
		if (this.ticket) {
			this.ticketSvc.getAdjuntosTicket(this.ticket, this.numPage, this.pageSize).pipe(takeUntil(this._unsubscribeAll)).subscribe(
				(data: any) => {
					this.adjuntos = data.elements;
					// console.log("adjuntos", this.adjuntos);
					this.totalElementos = data.totalElements;
					this.dataSource.data = this.adjuntos;

					setTimeout(() => {
						for (const itemAdjunto of this.adjuntos) {
							if (this.esImagen(itemAdjunto)) {
								this.getImageAdjunto(itemAdjunto);
							}
						}						
					}, 500);

				},
				(err) => {
					this.showSnack(err);
				}
			);
		}
		else {
			this.showSnack("Ticket no encontrado");
		}
	}

	descargarAdjunto(adjunto: any) {
		this.initLoading('.btn-download-' + adjunto.ID);
		this.ticketSvc.descargarAdjunto(this.ticket, adjunto.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				this.stopLoading('.btn-download-' + adjunto.ID);
				const linkSource = `data:${data.mime};base64,${data.base64}`;
				// console.log("MIME: ", data.mime);
				const downloadLink = document.createElement("a");
				const fileName = adjunto.filename;

				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				downloadLink.click();
			},
			(err) => {
				this.stopLoading('.btn-download-' + adjunto.ID);
				this.showSnack(err);
			}
		);
	}

	cerrar() {
		if (this.files.length > 0) {
			this.dialogRef.close({ files: this.files });
		}
		else {
			this.dialogRef.close();
		}
	}

	onFileDropped($event) {
		console.log("openfile");
		this.prepareFilesList($event);
	}

	fileBrowseHandler(files) {
		this.prepareFilesList(files);
	}

	private prepareFilesList(files: Array<any>) {
		console.log("files", files);
		for (const item of files) {
		  item.progress = 0;
		  item.docId = 0;
		  item.error = false;
		 // item.tipoAdjunto = this.tipoAdjunto;
		  this.files.push(item);
		}
		
		this.uploadFiles();
		
	
	}


	deleteFile(index: number) {
		const fileDel = this.files[index];
		console.log ("deleteFile", index, this.files, fileDel);
		if (fileDel.docId !== 0) {
			console.log("Borrar adjunto ws");
			this.ticketSvc.deleteAdjunto(this.ticket, fileDel.docId).pipe(takeUntil(this._unsubscribeAll)).subscribe(
				(data: any) => {
					this.files.splice(index, 1);
				},
				(err) => {
					this.showSnack(err);
				}
			);
		}
		else {
			this.files.splice(index, 1);
		}
	}

	openConfirmDeleteFile(index: number): void {
		const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: { 
				titulo: "Eliminar adjunto", 
				mensaje: "Se va a eliminar el adjunto, ¿está seguro?",
				btnLabelOk: "Sí",
				btnLabelCancel: "No"
			},
			panelClass: 'panel-aitana',
			disableClose: true,
			autoFocus: false
		});

		dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
			if (result === true) {
				this.deleteFile(index);
			}
			else {
				console.log("Eliminacion cancelada");
			}
		});
	}

	

	seleccionaTipoAdjunto() {
		console.log("seleccionar tipo adjunto", this.tipoAdjuntoSel);
		if (this.tipoAdjuntoSel) {
			this.mostrarAdjuntarFichero = true;
			this.tipoAdjunto = this.tipoAdjuntoSel;
		}
	}
	uploadFiles() {
		
		if(this.tipoAdjunto && this.tipoAdjunto > 0){
			for (let file of this.files) {
				// console.log("file upload", file);
				// console.log("file progress", file.progress);
				if (file.progress !== undefined && file.progress < 100) {
					// console.log("inicio upload");
					this.convertFileToBase64(file).then(base64Content => {
						this.ticketSvc.uploadAdjunto(this.ticket, this.tipoAdjunto, file.name, base64Content).pipe(takeUntil(this._unsubscribeAll)).subscribe(
							(data) => {
								console.log("Data file upload", data);
								file.docId = data.ID;
								this.loadAdjuntos();
							},
							(err) => {
								file.error = true;
								file.progress = 0;
								this.showSnack(err);
							}
						);
					});
					
				}
				
			}

			

		}else{
			this.showSnack("Debe seleccionar un tipo de Adjunto");
		}
		
	}

	convertFileToBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader;
			reader.onerror = reject;
			reader.onload = () => {
				resolve(reader.result);
			};
			reader.readAsDataURL(file);
		});
	};

}
