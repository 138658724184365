<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">Formulario: {{nombreFormulario}}</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column" style="width: 100%" *ngIf="campos">
        <form  #mainForm="ngForm" [formGroup]="formCampos" class="filtros" style="display:flex; flex-direction: row; flex-wrap: wrap; gap: 2em; width: 100%;">
            <ng-container *ngFor="let campo of campos">
                <mat-form-field *ngIf="campo.type_field == 'text'" appearance="standard" style="width: 45%; max-width: 100%;">
                    <mat-label>{{campo.label}}</mat-label>
                    <input matInput [name]="campo.name" [formControlName]="campo.name" class="form-control" autocomplete="off">
                </mat-form-field>


                <mat-form-field *ngIf="campo.type_field == 'number'" appearance="standard" style="width: 45%; max-width: 100%;">
                    <mat-label>{{campo.label}}</mat-label>
                    <input matInput type="number" [name]="campo.name" [formControlName]="campo.name" class="form-control" autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="campo.type_field == 'textarea'" appearance="standard" style="width: 45%; max-width: 100%;">
                    <mat-label>{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-label>
                    <textarea [formControlName]="campo.name" matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="4"></textarea>
                </mat-form-field>

                <mat-form-field *ngIf="campo.type_field == 'select'" appearance="standard" style="width: 45%; max-width: 100%;">
                    <mat-label>{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-label>
                    <mat-select [formControlName]="campo.name" class="form-control" matTooltip={{campo.label}} matTooltipPosition='above'>
                        <mat-option *ngFor="let option of campo.options" [value]="option.value">{{option.text}}</mat-option>
                    </mat-select>
                    <button  class="btn-close" mat-button matSuffix mat-icon-button (click)="clearValue(campo.name, $event)">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <div class="form-group w-100-p" *ngIf="campo.type_field == 'checklist'">
                    <mat-label>{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-label>
                    <ul>
                        <li *ngFor="let option of campo.options">
                          <mat-checkbox (change)="onCheckChange(campo.name, option.value, $event)" [value]="option.value" [checked]="campo.response && campo.response.value.includes(option.value)">
                            {{option.text}}
                          </mat-checkbox>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </form>
    </div>

    <div fxLayout="column" style="width: 100%" *ngIf="sinCampos">
        <h3>No se encontraron campos configurados en el proyecto</h3>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="btn-aitana" (click)="guardarDatos()" [disabled]="formCampos && !formCampos.valid && !guardando">
        {{ 'COMUN.APLICAR' | translate }}
    </button>
<button mat-button mat-stroked-button mat-dialog-close>{{ 'COMUN.CANCELAR' | translate }}</button>
</mat-dialog-actions>
