import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class ColaboradoresService {

  	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
	) { }

	getPartnersExperimentados(ticket: number, idMarca: number, idModelo: number, tipoDispositivo: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				ticketId: ticket,
				marcaId: idMarca,
				modeloId: idModelo,
				tipo: tipoDispositivo
			}

			const data = [
				//{ id: 1, image_user: "no_user.png", usename: "c-se-11", nombre_empresa: "c-se-11", nombreComercial: "Infortec" , num_reparaciones: 4},
				//{ id: 1, image_user: "no_user.png", username: "pp-se-8", nombre_empresa: "pp-se-8", nombreComercial: "Microquinto Informática" , num_reparaciones: 2},
				//{ id: 1, image_user: "no_user.png", username: "c-se-31", nombre_empresa: "c-se-31", nombreComercial: "RITEL IT SERVICES S.L." , num_reparaciones: 1},
			];
			observer.next(data);

			// const url = environment.API_URL + '/tecnicos/top-experimentados?' + this.helperSvc.serializeData(params);
			// this.http.get(url, options).subscribe(
			// 	(response: any) => {
			// 		console.log("ws getTecnicosExperimentados", response);
			// 		observer.next(response);
			// 	},
			// 	(err) => {
			// 		console.log("error ws getTecnicosExperimentados", err);
			// 		let msgError = "Se ha producido un error cargando los datos";

			// 		observer.error(msgError);
			// 	}
			// );

			return {unsubscribe() {}};
		});
	}
	
	getEstadosColaborador(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/colaboradores/estados';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getEstadosColaborador", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getEstadosColaborador", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getColaborador(idUsuario): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				idUsuario: idUsuario
			};

			const url = environment.API_URL + '/colaboradores/by-idusuario?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getColaborador", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getColaborador", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}


	actualizarDocProyecto(usuario: number, proyecto: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				usuario: usuario,
				proyecto: proyecto
			}


			const url = environment.API_URL + '/colaboradores/actualiza-proyectos?' + this.helperSvc.serializeData(params);
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws actualizarDocColaboradores", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws actualizarDocColaboradores", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	listaColaboradores(idProyecto: number, filtro: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				filtro: filtro,
				proyecto: idProyecto,
			}

			const url = environment.API_URL + '/colaboradores/filtrar?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws listaColaboradores", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws listaColaboradores", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getColaboradores(numPage: number, pageSize: number, columnOrder: string, columnDirection: string, dataFilter: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				page: numPage,
				pageSize: pageSize,
				id_ticket: dataFilter.idTicket,
				id_proyecto: dataFilter.idProyecto,
			}

			if (columnDirection !== "") {
				params['sortColum'] = columnOrder;
				params['sortDirection'] = columnDirection;
			}

			if (dataFilter.nombre_empresa && dataFilter.nombre_empresa !== "") {
				params['nombre_empresa'] = dataFilter.nombre_empresa;
			}
			if (dataFilter.estado_colaborador && dataFilter.estado_colaborador !== "") {
				params['estado_colaborador'] = dataFilter.estado_colaborador.join(',');
			}
			if (dataFilter.cobertura_provincia && dataFilter.cobertura_provincia !== "") {
				params['provincias'] = dataFilter.cobertura_provincia.join(',');
			}
			if (dataFilter.cobertura_pais && dataFilter.cobertura_pais !== "") {
				params['paises'] = dataFilter.cobertura_pais.join(',');
			}
			if (dataFilter.realiza_onsite !== "") {
				params['realiza_onsite'] = dataFilter.realiza_onsite;
			}
			if (dataFilter.valoracion !== "") {
				params['valoracion'] = dataFilter.valoracion;
			}

			const url = environment.API_URL + '/colaboradores/buscador?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws buscadorColaboradores", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws buscadorColaboradores", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	loadColaboradores(numPage: number, pageSize: number, columnOrder: string, columnDirection: string, dataFilter: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				page: numPage,
				pageSize: pageSize,
			}

			if (columnDirection !== "") {
				params['sortColum'] = columnOrder;
				params['sortDirection'] = columnDirection;
			}

			if(dataFilter.estado_colaborador) {
				params['estado_colaborador'] = dataFilter.estado_colaborador;
			}

			if(dataFilter.valoracion) {
				params['valoracion'] = dataFilter.valoracion;
			}

			if(dataFilter.localidad) {
				params['localidad'] = dataFilter.localidad;
			}

			if(dataFilter.codigo_postal) {
				params['codigo_postal'] = dataFilter.codigo_postal;
			}

			if(dataFilter.direccionColaborador) {
				params['direccionColaborador'] = dataFilter.direccionColaborador;
			}

			if(dataFilter.provincia) {
				params['provincia'] = dataFilter.provincia;
			}

			if(dataFilter.provincias) {
				params['provinciasCobertura'] = dataFilter.provincias;
			}

			if(dataFilter.pais) {
				params['pais'] = dataFilter.pais;
			}

			if(dataFilter.idUsuario) {
				params['idUsuario'] = dataFilter.idUsuario;
			}

			if(dataFilter.razon_social) {
				params['razon_social'] = dataFilter.razon_social;
			}

			if(dataFilter.nombreComercial) {
				params['nombreComercial'] = dataFilter.nombreComercial;
			}

			if(dataFilter.cif_nif) {
				params['cif_nif'] = dataFilter.cif_nif;
			}

			if(dataFilter.tipo_sociedad) {
				params['tipo_sociedad'] = dataFilter.tipo_sociedad;
			}

			if(dataFilter.realiza_onsite || dataFilter.realiza_onsite !== undefined) {
				params['realiza_onsite'] = dataFilter.realiza_onsite;
			}

			if(dataFilter.nombre_apellidos) {
				params['nombre_apellidos'] = dataFilter.nombre_apellidos;
			}

			if(dataFilter.telefono) {
				params['telefono'] = dataFilter.telefono;
			}

			if(dataFilter.movil) {
				params['movil'] = dataFilter.movil;
			}

			if(dataFilter.mail) {
				params['mail'] = dataFilter.mail;
			}

			if(dataFilter.fax) {
				params['fax'] = dataFilter.fax;
			}

			if(dataFilter.nombre_empresa) {
				params['nombre_empresa'] = dataFilter.nombre_empresa;
			}

			if(dataFilter.aptitudes_productos) {
				params['aptitudes_productos'] = dataFilter.aptitudes_productos;
			}

			if(dataFilter.aptitudes_gamas) {
				params['aptitudes_gamas'] = dataFilter.aptitudes_gamas;
			}

			if(dataFilter.aptitudes_marcas) {
				params['aptitudes_marcas'] = dataFilter.aptitudes_marcas;
			}

			const url = environment.API_URL + '/colaboradores/?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws loadColaboradores", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws loadColaboradores", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	validarDocumentacionProyecto(idColaborador, idProyecto, idContrato, idCliente): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				idColaborador: idColaborador,
				idProyecto: idProyecto,
				idContrato: idContrato,
				idCliente: idCliente
			}

			const url = environment.API_URL + '/colaboradores/validar-documentacion-proyecto?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws validarDocumentacionProyecto", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws validarDocumentacionProyecto", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getCargaTrabajo(idColaborador): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/colaboradores/carga-trabajo/' + idColaborador;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getCargaTrabajo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getCargaTrabajo", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getColaboradoresForTicket(): Observable<any>{
		const accessToken = this.authSvc.getAccessToken();

		const options = {
			headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
			})
		};

		const url = environment.API_URL + '/colaboradores/for-ticket';
		return this.http.get(url, options);
	}

	crearColaborador(params): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/colaboradores/';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearColaborador", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearColaborador", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.message ) {
						msgError = err.error.message;
					}

					if(err.error.error_description) {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	updateColaborador(params, idColaborador): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/colaboradores/' + idColaborador;
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws updateColaborador", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws updateColaborador", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.message ) {
						msgError = err.error.message;
					}

					if(err.error.error_description) {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getAptitudesProductos(filtro?: string, validos?: boolean): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			let params = {};
			if(filtro) {
				params['filtro'] = filtro;
			}
			if(validos) {
				params['validos'] = validos;
			}

			const url = environment.API_URL + '/colaboradores/productos?' + this.helperSvc.serializeData(params);


			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getAptitudesProductos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAptitudesProductos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getAptitudesGamas(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/colaboradores/gamas';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getAptitudesGamas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAptitudesGamas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}


	getColaboradorCobertura(idColaborador): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			let url = environment.API_URL + '/colaboradores/cobertura/' + idColaborador;

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getColaboradorCobertura", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getColaboradorCobertura", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	updateColaboradorCobertura(idColaborador, params): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			let url = environment.API_URL + '/colaboradores/cobertura/' + idColaborador;

			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws updateColaboradorCobertura", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws updateColaboradorCobertura", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	crearDispositivo(nombre): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				nombre: nombre,
			};

			const url = environment.API_URL + '/colaboradores/productos';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearDispositivo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearDispositivo", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	guardarConocimientos(idColaborador, params): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/colaboradores/conocimiento/' + idColaborador;
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws guardarConocimientos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws guardarConocimientos", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getDatosCmDocumentacionColaborador(numPage: number, pageSize: number, filtros: any, loadFiltros?: boolean): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				page: numPage,
				pageSize: pageSize,
				load_filtros: loadFiltros !== undefined ? loadFiltros : true,				
				estado_documento: filtros.estadoDoc
			}

			
			// if (filtros.proyecto && filtros.proyecto !== "") {
			// 	params['proyectos'] = filtros.proyecto.join(',');
			// }
			if (filtros.colaborador && filtros.colaborador !== "") {
				params['colaboradores'] = filtros.colaborador.join(',');
			}
			// if (filtros.trabajador && filtros.trabajador !== "") {
			// 	params['trabajadores'] = filtros.trabjador.join(',');
			// }

			const url = environment.API_URL + '/colaboradores/cm-doc-colaborador?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDatosCmDocumentosColaborador", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDatosCmDocumentosColaborador", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getDatosCmDocumentacionColaboradorTrabajador(numPage: number, pageSize: number, filtros: any, loadFiltros?: boolean): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				page: numPage,
				pageSize: pageSize,
				load_filtros: loadFiltros !== undefined ? loadFiltros : true,				
				estado_documento: filtros.estadoDoc
			}

			
			// if (filtros.proyecto && filtros.proyecto !== "") {
			// 	params['proyectos'] = filtros.proyecto.join(',');
			// }
			if (filtros.colaborador && filtros.colaborador !== "") {
				params['colaboradores'] = filtros.colaborador.join(',');
			}
			if (filtros.trabajador && filtros.trabajador !== "") {
				params['trabajadores'] = filtros.trabajador.join(',');
			}

			const url = environment.API_URL + '/colaboradores/cm-doc-trabajador?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDatosCmDocumentosColaboradorTrabajador", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDatosCmDocumentosColaboradorTrabajador", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
}
