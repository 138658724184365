<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">Buscar piezas</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column" style="width: 60vw">

        <div fxLayout="row" class="p-12">
            <div fxLayout="column" class="title">
                <h3 class="m-0">Piezas disponibles</h3>
                <h6 class="m-0">para el dispositivo seleccionado</h6>
            </div>
        </div>

        <div fxLayout="row" class="p-12" fxLayoutAlign="space-between">
            <div class="filtros" fxLayout="row wrap" fxLayoutAlign="flex-start center">
                <mat-form-field appearance="standard" class="form-group w-148 mr-16">
                    <mat-label>Descripción Pieza</mat-label>
                    <input matInput name="description" [(ngModel)]="filtros.description" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="form-group w-148 mr-16">
                    <mat-label>P/N</mat-label>
                    <input matInput name="pn" [(ngModel)]="filtros.pn" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <button mat-button class="btn-aitana ml-16" (click)="filtrar()">Buscar</button>
                <button *ngIf="isFiltrosActivos()" mat-button mat-stroked-button class="ml-16" (click)="resetFiltros()">Borrar filtros</button>
            </div>
        </div>

        <div class="table-container">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)" class="w-100-p">

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Pieza </th>
                    <td mat-cell *matCellDef="let row">{{row.description}}</td>
                </ng-container>

                <ng-container matColumnDef="pn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> PN </th>
                    <td mat-cell *matCellDef="let row"> {{row.pn}} </td>
                </ng-container>

                <ng-container matColumnDef="actions" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button matTooltip="Seleccionar pieza" (click)="seleccionarPieza(row)">
                            <mat-icon>playlist_add</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <mat-paginator [pageSizeOptions]="[5,10,20,30]" [length]="totalElementos" [pageIndex]="0" [pageSize]="pageSize" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
        </div>

    </div>
</mat-dialog-content>