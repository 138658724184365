import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

	//onNumSerieSearch: Subject<any>;

    constructor( 
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
    ) { 
	//	this.onNumSerieSearch = new Subject();
	}
    
    getTickets(numPage: number, pageSize: number, date: string, columnOrder: string, columnDirection: string, filtros: any, propio?: number, all?: boolean): Observable<any> {
		
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
	
			const options = { 
			headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
			})
			}
	
			const params = {
			page: numPage,
			pageSize: pageSize,
			date: date,
			propio: propio,
            allData: all ? all : false
			}

			if (columnDirection !== "") {
				params['sortColum'] = columnOrder;
				params['sortDirection'] = columnDirection;
			}
	
			if (filtros.ID && filtros.ID !== "") {
				params['ID'] = filtros.ID;
			}

			if (filtros.fk_proyecto && filtros.fk_proyecto !== "") {
				params['FK_entities'] = filtros.fk_proyecto;
			}

			if (filtros.estado && filtros.estado !== 0) {
				params['status'] = filtros.estado;
			}

            if (filtros.prioridad && filtros.prioridad !== 0) {
				params['priority'] = filtros.prioridad;
			}

		/*	if (filtros.grupoSolicitante && filtros.grupoSolicitante !== 0) {
				params['FK_group'] = filtros.grupoSolicitante;
			} */

		/*	if (filtros.grupoAsignado && filtros.grupoAsignado !== 0) {
				params['assign_group'] = filtros.grupoAsignado;
			}*/

			if (filtros.gruposAsignado && filtros.gruposAsignado !== "") {
				params['assign_group'] = filtros.gruposAsignado.join(',');
			}

			if (filtros.gruposSolicitante && filtros.gruposSolicitante !== "") {
				params['FK_group'] = filtros.gruposSolicitante.join(',');
			}

			if (filtros.fechaCreacionRange && filtros.fechaCreacionRange.startDate && filtros.fechaCreacionRange.startDate !== null 
				&& filtros.fechaCreacionRange.endDate && filtros.fechaCreacionRange.endDate !== null) {
				params['fechaInicioCreacion'] = moment(filtros.fechaCreacionRange.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaFinCreacion'] = moment(filtros.fechaCreacionRange.endDate).format('YYYY-MM-DD HH:mm:ss');
			}
			if (filtros.fechaModificacionRange && filtros.fechaModificacionRange.startDate && filtros.fechaModificacionRange.startDate !== null 
					&& filtros.fechaModificacionRange.endDate && filtros.fechaModificacionRange.endDate !== null) {
				params['fechaInicioModificacion'] = moment(filtros.fechaModificacionRange.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaFinModificacion'] = moment(filtros.fechaModificacionRange.endDate).format('YYYY-MM-DD HH:mm:ss');
			}
			if (filtros.fechaCierreRange && filtros.fechaCierreRange.startDate && filtros.fechaCierreRange.startDate !== null 
				&& filtros.fechaCierreRange.endDate && filtros.fechaCierreRange.endDate !== null) {
				params['fechaInicioCierre'] = moment(filtros.fechaCierreRange.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaFinCierre'] = moment(filtros.fechaCierreRange.endDate).format('YYYY-MM-DD HH:mm:ss');
			}

			if (filtros.weblordcase && filtros.weblordcase !== 0) {
				params['weblordcase'] = filtros.weblordcase;
			}

			if (filtros.numeroserie && filtros.numeroserie !== 0) {
				params['numserie'] = filtros.numeroserie;
			}

			if (filtros.otherserialnumber && filtros.otherserialnumber !== 0) {
				params['other_serial'] = filtros.otherserialnumber;
			}

			if (filtros.ninccliente && filtros.ninccliente !== 0) {
				params['ninccliente'] = filtros.ninccliente;
			}

			if (filtros.codcentro && filtros.codcentro !== 0) {
				params['codigocentro'] = filtros.codcentro;
			}

			if (filtros.titulo && filtros.titulo !== 0) {
				params['name'] = filtros.titulo;
			}

			if (filtros.autor && filtros.autor !== 0) {
				params['author'] = filtros.autor;
			}

			if (filtros.tecnico && filtros.tecnico !== 0) {
				params['assign'] = filtros.tecnico;
			}

			if (filtros.colaborador && filtros.colaborador !== 0) {
				params['assign_ent'] = filtros.colaborador;
			}

			if (filtros.estadoFabricante && filtros.estadoFabricante !== 0) {
				params['estado_cliente_fabricante'] = filtros.estadoFabricante;
			}

			if (filtros.estadosInterno && filtros.estadosInterno !== "") {
				params['estado_interno'] = filtros.estadosInterno.join(',');
			}
			
			if (filtros.etiquetas && filtros.etiquetas !== "") {
				params['etiquetas'] = filtros.etiquetas.join(',');
			}

            if (filtros.modelo && filtros.modelo !== 0) {
				params['modelo_id'] = filtros.modelo;
			}

            if (filtros.caja && filtros.caja !== 0) {
				params['caja_id'] = filtros.caja;
			}

			if(propio && propio == 1){
				params['propio'] = 1;
			}
			
			const url = environment.API_URL + '/tickets?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
			(response: any) => {
				console.log("ws getTickets", response);
				observer.next(response);
			},
			(err) => {
				console.log("error ws getTickets", err);
				let msgError = "Se ha producido un error cargando los datos";
	
				observer.error(msgError);
			}
			);
	
			return {unsubscribe() {}};
		});
    }

	getTicketsPropios(numPage: number, pageSize: number, date: string, columnOrder: string, columnDirection: string, filtros: any): Observable<any> {
		
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
	
			const options = { 
			headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
			})
			}
	
			const params = {
			page: numPage,
			pageSize: pageSize,
			date: date,
			}

			if (columnDirection !== "") {
				params['sortColum'] = columnOrder;
				params['sortDirection'] = columnDirection;
			}
	
			if (filtros.ID && filtros.ID !== "") {
				params['ID'] = filtros.ID;
			}

			if (filtros.fk_proyecto && filtros.fk_proyecto !== "") {
				params['FK_entities'] = filtros.fk_proyecto;
			}

			if (filtros.estado && filtros.estado !== 0) {
				params['status'] = filtros.estado;
			}

		/*	if (filtros.grupoSolicitante && filtros.grupoSolicitante !== 0) {
				params['FK_group'] = filtros.grupoSolicitante;
			} */

		/*	if (filtros.grupoAsignado && filtros.grupoAsignado !== 0) {
				params['assign_group'] = filtros.grupoAsignado;
			}*/

			if (filtros.gruposAsignado && filtros.gruposAsignado !== "") {
				params['assign_group'] = filtros.gruposAsignado.join(',');
			}

			if (filtros.gruposSolicitante && filtros.gruposSolicitante !== "") {
				params['FK_group'] = filtros.gruposSolicitante.join(',');
			}

			if (filtros.fechaCreacionRange && filtros.fechaCreacionRange.startDate && filtros.fechaCreacionRange.startDate !== null 
				&& filtros.fechaCreacionRange.endDate && filtros.fechaCreacionRange.endDate !== null) {
				params['fechaInicioCreacion'] = moment(filtros.fechaCreacionRange.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaFinCreacion'] = moment(filtros.fechaCreacionRange.endDate).format('YYYY-MM-DD HH:mm:ss');
			}
			if (filtros.fechaModificacionRange && filtros.fechaModificacionRange.startDate && filtros.fechaModificacionRange.startDate !== null 
					&& filtros.fechaModificacionRange.endDate && filtros.fechaModificacionRange.endDate !== null) {
				params['fechaInicioModificacion'] = moment(filtros.fechaModificacionRange.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaFinModificacion'] = moment(filtros.fechaModificacionRange.endDate).format('YYYY-MM-DD HH:mm:ss');
			}
			if (filtros.fechaCierreRange && filtros.fechaCierreRange.startDate && filtros.fechaCierreRange.startDate !== null 
				&& filtros.fechaCierreRange.endDate && filtros.fechaCierreRange.endDate !== null) {
				params['fechaInicioCierre'] = moment(filtros.fechaCierreRange.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaFinCierre'] = moment(filtros.fechaCierreRange.endDate).format('YYYY-MM-DD HH:mm:ss');
			}

			if (filtros.weblordcase && filtros.weblordcase !== 0) {
				params['weblordcase'] = filtros.weblordcase;
			}

			if (filtros.numeroserie && filtros.numeroserie !== 0) {
				params['numserie'] = filtros.numeroserie;
			}

			if (filtros.otherserialnumber && filtros.otherserialnumber !== 0) {
				params['other_serial'] = filtros.otherserialnumber;
			}

			if (filtros.ninccliente && filtros.ninccliente !== 0) {
				params['ninccliente'] = filtros.ninccliente;
			}

			if (filtros.codcentro && filtros.codcentro !== 0) {
				params['codigocentro'] = filtros.codcentro;
			}

			if (filtros.titulo && filtros.titulo !== 0) {
				params['name'] = filtros.titulo;
			}

			if (filtros.autor && filtros.autor !== 0) {
				params['author'] = filtros.autor;
			}

			if (filtros.tecnico && filtros.tecnico !== 0) {
				params['assign'] = filtros.tecnico;
			}

			if (filtros.colaborador && filtros.colaborador !== 0) {
				params['assign_ent'] = filtros.colaborador;
			}

			if (filtros.estadoFabricante && filtros.estadoFabricante !== 0) {
				params['estado_cliente_fabricante'] = filtros.estadoFabricante;
			}

			if (filtros.estadosInterno && filtros.estadosInterno !== "") {
				params['estado_interno'] = filtros.estadosInterno.join(',');
			}

            if (filtros.modelo && filtros.modelo !== 0) {
				params['modelo_id'] = filtros.modelo;
			}

            if (filtros.caja && filtros.caja !== 0) {
				params['caja_id'] = filtros.caja;
			}
			

			const url = environment.API_URL + '/tickets/propios?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
			(response: any) => {
				console.log("ws getTickets propios", response);
				observer.next(response);
			},
			(err) => {
				console.log("error ws getTickets propios", err);
				let msgError = "Se ha producido un error cargando los datos";
	
				observer.error(msgError);
			}
			);
	
			return {unsubscribe() {}};
		});
    }

    exportacionTickets(columns: any, date: string, columnOrder: string, columnDirection: string, filtros: any, propio?: number, all?: boolean): Observable<any> {
		
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
	
			const options = { 
			headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
			})
			}
	
			const params = {
                task_name: 'exportacion_tickets',
                date: date,
                propio: propio,
                allData: all ? all : false,
                columns: columns
			}

			if (columnDirection !== "") {
				params['sortColum'] = columnOrder;
				params['sortDirection'] = columnDirection;
			}
	
			if (filtros.ID && filtros.ID !== "") {
				params['ID'] = filtros.ID;
			}

			if (filtros.fk_proyecto && filtros.fk_proyecto !== "") {
				params['FK_entities'] = filtros.fk_proyecto;
			}

			if (filtros.estado && filtros.estado !== 0) {
				params['status'] = filtros.estado;
			}

            if (filtros.prioridad && filtros.prioridad !== 0) {
				params['priority'] = filtros.prioridad;
			}

			if (filtros.gruposAsignado && filtros.gruposAsignado !== "") {
				params['assign_group'] = filtros.gruposAsignado.join(',');
			}

			if (filtros.gruposSolicitante && filtros.gruposSolicitante !== "") {
				params['FK_group'] = filtros.gruposSolicitante.join(',');
			}

			if (filtros.fechaCreacionRange && filtros.fechaCreacionRange.startDate && filtros.fechaCreacionRange.startDate !== null 
				&& filtros.fechaCreacionRange.endDate && filtros.fechaCreacionRange.endDate !== null) {
				params['fechaInicioCreacion'] = moment(filtros.fechaCreacionRange.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaFinCreacion'] = moment(filtros.fechaCreacionRange.endDate).format('YYYY-MM-DD HH:mm:ss');
			}
			if (filtros.fechaModificacionRange && filtros.fechaModificacionRange.startDate && filtros.fechaModificacionRange.startDate !== null 
					&& filtros.fechaModificacionRange.endDate && filtros.fechaModificacionRange.endDate !== null) {
				params['fechaInicioModificacion'] = moment(filtros.fechaModificacionRange.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaFinModificacion'] = moment(filtros.fechaModificacionRange.endDate).format('YYYY-MM-DD HH:mm:ss');
			}
			if (filtros.fechaCierreRange && filtros.fechaCierreRange.startDate && filtros.fechaCierreRange.startDate !== null 
				&& filtros.fechaCierreRange.endDate && filtros.fechaCierreRange.endDate !== null) {
				params['fechaInicioCierre'] = moment(filtros.fechaCierreRange.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaFinCierre'] = moment(filtros.fechaCierreRange.endDate).format('YYYY-MM-DD HH:mm:ss');
			}

			if (filtros.weblordcase && filtros.weblordcase !== 0) {
				params['weblordcase'] = filtros.weblordcase;
			}

			if (filtros.numeroserie && filtros.numeroserie !== 0) {
				params['numserie'] = filtros.numeroserie;
			}

			if (filtros.otherserialnumber && filtros.otherserialnumber !== 0) {
				params['other_serial'] = filtros.otherserialnumber;
			}

			if (filtros.ninccliente && filtros.ninccliente !== 0) {
				params['ninccliente'] = filtros.ninccliente;
			}

			if (filtros.codcentro && filtros.codcentro !== 0) {
				params['codigocentro'] = filtros.codcentro;
			}

			if (filtros.titulo && filtros.titulo !== 0) {
				params['name'] = filtros.titulo;
			}

			if (filtros.autor && filtros.autor !== 0) {
				params['author'] = filtros.autor;
			}

			if (filtros.tecnico && filtros.tecnico !== 0) {
				params['assign'] = filtros.tecnico;
			}

			if (filtros.colaborador && filtros.colaborador !== 0) {
				params['assign_ent'] = filtros.colaborador;
			}

			if (filtros.estadoFabricante && filtros.estadoFabricante !== 0) {
				params['estado_cliente_fabricante'] = filtros.estadoFabricante;
			}

			if (filtros.estadosInterno && filtros.estadosInterno !== "") {
				params['estado_interno'] = filtros.estadosInterno.join(',');
			}
			
			if (filtros.etiquetas && filtros.etiquetas !== "") {
				params['etiquetas'] = filtros.etiquetas.join(',');
			}

            if (filtros.modelo && filtros.modelo !== 0) {
				params['modelo_id'] = filtros.modelo;
			}

            if (filtros.caja && filtros.caja !== 0) {
				params['caja_id'] = filtros.caja;
			}

			if(propio && propio == 1){
				params['propio'] = 1;
			}
			
			const url = environment.API_URL + '/solicitudes-cola/new-task?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
			(response: any) => {
				console.log("ws exportarTickets", response);
				observer.next(response);
			},
			(err) => {
				console.log("error ws exportarTickets", err);
				let msgError = err && err.error?err.error:"Se ha producido un error exportando los datos";
	
				observer.error(msgError);
			}
			);
	
			return {unsubscribe() {}};
		});
    }

	getCamposPropios(proyecto: any): Observable<any> {
		
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
	
			const options = { 
			headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
			})
			}
	
			const params = {
				proyecto: proyecto,
			}		
			

			const url = environment.API_URL + '/tickets/campos_propios?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
			(response: any) => {
				console.log("ws getCamposPropios ", response);
				observer.next(response);
			},
			(err) => {
				console.log("error ws getCamposPropios ", err);
				let msgError = "Se ha producido un error cargando los datos";
	
				observer.error(msgError);
			}
			);
	
			return {unsubscribe() {}};
		});
    }

	getCargaTrabajo(numPage: number, pageSize: number, columnOrder: string, columnDirection: string, filtros: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				page: numPage,
				pageSize: pageSize,
			}

			if (columnDirection !== "") {
				params['sortColum'] = columnOrder;
				params['sortDirection'] = columnDirection;
			}

			if (filtros.id_ticket && filtros.id_ticket !== "") {
				params['id_ticket'] = filtros.id_ticket;
			}
			if (filtros.estado_interno && filtros.estado_interno !== "") {
				params['estado_interno'] = filtros.estado_interno.join(',');
			}
			if (filtros.assign_group && filtros.assign_group !== "") {
				params['assign_group'] = filtros.assign_group.join(',');
			}
			if (filtros.FK_group && filtros.FK_group !== '') {
				params['FK_group'] = filtros.FK_group.join(',');
			}
			if (filtros.weblordcase && filtros.weblordcase !== "") {
				params['weblordcase'] = filtros.weblordcase;
			}
			if (filtros.numserie && filtros.numserie !== "") {
				params['numserie'] = filtros.numserie;
			}
			if (filtros.priority && filtros.priority !== 0) {
				params['priority'] = filtros.priority;
			}
			if (filtros.status && filtros.status !== "") {
				params['status'] = filtros.status;
			}
			if (filtros.FK_entities && filtros.FK_entities !== 0) {
				params['FK_entities'] = filtros.FK_entities;
			}
			if (filtros.assign && filtros.assign !== 0) {
				params['assign'] = filtros.assign;
			}
			if (filtros.provinciaCliente && filtros.provinciaCliente !== 0) {
				params['provinciaCliente'] = filtros.provinciaCliente;
			}

			const url = environment.API_URL + '/tickets/carga-trabajo?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getCargaTrabajo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getCargaTrabajo", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getTicketsEstados(numPage: number, pageSize: number, date: string, columnOrder: string, columnDirection: string, filtros: any, propio?: number, all?: boolean): Observable<any> {
		
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
	
			const options = { 
			headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
			})
			}
	
			const params = {
                page: numPage,
                pageSize: pageSize,
                date: date,
                propio: propio,
                allData: all ? all : false
			}

			if (columnDirection !== "") {
				params['sortColum'] = columnOrder;
				params['sortDirection'] = columnDirection;
			}
	
			if (filtros.ID && filtros.ID !== "") {
				params['ID'] = filtros.ID;
			}

			if (filtros.fk_proyecto && filtros.fk_proyecto !== "") {
				params['FK_entities'] = filtros.fk_proyecto;
			}

			if (filtros.estado && filtros.estado !== 0) {
				params['status'] = filtros.estado;
			}

			if (filtros.fecha && filtros.fecha.startDate && filtros.fecha.startDate !== null 
				&& filtros.fecha.endDate && filtros.fecha.endDate !== null) {
				params['fechaInicio'] = moment(filtros.fecha.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaFin'] = moment(filtros.fecha.endDate).format('YYYY-MM-DD HH:mm:ss');
			}

			if (filtros.numeroserie && filtros.numeroserie !== 0) {
				params['numserie'] = filtros.numeroserie;
			}

			if (filtros.autor && filtros.autor !== 0) {
				params['author'] = filtros.autor;
			}

			if (filtros.tecnico && filtros.tecnico !== 0) {
				params['assign'] = filtros.tecnico;
			}

			if (filtros.estadosInterno && filtros.estadosInterno !== "") {
				params['estado_interno'] = filtros.estadosInterno.join(',');
			}

            if (filtros.modelo && filtros.modelo !== 0) {
				params['modelo_id'] = filtros.modelo;
			}

            if (filtros.caja && filtros.caja !== 0) {
				params['caja_id'] = filtros.caja;
			}

			if(propio && propio == 1){
				params['propio'] = 1;
			}
			
			const url = environment.API_URL + '/tickets/tickets-estado?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
			(response: any) => {
				console.log("ws getTicketsEstados", response);
				observer.next(response);
			},
			(err) => {
				console.log("error ws getTicketsEstados", err);
				let msgError = "Se ha producido un error cargando los datos";
	
				observer.error(msgError);
			}
			);
	
			return {unsubscribe() {}};
		});
    }
    
	getTicket(idTicket: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
	
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/tickets/' + idTicket;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTickets", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTickets", err);
					let msgError = "Se ha producido un error cargando los datos";
		
					observer.error(msgError);
				}
			);
	
			return {unsubscribe() {}};
		});
    }

    getAutoresSeguimientosTicket(idTicket: number): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/tickets/'+idTicket+'/autores-seguimientos';
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getAutoresSeguimientosTicket", response);
				observer.next(response);
				},
				(err) => {
				//console.log("error ws getAutoresSeguimientosTicket", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }
    
	getSeguimientosTicket(idTicket: number, numPage?: number, pageSize?: number, filtros?: any): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
		
			let params = {
				page: numPage,
				pageSize: pageSize,
			}

			if (filtros) {
				if (filtros.content !== null) {
					params['contents'] = filtros.content
				}
				if (filtros.autor !== null) {
					params['author'] = filtros.autor
				}
				if (filtros.fecha !== null) {
					params['fecha'] = filtros.fecha
				}
			}
	
			const url = environment.API_URL + '/tickets/'+idTicket+'/seguimientos?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getSeguimientosTicket", response);
				observer.next(response);
				},
				(err) => {
				//console.log("error ws getSeguimientosTicket", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }
    
	crearSeguimiento(idTicket: number, datos: any): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
		
			const data = {
				comentario: datos.contents,
				notificarCierre: false,
				tipo: datos.tipo,
				private: datos.private
			};
	
			const url = environment.API_URL + '/tickets/'+idTicket+'/create-seguimiento';
			this.http.post(url, { data: data}, options).subscribe(
				(response: any) => {
					//console.log("ws crearSeguimiento", response);
					observer.next(response);
				},
				(err) => {
					//console.log("error ws crearSeguimiento", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

	getEtiquetasTicket(idTicket: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/tickets/'+idTicket+'/etiquetas';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getEtiquetasTicket", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getEtiquetasTicket", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getTicketsNumserie(numserie: string, numPage?: number, pageSize?: number): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
		
			const params = {
				page: numPage,
				pageSize: pageSize,
				numserie : numserie
			}
	
			const url = environment.API_URL + '/tickets/numserie?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				//console.log("ws getTicketTracking", response);
				observer.next(response);
				},
				(err) => {
				//console.log("error ws getTicketTracking", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

    getDiagnosisTicket(idTicket: number, page: number = 0, pageSize: number = 50): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
		
			const params = {
				page: 0,
				pageSize: 50,
			}
	
			const url = environment.API_URL + '/tickets/'+idTicket+'/diagnosis?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDiagnosisTicket", response);
					observer.next(response);
				},
				(err) => {
					//console.log("error ws getDiagnosisTicket", err);
					let msgError = "Se ha producido un error cargando los datos";
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

    getSintomas(idTicket: number): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/tickets/'+idTicket+'/sintomas';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getSintomas", response);
					observer.next(response);
				},
				(err) => {
					let msgError = "Se ha producido un error cargando los datos";
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

    getReparacionesTicket(idTicket: number): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
		
			const params = {
				repair: 1,
				page: 0,
				pageSize: 50,
			}
	
			const url = environment.API_URL + '/tickets/'+idTicket+'/diagnosis?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getReparacionesTicket", response);
					observer.next(response);
				},
				(err) => {
					//console.log("error ws getReparacionesTicket", err);
					let msgError = "Se ha producido un error cargando los datos";
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }
    
	getPresupuestoTicket(idTicket: number, numPage: number, pageSize: number): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
		
			const params = {
				page: numPage,
				pageSize: pageSize,
			}
	
			const url = environment.API_URL + '/tickets/'+idTicket+'/presupuestos?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getPresupuestosTicket", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getPresupuestosTicket", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

	getCategories(idTicket: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				id_ticket: idTicket
			}
	
			const url = environment.API_URL + '/tickets/categories?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getCategories", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getCategories", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	getAlertas(idTicket: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/tickets/'+idTicket+'/alertas';
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getAlertasTicket", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getAlertasTicket", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}


	getUsuarios(tipo: string) {

		const params = {
			tipo: tipo
		}

		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

		
			const url = environment.API_URL + '/usuarios?' + this.helperSvc.serializeData(params);
			console.log("ws getUsuarios tickets", url);
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getUsuarios", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getUsuarios", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });


	}
	
	getPriorities(idTicket?: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				id_ticket: idTicket
			}
	
			const url = environment.API_URL + '/tickets/priorities?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				//console.log("ws getTicketTracking", response);
				observer.next(response);
				},
				(err) => {
				//console.log("error ws getTicketTracking", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	getCriticalities(idTicket?: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				id_ticket: idTicket
			}
	
			const url = environment.API_URL + '/tickets/criticalities?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				//console.log("ws getTicketTracking", response);
				observer.next(response);
				},
				(err) => {
				//console.log("error ws getTicketTracking", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	getSeverities(idTicket?: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				id_ticket: idTicket
			}
	
			const url = environment.API_URL + '/tickets/severities?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				//console.log("ws getTicketTracking", response);
				observer.next(response);
				},
				(err) => {
				//console.log("error ws getTicketTracking", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	getInfoKpis(idTicket: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const data = [
				
				// { 
				// 	ID: 1, 
				// 	nombre: "FTF", 
				// 	descripcion: "First Time Fix (Reparación en la 1ª visita)",
				// 	condicion: "= 1 cita",
				// 	valorGeneral: "88,89%",
				// 	limite: ">= 95%",
				// 	cumple: 0,
				// },
				// { 
				// 	ID: 2, 
				// 	nombre: "RR", 
				// 	descripcion: "Ticket repetido en un periodo de 30 días",
				// 	condicion: "< 30 días naturales",
				// 	valorGeneral: "20%",
				// 	limite: "<= 3%",
				// 	cumple: 1,
				// },
				// { 
				// 	ID: 3, 
				// 	nombre: "TAT NBD Incidencia", 
				// 	descripcion: "Cierre del ticket al DIA SIGUIENTE LABORABLE de haber recibido la INCIDENCIA",
				// 	condicion: "8 horas",
				// 	valorGeneral: "45%",
				// 	limite: ">=90%",
				// 	cumple: 0,
				// },
			];
			observer.next(data);
	
			// const url = environment.API_URL + '/tickets/info-kpis';
			// this.http.get(url, options).subscribe(
			// 	(response: any) => {
			// 	console.log("ws getTicketTracking", response);
			// 	observer.next(response);
			// 	},
			// 	(err) => {
			// 	console.log("error ws getTicketTracking", err);
			// 	let msgError = "Se ha producido un error cargando los datos";
		
			// 	observer.error(msgError);
			// 	}
			// );
		
			return {unsubscribe() {}};
        });
	}
	
	getKpisTicket(idTicket: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/tickets/'+ idTicket +'/kpis';
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getKpisTicket", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getKpisTicket", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	getAdjuntosTicket(idTicket: number, numPage: number, pageSize: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				page: numPage,
				pageSize: pageSize
			}
	
			const url = environment.API_URL + '/tickets/'+ idTicket +'/adjuntos?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getAdjuntosTicket", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getAdjuntosTicket", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	getFormulariosTicket(idTicket: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

	
			const url = environment.API_URL + '/tickets/'+ idTicket +'/formularios-tickets';
			this.http.get(url, options).subscribe(
				(response: any) => {
				// console.log("ws getAdjuntosTicket", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getFormulariosTickets", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	
	getTiposAdjuntosTicket(idTicket: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/tickets/'+ idTicket +'/adjuntos-tipos';
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getTiposAdjuntosTicket", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposAdjuntosTicket", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	descargarAdjunto(idTicket: number, idAdjunto: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/tickets/'+ idTicket +'/download-adjunto/' + idAdjunto;
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws descargarAdjunto", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws descargarAdjunto", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}



	getEstadosGeneral(estadosAgrupados?: boolean) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				groupStatus: estadosAgrupados && estadosAgrupados === true ? 1 : 0
			}
	
			const url = environment.API_URL + '/tickets/estados?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getEstadosGeneral", response);
					
					observer.next(response);
				},
				(err) => {
					console.log("error ws getEstadosGeneral", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	getEstadosGrupos(type: string, idTicket?: number, idProject?: number, estadoSelected?: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				type: type,
				id_ticket: idTicket,
				id_project: idProject,
				estado_selected: estadoSelected
			}
	
			const url = environment.API_URL + '/tickets/estados-grupos?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getEstadosGrupos", type, response);
					response.unshift({ id: 0, name: 'SIN GRUPO' });
					observer.next(response);
				},
				(err) => {
					console.log("error ws getEstadosGrupos", type, err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	getPermisosEstadosInternoUsuario(idTicket?: number, idProyecto?, idEstado?: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				id_ticket: idTicket,
				id_project: idProyecto,
				id_estado: idEstado
			}
	
			const url = environment.API_URL + '/tickets/permiso-estados?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getPermiso estados internos usuario", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getPermiso estados internos usuario", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	getEstados(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const data = [
				{ID: 1, name:"Nueva" },
				{ID: 2, name:"En espera" },
				{ID: 3, name:"Cerrada (Resuelta)" },
				{ID: 4, name:"Cerrada (no resuelta)" },
				{ID: 5, name:"En curso(asignada)" },
				{ID: 6, name:"En curso(planificada)" },
				{ID: 7, name:"No cerrada" },
				{ID: 8, name:"En curso" },
				{ID: 9, name:"Todos" },
				{ID: 10, name:"Cerrada" },
			
				
			];
			observer.next(data);
		
			return {unsubscribe() {}};
        });
	}

    getPrioridades(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const data = [
				{ID: 1, name:"Muy Baja" },
				{ID: 2, name:"Baja" },
				{ID: 3, name:"Media" },
				{ID: 4, name:"Urgente" },
				{ID: 5, name:"Muy urgente" },
				{ID: 6, name:"Prioritario" },
				{ID: 7, name:"Normal" }
			];

			observer.next(data);
		
			return {unsubscribe() {}};
        });
	}

	getCodeEstado(idEstado: number) {
		let codeEstado = '';

		switch (idEstado) {
			case 1:
				codeEstado = 'new';
				break;
		
			case 2:
				codeEstado = 'waiting';
				break;
		
			case 3:
				codeEstado = 'old_done';
				break;
		
			case 4:
				codeEstado = 'old_notdone';
				break;
		
			case 5:
				codeEstado = 'assign';
				break;
		
			case 6:
				codeEstado = 'plan';
				break;
		
			case 7:
				codeEstado = 'nocerrada';
				break;
		
			case 8:
				codeEstado = 'encurso';
				break;
		
			case 9:
				codeEstado = 'todos';
				break;
		
			case 10:
				codeEstado = 'cerrada';
				break;
		
			default:
				break;
		}

		return codeEstado;
	}
	getIdEstadoFromCode(codeEstado: string) {
		let idEstado = 0;

		switch (codeEstado) {
			case 'new':
				idEstado = 1;
				break;
		
			case 'waiting':
				idEstado = 2;
				break;
		
			case 'old_done':
				idEstado = 3;
				break;
		
			case 'old_notdone':
				idEstado = 4;
				break;
		
			case 'assign':
				idEstado = 5;
				break;
		
			case 'plan':
				idEstado = 6;
				break;
		
			case 'nocerrada':
				idEstado = 7;
				break;
		
			case 'encurso':
				idEstado = 8;
				break;
		
			case 'todos':
				idEstado = 9;
				break;
		
			case 'cerrada':
				idEstado = 10;
				break;
		
			default:
				break;
		}

		return idEstado;
	}
	
	getEstadosClienteFabricante(idTicket?: number, idProyecto?, idEstado?: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				estado_selected: idEstado,
				id_ticket: idTicket,
				id_project: idProyecto,
				type: 'FABRICANTE'
			}
	
			const url = environment.API_URL + '/tickets/estados-fabricantes?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getEstadosFabricantes", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getEstadosFabricantes", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	getEstadosInternos(idTicket?: number, idProyecto?, idEstado?: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				id_ticket: idTicket,
				id_project: idProyecto,
				id_estado: idEstado,
				type: 'INTERNO'
			}
	
			const url = environment.API_URL + '/tickets/estados-internos?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getEstadosInterno", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getEstadosInterno", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	getEstadosInternosInicialProyecto(idTicket?: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				id_ticket: idTicket,
				type: 'INTERNO'
			}
	
			const url = environment.API_URL + '/tickets/estados-internosinicial-proyecto?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getEstadosInterno Inicial Proyecto", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getEstadosInterno", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

    getPestanas(idContrato){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/contratos/'+idContrato+'/pestanas-formulario';
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getPestanas", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getPestanas", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

	getDatosUnei(idProyecto?: number, idEstado?:number, modelo?:string, provincia?:string){
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
			//	id_ticket: idTicket,
                id_project: idProyecto,
				id_estado: idEstado,
				modelo: modelo,
				provincia: provincia
			}
	
			const url = environment.API_URL + '/tickets/datos-unei?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getDatosUnei", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getDatosUnei", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

    getEstadosInternosTicketUnei(idTicket?: number, idProyecto?: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				id_ticket: idTicket,
                id_project: idProyecto
			}
	
			const url = environment.API_URL + '/tickets/estados-internos-ticket-unei?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getEstadosInternosTicketUnei", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getEstadosInternosTicketUnei", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}


	getCodigosCentro(idTicket?: number) {

	
			return new Observable<any>(observer => {
				const accessToken = this.authSvc.getAccessToken();
			
				const options = {
					headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
					})
				}
	
				const params = {
	
					id_ticket: idTicket,
				
					
				}
		
				const url = environment.API_URL + '/tickets/codigos-centro?' + this.helperSvc.serializeData(params);
				this.http.get(url, options).subscribe(
					(response: any) => {
					console.log("ws getCodigosCentro", response);
					observer.next(response);
					},
					(err) => {
					console.log("error ws getCodigosCentro", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
					}
				);
			
				return {unsubscribe() {}};
			});
	}

	quitarCliente(idTicket: number, idCliente: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				idCliente: idCliente,
				idTicket: idTicket
			}
	
			const url = environment.API_URL + '/clientesolicitante/quitar-cliente-ticket';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws quitarClienteTicket", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws quitarClienteTicket", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	getDireccionesCliente(idTicket: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			// const data = [
			// 	{
			// 		ID: 1,
			// 		tipo_direccion: 4,
			// 		nombre: "Alberto Imaz",
			// 		apellido: "",
			// 		num_telefono: "",
			// 		id_pais: 1,
			// 		id_provincia: 28,
			// 		id_localidad: 30921,
			// 		direccion: "Calle Vereda del Colmenar, 4",
			// 		num_casa: "",
			// 		otros_datos: "",
			// 		direccion_email: "",
			// 		tipoDireccion: { id: 4, tipo_direccion: "Contacto"},
			// 		pais: { id: 1, pais: "España" },
			// 		provincia: { idprovincia: 28, provincia: "MADRID" },
			// 		localidad: { idpoblacion: 30921, postal: 28440, poblacion: "GUADARRAMA" }
			// 	},
			// 	{
			// 		ID: 1,
			// 		tipo_direccion: 3,
			// 		nombre: "Alberto Imaz",
			// 		apellido: "",
			// 		num_telefono: "",
			// 		id_pais: 1,
			// 		id_provincia: 28,
			// 		id_localidad: 30921,
			// 		direccion: "Calle Vereda del Colmenar, 4",
			// 		num_casa: "",
			// 		otros_datos: "",
			// 		direccion_email: "",
			// 		tipoDireccion: { id: 3, tipo_direccion: "Recogida"},
			// 		pais: { id: 1, pais: "España" },
			// 		provincia: { idprovincia: 28, provincia: "MADRID" },
			// 		localidad: { idpoblacion: 30921, postal: 28440, poblacion: "GUADARRAMA" }
			// 	}
			// ];
			// observer.next(data);
	
			const url = environment.API_URL + '/tickets/'+idTicket+'/direcciones-cliente';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDireccionesCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDireccionesCliente", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	eliminarDireccionCliente(idDireccion: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/clientesolicitante/delete-direccion-ticket/' + idDireccion;
			this.http.post(url, {}, options).subscribe(
				(response: any) => {
					console.log("ws eliminarDireccionCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws eliminarDireccionCliente", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	getDatosCmTicketsGrupos(numPage: number, pageSize: number, separateProjects: boolean, filtros: any, loadFiltros?: boolean): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				page: numPage,
				pageSize: pageSize,
				load_filtros: loadFiltros !== undefined ? loadFiltros : true,
				separate_projects: separateProjects,
				estado_tickets: filtros.estadoTickets
			}

			if (filtros.provincia && filtros.provincia !== "") {
				params['provincias'] = filtros.provincia.join(',');
			}
			if (filtros.proyecto && filtros.proyecto !== "") {
				params['proyectos'] = filtros.proyecto.join(',');
			}
			if (filtros.grupoSolicitante && filtros.grupoSolicitante !== "") {
				params['grupos_solicitante'] = filtros.grupoSolicitante.join(',');
			}
			if (filtros.grupoAsignado && filtros.grupoAsignado !== "") {
				params['grupos_asisgnado'] = filtros.grupoAsignado.join(',');
			}

			const url = environment.API_URL + '/tickets/cm-tickets-grupos?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDatosCmTicketsGrupos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDatosCmTicketsGrupos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getDatosCmTicketsUsuario(numPage: number, pageSize: number, separateProjects: boolean, filtros: any, loadFiltros?: boolean): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				page: numPage,
				pageSize: pageSize,
				load_filtros: loadFiltros !== undefined ? loadFiltros : true,
				separate_projects: separateProjects
			}

			if (filtros.fk_proyecto && filtros.fk_proyecto !== "") {
				params['FK_entities'] = filtros.fk_proyecto;
			}

			if (filtros.fecha && filtros.fecha.startDate && filtros.fecha.startDate !== null 
				&& filtros.fecha.endDate && filtros.fecha.endDate !== null) {
				params['fechaInicio'] = moment(filtros.fecha.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaFin'] = moment(filtros.fecha.endDate).format('YYYY-MM-DD HH:mm:ss');
			}

			if (filtros.tecnico && filtros.tecnico !== 0) {
				params['assign'] = filtros.tecnico;
			}

			const url = environment.API_URL + '/tickets/cm-tickets-usuario?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDatosCmTicketsUsuario", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDatosCmTicketsUsuario", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getDatosCmTicketsEstados(numPage: number, pageSize: number, separateProjects: boolean, filtros: any, loadFiltros?: boolean): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				page: numPage,
				pageSize: pageSize,
				load_filtros: loadFiltros !== undefined ? loadFiltros : true,
				separate_projects: separateProjects,
				estado_tickets: filtros.estadoTickets,
                estados_interno: filtros.estadoInterno,
                estados_fabricante: filtros.estadoFabricante,
                proyectos: filtros.proyecto
			}
            
			if (filtros.grupoSolicitante && filtros.grupoSolicitante !== "") {
				params['grupos_solicitante'] = filtros.grupoSolicitante.join(',');
			}
			if (filtros.grupoAsignado && filtros.grupoAsignado !== "") {
				params['grupos_asisgnado'] = filtros.grupoAsignado.join(',');
			}

			const url = environment.API_URL + '/tickets/cm-tickets-estados?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDatosCmTicketsEstados", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDatosCmTicketsEstados", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	//Crear ticket, sólo se fija en los contratos y fk distinto de null, por lo que si se necesitan más restricciones deberiamos retocar el parametros.ts
	crearTicket(data: any): Observable<any>{
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = data;


			const url = environment.API_URL + '/tickets';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearTicket", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearTicket", err);
					console.log(data)
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.error === 'invalid_no_fk_entities') {
						msgError = "El fk_entities indicado no existe";
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	} 
	
	guardarDatosTicket(idTicket, data: any): Observable<any>{
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = data;
			params['ID'] = Number(idTicket);

			const url = environment.API_URL + '/tickets/update';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws updateTicket", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws updateTicket", err);
					console.log(data)
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.error === 'invalid_no_fk_entities') {
						msgError = "El fk_entities indicado no existe";
					}
					else if (err.error.error === 'contrato_no_activo') {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	} 
	
	guardarEtiquetasTicket(idTicket, listIdEtiquetas: any): Observable<any>{
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				etiquetas: listIdEtiquetas
			}

			const url = environment.API_URL + '/tickets/'+idTicket+'/save-etiquetas';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws saveEtiquetasTicket", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws saveEtiquetasTicket", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	} 



	uploadAdjunto(idTicket: number, tipoAdjunto: number, fileName: string, imagenBase64: any): Observable<any>{
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				tipo_adjunto: tipoAdjunto,
				app: 0,
				file: {
					base64 : imagenBase64,
					filename: fileName
				}
			};

			const url = environment.API_URL + '/tickets/'+idTicket+'/upload-adjunto';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws uploadAdjunto", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws uploadAdjunto", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	deleteAdjunto(idTicket: number, idAdjunto: Number): Observable<any>{
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				idAdjunto: idAdjunto
			};

			const url = environment.API_URL + '/tickets/'+idTicket+'/delete-adjunto';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws deleteAdjunto", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws deleteAdjunto", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}


    // Apunta hacia un servidor JSON creado para probar la funcionalidad
    // getTicketsZone(numPage: number, pageSize: number, date: string): Observable<any> {
      getTicketsZone(): Observable<any> {
      return new Observable<any>(observer => {
        // const accessToken = this.authSvc.getAccessToken();
  
        // const options = {
        //   headers: new HttpHeaders({
        //     'Authentication': 'Bearer ' + accessToken,
        //     'Content-Type': 'application/json; charset=utf-8'
        //   })
        // }
  
        // const params = {
        //   page: numPage,
        //   pageSize: pageSize,
        //   date: date,
        // }

        // const url = environment.API_URL + '/tickets/cm-grupos-provincia?' + this.helperSvc.serializeData(params);
        const url = "/api/tickets_zona"
        // this.http.get(url, options).subscribe(
        this.http.get(url).subscribe(
          (response: any) => {
            console.log("ws getTicketsZone", response);
            observer.next(response);
          },
          (err) => {
            console.log("error ws getTicketsZone", err);
            let msgError = "Se ha producido un error cargando los datos";
  
            observer.error(msgError);
          }
        );
  
        return {unsubscribe() {}};
      });
    }

	changeStatus(idTicket: number, status: number): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
		
			const params = {
				status: status,
			}
	
			const url = environment.API_URL + '/tickets/'+idTicket+'/change-status';
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws changeStatus", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws changeStatus", err);
					let msgError = "Se ha producido un error guardando";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

	updateProperty(idTicket: number, propertyName: string, value: string | number) {
		const accessToken = this.authSvc.getAccessToken();
	
		const options = {
			headers: new HttpHeaders({
			'Authentication': 'Bearer ' + accessToken,
			'Content-Type': 'application/json; charset=utf-8'
			})
		}
		
		const params = {
			id: idTicket,
			property: propertyName,
			value: value
		}

		return this.http.put(environment.API_URL+'/tickets/update-property', params, options);
	}


	guardarCambiosMasivos(formValue: any, ids: Array<any>){
		const accessToken = this.authSvc.getAccessToken();
	
		const options = {
			headers: new HttpHeaders({
			'Authentication': 'Bearer ' + accessToken,
			'Content-Type': 'application/json; charset=utf-8'
			})
		}
		
		const params = {
			form: formValue,
			ids: ids
		}

		return this.http.put(environment.API_URL+'/tickets/masive-update', params, options);
	}
	
	buscarCasoAutoasignacion(numCaso: string){
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
		
			const params = {
				num_caso: numCaso
			}
	
			const url = environment.API_URL + '/tickets/buscar-caso-autoasignacion';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws buscarCasoAutoasignacion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws buscarCasoAutoasignacion", err);
					let msgError = "Se ha producido un error guardando";
					if (err.error.message) {
						msgError = err.error.message;
					}
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	asignarCaso(numCaso: string, idTecnico?: number){
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
		
			const params = {
				num_caso: numCaso,
				tecnico: idTecnico ? idTecnico : null
			}
	
			const url = environment.API_URL + '/tickets/asignar-caso';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws asignarCaso", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws asignarCaso", err);
					let codeError = "error";
					let msgError = "Se ha producido un error guardando";
					if (err.error.message) {
						msgError = err.error.message;
					}
					if (err.error.codeError) {
						codeError = err.error.codeError;
					}
			
					observer.error({ codeError: codeError, msg: msgError});
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	getPxQManobra(numPage: number, pageSize: number, filtros: any, loadFiltros?: boolean): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				page: numPage,
				pageSize: pageSize,
				load_filtros: loadFiltros !== undefined ? loadFiltros : true
			}

			
			if (filtros.proyecto && filtros.proyecto !== "") {
				params['proyectos'] = filtros.proyecto.join(',');
			}
			

			const url = environment.API_URL + '/tickets/pxqmanobra?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDatosPxqManobra", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDatosPxqManobra", err);
					let msgError = "Se ha producido un error cargando los datos pxq mano obra";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getDatosCmTendencia(numPage: number, pageSize: number, filtros: any, loadFiltros?: boolean): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				page: numPage,
				pageSize: pageSize,
				load_filtros: loadFiltros !== undefined ? loadFiltros : true
			}

			
			if (filtros.proyecto && filtros.proyecto !== "") {
				params['proyectos'] = filtros.proyecto.join(',');
			}
			

			const url = environment.API_URL + '/tickets/cmtendencia?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDatosTendencia", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDatosTendencia", err);
					let msgError = "Se ha producido un error cargando los datos pxq mano obra";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getDatosCmTendenciaSeparados(numPage: number, pageSize: number, filtros: any, loadFiltros?: boolean): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				page: numPage,
				pageSize: pageSize,
				load_filtros: loadFiltros !== undefined ? loadFiltros : true
			}

			
			if (filtros.proyecto && filtros.proyecto !== "") {
				params['proyectos'] = filtros.proyecto.join(',');
			}
			

			const url = environment.API_URL + '/tickets/cmtendenciaseparados?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDatosTendencia", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDatosTendencia", err);
					let msgError = "Se ha producido un error cargando los datos pxq mano obra";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getNameEstado(nameStatus: string) {
		
		if (nameStatus == 'new') {
			return 'Nueva'
		}else if(nameStatus == 'assign'){
			return 'En Curso (Asignado)'
		}else if(nameStatus == 'waiting'){
			return 'En espera'
		}else if(nameStatus == 'plan'){
			return 'En curso (Planificado)'
		}else if(nameStatus == 'old_done'){
			return 'Cerrada (Resuelta)'
		}else if(nameStatus == 'old_notdone'){
			return 'Cerrada (No Resuelta)'
		}
		else {
			return '';
		}
	}

	getEnvios(idTicket: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/tickets/' + idTicket + '/envios'
			this.http.get(url, options).subscribe(
				(response: any) => {
				//console.log("ws getEnviosTracking", response);
				observer.next(response);
				},
				(err) => {
				//console.log("error ws getEnviosTracking", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

    getFormularioCamposProyecto(idTicket: number, idEstado: number = null, idPestana: number = null){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

            const params = {
                idEstado: idEstado,
                idPestana: idPestana
            };
	
			const url = environment.API_URL + '/tickets/' + idTicket + '/formulario-proyecto?'+this.helperSvc.serializeData(params)
			this.http.get(url, options).subscribe(
				(response: any) => {
				//console.log("ws getEnviosTracking", response);
				observer.next(response);
				},
				(err) => {
				//console.log("error ws getEnviosTracking", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

    guardarCamposFormularioProyecto(formValue: any, id: number, idGrupo: any){
		const accessToken = this.authSvc.getAccessToken();
	
		const options = {
			headers: new HttpHeaders({
			'Authentication': 'Bearer ' + accessToken,
			'Content-Type': 'application/json; charset=utf-8'
			})
		}
		
		const params = {
			form: formValue,
			id: id,
            idGrupo: idGrupo
		}

		return this.http.put(environment.API_URL+'/tickets/guardar-valor-campos-proyecto', params, options);
	}

    generarDocumentoCajaUnei(idTicket: number){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const headers = new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
			});

			const url = environment.API_URL + '/tickets/' + idTicket + '/documento-caja-unei'
			this.http.get(url, {responseType: 'blob', headers: headers}).subscribe(
				(response: any) => {
					const file = new Blob([response], {type: 'application/pdf'});
					const fileUrl = window.URL.createObjectURL(file);
					observer.next(fileUrl);
				},
				(err) => {
					console.log("error descargarParte", err);
					observer.error(err);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

    generarDocumentoCajaUneiCaja(idCaja: number){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const headers = new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
			});

			const url = environment.API_URL + '/tickets/' + idCaja + '/documento-caja-unei-caja'
			this.http.get(url, {responseType: 'blob', headers: headers}).subscribe(
				(response: any) => {
					const file = new Blob([response], {type: 'application/pdf'});
					const fileUrl = window.URL.createObjectURL(file);
					observer.next(fileUrl);
				},
				(err) => {
					console.log("error descargarParte", err);
					observer.error(err);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

    sendTicketsUnei(idCaja: any){
        const accessToken = this.authSvc.getAccessToken();
    
        const options = {
            headers: new HttpHeaders({
            'Authentication': 'Bearer ' + accessToken,
            'Content-Type': 'application/json; charset=utf-8'
            })
        }
        
        const params = {
            id: idCaja
        }

        return this.http.post(environment.API_URL+'/tickets/send-tickets-unei', params, options);
    }


	modificarEstadoSiguienteUnei(idTicket: number,idGrupo: any){
		const accessToken = this.authSvc.getAccessToken();
	
		const options = {
			headers: new HttpHeaders({
			'Authentication': 'Bearer ' + accessToken,
			'Content-Type': 'application/json; charset=utf-8'
			})
		}
		
		const params = {
			idTicket: idTicket,
            idGrupo: idGrupo
		}

		return this.http.put(environment.API_URL+'/tickets/modificar-estado-siguiente-unei', params, options);
	}

    descargarParte(id: number){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const headers = new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
			});

			const url = environment.API_URL + '/partes/' + id+'?parte=boletin-instalacion';
			this.http.get(url, {responseType: 'blob', headers: headers}).subscribe(
				(response: any) => {
					const file = new Blob([response], {type: 'application/pdf'});
					const fileUrl = window.URL.createObjectURL(file);
					observer.next(fileUrl);
				},
				(err) => {
					console.log("error descargarParte", err);
					observer.error(err);
				}
			);

			return {unsubscribe() {}};
		});
    }
    
	getPartes(idTicket: number){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/tickets/' + idTicket + '/partes';
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getPartes", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getPartes", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }
	
	getFinancieros(idTicket: number, page: number, pageSize: number){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				page: page,
				pageSize: pageSize
			}

			const url = environment.API_URL + '/tickets/' + idTicket + '/financieros?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getFinancieros", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getFinancieros", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }
	
	getExpediciones(idTicket: number){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/tickets/' + idTicket + '/expediciones';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getExpediciones", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getExpediciones", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

    getCompras(idTicket: number, page: number, pageSize: number){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				page: page,
				pageSize: pageSize
			}

			const url = environment.API_URL + '/tickets/' + idTicket + '/compras?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
				console.log("ws getCompras", response);
				observer.next(response);
				},
				(err) => {
				console.log("error ws getCompras", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

	getFormularioPersonalizadoProyecto(idTicket: number, idFormulario: number = null){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
                idFormulario: idFormulario
            };

			const url = environment.API_URL + '/tickets/' + idTicket + '/formulario-personalizado-proyecto?'+this.helperSvc.serializeData(params)
			this.http.get(url, options).subscribe(
				(response: any) => {
				//console.log("ws getEnviosTracking", response);
				observer.next(response);
				},
				(err) => {
				//console.log("error ws getEnviosTracking", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

	
	getDataFormulario(idFormulario: number = null){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
                idFormulario: idFormulario
            };

			const url = environment.API_URL + '/tickets/datos-formulario-personalizado?'+this.helperSvc.serializeData(params)
			this.http.get(url, options).subscribe(
				(response: any) => {
				//console.log("ws getEnviosTracking", response);
				observer.next(response);
				},
				(err) => {
				//console.log("error ws getEnviosTracking", err);
				let msgError = "Se ha producido un error cargando los datos";
		
				observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

    guardarCamposFormularioPersonalizadoProyecto(formValue: any, id: number, idGrupo: any){
		const accessToken = this.authSvc.getAccessToken();
	
		const options = {
			headers: new HttpHeaders({
			'Authentication': 'Bearer ' + accessToken,
			'Content-Type': 'application/json; charset=utf-8'
			})
		}
		
		const params = {
			form: formValue,
			id: id,
            idGrupo: idGrupo
		}

		return this.http.put(environment.API_URL+'/tickets/guardar-valor-campos-formulario-personalizado-proyecto', params, options);
	}

    getInformePrueba(informe: any, page: number, size: number, filtros: any, getAll: boolean = false){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {...{
                getAll: getAll,
                dataName: informe,
                numPage: page,
                pageSize: size
            }, filtros};

			const url = environment.API_URL + '/tickets/prueba-informe?'+this.helperSvc.serializeData(params)
			this.http.get(url, options).subscribe(
				(response: any) => {
				    observer.next(response);
				},
				(err) => {
                    let msgError = "Se ha producido un error cargando los datos";
            
                    observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
    }

}
