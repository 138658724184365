import { Component, OnInit, EventEmitter, forwardRef, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR, FormControl, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColaboradoresService } from 'app/services/colaboradores/colaboradores.service';
import { DireccionesService } from 'app/services/direcciones/direcciones.service';


@Component({
    selector: 'app-direccion-form-dialog',
    templateUrl: './direccion-form-dialog.component.html',
    styleUrls: ['./direccion-form-dialog.component.scss'],
})
export class DireccionFormDialogComponent implements OnInit {

    closeDialog = new EventEmitter();

    direccionForm: FormGroup;

    error: string;
    isNewDireccion: boolean = false;

	loadingSave: boolean = false;
	disableBtnSave: boolean = false;

    idDireccion: number;
    idColaborador: number;

    paises;
    provincias;
    localidades;
    tiposDireccion;
    tiposVia: Array<any> = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<DireccionFormDialogComponent>,
        private _snackBar: MatSnackBar,
        private direccionesService: DireccionesService,
    ) {

        if (!this.data.ID)
            this.isNewDireccion = true;
        else
            this.idDireccion = this.data.ID;

        this.direccionForm = this.formBuilder.group({
            direccion: new FormControl(this.data ? this.data.direccion : null, Validators.required),
            num_casa: [this.data ? this.data.num_casa : null, Validators.required],
            otros_datos: [this.data ? this.data.otros_datos : null],
            tipo_via: [this.data ? this.data.tipo_via : null, Validators.required],
            nombre_contacto: [this.data ? this.data.nombre_contacto : null, Validators.required],
            cp: [this.data ? this.data.cp : null, Validators.required],
            apellido_contacto: [this.data ? this.data.apellido_contacto : null],
            telefono_contacto: new FormControl(this.data ? this.data.telefono_contacto : null, Validators.required),
            email_contacto: [this.data ? this.data.email_contacto : null],
            id_pais: new FormControl(this.data ? this.data.id_pais : null, Validators.required),
            id_provincia: new FormControl(this.data ? this.data.id_provincia : null, Validators.required),
            id_localidad: new FormControl(this.data ? this.data.id_localidad : null, Validators.required),
            tipo_direccion: new FormControl(this.data ? this.data.tipo_direccion : null, Validators.required),
            direccion_tipos: this.formBuilder.array([])
        });

        if(this.data && this.data.direccionTipos)
            for(let direccionTipo of this.data.direccionTipos)
                (<FormArray>this.direccionForm.controls['direccion_tipos']).push(this.formBuilder.group({name : [ direccionTipo.name ] }));

        this.direccionesService.getPaises().subscribe((data) => {
            this.paises = data;
            this.direccionForm.controls['id_pais'].valueChanges.subscribe((value) => {
                this.direccionesService.getProvincias(value).subscribe((data) => {
                    this.provincias = data;

                    if(this.data && this.data.id_provincia)
                        this.direccionForm.controls['id_provincia'].setValue(this.data.id_provincia);
                })
            });

            this.direccionForm.controls['id_provincia'].valueChanges.subscribe((value) => {
                this.direccionesService.getLocalidades(null, value).subscribe((data) => {
                    this.localidades = data;

                    if(this.data && this.data.id_localidad)
                        this.direccionForm.controls['id_localidad'].setValue(this.data.id_localidad);
                })
            });

            if(this.data && this.data.id_pais)
                this.direccionForm.controls['id_pais'].setValue(this.data.id_pais);
            else
                this.direccionForm.controls['id_pais'].setValue(1);
        });

        this.direccionesService.getTiposDireccion().subscribe((data) => {
            this.tiposDireccion = data;
        });

        this.direccionesService.getTiposVia().subscribe((data) => {
            this.tiposVia = data;
        });

        if(this.data.idColaborador)
            this.direccionForm.addControl('idColaborador', new FormControl(this.data.idColaborador, Validators.required));
    }

    ngOnInit(): void { }

    openSnackBar(message, action) {
        this._snackBar.open(message, action, {
            duration: 5000,
        });
    }

    addDireccionTipo() {
        (<FormArray>this.direccionForm.controls['direccion_tipos']).push(this.formBuilder.group({name : new FormControl(null, Validators.required)}));
    }

    removeDireccionTipo(index: number) {
        (<FormArray>this.direccionForm.controls['direccion_tipos']).removeAt(index);
    }

    submit() {
        this.disableBtnSave = true;
		this.loadingSave = true;

        console.log(this.direccionForm.value);
        if (this.isNewDireccion) {
            this.direccionesService.createDireccion(this.direccionForm.value).subscribe(
                (data: any) => {
                    if(data.success) {
                        this.openSnackBar("Datos guardados correctamente", "Cerrar");
                        this.dialogRef.close({ });
                        this.loadingSave = false;
                    }
                    else {
                        this.loadingSave = false;
                        this.disableBtnSave = false;
                        if(data.message) {
                            this.openSnackBar(data.message, "Cerrar");
                        }
                    }
                },
                (err) => {
                    this.loadingSave = false;
                    this.disableBtnSave = false;
                    this.openSnackBar(err, "Cerrar");
                }
            );
        } 
        else {
            this.direccionesService.updateDireccion(this.direccionForm.value, this.idDireccion).subscribe(
                (data: any) => {
                    if(data.success) {
                        this.openSnackBar("Datos actualizados correctamente", "Cerrar");
                        this.dialogRef.close({ });
                        this.loadingSave = false;
                    }
                    else {
                        this.loadingSave = false;
                        this.disableBtnSave = false;
                        if(data.message) {
                            this.openSnackBar(data.message, "Cerrar");
                        }
                    }
                },
                (err) => {
                    this.loadingSave = false;
                    this.disableBtnSave = false;
                    this.openSnackBar(err, "Cerrar");
                }
            );
        }
    }

}
