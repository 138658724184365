import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';


@Injectable({
	providedIn: 'root'
})
export class ExportService {
	EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	EXCEL_EXTENSION = '.xlsx';
	PDF_EXTENSION = '.pdf';

  	constructor() { }

	exportExcel(jsonData: any[], fileName: string): void {

		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
		const wb: XLSX.WorkBook = {
			Sheets: { 'Datos': ws },
			SheetNames: ['Datos']
		};
		const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

		this.saveExcelFile(excelBuffer, fileName);
	}

	private saveExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
		FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + this.EXCEL_EXTENSION);
	}

	exportPdf(datos: any, titulo: string) {
		let pdf = new jsPDF();

		pdf.setFontSize(18);
		pdf.text(titulo, 11, 14);

		pdf.setFontSize(12);
		pdf.setTextColor(100);

		(pdf as any).autoTable({
			head: datos.headers,
			body: datos.data,
			margin: { top: 22 },
			theme: 'striped'
		});

		window.open(URL.createObjectURL(pdf.output('blob')));
		// pdf.save(titulo + '_' + new Date().getTime() + this.PDF_EXTENSION);
	}

	exportPdfInventario(datos: any, titulo: string) {
		let pdf = new jsPDF();

		pdf.setFontSize(18);
		pdf.text(titulo, 11, 14);

		pdf.setFontSize(12);
		pdf.setTextColor(100);

		(pdf as any).autoTable({
			head: datos.headers,
			body: datos.data,
			bodyStyles: {rowHeight: 25},
			margin: { top: 22 },
			theme: 'striped',
			styles: {
				overflow: 'linebreak'
			},
			columnStyles: {5: {columnWidth: 25}},
			didDrawCell: function(data) {
				if (data.column.index === 5 && data.cell.section === 'body' && datos.data[data.row.index][data.column.index + 1]) {
					let imgBase64 = datos.data[data.row.index][data.column.index + 1];
					pdf.addImage(imgBase64, 'PNG', data.cell.x + 1, data.cell.y + 1, 20, 20);
				}
			}
		});

		window.open(URL.createObjectURL(pdf.output('blob')));
	}
}
