import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class DireccionesService {

  	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
	) { }
	
	getTiposDireccion() {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/direcciones/tipos-direccion';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposDireccion", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	getTiposDireccionColaborador(idColaborador) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/direcciones/tipos-direccion?idColaborador='+idColaborador;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposDireccion", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

    getTiposVia(idColaborador: number = null) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/direcciones/tipos-via' + (idColaborador?'?idColaborador='+idColaborador:'');
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposVia", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposVia", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	getPaises(filtro?) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			let url = environment.API_URL + '/direcciones/paises';
			if (filtro) {

				let params = {};
				params['filtro'] = filtro;

				url += '?' + this.helperSvc.serializeData(params);
			}

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPaises", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPaises", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	getProvincias(idPais?: number, filtro?: string) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let url = environment.API_URL + '/direcciones/provincias';

			if (idPais || filtro) {

				let params = {};
				if(idPais) {
					params['pais'] = idPais;
				}
				if(filtro) {
					params['filtro'] = filtro;
				}

				url += '?' + this.helperSvc.serializeData(params);
			}
	
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getProvincias", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getProvincias", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	getLocalidades(filtro: string, idProvincia?: any) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				filtro: filtro,
			}
			if (idProvincia) {
				params['provincia'] = idProvincia;
			}
	
			const url = environment.API_URL + '/direcciones/localidades?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getLocalidades", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getLocalidades", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	getDireccionesColaborador(idColaborador : number, filtros) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/direcciones/colaborador/' + idColaborador + '?' + this.helperSvc.serializeData(filtros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDireccionesColaborador", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDireccionesColaborador", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	deleteDireccion(idDireccion : number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/direcciones/' + idDireccion;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws deleteDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws deleteDireccion", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	createDireccion(formValues): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/direcciones/';
			this.http.post(url, formValues, options).subscribe(
				(response: any) => {
					console.log("ws crearDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearDireccion", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.message ) {
						msgError = err.error.message;
					}

					if(err.error.error_description) {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	updateDireccion(formValues, idDireccion): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/direcciones/' + idDireccion;
			this.http.put(url, formValues, options).subscribe(
				(response: any) => {
					console.log("ws crearDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearDireccion", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.message ) {
						msgError = err.error.message;
					}

					if(err.error.error_description) {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}


}
