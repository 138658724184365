<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 fxLayout="row" fxLayoutAlign="flex-start center" class="m-0 mb-8">
        <mat-icon class="mr-8">search</mat-icon>
        Buscar cliente
    </h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography" style="width: 80vw">
    <div fxLayout="column" >
        <form fxLayout="column" fxFlex="1" #mainForm="ngForm" [formGroup]="formBuscadorCliente" class="w-100-p">

            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column">
                    <h4 class="m-0 secondary-text font-weight-400">A continuación puede indicar los parámetros que necesite para buscar al cliente.</h4>
                    <h5 class="m-0 mb-4 secondary-text font-weight-600">Debe indicar al menos uno para poder realizar la búsqueda</h5>
                </div>

                <div fxLayout="row" fxLayoutAlign="flex-end center">
                    <button mat-button class="btn-aitana mr-16" [class.spinner]="loadingSearch" [disabled]="!formBuscadorCliente.valid"  (click)="buscarCliente()">Buscar</button>
                    <button mat-button mat-stroked-button class="btn-limpiar" (click)="resetForm()">Limpiar</button>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="center flex-start" class="mb-12">
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Tipo cliente</mat-label>
                    <mat-select name="tipo_cliente" formControlName="tipo_cliente" class="form-control">
                        <mat-option *ngFor="let item of tiposClienteData | async" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('tipo_cliente')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'tipo_cliente')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Nombre y apellidos</mat-label>
                    <input matInput name="nombreApellido" formControlName="nombreApellido" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>NIF / CIF</mat-label>
                    <input matInput name="cif_nif" formControlName="cif_nif" class="form-control" autocomplete="off">
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group  mr-16">
                    <mat-label>País</mat-label>
                    <mat-select name="id_pais" formControlName="id_pais" class="form-control">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="paisFilterCtrl"
                            placeholderLabel="País" noEntriesFoundLabel="No se encontró ningún valor"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of paisesFiltered | async" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('id_pais')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'id_pais')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Provincia</mat-label>
                    <mat-select name="id_provincia" formControlName="id_provincia" class="form-control">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="provinciaFilterCtrl"
                            placeholderLabel="Provincia" noEntriesFoundLabel="No se encontró ningún valor"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of provinciasFiltered | async" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('id_provincia')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'id_provincia')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>CP - Localidad</mat-label>
                    <mat-select name="id_localidad" formControlName="id_localidad" class="form-control">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="localidadFilterCtrl" [searching]="searchingLocalidades"
                            placeholderLabel="Localidad" noEntriesFoundLabel="No se encontro ningún valor"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of localidades" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('id_localidad')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'id_localidad')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Dirección</mat-label>
                    <input matInput name="direccion" formControlName="direccion" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>E-mail</mat-label>
                    <input matInput name="email" formControlName="email" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Teléfono</mat-label>
                    <input matInput name="telefono" formControlName="telefono" class="form-control" autocomplete="off">
                </mat-form-field>
            </div>

            <!-- <div fxLayout="row" fxLayoutAlign="flex-end center">
                <button mat-button class="btn-aitana mr-16" [class.spinner]="loadingSearch" [disabled]="!formBuscadorCliente.valid"  (click)="buscarCliente()">Buscar</button>
                <button mat-button mat-stroked-button class="btn-limpiar" (click)="resetForm()">Limpiar</button>
            </div> -->
            
        </form>

        <div *ngIf="clientesEncontrados?.length === 0" class="primary-50-bg bloq-info">
            <h3 class="secondary-text m-0">No se ha encontrado ningún cliente</h3>
        </div>
        
        <div *ngIf="clientesEncontrados?.length > 0" class="table-container table-clientes">
            <table mat-table [dataSource]="dataSourceClientes" class="w-100-p bloque-form">
                <ng-container matColumnDef="nombre">
                    <th mat-header-cell *matHeaderCellDef> Nombre </th>
                    <td mat-cell *matCellDef="let row">
                        {{ formatNombre(row) }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> E-mail </th>
                    <td mat-cell *matCellDef="let row">
                        {{ row.email_contacto && row.email_contacto !== '' ? row.email : (row.email_contacto2 && row.email_contacto2 !== '' ? row.email_contacto2 : ' - ') }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="direccion">
                    <th mat-header-cell *matHeaderCellDef> Dirección </th>
                    <td mat-cell *matCellDef="let row">
                        {{ formatDireccion(row) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <div fxLayout="row wrap" fxLayoutAlign="center center">
                            <button mat-icon-button matTooltip="Seleccionar cliente" (click)="seleccionarCliente(row)">
                                <mat-icon fontSet="fas" fontIcon="fa-user-check"></mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsClientes, sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsClientes;"></tr>
            </table>
        </div>

    </div>
</mat-dialog-content>