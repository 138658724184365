import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { GridConfigService } from 'app/services/grid/grid-config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogGuardarFiltrosGridComponent } from '../dialog-guardar-filtros-grid/dialog-guardar-filtros-grid.component';

@Component({
  selector: 'app-dialog-guardar-sorts-grid',
  templateUrl: './dialog-guardar-sorts-grid.component.html',
  styleUrls: ['./dialog-guardar-sorts-grid.component.scss']
})
export class DialogGuardarSortsGridComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	sort: any;
	columns: Array<any>;
	idUsuario: number;
	gridName: string;

	formSort: FormGroup;
	sortsUser: Array<any>;
	sortSelected: any;
	configSortSelected: any;

	displayedColumns: string[] = ['select', 'nombreSort', 'actions'];
	dataSource: MatTableDataSource<any>;
	selection: SelectionModel<any>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogGuardarFiltrosGridComponent>,
		private formBuilder: FormBuilder,
		private dialog: MatDialog, 
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private gridConfigSvc: GridConfigService,
	) {
		this._unsubscribeAll = new Subject();
		this.sortsUser = [];

		this.formSort = this.formBuilder.group({
			nombreSort: ['', Validators.required],
		});

		this.dataSource = new MatTableDataSource();
		this.selection = new SelectionModel(false, []);
		this.selection.changed.pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				console.log("item selected", this.selection.selected);
				if (this.selection.selected.length > 0) {
					this.sortSelected = this.selection.selected[0];
					this.formSort.get('nombreSort').setValue(this.sortSelected.name);
					this.getConfigSort();
				}
				else {
					this.sortSelected = null;
					this.configSortSelected = null;
				}
			}
		)
	}

	ngOnInit(): void {
		if (this.data.grid) {
			this.gridName = this.data.grid;
			this.idUsuario = this.data.idUser;
			this.columns = this.data.columns;
			this.sort = this.data.sort;

			this.loadMisSorts();
		}
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}

	getConfigSort() {
		this.configSortSelected = JSON.parse(this.sortSelected.data);
		console.log("config sort", this.configSortSelected);
		// console.log("filtros", this.filtros);
		// console.log("valueFilterSelected", valueFilterSelected);
		// for (const nameFilter in valueFilterSelected) {
		// 	const value = valueFilterSelected[nameFilter];
		// 	// console.log("value", value, nameFilter);
		// 	let dataFiltro = this.sorts.find((item) => { return item.name == nameFilter; });
		// 	// console.log("dataFiltro", dataFiltro);
		// 	if (dataFiltro) {
		// 		// console.log("asigno valor");
		// 		dataFiltro.value = value;
		// 		this.configSortSelected.push(dataFiltro);
		// 	}
		// 	// if (Object.prototype.hasOwnProperty.call(valueFilterSelected, nameFilter)) {
		// 	// }
		// }
		// console.log("configSortSelected", this.configSortSelected);
	}

	loadMisSorts() {
		this.gridConfigSvc.getSortsGrid(this.gridName).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data:any) => {
				this.sortsUser = data;
				this.dataSource.data = this.sortsUser;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	openConfirmDelete(event, orden: any): void {
		event.stopPropagation();
		
		const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: { 
				titulo: this.translateService.instant('COMUN.ELIMINAR_ORDEN'), 
				mensaje: this.translateService.instant('COMUN.ELIMINAR_ORDEN_MSG', {ordenName: orden.name}),
				btnLabelOk: this.translateService.instant('COMUN.SI'),
				btnLabelCancel: this.translateService.instant('COMUN.NO')
			},
			panelClass: 'panel-aitana',
			disableClose: true,
		});

		dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
			if (result === true) {
				this.deleteSort(orden);
			}
			else {
				console.log("Eliminacion cancelada");
			}
		});
	}

	deleteSort(sort: any): void {
		this.gridConfigSvc.borrarSort(sort.id).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				this.selection.clear();
				this.loadMisSorts();
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	guardarDatos() {
		const formValue = this.formSort.value;

		if (this.formSort.valid) {
			let idSort = this.sortSelected ? this.sortSelected.id : null;
			
			this.gridConfigSvc.guardarSort(this.gridName, formValue.nombreSort, this.sort, idSort).pipe(takeUntil(this._unsubscribeAll)).subscribe(
				(data: any) => {
					this.showSnack(this.translateService.instant('COMUN.ORDEN_GUARDADO'));
					this.dialogRef.close({ res: true });
				},
				(err) => {
					this.showSnack(err);
				}
			);
		}
		else {
			this.showSnack(this.translateService.instant('COMUN.ORDEN_GUARDADO_ERROR_NOMBRE'));
		}
	}

}
