import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  	constructor() { }

	getImageUser(filename: string) {
		if (filename) {
			return environment.API_URL + '/util/getImage/usuarios/' + filename;
		}
		else {
			return this.getImageNoUser();
		}
	}

	getImageNoUser() {
		return environment.API_URL + '/util/getImage/usuarios/no_user.png';
	}
}
