import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';

@Injectable({
  providedIn: 'root'
})
export class TimerTaskService {

	public currentStatus = new BehaviorSubject<string>('LOADING');

  	constructor(
		private http: HttpClient, 
		private authSvc: AuthService,
		private helperSvc: HelperService,
		private router: Router,
    	private activatedRoute: ActivatedRoute,
	) { }

    tasks(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};
            
			let url = environment.API_URL + '/task/user';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws tasks", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws tasks", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	event(taskId : number, eventType : string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				taskExtId: taskId,
				eventType: eventType
			}
            
			let url = environment.API_URL + '/task/event';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws event", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws event", err);
					let msgError = "Se ha producido un error cargando los datos";
					if(err.error.message)
						msgError = err.error.message;

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	changeSectionExternal(taskId : number, eventType : string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				taskExtId: taskId,
				eventType: eventType
			}
            
			let url = environment.API_URL + '/task/section';
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws changeSectionExternal", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws changeSectionExternal", err);
					let msgError = "Se ha producido un error cargando los datos";
					if(err.error.message)
						msgError = err.error.message;

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getAllPaginated(numPage: number, pageSize: number, sort : MatSort, filterForm: FormGroup): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			let params = {
                page: numPage,
				pageSize: pageSize,
				... filterForm.value,
			}

			if(sort && sort.active && sort.direction) {
				params['sortAttr'] = sort.active;
				params['sortDir'] = sort.direction;
			}

			this.router.navigate(
				[], 
				{
				  relativeTo: this.activatedRoute,
				  queryParams: params, 
				  queryParamsHandling: 'merge', // remove to replace all query params by provided
				});

			const url = environment.API_URL + '/task/report/time?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAllPaginated", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

}
