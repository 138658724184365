import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { takeUntil, take, finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimerTaskService } from 'app/services/timer-task/timer-task.service';
import { Block } from 'notiflix';


@Component({
  selector: 'timer-task-sidebar',
  templateUrl: './timer-task-sidebar.component.html',
  styleUrls: ['./timer-task-sidebar.component.scss']
})
export class TimerTaskSidebarComponent implements OnInit, AfterViewInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

    tasksInProgress = [];
    tasksPending = [];
    tasksBacklog = [];

    isLocalhost : boolean = false;

    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    private _chatViewScrollbar: FusePerfectScrollbarDirective;

	constructor(
		private translateService: TranslateService,
		private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private timerTaskSvc: TimerTaskService,
        private snackBar: MatSnackBar

	) {
		moment.locale(this.translateService.currentLang);
        this._unsubscribeAll = new Subject();

        const hostname = location.hostname;
        const port = location.port;
        this.isLocalhost = hostname === 'localhost' || hostname === '127.0.0.1' || port !== '';

	}

	ngOnInit(): void {
	}

    ngAfterViewInit(): void
    {
        this.loadTasks();
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'timer-task-sidebar';
        });
    }

	ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}

    toggleSidebarOpen(): void {
        this._fuseSidebarService.getSidebar('timerTask').toggleOpen();
    }

    getFirstCharacter(param : string) {
        return param.charAt(0);
    }

    initLoading(blockId, textoLoading?: string, type : 'standar' | 'dots' = 'standar', fontSize = '18px') {
        if(type == 'dots') {
            Block.dots(blockId, textoLoading ? textoLoading : '', {
                svgColor: '#666',
                messageColor: '#666',
                messageFontSize: fontSize
            });
        } else {
            Block.standard(blockId, textoLoading ? textoLoading : '', {
                svgColor: '#666',
                messageColor: '#666',
                messageFontSize: fontSize
            });
        }
	}

	stopLoading(blockId) {
		Block.remove(blockId);
	}

    loadTasks() {
        this.initLoading('#timer-task-sidebar', "Cargando tareas");
        this.timerTaskSvc.tasks().pipe(take(1)).subscribe((data) => {
            this.tasksInProgress = data.tasksInProgress;
            this.tasksPending = data.tasksPending;
            this.tasksBacklog = data.tasksBacklog;

            this.updateStatus();

            this.stopLoading('#timer-task-sidebar');
        });
    }

	playTask(task) {

        if(this.isLocalhost) {
            this.showSnack("No puedes empezar tarjetas en localhost");
            return;
        }

        let taskId = task['gid'];

        this.initLoading('#task-'+task.gid, '','dots');
        this.timerTaskSvc.event(taskId, 'START').pipe(take(1)).subscribe(
            (eventCreated) => {
                task['lastEvent'] = eventCreated;
                this.timerTaskSvc.currentStatus.next('START');

                let foundedTask = false;
                //Buscar en progreso
                for(let task of this.tasksInProgress) {
                    if(task.gid != taskId) {
                        //TODAS las tareas en progreso se paran
                        if(this.hasPauseButton(task)) {
                            this.timerTaskSvc.event(task.gid, 'PAUSE').pipe(take(1)).subscribe(
                                (eventCreated) => {
                                    task['lastEvent'] = eventCreated;
                                }
                            );
                        }
                        this.initLoading('#task-'+task.gid, '','dots');
                        this.timerTaskSvc.changeSectionExternal(task.gid, 'PAUSE').pipe(take(1),finalize(() => this.stopLoading('#task-'+task.gid))).subscribe(
                            (data) => {
                                //Eliminar de En Progreso
                                this.tasksInProgress.splice(this.tasksInProgress.findIndex((taskFind) => taskFind.gid == task.gid),1);
                                //Pasar a pendiente
                                this.tasksPending.push(task);
                            },
                            (error) => {
                                this.showSnack(error);
                            }
                        );

                    }else{
                        foundedTask = true;
                        this.stopLoading('#task-'+task.gid)
                    }
                }
    
                //Buscar en Pendiente
                if(!foundedTask) {
                    for(let task of this.tasksPending) {
                        if(task.gid == taskId) {
                            foundedTask = true;
            
                            this.timerTaskSvc.changeSectionExternal(task.gid, 'START').pipe(take(1),finalize(() => this.stopLoading('#task-'+task.gid))).subscribe(
                                (data) => {

                                    this.tasksPending.splice(this.tasksPending.findIndex((taskFind) => taskFind.gid == task.gid),1);
                                    this.tasksInProgress.push(task);

                                },
                                (error) => {
                                    this.showSnack(error);
                                }
                            );

                            break;
                        }
                    }
                }
    
                //Buscar en Backlog
                if(!foundedTask) {
                    for(let task of this.tasksBacklog) {
                        if(task.gid == taskId) {

                            this.timerTaskSvc.changeSectionExternal(task.gid, 'START').pipe(take(1),finalize(() => this.stopLoading('#task-'+task.gid))).subscribe(
                                (data) => {

                                    this.tasksBacklog.splice(this.tasksBacklog.findIndex((taskFind) => taskFind.gid == task.gid),1);
                                    this.tasksInProgress.push(task);

                                },
                                (error) => {
                                    this.showSnack(error);
                                }
                            );

                            break;
                        }
                    }
                }

            }, (error) => {
                this.showSnack(error);
            });	
	}

    pauseTask(task) {

        if(this.isLocalhost) {
            this.showSnack("No puedes pausar tarjetas en localhost");
            return;
        }

        let taskId = task['gid'];
        
        this.initLoading('#task-'+task.gid, '','dots');
        this.timerTaskSvc.event(taskId, 'PAUSE').pipe(take(1)).subscribe(
            (eventCreated) => {
                task['lastEvent'] = eventCreated;
                this.timerTaskSvc.currentStatus.next('PAUSE');

                let taskFound = this.tasksPending.find((task) => task.gid == taskId);
                if(!taskFound) {
                    //Buscar en progreso
                    for(let task of this.tasksInProgress) {
                        if(task.gid == taskId) {
                            
                            this.timerTaskSvc.changeSectionExternal(task.gid, 'PAUSE').pipe(take(1),finalize(() => this.stopLoading('#task-'+task.gid))).subscribe(
                                (data) => {
                                    this.tasksInProgress.splice(this.tasksInProgress.findIndex((taskFind) => taskFind.gid == task.gid),1);
                                    this.tasksPending.push(task);
                                },
                                (error) => {
                                    this.showSnack(error);
                                }
                            );

                            break;
                        }
                    }

                    //Buscar en Backlog
                    for(let task of this.tasksBacklog) {
                        if(task.gid == taskId) {
                            
                            this.timerTaskSvc.changeSectionExternal(task.gid, 'PAUSE').pipe(take(1)).subscribe(
                                (data) => {
                                    this.tasksBacklog.splice(this.tasksBacklog.findIndex((taskFind) => taskFind.gid == task.gid),1);
                                    this.tasksPending.push(task);
                                },
                                (error) => {
                                    this.showSnack(error);
                                },
                                () => this.stopLoading('#task-'+task.gid)
                            );

                            break;
                        }
                    }
                }
            }, (error) => {
                this.showSnack(error);
            });
    }

    stopTask(task) {

        if(this.isLocalhost) {
            this.showSnack("No puedes parar tarjetas en localhost");
            return;
        }

        let taskId = task['gid'];
        
        this.initLoading('#task-'+task.gid, '','dots');
        this.timerTaskSvc.event(taskId, 'STOP').pipe(take(1)).subscribe(
            (eventCreated) => {
                task['lastEvent'] = eventCreated;
                this.timerTaskSvc.currentStatus.next('STOP');

                //Buscamos en progreso
                for(let task of this.tasksInProgress) {
                    if(task.gid == taskId) {
                        
                        this.timerTaskSvc.changeSectionExternal(task.gid, 'STOP').pipe(take(1),finalize(() => this.stopLoading('#task-'+task.gid))).subscribe(
                            (data) => {
                                this.tasksInProgress.splice(this.tasksInProgress.findIndex((taskFind) => taskFind.gid == task.gid),1);
                            },
                            (error) => {
                                this.showSnack(error);
                            }
                        );

                        break;
                    }
                }

                //Buscar en Pendiente
                for(let task of this.tasksPending) {
                    if(task.gid == taskId) {
                        
                        this.timerTaskSvc.changeSectionExternal(task.gid, 'STOP').pipe(take(1),finalize(() => this.stopLoading('#task-'+task.gid))).subscribe(
                            (data) => {
                                this.tasksPending.splice(this.tasksPending.findIndex((taskFind) => taskFind.gid == task.gid),1);
                            },
                            (error) => {
                                this.showSnack(error);
                            }
                        );

                        break;
                    }
                }

                //Buscar en Backlock
                for(let task of this.tasksBacklog) {
                    if(task.gid == taskId) {

                        this.timerTaskSvc.changeSectionExternal(task.gid, 'STOP').pipe(take(1),finalize(() => this.stopLoading('#task-'+task.gid))).subscribe(
                            (data) => {
                                this.tasksBacklog.splice(this.tasksBacklog.findIndex((taskFind) => taskFind.gid == task.gid),1);
                            },
                            (error) => {
                                this.showSnack(error);
                            }
                        );

                        break;
                    }
                }

            }, (error) => {
                this.showSnack(error);
            });
    }

    updateStatus() {
        if(this.tasksInProgress.length > 0) {
            this.timerTaskSvc.currentStatus.next("START");
        } else if(this.tasksPending.length > 0 && this.tasksPending.findIndex((task) => task['lastEvent'] && task['lastEvent']['eventType'] == "PAUSE")) {
            this.timerTaskSvc.currentStatus.next("PAUSE");
        } else {
            this.timerTaskSvc.currentStatus.next("STOP");
        }
    }

    hasPlayButton(task) {
        return !task['lastEvent'] || task['lastEvent']['eventType'] != "START";
    }

    hasPauseButton(task) {
        return task['lastEvent'] && task['lastEvent']['eventType'] == "START";
    }

    hasStopButton(task) {
        return task['lastEvent'];
    }

}
