import { Component, OnInit, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';

//Traduccion
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { takeUntil } from 'rxjs/operators';
import { AiStatisticsService } from 'app/services/aistatistics/aistatistics.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { ProyectosService } from 'app/services/proyectos/proyectos.service';

@Component({
  selector: 'app-tabla-ai',
  templateUrl: './tabla-ai.component.html',
  styleUrls: ['./tabla-ai.component.scss']
})
export class TablaAiComponent implements OnInit {

  @Input('grafica') grafica: any;
  @Input('titulo') titulo: string = "Informe";
  @Input('height') height: string = "300px";
  @Input('width') width: string = "40vw";
  @Input('query') query: any = {};
  @Input('standalone') standalone: boolean = true;
  @Input('showAverage') showAverage: boolean = false;
  @Input('showLegend') showLegend: boolean = false;
  @Input('showBtnAcumulado') showBtnAcumulado: boolean = false;
  
    dataset: Array<any> = [];
    labels: Array<any> = [];
    optionsGrafica: any = null;
    desplegado: boolean = false;
    proyecto: any = 0;
    auxDataEstado1 :Array<any> = [];
    auxDataEstado2 :Array<any> = [];
    columnas:Array<any> = [];
    datos:Array<any> = [];
    displayedColumns: Array<any> = [];

    mostrarAcumulado: boolean = false;


    //Inicializamos elementos pagineo
    pagina:number = 0;
    pageSize:number = 20;
    totalElementos:number = 0;
    tieneMas:boolean = false;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    loading: boolean = false;


    //Elementos funcionalidad
    private _unsubscribeAll: Subject<any>; 

  constructor(
    private translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private aiStatisticService: AiStatisticsService,
        private proyectosSvc: ProyectosService
  ) {
    moment.locale(this.translateService.currentLang);
		this._unsubscribeAll = new Subject();
   }

  ngOnInit(): void {
    
      this.loadProyectos();

      this.proyectosSvc.onProjectSelected.pipe(takeUntil(this._unsubscribeAll)).subscribe(
          (data: any) => {
              if(data && data.projects) {
                  this.proyecto = data.projects.split(',')[0];
              }
              this.getDatos();
          }
      );
 
  }

  ngOnChanges() {
    if(!this.standalone) {
        this.getDatos();
    }
  }

  ngOnDestroy(): void {
     //Borramos cualquier suscripcion
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

    //Recogemos los datos de la gráfica
    getDatos(){
      this.loading = true;
      console.log("getDatos PRE", this.query, this.standalone);
      if(this.standalone) {
          this.query['proyecto'] = this.proyecto;
      }

      if(Object.keys(this.query).length > 0) {
          this.query['acumulado'] = this.mostrarAcumulado;
          this.aiStatisticService.filtrarMatriz().pipe(takeUntil(this._unsubscribeAll)).subscribe(
              (res:any) => {
               
                let columnasMostrar = res.map(item => item.estado1).filter((value, index, self) => self.indexOf(value) === index); //columnas sin duplicados
                columnasMostrar.unshift('columna1');
                
             
                this.columnas = columnasMostrar;                
                this.displayedColumns = columnasMostrar;

                console.log('Columnas',this.columnas);
                console.log('Diplayed Columns', this.displayedColumns);
                  this.auxDataEstado1 = res.map(item => item.estado1).filter((value, index, self) => self.indexOf(value) === index);
                  this.auxDataEstado2 = res.map(item => item.estado2).filter((value, index, self) => self.indexOf(value) === index);
                //  this.auxDataEstado1.unshift('');
                //  this.auxDataEstado2.unshift('');
                  // this.dataset = [{data: auxData, label: 'Total'}];

                  console.log('Estados1',this.auxDataEstado1);
                  console.log('Estados2',this.auxDataEstado2);

                  // for (const g1 of this.auxDataEstado1) {
                  
                  //     for( const g2 of this.auxDataEstado2){
                      
                  //         var combinacion = res.find(x => x.estado1 == g1 && x.estado2 == g2);
                  //         if(combinacion){

                  //           this.datos.push({estado1:g1,estado2:g2,tiempo:combinacion.tiempo});
                  //         }else{
                  //           this.datos.push({estado1:g1,estado2:g2,tiempo:0});
                  //         }
                      
                  //   }
                    
                  //   }

                    // for (const g2 of this.auxDataEstado2 ) {
                    //   let tiempo = [];
                    //   let estado1;
                    //     for( const g1 of this.auxDataEstado1){
                    //       estado1 = g1;
                           
                    //         var combinacion = res.find(x => x.estado1 == g1 && x.estado2 == g2);
                    //         if(combinacion){
                              
                    //           tiempo.push(Math.round(combinacion.tiempo));
                    //         }else{
                    //           tiempo.push(0);
                    //         }
                       
                            
                    //     }
                    //   this.datos.push({estado2:g2,tiempo});
                    //   }

                    for (const g2 of this.auxDataEstado2) {
                      let infoData = res.find((item) => {
                        if (item.estado2 == g2) {
                          return item;
                        }
                        });
                      this.datos.push({ estado2: g2, info: infoData });
                    }
                    console.log('DATOS', this.datos);

                //     this.totalElementos = res.pageData.totalElementos;
                // this.pageSize = res.pageData.pageSize;
                // this.pagina = res.pageData.numPage;
                // this.loading = false;

              }
          )
      }
  }
  pageChanged(event: PageEvent) {
		this.pagina = event.pageIndex;
		this.pageSize = event.pageSize;
		
        this.getDatos();
	}

  loadProyectos() {
    this.proyectosSvc.getProjectsUser().pipe(takeUntil(this._unsubscribeAll)).subscribe(
      (data: any) => {
        if(data && data.projects) {
            this.proyecto = data.projects.split(',')[0];
        }
        this.getDatos();
      },
      (err) => {

      }
    );
}

}
