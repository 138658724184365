import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-dialog-opcion',
    templateUrl: './dialog-opcion.component.html',
    styleUrls: ['./dialog-opcion.component.scss']
})
export class DialogOpcionComponent implements OnInit {
    formOption: FormGroup;

    nombreElemento: string = "";

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DialogOpcionComponent>, private formBuilder: FormBuilder) {
        this.formOption = this.formBuilder.group({
            nombre: ['', Validators.required]
        });
    }

    ngOnInit(): void {
        if (this.data.nombreElemento) {
            this.nombreElemento = this.data.nombreElemento;
        }
    }

    accept(): void {
        if(this.formOption.valid)
        this.dialogRef.close(this.formOption.value);
    }
    cancel(): void {
        this.dialogRef.close(false);
    }

}
