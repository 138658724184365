import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DbService } from '../../services/db/db.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-proyecto-dialog',
  templateUrl: './proyecto-dialog.component.html',
  styleUrls: ['./proyecto-dialog.component.scss']
})
export class ProyectoDialogComponent implements OnInit {

    closeDialog = new EventEmitter();

    proyectoForm: FormGroup;
    buttonsEnable: Boolean;

  constructor(private formBuilder: FormBuilder, private dbservice: DbService, private _snackBar: MatSnackBar) { 
    this.buttonsEnable = true;
    this.proyectoForm = this.formBuilder.group({
        nombre: [null, Validators.required]
      });
   }

  ngOnInit(): void {
  }

  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
        duration: 5000,
      });
  }

  submit() {
    this.buttonsEnable = false;
    if (!this.proyectoForm.valid) {
        this.buttonsEnable = true;
        return;
    }
    
    this.dbservice.newProyecto(this.proyectoForm.value).subscribe(data => {
        this.closeDialog.emit(); 
        this.openSnackBar('Proyecto creado', 'Cerrar');
    });

  //console.log(JSON.stringify(this.oportunidadForm.value));
}

}
