import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
	providedIn: 'root'
})
export class SeguimientosService {

	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
	) { }

	getTipos(devolucion?: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let url = environment.API_URL + '/seguimientos/tipos';
			if (devolucion) {
				const params = {
					devolucion: devolucion
				}

				url += '?' + this.helperSvc.serializeData(params);
			}

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposSeguimientos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposSeguimientos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getCierresPredefinidos(idTicket: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				idTicket: idTicket
			}

			let url = environment.API_URL + '/seguimientos/cierres-predefinidos?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getCierresPredefinidos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getCierresPredefinidos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getPartesTrabajo(idTicket: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				idTicket: idTicket
			}

			let url = environment.API_URL + '/seguimientos/partes-trabajo?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPartesTrabajo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPartesTrabajo", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	crearSeguimiento(dataSeguimiento:any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = dataSeguimiento;

			const url = environment.API_URL + '/seguimientos';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearSeguimiento", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearSeguimiento", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.error === 'telefono_no_valido') {
						msgError = err.error.error_description;
					}
					else if (err.error.error === 'email_no_valido') {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	modificarSeguimiento(idSeguimiento, dataSeguimiento: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = dataSeguimiento;

			const url = environment.API_URL + '/seguimientos/' + idSeguimiento;
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws modificarSeguimiento", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws modificarSeguimiento", err);
					let msgError = "Se ha producido un error guardando los datos";

					// if (err.error.error === 'tipo_direccion_exist') {
					// 	msgError = err.error.error_description;
					// }

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
}
