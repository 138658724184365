import { Component, OnDestroy, OnInit } from '@angular/core';
import { tick } from '@angular/core/testing';
import { FormGroup, FormBuilder, ValidationErrors, ValidatorFn, FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { AlmacenService } from 'app/services/almacen/almacen.service';
import { ClienteService } from 'app/services/cliente/cliente.service';
import { DispositivosService } from 'app/services/dispositivos/dispositivos.service';
import * as moment from 'moment';
import { ReplaySubject, Subject } from 'rxjs';

@Component({
	selector: 'app-buscador-avanzado-cliente',
	templateUrl: './buscador-avanzado-cliente.component.html',
	styleUrls: ['./buscador-avanzado-cliente.component.scss']
})
export class BuscadorAvanzadoClienteComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	formBuscador: FormGroup;
	loadingSearch: boolean;

	provincias: Array<any>;
	provinciaFilterCtrl: FormControl;
	provinciasFiltered: ReplaySubject<any[]>;
	localidades: Array<any>;
	searchingLocalidades: boolean = false;
	localidadFilterCtrl: FormControl;
	localidadSelected: any;
	marcas: Array<any>;
	marcaSelected: any;
	marcaFilterCtrl: FormControl;
	searchingMarcas: boolean = false;
	modelos: Array<any>;
	modeloSelected: any;
	modeloFilterCtrl: FormControl;
	searchingModelos: boolean = false;

	clientesEncontrados: Array<any>;
	displayedColumnsClientes: string[] = ['info_cliente', 'direcciones', 'actions'];
	dataSourceClientes: MatTableDataSource<any>;

	filtrosDisponibles: Array<any> = [
		{ type: 'text', name: 'garantia10', label: 'Nº de contrato' },
		{ type: 'text', name: 'telefono', label: 'Teléfono' },
		{ type: 'text', name: 'email', label: 'E-mail' },
		{ type: 'text', name: 'cif_nif', label: 'NIF / CIF' },
		{ type: 'text', name: 'nombre', label: 'Nombre' },
		{ type: 'text', name: 'apellidos', label: 'Apellidos' },
		{ type: 'text', name: 'direccion', label: 'Dirección' },
		{ type: 'select', name: 'id_provincia', label: 'Provincia' },
		{ type: 'select', name: 'id_localidad', label: 'CP - Localidad' },
	];
	filtrosActivos: Array<any>;

	constructor(
		private dialogRef: MatDialogRef<BuscadorAvanzadoClienteComponent>,
		private formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private clienteSvc: ClienteService,
	) {
		moment.locale(this.translateService.currentLang);
		this._unsubscribeAll = new Subject();

		this.formBuscador = this.formBuilder.group({
			cif_nif: [''],
			nombre: [''],
			apellidos: [''],
			telefono: [''],
			email: [''],
			direccion: [''],
			id_provincia: [''],
			id_localidad: [''],
			garantia10: [''],
			conGarantia10: [1],
		}, { validators: this.atLeastOneValidator });

		this.filtrosActivos = [
			{ type: 'text', name: 'garantia10', label: 'Nº de contrato' },
			{ type: 'text', name: 'telefono', label: 'Teléfono' },
			{ type: 'text', name: 'email', label: 'E-mail' },
		];

		this.provincias = [];
		this.provinciaFilterCtrl = new FormControl();
		this.provinciasFiltered = new ReplaySubject<any[]>(1);
		this.localidades = [];
		this.localidadFilterCtrl = new FormControl();

		this.loadingSearch = false;
		this.dataSourceClientes = new MatTableDataSource();
	}

	public atLeastOneValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
		let controls = control.controls;
		// console.log(controls);
		if (controls) {
			let theOne = Object.keys(controls).findIndex(key => key !== 'conGarantia10' && controls[key].value !== '');
			if (theOne === -1) {
				return {
					atLeastOneRequired: { text: 'Para realizar la búsqueda debe indicar al menos un parámetro' }
				}
			}
		};
	}

	ngOnInit(): void {
		this.loadProvincias();
		this.loadEventosCombos();
		this.loadFiltrosCombos();
	}

	loadEventosCombos() {
		let provinciaCtrl = this.formBuscador.get('id_provincia');
		let localidadCtrl = this.formBuscador.get('id_localidad');
		let marcaCtrl = this.formBuscador.get('marca');
		let modeloCtrl = this.formBuscador.get('modelo');
		
		provinciaCtrl.valueChanges.subscribe((idProvincia) => {
			localidadCtrl.setValue("");
			this.localidadSelected = null;
			this.localidades = [];
		});

		localidadCtrl.valueChanges.subscribe((idLocalidad) => {
			const localidad = this.localidades.find((item) => {
				return item.id === idLocalidad;
			});
			this.localidadSelected = localidad;
		});
	}

	loadFiltrosCombos() {
		this.provinciaFilterCtrl.valueChanges.subscribe((filterValue) => {
			if (!filterValue) {
				this.provinciasFiltered.next(this.provincias.slice());
				return;
			}

			this.provinciasFiltered.next(
				this.provincias.filter(item => {
					return item.name.toLowerCase().includes(filterValue.toLowerCase());
				})
			);
		});
		this.localidadFilterCtrl.valueChanges.subscribe((filterValue) => {
			if (filterValue.length > 0) {
				this.searchingLocalidades = true;
				this.loadLocalidades(filterValue);
			}
			else {
				this.localidades = [];
				if (this.localidadSelected) {
					this.localidades.push(this.localidadSelected);
				}
			}
		});
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 1000
		});
	}

	isFiltroActivo(itemFiltro: any) {
		let activo = false;
		const filtroActivo = this.filtrosActivos.find((item) => { return item.name === itemFiltro.name; });
		if (filtroActivo) {
			activo = true;
		}
		return activo;
	}

	toogleFiltro(itemFiltro: any) {
		const indexFiltro = this.filtrosActivos.findIndex((item) => { return item.name === itemFiltro.name; });
		if (indexFiltro === -1) {
			this.filtrosActivos.push(itemFiltro);
		}
		else {
			this.filtrosActivos.splice(indexFiltro, 1);
			this.clearSelect(null, itemFiltro.name);
		}
	}

	loadProvincias() {
		this.clienteSvc.getProvincias().subscribe(
			(data: any) => {
				this.provincias = data.map(item => {
					return { id: item.idprovincia, name: item.provincia };
				});
				this.provinciasFiltered.next(this.provincias.slice());
			},
			(err) => {
				this.showSnack(err);
			}
		);
	}

	loadLocalidades(filter?: string): void {
		let formControl = this.formBuscador.get('id_provincia');

		this.clienteSvc.getLocalidades(filter, formControl.value).subscribe(
			(data:any) => {
				this.searchingLocalidades = false;
				this.localidades = data.map(item => {
					return { id: item.idpoblacion, name: item.postal + ' - ' + item.poblacion };
				});
			},
			(err) => {
				this.searchingLocalidades = false;
				this.showSnack(err);
			}
		);
	}

	checkValue(formControlName: string) {
		let formControl = this.formBuscador.get(formControlName);
		if (formControl) {
			return formControl.value !== "";
		}
		else {
			return false;
		}
	}

	clearSelect(event, formControlName: string) {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		let formControl = this.formBuscador.get(formControlName);
		if (formControl) {
			formControl.setValue('');

			if (formControlName === 'id_localidad') {
				this.localidadSelected = null;
				this.localidades = [];
			}
		}
	}

	resetForm() {
		const controls = this.formBuscador.controls;
		const keyControls = Object.keys(controls);

		for (const key of keyControls) {
			if (key !== 'conGarantia10') {
				controls[key].setValue('', {emitEvent: false});
			}

			if (key === 'id_localidad') {
				this.localidadSelected = null;
				this.localidades = [];
			}
		}

		this.clientesEncontrados = null;
	}

	contratosCliente(cliente: any) {
		const listContratos = cliente.garantia10.map((item) => { return item.garantia10; });
		const cadContratos = listContratos.join(", ");
		return cadContratos;
	}

	formatDireccion(dataDireccion: any) {
		let direccionFormat = "";

		if (dataDireccion.direccion !== "") {
			direccionFormat += dataDireccion.direccion;
		}
		if (dataDireccion.num_casa && dataDireccion.num_casa !== "") {
			direccionFormat = direccionFormat !== "" ? direccionFormat + ", " : direccionFormat;
			direccionFormat += dataDireccion.num_casa;
		}
		if (dataDireccion.id_localidad) {
			direccionFormat = direccionFormat !== "" ? direccionFormat + ", " : direccionFormat;
			direccionFormat += "CP: " + dataDireccion.localidad.postal + ", " + dataDireccion.localidad.poblacion;
		}
		if (dataDireccion.id_provincia) {
			direccionFormat = direccionFormat !== "" ? direccionFormat + ", " : direccionFormat;
			direccionFormat += dataDireccion.provincia.provincia;
			
			if (dataDireccion.id_pais) {
				direccionFormat +=  " (" + dataDireccion.provincia.pais.pais + ")";
			}
		}

		return direccionFormat;
	}

	buscarCliente() {
		const formValue = this.formBuscador.value;
		// console.log("form value", formValue);
		this.loadingSearch = true;
		this.clienteSvc.buscarClientes(formValue).subscribe(
			(data: any) => {
				this.loadingSearch = false;
				this.clientesEncontrados = data.elements;
				this.dataSourceClientes.data = this.clientesEncontrados.slice();
			},
			(err) => {
				this.loadingSearch = false;
				this.showSnack(err);
			}
		)
	}

	seleccionarCliente(cliente: any) {
		this.dialogRef.close(cliente);
	}

}
