<div class="page-layout simple fullwidth px-12 pt-12">

    <div fxLayout="row" class="p-12">
        <div fxLayout="column">
            <h3 class="m-0 font-size-24 mr-16">{{titulo}}</h3>
        </div>
        <div class="acciones">
            <button mat-button mat-stroked-button [matMenuTriggerFor]="exportInformeMenu">
                Exportar
                <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
            </button>

            <mat-menu #exportInformeMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item fxLayout="row" fxLayoutAlign="flex-start center" (click)="exportData()">
                    <fa-icon class="secondary-text mr-12 font-size-18" icon="file-excel"></fa-icon>
                    <span>Exportar</span>
                </button>
            </mat-menu>
        </div>
    </div>

    <div *ngIf="standalone" fxLayout="row" fxLayoutAlign="space-between center" class="w-100-p">
        <div fxLayout="row wrap" fxLayoutAlign="flex-start center" class="p-12 bloque-filtros">
            <mat-chip-list *ngIf="isFiltrosActivos()" class="mr-16 mb-12">
                <ng-container *ngFor="let itemFiltro of filtros">
                    <mat-chip *ngIf="itemFiltro.active" [removable]="true" (removed)="quitarFiltro(itemFiltro)" (click)="dialogFiltro(itemFiltro)">
                        {{ (itemFiltro.label | translate) + ': ' + limitSizeMatChip(itemFiltro) }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </ng-container>
            </mat-chip-list>

            <button mat-button mat-stroked-button class="mr-16 btn-mas-filtro mb-12" [matMenuTriggerFor]="availableFilters">
                <mat-icon fontSet="fas" fontIcon="fa-plus-circle" class="mr-8"></mat-icon>
                {{ 'COMUN.ANADIR_FILTRO' | translate }}
            </button>

            <button mat-button mat-stroked-button *ngIf="isFiltrosActivos()" class="mr-16 mb-12" (click)="restablecerFiltros()">{{ 'COMUN.RESTABLECER' | translate }}</button>
            
            <button mat-button mat-stroked-button class="mr-16 mb-12 btn-buscar" (click)="filtrar()">
                <mat-icon fontSet="fas" fontIcon="fa-search" class="mr-8"></mat-icon> {{ 'COMUN.BUSCAR' | translate }}
            </button>

            <mat-menu #availableFilters="matMenu" [overlapTrigger]="false" class="pb-0">
                <button *ngFor="let item of filtros" mat-menu-item (click)="dialogFiltro(item)">
                    <fa-icon *ngIf="item.active == true" class="secondary-text mr-8" [icon]="['fas', 'check-square']"></fa-icon>
                    <fa-icon *ngIf="item.active == false" class="secondary-text mr-8" [icon]="['far', 'square']"></fa-icon>
                    <span>{{ item.label | translate }}</span>
                </button>
            </mat-menu>
        </div>
    </div>

    <div class="wrap-table" fxLayout="column" fxFlex="1 0 auto">
        <div class="table-container" fxFlex="1 0 auto" [style.height]="height">
            <table mat-table [dataSource]="datos" class="w-100-p">
                <ng-container *ngFor="let col of columnas" [matColumnDef]="col.id">
                    <th mat-header-cell *matHeaderCellDef> {{col.campo}} </th>
                    <td mat-cell *matCellDef="let row">
                        {{col.tipo == 'Date'?formatDate(row[col.id]):(col.tipo == 'Datetime'?formatDate(row[col.id], true):row[col.id])}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No se encontraron resultados</td>
                </tr>
            </table>
        </div>

        <mat-paginator [pageSizeOptions]="[10,20,30,50,100,300]" [length]="totalElementos" [pageIndex]="0" [pageSize]="pageSize" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
    </div>

</div>

