import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';

//Traduccion
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { takeUntil } from 'rxjs/operators';
import { AiStatisticsService } from 'app/services/aistatistics/aistatistics.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { ProyectosService } from 'app/services/proyectos/proyectos.service';

@Component({
    selector: 'app-matriz-ai',
    templateUrl: './matriz-ai.component.html',
    styleUrls: ['./matriz-ai.component.scss']
})
export class MatrizAiComponent implements OnInit {
    @Input('grafica') grafica: any;
    @Input('titulo') titulo: string = "Informe";
    @Input('height') height: string = "300px";
    @Input('width') width: string = "40vw";
    @Input('query') query: any = {};
    @Input('standalone') standalone: boolean = true;
    @Input('showAverage') showAverage: boolean = false;
    @Input('showLegend') showLegend: boolean = false;
    @Input('showBtnAcumulado') showBtnAcumulado: boolean = false;
    @Input('agrupaciones') agrupaciones: Array<any>;

    @Output() queryCambiada = new EventEmitter<any>();

    dataset: Array<any> = [];
    labels: Array<any> = [];
    optionsGrafica: any = null;
    desplegado: boolean = false;
    proyecto: any = 0;
    
    loading: boolean = false;
    mostrarAcumulado: boolean = false;

    //Elementos funcionalidad
    private _unsubscribeAll: Subject<any>;

    constructor(
        private translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private aiStatisticService: AiStatisticsService,
        private proyectosSvc: ProyectosService
    ) {
        moment.locale(this.translateService.currentLang);
		this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        if(this.standalone) {
            this.loadProyectos();

            this.proyectosSvc.onProjectSelected.pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (data: any) => {
                    if(data && data.projects) {
                        this.proyecto = data.projects.split(',')[0];
                    }
                    this.getDatos();
                }
            );
        }
    }

    ngOnChanges() {
        if(!this.standalone) {
            this.getDatos();
        }
    }

    ngOnDestroy(): void {
        //Borramos cualquier suscripcion
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

    //Recogemos los datos de la gráfica
    getDatos(){
        this.loading = true;
        console.log("getDatos PRE", this.query, this.standalone);
        if(this.standalone) {
            this.query['proyecto'] = this.proyecto;
        }

        if(Object.keys(this.query).length > 0) {
            this.query['acumulado'] = this.mostrarAcumulado;
            this.aiStatisticService.filtrarGrafica(this.grafica, this.query, null).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (res:any) => {
                    let auxData = res.datasets.map((item) => item.kpis.total);
                    this.dataset = [{data: auxData, label: 'Total'}];
                    if(res.formato == 'acumulado_agrupado') {
                        this.dataset = auxData;
                    }
                    if(this.showAverage) {
                        let average = (auxData.reduce((a, b) => a + b, 0) / auxData.length) || 0;
                        let averageArray = [];
                        auxData.forEach(element => { averageArray.push(average); });

                        let averageSet = {
                            type: 'line',
                            borderColor: 'red',
                            borderWidth: 2,
                            fill: false,
                            data: averageArray,
                            pointRadius: 0,
                            label: 'Media'
                        }
                        this.dataset.push(averageSet);
                    }

                    this.labels = res.datasets.map((item) => item.label);

                    this.optionsGrafica = {
                        chartType: 'bar',
                        datasets: this.dataset,
                        labels: this.labels,
                        colors   : [
                            {
                                borderColor              : '#3949ab',
                                backgroundColor          : '#3949ab',
                                pointBackgroundColor     : '#3949ab',
                                pointHoverBackgroundColor: '#3949ab',
                                pointBorderColor         : '#ffffff',
                                pointHoverBorderColor    : '#ffffff'
                            }
                        ],
                        options  : {
                            spanGaps           : false,
                            legend: {
                                labels: {
                                    generateLabels: (chart) => {
                                        return chart.data.labels.map(
                                            (label, index) => ({
                                                strokeStyle: '#3949ab',
                                                fillStyle: '#3949ab',
                                                text: label + (auxData[index] ? ' (' + auxData[index] + ')' : '')
                                            })
                                        )
                                    }
                                },
                                onHover: function(e) {
                                e.target.style.cursor = 'pointer';
                                }
                            },
                            hover: {
                                onHover: function(e) {
                                var point = this.getElementAtEvent(e);
                                if (point.length) e.target.style.cursor = 'pointer';
                                else e.target.style.cursor = 'default';
                                }
                            },
                            maintainAspectRatio: false,
                            tooltips           : {
                                position : 'nearest',
                                mode     : 'index',
                                intersect: false
                            },
                            layout             : {
                                padding: {
                                    left : 0,
                                    right: 0
                                }
                            },
                            elements           : {
                                point: {
                                    radius          : 4,
                                    borderWidth     : 2,
                                    hoverRadius     : 4,
                                    hoverBorderWidth: 2
                                }
                            },
                            scales             : {
                                xAxes: [
                                    {
                                        gridLines: {
                                            display: false
                                        },
                                        ticks    : {
                                            fontColor: 'rgba(0,0,0,0.54)'
                                        },
                                        stacked: true
                                    }
                                ],
                                yAxes: [
                                    {
                                        gridLines: {
                                            tickMarkLength: 16
                                        },
                                        ticks    : {
                                            stepSize: 20
                                        },
                                        stacked: true
                                    }
                                ]
                            },
                            plugins: {
                                filler: {
                                    propagate: false
                                }
                            },
                            onClick : (evt, array) => {
                                if(array[0] != undefined && array[0]._index != undefined && this.labels[array[0]._index] != undefined) {
                                    if(this.agrupaciones) {
                                        if(this.query['groupBy']) {
                                            let previoGroupBy = this.query['groupBy'];

                                            this.query[previoGroupBy] = this.labels[array[0]._index];
                                            let agrupacionesCopia = this.agrupaciones.slice();
                                            let itemAgrupacion = agrupacionesCopia.find((item) => { return item.id == previoGroupBy; });
                                            let index = agrupacionesCopia.indexOf(itemAgrupacion);
                                            agrupacionesCopia.splice(index, 1);
                                            let nextGroupBy = agrupacionesCopia[index] ? agrupacionesCopia[index].id : null;
                                            if(nextGroupBy) {
                                                this.query['groupBy'] = nextGroupBy;
                                                this.getDatos();

                                                let emitir = {};
                                                emitir[previoGroupBy] = this.labels[array[0]._index];
                                                emitir['groupBy'] = nextGroupBy;

                                                this.queryCambiada.emit(emitir);
                                            }
                                        }
                                    }
                                    else {
                                        this.desplegar(this.labels[array[0]._index]);
                                    }
                                }
                            }
                        }
                    }

                    this.loading = false;
                }
            )
        }
    }


    /////////// Bloque despliegue
    columnas:Array<any> = [];
    datos:Array<any> = [];
    displayedColumns: Array<any> = [];
    pagina:number = 0;
    pageSize:number = 20;
    totalElementos:number = 0;
    tieneMas:boolean = false;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    auxGrupo: any = null;

    desplegar(group){
        this.loading = true;
        this.query['label'] = group;
        console.log("desplegar PRE", this.query, this.standalone);
        if(this.standalone) {
            this.query['proyecto'] = this.proyecto;
        }

        this.aiStatisticService.filtrarInforme(this.grafica, this.pagina, this.pageSize, this.query).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(res:any) => {
                this.auxGrupo = group;
                this.columnas = res.columns;
                this.displayedColumns = res.columns.map((item) => { return item.id; });
                this.datos = res.data;
                this.totalElementos = res.pageData.totalElementos;
                this.desplegado = true;
                this.loading = false;
			}
		)
    }

    pageChanged(event: PageEvent) {
		this.pagina = event.pageIndex;
		this.pageSize = event.pageSize;
		
        this.desplegar(this.auxGrupo);
	}

    volver(){
        this.pagina = 0;
        this.pageSize = 20;
        this.desplegado = false;
    }

    formatDate(fecha: string, time: boolean = false){
        let result = "";
        if(fecha != ""){
            if(time){
                result = moment(fecha).format('DD/MM/YYYY HH:mm');
            }else{
                result = moment(fecha).format('DD/MM/YYYY');
            }
        }
        return result;
    }

    loadProyectos() {
        this.proyectosSvc.getProjectsUser().pipe(takeUntil(this._unsubscribeAll)).subscribe(
          (data: any) => {
            if(data && data.projects) {
                this.proyecto = data.projects.split(',')[0];
            }
            this.getDatos();
          },
          (err) => {

          }
        );
    }

    showAcumulado() {
        this.mostrarAcumulado = !this.mostrarAcumulado;
        this.getDatos();
    }

}
