<div class="header mat-elevation-z4 accent-400" fxLayout="row" fxLayoutAlign="space-between center">

    <div class="title ml-16" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="fuse-white-fg">av_timer</mat-icon>
        <h3 class="ml-12 fuse-white-fg">Tus tareas</h3>
    </div>

    <div class="actions">
        <button mat-icon-button matTooltip="Estadísticas" class="toggle-sidebar-open mr-16" [routerLink]="['/timer-task']">
            <mat-icon class="secondary-text">assessment</mat-icon>
        </button>

        <button mat-icon-button matTooltip="Recargar tareas" class="toggle-sidebar-open mr-16" (click)="loadTasks()">
            <mat-icon class="secondary-text">refresh</mat-icon>
        </button>
        
        <button mat-icon-button class="toggle-sidebar-open mr-8" (click)="toggleSidebarOpen();">
            <mat-icon class="secondary-text">close</mat-icon>
        </button>
    </div>
</div>

<div fxLayout="row" class="content">
    <div id="timer-task-sidebar" fxFlex="1 1 auto" fusePerfectScrollbar>

        <mat-list>
            <h3 matSubheader>
                <span>En progreso:</span>
            </h3>
            <mat-divider></mat-divider>
            <ng-container *ngIf="tasksInProgress.length == 0">
                <div fxLayout="row" fxLayoutAlign="center center" class="list-item">
                    <small class="secondary-text">¡No tienes ninguna tarea comenzada!</small>
                </div>
            </ng-container>
            <ng-container *ngFor="let task of tasksInProgress">
                <mat-list-item id="task-{{task.gid}}">
                    <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="row" class="w-80-p">
                            <div fxLayout="row" fxLayoutAlign="center center" class="pr-8">
                                <div fxLayout="row" class="w-40 h-40 {{task.project.color}} icon-project" matTooltip="Proyecto {{task.project.name}}" fxLayoutAlign="center center">
                                   <span>{{getFirstCharacter(task.project.name)}}</span>
                                </div>
                            </div>
                            <div fxLayout="column">
                                <span matListItemTitle>{{task.name}}</span>
                                <small><a target="_blank" [href]="task.link">Ver más detalles</a></small>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end center" class="w-20-p">
                            <button *ngIf="hasPlayButton(task)" mat-icon-button (click)="playTask(task)">
                                <mat-icon fontSet="fas" fontIcon="fa-play"></mat-icon>
                            </button>
                            <button *ngIf="hasPauseButton(task)" mat-icon-button (click)="pauseTask(task)">
                                <mat-icon fontSet="fas" fontIcon="fa-pause"></mat-icon>
                            </button>
                            <button *ngIf="hasStopButton(task)" mat-icon-button (click)="stopTask(task)">
                                <mat-icon fontSet="fas" fontIcon="fa-stop"></mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-list>
    
        <mat-list>
            <h3 matSubheader>
                <span>Pendientes:</span>
            </h3>
            <mat-divider></mat-divider>
            <ng-container *ngIf="tasksPending.length == 0">
                <div fxLayout="row" fxLayoutAlign="center center" class="list-item">
                    <small class="secondary-text">No hay ninguna tarea en este estado</small>
                </div>
            </ng-container>
            <ng-container *ngFor="let task of tasksPending">
                <mat-list-item id="task-{{task.gid}}">
                    <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="row" class="w-80-p">
                            <div fxLayout="row" fxLayoutAlign="center center" class="pr-8">
                                <div fxLayout="row" class="w-40 h-40 {{task.project.color}} icon-project" matTooltip="Proyecto {{task.project.name}}" fxLayoutAlign="center center">
                                   <span>{{getFirstCharacter(task.project.name)}}</span>
                                </div>
                            </div>
                            <div fxLayout="column">
                                <span matListItemTitle>{{task.name}}</span>
                                <small><a target="_blank" [href]="task.link">Ver más detalles</a></small>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end center" class="w-20-p">
                            <button *ngIf="hasPlayButton(task)" mat-icon-button (click)="playTask(task)">
                                <mat-icon fontSet="fas" fontIcon="fa-play"></mat-icon>
                            </button>
                            <button *ngIf="hasPauseButton(task)" mat-icon-button (click)="pauseTask(task)">
                                <mat-icon fontSet="fas" fontIcon="fa-pause"></mat-icon>
                            </button>
                            <button *ngIf="hasStopButton(task)" mat-icon-button (click)="stopTask(task)">
                                <mat-icon fontSet="fas" fontIcon="fa-stop"></mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-list>
    
        <mat-list>
            <h3 matSubheader>
                <span>Backlog:</span>
            </h3>
            <mat-divider></mat-divider>
            <ng-container *ngIf="tasksBacklog.length == 0">
                <div fxLayout="row" fxLayoutAlign="center center" class="list-item">
                    <small class="secondary-text">No hay ninguna tarea en este estado</small>
                </div>
            </ng-container>
            <ng-container *ngFor="let task of tasksBacklog">
                <mat-list-item id="task-{{task.gid}}">
                    <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="row" class="w-80-p">
                            <div fxLayout="row" fxLayoutAlign="center center" class="pr-8">
                                <div fxLayout="row" class="w-40 h-40 {{task.project.color}} icon-project" matTooltip="Proyecto {{task.project.name}}" fxLayoutAlign="center center">
                                   <span>{{getFirstCharacter(task.project.name)}}</span>
                                </div>
                            </div>
                            <div fxLayout="column">
                                <span matListItemTitle>{{task.name}}</span>
                                <small><a target="_blank" [href]="task.link">Ver más detalles</a></small>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end center" class="w-20-p">
                            <button *ngIf="hasPlayButton(task)" mat-icon-button (click)="playTask(task)">
                                <mat-icon fontSet="fas" fontIcon="fa-play"></mat-icon>
                            </button>
                            <button *ngIf="hasStopButton(task)" mat-icon-button (click)="stopTask(task)">
                                <mat-icon fontSet="fas" fontIcon="fa-stop"></mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-list>

    </div>
</div>