import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

import { ProyectosService } from 'app/services/proyectos/proyectos.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit {

	url: SafeResourceUrl;
	urlParam : string;
	htmlIframe: SafeHtml;
	loadingIframe: boolean;

	@ViewChild('iframeAitana') iframe: ElementRef;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private sanitizer: DomSanitizer,
		private proyectosSvc: ProyectosService
	) {

		this.route.queryParams.subscribe(
			(params) => {
				// console.log('route params', params);
				this.urlParam = environment.linkNewAitana + "/site/index";
				if (params.url) {
					this.urlParam = params.url;
				}
				
				console.log("url iframe", this.urlParam);
				this.loadingIframe = true;
				setTimeout(() => {
					this.url = sanitizer.bypassSecurityTrustResourceUrl(this.urlParam);
				}, 400);
			}
		);
	}

	ngOnInit(): void {
		this.proyectosSvc.onProjectSelected.subscribe(
			(data: any) => {
				this.reloadIframe();
			}
		);
	}

	reloadIframe() {
		console.log("reloadIframe", this.urlParam);
		this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.linkNewAitana + "/site/load-project-sesion");
		setTimeout(() => {
			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlParam);
		}, 500);
	}

}
