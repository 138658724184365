import { Component, OnInit, EventEmitter, Inject, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { TicketsService } from 'app/services/ticket/tickets.service';
import { UserRoleService } from 'app/services/user/user-role.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-form-personalizado',
  templateUrl: './dialog-form-personalizado.component.html',
  styleUrls: ['./dialog-form-personalizado.component.scss']
})
export class DialogFormPersonalizadoComponent implements OnInit {

  closeDialog = new EventEmitter();
  private _unsubscribeAll: Subject<any>;
 // dataSource: MatTableDataSource<any>;
  formCampos: FormGroup;
  campos: any;
  valoresCheck: any = {};
  camposGroup: any = null;
  idTicket: number;
  ticket: any = null;
  formulario: any = null;
  contrato: any = null;
  sinCampos: boolean = false;
  guardando: boolean = false;
  nombreFormulario: string = "";
  paramsUrl: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogFormPersonalizadoComponent>,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private ticketsSvc: TicketsService,
    private userRoleSvc: UserRoleService,
    private snackBar: MatSnackBar,
   
    private _changeDetectorRef: ChangeDetectorRef) {

    this._unsubscribeAll = new Subject();
    moment.locale(this.translateService.currentLang);
    this.idTicket = this.data.ticket.ID;
    this.ticket = this.data.ticket;    
    this.formulario = this.data.formulario;

   
 
       


       
    }

  ngOnInit(): void {
    if(this.data.formulario){
      this.loadNombreFormulario(this.data.formulario);
    }
    if (this.data.ticket) {      
      this.loadFormularioPersonalizado(this.data.ticket, this.data.formulario)
    } else {
      this.dialog.closeAll();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
}

  showSnack(mensaje: string) {
    this.snackBar.open(mensaje, null, {
        duration: 2000
    });
  }

  loadFormularioPersonalizado(ticket: any, formulario:any) {
    this.ticketsSvc.getFormularioPersonalizadoProyecto(ticket.ID,formulario).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        (data: any) => {
          console.log('Formulario 1', data);
           let formControls = {};
           this.formCampos = this.formBuilder.group({});
            if (data.campos && data.campos.length > 0) {
                for(var i = 0; i<data.campos.length; i++){
                    const campos = data.campos[i];
                    this.camposGroup = campos.ID;

                  //  for (var i = 0; i < campos.camposContrato.length; i++) {
                        let response = campos.response ? campos.response.value : '';
                       
                        if (campos.options) {
                            campos.options = JSON.parse(campos.options);
                        }

                        if (campos.type_field == 'checklist') {
                            campos.name = campos.name;
                            response = response.split(',');
                            this.valoresCheck[campos.name] = response;
                        }

                        formControls[campos.name] = [response];

                  //  }
                }
            } else {
                this.sinCampos = true;
                this.camposGroup = null;
            }

            this.campos = data.campos;

            this.formCampos = this.formBuilder.group(formControls);
            console.log('Campos Formulario', this.formCampos);
        },
        (err) => {
            this.showSnack(err);
        }
    );
}

loadNombreFormulario(formulario:any){
  this.ticketsSvc.getDataFormulario(formulario).pipe(takeUntil(this._unsubscribeAll)).subscribe(
      (data: any) => {
     
        this.nombreFormulario = data.formulario.name?data.formulario.name:'';

      },
      (err) => {
          this.showSnack(err);
      }
  );
}

guardarDatos() {
    this.guardando = true;
    const formValue = this.formCampos.value;

    console.log('Datos Formulario', formValue);
    if (this.formCampos.valid) {
      console.log('Datos Validados');
        this.ticketsSvc.guardarCamposFormularioPersonalizadoProyecto(formValue, this.idTicket, this.data.formulario).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (data: any) => {
                this.showSnack(this.translateService.instant('COMUN.DATOS_GUARDADOS'));
                this.dialogRef.close({ res: true });
                this.guardando = false;
            },
            (err) => {
                this.showSnack(err);
                this.guardando = false;
            }
        );
    }
    else {
        this.showSnack(this.translateService.instant('COMUN.ERROR_GUARDAR'));
        this.guardando = false;
    }
}

onCheckChange(campo, valor, event) {
    if (event.checked) {
        if (this.valoresCheck[campo]) {
            this.valoresCheck[campo].push(valor);
        } else {
            this.valoresCheck[campo] = [valor];
        }
    } else {
        if (this.valoresCheck[campo]) {
            const index = this.valoresCheck[campo].indexOf(valor);
            if (index > -1) {
                this.valoresCheck[campo].splice(index, 1);
            }
        }
    }

    this.formCampos.get(campo).setValue(this.valoresCheck[campo]);
}

clearValue(formControlName: string, event?) {
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }

    let formControl = this.formCampos.get(formControlName);
    if (formControl) {
        formControl.setValue('');
    }
}
}
