import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProyectosService } from 'app/services/proyectos/proyectos.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogOpcionComponent } from './dialog-opcion/dialog-opcion.component';

@Component({
    selector: 'material-select',
    templateUrl: './material-select.component.html',
    styleUrls: ['./material-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MaterialSelectComponent),
            multi: true
        }
    ]
})

/*
################################### Cosas pendientes:

- select con triggers como el campo Tecnico en citas
- select con contenido como imagenes o iconos en las opciones del select, por ejemplo el campo Tecnico en citas
*/

export class MaterialSelectComponent implements OnInit {
    private _unsubscribeAll: Subject<any> = new Subject();
    public control: FormControl = new FormControl();;
    public filtered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    // ###### Obligatorios
    @Input() titulo: string = "";                   //Título del elemento
    @Input() elements: any = [];                    //Array elementos
    @Input() elementVal: string = null;             //Identificador del valor del elemento
    @Input() elementText: string = null;            //Identificador del testo a mostrar del elemento

    // ###### Necesario para creación
    @Input() proyecto: string | number = null;      //ID del proyecto
    @Input() tipo: string = null;                   //Tipo de elemento
    @Input() showCreate: boolean = false;           //Mostrar u ocultar el botón crear opción

    // ###### Necesario en formularios
    @Input() name: string = null;                   //Name del control del elemento en el form
    @Input() form: any = null;                      //Formulario al que pertenece el select

    // ###### Necesario para select multiples
    @Input() multiple: boolean = false;             //Si el select es multiple
    @Input() seleccionados: any = [];
    
    // ###### Otros
    @Input() estilo: string = null;                 //Clases css para el select
    @Input() buscador: boolean = false;             //Mostrar buscador para filtrar resultados
    @Input() trigger: boolean = false;              //Mostrar trigger en el select

    @Output() opcionSaved = new EventEmitter<any>();        //Función a llamar tras crear opción
    @Output() checkDisabled = new EventEmitter<any>();      //Función a llamar para comprobar si una opción debe estar disabled
    @Output() changeFunction = new EventEmitter<any>();     //Función a llamar al cambiar el valor del select
    @Output() deseleccionarFunc = new EventEmitter<any>();  //Función a llamar al quitar selección multiple
    @Output() filterFunc = new EventEmitter<any>();         //Función a llamar cuando se filtre

    public creando: boolean = false;

    constructor(private dialog: MatDialog, private proyectosService: ProyectosService) { }

    ngOnInit(): void {
        if(this.filterFunc){
            this.control.valueChanges.subscribe((filterValue) => {
                if (filterValue.length > 0) {
                    //this.searching = true;
                    this.filterFunc.emit(filterValue);
                }
                else {
                    this.elements = [];
                    if (this.seleccionados) {
                        this.elements = this.seleccionados.slice();
                    }
                }
            });
        }else{
            this.control.valueChanges.subscribe((filterValue) => {
                if (!filterValue) {
                    this.filtered.next(this.elements.slice());
                    return;
                }
    
                this.filtered.next(
                    this.elements.filter(item => {
                        return item.name.toLowerCase().includes(filterValue.toLowerCase());
                    })
                );
            });
        }
    }

    ngOnChanges(){
        this.filtered.next(this.elements.slice());
    }

    nuevaOpcionDialog(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        const dialogRef = this.dialog.open(DialogOpcionComponent, {
            data: {
                nombreElemento: this.titulo,
            },
            panelClass: 'panel-aitana',
            disableClose: true,
            autoFocus: false
        });

        dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll))?.subscribe(result => {
            if (result && result !== undefined) {
                this.proyectosService.createOption(this.tipo, this.proyecto, result).subscribe((res) => {
                    if (res) {
                        if(this.form){
                            this.form.markAsDirty();
                            this.form.get(this.name).setValue(res.result);
                        }

                        if(this.opcionSaved)
                        this.opcionSaved.emit(res.result);
                    } else {
                        console.log('Nope');
                    }
                });
            }
        });
    }

    onChange($event){
        if(this.changeFunction)
        this.changeFunction.emit($event);
    }

    deseleccionar(item){
        if(this.deseleccionarFunc)
        this.deseleccionarFunc.emit(item);
    }
}
