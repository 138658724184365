import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError,map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(private http: HttpClient) { }

  private readonly URL = 'https://wsaitanaangular.aitanasolutions.com/';
//   private readonly URL = 'http://localhost:3000';

  getOportunidades(): Observable<any> {
    return this.http.get(this.URL+'oportunidad');
  }

  getOportunidad(id: string): Observable<any> {
    return this.http.get(this.URL+'oportunidad/'+id);
  }

  newOportunidad(body): Observable<any> {
    return this.http.post<any>(this.URL+'oportunidad', body);
  }

  getEtapas(): Observable<any> {
    return this.http.get(this.URL+'etapa');
  }

  getEmbudos(): Observable<any> {
    return this.http.get(this.URL+'embudo');
  }

  getEstados(): Observable<any> {
    return this.http.get(this.URL+'estado');
  }

  getFuentes(): Observable<any> {
    return this.http.get(this.URL+'fuente');
  }

  getProyectos(): Observable<any> {
    return this.http.get(this.URL+'proyecto');
  }

  newProyecto(body): Observable<any> {
    return this.http.post<any>(this.URL+'proyecto', body);
  }

  getEmpresas(): Observable<any> {
    return this.http.get(this.URL+'empresa');
  }

  newEmpresa(body): Observable<any> {
    return this.http.post<any>(this.URL+'empresa', body);
  }

  getEmpresasGrid(order, order_dir, page): Observable<any> {
    return this.http.get(this.URL+'empresa?order='+order+'&order_dir='+order+'&page='+page);
  }

  getClientesPotenciales(): Observable<any> {
    return this.http.get(this.URL+'clientepotencial');
  }

  newClientePotencial(body): Observable<any> {
    return this.http.post<any>(this.URL+'clientepotencial', body);
  }

  getPersonas(): Observable<any> {
    return this.http.get(this.URL+'persona');
  }

  newPersona(body): Observable<any> {
    return this.http.post<any>(this.URL+'persona', body);
  }

  getProyectosGarantia10 = function(){
    const url = this.URL + 'garantia10';

    return url;
  }

  getAnalisisProyectoGarantia10(idProyecto){
    const url = this.URL + 'garantia10/analisis-proyecto/'+idProyecto;

    return url;
  }

  getSolicitudesGarantia10(){
    const url = this.URL + 'garantia10/solicitudes/';

    return url;
  }

  getEstadosSolicitudesCotizacion(){
    const url = this.URL + 'garantia10/estados/';

    return url;
  }

  getInformeKpi(){
    const url = this.URL + 'metricas/informe-kpi/';

    return url;
  }

  
  /*getAll(): Observable<OportunidadesResponse | void>{
    return this.http
    .post<Oportunidades>(`${environment.API_URL}/oportunidad/getAll`, null)
    .pipe(
      map((res: OportunidadesResponse) => {
        console.log('respuesta', res);
        return res;
      }),
      catchError((err) => this.handlerError(err))
    );
  }*/

  private handlerError(err): Observable<never>{

    let errorMessage = ' Error ocurrido al recibir datos';
    if(err){
      errorMessage = `Error: code ${err.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);

  }
}
