import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { DialogVideoComponent } from 'app/main/centro-conocimiento/dialog-video/dialog-video.component';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-dialog-video-cc',
	templateUrl: './dialog-video-cc.component.html',
	styleUrls: ['./dialog-video-cc.component.scss']
})
export class DialogVideoCcComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;

	@ViewChild('iframeAitana') iframe: ElementRef;
	
    url: SafeResourceUrl;
    loadingIframe: boolean;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DialogVideoComponent>,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private sanitizer: DomSanitizer
	) { 
		this._unsubscribeAll = new Subject();

        this.loadingIframe = true;

        switch(this.data.extension){
            case "VIMEO":
                setTimeout(() => {
                    this.url = sanitizer.bypassSecurityTrustResourceUrl("https://player.vimeo.com/video/"+this.data.vimeo_id);
                }, 400);
            break;
            case "GOOGLE":
                setTimeout(() => {
                    this.url = sanitizer.bypassSecurityTrustResourceUrl("https://drive.google.com/open?id="+this.data.google_drive_id);
                }, 400);
            break;
            case "YOUTUBE":
                setTimeout(() => {
                    this.url = sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+this.data.youtube_id);
                }, 400);
            break;
        }
	}

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    showSnack(mensaje: string) {
        this.snackBar.open(mensaje, null, {
            duration: 2000
        });
    }

}
