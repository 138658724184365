<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 fxLayout="row" fxLayoutAlign="flex-start center" class="m-0 mb-8">
        <mat-icon class="mr-8">search</mat-icon>
        Buscar Proveedor (Partner)
    </h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="mat-typography">
    <div fxLayout="column" class="w-100-p">
        <form fxLayout="column" fxFlex="1" #mainForm="ngForm" [formGroup]="formBuscadorPartner" class="w-100-p">
            <div fxLayout="row" class="p-12 pt-8" fxLayoutAlign="space-between center">
                <div class="filtros" fxLayout="row wrap" fxLayoutAlign="flex-start center">
                    <mat-form-field appearance="standard" class="form-group w-200 mr-16">
                        <mat-label>Nombre empresa</mat-label>
                        <input matInput name="et" formControlName="nombre_empresa" class="form-control" autocomplete="off">
                    </mat-form-field>

                    <mat-form-field appearance="standard" class="form-group w-240 mr-16">
                        <mat-label>Estado Colaborador</mat-label>
                        <mat-select name="estado_colaborador" formControlName="estado_colaborador" class="form-control chip-list" [multiple]="true">
                            <mat-select-trigger>
                                <mat-chip-list>
                                    <mat-chip *ngFor="let estadoCol of estadosColaboradorSelected" [removable]="true" (removed)="deselectEstadoColaborador(estadoCol)">
                                        {{ estadoCol.name }}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-list>
                            </mat-select-trigger>
                            <mat-option *ngFor="let itemEstadoCol of estadosColaborador" [value]="itemEstadoCol.ID">{{itemEstadoCol.name}}</mat-option>
                        </mat-select>
                        <button *ngIf="estadosColaboradorSelected?.length > 0" class="btn-close" mat-button matSuffix mat-icon-button (click)="clearEstadosColaborador($event)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    
                    <mat-form-field appearance="standard" class="form-group w-240 mr-16">
                        <mat-label>Cobertura</mat-label>
                        <mat-select name="cobertura_provincia" formControlName="cobertura_provincia" class="form-control chip-list" [multiple]="true">
                            <mat-select-trigger>
                                <mat-chip-list>
                                    <mat-chip *ngFor="let coberturaProv of coberturaProvinciaSelected" [removable]="true" (removed)="deselectCoberturaProvincia(coberturaProv)">
                                        {{ coberturaProv?.name }}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-list>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="provinciaFilterCtrl"
                                    placeholderLabel="Buscar provincia ..." noEntriesFoundLabel="No se encontró ningún valor"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let itemProv of provinciasFiltered | async" [value]="itemProv.id">{{itemProv.name}}</mat-option>
                        </mat-select>
                        <button *ngIf="coberturaProvinciaSelected?.length > 0" class="btn-close" mat-button matSuffix mat-icon-button (click)="clearCoberturaProvincia($event)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    
                    <mat-form-field appearance="standard" class="form-group w-240 mr-16">
                        <mat-label>Cobertura País</mat-label>
                        <mat-select name="cobertura_pais" formControlName="cobertura_pais" class="form-control chip-list" [multiple]="true">
                            <mat-select-trigger>
                                <mat-chip-list>
                                    <mat-chip *ngFor="let coberturaPais of coberturaPaisSelected" [removable]="true" (removed)="deselectCoberturaPais(coberturaPais)">
                                        {{ coberturaPais.name }}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-list>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="paisFilterCtrl"
                                    placeholderLabel="Buscar país ..." noEntriesFoundLabel="No se encontró ningún valor"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let itemPais of paisesFiltered | async" [value]="itemPais.id">{{itemPais.name}}</mat-option>
                        </mat-select>
                        <button *ngIf="coberturaPaisSelected?.length > 0" class="btn-close" mat-button matSuffix mat-icon-button (click)="clearCoberturaPais($event)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field appearance="standard" class="form-group w-180 mr-16">
                        <mat-label>Realiza On-Site</mat-label>
                        <mat-select name="realiza_onsite" formControlName="realiza_onsite" class="form-control">
                            <mat-option *ngFor="let item of valoresRealizaOnsite" [value]="item.id">{{item.name}}</mat-option>
                        </mat-select>
                        <button *ngIf="checkValue('realiza_onsite')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'realiza_onsite')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <div fxLayout="row" fxLayoutAlign="flex-end center" class="form-group w-200 mr-16">
                        <bar-rating [(rate)]="filtroValoracion" [max]="5" [theme]="'fontawesome'" class="size-filter" (rateChange)="changeFiltroValoracion($event)"></bar-rating>
                        <button [disabled]="!checkValue('valoracion')" mat-button matSuffix mat-icon-button class="ml-12" (click)="clearValoracion()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>

                </div>

                <div fxLayout="row wrap" fxLayoutAlign="center center">
                    <button mat-button class="btn-aitana" (click)="filtrar()">Buscar</button>
                    <button *ngIf="isFiltrosActivos()" mat-button mat-stroked-button class="ml-16 mt-8" (click)="resetFiltros()">Borrar filtros</button>
                </div>
            </div>
        </form>

        <div *ngIf="colaboradoresEncontrados?.length === 0" class="primary-50-bg bloq-info">
            <h3 class="secondary-text m-0">No se ha encontrado ningún colaborador</h3>
        </div>

        <div *ngIf="colaboradoresEncontrados?.length > 0" class="table-container table-coloboradores">
            <table mat-table [dataSource]="dataSourceColaboradores" matSort (matSortChange)="sortChange($event)" class="w-100-p">

                <ng-container matColumnDef="nombre_empresa">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre empresa </th>
                    <td mat-cell *matCellDef="let row">
                        <div fxLayout="row" class="row-nombreEmpresa">
                            {{row.nombre_empresa ? row.nombre_empresa : (row.nombreComercial ? row.nombreComercial : (row.razon_social ? razon_social : ' - '))}}
                            <div fxLayout="row" *ngIf="showIconMano(row) && row.documentacion.mensaje && row.documentacion.mensaje != ''" matTooltip="{{formatMensaje(row.documentacion.mensaje)}}" matTooltipClass="line-broken-tooltip">
                                <mat-icon fontSet="far" fontIcon="fa-hand-paper" class="ml-8 icon-red"></mat-icon>
                            </div>
                            <div fxLayout="row" *ngIf="showIconAviso(row)" matTooltip="{{formatMensaje(row.documentacion.mensaje)}}" matTooltipClass="line-broken-tooltip">
                                <mat-icon fontSet="fas" fontIcon="fa-exclamation-triangle" class="ml-8 icon-yellow"></mat-icon>
                            </div>
                            <ng-container *ngIf="showBotonActualizarDocumentacion(row)">
                                <button mat-button matSuffix mat-icon-button class="ml-8 icon-principal" (click)="actualizaDocumentacion(row)"
                                         matTooltip="Actualiza documentación del colaborador para el proyecto" >
                                    <mat-icon fontSet="fab" fontIcon="fa-expeditedssl"></mat-icon>
                                </button>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="estado_colaborador">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado colaborador </th>
                    <td mat-cell *matCellDef="let row"> {{row.estado_colaborador}} </td>
                </ng-container>

                <ng-container matColumnDef="cobertura">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cobertura </th>
                    <td mat-cell *matCellDef="let row"> {{row.provincias}} </td>
                </ng-container>

                <ng-container matColumnDef="cobertura_pais">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cobertura país </th>
                    <td mat-cell *matCellDef="let row"> {{row.paises}} </td>
                </ng-container>

                <ng-container matColumnDef="realiza_onsite">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Realiza On-Site </th>
                    <td mat-cell *matCellDef="let row"> {{row.realiza_onsite == 1 ? 'SÍ' : 'NO'}} </td>
                </ng-container>

                <ng-container matColumnDef="valoracion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valoración </th>
                    <td mat-cell *matCellDef="let row">
                        <bar-rating [(rate)]="row.valoracion" [max]="5" [readOnly]="true" [theme]="'fontawesome'"></bar-rating>
                    </td>
                </ng-container>

                <ng-container matColumnDef="carga_trabajo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tickets Técnico </th>
                    <td mat-cell *matCellDef="let row"> {{row.carga_trabajo}} </td>
                </ng-container>

                <ng-container matColumnDef="distancia">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Distancia </th>
                    <td mat-cell *matCellDef="let row"> {{row.distance ? row.distance + 'km.' : '-'}} </td>
                </ng-container>

                <ng-container matColumnDef="actions" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.documentacion" class="spinner w-40 h-40"></div>
                        <button *ngIf="colaboradorSeleccionable(row) && docComprobada" mat-icon-button class="btn-seleccionar-colaborador" 
                                matTooltip="Seleccionar proveedor" (click)="seleccionarColaborador(row)">
                            <mat-icon>playlist_add</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsColaborador, sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsColaborador;"></tr>

            </table>
            <mat-paginator [pageSizeOptions]="[5,10,20,30]" [length]="totalElementos" [pageIndex]="0" [pageSize]="pageSize" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</mat-dialog-content>