<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <div fxLayout="row" class="header-info">
        <span class="h2">Info Activo {{ activo.pn + ' - ' + activo.serial }}</span>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <div class="content">

        <mat-card *ngIf="componentes" class="card-info">
            <mat-card-title>
                <label class="card-label">&nbsp;Componentes KIT</label>
            </mat-card-title>
            <mat-card-content>
                <div class="wrap-table">
                    <div class="table-container">
                        <table mat-table [dataSource]="dataSourceComponentes" class="w-100-p">

                            <ng-container matColumnDef="pn">
                                <th mat-header-cell *matHeaderCellDef> P/N </th>
                                <td mat-cell *matCellDef="let row"> {{row.pn}} </td>
                            </ng-container>

                            <ng-container matColumnDef="serial">
                                <th mat-header-cell *matHeaderCellDef> N/S </th>
                                <td mat-cell *matCellDef="let row"> {{row.serial}} </td>
                            </ng-container>

                            <ng-container matColumnDef="tipo">
                                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                                <td mat-cell *matCellDef="let row"> {{row.tipo}} </td>
                            </ng-container>

                            <ng-container matColumnDef="fabricante">
                                <th mat-header-cell *matHeaderCellDef> Fabricante </th>
                                <td mat-cell *matCellDef="let row"> {{row.fabricante}} </td>
                            </ng-container>

                            <ng-container matColumnDef="modelo">
                                <th mat-header-cell *matHeaderCellDef> Modelo </th>
                                <td mat-cell *matCellDef="let row"> {{row.modelo}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsComponentes; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsComponentes;"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No se encontraron datos</td>
                            </tr>

                        </table>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="card-info">
            <mat-card-title>
                <label class="card-label">&nbsp;Tickets Activo</label>
            </mat-card-title>
            <mat-card-content>
                <div class="wrap-table">
                    <div class="table-container">
                        <table mat-table [dataSource]="dataSourceTickets" class="w-100-p">

                            <ng-container matColumnDef="ID">
                                <th mat-header-cell *matHeaderCellDef> ID </th>
                                <td mat-cell *matCellDef="let row">
                                    <button class="mr-12" mat-icon-button matTooltip="Ver Ticket" (click)="verTicket(row.ID)">
                                        <span class="ticket-elemento">{{ row.ID }}</span>
                                    </button>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="fecha_apertura">
                                <th mat-header-cell *matHeaderCellDef> Fecha Apertura </th>
                                <td mat-cell *matCellDef="let row"> {{row.fecha_apertura | date:'dd/MM/yyyy hh:mm:ss'}} </td>
                            </ng-container>

                            <ng-container matColumnDef="estado">
                                <th mat-header-cell *matHeaderCellDef> Estado </th>
                                <td mat-cell *matCellDef="let row"> {{row.estado}} </td>
                            </ng-container>

                            <ng-container matColumnDef="titulo">
                                <th mat-header-cell *matHeaderCellDef> Título </th>
                                <td mat-cell *matCellDef="let row" matTooltip="{{row.titulo}}"> {{limitSize(row.titulo)}} </td>
                            </ng-container>

                            <ng-container matColumnDef="categoria">
                                <th mat-header-cell *matHeaderCellDef> Categoría </th>
                                <td mat-cell *matCellDef="let row"> {{row.categoria}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsTickets; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsTickets;"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No se encontraron datos</td>
                            </tr>

                        </table>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="card-info">
            <mat-card-title>
                <label class="card-label">&nbsp;Documentos Activo</label>
                <button *ngIf="knowledge.length > 0" mat-icon-button matTooltip="Ir al Centro de Conocimiento" (click)="getUrlCentroConocimiento()">
                    <fa-icon class="secondary-text font-size-18" icon="graduation-cap"></fa-icon>
                </button>
            </mat-card-title>
            <mat-card-content>
                <div class="wrap-table">
                    <div class="table-container">
                        <table mat-table [dataSource]="dataSourceKnowledge" class="w-100-p">

                            <ng-container matColumnDef="titulo">
                                <th mat-header-cell *matHeaderCellDef> Título </th>
                                <td mat-cell *matCellDef="let row">{{ row.titulo }}</td>
                            </ng-container>

                            <ng-container matColumnDef="tipo">
                                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                                <td mat-cell *matCellDef="let row"> 
                                    <div class="icon-container">
                                        <fa-icon class="icon-doc" size="2x" [icon]="['fas', row.icon]"></fa-icon>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="descripcion">
                                <th mat-header-cell *matHeaderCellDef> Descripción </th>
                                <td mat-cell *matCellDef="let row" matTooltip="{{row.descripcion}}"> {{limitSize(row.descripcion)}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsKnowledge; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsKnowledge;"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No se encontraron datos</td>
                            </tr>

                        </table>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>
</mat-dialog-content>