import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';

import { AuthService } from 'app/services/auth/auth.service';
import { UserService } from 'app/services/user/user.service';
import { ProyectosService } from 'app/services/proyectos/proyectos.service';
import { ImageService } from 'app/services/helper/image.service';
import { Router } from '@angular/router';
import { SocketService } from 'app/services/socket/socket.service';
import { NotificacionesService } from 'app/services/notificaciones/notificaciones.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CygPermission } from '@fuse/types';
import { UserRoleService } from 'app/services/user/user-role.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { TimerTaskService } from 'app/services/timer-task/timer-task.service';

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];

    usuario: any;
    numNotificacionesNoLeidas: number;

    mostrarBuscadorTicket: boolean = false;
    permisoPerfilUnei: CygPermission;

    mostrarBuscadorNSAutoasignar: boolean = false;
    permisoAutoAsignarUnei:CygPermission;

    permisoChatAutoDiagnosis: CygPermission;
	chatAutoDiagnosisPermitido: boolean;
	timerTaskPermitido: boolean;
    timerIcon : string = 'START';
    timerTextClass : string = 'text-danger';

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseNavigationService: FuseNavigationService,
        private _translateService: TranslateService,
        public authSvc: AuthService,
        private userSvc: UserService,
        private proyectosSvc: ProyectosService,
        private imageSvc: ImageService,
        private notificacionesSvc: NotificacionesService,
        private timerTaskSvc: TimerTaskService,
        private socketSvc: SocketService,
        private snackBar: MatSnackBar,
        private userRoleSvc: UserRoleService,
    )
    {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon : 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon : 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon : 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id   : 'es',
                title: 'Español',
                flag : 'es'
            },
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.numNotificacionesNoLeidas = 0;

        this.chatAutoDiagnosisPermitido = false;
        this.timerTaskPermitido = false;
		this.permisoChatAutoDiagnosis = { type: "item", name: "Aitana: Chat AD", active: true };
        this.permisoPerfilUnei = { type: "item", name: "Aitana: No mostrar enlace new ticket rejilla", active: false };
        this.permisoAutoAsignarUnei = { type: "item", name: "Aitana: Permite autoasignar ticket por numserie", active: true };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

            
        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});

        this.loadUser();

        this.notificacionesSvc.onNoLeidasChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (data: any) => {
                console.log("subscribe no leidas", data);
                if (data) {
                    this.loadNoLeidas();
                }
            }
        );
        this.socketSvc.onNuevaNotificacion.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (data: any) => {
                this.loadNoLeidas();
            }
        );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    cambiarIdioma(lang) {
        this.userSvc.cambiarIdioma(lang.id).subscribe(
            (res: any) => {
                this.setLanguage(lang);
            },
            (err) => {
                this.showSnack(err);
            }
        );
    }

    showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}

    loadUser() {
        if (this.authSvc.isUserLoggedIn()) {
            this.userSvc.getUsuario().subscribe(
                (data:any) => {
                    this.usuario = data;
                    this.checkPermisos();

                    if(this.usuario.asana_id) {
                        this.timerTaskPermitido = true;

                        this.timerTaskSvc.currentStatus.subscribe(
                            (status : string) => this.timerIcon = status
                        )
                    }
                    
                    const langUser = this.languages.find(item => { return item.id == this.usuario.language; });
                    console.log("langUser", langUser, this.usuario.language);
                    if (langUser) {
                        this.setLanguage(langUser);
                    }
                    this.socketSvc.setupSocketConnection();
                }
            );
            this.proyectosSvc.getProjectsUser().subscribe(
                (data: any) => {}
            );
        }
	}

    checkPermisos() {
		this.userRoleSvc.checkPermissions([this.permisoChatAutoDiagnosis])
			.then((permiso:boolean) => {
				this.chatAutoDiagnosisPermitido = permiso;
                if (this.usuario.id === 1033 || this.usuario.id == 6 || this.usuario.id == 117 || this.usuario.id == 939) {
                    this.chatAutoDiagnosisPermitido = true;
                }
                else {
                    this.chatAutoDiagnosisPermitido = false;
                }
			});

        this.userRoleSvc.checkPermissions([this.permisoPerfilUnei])
        .then((permiso:boolean) => {
            this.mostrarBuscadorTicket = permiso;
           
        });

        this.userRoleSvc.checkPermissions([this.permisoAutoAsignarUnei])
        .then((permiso:boolean) => {
            this.mostrarBuscadorNSAutoasignar = permiso;
           
        });

	}

    loadNoLeidas() {
        if (this.authSvc.isUserLoggedIn()) {
            this.notificacionesSvc.getNoLeidas().pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (data: any) => {
                    this.numNotificacionesNoLeidas = data.length;
                }
            );
        }
    }

    logout() {
        this.userSvc.logout();
        this.authSvc.logout();
        this.socketSvc.disconnect();
        this._fuseNavigationService.unregister('main');
    }
}
