import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { Block } from 'notiflix';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';

import { UserService } from 'app/services/user/user.service';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from 'app/services/helper/helper.service';
import { ChatGptService } from 'app/services/chat-gpt/chat-gpt.service';


export interface Mensaje {
    who: 'contact' | 'user',
    avatar: string,
    type: 'message' | 'action' | 'input' | 'list-data' | 'message-error',
    disabled?: boolean,
    message?: string,
    error?: boolean,
    success?: boolean,
    actions?: Array<ActionMensaje>,
    input?: string,
    listName?: string,
    data?: any
}

export interface ActionMensaje {
    action: 'iniciar' | 'reiniciar' | 'sintomaSi' | 'sintomaNo' | 'sintomaNS',
    text?: string,
    icon?: string,
    title?: string,
    id?: string,
    nombre?: string,
    selected?: boolean,
}


@Component({
  selector: 'diagnosis-bot-gpt',
  templateUrl: './diagnosis-bot-gpt.component.html',
  styleUrls: ['./diagnosis-bot-gpt.component.scss']
})
export class DiagnosisBotGptComponent implements OnInit, AfterViewInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

    usuario: any;

    avatarBot: string = "assets/images/autodiagnosis-bot/adbot_gpt.png";
    mensajes: Array<Mensaje>;

    botType : 'default' | 'avacom' = 'default';

    chatMsg: string;
    completeChat : string;

    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    @ViewChild('chatMsgInput', { read: ElementRef, static:false }) chatMsgInput: ElementRef;

    private _chatViewScrollbar: FusePerfectScrollbarDirective;

	constructor(
		private translateService: TranslateService,
		private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private snackBar: MatSnackBar,
        private userSvc: UserService,
        private chatGptSvc: ChatGptService,
        private helperSvc: HelperService

	) {
		moment.locale(this.translateService.currentLang);
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(english, spanish);

        this.mensajes = [];
	}

	ngOnInit(): void {
        this.loadUser();
        this.resetChat();
	}

    ngAfterViewInit(): void
    {
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'messages-autodiagnosis-bot';
        });
    }

	ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private _focusChatReply(): void
    {
        setTimeout(() => {
            // Focus to the reply input
            this.chatMsgInput.nativeElement.focus();
        },500);
    }

    private _prepareChatForReplies(): void
    {
        setTimeout(() => {

            // Scroll to the bottom of the messages list
            if ( this._chatViewScrollbar )
            {
                this._chatViewScrollbar.update();

                setTimeout(() => {
                    this._chatViewScrollbar.scrollToBottom(0);
                });
            }
        });
    }

    showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}

    getLangUser() {
        let langUser = "es";
        if (this.usuario) {
            langUser = this.usuario.language ? this.usuario.language : "es";
        }

        return langUser;
    }

    toggleSidebarOpen(): void {
        this._fuseSidebarService.getSidebar('diagnosisBotGpt').toggleOpen();
    }

    loadUser() {
        this.userSvc.getUsuario().subscribe(
            (data:any) => {
                this.usuario = data;
            }
        );
	}

    addMessageError(texto: string) {
        const message: Mensaje = {
            who: 'contact',
            avatar: this.avatarBot,
            type: 'message',
            message: texto,
            error: true
        }
        this.mensajes.push(message);

        const langUser = this.getLangUser();
        this.helperSvc.sayIt(texto, langUser);
    }
    addMessageSuccess(texto: string) {
        const message: Mensaje = {
            who: 'contact',
            avatar: this.avatarBot,
            type: 'message',
            message: texto,
            success: true
        }
        this.mensajes.push(message);

        const langUser = this.getLangUser();
        this.helperSvc.sayIt(texto, langUser);
    }
    addMessage(texto: string, who?, read?: boolean) {
        const message: Mensaje = {
            who: who ? who : 'contact',
            avatar: this.avatarBot,
            type: 'message',
            message: texto,
        }
        this.mensajes.push(message);

        if (read === undefined) {
            read = true;
        }

        if (read) {
            const langUser = this.getLangUser();
            this.helperSvc.sayIt(texto, langUser);
        }
    }
    addActionMessage(actions: Array<ActionMensaje>, who?) {
        const message: Mensaje = {
            who: who ? who : 'contact',
            avatar: this.avatarBot,
            type: 'action',
            disabled: false,
            actions: actions,
        }
        this.mensajes.push(message);
    }
    addInputMessage(input: string, who?) {
        const message: Mensaje = {
            who:  who ? who : 'contact',
            avatar: this.avatarBot,
            type: 'input',
            disabled: false,
            input: input
        }
        this.mensajes.push(message);

        if(input == 'chat')
            this._focusChatReply();
    }
    addListDataMessage(data: any, listName: string, who?) {
        const message: Mensaje = {
            who: who ? who : 'contact',
            avatar: this.avatarBot,
            type: 'list-data',
            listName: listName,
            data: data
        }
        this.mensajes.push(message);
    }

    resetChat() {
        this.mensajes = [];
        this.completeChat = null;
        this.addActionMessage([
            {
                text: 'INICIAR CHAT',
                action: "iniciar"
            }
        ]);
    }

    initChat() {
        this.mensajes = [];
        this.completeChat = null;
        if(this.botType == 'avacom')
            this.addMessage("¡Hola! Me llamo AITANA y soy el asistente virtual de reparación de dispositivos Apple. ¿Con que dispositivo tienes problemas?", "contact", true);
        else
            this.addMessage("¡Hola! Me llamo AITANA y soy el asistente virtual de reparación de dispositivos. ¿Con que dispositivo tienes problemas?", "contact", true);
        this.addInputMessage("chat");

        this._prepareChatForReplies();
    }

    isFirstMessageOfGroup(mensaje, index): boolean {
        return (index === 0 || (this.mensajes[index-1] && (this.mensajes[index-1].who !== mensaje.who || this.mensajes[index-1].type !== 'message')));
    }
    isLastMessageOfGroup(mensaje, index): boolean {
        let isLastMessage = false;
        if (index === this.mensajes.length-1) {
            isLastMessage = true;
        }
        else {
            if (this.mensajes[index+1]) {
                const nextMessage = this.mensajes[index+1];
                if (nextMessage.who !== mensaje.who || (nextMessage.who === mensaje.who && nextMessage.type !== 'message')) {
                    isLastMessage = true;
                }
            }
        }

        return isLastMessage;
    }
    shouldShowContactAvatar(mensaje, index): boolean {
        return (
            mensaje.who == 'contact' && mensaje.type == 'message' && this.isLastMessageOfGroup(mensaje, index)
        );
    }

    actionMensaje(accion: string, index: number, idItem?: number) {
        // console.log("Accion mensaje", accion);
        
        switch (accion) {
            case "sendChat":
                this.mensajes.pop();
                this.addMessage(this.chatMsg, 'user', false);
                this.sendChat(this.chatMsg);
                this.chatMsg = null;
                break;
            case "iniciar":
                this.initChat();
                break;
            case "reiniciar":
                this.resetChat();
                break;        
            default:
                break;
        }
    }

    sendChat(msg : string) {
        this.addMessage("Escribiendo ...", "contact", false);
        this._prepareChatForReplies();
        this.chatGptSvc.sendMsg(msg, this.completeChat, this.botType).pipe(takeUntil(this._unsubscribeAll)).subscribe((response) => {
            this.completeChat = response.completeChat;
            this.mensajes.pop();

            let responseIA = response.responseIA;
            if(responseIA.includes('[flex_URL]')) {
                responseIA = responseIA.replace('[flex_URL]', 'https://flex.exxita.com/');
            }

            this.addMessage(responseIA, "contact", true);

            this.addInputMessage("chat");
            this._prepareChatForReplies();

            if(response.dataFormValid) {
                this.addMessageSuccess(JSON.stringify(response.dataForm));
            }
        });
    }

}
