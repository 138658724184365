<mat-toolbar class="blank">
    <mat-toolbar-row>
        <form>
            <mat-form-field class="example-form-field">
                <span matPrefix><mat-icon>search</mat-icon> &nbsp;</span>
                <input matInput type="text" placeholder="Buscar" (keyup)="applyFilter($event)">
                <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </form>
        <span class="example-spacer"></span>
        <mat-icon class="example-icon" aria-hidden="false" aria-label="Example heart icon" (click)="openDialog()">add</mat-icon>
        <mat-icon class="example-icon" aria-hidden="false" aria-label="Example delete icon">notifications</mat-icon>
        <mat-icon class="example-icon" aria-hidden="false" aria-label="Example delete icon">help_outline</mat-icon>
    </mat-toolbar-row>
</mat-toolbar>

