import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthTokenResponse, User, UserResponse } from 'app/shared/models/user.interface';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError,map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { Router } from '@angular/router';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {

	public loggedIn = new BehaviorSubject<boolean>(false);
	public user : any = {};
	// public entorno: 'aitana' | 'gtp' | 'ewaste' | 'circpass' | 'circpass_gtp';

	constructor(private http: HttpClient, private router : Router) {
		this.checkToken();
	}

	// setEntorno() {
	// 	console.log("HOST", window.location.host, window.location.href);
	// 	if (window.location.host == 'gtp.exxita.com' || window.location.href.includes("gtp.exxita") || window.location.href.includes("greentech-passport")) {
	// 		environment.entorno = 'gtp';
	// 	}
	// 	else if(window.location.host.includes('app.circpass') || window.location.href.includes("app.circpass")) {
	// 		environment.entorno = 'circpass_gtp';
	// 	}
	// 	else if (window.location.host == 'ewaste.aitanasolutions.com' || window.location.href.includes("ewaste.aitanasolutions")) {
	// 		environment.entorno = 'ewaste';
	// 	}
	// 	else if(window.location.host.includes('cria.circpass') || window.location.href.includes("cria.circpass")) {
	// 		environment.entorno = 'circpass';
	// 	}
	// 	else {
	// 		environment.entorno = 'aitana';
	// 		environment.entorno = 'ewaste';
	// 	}
	// 	console.log("ENTORNO", environment.entorno);
	// }

	getAccessToken(): string {
		const stringToken = localStorage.getItem('tokenUser');
		let accessToken = "";

		if (stringToken !== null) {
			const token = JSON.parse(stringToken);
			accessToken = token.access_token;
		}
		
		return accessToken;
	}

	isUserLoggedIn(): boolean {
		return this.checkToken();
	}

    needConfirmSecurityCode(): boolean {
        return this.checkSecurityCode()
    }

  	loginUser(authData: User): Observable<AuthTokenResponse> {
		return new Observable<AuthTokenResponse>(observer => {
			const body = {
				"username": authData.username,
				"password": authData.password,
				"grant_type": "password",
				"target": "web"
			}
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Basic ' + environment.clientSecret,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			this.http.post(environment.API_URL + '/auth/token', body, options).subscribe(
				(response: AuthTokenResponse) => {
					// console.log("response token", response);
					this.saveToken(response);
					this.loggedIn.next(true);
					observer.next(response);
				},
				err => {
					console.log("error login", err);
					let msgError = err.error.message;

					observer.error(msgError);
				}
			)
		});
	}

	login(authData: User): Observable<UserResponse | void> {	
		return this.http
			.post<UserResponse>(`${environment.API_URL}/auth/login`, authData)
			.pipe(
				map((res: UserResponse) => {
				localStorage.setItem('tokenUser',res.token);
				
				this.loggedIn.next(true);

				console.log('respuesta', res);
				return res;

				}),
				catchError((err) => this.handlerError(err))
		);
	}

	loginSocial(email: string, idToken: string): Observable<AuthTokenResponse> {
		return new Observable<AuthTokenResponse>(observer => {
			const body = {
				"email": email,
				"token": idToken,
				"grant_type": "email"
			}
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Basic ' + environment.clientSecret,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			this.http.post(environment.API_URL + '/auth/token', body, options).subscribe(
				(response: AuthTokenResponse) => {
					// console.log("response token", response);
					this.saveToken(response);
					this.loggedIn.next(true);
					observer.next(response);
				},
				err => {
					console.log("error login", err);
					let msgError = err.error.message;

					observer.error(msgError);
				}
			);
		});
	}

	logout() {
		localStorage.removeItem('tokenUser');
        this.loggedIn.next(false);
		this.router.navigate(['login']);
	}

	rememberPassword(email: string): Observable<boolean> {
		return new Observable<boolean>(observer => {
			const body = {
				"email": email,
			}
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Basic ' + environment.clientSecret,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			this.http.post(environment.API_URL + '/auth/recuperarPassword', body, options).subscribe(
				(response: any) => {
					observer.next(true);
				},
				err => {
					console.log("error login", err);
					if(err.status == 404) {
						//Si no se encuentra el usuario, se considera como que ha ido bien la llamada, para no dar pistas de los correos de los usuarios
						observer.next(true);
					} else {
						let msgError = "No se ha podido enviar el email.";
						if (err.error.error === 'invalid_username') {
							msgError = "Debe indicar el usuario";
						}
						observer.error(msgError);
					}
				}
			);
		});
	}

	validateHashPassword(userId: number, date: string, hash: string): Observable<boolean> {
		return new Observable<boolean>(observer => {
			const body = {
				"userId": userId,
				"date": date,
				"hash": hash
			}
			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Basic ' + environment.clientSecret,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			this.http.post(environment.API_URL + '/auth/validateHash', body, options).subscribe(
				(response: any) => {
					// console.log("response token validate hash", response);
					this.saveToken(response);
					observer.next(true);
				},
				err => {
					console.log("error validate hash", err);
					this.router.navigate(['login'], {replaceUrl: true, queryParams: { returnUrl: this.router.url }});
					observer.next(false);
					let msgError = "No se ha podido enviar el email.";

					// observer.error(msgError);
				}
			);
		});
	}

	private checkToken(): boolean{
		let validToken = false;
		const stringToken = localStorage.getItem('tokenUser');

		if (stringToken !== null) {
			// console.log("token", JSON.parse(stringToken));
			const token = JSON.parse(stringToken);
			if (token.access_token && token.expires_in) {
				const now = moment();
				const dateExpired = moment(token.expires_in);

				// console.log("now", now);
				// console.log("expire", dateExpired);

				if (now.isBefore(dateExpired)) {
					validToken = true;
				}
			}
		}

		return validToken;
	}

    private checkSecurityCode(): boolean {
        let security_code = false;
		const stringToken = localStorage.getItem('tokenUser');

		if (stringToken !== null) {
			const token = JSON.parse(stringToken);
            
			if (token.securityCode) {
				security_code = token.securityCode;
			}
		}

		return security_code;
    }

    confirmarCodigoSeguridad(codigo : any){
        return new Observable<any>(observer => {
			const accessToken = this.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/usuarios/config/securityCode';
			this.http.post(url, {'security_code': codigo}, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error ws getMenu", err);
					let msgError = err.message?err.message:"Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	private saveToken(token: AuthTokenResponse): void{
		let now = moment();
		let dateExpired = now.toDate().getTime() + token.expires_in;
		token.expires_in = dateExpired;
		// console.log("ahora", now);
		// console.log("dateExpired", moment(dateExpired));

		const tokenString = JSON.stringify(token);
		localStorage.setItem('tokenUser',tokenString); 
	}
    
    public saveNecesitaConfirmarCodigo(val: Boolean): void{
        const stringToken = localStorage.getItem('tokenUser');
        if (stringToken !== null) {
			const token = JSON.parse(stringToken);
            
			token.securityCode = val;
            this.saveToken(token);
		}
    }

	private handlerError(err): Observable<never>{

		let errorMessage = ' Error ocurrido al recibir datos';
		if(err){
			errorMessage = `Error: code ${err.message}`;
		}
		window.alert(errorMessage);
		return throwError(errorMessage);
  }

}
