<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">{{ tituloDialog }}</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="mat-typography">
    <div fxLayout="column" style="width: 40vw">
        <form fxLayout="row wrap" fxLayoutAlign="flex-start center" #mainForm="ngForm" [formGroup]="formFiltro" class="filtros">

            <!-- TIPO TEXTO -->
            <mat-form-field *ngIf="datosFiltro?.type == 'text'" appearance="standard" class="form-group w-100-p">
                <mat-label>{{datosFiltro?.label}}</mat-label>
                <input matInput [name]="datosFiltro?.name" [formControlName]="datosFiltro?.name" class="form-control" autocomplete="off">
            </mat-form-field>

            <!-- TIPO FECHA -->
            <!-- <div *ngIf="datosFiltro?.type == 'date'" fxLayout="row" fxLayoutAlign="space-between center" class="wrap-date-range w-100-p">
                <input type="text" matInput
                    ngxDaterangepickerMd
                    [locale]="localeRangeDate"
                    [autoApply]="true"
                    [showCustomRangeLabel]="true"
                    [alwaysShowCalendars]="true"
                    [linkedCalendars]="true"
                    [ranges]="customRanges"
                    [formControlName]="datosFiltro?.name"
                    [name]="datosFiltro?.name"
                    [placeholder]="datosFiltro?.label"
                    class="rangedate datepicker-calendar-icon"/>
                <mat-icon>calendar_today</mat-icon>
                <button *ngIf="checkValue(datosFiltro?.name)" mat-button matSuffix mat-icon-button (click)="clearValue(datosFiltro?.name, $event)">
                    <mat-icon>close</mat-icon>
                </button>
            </div> -->

            <!-- TIPOS SELECT -->

            <mat-form-field *ngIf="datosFiltro?.name == 'tipo_sociedad'" appearance="standard" class="form-group w-100-p">
                <mat-label>{{datosFiltro?.label}}</mat-label>
                <mat-select [formControlName]="datosFiltro?.name" class="form-control">
                    <mat-option *ngFor="let item of tiposSociedad" [value]="item.id">{{item.name}}</mat-option>
                </mat-select>
                <button *ngIf="checkValue(datosFiltro?.name)" mat-button matSuffix mat-icon-button (click)="clearValue(datosFiltro?.name, $event)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field *ngIf="datosFiltro?.name == 'estado_colaborador'" appearance="standard" class="form-group w-100-p">
                <mat-label>{{datosFiltro?.label}}</mat-label>
                <mat-select [formControlName]="datosFiltro?.name" class="form-control">
                    <mat-option *ngFor="let item of estadosColaborador" [value]="item.id">{{item.name}}</mat-option>
                </mat-select>
                <button *ngIf="checkValue(datosFiltro?.name)" mat-button matSuffix mat-icon-button (click)="clearValue(datosFiltro?.name, $event)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field *ngIf="datosFiltro?.name == 'localidad'" appearance="standard" class="form-group w-100-p">
                <mat-label>{{datosFiltro?.label}}</mat-label>
                <mat-select [formControlName]="datosFiltro?.name" class="form-control">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="localidadesFilterCtrl" [searching]="localidadesSearching"
                        placeholderLabel="Buscar localidad..." noEntriesFoundLabel="No se encontró ninguna localidad"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of localidades" [value]="item.id">{{item.name + ' (' + item.postal + ')'}}</mat-option>
                </mat-select>
                <button *ngIf="checkValue(datosFiltro?.name)" mat-button matSuffix mat-icon-button (click)="clearValue(datosFiltro?.name, $event)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field *ngIf="datosFiltro?.name == 'direccion_tipos'" appearance="standard" class="form-group w-100-p">
                <mat-label>Tipo dirección</mat-label>
                <mat-select [name]="datosFiltro?.name" [formControlName]="datosFiltro?.name" class="form-control">
                    <mat-option *ngFor="let item of direccionTipos" [value]="item.id">{{item.id}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="datosFiltro?.name == 'tipo_via'" appearance="standard" class="form-group w-100-p">
                <mat-label>Tipo vía</mat-label>
                <mat-select [name]="datosFiltro?.name" [formControlName]="datosFiltro?.name" class="form-control">
                    <mat-option *ngFor="let item of tiposVia" [value]="item.id">{{item.id}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="datosFiltro?.name == 'provincia' || datosFiltro?.name == 'provincias'" appearance="standard" class="form-group w-100-p">
                <mat-label>{{datosFiltro?.label}}</mat-label>
                <mat-select [formControlName]="datosFiltro?.name" class="form-control">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="provinciasFilterCtrl" [searching]="provinciasSearching"
                        placeholderLabel="Buscar provincia..." noEntriesFoundLabel="No se encontró ninguna provincia"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of provincias" [value]="item.id">{{item.name}}</mat-option>
                </mat-select>
                <button *ngIf="checkValue(datosFiltro?.name)" mat-button matSuffix mat-icon-button (click)="clearValue(datosFiltro?.name, $event)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field *ngIf="datosFiltro?.name == 'pais'" appearance="standard" class="form-group w-100-p">
                <mat-label>{{datosFiltro?.label}}</mat-label>
                <mat-select [formControlName]="datosFiltro?.name" class="form-control">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="paisesFilterCtrl" [searching]="paisesSearching"
                        placeholderLabel="Buscar país..." noEntriesFoundLabel="No se encontró ningún país"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of paises" [value]="item.id">{{item.name}}</mat-option>
                </mat-select>
                <button *ngIf="checkValue(datosFiltro?.name)" mat-button matSuffix mat-icon-button (click)="clearValue(datosFiltro?.name, $event)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field *ngIf="datosFiltro?.name == 'aptitudes_productos'" appearance="standard" class="form-group w-100-p">
                <mat-label>{{datosFiltro?.label}}</mat-label>
                <mat-select [formControlName]="datosFiltro?.name" class="form-control">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="productosFilterCtrl" [searching]="productosSearching"
                        placeholderLabel="Buscar producto..." noEntriesFoundLabel="No se encontró ningún producto"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of productos" [value]="item.id">{{item.name}}</mat-option>
                </mat-select>
                <button *ngIf="checkValue(datosFiltro?.name)" mat-button matSuffix mat-icon-button (click)="clearValue(datosFiltro?.name, $event)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field *ngIf="datosFiltro?.name == 'aptitudes_gamas'" appearance="standard" class="form-group w-100-p">
                <mat-label>{{datosFiltro?.label}}</mat-label>
                <mat-select [formControlName]="datosFiltro?.name" class="form-control">
                    <mat-option *ngFor="let item of gamas" [value]="item.id">{{item.name}}</mat-option>
                </mat-select>
                <button *ngIf="checkValue(datosFiltro?.name)" mat-button matSuffix mat-icon-button (click)="clearValue(datosFiltro?.name, $event)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field *ngIf="datosFiltro?.name == 'aptitudes_marcas'" appearance="standard" class="form-group w-100-p">
                <mat-label>{{datosFiltro?.label}}</mat-label>
                <mat-select [formControlName]="datosFiltro?.name" class="form-control">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="marcasFilterCtrl" [searching]="marcasSearching"
                        placeholderLabel="Buscar marca..." noEntriesFoundLabel="No se encontró ninguna marca"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of marcas" [value]="item.id">{{item.name}}</mat-option>
                </mat-select>
                <button *ngIf="checkValue(datosFiltro?.name)" mat-button matSuffix mat-icon-button (click)="clearValue(datosFiltro?.name, $event)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field *ngIf="datosFiltro?.name == 'realiza_onsite'" appearance="standard" class="form-group w-100-p">
                <mat-label>Realiza Onsite</mat-label>
                <mat-select name="realiza_onsite" formControlName="realiza_onsite" class="form-control">
                    <mat-option *ngFor="let item of comboSiNo" [value]="item.id">{{item.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngIf="datosFiltro?.name == 'valoracion'" fxLayout="row" class="form-group w-200 mr-16">
                <mat-card class="card-valoracion-filtro">
                    <mat-card-title><label>Valoración</label></mat-card-title>
                    <mat-card-content>
                        <div fxLayout="row">
                            <bar-rating [(rate)]="valoracionesValor" [max]="5" [theme]="'fontawesome'" class="size-filter" (rateChange)="changeFiltroValoracion($event)"></bar-rating>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button class="btn-aitana" (click)="guardarDatos()">
        Aplicar
    </button>
    <button mat-button mat-stroked-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>