import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { GridConfigService } from 'app/services/grid/grid-config.service';
import { Subject } from 'rxjs';
import { DialogGuardarFiltrosGridComponent } from '../dialog-guardar-filtros-grid/dialog-guardar-filtros-grid.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-configuracion-grid',
  templateUrl: './dialog-configuracion-grid.component.html',
  styleUrls: ['./dialog-configuracion-grid.component.scss']
})
export class DialogConfiguracionGridComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	idUsuario: number;
	idConfig: string;
	gridName: string;
	pageSizeOptions: Array<any>;
	filtros: Array<any>;
	sorts: Array<any>;
	columns: Array<any>;
	displayedColumns: Array<string>;
	hideColumns: Array<string>;

	formConfig: FormGroup;

	proyectoFiltro: number = null;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogGuardarFiltrosGridComponent>,
		private formBuilder: FormBuilder,
		private dialog: MatDialog, 
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		public gridConfigSvc: GridConfigService,
	) {
		this._unsubscribeAll = new Subject();

		this.formConfig = this.formBuilder.group({
			pageSize: [''],
			filtro: [''],
			sort: [''],
		});
		this.columns = [];
		this.hideColumns = [];
		this.displayedColumns = [];
	}

	ngOnInit(): void {
		if (this.data.grid) {
			this.idConfig = this.data.idConfig;
			this.gridName = this.data.grid;			
			const arraygridName = this.idConfig.toString().split("_");		
			if(arraygridName.length == 3){
				this.proyectoFiltro = Number(arraygridName[2]);
			}
			this.idUsuario = this.data.idUser;
			this.pageSizeOptions = this.data.pageSizeOptions;
			this.filtros = this.data.filtros;
			this.sorts = this.data.sorts;
			this.columns = this.data.columns;
			this.displayedColumns = this.data.displayedColumns;
			this.hideColumns = this.columns.filter((item:any) => this.displayedColumns.indexOf(item.id) < 0).map((item:any) => { return item.id; });
			console.log("columns", this.columns);
			console.log("Hide columns", this.hideColumns);
			console.log("Datos Grid", this.data);
			console.log("Proyecto", this.proyectoFiltro);
			this.formConfig.get("pageSize").setValue(this.data.pageSize);
			if (this.idConfig) {
				this.loadDataConfiguracion();
			}
		}
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}

	loadDataConfiguracion() {
		this.gridConfigSvc.getConfiguracion(this.gridName, this.proyectoFiltro).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data:any) => {
				if (data.filtro) {
					this.formConfig.get("filtro").setValue(data.filtro.id);
				}
				if (data.sort) {
					this.formConfig.get("sort").setValue(data.sort.id);
				}
			},
			(err) => {
				// this.showSnack(err);
			}
		);
	}

	checkValue(formControlName: string) {
		let formControl = this.formConfig.get(formControlName);
		if (formControl) {
			return formControl.value !== "";
		}
		else {
			return false;
		}
	}
	clearValue(formControlName: string, event?) {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		let formControl = this.formConfig.get(formControlName);
		if (formControl) {
			formControl.setValue('');
		}
	}

	dropColumn(event: CdkDragDrop<any[]>) {
		console.log("event drop", event);
		console.log("displayed columns", this.displayedColumns.length);
		if (event.previousContainer === event.container) {
			if (event.currentIndex < (this.displayedColumns.length-1)) {
				moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
			}
			else {
				moveItemInArray(event.container.data, event.previousIndex, event.currentIndex-1);
			}
		} 
		else {
		  	transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
		}
	}

	reiniciar() {
		this.formConfig.get("pageSize").setValue(20);
		this.formConfig.get("filtro").setValue("");
		this.formConfig.get("sort").setValue("");

		this.displayedColumns = this.columns.map((item) => { return item.id; });
		this.hideColumns = [];
	}

	guardarDatos() {
		const formValue = this.formConfig.value;
		// console.log("columnas visibles", this.displayedColumns);
		// console.log("formValue", formValue);

		if (this.displayedColumns.length == 0) {
			this.showSnack(this.translateService.instant('COMUN.UNA_COLUMNA_VISIBLE'));
		}
		else {
			// const result = {
			// 	displayedColumns: this.displayedColumns,
			// 	pageSize: formValue.pageSize,
			// 	filtro: formValue.filtro,
			// 	sort: formValue.sort
			// };
	
			console.log('Datos configuracion guardar grid',this.proyectoFiltro,this.gridName);
			this.gridConfigSvc.guardarConfiguracion(this.gridName, formValue.pageSize, formValue.filtro, formValue.sort, this.displayedColumns,this.proyectoFiltro).pipe(takeUntil(this._unsubscribeAll)).subscribe(
				(data: any) => {
					this.showSnack(this.translateService.instant('COMUN.CONFIGURACION_GUARDADA'));
					this.dialogRef.close({ data });
				},
				(err) => {
					this.showSnack(err);
				}
			);
		}
	}

}
