<form [formGroup]="oportunidadForm" (submit)="submit()">
    <h2 mat-dialog-title>Nueva Oportunidad</h2>
    <div mat-dialog-content>
        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="60%">
                <mat-form-field class="form-field">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="nombre" required>
                    <mat-error *ngIf="oportunidadForm.controls.nombre.touched && oportunidadForm.controls.nombre.invalid">
                        <span *ngIf="oportunidadForm.controls.nombre.errors.required">Este campo es obligatorio</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxFlex="40%">
                <mat-form-field class="form-field">
                    <mat-label>Embudo</mat-label>
                    <mat-select formControlName="fk_embudo">
                        <ng-container *ngFor="let embudo of embudos | async">
                            <mat-option value="{{embudo.id}}" ng-value="embudo.id">{{embudo.nombre}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="40%">
                <mat-form-field class="form-field">
                    <mat-label>Contacto</mat-label>
                    <mat-select formControlName="fk_contacto">
                        <ng-container *ngFor="let persona of personas | async">
                            <mat-option value="{{persona.id}}" ng-value="persona.id">{{persona.nombre}} {{persona.segundo_nombre}} {{persona.apellido}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Etapa</mat-label>
                    <mat-select formControlName="fk_etapa" required>
                        <ng-container *ngFor="let etapa of etapas | async">
                            <mat-option value="{{etapa.id}}" ng-value="etapa.id">{{etapa.nombre}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error *ngIf="oportunidadForm.controls.fk_etapa.touched && oportunidadForm.controls.fk_etapa.invalid">
                        <span *ngIf="oportunidadForm.controls.fk_etapa.errors.required">Este campo es obligatorio</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Estado</mat-label>
                    <mat-select formControlName="fk_estado">
                        <ng-container *ngFor="let estado of estados | async">
                            <mat-option value="{{estado.id}}" ng-value="estado.id">{{estado.nombre}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Fuente</mat-label>
                    <mat-select formControlName="fk_fuente">
                        <ng-container *ngFor="let fuente of fuentes | async">
                            <mat-option value="{{fuente.id}}" ng-value="fuente.id">{{fuente.nombre}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="20%">
                <mat-form-field class="form-field">
                    <mat-label>Prioridad</mat-label>
                    <mat-select formControlName="prioridad">
                        <mat-option value="1">Sí</mat-option>
                        <mat-option value="0">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="20%">
                <mat-form-field class="form-field">
                    <mat-label>Valor</mat-label>
                    <input formControlName="valor" type="number" matInput>
                    <mat-icon matSuffix>€</mat-icon>
                </mat-form-field>
            </div>
            
            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Fecha cierre</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="fecha_cierre" readonly required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="oportunidadForm.controls.fecha_cierre.touched && oportunidadForm.controls.fecha_cierre.invalid">
                        <span *ngIf="oportunidadForm.controls.fecha_cierre.errors.required">Este campo es obligatorio</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="100%">
                <mat-form-field class="form-field">
                    <mat-label>Descripción</mat-label>
                    <textarea formControlName="descripcion" matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-flat-button [mat-dialog-close]="true" type="button">Cancelar</button>
        <button *ngIf="buttonsEnable" mat-raised-button color="primary" type="submit">Guardar</button>
        <button *ngIf="!buttonsEnable" mat-raised-button color="primary" type="button">Guardar</button>
    </div>            
</form>