import { Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ItemDecisionTree {
    idQuestion: string,
    question: string,
	type?: 'filter',
    hint?: string,
    options: Array<OptionsQuestionTree>
}

export interface OptionsQuestionTree {
    id: string,
    name: string,
    hint?: string,
    idQuestion?: string,
    result?: ResultQuestionTree
}

export interface ResultQuestionTree {
	text: string,
	data: any
}

@Component({
	selector: 'app-arbol-decision-dialog',
	templateUrl: './arbol-decision-dialog.component.html',
	styleUrls: ['./arbol-decision-dialog.component.scss']
})
export class ArbolDecisionDialogComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	titulo: string;
	devicePN: string;
	arbolDecision: Array<ItemDecisionTree>;

	questions: Array<ItemDecisionTree>;
	resultado: ResultQuestionTree;
	optionsSelected: Array<any>;

	filtersQuestion: any;
	optionsQuestion: any;

	@ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

	private _scrollBar: FusePerfectScrollbarDirective;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<ArbolDecisionDialogComponent>,
		private dialog: MatDialog, 
		private formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
	) {
		moment.locale(this.translateService.currentLang);
		this._unsubscribeAll = new Subject();

		this.questions = [];
		this.optionsSelected = [];

		this.filtersQuestion = {};
		this.optionsQuestion = {};
	}

	ngOnInit(): void {
		this.titulo = this.data.titulo;
		this.devicePN = this.data.devicePN ? this.data.devicePN : null;
		this.arbolDecision = this.data.arbolDecision;
		console.log("Arbol", this.arbolDecision);

		this.createFiltersQuestions();
		this.addQuestion();
	}

	ngAfterViewInit(): void  {
        this._scrollBar = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'asistente-content';
        });
    }

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}

	private _scrollBarBottom(): void  {
        setTimeout(() => {
            // Scroll to the bottom of the messages list
            if ( this._scrollBar ) {
                this._scrollBar.update();

                setTimeout(() => {
                    this._scrollBar.scrollToBottom(0);
                });
            }
        });
    }

	createFiltersQuestions() {
		const questionsFilters = this.arbolDecision.filter((itemArbol) => {
			return itemArbol.type && itemArbol.type === 'filter';
		});

		for (const question of questionsFilters) {
			this.filtersQuestion[question.idQuestion] = new FormControl();
			this.optionsQuestion[question.idQuestion] = new ReplaySubject<any[]>(1);
		}

		for (const keyFilter of Object.keys(this.filtersQuestion)) {
			const filterCtrl = this.filtersQuestion[keyFilter];
			const optionsFiltered = this.optionsQuestion[keyFilter];
			const question =  this.arbolDecision.find((itemArbol) => {
				return itemArbol.idQuestion === keyFilter;
			});

			if (filterCtrl && optionsFiltered && question) {
				filterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((filterValue) => {
					if (filterValue != "" && filterValue.length < 3) {
						optionsFiltered.next([]);
						return;
					}
					else if (filterValue === "") {
						const seleccion = this.optionsSelected.find((item) => { return item.idQuestion === question.idQuestion; });
						if (seleccion) {
							optionsFiltered.next(
								question.options.filter(item => {
									return item.id === seleccion.idOpcion;
								})
							);
						}
						return;
					}
		
					optionsFiltered.next(
						question.options.filter(item => {
							return item.name.toLowerCase().includes(filterValue.toLowerCase());
						})
					);
				});
			}
		}
	}

	addQuestion(idQuestion?: string) {
		let question;

		if (idQuestion) {
			question = this.arbolDecision.find((itemArbol) => {
				return itemArbol.idQuestion === idQuestion;
			});

		}
		else {
			question = this.arbolDecision.length > 0 ? this.arbolDecision[0] : null;
		}

		if (question) {
			this.questions.push(question);
		}
	}

	isSelected(idQuestion: string, idOpcion: string) {
		let selected = false;

		const optionSel = this.optionsSelected.find((item) => {
			return item.idQuestion === idQuestion && item.idOpcion === idOpcion;
		});
		if (optionSel) {
			selected = true;
		}

		return selected;
	}

	guardarSeleccion(idQuestion: string, idOpcion: string) {
		const seleccion = this.optionsSelected.find((item) => { return item.idQuestion === idQuestion; });
		if (seleccion) {
			seleccion.idOpcion = idOpcion;
		}
		else {
			this.optionsSelected.push({ idQuestion: idQuestion, idOpcion: idOpcion });
		}
	}

	selectOption(idQuestion: string, option: OptionsQuestionTree) {
		this.guardarSeleccion(idQuestion, option.id);

		const indexQuestion = this.questions.findIndex((item) => { return item.idQuestion === idQuestion; });
		if (indexQuestion !== (this.questions.length-1)) {
			this.questions.splice(indexQuestion+1);
			this.optionsSelected.splice(indexQuestion+1);
		}
		
		if (option.idQuestion) {
			this.resultado = null;
			this.addQuestion(option.idQuestion);
		}
		else if (option.result) {
			this.resultado = option.result;
			//Nos quedamos con la pieza del modelo en el ticket
			if(this.resultado.data && this.resultado.data.piezas)
				this.resultado.data.piezas = this.resultado.data.piezas.filter((pieza) => pieza.devicePN == this.data.devicePN);
		}
		this._scrollBarBottom();
	}

	opcionSeleccionada(event, idQuestion: string) {
		const question = this.arbolDecision.find((item) => { return item.idQuestion === idQuestion; });
		if (question) {		
			const option = question.options.find((itemOp) => { return itemOp.id === event.value; });
			if (option) {
				if (question.type && question.type === 'filter') {
					this.optionsQuestion[question.idQuestion].next([option]);
				}

				this.selectOption(idQuestion, option);
			}
			else {
				this.showSnack("Respuesta no encontrada");
			}
		}
		else {
			this.showSnack("Pregunta no encontrada");
		}
	}

	hintOptionQuestion(idQuestion: string) {
		let hint = '';

		const dataSelected = this.optionsSelected.find((item) => { return item.idQuestion === idQuestion; });
		if (dataSelected) {
			const question = this.arbolDecision.find((item) => { return item.idQuestion === dataSelected.idQuestion; });
			if (question) {
				const option = question.options.find((itemOp) => { return itemOp.id === dataSelected.idOpcion; });
				if (option && option.hint) {
					hint = option.hint;
				}
			}
		}

		return hint;
	}

	getCodigoError() {
		let codigoError = "";

		const firstSelected = this.optionsSelected[0];
		const questionFirst = this.arbolDecision.find((item) => {
			return item.idQuestion === firstSelected.idQuestion;
		});
		if (questionFirst) {
			const optionSel = questionFirst.options.find((item) => {
				return item.id === firstSelected.idOpcion;
			});

			if (optionSel) {
				codigoError = optionSel.name;
			}
		}

		return codigoError;
	}

	aplicarResultado() {
		this.resultado.data['codigoError'] = this.getCodigoError();
		this.dialogRef.close(this.resultado.data);
	}

}
