import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Validators, FormGroup, FormControl, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';

import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject } from 'rxjs';

import { ClienteService } from 'app/services/cliente/cliente.service';

@Component({
  selector: 'app-buscar-cliente',
  templateUrl: './buscar-cliente.component.html',
  styleUrls: ['./buscar-cliente.component.scss']
})
export class BuscarClienteComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	formBuscadorCliente: FormGroup;
	loadingSearch: boolean;

	tiposClienteData: ReplaySubject<any[]>;
	paises: Array<any>;
	paisFilterCtrl: FormControl;
	paisesFiltered: ReplaySubject<any[]>;
	provincias: Array<any>;
	provinciaFilterCtrl: FormControl;
	provinciasFiltered: ReplaySubject<any[]>;
	localidades: Array<any>;
	searchingLocalidades: boolean = false;
	localidadFilterCtrl: FormControl;
	localidadSelected: any;

	clientesEncontrados: Array<any>;
	displayedColumnsClientes: string[] = ['nombre','email', 'direccion', 'actions'];
	dataSourceClientes: MatTableDataSource<any>;

	constructor(
		private dialogRef: MatDialogRef<BuscarClienteComponent>,
		private dialog: MatDialog, 
		private formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private clienteSvc: ClienteService,
	) {
		moment.locale(this.translateService.currentLang);
		this._unsubscribeAll = new Subject();

		this.formBuscadorCliente = this.formBuilder.group({
			tipo_cliente: [''],
			nombreApellido: [''],
			cif_nif: [''],
			telefono: [''],
			email: [''],
			direccion: [''],
			id_pais: [''],
			id_provincia: [''],
			id_localidad: [''],
		}, { validators: this.atLeastOneValidator });
		
		this.tiposClienteData = new ReplaySubject<any[]>(1);
		this.paises = [];
		this.paisFilterCtrl = new FormControl();
		this.paisesFiltered = new ReplaySubject<any[]>(1);
		this.provincias = [];
		this.provinciaFilterCtrl = new FormControl();
		this.provinciasFiltered = new ReplaySubject<any[]>(1);
		this.localidades = [];
		this.localidadFilterCtrl = new FormControl();

		// this.clientesEncontrados = [];
		this.loadingSearch = false;
		this.dataSourceClientes = new MatTableDataSource();

		this.loadEventosCombos();
	}

	public atLeastOneValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
		let controls = control.controls;
		// console.log(controls);
		if (controls) {
			let theOne = Object.keys(controls).findIndex(key => controls[key].value !== '');
			if (theOne === -1) {
				return {
					atLeastOneRequired: { text: 'Para buscar un cliente debe indicar al menos un parámetro de búsqueda' }
				}
			}
		};
	}

	ngOnInit(): void {
		this.loadTiposCliente();
		this.loadPaises();
		this.loadProvincias();

		this.paisFilterCtrl.valueChanges.subscribe((filterValue) => {
			if (!filterValue) {
				this.paisesFiltered.next(this.paises.slice());
				return;
			}

			this.paisesFiltered.next(
				this.paises.filter(item => {
					return item.name.toLowerCase().includes(filterValue.toLowerCase());
				})
			);
		});
		this.provinciaFilterCtrl.valueChanges.subscribe((filterValue) => {
			if (!filterValue) {
				this.provinciasFiltered.next(this.provincias.slice());
				return;
			}

			this.provinciasFiltered.next(
				this.provincias.filter(item => {
					return item.name.toLowerCase().includes(filterValue.toLowerCase());
				})
			);
		});
		this.localidadFilterCtrl.valueChanges.subscribe((filterValue) => {
			if (filterValue.length > 0) {
				this.searchingLocalidades = true;
				this.loadLocalidades(filterValue);
			}
			else {
				this.localidades = [];
				if (this.localidadSelected) {
					this.localidades.push(this.localidadSelected);
				}
			}
		});
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 1000
		});
	}

	loadEventosCombos() {
		let paisCtrl = this.formBuscadorCliente.get('id_pais');
		let provinciaCtrl = this.formBuscadorCliente.get('id_provincia');
		let localidadCtrl = this.formBuscadorCliente.get('id_localidad');

		paisCtrl.valueChanges.subscribe((idPais) => {
			this.loadProvincias();
		});
		
		provinciaCtrl.valueChanges.subscribe((idProvincia) => {
			localidadCtrl.setValue("");
			this.localidadSelected = null;
			this.localidades = [];
		});

		localidadCtrl.valueChanges.subscribe((idLocalidad) => {
			const localidad = this.localidades.find((item) => {
				return item.id === idLocalidad;
			});
			this.localidadSelected = localidad;
		});
	}

	loadTiposCliente() {
		this.clienteSvc.getTiposCliente().subscribe(
			(data:any) => {
				this.tiposClienteData.next(data.slice());
			}
		)
	}
	loadPaises() {
		this.clienteSvc.getPaises().subscribe(
			(data: any) => {
				this.paises = data.map(item => {
					return { id: item.id, name: item.pais };
				});
				this.paisesFiltered.next(this.paises.slice());
			},
			(err) => {
				this.showSnack(err);
			}
		);
	}
	loadProvincias() {
		let formControl = this.formBuscadorCliente.get('id_pais');

		this.clienteSvc.getProvincias(formControl.value).subscribe(
			(data: any) => {
				this.provincias = data.map(item => {
					return { id: item.idprovincia, name: item.provincia };
				});
				this.provinciasFiltered.next(this.provincias.slice());
			},
			(err) => {
				this.showSnack(err);
			}
		);
	}
	loadLocalidades(filter?: string): void {
		let formControl = this.formBuscadorCliente.get('id_provincia');

		this.clienteSvc.getLocalidades(filter, formControl.value).subscribe(
			(data:any) => {
				this.searchingLocalidades = false;
				this.localidades = data.map(item => {
					return { id: item.idpoblacion, name: item.postal + ' - ' + item.poblacion };
				});
			},
			(err) => {
				this.searchingLocalidades = false;
				this.showSnack(err);
			}
		);
	}

	checkValue(formControlName: string) {
		let formControl = this.formBuscadorCliente.get(formControlName);
		if (formControl) {
			return formControl.value !== "";
		}
		else {
			return false;
		}
	}

	clearSelect(event, formControlName: string) {
		event.preventDefault();
		event.stopPropagation();

		let formControl = this.formBuscadorCliente.get(formControlName);
		if (formControl) {
			formControl.setValue('');
		}
	}

	formatNombre(cliente: any) {
		let cadNombre = "";

		if (cliente.nombre && cliente.nombre != "") {
			cadNombre += cliente.nombre;
		}
		if (cliente.apellido && cliente.apellido != "") {
			cadNombre += cadNombre != "" ? " " : "";
			cadNombre += cliente.apellido;
		}
		if (cadNombre == "") {
			cadNombre = " - ";
		}

		return cadNombre;
	}

	formatDireccion(cliente: any) {
		let direccionFormat = "";

		let direccion = null;
		if (cliente.direcciones.length > 0) {
			if (cliente.direcciones.length == 1) {
				direccion = cliente.direcciones[0];
			}
			else {
				direccion = cliente.direcciones.find((item) => { return item.tipo_direccion = 4; });
				if (!direccion) {
					direccion = cliente.direcciones[0];
				}
			}
		}

		if (direccion) {
			direccionFormat += direccion.direccion;
			
			if (direccion.num_casa && direccion.num_casa !== "") {
				direccionFormat = direccionFormat !== "" ? direccionFormat + ", " : direccionFormat;
				direccionFormat += direccion.num_casa;
			}
			if (direccion.localidad) {
				direccionFormat = direccionFormat !== "" ? direccionFormat + ", " : direccionFormat;
				direccionFormat += direccion.localidad.poblacion;
			}
			if (direccion.provincia) {
				direccionFormat = direccionFormat !== "" ? direccionFormat + ", " : direccionFormat;
				direccionFormat += direccion.provincia.provincia;
				
				if (direccion.provincia.pais) {
					direccionFormat +=  " (" + direccion.provincia.pais.pais + ")";
				}
			}
		}

		

		if (direccionFormat === "") {
			direccionFormat = " - ";
		}

		return direccionFormat;
	}

	resetForm() {
		const controls = this.formBuscadorCliente.controls;
		const keyControls = Object.keys(controls);

		for (const key of keyControls) {
			controls[key].setValue('', {emitEvent: false});

			if (key === 'id_localidad') {
				this.localidadSelected = null;
				this.localidades = [];
			}
		}

		this.clientesEncontrados = null;
	}

	buscarCliente() {
		const formValue = this.formBuscadorCliente.value;
		this.loadingSearch = true;
		this.clienteSvc.buscarClientes(formValue).subscribe(
			(data: any) => {
				this.loadingSearch = false;
				this.clientesEncontrados = data.elements;
				this.dataSourceClientes.data = this.clientesEncontrados.slice();
			},
			(err) => {
				this.loadingSearch = false;
				this.showSnack(err);
			}
		)
	}

	seleccionarCliente(cliente: any) {
		this.dialogRef.close(cliente);
	}

}
