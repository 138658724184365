import { Component, Inject, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DiagnosisService } from 'app/services/diagnosis/diagnosis.service';

@Component({
  selector: 'app-dialog-buscar-piezas',
  templateUrl: './dialog-buscar-piezas.component.html',
  styleUrls: ['./dialog-buscar-piezas.component.scss']
})
export class DialogBuscarPiezasComponent implements OnInit {

	displayedColumns: string[] = ['description', 'pn', 'actions'];
	dataSource: MatTableDataSource<any>;
	piezas: Array<any>;

	numPage: number;
	pageSize: number;

	currentOrder: string;
	currentDirection: string;
	totalElementos: number;

	filtros = {
		description: "",
		pn: "",
	}
	idTicket: number;
	idMarca: number;
	idModelo: number;
	idTipoDispositivo: string;
	sintoma: any;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogBuscarPiezasComponent>,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private diagnosisSvc: DiagnosisService
	) {
		this.numPage = 0;
		this.pageSize = 5;
		this.totalElementos = 0;
		this.dataSource = new MatTableDataSource();
		this.piezas = [];
	}

	ngOnInit(): void {
		this.idTicket = this.data.idTicket;
		this.idMarca = this.data.idMarca;
		this.idModelo = this.data.idModelo;
		this.idTipoDispositivo = this.data.idTipoDispositivo;
		this.sintoma = this.data.sintoma;
		this.loadPiezas();
	}

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 1000
		});
	}

	sortChange(event) {
		console.log("sortchange", event);
		if (event.direction !== "") {
			this.currentOrder = event.active;
			this.currentDirection = event.direction;
		}
		else {
			this.currentDirection = "";
			this.currentOrder = "";
		}
		this.loadPiezas();
	}

	pageChanged(event: PageEvent) {
		console.log("pageChange", event);
		this.numPage = event.pageIndex;
		this.pageSize = event.pageSize;
		this.loadPiezas();
	}

	filtrar() {
		console.log("filtros", this.filtros);
		this.numPage = 0;
		this.loadPiezas();
	}

	isFiltrosActivos(): boolean {
		let filtrosActivos = false;

		if (this.filtros.description !== "" || this.filtros.pn !== "") 
		{
			filtrosActivos = true;
		}

		return filtrosActivos;
	}

	resetFiltros() {
		this.filtros = {
			description: "",
			pn: "",
		}
		this.numPage = 0;
		this.loadPiezas();
	}

	seleccionarPieza(pieza: any): void {
		this.dialogRef.close(pieza);
	}

	loadPiezas(): void {
		this.diagnosisSvc.getPiezas(this.numPage, this.pageSize, this.currentOrder, this.currentDirection, this.idTicket, this.idMarca, this.idModelo, this.idTipoDispositivo, this.filtros).subscribe(
			(data:any) => {
				this.piezas = data.elements;
				this.totalElementos = data.totalElements;
				this.dataSource.data = this.piezas;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

}
