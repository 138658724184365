import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class TecnicosService {

	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
	) { }

	getTecnicos(idProyecto: number, filtro: string, colaboradorId?: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			let params = {
				tecnico: filtro,
				proyecto: idProyecto,
			}
			if (colaboradorId) {
				params['colaborador'] = colaboradorId;
			}

			const url = environment.API_URL + '/tecnicos?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTecnicos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTecnicos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getSubtecnicos(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/tecnicos/subtecnicos';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getSubtecnicos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getSubtecnicos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getTecnicosExperimentados(ticket: number, idMarca: number, idModelo: number, tipoDispositivo: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				ticketId: ticket,
				marcaId: idMarca,
				modeloId: idModelo,
				tipo: tipoDispositivo
			}

			const url = environment.API_URL + '/tecnicos/top-experimentados?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTecnicosExperimentados", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTecnicosExperimentados", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
}
