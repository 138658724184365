import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/services/auth/auth.service';

import { ProyectosService } from 'app/services/proyectos/proyectos.service';
import { UserService } from 'app/services/user/user.service';
import { DialogConfirmComponent } from '../util/dialog-confirm/dialog-confirm.component';

import { DialogConfiguracionProyectosComponent } from './dialog-configuracion-proyectos/dialog-configuracion-proyectos.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-selector-proyectos',
  templateUrl: './selector-proyectos.component.html',
  styleUrls: ['./selector-proyectos.component.scss']
})
export class SelectorProyectosComponent implements OnInit {

	@ViewChild('searchProject') searchProject: ElementRef;
	searchUpdated: Subject<string> = new Subject<string>();

	showMenuProject: boolean;
	usuario: any;
	proyectos: Array<any>;
	configuracionesProyectos: Array<any>;
	projectSelected: any;

	numPage: number;
	pageSize: number;
	hasMore: boolean;

	filtrandoProyectos: boolean;

	constructor(
		private dialog: MatDialog, 
		private snackBar: MatSnackBar,
		private proyectosSvc: ProyectosService,
		private userSvc: UserService,
		private authSvc: AuthService,
	) {
		this.showMenuProject = false;
		this.proyectos = [];
		this.configuracionesProyectos = [];

		this.numPage = 0;
		this.pageSize = 10;
		this.hasMore = false;

		this.filtrandoProyectos = false;

		this.searchUpdated.pipe(debounceTime(300), distinctUntilChanged()).subscribe(valueFiltro => {
			this.loadProyectos(valueFiltro);
		});
	}

	ngOnInit(): void {
		this.proyectosSvc.onProjectsUserInitialize.subscribe(
			(data: any) => {
				this.projectSelected = data;
			}
		);
		this.proyectosSvc.onProjectSelected.subscribe(
			(data: any) => {
				this.projectSelected = data;
			}
		);
		if (this.authSvc.isUserLoggedIn()) {
			this.projectSelected = this.proyectosSvc.getProjectsSeleted();
			
			this.userSvc.getUsuario().subscribe(
				(data: any) => {
					this.usuario = data;
				}
			);
			this.loadConfiguracionesUsuario();
		}
	}

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 1000
		});
	}

	loadConfiguracionesUsuario() {
		this.proyectosSvc.getConfigProjectsUser().subscribe(
			(data: any) => {
				this.configuracionesProyectos = data;
			},
			err => {
				this.showSnack(err);
			}
		)
	}

	openMenuProject() {
		this.showMenuProject = true;
		setTimeout(() => {
			this.searchProject.nativeElement.focus();
		});
	}
	closeMenuProject() {
		this.showMenuProject = false;
		this.searchProject.nativeElement.value = "";
		this.proyectos = [];
	}

	search(event): void {
		const valueFiltro = event.target.value;

		if (valueFiltro.length > 0) {
			this.hasMore = false;
			this.numPage = 0;
			this.filtrandoProyectos = true;
			this.searchUpdated.next(valueFiltro);
		}
	}

	loadProyectos(filtro) {
		this.proyectosSvc.getProjects(this.numPage, this.pageSize, filtro).subscribe(
			(data: any) => {
				if (this.filtrandoProyectos) {
					this.proyectos = [];
				}
				this.proyectos = this.proyectos.concat(data.elements);
				this.hasMore = data.hasMore;
				this.numPage++;
				this.filtrandoProyectos = false;
			},
			(err) => {
				this.filtrandoProyectos = false;
				this.showSnack(err);
			}
		)
	}

	selectProject(dataProject: any) {
		// console.log("select proyecto", dataProject);
		this.proyectosSvc.selectProject(dataProject).subscribe(
			(data: any) => {
				this.closeMenuProject();
			},
			err => {
				this.showSnack(err);
			}
		);
	}

	selectConfigProject(dataConfig: any) {
		// console.log("select configuracion", dataConfig);
		this.proyectosSvc.selectConfigProject(dataConfig).subscribe(
			(data: any) => {
				this.closeMenuProject();
			},
			err => {
				this.showSnack(err);
			}
		);
	}

	selectDefaultProjects(event) {
		event.stopPropagation();

		this.proyectosSvc.selectDefaultProject().subscribe(
			(data:any) => {
				// No hago nada
			},
			err => {
				this.showSnack(err);
			}
		)
	}

	openModalConfigProyectos(event, dataConfig?: any) {
		event.stopPropagation();
		
		this.showMenuProject = false;
		const dialogRef = this.dialog.open(DialogConfiguracionProyectosComponent, {
			data: {
				configProject: dataConfig
			},
			panelClass: 'panel-aitana',
			// maxWidth: '70vw',
			disableClose: true,
			autoFocus: false
		});
		dialogRef.updatePosition({ top: '4rem' });

		dialogRef.afterClosed().subscribe(result => {
			if (result && result !== undefined) {
				this.loadConfiguracionesUsuario();
			}
		});
	}

	openConfirmDeleteConfig(event, dataConfig: any) {
		event.stopPropagation();

		const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: { 
				titulo: "Eliminar configuración", 
				mensaje: "Se va a eliminar la configuración \"" + dataConfig.nombre + "\", ¿está seguro?",
				btnLabelOk: "Sí",
				btnLabelCancel: "No"
			},
			panelClass: 'panel-aitana',
			// maxWidth: '70vw',
			disableClose: true,
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result === true) {
				this.eliminarConfiguracion(dataConfig);
			}
			else {
				console.log("Eliminacion cancelada");
			}
		});
	}

	eliminarConfiguracion(dataConfig: any) {
		// console.log("dataconfig", dataConfig);
		this.proyectosSvc.deleteConfigProjects(dataConfig.ID).subscribe(
			(data:any) => {
				const index = this.configuracionesProyectos.findIndex((item) => {
					return item.ID === dataConfig.ID;
				});
				if (index >= 0) {
					this.configuracionesProyectos.splice(index, 1);
				}

				this.loadConfiguracionesUsuario();
			},
			err => {
				this.showSnack(err);
			}
		)
	}

}
