import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { Block } from 'notiflix';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';

import { UserService } from 'app/services/user/user.service';
import { DiagnosisService } from 'app/services/diagnosis/diagnosis.service';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from 'app/services/helper/helper.service';


export interface Mensaje {
    who: 'contact' | 'user',
    avatar: string,
    type: 'message' | 'action' | 'input' | 'list-data' | 'message-error',
    disabled?: boolean,
    message?: string,
    error?: boolean,
    success?: boolean,
    actions?: Array<ActionMensaje>,
    input?: string,
    listName?: string,
    data?: any
}

export interface ActionMensaje {
    action: 'iniciar' | 'reiniciar' | 'sintomaSi' | 'sintomaNo' | 'sintomaNS',
    text?: string,
    icon?: string,
    title?: string,
    id?: string,
    nombre?: string,
    selected?: boolean,
}


@Component({
  selector: 'diagnosis-bot',
  templateUrl: './diagnosis-bot.component.html',
  styleUrls: ['./diagnosis-bot.component.scss']
})
export class DiagnosisBotComponent implements OnInit, AfterViewInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

    usuario: any;

    avatarBot: string = "assets/images/autodiagnosis-bot/adbot.png";
    mensajes: Array<Mensaje>;
    sintomas: any;
    sintomasAceptados: Array<any>;
    idTicket: number;
    posiblesSintomas: Array<any>;
    indexPosibleSintoma: number;
    totalDiagnosis: number;
    diagnosticos: Array<any>;

    tiposElementos = [
        { id: 'NB', icon: 'fa-laptop', nombre: 'Portatil', selected: false },
        { id: 'DESKTOP', icon: 'fa-desktop', nombre: 'PC', selected: false },
        { id: 'TABLET', icon: 'fa-tablet-alt', nombre: 'Tablet', selected: false },
        { id: 'SMARTPHONE', icon: 'fa-mobile-alt', nombre: 'Smartphone', selected: false },
    ];
    tipoElemento: string;
    idDefecto: number;
    descripcionProblema: string;

    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    private _chatViewScrollbar: FusePerfectScrollbarDirective;

	constructor(
		private translateService: TranslateService,
		private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private snackBar: MatSnackBar,
        private userSvc: UserService,
        private helperSvc: HelperService,
        private diagnosisSvc: DiagnosisService

	) {
		moment.locale(this.translateService.currentLang);
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(english, spanish);

        this.mensajes = [];
        this.indexPosibleSintoma = 0;
        this.posiblesSintomas = [];
        this.diagnosticos = [];
	}

	ngOnInit(): void {
        this.loadUser();
        this.initChat();
	}

    ngAfterViewInit(): void
    {
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'messages-autodiagnosis';
        });
    }

	ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private _prepareChatForReplies(): void
    {
        setTimeout(() => {

            // Focus to the reply input
            // this._replyInput.nativeElement.focus();

            // Scroll to the bottom of the messages list
            if ( this._chatViewScrollbar )
            {
                this._chatViewScrollbar.update();

                setTimeout(() => {
                    this._chatViewScrollbar.scrollToBottom(0);
                });
            }
        });
    }

    showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}

    getLangUser() {
        let langUser = "es";
        if (this.usuario) {
            langUser = this.usuario.language ? this.usuario.language : "es";
        }

        return langUser;
    }

    getPorcentajeReparacion(numDiagnosis, totalDiagnosis) {
        let porcentaje = 0;
        if (totalDiagnosis != 0) {
            porcentaje = numDiagnosis * 100 / totalDiagnosis;
        }
        return porcentaje.toLocaleString('es-ES', { minimumFractionDigits: 0});
    }

    allSintomasDiagnosticoSelected() {
        let selectedAll = false;
        let sintomasDiagSelected = [];

        let diagnositicosSelected = this.diagnosticos.filter((item) => { return item.selected && item.selected == true; });
        if (diagnositicosSelected.length > 0) {
            for (const itemDiagSel of diagnositicosSelected) {
                for (const itemSintoma of itemDiagSel.sintomasDiagnostico) {
                    if (itemSintoma.selected) {
                        const indexSintoma = sintomasDiagSelected.findIndex((item) => { return item.id_sintoma == itemSintoma.id_sintoma; });
                        if (indexSintoma == -1) {
                            sintomasDiagSelected.push(itemSintoma);
                        }
                    }
                }
            }

            if (this.sintomasAceptados.length == sintomasDiagSelected.length) {
                selectedAll = true;
            }
        }

        return selectedAll;
    }

    initLoading(textoLoading?: string) {
		// this.loading = true;
		Block.standard('#chat-autodiagnosis', textoLoading ? textoLoading : '', {
			svgColor: '#666',
			messageColor: '#666',
			messageFontSize: '18px'
		});
	}

	stopLoading() {
		// this.loading = false;
		Block.remove('#chat-autodiagnosis');
	}

    toggleSidebarOpen(): void {
        this._fuseSidebarService.getSidebar('diagnosisBot').toggleOpen();
    }

    loadUser() {
        this.userSvc.getUsuario().subscribe(
            (data:any) => {
                this.usuario = data;
            }
        );
	}

    addMessageError(texto: string) {
        const message: Mensaje = {
            who: 'contact',
            avatar: this.avatarBot,
            type: 'message',
            message: texto,
            error: true
        }
        this.mensajes.push(message);

        const langUser = this.getLangUser();
        this.helperSvc.sayIt(texto, langUser);
    }
    addMessageSuccess(texto: string) {
        const message: Mensaje = {
            who: 'contact',
            avatar: this.avatarBot,
            type: 'message',
            message: texto,
            success: true
        }
        this.mensajes.push(message);

        const langUser = this.getLangUser();
        this.helperSvc.sayIt(texto, langUser);
    }
    addMessage(texto: string, who?, read?: boolean) {
        const message: Mensaje = {
            who: who ? who : 'contact',
            avatar: this.avatarBot,
            type: 'message',
            message: texto,
        }
        this.mensajes.push(message);

        if (read === undefined) {
            read = true;
        }

        if (read) {
            const langUser = this.getLangUser();
            this.helperSvc.sayIt(texto, langUser);
        }
    }
    addActionMessage(actions: Array<ActionMensaje>, who?) {
        const message: Mensaje = {
            who: who ? who : 'contact',
            avatar: this.avatarBot,
            type: 'action',
            disabled: false,
            actions: actions,
        }
        this.mensajes.push(message);
    }
    addInputMessage(input: string, who?) {
        const message: Mensaje = {
            who:  who ? who : 'contact',
            avatar: this.avatarBot,
            type: 'input',
            disabled: false,
            input: input
        }
        this.mensajes.push(message);
    }
    addListDataMessage(data: any, listName: string, who?) {
        const message: Mensaje = {
            who: who ? who : 'contact',
            avatar: this.avatarBot,
            type: 'list-data',
            listName: listName,
            data: data
        }
        this.mensajes.push(message);
    }

    initChat() {
        this.mensajes = [];
        this.addMessage(this.translateService.instant('AUTODIAGNOSIS_BOT.PROCESO_SELECCION_SINTOMAS'), null, false);
        this.addActionMessage([
            {
                text: this.translateService.instant('AUTODIAGNOSIS_BOT.INICIAR_DIAGNOSIS'),
                action: "iniciar"
            }
        ]);

        this.idTicket = null;
        this.tipoElemento = null;
        this.sintomas = {
            SI: [],
            NO: [],
            NSNC: []
        }
        this.indexPosibleSintoma = 0;
        this.posiblesSintomas = [];
        this.sintomasAceptados = [];
        this.idDefecto = null;
        this.descripcionProblema = "";

        for (const itemTipoElem of this.tiposElementos) {
            itemTipoElem.selected = false;
        }

        this._prepareChatForReplies();
    }

    resetData() {

    }

    isFirstMessageOfGroup(mensaje, index): boolean {
        return (index === 0 || (this.mensajes[index-1] && (this.mensajes[index-1].who !== mensaje.who || this.mensajes[index-1].type !== 'message')));
    }
    isLastMessageOfGroup(mensaje, index): boolean {
        let isLastMessage = false;
        if (index === this.mensajes.length-1) {
            isLastMessage = true;
        }
        else {
            if (this.mensajes[index+1]) {
                const nextMessage = this.mensajes[index+1];
                if (nextMessage.who !== mensaje.who || (nextMessage.who === mensaje.who && nextMessage.type !== 'message')) {
                    isLastMessage = true;
                }
            }
        }

        return isLastMessage;
    }
    shouldShowContactAvatar(mensaje, index): boolean {
        return (
            mensaje.who == 'contact' && mensaje.type == 'message' && this.isLastMessageOfGroup(mensaje, index)
        );
    }

    actionMensaje(accion: string, index: number, idItem?: number) {
        // console.log("Accion mensaje", accion);
        
        switch (accion) {
            case "iniciar":
                this.mensajes[index].disabled = true;
                this.iniciarAutoDiagnosis();
                break;
            case "reiniciar":
                this.initChat();
                break;
            case "sintomaSi":
                this.mensajes[index].disabled = true;
                const actionSi = this.mensajes[index].actions.find((item) => { return item.action == 'sintomaSi'; });
                if (actionSi) {
                    actionSi['selected'] = true;
                }
                this.sintomas.SI.push(idItem);
                this.sintomasAceptados.push({ id: idItem, nombre: actionSi.nombre });

                this.indexPosibleSintoma = 0;
                this.posiblesSintomas = [];
                this.solicitarPosibleSintoma();
                break;
            case "sintomaNo":
                this.mensajes[index].disabled = true;
                const actionNo = this.mensajes[index].actions.find((item) => { return item.action == 'sintomaNo'; });
                if (actionNo) {
                    actionNo['selected'] = true;
                }
                this.sintomas.NO.push(idItem);
                this.solicitarPosibleSintoma();
                break;
            case "sintomaNS":
                this.mensajes[index].disabled = true;
                const actionNS = this.mensajes[index].actions.find((item) => { return item.action == 'sintomaNS'; });
                if (actionNS) {
                    actionNS['selected'] = true;
                }
                this.sintomas.NSNC.push(idItem);
                this.solicitarPosibleSintoma();
                break;
            // case "seleccionarDefecto":
            //     this.idDefecto = idItem;
            //     this.solicitarDescripcionProblema();
            //     break;
            case "solicitarDescripcion":
                this.mensajes[index].disabled = true;
                this.solicitarDescripcionProblema();
                break;
            case "toogleDefecto":
                this.toogleDefecto(idItem);
                break;
            case "crearDiagnosis":
                this.mensajes[index].disabled = true;
                this.crearDiagnosis();
                break;
        
            default:
                break;
        }
    }
    selectTipoElemento(idTipoElemento: string, indexMensaje: number) {
        this.tipoElemento = idTipoElemento;
        let itemTipoElemento = this.tiposElementos.find((item) => { return item.id === idTipoElemento; });
        if (itemTipoElemento) {
            itemTipoElemento.selected = true;
        }
        this.mensajes[indexMensaje].disabled = true;
        this.iniciarAutoDiagnosis();
    }

	iniciarAutoDiagnosis() {
        console.log("idTicket", this.idTicket);
        if (!this.idTicket) {
            this.solicitarIdTicket();
        }
        else {
            this.solicitarPosibleSintoma()
        }
    }

    solicitarIdTicket() {
        this.addMessage(this.translateService.instant('AUTODIAGNOSIS_BOT.INDICA_NUM_TICKET'));
        this.addInputMessage('ticket');
        this._prepareChatForReplies();
    }
    solicitarTipoElemento() {
        this.addMessage(this.translateService.instant('AUTODIAGNOSIS_BOT.QUE_TIPO_ELEMENTO'));
        this.addInputMessage('tipoElemento');
        this._prepareChatForReplies();
    }
    toogleDefecto(idDefecto) {
        let diagnostico = this.diagnosticos.find((item) => { return item.id_defecto == idDefecto; });
        if (diagnostico) {
            diagnostico.selected = !diagnostico.selected;

            if (diagnostico.selected === true) {
                let otrosDiagnosticosSelected = this.diagnosticos.filter((item) => { return item.selected && item.selected == true &&  item.id_defecto != idDefecto});

                if (diagnostico.sintomasDiagnostico == undefined) {
                    diagnostico['sintomasDiagnostico'] = diagnostico.sintomas.slice();
                }

                for (let itemSintomaDiag of diagnostico.sintomasDiagnostico) {
                    if (otrosDiagnosticosSelected.length > 0) {
                        itemSintomaDiag['selected'] = false;
                    }
                    else {
                        itemSintomaDiag['selected'] = true;
                    }
                }
            }
            else {
                for (let itemSintomaDiag of diagnostico.sintomasDiagnostico) {
                    itemSintomaDiag['selected'] = false;
                }
            }
        }
        this._prepareChatForReplies();
    }
    toogleSintomaDiagnostico(idDefecto, sintoma) {
        sintoma.selected = !sintoma.selected;
        if (sintoma.selected) {
            let otrosDiagnosticosSelected = this.diagnosticos.filter((item) => { return item.selected && item.selected == true &&  item.id_defecto != idDefecto});
            for (const itemOtroDiagSel of otrosDiagnosticosSelected) {
                let sintomaDiag = itemOtroDiagSel.sintomasDiagnostico.find((item) => { return item.id_sintoma == sintoma.id_sintoma });
                if (sintomaDiag && sintomaDiag.selected) {
                    sintomaDiag.selected = false;
                }
            }
        }
    }

    addMensajesPosibleSintoma() {
        const posibleSintoma = this.posiblesSintomas[this.indexPosibleSintoma];
        const mensaje = posibleSintoma.sintoma != "" ? posibleSintoma.sintoma + " ?" : this.translateService.instant('AUTODIAGNOSIS_BOT.SIN_SINTOMAS');
        this.addMessage(mensaje);
        this.addActionMessage([
            { id: posibleSintoma.idSintoma, nombre: posibleSintoma.sintoma, action: "sintomaSi", icon: "fa-check-circle" },
            { id: posibleSintoma.idSintoma, nombre: posibleSintoma.sintoma, action: "sintomaNo", icon: "fa-times-circle" },
            { id: posibleSintoma.idSintoma, nombre: posibleSintoma.sintoma, action: "sintomaNS", icon: "fa-question-circle", title: posibleSintoma.comentarios },
        ]);
        
        this._prepareChatForReplies();
    }

    solicitarPosibleSintoma() {
        const langUser = this.getLangUser();

        if (this.posiblesSintomas.length > 0 && this.indexPosibleSintoma < (this.posiblesSintomas.length-1)) {
            this.indexPosibleSintoma++;
            this.addMensajesPosibleSintoma();
        }
        else {
            this.initLoading();
            this.diagnosisSvc.solcitarSintomaAutoDiagnosis(this.idTicket, this.sintomas, langUser, this.tipoElemento).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (data: any) => {
                    this.stopLoading();
                    // this.entornoBusqueda = data.entorno;
    
                    if (data.posiblesSintomas && data.posiblesSintomas.length > 0) {
                        this.indexPosibleSintoma = 0;
                        this.posiblesSintomas = data.posiblesSintomas.slice();
    
                        this.addMensajesPosibleSintoma();
                    }
                    else if (data.diagnosticos) {
                        console.log("diagnosticos", data.diagnosticos);
                        this.totalDiagnosis = data.totalDiagnosis;
                        this.diagnosticos = data.diagnosticos.slice();
                        for (let itemDiagnostico of this.diagnosticos) {
                            itemDiagnostico['selected'] = false;
                        }
    
                        this.addMessage(this.translateService.instant('AUTODIAGNOSIS_BOT.DIAGNOSTICOS_ENCONTRADOS'));
                        this.addMessage(this.translateService.instant('AUTODIAGNOSIS_BOT.SELECCIONA_DIAGNOSTICO'));
                        this.addListDataMessage(this.diagnosticos, 'diagnosticos');
                        this._prepareChatForReplies();
                    }
                    else {
                        if (this.sintomas.SI.length == 0) {
                            this.addMessageError(this.translateService.instant('AUTODIAGNOSIS_BOT.NINGUN_SINTOMA_MARCADO'));
                            this.addMessage(this.translateService.instant('AUTODIAGNOSIS_BOT.REINICIA_AUTODIAGNOSIS'));
                            this.addActionMessage([
                                { text: this.translateService.instant('AUTODIAGNOSIS_BOT.REINICIAR_DIAGNOSIS'),  action: "reiniciar" }
                            ]);
                        }
                        else {
                            this.addMessageError(this.translateService.instant('AUTODIAGNOSIS_BOT.DIAGNOSTICOS_NO_ENCONTRADOS'));
                            this.addMessage(this.translateService.instant('AUTODIAGNOSIS_BOT.REINICIA_AUTODIAGNOSIS'));
                            this.addActionMessage([
                                { text: this.translateService.instant('AUTODIAGNOSIS_BOT.REINICIAR_DIAGNOSIS'),  action: "reiniciar" }
                            ]);
                        }
                        this._prepareChatForReplies();
                    }
                },
                (err) => {
                    this.stopLoading();
                    if (err.error) {
                        if (err.error == 'ticket_not_found') {
                            this.addMessageError(this.translateService.instant('AUTODIAGNOSIS_BOT.TICKET_NO_ENCONTRADO'));
                            this.solicitarIdTicket();
                        }
                        else if (err.error == 'no_tipo_elemento') {
                            this.addMessageError(this.translateService.instant('AUTODIAGNOSIS_BOT.TIPO_ELEMENTO_NO_INDICADO'));
                            this.solicitarTipoElemento();
                        }
                    }
                    else {
                        this.addMessageError(err);
                    }
                }
            );
        }

    }

    solicitarDescripcionProblema() {
        this.addMessage(this.translateService.instant('AUTODIAGNOSIS_BOT.DESCRIPCION_PROBLEMA'));
        this.addInputMessage("descripcion");

        this._prepareChatForReplies();
    }

    crearDiagnosis() {
        let dataIris = [];
        const diagnosticosSelected = this.diagnosticos.filter((item) => { return item.selected && item.selected == true });
        for (const itemDiagSel of diagnosticosSelected) {
            if (itemDiagSel.sintomasDiagnostico) {
                for (const itemSintomaDiag of itemDiagSel.sintomasDiagnostico) {
                    if (itemSintomaDiag.selected) {
                        const objIris = { id_sintoma: itemSintomaDiag.id_sintoma, id_defecto: itemDiagSel.id_defecto };
                        dataIris.push(objIris);
                    }
                }
            }
        }
        console.log("dataIris", dataIris);

        if (dataIris.length > 0) {
            this.diagnosisSvc.crearAutoDiagnosis(this.idTicket, dataIris, this.descripcionProblema).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (data: any) => {
                    this.addMessageSuccess(this.translateService.instant('AUTODIAGNOSIS_BOT.DIAGNOSIS_FINALIZADA'));
                    this.addMessage(this.translateService.instant('AUTODIAGNOSIS_BOT.DIAGNOSIS_REGISTRADAS'));
                    this.addMessage(this.translateService.instant('AUTODIAGNOSIS_BOT.DIAGNOSTICAR_NUEVO_DISPOSITIVO'));
                    this.addActionMessage([
                        { text: this.translateService.instant('AUTODIAGNOSIS_BOT.NUEVA_DIAGNOSIS'),  action: "reiniciar" }
                    ]);
                    this._prepareChatForReplies();
                },
                (err) => {
                    this.addMessageError(err);
                    this._prepareChatForReplies();
                }
            );
        }
        else {
            this.addMessageError(this.translateService.instant('AUTODIAGNOSIS_BOT.SINTOMAS_REPARACIONES_NO_MARCADAS'));
            this._prepareChatForReplies();
        }
    }

}
