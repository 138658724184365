<div class="navbar-header" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div *ngIf="entorno == 'aitana'" class="logo">
        <img class="logo-icon" src="assets/images/logos/aitana.png">
        <span class="logo-text">AITANA</span>
    </div>
    <div *ngIf="entorno === 'ewaste'" class="logo">
        <img class="logo-icon" src="assets/images/ewaste/aitana-ewaste-ico.png">
        <span class="logo-text">eWASTE</span>
    </div>
    <div *ngIf="entorno === 'circpass'" class="logo">
        <img src="assets/images/circpass/aitana-circpass-ico.png">
    </div>

    <button mat-icon-button class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
    </button>

</div>

<div class="navbar-content" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
     [ngClass]="fuseConfig.layout.navbar.primaryBackground">
     <app-buscador-menu></app-buscador-menu>
    <fuse-navigation layout="vertical"></fuse-navigation>
</div>