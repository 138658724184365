import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatBadgeModule} from '@angular/material/badge';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { CygShareModule } from 'app/componentes/cyg-share.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
//import { NotificacionComponent } from 'app/layout/components/notificacion/notificacion.component';

@NgModule({
    declarations: [
        ToolbarComponent,
        //NotificacionComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatBadgeModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,

        CygShareModule
    ],
    exports     : [
        ToolbarComponent
    ]
})
export class ToolbarModule
{
}
