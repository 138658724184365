import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from 'app/services/user/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlmacenService } from 'app/services/almacen/almacen.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogAlertComponent } from 'app/componentes/util/dialog-alert/dialog-alert.component';
@Component({
  selector: 'app-subir-inventario',
  templateUrl: './subir-inventario.component.html',
  styleUrls: ['./subir-inventario.component.scss']
})
export class SubirInventarioComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  usuario: any;

  files: any[];

  constructor(
    private dialog: MatDialog, 
    private userSvc: UserService,
    private almacenSvc: AlmacenService,
  ) {
    this._unsubscribeAll = new Subject();
    this.files = [];
   }

  ngOnInit(): void {
   // this.loadUser();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
}

  onFileDropped($event) {
    console.log("openfile");
    this.prepareFilesList($event);
  }
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  private prepareFilesList(files: Array<any>) {
		
		for (const item of files) {
		  item.progress = 0;
		  this.files.push(item);
		}
		
		this.uploadFiles();
	}

  uploadFiles() {
		
		for (let file of this.files) {
      console.log("file upload", file);
			console.log("file progress", file.progress);
      if (file.progress !== undefined && file.progress < 100) {
        //Meter fichero en la cola
        console.log('Fichero subido');
        this.almacenSvc.uploadFileInventario(file).subscribe(
          (data) => {
            console.log("Data file upload", data);
            file.progress = data.progress;
            if (data.res) {
              this.showAlert("Fichero Subido", "Se ha subido el fichero correctamente. Cuando se finalice la carga, se le informará por correo electrónico ");
             // file.docId = data.res.ID;
            }
          }
        );
      }
		}
	}

  showAlert(titulo: string, mensaje: string): void {
		const dialogRef = this.dialog.open(DialogAlertComponent, {
			data: { 
				titulo: titulo, 
				mensaje: mensaje,
			},
			panelClass: 'panel-aitana',
			disableClose: true,
		});
	}
  loadUser() {
		this.userSvc.getUsuario().pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data:any) => {
				this.usuario = data;
			}
		)
	}

  formatBytes(bytes, decimals) {
		if (bytes === 0) {
		  	return '0 Bytes';
		}
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals || 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

}
