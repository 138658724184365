import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class ProyectosService {

	onProjectSelected: Subject<any>;
	onProjectsUserInitialize: Subject<any>;

	private projectsSelected: any;

	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
	) {
		this.onProjectSelected = new Subject();
		this.onProjectsUserInitialize = new Subject();
		// this.projectsSelected = [];
	}

	getProjectsSeleted() {
		return this.projectsSelected;
	}
	setProjectsSelected(dataProject: any) {
		this.projectsSelected = dataProject;
		this.onProjectSelected.next(this.projectsSelected);
	}

	selectProject(dataProject: any) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const body = {
				projectId: dataProject.id,
			};

			const url = environment.API_URL + '/proyectos/select-entity';
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					console.log("ws changeEntity", response);
					this.setProjectsSelected(response);
					// this.changeProjectNewAitana(dataProject.id, 'project');
					observer.next(response);
				},
				(err) => {
					console.log("error ws changeEntity", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	selectConfigProject(dataConfig: any) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const body = {
				configProjectId: dataConfig.ID
			};

			const url = environment.API_URL + '/proyectos/select-config-project';
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					console.log("ws selectConfigProject", response);
					this.setProjectsSelected(response);
					// this.changeProjectNewAitana(dataConfig.ID, 'config');
					observer.next(response);
				},
				(err) => {
					console.log("error ws selectConfigProject", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	selectDefaultProject() {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/proyectos/select-default-entity';
			this.http.post(url, {}, options).subscribe(
				(response: any) => {
					console.log("ws selectDefaultProject", response);
					this.setProjectsSelected(response);
					// this.changeProjectNewAitana(0, 'default');
					observer.next(response);
				},
				(err) => {
					console.log("error ws selectDefaultProject", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	checkGrupoProyecto(idProyecto: number, grupoProyecto: string) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				idProyecto: idProyecto,
				grupoProyecto: grupoProyecto
			}

			const url = environment.API_URL + '/proyectos/check-grupo-proyecto?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getProject", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getProject", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getProject(idProyecto: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/proyectos/'+idProyecto;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getProject", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getProject", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getContrato(idProyecto: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/proyectos/'+idProyecto+'/contrato';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getContrato", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getContrato", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getProjectsUser() {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/proyectos/user-selected';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getProyectosUser", response);
					this.projectsSelected = response;
					this.onProjectsUserInitialize.next(this.projectsSelected);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getProyectosUser", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getProyectosUsuario(){
		const accessToken = this.authSvc.getAccessToken();

		const options = {
			headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
			})
		};

		const url = environment.API_URL + '/proyectos/proyectos-user';
		
		return this.http.get(url, options);
	}

	getConfigProjectsUser() {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/proyectos/config-projects-user';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getConfigProjectsUser", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getConfigProjectsUser", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getProjects(numPage: number, pageSize: number, filtro: string, withSap?: boolean, nuevoTicket?: boolean, soloActivos?: boolean): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				page: numPage,
				pageSize: pageSize,
				filtro: filtro,
				withSap: withSap ? withSap : false,
				nuevoTicket: nuevoTicket ? nuevoTicket : false,
				solo_proyectos_activos: soloActivos ? soloActivos : false
			}

			const url = environment.API_URL + '/proyectos?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getProyectos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getProyectos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getProjectsSelected(numPage: number, pageSize: number, filtro: string, withSap?: boolean): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				page: numPage,
				pageSize: pageSize,
				filtro: filtro,
				withSap: withSap ? withSap : false
			}

			const url = environment.API_URL + '/proyectos/proyect-selected?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getProyectos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getProyectos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	saveConfigProjects(nombre: string, listaProyectos: string, esPublica: boolean, idConfig?: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const body = {
				nombre: nombre,
				listaProyectos: listaProyectos,
				esPublica: esPublica ? 1 : 0
			};

			let url = environment.API_URL + '/proyectos/create-config-projects';
			if (idConfig) {
				url = environment.API_URL + '/proyectos/update-config-projects/'+idConfig;
			}
			
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					console.log("ws saveConfigProjets", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws saveConfigProjets", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	deleteConfigProjects(idConfig: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			let url = environment.API_URL + '/proyectos/delete-config-projects/'+idConfig;
			this.http.post(url, {}, options).subscribe(
				(response: any) => {
					console.log("ws deleteConfigProjets", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws deleteConfigProjets", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getProfiles(numPage: number, pageSize: number, filtro: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

            const params = {
				page: numPage,
				pageSize: pageSize,
				filtro: filtro,
			}

			let url = environment.API_URL + '/proyectos/profiles?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getEntities", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getEntities", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getOrganismos(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			let url = environment.API_URL + '/proyectos/organismos';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getOrganismos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getOrganismos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getProjectsPlanta(idsProyectos: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/proyectos/planta?ids='+idsProyectos;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getProjectsPlanta", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getProjectsPlanta", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    createOption(tipo: string, proyecto: string | number, datos: any){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			let url = environment.API_URL + '/proyectos/select-option/'+proyecto;
			this.http.post(url, {tipo: tipo, datos: datos}, options).subscribe(
				(response: any) => {
					console.log("ws createOption", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws createOption", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
    }
}
