<div id="buscador-menu">
    <div class="buscador-input">
        <div class="buscador-menu" fxLayout="row">

            <div appearance="outline" fxLayout="row" fxLayoutAlign="space-between center">
                <input #searchMenu name="filterTickets" class="input-search-menu secondary-text" [(ngModel)]="textSearch" [placeholder]="'BUSCADOR.BUSCAR' | translate" (ngModelChange)="search()">
                <button mat-icon-button class="secondary-text" (click)="clearSearch()">
                    <mat-icon >close</mat-icon>
                </button>
            </div>

        </div>
    </div>

    <div class="buscador-icon">
        <div class="icon-search-menu" appearance="outline" fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-icon-button>
                <mat-icon >search</mat-icon>
            </button>
        </div>
    </div>
</div>