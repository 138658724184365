import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subject } from 'rxjs';
import firebase from 'firebase/app';
import { UserRoleService } from 'app/services/user/user-role.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { CygPermission } from '@fuse/types';
import { AuthService } from 'app/services/auth/auth.service';

import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	loginForm = this.fb.group(
		{
		username: [''],
		password: [''],
		}
	);
	rememberForm = this.fb.group(
		{
		email: [''],
		}
	);

	entorno: string = environment.entorno;

	msgErrorLogin: string;
	loadingBtn: boolean;

	msgErrorRemember: string;
	msgSuccessRemember: string;
	showRemember = false;

	permisoTecnicoUnei: CygPermission;
	permisoPerfileWaste: CygPermission;
    permisoCodigoSeguridad: CygPermission = { type: "item", name: "Aitana:Solicitud codigo seguridad", active: true };

	constructor( 
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private _fuseConfigService: FuseConfigService, 
		public fireAuth: AngularFireAuth, 
		private authSvc: AuthService, 
		private fb:FormBuilder, 
		private router: Router,
		private userRoleSvc: UserRoleService,
		private msalAuthService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private snackbar: MatSnackBar
	) {
		this.permisoTecnicoUnei = { type: "item", name: "Aitana:Perfil Cliente Unei técnico", active: true };
		this.permisoPerfileWaste = { type: "item", name: "Aitana: Perfil eWaste", active: true };
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar   : {
					hidden: true
				},
				toolbar  : {
					hidden: true
				},
				footer   : {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		};
		this.loadingBtn = false;

		this.fireAuth.authState.subscribe( user => {
			if(user)
				this.proccessLoginFirebase(user);
		});

	}

	ngOnInit(): void {
        this.authSvc.logout();
	}

	onLogin():void{
		const formValue = this.loginForm.value;
		this.loadingBtn = true;
        this.msgSuccessRemember = '';

		this.authSvc.loginUser(formValue).subscribe(
			res => {
				// this.router.navigate(['sample']);
                this.loadingBtn = false;
				this.loginSuccess(res['securityCode']);
			},
			err => {
				this.msgErrorLogin = err;
				this.loadingBtn = false;
			});
	}

	loginGoogle(){
		this.fireAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
	}

	loginOutlook() {
		if (this.msalGuardConfig.authRequest) {
			this.msalAuthService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
				.subscribe((response: AuthenticationResult) => {
					this.msalAuthService.instance.setActiveAccount(response.account);
					this.proccessLoginOutlook(response.account);
				});
		} 
		else {
			this.msalAuthService.loginPopup()
				.subscribe((response: AuthenticationResult) => {
					this.msalAuthService.instance.setActiveAccount(response.account);
					this.proccessLoginOutlook(response.account);
				});
		}
	}
	
	logoutOutlook() {
		this.msalAuthService.logoutPopup({
			postLogoutRedirectUri: "/",
			mainWindowRedirectUri: "/"
		});
	}

	onRemember() {
        this.msgErrorRemember = '';
        const formValue = this.rememberForm.value;

        if (this.rememberForm.valid) {
            this.authSvc.rememberPassword(formValue.email).subscribe(
                data => {
                    this.showRemember = false;

                    this.msgSuccessRemember = "Se ha enviado un email a tu cuenta para restablecer la contraseña.";
                },
                err => {
                    this.msgErrorRemember = err;
                }
            );
        }
	}

	private proccessLoginFirebase(user) {
		user.getIdToken().then((idToken) => {
			this.authSvc.loginSocial(user.email, idToken).subscribe( res => {
				this.fireAuth.signOut();
				// this.router.navigate(['sample']);
				this.loginSuccess(res['securityCode']);
			}, err => {
                this.fireAuth.signOut();
				this.msgErrorLogin = err;
			});
		});
  	}

	private proccessLoginOutlook(account) {
		this.authSvc.loginSocial(account.username, account.idTokenClaims.rh).subscribe( res => {
			this.fireAuth.signOut();
			this.loginSuccess(res['securityCode']);
		}, err => {
			this.fireAuth.signOut();
			this.msgErrorLogin = err;
		});
  	}

	private loginSuccess(securityCode) {
        this.userRoleSvc.checkPermissions([this.permisoCodigoSeguridad])
        .then((permiso:boolean) => {
            if(permiso && securityCode && securityCode == true){
                this.router.navigate(['security-login']);
            }else{
                this.userRoleSvc.checkPermissions([this.permisoTecnicoUnei])
                .then((permiso2:boolean) => {
                    if(permiso2){
                        this.router.navigate(['tickets']);
                    }else{
						this.userRoleSvc.checkPermissions([this.permisoPerfileWaste])
                			.then((permiso2:boolean) => {
								if(permiso2 || this.entorno === 'ewaste'){
									this.router.navigate(['dashboards/triaje']);
								}else{
									this.router.navigate(['']);
								}
							});
                    }
                    
                });   
            }
        });
	}
}