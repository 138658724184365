import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';

//Traduccion
import { takeUntil } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { AlmacenService } from 'app/services/almacen/almacen.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-info-activo',
    templateUrl: './info-activo.component.html',
    styleUrls: ['./info-activo.component.scss']
})
export class InfoActivoComponent implements OnInit {
    @Input('activo') activo: any;

	tickets: Array<any>;
	dataSourceTickets: MatTableDataSource<any>;
	displayedColumnsTickets: string[] = ['ID', 'fecha_apertura', 'estado', 'titulo', 'categoria'];

	knowledge: Array<any>;
	dataSourceKnowledge: MatTableDataSource<any>;
	displayedColumnsKnowledge: string[] = ['titulo', 'tipo', 'descripcion'];

	componentes: Array<any>;
	dataSourceComponentes: MatTableDataSource<any>;
	displayedColumnsComponentes: string[] = ['pn', 'serial', 'tipo', 'fabricante', 'modelo'];

    //Elementos funcionalidad
    private _unsubscribeAll: Subject<any>;

    constructor(
		private almacenService: AlmacenService,
		private snackBar: MatSnackBar,
    ) {
		this._unsubscribeAll = new Subject();

		this.tickets = [];
		this.dataSourceTickets = new MatTableDataSource();

		this.knowledge = [];
		this.dataSourceKnowledge = new MatTableDataSource();

		this.componentes = [];
		this.dataSourceComponentes = new MatTableDataSource();
    }

    ngOnInit(): void {
    }

    ngOnChanges() {
		this.loadInfoActivo();
    }

    ngOnDestroy(): void {
        //Borramos cualquier suscripcion
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	verTicket(idTicket) {
		window.open('/tickets/gestion-nuevo-ticket/' + idTicket, '_blank');
	}

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 4000
		});
	}

	loadInfoActivo() {
		let params = {
			tickets: this.activo.listTickets,
			marcaId: this.activo.fabricanteId,
			modeloId: this.activo.modeloId,
			activoId: this.activo.id,
		};
		this.almacenService.getInfoActivo(params).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				this.tickets = data.tickets;
				this.dataSourceTickets.data = this.tickets;
				this.knowledge = data.knowledge;
				this.dataSourceKnowledge.data = this.knowledge;
				this.componentes = data.componentes;
				this.dataSourceComponentes.data = this.componentes;
			},
			(err) => {
				this.showSnack(err.message);
			}
		);
	}

	limitSize(cadena) {
		return cadena.substring(0,30) + (cadena.length > 30 ? '...' : '');
	}

	getUrlCentroConocimiento() {
		let url = '/centro-conocimiento';

		if(this.activo.fabricanteId) {
			url += '?marcas=' + this.activo.fabricanteId;
		}

		if(this.activo.modeloId) {
			url += '&modelo=' + this.activo.modeloId;
		}

		window.open(url, '_blank');
	}
}
