import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { DbService } from './db.service';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class Garantia10Service {

	constructor(private http: HttpClient, private db: DbService, private authSvc: AuthService, private helperSvc: HelperService) { }

	getProyectos(page, pageSize, sortColum, sortDirection, filtro = "") {
		const url = this.db.getProyectosGarantia10();

		console.log('URL:   ' + url);

		return new Observable<any>(observer => {
			this.http.get(url + "?page=" + page + "&pageSize=" + pageSize + "&sortColum=" + sortColum + "&sortDirection=" + sortDirection)
				.subscribe(data => {
					observer.next(data);
				}, err => {
					console.log("error garantia10", err);
					let msgError = "No se han podido cargar los datos";

					observer.error(msgError);
				});

			return { unsubscribe() { } };
		});
	}

	getAnalisisProyecto(page, pageSize, sortColum, sortDirection, idProyecto) {
		const url = this.db.getAnalisisProyectoGarantia10(idProyecto);

		console.log('URL:   ' + url);

		return new Observable<any>(observer => {
			this.http.get(url + "?page=" + page + "&pageSize=" + pageSize + "&sortColum=" + sortColum + "&sortDirection=" + sortDirection)
				.subscribe(data => {
					observer.next(data);
				}, err => {
					console.log("error garantia10", err);
					let msgError = "No se han podido cargar los datos";

					observer.error(msgError);
				});

			return { unsubscribe() { } };
		});
	}

	getSolicitudes(page, pageSize, sortColum, sortDirection, idProyecto): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/garantia10/solicitudes' + "?page=" + page + "&pageSize=" + pageSize + "&sortColum=" + sortColum + "&sortDirection=" + sortDirection;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error garantia10", err);
					let msgError = "No se han podido cargar los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getEstados(): Observable<any> {
		const url = this.db.getEstadosSolicitudesCotizacion();

		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/garantia10/estados';

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log('ESTADOS')
					console.log(response);
					observer.next(response);
				},
				(err) => {
					console.log("error garantia10", err);
					let msgError = "No se han podido cargar los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	modificarSolicitudCotizacion(idSolicitud, form: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				datos: form
			};

			const url = environment.API_URL + '/garantia10/solicitud/' + idSolicitud;
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws modificarSolicitudCotizacion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws modificarSolicitudCotizacion", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	enviarMailSolicitudCotizacion(idSolicitud): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}
			const url = environment.API_URL + '/garantia10/send-mail-solicitud-cotizacion/' + idSolicitud;
			this.http.post(url, {}, options).subscribe(
				(response: any) => {
					console.log("ws enviarMailSolicitudCotizacion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws enviarMailSolicitudCotizacion", err);
					let msgError = "Se ha producido un error enviando el email";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}


}
