import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

	private usuario: any;
	onMenuFiltered: Subject<any>;
    isUsuarioLoaded = new BehaviorSubject<boolean>(false);

	constructor(
			private http: HttpClient, 
			private authSvc: AuthService, 
			private helperSvc: HelperService
	) { 
		this.onMenuFiltered = new Subject();
	}

	getMenu(entorno: string, navigation: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				entorno: entorno,
				navigation: navigation
			}

			const url = environment.API_URL + '/menu';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws getMenu", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getMenu", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getUsuario(forceRefresh?: boolean): Observable<any> {
		return new Observable<any>(observer => {
			if (!this.usuario || forceRefresh) {
				const accessToken = this.authSvc.getAccessToken();
	
				const options = {
					headers: new HttpHeaders({
						'Authentication': 'Bearer ' + accessToken,
						'Content-Type': 'application/json; charset=utf-8'
					})
				}
	
				const url = environment.API_URL + '/usuarios/me';
				this.http.get(url, options).subscribe(
					(response: any) => {
						console.log("ws getUsuario", response);
						this.usuario = response;
                        
                        if(this.isUsuarioLoaded.getValue() == false)
                        this.isUsuarioLoaded.next(true);

						observer.next(response);
					},
					(err) => {
						console.log("error ws getUsuario", err);
						let msgError = "Se ha producido un error cargando los datos";
	
						observer.error(msgError);
					}
				);
			}
			else {
				observer.next(this.usuario);
			}

			return {unsubscribe() {}};
		});
	}
	
	cambiarIdioma(language: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/usuarios/config/language/'+language;
			this.http.put(url, {}, options).subscribe(
				(response: any) => {
					console.log("ws cambiarIdioma", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws cambiarIdioma", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	logout(): void {
		this.usuario = null;
        this.isUsuarioLoaded.next(false);
	}

	getEmpleados(): Observable <any>{
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/usuarios/empleados';

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getEmpleados", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getEmpleados", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getSedePrincipal(): Observable <any>{
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/usuarios/sede-principal';

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getSedePrincipal", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getSedePrincipal", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getTecnicosLab(): Observable <any>{
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/usuarios/tecnicos-lab';

			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws gettecnicosLab", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws gettecnicosLab", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getColaboradoresPropios(): Observable <any>{
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/usuarios/colaboradores-propios';

			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getColaboradoresPropios", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getColaboradoresPropios", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	changePassword(newPassword: string): Observable<boolean> {
		return new Observable<boolean>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const body = {
				"password": newPassword
			}

			this.http.put(environment.API_URL + '/usuarios/config/changePassword', body, options).subscribe(
				(response: any) => {
					console.log("change password", response);
					observer.next(true);
				},
				err => {
					console.log("error change password", err);
					let msgError = err.error.message ? err.error.message : "No se ha podido cambiar la contraseña";

					observer.error(msgError);
				}
			)
		});
	}

    confirmarCodigoSeguridad(codigo : any){
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/usuarios/config/securityCode';
			this.http.post(url, {'security_code': codigo}, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error ws confirmarCodigoSeguridad", err);
					let msgError = err.error && err.error.message?err.error.message:"Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}


}
