<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">Código QR</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <canvas id="canvas"></canvas>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-button mat-stroked-button (click)="getImageQrCode()">Guardar imagen QR</button>
</mat-dialog-actions>