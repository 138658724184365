import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';
import { exit } from 'process';

@Injectable({
  providedIn: 'root'
})
export class AlmacenService {

  	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
	) { }

	getMarcas(params = null): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let url = environment.API_URL + '/almacenes/manufacturers';
			if(params) {
				url = environment.API_URL + '/almacenes/manufacturers?' + this.helperSvc.serializeData(params);
			}

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getMarcas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getMarcas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getMateriasPrimas(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let url = environment.API_URL + '/almacenes/materias-primas';

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getMateriasPrimas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getMateriasPrimas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getMarcasPlanta(proyectoSelected: number[], filterValue? : string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				projects : proyectoSelected,
				name : filterValue
			}

			const url = environment.API_URL + '/almacenes/manufacturers-planta?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getMarcasPlanta", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getMarcasPlanta", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getModelos(marcaID, filtro = null): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let url = environment.API_URL + '/almacenes/models?marcaId=' + marcaID;
			if(filtro) {
				url += '&modelo=' + filtro;
			}

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getModelos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getModelos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getModeloById(modeloId): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/models/' + modeloId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getModeloById", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getModeloById", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getFamilias(filtro=null): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let url = environment.API_URL + '/almacenes/familias';
			if(filtro) {
				url += '?familia=' + filtro;
			}
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getFamilias", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getFamilias", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getTipos(proyectoSelected: number[], filterValue? : string, idTipo = null): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				projects : proyectoSelected,
				name : filterValue
			}

			if(idTipo) {
				params['idTipo'] = idTipo;
			}

			const url = environment.API_URL + '/almacenes/tipos-dispositivo?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTipos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTipos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getTiposPlanta(proyectoSelected: number[], filterValue? : string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				projects : proyectoSelected,
				name : filterValue
			}

			const url = environment.API_URL + '/almacenes/tipos-dispositivo-planta?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposPlanta", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposPlanta", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getFamiliasPlanta(proyectoSelected: number[], filterValue? : string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				projects : proyectoSelected,
				name : filterValue
			}

			const url = environment.API_URL + '/almacenes/familias-planta?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getFamiliasPlanta", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getFamiliasPlanta", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	createTipo(proyectoSelected, name : string, restParams = null): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				project : proyectoSelected,
				name : name
			}

			if(restParams) {
				params = {...params, ...restParams};
			}

			const url = environment.API_URL + '/almacenes/tipo-dispositivo';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws createTipo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws createTipo", err);

					observer.error(err.message ?? "Se ha producido un error cargando los datos");
				}
			);

			return {unsubscribe() {}};
		});
	}

	createMateriaPrima(formValues): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = formValues;

			const url = environment.API_URL + '/almacenes/materias-primas';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws createMateriaPrima", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws createMateriaPrima", err);

					observer.error(err.message ?? "Se ha producido un error cargando los datos");
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	
	getArticulos(proyectoSelected: number[], numPage: number, pageSize: number, columnOrder: string, columnDirection: string, filtros: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				projects : proyectoSelected,
				page: numPage,
				pageSize: pageSize,
			}

			if (columnDirection !== "") {
				params['sortColum'] = columnOrder;
				params['sortDirection'] = columnDirection;
			}

			console.log("filtros", filtros);
			if (filtros.pn && filtros.pn !== "") {
				params['pn'] = filtros.pn;
			}
			if (filtros.nombre && filtros.nombre !== "") {
				params['nombre'] = filtros.nombre;
			}
			if (filtros.tipo && filtros.tipo !== "") {
				params['tipo'] = filtros.tipo;
			}
			if (filtros.familia && filtros.familia !== 0) {
				params['familia'] = filtros.familia;
			}
			if (filtros.color && filtros.color !== "") {
				params['color'] = filtros.color;
			}
			if (filtros.marca && filtros.marca !== 0) {
				params['marca'] = filtros.marca;
			}
			if (filtros.modelo && filtros.modelo !== 0) {
				params['modelo'] = filtros.modelo;
			}
			if (filtros.kgs && filtros.kgs !== "") {
				params['kgs'] = filtros.kgs;
			}
			if (filtros.medida && filtros.medida !== 0) {
				params['medida'] = filtros.medida;
			}
			if (filtros.filtroSelector !== "") {
				params['filtroSelector'] = filtros.filtroSelector;
			}
			if (filtros.getAll !== "") {
				params['getAll'] = filtros.getAll;
			}
			// console.log("params filtro", params);

			const url = environment.API_URL + '/almacenes/maestro-articulos?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getArticulos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getArticulos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}


	crearArticulo(data, docs:Array<number>=null): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			let unidadMedida = null;

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			if(data.medida == "Peso"){
				unidadMedida = "P";
			}else{
				unidadMedida = "U";
			}

			const params = {
				pn: data.pn,
				name: data.nombre,
				dispositivo: data.tipo,
				familia: data.familia,
				color: data.color,
				marca: data.marca,
				modelo: data.modelo,
				kgs: data.kgs,
				medida: unidadMedida,
				notes: data.descripcion,
				consumo_energetico_medio: data.consumo_energetico_medio,
				consumo_energetico_texto: data.consumo_energetico_texto,
				fk_entity : data.fk_entity,
				id_test_device : data.id_test_device,
				materiasPrimas: data.materias_primas,
				subtipo2: data.subtipo2,
				subtipo3: data.subtipo3,
				first_activity_monthly: data.first_activity_monthly,
				energy_label: data.energy_label,
				infoweb: data.infoweb,
				ean: data.ean,
				//files: docs
			};

			console.log(params)


			const url = environment.API_URL + '/almacenes/maestro-articulos';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearArticulo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearArticulo", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.error_description) {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	modificarArticulo(idArticulo, dataArticulo: any, docs:Array<number>): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			let unidadMedida = null;
			let modelo = null;

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			if(dataArticulo.medida == "Peso"){
				unidadMedida = "P";
			}else{
				unidadMedida = "U";
			}

			const params = {
				pn: dataArticulo.pn,
				nombre: dataArticulo.nombre,
				tipo: dataArticulo.tipo,
				familia: dataArticulo.familia,
				color: dataArticulo.color,
				marca: dataArticulo.marca,
				modelo: dataArticulo.modelo,
				kgs: dataArticulo.kgs,
				medida: unidadMedida,
				notes: dataArticulo.descripcion,
				dimension_alto: dataArticulo.dimension_alto,
				dimension_ancho: dataArticulo.dimension_ancho,
				dimension_fondo: dataArticulo.dimension_fondo,
				consumo_energetico_medio: dataArticulo.consumo_energetico_medio,
				consumo_energetico_texto: dataArticulo.consumo_energetico_texto,
				tipo_alimentacion: dataArticulo.tipo_alimentacion,
				potencia_conexion: dataArticulo.potencia_conexion,
				fk_entity : dataArticulo.fk_entity,
				id_test_device : dataArticulo.id_test_device,
				materiasPrimas: dataArticulo.materias_primas,
				subtipo2: dataArticulo.subtipo2,
				subtipo3: dataArticulo.subtipo3,
				first_activity_monthly: dataArticulo.first_activity_monthly,
				energy_label: dataArticulo.energy_label,
				infoweb: dataArticulo.infoweb,
				ean: dataArticulo.ean,
				//files: docs
			};

			const url = environment.API_URL + '/almacenes/maestro-articulos/' + idArticulo;
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws editarArticulo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws editarArticulo", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.error === 'invalid_no_pn') {
						msgError = "El P/N indicado no existe";
					} else if(err.error.error_description) {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getArticulo(idArticulo: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/maestro-articulos/' + idArticulo;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getArticulo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getArticulo", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	deleteArticulo(idArticulo: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/maestro-articulos/' + idArticulo;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws borrarArticulo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws borrarArticulo", err);
					let msgError = "Se ha producido un error eliminando el artículo";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	uploadFileArticulo(idArticulo: number, file: File): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const headers = new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
			});

			const data = new FormData();
			data.append('file', file);

			const url = environment.API_URL + '/almacenes/maestro-articulos/' + idArticulo + '/imagen';
			this.http.post(url, data, {reportProgress: true, observe: 'events', headers: headers}).subscribe(
				(event: HttpEvent<any>) => {
					switch (event.type) {
						case HttpEventType.UploadProgress:
							let progress = Math.round(event.loaded / event.total * 100);
							console.log("Uploaded " + progress + "%");
							observer.next({progress: progress, res: null});
							break;
						
							case HttpEventType.Response:
							console.log("Upload file complete", event.body);
							observer.next({progress: 100, res: event.body});
							break;
					}
				}
			);

			return {unsubscribe() {}};
		});
	}

	deleteFileArticulo(idImagen: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/maestro-articulos/imagen/' + idImagen;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws deleteFileArticulo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws deleteFileArticulo", err);
					let msgError = "Se ha producido un error eliminando el documento";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	verImagen(idImagen: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const headers = new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
			});

			const url = environment.API_URL + '/almacenes/maestro-articulos/imagen/' + idImagen;
			this.http.get(url, {responseType: 'blob', headers: headers}).subscribe(
				(response: any) => {
					console.log("viewFile", response);
					// const file = new Blob([response.blob()]);
					const fileUrl = window.URL.createObjectURL(response);
					observer.next(fileUrl);
				},
				(err) => {
					console.log("error viewFile", err);
					observer.error(err);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getArticuloRelacion(idArticulo: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/alquiler/inventario/' + idArticulo;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getArticuloAlquiler", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getArticuloAlquiler", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getAlquilerInventario(id_inventario:number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/alquiler/inventario/' + id_inventario;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getAlquilerInventario", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAlquilerInventario", err);
					let msgError = "Se ha producido un error cargando los datos";
					
					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getAlquilerEspecifico(month: number, price:number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/alquiler/especifico/' + month + '/' + price;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getAlquilerEspecifico", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAlquilerEspecifico", err);
					let msgError = "Se ha producido un error cargando los datos";

					if(err.status == 404){
						msgError = "Alquiler no encontrado";
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	crearAlquiler(data): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				meses: data[0].meses,
				precio: data[0].precio
			};


			const url = environment.API_URL + '/almacenes/alquiler';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearAlquiler", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearAlquiler", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}



	crearArticuloRelacion(data): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				idInventario: data[0].id_inventario,
				idAlquiler: data[0].id_alquileres_meses
			};

			console.log(params)


			const url = environment.API_URL + '/almacenes/articulo-alquiler';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearArticuloAlquiler", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearArticuloAlquiler", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	deleteArticuloRelacion(id: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/articulo-alquiler/' + id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws deleteArticuloRelacion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws deleteArticuloRelacion", err);
					let msgError = "Se ha producido un error eliminando el documento";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	deleteArticuloRelacionID(idInventario: number, idAlquiler: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/articulo-alquiler/ids/' + idInventario + "/" + idAlquiler;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws deleteArticuloRelacion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws deleteArticuloRelacion", err);
					let msgError = "Se ha producido un error eliminando el documento";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}


	getJerarquiaArticulo(idArticulo: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/maestro-articulos/jerarquia/' + idArticulo;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws jerarquiaArticulo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws jerarquiaArticulo", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	deleteJerarquiaArticulo(idArticulo, idArticuloJerarquia): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/maestro-articulos/jerarquia/'+ idArticulo + '/' + idArticuloJerarquia;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws deleteJerarquiaArticulo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws deleteJerarquiaArticulo", err);
					let msgError = "Se ha producido un error eliminando el artículo";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	addJerarquiaArticulo(idArticulo, idArticuloJerarquia): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				pn_padre: idArticulo,
				pn_hijo: idArticuloJerarquia
			}

			const url = environment.API_URL + '/almacenes/maestro-articulos/jerarquia/';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws addJerarquiaArticulo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws addJerarquiaArticulo", err);
					let msgError = "Se ha producido un error añadiendo la jerarquía";
					if(err.error.error_description) {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	
	getCompatibilidadArticulo(idArticulo: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/maestro-articulos/compatibilidad/' + idArticulo;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws compatibilidadArticulo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws compatibilidadArticulo", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	deleteCompatibilidadArticulo(idArticulo, idArticuloCompatibilidad): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/maestro-articulos/compatibilidad/'+ idArticulo + '/' + idArticuloCompatibilidad;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws deleteCompatibilidadArticulo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws deleteCompatibilidadArticulo", err);
					let msgError = "Se ha producido un error eliminando el artículo";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	addCompatibilidadArticulo(idArticulo, idArticuloCompatibilidad): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				pn1: idArticulo,
				pn2: idArticuloCompatibilidad
			}

			const url = environment.API_URL + '/almacenes/maestro-articulos/compatibilidad/';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws addCompatibilidadArticulo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws addCompatibilidadArticulo", err);
					let msgError = "Se ha producido un error añadiendo la compatibilidad";
					if(err.error.error_description) {
						msgError = err.error.error_description;
					}
					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getClientesAlmacenes(filtro): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenesmovimientos/clientes?filtro='+filtro;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getClientesAlmacenes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getClientesAlmacenes", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getPlanta(proyectoSelected: number[], numPage: number, pageSize: number, columnOrder: string, columnDirection: string, filtros: any, all: number = 0): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				projects : proyectoSelected,
				page: numPage,
				pageSize: pageSize,
				getAll: all
			}
			//Filtros
			if (filtros.pn && filtros.pn !== "") {
				params['pn'] = filtros.pn;
			}
			if (filtros.serial && filtros.serial !== "") {
				params['serial'] = filtros.serial;
			}
			if (filtros.tipo && filtros.tipo !== "") {
				params['tipo'] = filtros.tipo;
			}
			if (filtros.marca && filtros.marca !== "") {
				params['marca'] = filtros.marca;
			}

			const url = environment.API_URL + '/almacenes/planta?' + this.helperSvc.serializeData(params);

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getAlmacenPlanta", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAlmacenPlanta", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	deletePlanta(id_movimiento: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/planta/' + id_movimiento;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws deletePlanta", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws deletePlanta", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	deleteElementoTienda(id_movimiento: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/tienda/elemento/' + id_movimiento;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws deleteElementoTienda", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws deleteElementoTienda", err);
					let msgError = "Se ha producido un error eliminando el elemento";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getPlantaContrato(proyectoSelected: number[], numPage: number, pageSize: number, columnOrder: string, columnDirection: string, filtros: any, all: number = 0): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				projects : proyectoSelected,
				page: numPage,
				pageSize: pageSize,
				getAll: all
			}
			//Filtros
			if (filtros.pn && filtros.pn !== "") {
				params['pn'] = filtros.pn;
			}
			if (filtros.serial && filtros.serial !== "") {
				params['serial'] = filtros.serial;
			}
			if (filtros.tipo_dispositivo && filtros.tipo_dispositivo !== "") {
				params['tipo_dispositivo'] = filtros.tipo_dispositivo;
			}
			if (filtros.marca && filtros.marca !== "") {
				params['marca'] = filtros.marca;
			}
			if (filtros.familia && filtros.familia !== "") {
				params['familia'] = filtros.familia;
			}
			if (filtros.fecha_fabricacion && filtros.fecha_fabricacion.startDate && filtros.fecha_fabricacion.endDate) {
				params['fecha_fabricacion'] = moment(filtros.fecha_fabricacion.startDate).format('YYYY-MM-DD') + ' - ' + moment(filtros.fecha_fabricacion.endDate).format('YYYY-MM-DD');
			}
			if (filtros.cod_ubicacion && filtros.cod_ubicacion !== "") {
				params['cod_ubicacion'] = filtros.cod_ubicacion;
			}
			if (filtros.cod_cliente && filtros.cod_cliente !== "") {
				params['cod_cliente'] = filtros.cod_cliente;
			}
			if (filtros.cpostal && filtros.cpostal !== "") {
				params['cpostal'] = filtros.cpostal;
			}
			if (filtros.id_provincia && filtros.id_provincia !== "") {
				params['id_provincia'] = filtros.id_provincia;
			}
			if (filtros.id_localidad && filtros.id_localidad !== "") {
				params['id_localidad'] = filtros.id_localidad;
			}
			if (filtros.nombre && filtros.nombre !== "") {
				params['nombre'] = filtros.nombre;
			}
			if (filtros.apellidos && filtros.apellidos !== "") {
				params['apellidos'] = filtros.apellidos;
			}
			if (filtros.tarjeta && filtros.tarjeta !== "") {
				params['tarjeta'] = filtros.tarjeta;
			}

			const url = environment.API_URL + '/almacenes/planta-contrato?' + this.helperSvc.serializeData(params);

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getAlmacenPlanta", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAlmacenPlanta", err);

					observer.error(err.error);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getTiendaElementos(proyectoSelected: number[], numPage: number, pageSize: number, columnOrder: string, columnDirection: string, filtros: any, all: number = 0): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				projects : proyectoSelected,
				page: numPage,
				pageSize: pageSize,
				getAll: all
			}
			//Filtros
			if (filtros.pn && filtros.pn !== "") {
				params['pn'] = filtros.pn;
			}
			if (filtros.serial && filtros.serial !== "") {
				params['serial'] = filtros.serial;
			}
			if (filtros.tipo_dispositivo && filtros.tipo_dispositivo !== "") {
				params['tipo_dispositivo'] = filtros.tipo_dispositivo;
			}
			if (filtros.marca && filtros.marca !== "") {
				params['marca'] = filtros.marca;
			}
			if (filtros.familia && filtros.familia !== "") {
				params['familia'] = filtros.familia;
			}
			if (filtros.fecha_fabricacion && filtros.fecha_fabricacion.startDate && filtros.fecha_fabricacion.endDate) {
				params['fecha_fabricacion'] = moment(filtros.fecha_fabricacion.startDate).format('YYYY-MM-DD') + ' - ' + moment(filtros.fecha_fabricacion.endDate).format('YYYY-MM-DD');
			}
			if (filtros.cod_ubicacion && filtros.cod_ubicacion !== "") {
				params['cod_ubicacion'] = filtros.cod_ubicacion;
			}
			if (filtros.cod_cliente && filtros.cod_cliente !== "") {
				params['cod_cliente'] = filtros.cod_cliente;
			}
			if (filtros.cpostal && filtros.cpostal !== "") {
				params['cpostal'] = filtros.cpostal;
			}
			if (filtros.id_provincia && filtros.id_provincia !== "") {
				params['id_provincia'] = filtros.id_provincia;
			}
			if (filtros.id_localidad && filtros.id_localidad !== "") {
				params['id_localidad'] = filtros.id_localidad;
			}
			if (filtros.nombre && filtros.nombre !== "") {
				params['nombre'] = filtros.nombre;
			}
			if (filtros.apellidos && filtros.apellidos !== "") {
				params['apellidos'] = filtros.apellidos;
			}
			if (filtros.tarjeta && filtros.tarjeta !== "") {
				params['tarjeta'] = filtros.tarjeta;
			}

			const url = environment.API_URL + '/tienda/elementos?' + this.helperSvc.serializeData(params);

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiendaElementos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiendaElementos", err);

					observer.error(err.error);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getPlantaMapa(proyectoSelected: number[]): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				projects : proyectoSelected,
			}

			const url = environment.API_URL + '/almacenes/planta-mapa?' + this.helperSvc.serializeData(params);

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPlantaMapa", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPlantaMapa", err);
					observer.error(err.error);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getAlmacenes(params): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getAlmacenes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAlmacenes", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getAlmaceUbicaciones(almacen): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/ubicaciones/' + almacen;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getAlmacenesUbicaciones", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAlmacenesUbicaciones", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getInventarioEstados(sap=false): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/maestro-articulos/estados?sap=' + sap;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getInventarioEstado", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getInventarioEstado", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	checkInventarioSerieLote(idArticulo): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/maestro-articulos/check-serie-lote/' + idArticulo;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws checkInventarioSerieLote", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws checkInventarioSerieLote", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	uploadFileInventario(file: File): Observable<any> {
			
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const headers = new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
			});

			const data = new FormData();
			data.append('file', file);

			const url = environment.API_URL + '/almacenes/inventario/upload';
			this.http.post(url, data, {reportProgress: true, observe: 'events', headers: headers}).subscribe(
				(event: HttpEvent<any>) => {
					switch (event.type) {
						case HttpEventType.UploadProgress:
							let progress = Math.round(event.loaded / event.total * 100);
							console.log("Uploaded " + progress + "%");
							observer.next({progress: progress, res: null});
							break;
						
							case HttpEventType.Response:
							console.log("Upload file complete", event.body);
							observer.next({progress: 100, res: event.body});
							break;
					}
				}
			);

			return {unsubscribe() {}};
		});
	}

	saveAlmacenesMovimientos(params): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			let unidadMedida = null;

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenesmovimientos/';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws saveAlmacenesMovimientos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws saveAlmacenesMovimientos", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.message ) {
						msgError = err.error.message;
					}

					if(err.error.error_description) {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	guardarElemento(params): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			let unidadMedida = null;

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenesmovimientos/elemento-planta';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws guardarElemento", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws guardarElemento", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.message ) {
						msgError = err.error.message;
					}

					if(err.error.error_description) {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	guardarElementoTienda(params): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			let unidadMedida = null;

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/tienda/elemento';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws guardarElementoTienda", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws guardarElementoTienda", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.message ) {
						msgError = err.error.message;
					}

					if(err.error.error_description) {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getElementosPnDisponibles(pn, almacen, lineasExistentes: Array<any>, lineaEdicion): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				pn: pn,
				almacen: almacen
			};

			const url = environment.API_URL + '/almacenesmovimientos/elementos-pn';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws getElementosPnDisponibles", response);

					let existentes = [];
					if(lineaEdicion) {
						existentes = lineasExistentes.filter((item) => {
							return item.ID != lineaEdicion.ID
						}).map((item) => {
							return item.ID;
						});
					}
					else {
						let pnSinNumeroSerie = lineasExistentes.find((item) => {
							return item.pn.ID === pn && !item.serial && !item.otherserial;
						});

						if(pnSinNumeroSerie) {
							existentes = lineasExistentes.filter((item) => {
								return item.pn.ID != pn
							}).map((item) => {
								return item.ID;
							});
						}
						else {
							existentes = lineasExistentes.map((item) => {
								return item.ID;
							});
						}
					}

					let serials = response.filter((item) => {
						return item.serial && !existentes.includes(item.id);
					}).map((item) => {
						return {
							id: item.id,
							serial: item.serial,
						}
					});

					let otherserials = response.filter((item) => {
						return item.otherserial && !existentes.includes(item.id);
					}).map((item) => {
						return {
							id: item.id,
							otherserial: item.otherserial,
						}
					});

					let result = response.filter((item) => {
						return !existentes.includes(item.id);
					}).map((item) => {
                        return {
							id: item.id,
							serial: item.serial,
							otherserial: item.otherserial,
							disponible: item.acumulado,
							estado: item.estado
						};
                    });

					let finalResult = {
						items: result,
						serials: serials,
						otherserials: otherserials,
					};

					observer.next(finalResult);
				},
				(err) => {
					console.log("error ws getElementosPnDisponibles", err);
					let msgError = "Se ha producido un error";

					if (err.error.message ) {
						msgError = err.error.message;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getSerialByPN(pn, almacen): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				pn: pn,
				almacen: almacen
			};

			
			const url = environment.API_URL + '/almacenesmovimientos/serialByPN?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getSerialByPN", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getSerialByPN", err);
					let msgError = "Se ha producido un error al buscar el telefono asociado con la tarjeta";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

	getDispositivosCliente(idCliente, garantia10?: string): Observable<any>{
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				id_cliente: idCliente
			};
			if (garantia10) {
				params['garantia10'] = garantia10;
			}

			const url = environment.API_URL + '/almacenes/dispositivos-cliente-garantia10?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDispositivosCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDispositivosCliente", err);
					let msgError = "Se ha producido un error enviando el email";

					observer.error(msgError);
				}
			);

			return { unsubscribe() { } };
		});
	}

    getStockPorProyecto(numPage: number, pageSize: number, columnOrder: string, columnDirection: string, filtros: any): Observable<any>{
        return new Observable<any>(observer => {
            const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

            if (filtros.fecha_limite_garantia && filtros.fecha_limite_garantia.startDate && filtros.fecha_limite_garantia.startDate !== null 
                && filtros.fecha_limite_garantia.endDate && filtros.fecha_limite_garantia.endDate !== null) {
                filtros['fechaLimiteGarantiaInicio'] = moment(filtros.fecha_limite_garantia.startDate).format('YYYY-MM-DD HH:mm:ss');
                filtros['fechaLimiteGarantiaFin'] = moment(filtros.fecha_limite_garantia.endDate).format('YYYY-MM-DD HH:mm:ss');
            }

            if (filtros.fecha_entrada && filtros.fecha_entrada.startDate && filtros.fecha_entrada.startDate !== null 
                && filtros.fecha_entrada.endDate && filtros.fecha_entrada.endDate !== null) {
                filtros['fechaEntradaInicio'] = moment(filtros.fecha_entrada.startDate).format('YYYY-MM-DD HH:mm:ss');
                filtros['fechaEntradaFin'] = moment(filtros.fecha_entrada.endDate).format('YYYY-MM-DD HH:mm:ss');
            }

            const url = environment.API_URL + '/almacenes/stock-proyecto?' + this.helperSvc.serializeData(filtros);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getStockPorProyecto", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getStockPorProyecto", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

            return { unsubscribe() { } };
        });
    }

	getAlmacenMinimos(id): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/minimos/' + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getAlmacenMinimos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAlmacenMinimos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	updateAlmacenMinimos(item, cantidad): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				id_almacen: item.id_almacen,
				id_elemento: item.id_elemento ? item.id_elemento : null,
				nuevoPn: item.nuevoPn ? item.nuevoPn : null,
				cantidad: cantidad
			};

			const url = environment.API_URL + '/almacenes/minimos';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws updateAlmacenMinimos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws updateAlmacenMinimos", err);
					let msgError = "Se ha producido un error actualizando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}


	uploadFilePlanta(file: File, proyecto): Observable<any> {
			
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const headers = new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
			});

			const data = new FormData();
			data.append('file', file);
			data.append('idProyecto', proyecto);

			const url = environment.API_URL + '/almacenes/planta-contrato/upload';
			this.http.post(url, data, {reportProgress: true, observe: 'events', headers: headers}).subscribe(
				(event: HttpEvent<any>) => {
					switch (event.type) {
						case HttpEventType.UploadProgress:
							let progress = Math.round(event.loaded / event.total * 100);
							console.log("Uploaded " + progress + "%");
							observer.next({progress: progress, res: null});
							break;
						
							case HttpEventType.Response:
							console.log("Upload file complete", event.body);
							observer.next({progress: 100, res: event.body});
							break;
					}
				}
			);

			return {unsubscribe() {}};
		});
	}

	getPlantaDireccionesUbicacion(filtro, idAlmacen): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				filtro: filtro,
				idAlmacen: idAlmacen
			};

			const url = environment.API_URL + '/almacenes/planta-direcciones/?' +  this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPlantaDireccionesUbicacion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPlantaDireccionesUbicacion", err);
					let msgError = "Se ha producido un error cargando los datos";
					if (err.error.error_description) {
						msgError = err.error.error_description;
					}
					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	updatePlantaDireccionUbicacion(idMovimiento, idDireccion): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				idMovimiento: idMovimiento,
				idDireccion: idDireccion
			};

			const url = environment.API_URL + '/almacenes/planta-direcciones/';
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws updatePlantaDireccionUbicacion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws updatePlantaDireccionUbicacion", err);
					let msgError = "Se ha producido un error actualizando los datos";
					if (err.error.error_description) {
						msgError = err.error.error_description;
					}
					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getDesgloseActivoPlanta(idMaestroArticulo): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/desglose-activo/' +  idMaestroArticulo;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDesgloseActivoPlanta", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDesgloseActivoPlanta", err);
					let msgError = "Se ha producido un error cargando los datos";
					if (err.error.error_description) {
						msgError = err.error.error_description;
					}
					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getExpedicionesActivoPlanta(idMovimiento): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/expediciones-activo/' +  idMovimiento;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getExpedicionesActivoPlanta", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getExpedicionesActivoPlanta", err);
					let msgError = "Se ha producido un error cargando los datos";
					if (err.error.error_description) {
						msgError = err.error.error_description;
					}
					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getLogActivosPlanta(proyectos, numPage: number, pageSize: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				proyectos : proyectos,
				page: numPage,
				pageSize: pageSize,
			}

			const url = environment.API_URL + '/almacenes/log-activos?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getLogActivosPlanta", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getLogActivosPlanta", err);
					let msgError = "Se ha producido un error cargando los datos";
					if (err.error.error_description) {
						msgError = err.error.error_description;
					}
					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getInfoActivo(params): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/info-activo?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getInfoActivo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getInfoActivo", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getPiezasActivo(idActivo: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/activo/piezas/' + idActivo;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPiezasActivo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPiezasActivo", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getPiezasProyecto(idProyecto: number, pn: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				proyecto: idProyecto,
				pn: pn
			}

			const url = environment.API_URL + '/almacenes/listado-pn-proyecto?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPiezasProyecto", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPiezasProyecto", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getDatosRecuperabilidad(idActivo: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/activo/recuperabilidad-pieza/' + idActivo;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getRecuperabilidadPieza", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getRecuperabilidadPieza", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getEstadosPieza(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/activo/estados';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getEstadosPieza", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getEstadosPieza", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	getNivelesRecuperabilidad(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/activo/niveles-recuperabilidad';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getNivelesRecuperabilidad", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getNivelesRecuperabilidad", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	modificarEscandalloActivo(idActivo, idEscandallo, numSerie, idPN): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				idActivo: idActivo,
				idEscandallo: idEscandallo,
				numSerie: numSerie,
				idPN: idPN
			}

			const url = environment.API_URL + '/almacenes/escandallo';
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws modificarEscandalloActivo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws modificarEscandalloActivo", err);

					observer.error(err.message ?? "Se ha producido un error cargando los datos");
				}
			);

			return {unsubscribe() {}};
		});
	}

	getInfoPlanta(proyectoSelected: number[]): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				projects : proyectoSelected,
			}

			const url = environment.API_URL + '/almacenes/info-planta?' + this.helperSvc.serializeData(params);

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getInfoPlanta", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getInfoPlanta", err);

					observer.error(err.error);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getTiposDispositivosCategoria(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/dispositivo-categoria';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposDispositivosCategoria", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposDispositivosCategoria", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getTiposDispositivos2(proyectoSelected: number[]): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				projects : proyectoSelected,
			}

			const url = environment.API_URL + '/almacenes/tipos-dispositivo-2?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposDispositivos2", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposDispositivos2", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	createTipoDispositivo2(proyectoSelected, name : string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				project : proyectoSelected,
				name : name
			}

			const url = environment.API_URL + '/almacenes/tipo-dispositivo-2';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws createTipoDispositivo2", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws createTipoDispositivo2", err);

					observer.error(err.message ?? "Se ha producido un error cargando los datos");
				}
			);

			return {unsubscribe() {}};
		});
	}

	getTiposDispositivos3(proyectoSelected: number[]): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				projects : proyectoSelected,
			}

			const url = environment.API_URL + '/almacenes/tipos-dispositivo-3?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposDispositivos3", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposDispositivos3", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	createTipoDispositivo3(proyectoSelected, name : string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				project : proyectoSelected,
				name : name
			}

			const url = environment.API_URL + '/almacenes/tipo-dispositivo-3';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws createTipoDispositivo3", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws createTipoDispositivo3", err);

					observer.error(err.message ?? "Se ha producido un error cargando los datos");
				}
			);

			return {unsubscribe() {}};
		});
	}

	getDispositivoUso(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/device-usage';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDispositivoUso", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDispositivoUso", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getDispositivoTipoResiduo(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/device-waste-type';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDispositivoTipoResiduo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDispositivoTipoResiduo", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getDispositivoCategoriaResiduo(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/device-waste-category';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDispositivoCategoriaResiduo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDispositivoCategoriaResiduo", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	deleteTipoDispositivo(idTipo: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/tipo-dispositivo/' + idTipo;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws deleteTipoDispositivo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws deleteTipoDispositivo", err);
					let msgError = "Se ha producido un error eliminando el tipo de dispositivo";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getArticulosLite(proyectoSelected: number[], parametros = null): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				projects : proyectoSelected,
			}

			if(parametros) {
				params = {...params, ...parametros};
			}

			const url = environment.API_URL + '/almacenes/maestro-articulos-lite?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getArticulosLite", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getArticulosLite", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getMarcasMaestro(proyectoSelected: number[], parametros = null): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				projects : proyectoSelected,
			}

			if(parametros) {
				params = {...params, ...parametros};
			}

			const url = environment.API_URL + '/almacenes/manufacturers-maestro?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getMarcasMaestro", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getMarcasMaestro", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getTiposMaestro(proyectoSelected: number[], parametros = null): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				projects : proyectoSelected,
			}

			if(parametros) {
				params = {...params, ...parametros};
			}

			const url = environment.API_URL + '/almacenes/tipos-dispositivo-maestro?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposMaestro", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposMaestro", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getModelosMaestro(proyectoSelected: number[], parametros = null): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				projects : proyectoSelected,
			}

			if(parametros) {
				params = {...params, ...parametros};
			}

			const url = environment.API_URL + '/almacenes/modelos-maestro?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getModelosMaestro", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getModelosMaestro", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
}