import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class GreenTechPassportService {

	onLanguageChange: BehaviorSubject<any>;
	onConfigLoad: BehaviorSubject<any>;
	onInfoDeviceLoad: BehaviorSubject<any>;

	configLoad$: Observable<any>; 

	personalizado: boolean = false;
	gtpPrimaryColor: string;
	gtpSecondaryColor: string;
	gtpPrimaryColorContrast: string;
	fileLogo: string;
	fileHojas: string;
	fileLogoFooter: string;

	colorGtp: string = '#00C600';
	colorAzcoyen: string = '#e4022d';

  	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService,
	) {
		this.onLanguageChange = new BehaviorSubject(null);
		this.onConfigLoad = new BehaviorSubject(null);
		this.onInfoDeviceLoad = new BehaviorSubject(null);

		this.configLoad$ = this.onConfigLoad.asObservable();

		this.gtpPrimaryColor = this.colorGtp;
		this.gtpSecondaryColor = this.colorGtp;
		this.gtpPrimaryColorContrast = '#000000de';
		this.fileLogo = "assets/images/" + environment.entorno + "/digipass-logo.png";
		this.fileHojas = "assets/images/greentech/hojas.png";
		this.fileLogoFooter = null;
	}

	setIdioma(idioma:string) {
		sessionStorage.setItem('gtp-idioma', idioma);
		this.onLanguageChange.next(idioma);
	}

	getIdioma() {
		return sessionStorage.getItem('gtp-idioma');
	}

	generarUrl(idDispositivo: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				id_dispositivo: idDispositivo
			}

			const url = environment.API_URL + '/green-tech-passport/generar-url?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP generar URL", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP generar URL", err);
					let msgError = "Se ha producido un error cargando los datos";

					if (err.error.error === 'consulta_dispositivo') {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	loadConfiguracion(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				'origin' : window.location.host
			}

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/config';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws GTP getConfiguracion", response);
					this.personalizado = response.personalizado;
					this.gtpPrimaryColor = response.primaryColor;
					this.gtpSecondaryColor = response.secondaryColor;
					this.fileLogo = 'assets/images/' + environment.entorno + '/' + response.fileLogo;
					this.fileHojas = 'assets/images/greentech/' + response.fileHojas;
					this.fileLogoFooter = response.fileLogoFooter ? 'assets/images/greentech/' + response.fileLogoFooter : null;

					this.onConfigLoad.next(response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getConfiguracion", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	getInfoDevice(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/info-device';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getInfoDevice", response);
					this.onInfoDeviceLoad.next(response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getInfoDevice", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	getDiagnosisDevice(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const gtpIdioma = this.getIdioma();

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/diagnosis-device?lang='+gtpIdioma;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getDiagnosisDevice", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getDiagnosisDevice", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	getCheckInOutDevice(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/checkinout-device';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getCheckInOutDevice", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getCheckInOutDevice", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getTrazabilidad(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const gtpIdioma = this.getIdioma();

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/trazabilidad?lang='+gtpIdioma;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getTrazabilidad", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getTrazabilidad", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getVidaUtil(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/vida-util';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getVidaUtil", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getVidaUtil", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getCentroConocimiento(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/centro-conocimiento';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getCentroConocimiento", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getCentroConocimiento", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    descargarDocumentoBlob(hash: string, ID: string){
		const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/centro-conocimiento/documento/' + ID;
		return this.http.get(url,{responseType:'blob'});
    }

    descargarDocumento(hash: string, ID: string){
		return new Observable<any>(observer => {

			const options = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/centro-conocimiento/documento/' + ID;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP descargarDocumento", response);
					observer.next(response.base64);
				},
				(err) => {
					console.log("error ws GTP descargarDocumento", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
    }

	getEcoRating(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/eco-rating';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getVidaUtil", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getVidaUtil", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getBuyback(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/buyback';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getBuyback", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getBuyback", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	solicitarRecompra(hash: string, formData): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = formData;

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/buyback/solicitar';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws GTP solicitarRecompra", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP solicitarRecompra", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getElementData(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/data';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getElementData", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getElementData", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	saveElementData(hash: string, formData): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = formData;

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/data';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws GTP saveElementData", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP saveElementData", err);
					let msgError = "Se ha producido un error cargando los datos";
					if(err.error && err.error.message) {
						msgError = err.error.message;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getConsumoEnergia(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/consumo-energia';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getConsumoEnergia", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getConsumoEnergia", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getEtiquetaEnergetica(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/etiqueta-energetica';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getEtiquetaEnergetica", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getEtiquetaEnergetica", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getReparabilidad(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/reparabilidad';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getReparabilidad", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getReparabilidad", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getReciclabilidad(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/reciclabilidad';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getReciclabilidad", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getReciclabilidad", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getHuellaEcologica(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/huella-ecologica';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getHuellaEcologica", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getHuellaEcologica", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getInfoReduceHuella(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const gtpIdioma = this.getIdioma();
			const params = {
				lang: gtpIdioma
			}

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/info-mejora-huella?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getInfoReduceHuella", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getInfoReduceHuella", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getInfoMejorarValoracion(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const gtpIdioma = this.getIdioma();
			const params = {
				lang: gtpIdioma
			}

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/info-mejora-valoracion?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getInfoMejorarValoracion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getInfoMejorarValoracion", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getImagesDevice(hash: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(hash) + '/images-device';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getImagesDevice", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getImagesDevice", err);
					let msgError = "Se ha producido un error cargando las imágenes";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getCertificaciones(idTicket: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/green-tech-passport/' + encodeURIComponent(idTicket) + '/certificaciones';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws GTP getCertificaciones", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws GTP getCertificaciones", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
}
