import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { DbService } from './db.service';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from 'environments/environment';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class NetsuiteService {

  constructor(
    private http: HttpClient, 
    private db: DbService, 
    private authSvc: AuthService,
    private helperSvc: HelperService
  ) { }

  buscaElementos(almacen = '', pn = '', sn = '', sni = '', desc = '', cantidad = '', estado = '', company = '', restoAlmacenes = '', pedidoRelacionado = '') {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

      let params = {
        almacen: almacen,
        sn: sn,
        pn: pn,
        sni: sni,
        desc: desc,
        cantidad: cantidad,
        estado: estado,
        company: company,
        restoAlmacenes: restoAlmacenes,
        pedidoRelacionado: pedidoRelacionado
      };

			const url = environment.API_URL + '/netsuite/busca-elementos?' + this.helperSvc.serializeData(params);
      this.http.get(url, options)
        .subscribe(data => {
          observer.next(data);
        }, err => {
          console.log("error netsuite get elementos", err);
          let msgError = err.error.error_description;
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  buscaAlmacenes(filtro = '', company = '', soloid = '', igual = '') { 
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

      let params = {
        company: company,
        filtro: filtro,
        soloid: soloid,
        igual: igual
      };

			const url = environment.API_URL + '/netsuite/busca-almacenes?' + this.helperSvc.serializeData(params);
      this.http.get(url, options)
        .subscribe(data => {
          // console.log("almacenes", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite get almacenes", err);
          let msgError = "No se han podido cargar los datos";
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  solicitudesPiezaSap(almacen) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/solicitudes-pieza-sap';
      this.http.get(url+"?almacen="+almacen, options)
        .subscribe(data => {
          // console.log("solicitudes pieza sap", data);
          observer.next(data);
        }, err => {
          console.log("error get solicitudes pieza sap", err);
          let msgError = "No se han podido cargar los datos";
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  ticketsPiezasReparacion(serials) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/tickets-arreglo-piezas';
      this.http.get(url+"?serials="+serials, options)
        .subscribe(data => {
          // console.log("tickets piezas en reparación", data);
          observer.next(data);
        }, err => {
          console.log("error get tickets piezas en reparación", err);
          let msgError = "No se han podido cargar los datos";
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  buscaClientes(filtro, setInitValue = false, sociedad = 'EXXITA') {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

      let params;
      if(setInitValue) {
        params = {
          idCliente: filtro,
        }
      }
      else {
        params = {
          filtro: filtro,
        }
      }

      params['sociedad'] = sociedad;

			const url = environment.API_URL + '/netsuite/clientes?' + this.helperSvc.serializeData(params);
      this.http.get(url, options).subscribe(
        data => {
          // console.log("ws netsuite buscaClientes", data);
          observer.next(data);
        }, 
        err => {
          console.log("error ws netsuite buscaClientes", err);
          let msgError = "No se han podido cargar los datos";
  
          observer.error(msgError);
        }
      );
  
      return {unsubscribe() {}};
    });
  }

  loadAlmacenesGestionNetsuite() {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/almacenes/almacenes-sap';

      this.http.get(url, options)
        .subscribe(data => {
          console.log("ws loadAlmacenesGestionSap", data);
          observer.next(data);
        }, err => {
          console.log("error loadAlmacenesGestionSap", err);
          let msgError = "No se han podido cargar los datos";

          observer.error(msgError);
      });

      return {unsubscribe() {}};
    });
  }

  getMaestroArticulos(filtro = '', sociedad = 'EXXITA') {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

      let params = {
        filtro: filtro,
        sociedad: sociedad
      };

			const url = environment.API_URL + '/netsuite/maestro-articulos?' + this.helperSvc.serializeData(params);
      this.http.get(url, options)
        .subscribe(data => {
          console.log("get maestro articulos", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite get maestro articulos", err);
          let msgError = "No se han podido cargar los datos";
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  getProveedores(filtro = '', sociedad = 'EXXITA') {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

      let params = {
        filtro: filtro,
        sociedad: sociedad
      };

			const url = environment.API_URL + '/netsuite/proveedores?' + this.helperSvc.serializeData(params);
      this.http.get(url, options)
        .subscribe(data => {
          console.log("get proveedores", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite get proveedores", err);
          let msgError = "No se han podido cargar los datos";
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  getLineasNegocio(filtro = '', sociedad = 'EXXITA') {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

      let params = {
        filtro: filtro,
        sociedad: sociedad
      };

			const url = environment.API_URL + '/netsuite/proyectos?' + this.helperSvc.serializeData(params);
      this.http.get(url, options)
        .subscribe(data => {
          console.log("get lineas negocio", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite get lineas negocio", err);
          let msgError = "No se han podido cargar los datos";
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  getPedidosProveedor(idProveedor, numPedido, numPage = 0, pageSize = 10) { 
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

      let params = {
        idProveedor: idProveedor,
        numPedido: numPedido,
        numPage: numPage,
        pageSize: pageSize
      };

			const url = environment.API_URL + '/netsuite/proveedor/pedidos?' + this.helperSvc.serializeData(params);
      this.http.get(url, options)
        .subscribe(data => {
          console.log("getPedidosProveedor", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite getPedidosProveedor", err);
          let msgError = "No se han podido cargar los datos";
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  nuevoPedidoCompra(params) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/netsuite/pedido-compra';
			this.http.post(url, params, options)
        .subscribe(data => {
          console.log("nuevoPedidoCompra", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite nuevoPedidoCompra", err);
          let msgError = "No se han podido guardar los datos";
          if(err.error && err.error.message) {
            msgError = err.error.message;
          }
          else if(err.error && err.error.text) {
            msgError = err.error.text;
          }
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  nuevoPedidoVenta(params) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/netsuite/pedido-venta';
			this.http.post(url, params, options)
        .subscribe(data => {
          console.log("nuevoPedidoVenta", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite nuevoPedidoVenta", err);
          let msgError = "No se han podido guardar los datos";
          if(err.error && err.error.message) {
            msgError = err.error.message;
          }
          else if(err.error && err.error.text) {
            msgError = err.error.text;
          }
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  modificarPedidoCompra(params) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/netsuite/pedido';
			this.http.put(url, params, options)
        .subscribe(data => {
          console.log("modificarPedidoCompra", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite modificarPedidoCompra", err);
          let msgError = "No se han podido cargar los datos";
          if(err.error && err.error.message) {
            msgError = err.error.message;
          }
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  cambiarEstadoInventario(params) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/netsuite/estado-inventario';
			this.http.post(url, params, options)
        .subscribe(data => {
          console.log("cambiarEstadoInventario", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite cambiarEstadoInventario", err);
          let msgError = "No se han podido cargar los datos";
          if(err.error && err.error.message) {
            msgError = err.error.message;
          }
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  getUltimosMovimientos(pn, serial) { 
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

      const params = {
        pn: pn,
        serial: serial
      };

			const url = environment.API_URL + '/netsuite/ultimos-movimientos';
      this.http.post(url, params, options)
        .subscribe(data => {
          console.log("getUltimosMovimientos", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite getUltimosMovimientos", err);
          let msgError = "No se han podido cargar los datos";
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  getDesglosePedido(params) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/netsuite/desglose-pedido';
			this.http.post(url, params, options)
        .subscribe(data => {
          console.log("getDesglosePedido", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite getDesglosePedido", err);
          let msgError = "No se han podido cargar los datos";
          if(err.error && err.error.message) {
            msgError = err.error.message;
          }
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  servirPedidoCompra(params) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/netsuite/servir-pedido-compra';
			this.http.post(url, params, options)
        .subscribe(data => {
          console.log("servirPedidoCompra", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite servirPedidoCompra", err);
          let msgError = "No se han podido cargar los datos";
          if(err.error && err.error.message) {
            msgError = err.error.message;
          }
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  nuevoCliente(params) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/netsuite/cliente';
			this.http.post(url, params, options)
        .subscribe(data => {
          console.log("nuevoCliente", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite nuevoCliente", err);
          let msgError = "No se ha podido crear el cliente";
          if(err.error && err.error.message) {
            msgError = err.error.message;
          }
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  nuevoPartNumber(params) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/netsuite/nuevo-pn';
			this.http.post(url, params, options)
        .subscribe(data => {
          console.log("nuevoPartNumber", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite nuevoPartNumber", err);
          let msgError = "No se ha podido crear el P/N";
          if(err.error && err.error.message) {
            msgError = err.error.message;
          }
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  getPedidoPdf(idPedido) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/netsuite/pedido-pdf/' + idPedido;
			this.http.get(url, options)
        .subscribe(data => {
          console.log("getPedidoPdf", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite getPedidoPdf", err);
          let msgError = "No se han podido cargar los datos";
          if(err.error && err.error.message) {
            msgError = err.error.message;
          }
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  nuevoProveedor(params) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/netsuite/proveedor';
			this.http.post(url, params, options)
        .subscribe(data => {
          console.log("nuevoProveedor", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite nuevoProveedor", err);
          let msgError = "No se ha podido crear el proveedor";
          if(err.error && err.error.message) {
            msgError = err.error.message;
          }
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  getDatosProveedor(idProveedor) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/netsuite/proveedor/' + idProveedor;
			this.http.get(url, options)
        .subscribe(data => {
          console.log("getDatosProveedor", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite getDatosProveedor", err);
          let msgError = "No se han podido obtener los datos";
          if(err.error && err.error.message) {
            msgError = err.error.message;
          }
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

	uploadFileNetsuite(file: File, rango): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
      //ISOR
		  //Preparado para pruebas
      /*
      observer.next(
          [
            {
                "itemCode": "3EH73092AC",
                "itemName": "Placa de interfaces analógicas SLI8-2: 8 interfaces analógicas",
                "quantity": 1,
                "rate": 314.2606,
                "existe": true,
                "isSerial": false,
                "type": "inventoryitem",
                "contabilizacionDirecta": false,
                "disponible": 0
            },
            {
                "itemCode": "3MG27202WW",
                "itemName": "8028s Premium Deskphone",
                "quantity": 28,
                "rate": 124.8196,
                "existe": true,
                "isSerial": true,
                "type": "serializedinventoryitem",
                "contabilizacionDirecta": false,
                "disponible": "2"
            },
            {
                "itemCode": "TERANAGMN",
                "itemName": "Terminal analógico gama media (Negro), ",
                "quantity": 10,
                "rate": 24.4,
                "existe": true,
                "isSerial": true,
                "type": "serializedinventoryitem",
                "contabilizacionDirecta": false,
                "disponible": "47"
            }
        ]
      );
    */

			const headers = new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
			});

			const data = new FormData();
			data.append('file', file);
			data.append('rango', rango);

			const url = environment.API_URL + '/netsuite/upload-file';
			this.http.post(url, data, {reportProgress: true, observe: 'events', headers: headers}).subscribe(
				(event: HttpEvent<any>) => {
					switch (event.type) {
						case HttpEventType.UploadProgress:
							let progress = Math.round(event.loaded / event.total * 100);
							console.log("Uploaded " + progress + "%");
							// observer.next({progress: progress, res: null});
							break;
						
							case HttpEventType.Response:
							console.log("Upload file complete", event.body);
              observer.next(event.body);
							// observer.next({progress: 100, res: event.body});
							break;
					}
				},
        (data) => {
          console.log("respuesta", data);
          if(data.error) {
            console.log("error netsuite uploadFileNetsuite", data.error);
            let msgError = "No se ha podido leer el excel";
            if(data.error && data.error.message) {
              msgError = data.error.message;
            }
            observer.error(msgError);
          }
          else {
            observer.next(data);
          }
        }
			);

			return {unsubscribe() {}};
		});
	}

	uploadFileCompraNetsuite(file: File, rango, columnsExcel): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
			const headers = new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
			});

			const data = new FormData();
			data.append('file', file);
			data.append('rango', rango);
			data.append('colItem', columnsExcel.colItem);
			data.append('colDesc', columnsExcel.colDesc);
			data.append('colQty', columnsExcel.colQty);
			data.append('colRate', columnsExcel.colRate);

			const url = environment.API_URL + '/netsuite/upload-file-compra';
			this.http.post(url, data, {reportProgress: true, observe: 'events', headers: headers}).subscribe(
				(event: HttpEvent<any>) => {
					switch (event.type) {
						case HttpEventType.UploadProgress:
							let progress = Math.round(event.loaded / event.total * 100);
							console.log("Uploaded " + progress + "%");
							// observer.next({progress: progress, res: null});
							break;
						
							case HttpEventType.Response:
							console.log("Upload file complete", event.body);
              observer.next(event.body);
							// observer.next({progress: 100, res: event.body});
							break;
					}
				},
        (data) => {
          console.log("respuesta", data);
          if(data.error) {
            console.log("error netsuite uploadFileCompraNetsuite", data.error);
            let msgError = "No se ha podido leer el excel de compra";
            if(data.error && data.error.message) {
              msgError = data.error.message;
            }
    
            observer.error(msgError);
          }
          else {
            observer.next(data);
          }
        }
			);

			return {unsubscribe() {}};
		});
	}

  getDatosCliente(idCliente) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/netsuite/cliente/' + idCliente;
			this.http.get(url, options)
        .subscribe(data => {
          console.log("getDatosCliente", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite getDatosCliente", err);
          let msgError = "No se han podido obtener los datos";
          if(err.error && err.error.message) {
            msgError = err.error.message;
          }
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }

  getImpuestos(tipoPedido) {
    return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/netsuite/impuestos?tipo='+tipoPedido;
			this.http.get(url, options)
        .subscribe(data => {
          console.log("getImpuestos", data);
          observer.next(data);
        }, err => {
          console.log("error netsuite getImpuestos", err);
          let msgError = "No se han podido cargar los datos";
          if(err.error && err.error.message) {
            msgError = err.error.message;
          }
  
          observer.error(msgError);
      });
  
      return {unsubscribe() {}};
    });
  }
}
