import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import {SnotifyDefaults, SnotifyPosition, SnotifyService} from 'ng-snotify';

// import { RainbowService } from 'app/services/rainbow/rainbow.service';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { locale as navigationSpanish } from 'app/navigation/i18n/es';

import { locale as comunSpanish } from 'app/i18n/es';
import { locale as comunEnglish } from 'app/i18n/en';

import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { SocketService } from './services/socket/socket.service';
import { UserService } from './services/user/user.service';
import { Block } from 'notiflix';
import { environment } from 'environments/environment';

@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy 
{
    fuseConfig: any;
    navigation: any;
    items: Observable<any[]>;
    usuario: any; 

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        // private _rbwService: RainbowService,
        private _platform: Platform,
        firestore: AngularFirestore,
        private snotifyService: SnotifyService,
        private socketService: SocketService,
        private authService: AuthService,
        private userService: UserService,
        private router: Router,
    )
    {

        // // Get default navigation
        // this.navigation = navigation;

        // // Register the navigation to the service
        // this._fuseNavigationService.register('main', this.navigation);

        // // Set the main navigation as our current navigation
        // this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'es']);

        // Set the default language
        this._translateService.setDefaultLang('es');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationSpanish);
        this._fuseTranslationLoaderService.loadTranslations(comunEnglish, comunSpanish);

        // Use a language
        this._translateService.use('es');

        // this.authService.setEntorno();

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.items = firestore.collection('items').valueChanges();

        let configDefault: SnotifyDefaults = {
            toast: { position: SnotifyPosition.rightTop }
        };
        snotifyService.setDefaults(configDefault);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if ( this.fuseConfig.layout.width === 'boxed' )
                {
                    this.document.body.classList.add('boxed');
                }
                else
                {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for ( let i = 0; i < this.document.body.classList.length; i++ )
                {
                    const className = this.document.body.classList[i];

                    if ( className.startsWith('theme-') )
                    {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

            this.userService.isUsuarioLoaded.pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (login: boolean) => {
                    if (login) {
                        setTimeout(() => {
                            this.loadMenu();                        
                        }, 200);
                    }
                }
            );

        // this.authService.loggedIn.pipe(takeUntil(this._unsubscribeAll)).subscribe(
        //     (login: boolean) => {
        //         if (login) {
        //             setTimeout(() => {
        //                 this.loadMenu();                        
        //             }, 200);
        //         }
        //     }
        // );

        // if (this.authService.isUserLoggedIn()) {
        //     setTimeout(() => {
        //         this.loadMenu();

        //         // if (environment.entorno === 'ewaste') {
        //         //     this.router.navigate(['dashboards/triaje']);
        //         // }
        //     }, 200);
        // }

        // this.socketService.setupSocketConnection();

        // this._rbwService.init();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        this.socketService.disconnect();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    loadMenu() {
        if (environment.entorno !== 'gtp' && environment.entorno !== 'circpass_gtp') {
            this.initLoading('.navbar-content');
            this.userService.getMenu(environment.entorno, navigation).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (dataNavigation: any) => {
                    this.stopLoading('.navbar-content');
                    // Get default navigation
                    this.navigation = dataNavigation;
    
                    // Register the navigation to the service
                    this._fuseNavigationService.register('main', this.navigation);
    
                    // Set the main navigation as our current navigation
                    this._fuseNavigationService.setCurrentNavigation('main');
                },
                (err) => {
                    this.stopLoading('.navbar-content');
                }
            );
        }
    }

    initLoading(selector, textoLoading?: string) {
		// this.loading = true;
        Block.standard(selector, textoLoading ? textoLoading : '', {
            svgColor: '#666',
            messageColor: '#666',
            messageFontSize: '18px',
            backgroundColor: 'rgba(255,255,255,0.8)'
        });
	}

	stopLoading(selector) {
		// this.loading = false;
		Block.remove(selector);
	}
}