<ng-container *ngIf="form" [formGroup]="form">
    <mat-select [formControlName]="name" [class]="estilo" (selectionChange)="onChange($event)" [multiple]="multiple">
        <div class="select-panel">
            <mat-select-trigger *ngIf="multiple">
                <mat-chip-list>
                    <mat-chip *ngFor="let elemento of seleccionados" [removable]="true" (removed)="deseleccionar(elemento)" [style.background-color]="elemento.color">
                        {{ elemento[elementVal] }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </mat-select-trigger>

            <mat-option *ngIf="buscador">
                <ngx-mat-select-search [formControl]="control"  
                [placeholderLabel]="titulo" noEntriesFoundLabel="No se encontró ningún resultado"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of filtered | async" [value]="item[elementVal]" [disabled]="item.disabled_option">{{item[elementText]}}</mat-option>
        </div>
        
        <footer class="select-footer" *ngIf="showCreate">
            <button type="button" mat-button class="btn-aitana" style="margin: 5px;" (click)="nuevaOpcionDialog($event)">Crear {{nombreElemento}}</button>
        </footer>
    </mat-select>
</ng-container>