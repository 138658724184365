import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Garantia10Service } from '../../../services/db/garantia10.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-analisis-proyecto',
  templateUrl: './analisis-proyecto.component.html',
  styleUrls: ['./analisis-proyecto.component.scss']
})
export class AnalisisProyectoComponent implements OnInit {

    public currentPage : number = 0;
    public perPage = 10;
    public currentOrder = "";
    public currentDirection = "";
    public proyectos = null;

    public totalStock_Proyecto:number = 0;
    public tipoDispositivo_Proyecto = "";
    public finGarantiaOficial_Proyecto = null;
    public porcentajeAverias_Proyecto = null;
    public arrayGastos = new Array;

    public promedio = new Array;
    public totalTickets:number = 0;
    public totalGastos = new Array;
    public rentabilidad = 0;
    public rentabilidadEstimada = 0;

    private fechaAux = new Date();
    public meses = new Array();


    dataSource = new MatTableDataSource();
    displayedColumns : any[] = ['proyecto.name', 'tipo.name', 'modalidad.name', 'totalStockElementos', 'tipo_dispositivo', 'garantia', 
'contrato.fecha_comienzo', 'contrato.fecha_vencimiento', 'rentabilidad', 'rentabilidad_prevista', 'totalAverias', 'contrato.extension_garantia'];

    dataSourceElementos = new MatTableDataSource();
    displayedColumnsElementos : any[] = ['columna'];
    

  constructor(private garantiaService: Garantia10Service, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
        this.meses = [this.fechaAux.getFullYear()+"-01",this.fechaAux.getFullYear()+"-02",this.fechaAux.getFullYear()+"-03",this.fechaAux.getFullYear()+"-04",
            this.fechaAux.getFullYear()+"-05",this.fechaAux.getFullYear()+"-06",this.fechaAux.getFullYear()+"-07",this.fechaAux.getFullYear()+"-08",this.fechaAux.getFullYear()+"-09",
            this.fechaAux.getFullYear()+"-10",this.fechaAux.getFullYear()+"-11",this.fechaAux.getFullYear()+"-12"];

        this.getProyectoRow(params.get('idProyecto'));
    });
  }

  getProyectoRow(idProyecto){
    this.garantiaService.getAnalisisProyecto(this.currentPage,this.perPage, this.currentOrder, this.currentDirection, idProyecto).subscribe(
        (data) => {
            //Creamos la row del proyecto
            this.proyectos = data.proyectos;
            this.totalStock_Proyecto = data.totalStock;
            this.tipoDispositivo_Proyecto = data.tipoDispositivo;
            this.finGarantiaOficial_Proyecto = data.fechaFinGarantia;
            this.porcentajeAverias_Proyecto = (parseFloat(data.porcentajeAverias)).toFixed(2);
            this.dataSource.data = [data.proyectos[0]];

            //Creamos las row de elementos
            //this.dataSourceElementos.data = [{hola: 'holaaaa', adios: 'adiossss'}];
            //this.arrayGastos = data.registros;
            var totalMeses = 0;
            var totalGastosPieza = 0;
            var totalGastosTransporte = 0;
            var totalGastosPartner = 0;
            var totalGastosObra = 0;

            for(let i=0; i<this.meses.length; i++){
                if(data.registros[this.meses[i]]){
                    totalMeses++;
                    this.totalGastos[this.meses[i]] = 0;

                    if(data.registros[this.meses[i]].total_tickets && data.registros[this.meses[i]].total_tickets != "" && data.registros[this.meses[i]].total_tickets > 0){
                        this.totalTickets += parseInt(data.registros[this.meses[i]].total_tickets);

                        if(data.registros[this.meses[i]].gastos_pieza && data.registros[this.meses[i]].gastos_pieza != ""){
                            data.registros[this.meses[i]].gastos_pieza = parseFloat((data.registros[this.meses[i]].gastos_pieza/data.registros[this.meses[i]].total_tickets).toFixed(2));
                            totalGastosPieza += data.registros[this.meses[i]].gastos_pieza;
                            this.totalGastos[this.meses[i]] += parseFloat(data.registros[this.meses[i]].gastos_pieza);
                        }

                        if(data.registros[this.meses[i]].gastos_transporte && data.registros[this.meses[i]].gastos_transporte != ""){
                            data.registros[this.meses[i]].gastos_transporte = parseFloat((data.registros[this.meses[i]].gastos_transporte/data.registros[this.meses[i]].total_tickets).toFixed(2));
                            totalGastosTransporte += data.registros[this.meses[i]].gastos_transporte;
                            this.totalGastos[this.meses[i]] += parseFloat(data.registros[this.meses[i]].gastos_transporte);
                        }

                        if(data.registros[this.meses[i]].gastos_partner && data.registros[this.meses[i]].gastos_partner != ""){
                            data.registros[this.meses[i]].gastos_partner = parseFloat((data.registros[this.meses[i]].gastos_partner/data.registros[this.meses[i]].total_tickets).toFixed(2));
                            totalGastosPartner += data.registros[this.meses[i]].gastos_partner;
                            this.totalGastos[this.meses[i]] += parseFloat(data.registros[this.meses[i]].gastos_partner);
                        }

                        if(data.registros[this.meses[i]].gastos_obra && data.registros[this.meses[i]].gastos_obra != ""){
                            data.registros[this.meses[i]].gastos_obra = parseFloat((data.registros[this.meses[i]].gastos_obra/data.registros[this.meses[i]].total_tickets).toFixed(2));
                            totalGastosObra += data.registros[this.meses[i]].gastos_obra;
                            this.totalGastos[this.meses[i]] += parseFloat(data.registros[this.meses[i]].gastos_obra);
                        }
                    }
                }
            }

            if(totalMeses>0){
                this.promedio['tickets'] = this.totalTickets/totalMeses;
                this.promedio['pieza'] = totalGastosPieza/totalMeses;
                this.promedio['transporte'] = totalGastosTransporte/totalMeses;
                this.promedio['partner'] = totalGastosPartner/totalMeses;
                this.promedio['obra'] = totalGastosObra/totalMeses;
            }

            this.rentabilidad = data.rentabilidad.toFixed(2);
            this.rentabilidadEstimada = data.rentabilidadEstimada.toFixed(2);

            this.arrayGastos = data.registros;
        }
    );
  }

}
