import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
	) { }

    getAll(numPage: number, pageSize: number, columnOrder: string, columnDirection: string, filtros: any, all?: boolean): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
                page: numPage,
                pageSize: pageSize,
                allData: all ? all : false
			}

            if(filtros.cif_nif && filtros.cif_nif != ""){
                params['cif_nif'] = filtros.cif_nif;
            }
            if(filtros.nombre && filtros.nombre != ""){
                params['nombre'] = filtros.nombre;
            }
            if(filtros.apellidos && filtros.apellidos != ""){
                params['apellidos'] = filtros.apellidos;
            }
            if(filtros.tlf_contacto && filtros.tlf_contacto != ""){
                params['tlf_contacto'] = filtros.tlf_contacto;
            }
            if(filtros.movil_contacto && filtros.movil_contacto != ""){
                params['movil_contacto'] = filtros.movil_contacto;
            }
            if(filtros.fax_contacto && filtros.fax_contacto != ""){
                params['fax_contacto'] = filtros.fax_contacto;
            }
            if(filtros.email_contacto && filtros.email_contacto != ""){
                params['email_contacto'] = filtros.email_contacto;
            }
            if(filtros.tipo_cliente && filtros.tipo_cliente != ""){
                params['tipo_cliente'] = filtros.tipo_cliente;
            }
            if(filtros.fecha_creacion && filtros.fecha_creacion != ""){
                params['fechaCreacionInicio'] = moment(filtros.fecha_creacion.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaCreacionFin'] = moment(filtros.fecha_creacion.endDate).format('YYYY-MM-DD HH:mm:ss');
            }
            if(filtros.comunicaciones_myfixpert && filtros.comunicaciones_myfixpert != ""){
                params['comunicaciones_myfixpert'] = filtros.comunicaciones_myfixpert;
            }
            if(filtros.direccion_facturacion && filtros.direccion_facturacion != ""){
                params['direccion_facturacion'] = filtros.direccion_facturacion;
            }
            if(filtros.direccion_envio && filtros.direccion_envio != ""){
                params['direccion_envio'] = filtros.direccion_envio;
            }
            if(filtros.direccion_recogida && filtros.direccion_recogida != ""){
                params['direccion_recogida'] = filtros.direccion_recogida;
            }
            if(filtros.direccion_contacto && filtros.direccion_contacto != ""){
                params['direccion_contacto'] = filtros.direccion_contacto;
            }

			if(filtros.netsuite) {
				params['netsuite_cifnif'] = filtros.netsuite.netsuite_cifnif;
				params['netsuite_nombre'] = filtros.netsuite.netsuite_nombre;
			}

            if (columnDirection !== "") {
				params['sortColum'] = columnOrder;
				params['sortDirection'] = columnDirection;
			}
            
			const url = environment.API_URL + '/clientesolicitante/all?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getClientes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getCitas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getCliente(idCliente: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/clientesolicitante/' + idCliente;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getCliente", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	getTiposCliente(idProyecto?: number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let url = environment.API_URL + '/clientesolicitante/tipos';
			if (idProyecto) {
				const params = {
					proyecto: idProyecto
				}

				url += '?' + this.helperSvc.serializeData(params);
			}
	
			
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTipoCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTipoCliente", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	getBolsaHoras() {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const data = [
				{ ID: 1, descripcion: "Bolsa Horas 1" },
				{ ID: 2, descripcion: "Bolsa Horas 2" },
				{ ID: 3, descripcion: "Bolsa Horas 3" },
			];
			observer.next(data);
	
			// const url = environment.API_URL + '/cliente/tipos';
			// this.http.get(url, options).subscribe(
			// 	(response: any) => {
			// 	console.log("ws getTicketTracking", response);
			// 	observer.next(response);
			// 	},
			// 	(err) => {
			// 	console.log("error ws getTicketTracking", err);
			// 	let msgError = "Se ha producido un error cargando los datos";
		
			// 	observer.error(msgError);
			// 	}
			// );
		
			return {unsubscribe() {}};
        });
	}
	
	getTiposDireccion() {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/direcciones/tipos-direccion';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposDireccion", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	getPaises(filtro?) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			let url = environment.API_URL + '/direcciones/paises';
			if (filtro) {

				let params = {};
				params['filtro'] = filtro;

				url += '?' + this.helperSvc.serializeData(params);
			}

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPaises", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPaises", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	getProvincias(idPais?: number, filtro?: string) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let url = environment.API_URL + '/direcciones/provincias';

			if (idPais || filtro) {

				let params = {};
				if(idPais) {
					params['pais'] = idPais;
				}
				if(filtro) {
					params['filtro'] = filtro;
				}

				url += '?' + this.helperSvc.serializeData(params);
			}
	
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getProvincias", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getProvincias", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}
	
	getLocalidades(filtro: string, idProvincia?: any) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				filtro: filtro,
			}
			if (idProvincia) {
				params['provincia'] = idProvincia;
			}
	
			const url = environment.API_URL + '/direcciones/localidades?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getLocalidades", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getLocalidades", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	buscarClientes(filtros: any, pageSize?: Number) {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				pageSize: pageSize ? pageSize : 150
			};

			if (filtros.tipo_cliente && filtros.tipo_cliente !== "") {
				params['tipoCliente'] = filtros.tipo_cliente;
			}
			if (filtros.nombreApellido && filtros.nombreApellido !== "") {
				params['nombreApellido'] = filtros.nombreApellido;
			}
			if (filtros.nombre && filtros.nombre !== "") {
				params['nombreApellido'] = filtros.nombre;
			}
			if (filtros.apellidos && filtros.apellidos !== "") {
				params['apellidos'] = filtros.apellidos;
			}
			if (filtros.cif_nif && filtros.cif_nif !== "") {
				params['nif'] = filtros.cif_nif;
			}
			if (filtros.telefono && filtros.telefono !== "") {
				params['telefono'] = filtros.telefono;
			}
			if (filtros.email && filtros.email !== "") {
				params['email'] = filtros.email;
			}
			if (filtros.direccion && filtros.direccion !== "") {
				params['direccion'] = filtros.direccion;
			}
			if (filtros.id_pais && filtros.id_pais !== "") {
				params['idPais'] = filtros.id_pais;
			}
			if (filtros.id_provincia && filtros.id_provincia !== "") {
				params['idProvincia'] = filtros.id_provincia;
			}
			if (filtros.id_localidad && filtros.id_localidad !== "") {
				params['idLocalidad'] = filtros.id_localidad;
			}
			if (filtros.filtroCadena && filtros.filtroCadena !== "") {
				params['filtroCadena'] = filtros.filtroCadena;
			}
			if (filtros.garantia10 && filtros.garantia10 !== "") {
				params['garantia10'] = filtros.garantia10;
			}
			if (filtros.preFiltroCallCenter && filtros.preFiltroCallCenter !== "") {
				params['preFiltroCallCenter'] = filtros.preFiltroCallCenter;
			}
			if (filtros.conGarantia10) {
				params['conGarantia10'] = filtros.conGarantia10;
			}
	
			const url = environment.API_URL + '/clientesolicitante/find?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws buscarCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws buscarCliente", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	getDistribuidores() {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();
		
			const options = {
				headers: new HttpHeaders({
				'Authentication': 'Bearer ' + accessToken,
				'Content-Type': 'application/json; charset=utf-8'
				})
			}
	
			const url = environment.API_URL + '/clientesolicitante/distribuidores';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDistribuidores", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDistribuidores", err);
					let msgError = "Se ha producido un error cargando los datos";
			
					observer.error(msgError);
				}
			);
		
			return {unsubscribe() {}};
        });
	}

	crearCliente(dataCliente:any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				tipo_cliente: dataCliente.tipo_cliente,
				id_cliente_padre: dataCliente.id_cliente_padre,
				nombre: dataCliente.nombre,
				apellido: dataCliente.apellido,
				cif_nif: dataCliente.cif_nif,
				email_contacto: dataCliente.email_contacto,
				tlf_contacto: dataCliente.tlf_contacto,
				movil_contacto: dataCliente.movil_contacto,
				fax_contacto: dataCliente.fax_contacto,
				sms: dataCliente.sms,
				whatsapp: dataCliente.whatsapp,
				publicidad: dataCliente.publicidad
			};

			const url = environment.API_URL + '/clientesolicitante/cliente-ticket';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearCliente", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error && err.error.length > 0) {
						let msg = '';
						for (const itemErr of err.error) {
							if (itemErr.constraints) {
								const keysValidators = Object.keys(itemErr.constraints);
								for (const key of keysValidators) {
									msg += itemErr.constraints[key] + ". ";
								}
							}
						}
						msgError = msg !== '' ? msg : msgError;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	modificarCliente(idCliente, dataCliente: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				tipo_cliente: dataCliente.tipo_cliente,
				id_cliente_padre: dataCliente.id_cliente_padre,
				nombre: dataCliente.nombre,
				apellido: dataCliente.apellido,
				cif_nif: dataCliente.cif_nif,
				email_contacto: dataCliente.email_contacto,
				tlf_contacto: dataCliente.tlf_contacto,
				movil_contacto: dataCliente.movil_contacto,
				fax_contacto: dataCliente.fax_contacto,
				sms: dataCliente.sms,
				whatsapp: dataCliente.whatsapp,
				publicidad: dataCliente.publicidad
			};

			const url = environment.API_URL + '/clientesolicitante/cliente-ticket/' + idCliente;
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws modificarCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws modificarCliente", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	asignarClienteTicket(idCliente, idTicket): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				idCliente: idCliente,
				idTicket: idTicket
			};

			const url = environment.API_URL + '/clientesolicitante/asignar-cliente-ticket';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws asignarClienteTicket", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws asignarClienteTicket", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	asignarTecnicoUbicacionCliente(idCliente, idTicket): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				idCliente: idCliente,
				idTicket: idTicket
			};

			const url = environment.API_URL + '/clientesolicitante/asignar-tecnico-ubicacion';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws asignarTecnicoUbicacionCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws asignarTecnicoUbicacionCliente", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	crearDireccion(dataDireccion:any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = dataDireccion;

			const url = environment.API_URL + '/clientesolicitante/crear-direccion';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearDireccion", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.error === 'tipo_direccion_exist') {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    crearDireccionCliente(dataDireccion:any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = dataDireccion;

			const url = environment.API_URL + '/clientesolicitante/crear-direccion-cliente';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearDireccion", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.error === 'tipo_direccion_exist') {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	modificarDireccion(idDireccion, dataDireccion: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = dataDireccion;

			const url = environment.API_URL + '/clientesolicitante/update-direccion/' + idDireccion;
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws modificarDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws modificarDireccion", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.error === 'tipo_direccion_exist') {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    modificarDireccionCliente(idDireccion, dataDireccion: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = dataDireccion;

			const url = environment.API_URL + '/clientesolicitante/update-direccion-cliente/' + idDireccion;
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws modificarDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws modificarDireccion", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.error === 'tipo_direccion_exist') {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    eliminarDireccion(idDireccion: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/clientesolicitante/delete/' + idDireccion;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws eliminarDireccion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws eliminarDireccion", err);
					let msgError = "Se ha producido un error eliminando la dirección";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    eliminarCliente(idCliente: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/clientesolicitante/delete-cliente/' + idCliente;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws eliminarCliente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws eliminarCliente", err);
					let msgError = "Se ha producido un error eliminando el cliente";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	direccionesCompletas(direcciones: Array<any>, tiposDireccion: Array<any>): boolean {
		let dirCompletas = true;

		for (const tipoDir of tiposDireccion) {
			const direccion = direcciones.find((item) => { return item.tipo_direccion == tipoDir.ID; });
			if (!direccion) {
				dirCompletas = false;
				break;
			}
		}

		return dirCompletas;
	}

	getClientesShipto(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/clientesolicitante/shipto';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getClientesShipto", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getClientesShipto", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
}
