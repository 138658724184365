import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import QRCode from 'qrcode';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dialog-qr-code',
  templateUrl: './dialog-qr-code.component.html',
  styleUrls: ['./dialog-qr-code.component.scss']
})
export class DialogQrCodeComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private snackBar: MatSnackBar,
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {
		if (this.data.cadenaQr) {
			var canvas = document.getElementById('canvas');
			QRCode.toCanvas(canvas,this.data.cadenaQr, { width: 300});
		}
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}

	getImageQrCode() {
		if (this.data.cadenaQr) {
			QRCode.toDataURL(this.data.cadenaQr, { errorCorrectionLevel: 'H' }, (err, url) => {
				if (err) {
					console.log("Error toDataUrl", err);
					this.showSnack("No se ha podido generar la imagen");
				}
				else {
					console.log(url);
					const image = new Image();
					image.src = url;
					
					const newTab = window.open("",  'targetWindow', "width=250,height=250");
					newTab.document.write(image.outerHTML);
				}
			});
		}
	}

}
