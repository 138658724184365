import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { TicketsService } from 'app/services/ticket/tickets.service';
import { takeUntil } from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { UserService } from 'app/services/user/user.service';
import {MatTableDataSource} from '@angular/material/table';
import { indexOf, reject } from 'lodash';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormularioCamposProyectoDialogComponent } from 'app/componentes/formulario-campos-proyecto-dialog/formulario-campos-proyecto-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-buscador-ns',
  templateUrl: './buscador-ns.component.html',
  styleUrls: ['./buscador-ns.component.scss']
})
export class BuscadorNsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  formEstadoInterno : FormGroup;
  
  typeSearchs = [
		
		{ text: "N/S", value: 0, iconLib: "fas", iconName: "barcode", icon: "4k" },
	
	];
	typeSearchSelected: any;
	textSearch: string;
	@ViewChild('searchTickets') inputSearch: ElementRef;
	@ViewChild('searchNss') searchNss: ElementRef;
	dataSource: MatTableDataSource<any>;
	numPage: number;
	pageSize: number;
	fecha: string;
	currentOrder: string;
	currentDirection: string;
	totalElementos: number;
  	tickets: Array<any>;
	ticketsAsignado: Array<any>;
	usuario: any;
	envios: Array<any>;
	seguimientos : Array<any>;
	estados_internos: Array<any> = [];
	estados_permitidos: Array<any> = [];
	estados_internos_permitidos: Array<any> = [];
	modificado: boolean;
	mostrarMensaje : boolean;
	mensajeUsuario: string;
	mostrarOpciones : boolean;
	
  constructor(
    	private router: Router,
		private sanitizer: DomSanitizer,
    	private ticketsSvc: TicketsService,
    	private snackBar: MatSnackBar,
		private userSvc: UserService,
		private dialog: MatDialog, 
		private fb:FormBuilder
  ) {
		this._unsubscribeAll = new Subject();
    	console.log("typesearch", this.typeSearchs);
		this.typeSearchSelected = this.typeSearchs[0];
		this.textSearch = "";
    	this.tickets = [];
		this.ticketsAsignado = [];
		this.numPage = 0;
		this.pageSize = 20;
		this.currentOrder = 'date_mod';
		this.currentDirection = 'desc';
		this.totalElementos = 0;
		this.dataSource = new MatTableDataSource();
		this.envios = [];
		this.seguimientos = [];
		this.modificado = false;
		this.mostrarMensaje = false;
		this.mensajeUsuario = "";
		this.mostrarOpciones = false;
		this.estados_internos_permitidos = [];
		this.formEstadoInterno = this.fb.group({
			estado_interno_permitido : []
		});
   }

  ngOnInit(): void {
	this.loadUser();
//	this.loadPermisosEstados();
	//this.comprobarTicketAsignado();
	
  }


  ngAfterViewInit() {

	this.focusInput();
	
	}

  ngOnDestroy(): void {
	// Unsubscribe from all subscriptions
	this._unsubscribeAll.next();
	this._unsubscribeAll.complete();
  }

  focusInput() {

		setTimeout(() => {
		
			if (this.searchNss) {
			
			this.searchNss.nativeElement.focus();
			
			}
		
		}, 100);
	
	}

  selectTypeSearch(item: any) {
		if (item.value === 9) { // N/S multiple
			const url = "/open-link?url="+environment.linkNewAitana+"/tickets/buscar-ns-multiple";

			window.open(url, '_blank');
		}
		else {
			this.typeSearchSelected = item;
			setTimeout(() => {
				this.inputSearch.nativeElement.focus();
			});
		}
	}


	searchv5() {

		this.mostrarMensaje = true;
		this.mensajeUsuario = "";
		this.estados_internos_permitidos = [];
		console.log('Número de serie',this.textSearch);

		//if(this.textSearch != ""){
			let filtros = {};
	
			let cambioEstado = false;
	
			filtros['numeroserie'] = this.textSearch;
			filtros['estado'] = 7; //No cerrado
			filtros['tecnico'] = 0; //No asignado
			let propio = 3; //Busco sin tener en cuenta asignación
			console.log('PROPIO CLIENTE', propio);
			//console.log('Filtros buscador unei',filtros);
			this.ticketsSvc.getTickets(this.numPage, this.pageSize, this.fecha, this.currentOrder, this.currentDirection, filtros, propio).pipe(takeUntil(this._unsubscribeAll)).subscribe(
				(data: any) => {
					this.tickets = data.elements;
					console.log('Tickets ',this.tickets);				
					console.log('Usuario',this.usuario);
					if(this.tickets.length>0){
						if(this.tickets[0].assign == 0 || (this.tickets[0].assign == this.usuario.id && !this.tickets[0].estado_interno)){
							console.log('Comprueba permisos del numero de serie introducido');
							//Obtenemos los estados siguientes del estado del ticket.

							if(!this.tickets[0].estado_interno){
								this.tickets[0].estado_interno = 0;
							}
							this.ticketsSvc.getPermisosEstadosInternoUsuario(this.tickets[0].ID, this.tickets[0].FK_entities, this.tickets[0].estado_interno).subscribe(
								(data: any) => {
									this.estados_permitidos = data;
									if(this.tickets[0].estado_interno == 0){
							
										//obtenemos el estado inicial del proyecto

										this.ticketsSvc.getEstadosInternosInicialProyecto(this.tickets[0].ID).subscribe(
											(data: any) => {
												if (data.id != null) {
													console.log('Obtenemos el estado inicial del proyecto', data.id);
													//Comprobamos que el usuario tenga permisos para el estado inicial
													if(this.estados_permitidos.indexOf(data.id)> -1){
														console.log('Tiene permiso para el estado inicial', data.id);
														this.modificado = true;
														// //Se asigna el ticket al usuario
														this.ticketsSvc.updateProperty(this.tickets[0].ID, 'assign', this.usuario.id).subscribe(
															() => {
																console.log('Tickets autoasignado',this.tickets[0].ID);
			
																this.ticketsSvc.updateProperty(this.tickets[0].ID, 'status', 'assign').subscribe(
																	() => {
																		console.log('Tickets en curso',this.tickets[0].ID);
																		//Se cambia al estado inicial
																		this.ticketsSvc.updateProperty(this.tickets[0].ID, 'estado_interno', data.id).subscribe(
																			() => {
																				// this.router.navigate(['tickets']);
																				console.log('Tickets estado cambiado',this.tickets[0].ID);
																				if(this.tickets[0]){
																					this.textSearch = '';
																					this.mostrarMensaje = false;
																					const dialogConfig = new MatDialogConfig();

																					dialogConfig.disableClose = true;
																					dialogConfig.autoFocus = true;
																					dialogConfig.minWidth = "70%";
																					dialogConfig.maxWidth = "95%";
																				//	dialogConfig.maxHeight = "90vh";
																			
																					dialogConfig.data = {
																						ticket: this.tickets[0],
																					};
																				
																					let dialogRef = this.dialog.open(FormularioCamposProyectoDialogComponent, dialogConfig);
																				
																					dialogRef.componentInstance.closeDialog.subscribe(() => {
																						this.dialog.closeAll();
																					});
																					dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
                                                                                        console.log('Resultado 1',result);
                                                                                        if (result && result !== undefined && result.res == true) {
                                                                                            this.comprobarTicketAsignado();
                                                                                        }
                                                                                    });
																				}
																				
																				
																			},
																			(err) => {
																				this.showSnack(err);
																			}
																		);
																	
																	},
																	(err) => {
																		this.showSnack(err);
																	}
																);
			

															},
															(err) => {
																this.showSnack(err);
															}
														);
													}else{
														this.mostrarMensaje = true;
														this.mensajeUsuario = "No tiene permitido cambiar al estado " + this.tickets[0].estadoInterno.nombre;
													}
												}else{
													console.log('El proyecto no tiene estado inicial');
												}

											}
										);

										
									}else{
										//Comprobar que el usuario tiene permiso para el estado asignado
		
										
										if(this.estados_permitidos.indexOf(this.tickets[0].estado_interno)> -1){
											console.log('El usuario tiene permitido el estado actual del ticket', this.tickets[0].estado_interno);
											this.modificado = true;
											// //Se asigna el ticket al usuario
											this.ticketsSvc.updateProperty(this.tickets[0].ID, 'assign', this.usuario.id).subscribe(
												() => {
													console.log('Tickets autoasignado',this.tickets[0].ID);

													this.ticketsSvc.updateProperty(this.tickets[0].ID, 'status', 'assign').subscribe(
														() => {
															console.log('Tickets en curso',this.tickets[0].ID);

														
														},
														(err) => {
															this.showSnack(err);
														}
													);

													//Abre formulario

													if(this.tickets[0]){
														this.textSearch = '';
														this.mostrarMensaje = false;
														const dialogConfig = new MatDialogConfig();

														dialogConfig.disableClose = true;
														dialogConfig.autoFocus = true;
														dialogConfig.minWidth = "70%";
														dialogConfig.maxWidth = "95%";
													//	dialogConfig.maxHeight = "90vh";
												
														dialogConfig.data = {
															ticket: this.tickets[0],
														};
													
														let dialogRef = this.dialog.open(FormularioCamposProyectoDialogComponent, dialogConfig);
													
														dialogRef.componentInstance.closeDialog.subscribe(() => {
															this.dialog.closeAll();
														});
														dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
															console.log('Resultado 2',result);
															if (result && result !== undefined && result.res == true) {
																this.comprobarTicketAsignado();
															}
														});
													}
												},
												(err) => {
													this.showSnack(err);
												}
											);
										}else{
											console.log('El usuario NO tiene permitido el estado', this.tickets[0].estado_interno);
											this.mostrarMensaje = true;
											this.mensajeUsuario = "No tiene permitido cambiar al estado " + this.tickets[0].estadoInterno.nombre;
										}
											//});
										
		
									}

								});
							
						}
						else{

							if(this.tickets[0].assign == this.usuario.id){
								console.log('Abre el formulario inicial',this.tickets[0].ID);
								if(this.tickets[0] && this.tickets[0].estado_interno){
									this.mostrarCamposProyecto(this.tickets[0]);
									this.textSearch = '';
								}
								
							}else{
								

								setTimeout(() => {
		
									//this.showSnack('El ticket ya tiene un técnico asignado');
                                    this.mostrarMensaje = true;
                                    
                                    if(this.tickets[0].tecnico){
                                        this.mensajeUsuario = "El ticket ya está asignado al técnico "+this.tickets[0].tecnico.displayName;
                                    }else{
                                        this.mensajeUsuario = "El ticket ya tiene un técnico asignado";
                                    }

                                    if(this.tickets[0].estadoInterno)
                                        this.mensajeUsuario += " y está pendiende de "+this.tickets[0].estadoInterno.nombre;
                                    }
                                , 100);
							}
							
						}
						
	
					}else{
						
						setTimeout(() => {
		
						//this.showSnack('No se han encontrado ninugn ticket con número de serie');
						this.mostrarMensaje = true;
						
						this.mensajeUsuario = "No existe ticket con el número de serie introducido";

						
						}, 100);
					}
					
				},
				(err) => {
					this.showSnack(err);
				}
			)

	}

	// searchv4() {

	// 	this.mostrarMensaje = true;
	// 	this.mensajeUsuario = "";
	// 	this.estados_internos_permitidos = [];
	// 	console.log('Número de serie',this.textSearch);

	// 	//if(this.textSearch != ""){
	// 		let filtros = {};
	
	// 		let cambioEstado = false;
	
	// 		filtros['numeroserie'] = this.textSearch;
	// 		filtros['estado'] = 7; //No cerrado
	// 		filtros['tecnico'] = 0; //No asignado
		
	
	// 		console.log('Filtros',filtros);
	// 		this.ticketsSvc.getTickets(this.numPage, this.pageSize, this.fecha, this.currentOrder, this.currentDirection, filtros).pipe(takeUntil(this._unsubscribeAll)).subscribe(
	// 			(data: any) => {
	// 				this.tickets = data.elements;
	// 				console.log('Tickets ',this.tickets);				
	// 				console.log('Usuario',this.usuario);
	// 				if(this.tickets.length>0){
	// 					if(this.tickets[0].assign == 0){
	// 						console.log('Comprueba permisos del numero de serie introducido');
	// 						//Obtenemos los estados siguientes del estado del ticket.
	// 						if(!this.tickets[0].estado_interno){
	// 							this.tickets[0].estado_interno = 0;
	// 						}
	
	// 						this.ticketsSvc.getEstadosInternos(this.tickets[0].ID, this.tickets[0].FK_entities, this.tickets[0].estado_interno).subscribe(
	// 							(data: any) => {
	// 								this.estados_internos = data;
	
	// 								console.log('Estados Internos Siguientes',this.estados_internos);
	
	// 								//Obtenemos los estados que tiene permiso el usuario
	
	// 								this.ticketsSvc.getPermisosEstadosInternoUsuario(this.tickets[0].ID, this.tickets[0].FK_entities, this.tickets[0].estado_interno).subscribe(
	// 									(data: any) => {
	// 										this.estados_permitidos = data;


	
	// 										console.log('Estados Permitidos',this.estados_permitidos);

	// 										//
	// 										this.modificado = false;
	// 										for (var i=0; i<this.estados_internos.length;i++){
	// 											console.log('Siguientes estados',this.estados_internos[i].id);
	// 											if(this.estados_internos[i].id != this.tickets[0].estado_interno){
			
	// 												if(this.estados_permitidos.indexOf(this.estados_internos[i].id)> -1){
	// 													console.log('El usuario tiene permitido el siguiente estado', this.estados_internos[i].id);
	// 													this.modificado = true;
	// 													this.estados_internos_permitidos.push(this.estados_internos[i]);
	// 												}else{
	// 													console.log('El usuario NO tiene permitido el siguiente estado', this.estados_internos[i].id);
	// 												}
	// 											}else{
	// 												console.log('El estad del ticket es', this.estados_internos[i].id);
	// 											}
	// 										}
	// 										//
										
	// 									//	setTimeout(() => {
	// 										// if(!this.modificado){
	// 										// 	//this.showSnack('No tiene permitido cambiar al siguiente estado');
	// 										// 	this.mostrarMensaje = true;
	// 										// 	this.mensajeUsuario = "No tiene permitido cambiar al siguiente estado";
												
	// 										// }else{
	// 										// 	console.log('Ticket modificado');
	// 										// }
											
	// 											console.log('Estados siguientes permitido Usuario',this.estados_internos_permitidos);

	// 											if(this.estados_internos_permitidos.length>0){
	// 												console.log('Cambios de estado y tecnico');
	// 												if(this.estados_internos_permitidos.length == 1){
	// 													console.log('Cambios de estado y tecnico automatico');

	// 													// //Se asigna el ticket al usuario
	// 													this.ticketsSvc.updateProperty(this.tickets[0].ID, 'assign', this.usuario.id).subscribe(
	// 														() => {
	// 															console.log('Tickets autoasignado',this.tickets[0].ID);

	// 															this.ticketsSvc.updateProperty(this.tickets[0].ID, 'status', 'assign').subscribe(
	// 																() => {
	// 																	console.log('Tickets en curso',this.tickets[0].ID);

																	
	// 																},
	// 																(err) => {
	// 																	this.showSnack(err);
	// 																}
	// 															);
	// 														},
	// 														(err) => {
	// 															this.showSnack(err);
	// 														}
	// 													);

	// 													//Se cambia al primer estado permitido
	// 													this.ticketsSvc.updateProperty(this.tickets[0].ID, 'estado_interno', this.estados_internos_permitidos[0].id).subscribe(
	// 														() => {
	// 															// this.router.navigate(['tickets']);
	// 															console.log('Tickets estado cambiado',this.tickets[0].ID);
	// 															if(this.tickets[0]){
	// 																this.mostrarMensaje = false;
	// 																const dialogConfig = new MatDialogConfig();

	// 																dialogConfig.disableClose = true;
	// 																dialogConfig.autoFocus = true;
	// 																dialogConfig.minWidth = "70%";
	// 																dialogConfig.maxWidth = "95%";
															
	// 																dialogConfig.data = {
	// 																	ticket: this.tickets[0],
	// 																};
																
	// 																let dialogRef = this.dialog.open(FormularioCamposProyectoDialogComponent, dialogConfig);
																
	// 																dialogRef.componentInstance.closeDialog.subscribe(() => {
	// 																	this.dialog.closeAll();
	// 																});
	// 															}
																
																
	// 														},
	// 														(err) => {
	// 															this.showSnack(err);
	// 														}
	// 													);

	// 												}else{
	// 													this.mostrarOpciones = true;
	// 													console.log('Mostar formulario con opciones');

														
	// 												}
													
	// 											}else{
	// 												this.mostrarMensaje = true;
	// 										 		this.mensajeUsuario = "No tiene permitido cambiar al siguiente estado";
	// 												 console.log('NO Cambios de estado y tecnico');
	// 											}
											
	// 								//	}, 200);
									
	// 									},
	// 									(err) => {
	// 										this.showSnack(err);
	// 									}
	// 								)
								
	// 							},
	// 							(err) => {
	// 								this.showSnack(err);
	// 							}
	// 						)
	

	// 					}
	// 					else{

	// 						if(this.tickets[0].assign == this.usuario.id){
	// 							console.log('Abre el formulario inicial');
	// 							if(this.tickets[0]){
	// 								this.mostrarCamposProyecto(this.tickets[0]);
	// 							}
								
	// 						}else{
								

	// 							setTimeout(() => {
		
	// 								//this.showSnack('El ticket ya tiene un técnico asignado');
	// 							this.mostrarMensaje = true;
	// 							this.mensajeUsuario = "El ticket ya tiene un técnico asignado";
								
	// 							}, 100);
	// 						}
							
	// 					}
						
	
	// 				}else{
						
	// 					setTimeout(() => {
		
	// 					//this.showSnack('No se han encontrado ninugn ticket con número de serie');
	// 					this.mostrarMensaje = true;
	// 					this.mensajeUsuario = "No existe ticket con el número de serie introducido";

						
	// 					}, 100);
	// 				}
					
	// 			},
	// 			(err) => {
	// 				this.showSnack(err);
	// 			}
	// 		)

	// }

	selectEstado(event: any): void {

		console.log('Elemento seleccionado',event);
		let estadoSeleccionado = this.formEstadoInterno.get('estado_interno_permitido')?.value;
		console.log('Estado Interno',estadoSeleccionado);
		console.log('Ticket a modificar',this.tickets[0].ID);
		console.log('Usuario a modificar',this.usuario.id);
		this.mostrarOpciones = false;
		if(this.tickets[0] && this.usuario.id){
			// //Se asigna el ticket al usuario
			this.ticketsSvc.updateProperty(this.tickets[0].ID, 'assign', this.usuario.id).subscribe(
				() => {
					console.log('Tickets autoasignado',this.tickets[0].ID);

					this.ticketsSvc.updateProperty(this.tickets[0].ID, 'status', 'assign').subscribe(
						() => {
							console.log('Tickets en curso',this.tickets[0].ID);

						
						},
						(err) => {
							this.showSnack(err);
						}
					);
				},
				(err) => {
					this.showSnack(err);
				}
			);

			//Se cambia al primer estado permitido
			this.ticketsSvc.updateProperty(this.tickets[0].ID, 'estado_interno', estadoSeleccionado).subscribe(
				() => {
					console.log('Tickets estado cambiado',this.tickets[0].ID);
					if(this.tickets[0]){
						this.mostrarMensaje = false;
						const dialogConfig = new MatDialogConfig();

						dialogConfig.disableClose = true;
						dialogConfig.autoFocus = true;
						dialogConfig.minWidth = "70%";
						dialogConfig.maxWidth = "95%";
					//	dialogConfig.maxHeight = "90vh";
				
						dialogConfig.data = {
							ticket: this.tickets[0],
						};
					
						let dialogRef = this.dialog.open(FormularioCamposProyectoDialogComponent, dialogConfig);
					
						dialogRef.componentInstance.closeDialog.subscribe(() => {
							this.dialog.closeAll();
						});
						// dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
						// 	console.log('Resultado 3',result);
						// 	if (result && result !== undefined && result.res == true) {
						// 		this.comprobarTicketAsignado();
						// 	}
						// });
					}
					
					
				},
				(err) => {
					this.showSnack(err);
				}
			);
		}


	}

	async actualizarTicketFormulario(){

		console.log('Array estados permitidos usuario',this.estados_internos_permitidos);

		// //Se asigna el ticket al usuario
		// this.ticketsSvc.updateProperty(this.tickets[0].ID, 'assign', this.usuario.id).subscribe(
		// 	() => {
		// 		console.log('Tickets autoasignado',this.tickets[0].ID);

		// 		this.ticketsSvc.updateProperty(this.tickets[0].ID, 'status', 'assign').subscribe(
		// 			() => {
		// 				console.log('Tickets en curso',this.tickets[0].ID);

					
		// 			},
		// 			(err) => {
		// 				this.showSnack(err);
		// 			}
		// 		);
		// 	},
		// 	(err) => {
		// 		this.showSnack(err);
		// 	}
		// );

		// //Se cambia al primer estado permitido
		// this.ticketsSvc.updateProperty(this.tickets[0].ID, 'estado_interno', this.estados_internos[index].id).subscribe(
		// 	() => {
		// 		console.log('Tickets estado cambiado',this.tickets[0].ID);
		// 		if(this.tickets[0]){
		// 			this.mostrarMensaje = false;
		// 			const dialogConfig = new MatDialogConfig();

		// 			dialogConfig.disableClose = true;
		// 			dialogConfig.autoFocus = true;
		// 			dialogConfig.minWidth = "70%";
		// 			dialogConfig.maxWidth = "95%";
			
		// 			dialogConfig.data = {
		// 				ticket: this.tickets[0],
		// 			};
				
		// 			let dialogRef = this.dialog.open(FormularioCamposProyectoDialogComponent, dialogConfig);
				
		// 			dialogRef.componentInstance.closeDialog.subscribe(() => {
		// 				this.dialog.closeAll();
		// 			});
		// 		}
				
				
		// 	},
		// 	(err) => {
		// 		this.showSnack(err);
		// 	}
		// );
	}
	// searchv3() {

	// 	this.mostrarMensaje = true;
	// 	this.mensajeUsuario = "";
	// 	console.log('Número de serie',this.textSearch);

	// 	//if(this.textSearch != ""){
	// 		let filtros = {};
	
	// 		let cambioEstado = false;
	
	// 		filtros['numeroserie'] = this.textSearch;
	// 		filtros['estado'] = 7; //No cerrado
	// 		filtros['tecnico'] = 0; //No asignado
		
	
	// 		console.log('Filtros',filtros);
	// 		this.ticketsSvc.getTickets(this.numPage, this.pageSize, this.fecha, this.currentOrder, this.currentDirection, filtros).pipe(takeUntil(this._unsubscribeAll)).subscribe(
	// 			(data: any) => {
	// 				this.tickets = data.elements;
	// 				console.log('Tickets ',this.tickets);				
	// 				console.log('Usuario',this.usuario);
	// 				if(this.tickets.length>0){
	// 					if(this.tickets[0].assign == 0){
	// 						console.log('Comprueba permisos del numero de serie introducido');
	// 						//Obtenemos los estados siguientes del estado del ticket.
	// 						if(!this.tickets[0].estado_interno){
	// 							this.tickets[0].estado_interno = 0;
	// 						}
	
	// 						this.ticketsSvc.getEstadosInternos(this.tickets[0].ID, this.tickets[0].FK_entities, this.tickets[0].estado_interno).subscribe(
	// 							(data: any) => {
	// 								this.estados_internos = data;
	
	// 								console.log('Estados Internos Siguientes',this.estados_internos);
	
	// 								//Obtenemos los estados que tiene permiso el usuario
	
	// 								this.ticketsSvc.getPermisosEstadosInternoUsuario(this.tickets[0].ID, this.tickets[0].FK_entities, this.tickets[0].estado_interno).subscribe(
	// 									(data: any) => {
	// 										this.estados_permitidos = data;


	
	// 										console.log('Estados Permitidos',this.estados_permitidos);

											
	// 										//FORECAH
	// 										this.modificado = false;
	// 										this.estados_internos.forEach(async index =>{
	// 											if(index === this.estados_internos.length - 1){
	// 												console.log('Estado siguiente', this.estados_internos[index].id);
	// 												if(this.estados_internos[index].id != this.tickets[0].estado_interno){
			
	// 													if(this.estados_permitidos.indexOf(this.estados_internos[index].id)> -1){
	// 														console.log('El usuario tiene permitido el siguiente estado', this.estados_internos[index].id);
	// 														this.modificado = true;
	// 														this.estados_internos_permitidos.push(this.estados_internos[index]);
	// 														//Se asigna el ticket al usuario
	// 														this.ticketsSvc.updateProperty(this.tickets[0].ID, 'assign', this.usuario.id).subscribe(
	// 															() => {
	// 																console.log('Tickets autoasignado',this.tickets[0].ID);
										
	// 																this.ticketsSvc.updateProperty(this.tickets[0].ID, 'status', 'assign').subscribe(
	// 																	() => {
	// 																		console.log('Tickets en curso',this.tickets[0].ID);
												
																		
	// 																	},
	// 																	(err) => {
	// 																		this.showSnack(err);
	// 																	}
	// 																);
	// 															},
	// 															(err) => {
	// 																this.showSnack(err);
	// 															}
	// 														);
		
	// 														//Se cambia al primer estado permitido
	// 														this.ticketsSvc.updateProperty(this.tickets[0].ID, 'estado_interno', this.estados_internos[index].id).subscribe(
	// 															() => {
	// 																console.log('Tickets estado cambiado',this.tickets[0].ID);
	// 																if(this.tickets[0]){
	// 																	this.mostrarMensaje = false;
	// 																	const dialogConfig = new MatDialogConfig();
				
	// 																	dialogConfig.disableClose = true;
	// 																	dialogConfig.autoFocus = true;
	// 																	dialogConfig.minWidth = "70%";
	// 																	dialogConfig.maxWidth = "95%";
																
	// 																	dialogConfig.data = {
	// 																		ticket: this.tickets[0],
	// 																	};
																	
	// 																	let dialogRef = this.dialog.open(FormularioCamposProyectoDialogComponent, dialogConfig);
																	
	// 																	dialogRef.componentInstance.closeDialog.subscribe(() => {
	// 																		this.dialog.closeAll();
	// 																	});
	// 																}
																	
																	
	// 															},
	// 															(err) => {
	// 																this.showSnack(err);
	// 															}
	// 														);
		
				
	// 													 } else{
	// 														console.log('El usuario NO tiene permitido el siguiente estado', this.estados_internos[index].id);
	// 													 }
	// 												}else{
	// 													console.log('El estado interno es el mismo que tiene el ticket asignado');
	// 												}
	// 											}
	// 										});
	// 										setTimeout(() => {
	// 										if(!this.modificado){
	// 											//this.showSnack('No tiene permitido cambiar al siguiente estado');
	// 											this.mostrarMensaje = true;
	// 											this.mensajeUsuario = "No tiene permitido cambiar al siguiente estado";
												
	// 										}else{
	// 											console.log('Ticket modificado');
	// 										}
	// 										if(this.estados_internos_permitidos.length > 1){
	// 											this.mostrarOpciones = true;
	// 											console.log('Estados siguientes permitido Usuario',this.estados_internos_permitidos);
	// 										}
	// 									}, 200);
									
	// 									},
	// 									(err) => {
	// 										this.showSnack(err);
	// 									}
	// 								)
								
	// 							},
	// 							(err) => {
	// 								this.showSnack(err);
	// 							}
	// 						)
	

	// 					}
	// 					else{

	// 						if(this.tickets[0].assign == this.usuario.id){
	// 							console.log('Abre el formulario inicial');
	// 							if(this.tickets[0]){
	// 								this.mostrarCamposProyecto(this.tickets[0]);
	// 							}
								
	// 						}else{
								

	// 							setTimeout(() => {
		
	// 								//this.showSnack('El ticket ya tiene un técnico asignado');
	// 							this.mostrarMensaje = true;
	// 							this.mensajeUsuario = "El ticket ya tiene un técnico asignado";
								
	// 							}, 100);
	// 						}
							
	// 					}
						
	
	// 				}else{
						
	// 					setTimeout(() => {
		
	// 					//this.showSnack('No se han encontrado ninugn ticket con número de serie');
	// 					this.mostrarMensaje = true;
	// 					this.mensajeUsuario = "No existe ticket con el número de serie introducido";

						
	// 					}, 100);
	// 				}
					
	// 			},
	// 			(err) => {
	// 				this.showSnack(err);
	// 			}
	// 		)

	// }

	// search() {

	// 	this.mostrarMensaje = true;
	// 	this.mensajeUsuario = "";
	// 	console.log('Número de serie',this.textSearch);

	// 	//if(this.textSearch != ""){
	// 		let filtros = {};
	
	// 		let cambioEstado = false;
	
	// 		filtros['numeroserie'] = this.textSearch;
	// 		filtros['estado'] = 7; //No cerrado
	// 		filtros['tecnico'] = 0; //No asignado
		
	
	// 		console.log('Filtros',filtros);
	// 		this.ticketsSvc.getTickets(this.numPage, this.pageSize, this.fecha, this.currentOrder, this.currentDirection, filtros).pipe(takeUntil(this._unsubscribeAll)).subscribe(
	// 			(data: any) => {
	// 				this.tickets = data.elements;
	// 				console.log('Tickets ',this.tickets);				
	// 				console.log('Usuario',this.usuario);
	// 				if(this.tickets.length>0){
	// 					if(this.tickets[0].assign == 0){
	// 						console.log('Comprueba permisos del numero de serie introducido');
	// 						//Obtenemos los estados siguientes del estado del ticket.
	// 						if(!this.tickets[0].estado_interno){
	// 							this.tickets[0].estado_interno = 0;
	// 						}
	
	// 						this.ticketsSvc.getEstadosInternos(this.tickets[0].ID, this.tickets[0].FK_entities, this.tickets[0].estado_interno).subscribe(
	// 							(data: any) => {
	// 								this.estados_internos = data;
	
	// 								console.log('Estados Internos Siguientes',this.estados_internos);
	
	// 								//Obtenemos los estados que tiene permiso el usuario
	
	// 								this.ticketsSvc.getPermisosEstadosInternoUsuario(this.tickets[0].ID, this.tickets[0].FK_entities, this.tickets[0].estado_interno).subscribe(
	// 									(data: any) => {
	// 										this.estados_permitidos = data;
	
	// 										console.log('Estados Permitidos',this.estados_permitidos);
	
	// 										for (var i=0; i<this.estados_internos.length;i++){
	// 											console.log('Siguientes estados',this.estados_internos[i].id);
	// 											if(this.estados_internos[i].id != this.tickets[0].estado_interno){
			
	// 												if(this.estados_permitidos.indexOf(this.estados_internos[i].id)> -1){
	// 													console.log('El usuario tiene permitido el siguiente estado', this.estados_internos[i].id);
	// 													this.modificado = true;
	// 													//Se asigna el ticket al usuario
	// 													this.ticketsSvc.updateProperty(this.tickets[0].ID, 'assign', this.usuario.id).subscribe(
	// 														() => {
	// 															console.log('Tickets autoasignado',this.tickets[0].ID);
									
	// 															this.ticketsSvc.updateProperty(this.tickets[0].ID, 'status', 'assign').subscribe(
	// 																() => {
	// 																	console.log('Tickets en curso',this.tickets[0].ID);
											
	// 																//	this.ticketsSvc.onNumSerieSearch.next(this.tickets[0].ID);
	// 																	//this.loadTicketsPropios();
											
	// 																	//this.router.navigate(['tickets']);
	// 																},
	// 																(err) => {
	// 																	this.showSnack(err);
	// 																}
	// 															);
	// 														},
	// 														(err) => {
	// 															this.showSnack(err);
	// 														}
	// 													);
	
	// 													//Se cambia al primer estado permitido
	// 													this.ticketsSvc.updateProperty(this.tickets[0].ID, 'estado_interno', this.estados_internos[i].id).subscribe(
	// 														() => {
	// 															console.log('Tickets estado cambiado',this.tickets[0].ID);
	// 															if(this.tickets[0]){
	// 																this.mostrarMensaje = false;
	// 																const dialogConfig = new MatDialogConfig();
			
	// 																dialogConfig.disableClose = true;
	// 																dialogConfig.autoFocus = true;
	// 																dialogConfig.minWidth = "70%";
	// 																dialogConfig.maxWidth = "95%";
															
	// 																dialogConfig.data = {
	// 																	ticket: this.tickets[0],
	// 																};
																
	// 																let dialogRef = this.dialog.open(FormularioCamposProyectoDialogComponent, dialogConfig);
																
	// 																dialogRef.componentInstance.closeDialog.subscribe(() => {
	// 																	this.dialog.closeAll();
	// 																});
	// 															}
																
																
	// 														},
	// 														(err) => {
	// 															this.showSnack(err);
	// 														}
	// 													);
	
			
	// 												 } else{
														
	// 												// 	setTimeout(() => {
		
	// 												// 		//console.log('El usuario NO tiene permitido el siguiente estado', this.estados_internos[i].id);
	// 												// 		this.mostrarMensaje = true;
	// 												 		this.mensajeUsuario = "No tiene permitido cambiar al siguiente estado";

									
															
	// 												// 		}, 200);
	// 												 }
	// 											}
	// 										}
	
	// 										setTimeout(() => {
		
	// 											if(!this.modificado){
	// 												//this.showSnack('No tiene permitido cambiar al siguiente estado');
	// 											   this.mostrarMensaje = true;
	// 											   this.mensajeUsuario = "No tiene permitido cambiar al siguiente estado";
													
	// 										   }
											
	// 										}, 200);

											
	
											
	// 									},
	// 									(err) => {
	// 										this.showSnack(err);
	// 									}
	// 								)
								
	// 							},
	// 							(err) => {
	// 								this.showSnack(err);
	// 							}
	// 						)
	
	
							
	// 						// this.ticketsSvc.updateProperty(this.tickets[0].ID, 'assign', this.usuario.id).subscribe(
	// 						// 	() => {
	// 						// 		console.log('Tickets autoasignado',this.tickets[0].ID);
		
	// 						// 		this.ticketsSvc.onNumSerieSearch.next(this.tickets[0].ID);
	// 						// 		//this.loadTicketsPropios();
		
	// 						// 		//this.router.navigate(['tickets']);
	// 						// 	},
	// 						// 	(err) => {
	// 						// 		this.showSnack(err);
	// 						// 	}
	// 						// );
	// 					}
	// 					else{

	// 						if(this.tickets[0].assign == this.usuario.id){
	// 							console.log('Abre el formulario inicial');
	// 							if(this.tickets[0]){
	// 								this.mostrarCamposProyecto(this.tickets[0]);
	// 							}
								
	// 						}else{
								

	// 							setTimeout(() => {
		
	// 								//this.showSnack('El ticket ya tiene un técnico asignado');
	// 							this.mostrarMensaje = true;
	// 							this.mensajeUsuario = "El ticket ya tiene un técnico asignado";
								
	// 							}, 100);
	// 						}
							
	// 					}
						
	
	// 				}else{
						
	// 					setTimeout(() => {
		
	// 					//this.showSnack('No se han encontrado ninugn ticket con número de serie');
	// 					this.mostrarMensaje = true;
	// 					this.mensajeUsuario = "No existe ticket con el número de serie introducido";

						
	// 					}, 100);
	// 				}
					
	// 			},
	// 			(err) => {
	// 				this.showSnack(err);
	// 			}
	// 		)

	// }


	// async searchV2() {

	// 	console.log('Número de serie',this.textSearch);

    // 	let filtros = {};
	
	// 	let cambioEstado = false;

	// 	filtros['numeroserie'] = this.textSearch;
    // 	filtros['estado'] = 7; //No cerrado
	// 	filtros['tecnico'] = 0; //No asignado
	

	// 	console.log('Filtros',filtros);
	// 	this.ticketsSvc.getTickets(this.numPage, this.pageSize, this.fecha, this.currentOrder, this.currentDirection, filtros).pipe(takeUntil(this._unsubscribeAll)).subscribe(
	// 		async (data: any) => {
	// 			this.tickets = data.elements;
	// 			console.log('Existe ticket sin asignar con número de serie introducido ',this.tickets);				
	// 			console.log('Usuario',this.usuario);
	// 			if(this.tickets.length>0){
	// 				if(this.tickets[0].assign == 0){
						
	// 					await this.obtenerEstadosInternos();
	// 					//Obtenemos los estados siguientes del estado del ticket.

	// 					await this.obtenerEstadosPermitidos();

	// 					await this.empezarCaso();

	// 					//Se llama al formulario

	// 					if(this.modificado){
	// 						console.log('Se modifica el ticket y por tanto se muestra formulario');
	// 						await this.mostrarCamposProyecto(this.tickets[0]); 
	// 					}else{
	// 						console.log('Se modifica el ticket y por tanto se muestra formulario');
	// 						this.showSnack('El usuario no tiene permiso para el estado siguiente');
	// 					}
						
	// 				}else{
	// 					this.showSnack('El ticket ya tiene un técnico asignado');
	// 				}
					

	// 			}else{
	// 				this.showSnack('No se han encontrado ninugn ticket con número de serie');
	// 			}
				
	// 		},
	// 		(err) => {
	// 			this.showSnack(err);
	// 		}
	// 	)


    	

		
	// }

	empezarCaso (){
		return new Promise((resolve, reject) => {
			console.log('LLega a empezar caso', this.estados_internos);
			
			for (var i=0; i<this.estados_internos.length;i++){
				console.log('Siguientes estados',this.estados_internos[i].id);
				if(this.estados_internos[i].id != this.tickets[0].estado_interno){

					if(this.estados_permitidos.indexOf(this.estados_internos[i].id)> -1){
						
						console.log('El usuario tiene permitido el siguiente esstado', this.estados_internos[i].id);

						//Se asigna el ticket al usuario

						this.modificarTicket(this.tickets[0].ID, 'assign', this.usuario.id);

						//Se modifica el estado
						this.modificarTicket(this.tickets[0].ID, 'estado_interno', this.estados_internos[i].id);
						this.modificado = true;

					}else{
						console.log('El usuario NO tiene permitido el siguiente esstado', this.estados_internos[i].id);
					}
				}
			}


		});
	}

	modificarTicket(idTicket, campo, valor ){
		//Se cambia al primer estado permitido
		//this.ticketsSvc.updateProperty(this.tickets[0].ID, 'estado_interno', this.estados_internos[i].id).subscribe(
		//	return new Promise((resolve, reject) => {
				this.ticketsSvc.updateProperty(idTicket, campo, valor).subscribe(
					() => {
						console.log('Tickets estado cambiado',this.tickets[0].ID);
		
						//this.ticketsSvc.onNumSerieSearch.next(this.tickets[0].ID);

					},
					(err) => {
						this.showSnack(err);
					}
				);
		//	});	


	}

	obtenerEstadosInternos(){


		return new Promise((resolve, reject) => {
			this.ticketsSvc.getEstadosInternos(this.tickets[0].ID,this.tickets[0].FK_entities, this.tickets[0].estado_interno).subscribe(
				(data: any) => {
					this.estados_internos = data;
	
					console.log('Estados Internos Siguientes',this.estados_internos);
					resolve(this.estados_internos);
	
				},
				(err) => {
					this.showSnack(err);
				}
			)
		});

	}

	obtenerEstadosPermitidos(){
		return new Promise((resolve, reject) => {
			if(!this.tickets[0].estado_interno){
				this.tickets[0].estado_interno = 0;
			}
			this.ticketsSvc.getPermisosEstadosInternoUsuario(this.tickets[0].ID, this.tickets[0].FK_entities, this.tickets[0].estado_interno).subscribe(
				(data: any) => {
					this.estados_permitidos = data;

					console.log('Estados Permitidos',this.estados_permitidos);
					resolve(this.estados_permitidos);
				},
				(err) => {
					this.showSnack(err);
				}
			)
		});
	}

	mostrarCamposProyecto(ticket){

		if(ticket){
			const dialogConfig = new MatDialogConfig();
	
			dialogConfig.disableClose = true;
			dialogConfig.autoFocus = true;
			dialogConfig.minWidth = "70%";
			dialogConfig.maxWidth = "95%";
		//	dialogConfig.maxHeight = "90vh";
	
			dialogConfig.data = {
				ticket: ticket,
			};
		
			let dialogRef = this.dialog.open(FormularioCamposProyectoDialogComponent, dialogConfig);
		
			dialogRef.componentInstance.closeDialog.subscribe(() => {
				this.dialog.closeAll();
			});
			dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
				console.log('Resultado',result);
				if (result && result !== undefined && result.res == true) {
					this.comprobarTicketAsignado();
				}
			});
		}


    }

  	showSnack(mensaje: string) {
		// this.snackBar.open(mensaje, null, {
		// 	duration: 2000
		// });

		this.snackBar.open(mensaje, '', {
			duration: 2000,
			//verticalPosition: 'top',
			panelClass: ['blue-snackbar']
		 });


	}

	loadUser() {
		this.userSvc.getUsuario().pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data:any) => {
				this.usuario = data;
				//this.comprobarTicketAsignado();
			}
		)
	}

	comprobarTicketAsignado(){

		let filtros = {};
	
		let cambioEstado = false;

		filtros['numeroserie'] = this.textSearch;
    	filtros['estado'] = 7; //No cerrado
		filtros['tecnico'] = this.usuario.id;
		//filtros['estadosInterno'] = 
		console.log('Tecnico',this.usuario.id);
		this.ticketsSvc.getTickets(this.numPage, this.pageSize, this.fecha, this.currentOrder, this.currentDirection, filtros).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				this.ticketsAsignado = data.elements;

				for(var i = 0; i < this.ticketsAsignado.length; i++){

					if(this.ticketsAsignado[i].estado_interno > 0){

						//if(this.ticketsAsignado.length>0 && this.ticketsAsignado[0].estado_interno != null){
							this.mostrarCamposProyecto(this.ticketsAsignado[i]);
							break;
						//}
					}
				}

				
				
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}




}
