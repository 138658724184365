<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 *ngIf="!direccion" class="m-0 mb-8">Nueva dirección</h2>
    <h2 *ngIf="direccion" class="m-0 mb-8">Editar dirección</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography" style="width: 60vw">
    <div fxLayout="column">
        <form #mainForm="ngForm" [formGroup]="formDireccion" class="w-100-p">
            <div fxLayout="row wrap" class="">
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Tipo dirección<span class="required">*</span></mat-label>
                    <mat-select name="tipo_direccion" formControlName="tipo_direccion" class="form-control">
                        <mat-option *ngFor="let item of tiposDireccionData | async" [value]="item.ID">{{item.tipo_direccion}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('tipo_direccion')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'tipo_direccion')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div fxLayout="row wrap" class="mb-12">
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Nombre<span class="required">*</span></mat-label>
                    <input matInput name="nombre" formControlName="nombre" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Apellido</mat-label>
                    <input matInput name="apellido" formControlName="apellido" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="form-group mr-16 field-width-small">
                    <mat-label>Teléfono</mat-label>
                    <input matInput name="num_telefono" formControlName="num_telefono" class="form-control" autocomplete="off">
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group mr-16 field-width-big">
                    <mat-label>E-mail</mat-label>
                    <input matInput name="direccion_email" formControlName="direccion_email" class="form-control" autocomplete="off">
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>País</mat-label>
                    <mat-select name="id_pais" formControlName="id_pais" class="form-control">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="paisFilterCtrl"
                            placeholderLabel="País" noEntriesFoundLabel="No se encontró ningún valor"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of paisesFiltered | async" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('id_pais')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'id_pais')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Provincia</mat-label>
                    <mat-select name="id_provincia" formControlName="id_provincia" class="form-control">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="provinciaFilterCtrl"
                            placeholderLabel="Provincia" noEntriesFoundLabel="No se encontró ningún valor"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of provinciasFiltered | async" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('id_provincia')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'id_provincia')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group mr-16 field-width-full">
                    <mat-label>CP - Localidad</mat-label>
                    <mat-select name="id_localidad" formControlName="id_localidad" class="form-control">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="localidadFilterCtrl" [searching]="searchingLocalidades"
                            placeholderLabel="Localidad" noEntriesFoundLabel="No se encontro ningún valor"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of localidades" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('id_localidad')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'id_localidad')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group mr-16 field-width-big">
                    <mat-label>Dirección</mat-label>
                    <input matInput name="direccion" formControlName="direccion" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="form-group mr-16 field-width-small">
                    <mat-label>Número</mat-label>
                    <input matInput name="num_casa" formControlName="num_casa" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="form-group mr-16 field-width-full">
                    <mat-label>Otros datos</mat-label>
                    <input matInput name="otros_datos" formControlName="otros_datos" class="form-control" autocomplete="off">
                </mat-form-field>
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-button class="btn-aitana" [class.spinner]="loadingSave" [disabled]="loadingSave || !formDireccion.valid" (click)="guardarDireccion()">Guardar</button>
</mat-dialog-actions>