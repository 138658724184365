export const locale = {
    lang: 'en',
    data: {
        'AUTODIAGNOSIS_BOT': {
            'PROCESO_SELECCION_SINTOMAS': 'SYMPTOMS SELECTION PROCESS',
            'INICIAR_DIAGNOSIS': 'START DIAGNOSIS',
            'INDICA_NUM_TICKET': 'Indicate the ticket number on which you want to carry out the diagnosis',
            'QUE_TIPO_ELEMENTO': 'What type of device do you want to diagnose?',
            'SIN_SINTOMAS': 'NO SYMPTOMS',
            'DIAGNOSTICOS_ENCONTRADOS': 'There are no other possible symptoms, these are the diagnoses found.',
            'SELECCIONA_DIAGNOSTICO': 'Select the one(s) that you think best fit your problem',
            'NINGUN_SINTOMA_MARCADO': 'From the list of possible symptoms none have been marked',
            'REINICIA_AUTODIAGNOSIS': 'Restart self-diagnosis to see possible symptoms again',
            'DIAGNOSTICOS_NO_ENCONTRADOS': 'No diagnosis could be found for the selected symptoms',
            'REINICIAR_DIAGNOSIS': 'RESET DIAGNOSIS',
            'TICKET_NO_ENCONTRADO': 'The indicated ticket was not found',
            'TIPO_ELEMENTO_NO_INDICADO': 'The type of element is not indicated',
            'DESCRIPCION_PROBLEMA': 'Do you want to add a brief description of the problem?',
            'DIAGNOSIS_FINALIZADA': 'Diagnosis completed successfully',
            'DIAGNOSIS_REGISTRADAS': 'Diagnoses have been recorded for the selected symptoms and diagnoses',
            'DIAGNOSTICAR_NUEVO_DISPOSITIVO': 'Do you want to diagnose a new device?',
            'NUEVA_DIAGNOSIS': 'NEW DIAGNOSIS',
            'SINTOMAS_REPARACIONES_NO_MARCADAS': 'Error: No selected symptoms and repairs found',
            'AUTODIAGNOSIS_BOT': 'Self Diagnosis Bot',
            'TICKET': 'Ticket',
            'CONTINUEMOS': 'Let\'s continue',
            'DESCRIPCION': 'Description',
            'CREAR_DIAGNOSIS': 'Create diagnosis',
            'PORCENTAJE_REPARACIONES': '{{porcentaje}}% of repairs',
            'REPARACION_COMUN': 'Repair common to the {{num_sintomas}} selected symptoms',
            'REPARACION_SINTOMAS': 'Repair for symptoms:',
        }
    }
};
