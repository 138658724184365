<div *ngIf="loading" [style.height]="height" [style.width]="width" class="body-component" [class.spinner]="true">
    <div class="position-relative p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="start start">
            <span class="h2 secondary-text">{{titulo}}</span>
        </div>
        <div *ngIf="showBtnAcumulado" fxLayout="row" fxLayoutAlign="start center" style="border: solid 1px black">
            <div class="py-8 px-12 border-radius-2 line-height-1 cursor-pointer" (click)="showAcumulado()" [ngClass]="{'accent': mostrarAcumulado}">
                ACUMULADO
            </div>
        </div>
    </div>

    <canvas></canvas>
</div>

<div *ngIf="!loading" [style.height]="height" [style.width]="width" class="body-component">

    <div class="position-relative p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="start start">
            <span class="h2 secondary-text">{{titulo}}</span>
        </div>
        <div *ngIf="showBtnAcumulado" fxLayout="row" fxLayoutAlign="start center" style="border: solid 1px black">
            <div class="py-8 px-12 border-radius-2 line-height-1 cursor-pointer" (click)="showAcumulado()" [ngClass]="{'accent': mostrarAcumulado}">
                ACUMULADO
            </div>
        </div>
    </div>

    <canvas baseChart *ngIf="optionsGrafica && !desplegado"
            [datasets]="optionsGrafica.datasets"
            [labels]="optionsGrafica.labels"
            [colors]="optionsGrafica.colors"
            [options]="optionsGrafica.options"
            [legend]="showLegend"
            [chartType]="optionsGrafica.chartType">
    </canvas>

    <div class="wrap-table" fxLayout="column" fxFlex="1 0 auto" *ngIf="desplegado" [style.width]="width" [style.height]="height">
        <button mat-button (click)="volver()">
            <mat-icon class="s-16" fxHide.xs>keyboard_return</mat-icon> Volver
        </button>

        <div class="table-container">
            <table mat-table [dataSource]="datos" class="w-100-p">
                <ng-container *ngFor="let col of columnas" [matColumnDef]="col.id">
                    <th mat-header-cell *matHeaderCellDef> {{col.campo}} </th>
                    <td mat-cell *matCellDef="let row">
                        {{col.tipo == 'Date'?formatDate(row[col.id]):(col.tipo == 'Datetime'?formatDate(row[col.id], true):row[col.id])}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No se encontraron resultados</td>
                </tr>
            </table>
        </div>

        <mat-paginator [pageSizeOptions]="[10,20,30,50,100,300]" [length]="totalElementos" [pageIndex]="pagina" [pageSize]="pageSize" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
    </div>
</div>