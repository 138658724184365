import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/services/auth/auth.service';
import { NotificacionesService } from 'app/services/notificaciones/notificaciones.service';
import { SocketService } from 'app/services/socket/socket.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent implements OnInit, OnDestroy
{
    private _unsubscribeAll: Subject<any>;
    
    date: Date;
    notificaciones: Array<any>;
    events: any[];
    notes: any[];
    settings: any;

    /**
     * Constructor
     */
    constructor(
        private translateService: TranslateService,
        private notificacionesSvc: NotificacionesService,
        private sockectSvc: SocketService,
        private authSvc: AuthService,
    ) {
        moment.locale(this.translateService.currentLang);
        this._unsubscribeAll = new Subject();

        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud : false,
            retro : true
        };

        this.notificaciones = [];
    }

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loadNoLeidas();
        this.sockectSvc.onNuevaNotificacion.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (data: any) => {
                this.loadNoLeidas();
            }
        );
        this.notificacionesSvc.onNoLeidasChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (data: any) => {
                this.loadNoLeidas();
            }
        );
    }
 
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    loadNoLeidas() {
        if (this.authSvc.isUserLoggedIn()) {
            this.notificacionesSvc.getNoLeidas().pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (data: any) => {
                    this.notificaciones = data;
                }
            );
        }
    }

    marcarNotificacionLeida(notificacion: any) {
        this.notificacionesSvc.marcarComoLeida(notificacion.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (data: any) => {
                this.loadNoLeidas();
            }
        );
    }

    formatDate(fecha, format) {
        if (fecha) {
            return moment(fecha).format(format);
        }
        else {
            return "";
        }
    }
}
