<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">Adjuntos</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column" fxFlex="1 0 auto">

        <div *ngIf="adjuntos?.length == 0" fxLayuot="row" fxLayoutAlign="center center" class="w-100-p">
            <span class="m-0 secondary-text font-size-22">El ticket no tiene ficheros adjuntos</span>
        </div>

        <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
            <h4 class="m-0 mb-8">Subir Adjuntos</h4>
        </div>
       
       
            <div fxLayout="column">

                <mat-form-field appearance="standard" class="form-group w-300">
                    <mat-label>Tipo de Adjunto</mat-label>
                    <mat-select name="tipoElemento" class="form-control" [(ngModel)]="tipoAdjuntoSel" placeholder="Selecciona un tipo de Adjunto" (selectionChange)="seleccionaTipoAdjunto()">
                        <mat-option *ngFor="let item of tiposAdjuntos" [value]="item.ID">{{item.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div  *ngIf="mostrarAdjuntarFichero" fxLayout="column" class="mb-16 mt-16">
                    <div class="dropzone mb-8" appDragAndDrop fxLayout="row" fxLayoutAlign="center center" (fileDropped)="onFileDropped($event)">
                        <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                        <mat-icon matSuffix class="secondary-text">cloud_upload</mat-icon>
                        <h3>Suelta los archivos para adjuntarlos o <label for="fileDropRef">explorar</label></h3>
                    </div>
        
                    <div class="files-list">
                        <div class="single-file" *ngFor="let file of files; let i = index" [class.error-file]="file.error">
                            <mat-icon [inline]="true" class="secondary-text" style="font-size: 40px;">insert_drive_file</mat-icon>
                            <div class="info">
                                <h4 class="name">{{ file?.name }}</h4>
                                <p *ngIf="file.size" class="size">{{ formatBytes(file?.size) }}</p>
                                <app-progress *ngIf="file.progress" [progress]="file?.progress"></app-progress>
                            </div>
                            <button *ngIf="!file.error" [disabled]="(file.docId === 0 && file.progress < 100 && file.progress > 0)" mat-icon-button (click)="openConfirmDeleteFile(i)" matTooltip="Eliminar documento">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button *ngIf="file.error" mat-icon-button (click)="deleteFile(i)" matTooltip="Eliminar documento">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
        
            </div>
       
 

        <div *ngIf="adjuntos?.length > 0" class="wrap-table" fxLayout="column" fxFlex="1 0 auto">
            <div class="table-container" fxFlex="1 0 auto">
                <table mat-table [dataSource]="dataSource" class="w-100-p">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> ID </th>
                        <td mat-cell *matCellDef="let row">{{ row.ID }}</td>
                    </ng-container>

                    <ng-container matColumnDef="archivo">
                        <th mat-header-cell *matHeaderCellDef> Archivo </th>
                        <td mat-cell *matCellDef="let row">
                            <div fxLayout="row" fxLayoutAlign="flex-start center" class="w-100-p">
                                <div *ngIf="esImagen(row)" fxLayout="row" fxLayoutAlign="center center" class="thumb-image" [ngClass]="'imagen-' + row.ID">
                                    <img *ngIf="row.base64" [src]="row.base64" >
                                    <mat-icon [fxHide]="row.base64" class="secondary-text">image</mat-icon>
                                </div>
                                <mat-icon *ngIf="!esImagen(row)" [fontSet]="getIconFile(row).lib" [fontIcon]="getIconFile(row).icon" class="icon-file"></mat-icon>

                                <div fxLayout="column" fxLayoutAlign="center flex-start" class="w-100-p ml-16">
                                    <span class="font-size-14 secondary-text">{{ row.filename }}</span>
                                    <span class="font-size-14 secondary-text">{{ row.tipo ? row.tipo.nombre : ' - ' }}</span>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="datosSubida">
                        <th mat-header-cell *matHeaderCellDef> Datos de subida </th>
                        <td mat-cell *matCellDef="let row">
                            <div fxLayout="column">
                                <span class="font-size-14 secondary-text">{{ formatDate(row.date_mod) }}</span>
                                <span class="font-size-14 secondary-text">{{ row.autor ? row.autor.name : ' - ' }}</span>
                            </div>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="actions" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row">
                            <div fxLayout="row wrap" fxLayoutAlign="center center" [ngClass]="'btn-download-' + row.ID">
                                <button mat-icon-button matTooltip="Descargar adjunto" (click)="descargarAdjunto(row)">
                                    <mat-icon>cloud_download</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

            <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="totalElementos" [pageIndex]="0" [pageSize]="pageSize" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
        </div>
        
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>