import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Validators, FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { TranslateService } from '@ngx-translate/core';

import { ProyectosService } from 'app/services/proyectos/proyectos.service';

@Component({
  selector: 'app-dialog-configuracion-proyectos',
  templateUrl: './dialog-configuracion-proyectos.component.html',
  styleUrls: ['./dialog-configuracion-proyectos.component.scss']
})
export class DialogConfiguracionProyectosComponent implements OnInit {

	formConfiguracion: FormGroup;
	proyectosCtrl: AbstractControl;
	proyectosSelected: Array<any>;
	loadingSave: boolean;
	
	proyectosFilterCtrl: FormControl;
	searchingProyectos: boolean;
	proyectos: Array<any>;

	dataConfiguracion: any;
	idConfiguracion: number;

	numPage: number;
	pageSize: number;
	hasMore: boolean;

	@ViewChild('projectInput') projectInput: ElementRef<HTMLInputElement>;
	@ViewChild('autoProject') matAutocomplete: MatAutocomplete;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogConfiguracionProyectosComponent>,
		private dialog: MatDialog, 
		private formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private proyectosSvc: ProyectosService,
	) {
		this.formConfiguracion = this.formBuilder.group({
			nombre: ['', Validators.required],
			proyectos: ['', Validators.required],
			esPublica: [''],
		});
		this.proyectosCtrl = this.formConfiguracion.get('proyectos');
		this.proyectosFilterCtrl = new FormControl();
		this.searchingProyectos = false;
		this.proyectos = [];
		// this.proyectosSelected = [];

		this.loadingSave = false;

		this.numPage = 0;
		this.pageSize = 30;
		this.hasMore = false;

		this.proyectosCtrl.valueChanges.subscribe((valueSelect) => {
			// console.log("valueSelect", valueSelect);
			this.proyectosSelected = [];
			for (const idProject of valueSelect) {
				const proyecto = this.proyectos.find((item) => {
					return item.id === idProject;
				});
				this.proyectosSelected.push(proyecto);
			}
			// console.log("proyectosSelected", this.proyectosSelected);
		});
	}

	ngOnInit(): void {
		if (this.data.configProject) {
			this.dataConfiguracion = this.data.configProject;
			this.idConfiguracion = this.dataConfiguracion.ID;
			// console.log("dataConfiguracion", this.dataConfiguracion);

			const nombreCtrl = this.formConfiguracion.get('nombre');
			nombreCtrl.setValue(this.dataConfiguracion.nombre);

			const valueEsPublica = this.dataConfiguracion.esPublica == 1 ? true : false;
			const esPublicaCtrl = this.formConfiguracion.get('esPublica');
			esPublicaCtrl.setValue(valueEsPublica);

			const listaProyectos = this.dataConfiguracion.listaProyectos.split(",");
			this.loadProyectosSeleccionados(listaProyectos);
		}

		this.proyectosFilterCtrl.valueChanges.subscribe((filterValue) => {
			// console.log("filterValueMarca", filterValue);
			if (filterValue.length > 0) {
				this.searchingProyectos = true;
				this.loadProyectos(filterValue);
			}
			else {
				this.proyectos = [];
				if (this.proyectosSelected) {
					this.proyectos = this.proyectosSelected.slice();
				}
			}
		});
	}

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 1000
		});
	}

	deselectProject(dataProject: any): void {
		const index = this.proyectosSelected.findIndex((item) => {
			return item.id === dataProject.id;
		});

		if (index >= 0) {
			this.proyectosSelected.splice(index, 1);
		}
		let idProyectos = this.proyectosSelected.map((item) => {
			return item.id;
		});
		const proyectosCtrl = this.formConfiguracion.get('proyectos');
		proyectosCtrl.setValue(idProyectos);
	}

	clearProyectos(event) {
		event.preventDefault();
		event.stopPropagation();

		// console.log("clear proyectos");
		this.proyectosSelected = null;
		this.proyectos = [];
		const proyectosCtrl = this.formConfiguracion.get('proyectos');
		proyectosCtrl.setValue('');
	}

	async loadProyectosSeleccionados(listaIds: Array<any>) {
		let listaProyectos = [];
		let listaIdsNumber = [];
		const proyectosCtrl = this.formConfiguracion.get('proyectos');

		for (const idProyecto of listaIds) {
			let proyecto = await this.getDataProject(idProyecto);
			// console.log("proyecto", proyecto);
			if (proyecto) {
				listaProyectos.push(proyecto);
				listaIdsNumber.push(Number(idProyecto));
			}
		}

		this.proyectos = listaProyectos.slice();
		// console.log("proyectos", this.proyectos);
		proyectosCtrl.setValue(listaIdsNumber);
	}

	async getDataProject(idProyecto: number) {
		return new Promise(async resolve => {
            this.proyectosSvc.getProject(idProyecto).subscribe(
				(data:any) => {
					resolve(data);
				},
				(err) => {
					resolve(null);
				}
			)
        });
	}

	loadProyectos(filter?: string) {
		this.proyectosSvc.getProjects(this.numPage, this.pageSize, filter).subscribe(
			(data: any) => {
				this.searchingProyectos = false;
				if (this.proyectosSelected) {
					this.proyectos = this.proyectosSelected.slice();
					for (const itemProject of data.elements) {
						const itemAux = this.proyectosSelected.find((item:any) => {
							return item.id === itemProject.id;
						});

						if (!itemAux) {
							this.proyectos.push(itemProject);
						}
					}
				}
				else {
					this.proyectos = data.elements.slice();
				}
				// this.proyectos = this.proyectos.concat(data.elements);
				// this.hasMore = data.hasMore;
				// this.numPage++;
			},
			(err) => {
				this.searchingProyectos = false;
				this.showSnack(err);
			}
		)
	}

	guardarConfiguracion(): void {
		const formValue = this.formConfiguracion.value;
		// console.log("idconfiguracion", this.idConfiguracion);
		// console.log("formValue", formValue);

		const projectsUserSelected = this.proyectosSvc.getProjectsSeleted();

		this.loadingSave = true;
		let listaProyectos = formValue.proyectos.join(',');
		this.proyectosSvc.saveConfigProjects(formValue.nombre, listaProyectos, formValue.esPublica, this.idConfiguracion).subscribe(
			(data: any) => {
				this.loadingSave = false;

				if (this.idConfiguracion && projectsUserSelected.name === this.dataConfiguracion.nombre) { //Si hemos editado la configuracion que tenemos seleccionada
					this.proyectosSvc.selectConfigProject({ ID: this.idConfiguracion }).subscribe((data:any) => {});
				}

				this.dialogRef.close(data);
			},
			err => {
				this.loadingSave = false;
				this.showSnack(err);
			}
		)
	}

}
