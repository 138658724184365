<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 *ngIf="!seguimiento" class="m-0 mb-8">Nuevo seguimiento</h2>
    <h2 *ngIf="seguimiento" class="m-0 mb-8">Editar seguimiento</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column">
        <form #mainForm="ngForm" [formGroup]="formSeguimiento" class="w-100-p">
            <div fxLayout="row wrap" fxLayoutAlign="flex-start center" class="">
                <mat-form-field appearance="standard" class="form-group mr-40">
                    <mat-label>Tipo</mat-label>
                    <mat-select name="tipo" formControlName="tipo" class="form-control">
                        <mat-option *ngFor="let item of tiposSeguimientos" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <!-- <button *ngIf="checkValue('tipo')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'tipo')">
                        <mat-icon>close</mat-icon>
                    </button> -->
                </mat-form-field>

                <ng-container *ngIf="permisosSeguimientosPrivados && !permisosColaboradorColPartner && !permisoLaboratorio1">
                    <mat-slide-toggle formControlName="private">Privado</mat-slide-toggle>
                </ng-container>
            </div>

            <!-- Textos predefinidos segun contrato -->
            <div *ngIf="tipoSegCierreSelected && cierresPredefinidos.length > 0" fxLayout="row">
                <mat-form-field appearance="standard" class="form-group w-100-p mr-16">
                    <mat-label>Textos cierre</mat-label>
                    <mat-select name="cierres_predefinidos" formControlName="cierres_predefinidos" class="form-control">
                        <mat-option *ngFor="let item of cierresPredefinidos" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('cierres_predefinidos')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'cierres_predefinidos')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div fxLayout="row">
                <mat-form-field appearance="standard" class="form-group w-100-p mr-16">
                    <mat-label>Contenido<span class="required">*</span></mat-label>
                    <textarea matInput name="contents" formControlName="contents" class="form-control" style="height: 80px"></textarea>
                </mat-form-field>
            </div>

            <ng-container *ngIf="!seguimiento">
                <div fxLayout="row wrap" fxLayout="flex-start space-between">
                    <div *ngIf="permisoEnviarSegCorreo" fxLayout="column" fxFlex="33" class="px-8">
                        <mat-slide-toggle formControlName="notificacion">Enviar notificación (APP)</mat-slide-toggle>
                    </div>

                    <div *ngIf="permisoEnviarSMS" fxLayout="column" fxFlex="33" class="px-8">
                        <mat-slide-toggle formControlName="sms" (change)="changeModeSms($event)">Enviar como SMS</mat-slide-toggle>
    
                        <mat-form-field  *ngIf="marcadoSMS" appearance="standard" class="form-group w-80-p ml-4">
                            <mat-label>Nº Móvil</mat-label>
                            <input matInput name="telefonoCliente" formControlName="telefonoCliente" class="form-control" autocomplete="off">
                        </mat-form-field>
                    </div>

                    <div *ngIf="permisoEnviarSegCorreo" fxLayout="column" fxFlex="33" class="px-8">
                        <mat-slide-toggle formControlName="email" (change)="changeModeEmail($event)">Enviar como e-mail</mat-slide-toggle>
    
                        <mat-form-field *ngIf="marcadoEmail" appearance="standard" class="form-group w-80-p ml-4">
                            <mat-label>E-mail</mat-label>
                            <input matInput name="emailColaborador" formControlName="emailColaborador" class="form-control" autocomplete="off">
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="tipoSegCierreSelected && permisoEnviarSegCorreo && permisoEnviarPartesTrabajo && partesTrabajo.length > 0" fxLayout="row">
                <mat-form-field appearance="standard" class="form-group w-100-p mr-16">
                    <mat-label>Enviar Partes de Trabajo</mat-label>
                    <mat-select #selectPartesTrabajo name="lista_adjuntos" formControlName="lista_adjuntos" class="form-control" [multiple]="true">
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip *ngFor="let pt of partesTrabajoSelected" [removable]="true" (removed)="deselectParteTrabajo(pt)">
                                    {{ pt.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of partesTrabajo" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('lista_adjuntos')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'lista_adjuntos')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div fxLayout="row" class="mt-32">
                <!-- <mat-slide-toggle formControlName="env_sgsd" class="mr-32">Actualizar SGSD</mat-slide-toggle> -->
                <mat-slide-toggle *ngIf="weblord" formControlName="env_weblord">Añadir a WEBLORD</mat-slide-toggle>
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-button class="btn-aitana" [class.spinner]="loadingSave" [disabled]="loadingSave || !formSeguimiento.valid" (click)="guardarSeguimiento()">Guardar</button>
</mat-dialog-actions>