<form [formGroup]="direccionForm" (submit)="submit()">
    <h2 mat-dialog-title>{{ this.isNewDireccion ? 'Nueva dirección' : 'Editar dirección' }}</h2>
    <div mat-dialog-content>

        <mat-error *ngIf="error">
            <span>{{ error }}</span>
        </mat-error>

        <div class="container" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px grid" fxLayoutGap.xs="0">
            <div fxFlex="20">
                <mat-form-field class="form-field">
                    <mat-label>Tipo dirección <span class="required">*</span></mat-label>
                    <mat-select matInput formControlName="tipo_direccion">
                        <mat-option *ngFor="let tipoDireccion of tiposDireccion" [value]="tipoDireccion.ID">
                          {{tipoDireccion.tipo_direccion}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="direccionForm.controls['direccion_tipos']" formArrayName="direccion_tipos" fxFlex="80" fxLayout="row wrap" fxLayoutAlign="left" fxLayoutGap="10px grid" fxLayoutGap.xs="0">

                <div fxFlex="40" *ngFor="let formGroupControl of direccionForm.controls['direccion_tipos'].controls; let i = index" [formGroup]="formGroupControl">
                    <mat-form-field class="form-field">
                        <mat-label>Tipo extra {{i + 1}}</mat-label>
                        <input matInput formControlName="name">
                        <mat-icon matSuffix (click)="removeDireccionTipo(i)">close</mat-icon>
                    </mat-form-field>
                </div>
                <div fxFlex="20">
                    <div style="padding-top: 8px;">
                        <button mat-flat-button (click)="addDireccionTipo()" type="button"><mat-icon>add</mat-icon> Añadir tipo</button>
                    </div>
                </div>

            </div>
            <div fxFlex="20">
                <mat-form-field class="form-field">
                    <mat-label>Tipo vía  <span class="required">*</span></mat-label>
                    <mat-select matInput formControlName="tipo_via">
                        <mat-option *ngFor="let tipo of tiposVia" [value]="tipo.ID">
                            {{tipo.ID}}
                          </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="60">
                <mat-form-field class="form-field">
                    <mat-label>Dirección <span class="required">*</span></mat-label>
                    <input matInput formControlName="direccion">
                </mat-form-field>
            </div>
            <div fxFlex="20">
                <mat-form-field class="form-field">
                    <mat-label>Número <span class="required">*</span></mat-label>
                    <input matInput type="number" formControlName="num_casa">
                </mat-form-field>
            </div>
            <div fxFlex="25">
                <mat-form-field class="form-field">
                    <mat-label>Pais <span class="required">*</span></mat-label>
                    <mat-select matInput formControlName="id_pais">
                        <mat-option *ngFor="let pais of paises" [value]="pais.id">
                          {{pais.pais}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="25">
                <mat-form-field class="form-field">
                    <mat-label>Provincia <span class="required">*</span></mat-label>
                    <mat-select matInput formControlName="id_provincia">
                        <mat-option *ngFor="let provincia of provincias" [value]="provincia.idprovincia">
                          {{provincia.provincia}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="50">
                <mat-form-field class="form-field">
                    <mat-label>Localidad <span class="required">*</span></mat-label>
                    <mat-select matInput formControlName="id_localidad">
                        <mat-option *ngFor="let localidad of localidades" [value]="localidad.idpoblacion">
                          {{localidad.poblacion}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="20">
                <mat-form-field class="form-field">
                    <mat-label>CP <span class="required">*</span></mat-label>
                    <input matInput formControlName="cp" maxlength="45">
                </mat-form-field>
            </div>
            <div fxFlex="50">
                <mat-form-field class="form-field">
                    <mat-label>Nombre contacto <span class="required">*</span></mat-label>
                    <input matInput formControlName="nombre_contacto">
                </mat-form-field>
            </div>
            <div fxFlex="50">
                <mat-form-field class="form-field">
                    <mat-label>Apellido contacto</mat-label>
                    <input matInput formControlName="apellido_contacto">
                </mat-form-field>
            </div>
            <div fxFlex="50">
                <mat-form-field class="form-field">
                    <mat-label>Teléfono contacto <span class="required">*</span></mat-label>
                    <input matInput formControlName="telefono_contacto">
                </mat-form-field>
            </div>
            <div fxFlex="50">
                <mat-form-field class="form-field">
                    <mat-label>Email contacto</mat-label>
                    <input matInput formControlName="email_contacto">
                </mat-form-field>
            </div>
            <div fxFlex="100">
                <mat-form-field class="form-field">
                    <mat-label>Otros datos</mat-label>
                    <textarea matInput formControlName="otros_datos" cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
                </mat-form-field>
            </div>
        </div>

    </div>

    <div mat-dialog-actions align="end">
        <button mat-flat-button [mat-dialog-close]="true" type="button">Cancelar</button>
        <button mat-raised-button color="primary" [class.spinner]="loadingSave" [disabled]="disableBtnSave || (direccionForm && !direccionForm.valid)" type="submit">Guardar</button>
    </div>

</form>