<div class="header mat-elevation-z4 accent-400" fxLayout="row" fxLayoutAlign="space-between center">

    <div class="title ml-16" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon fontSet="fas" fontIcon="fa-comments" class="s-48 fuse-white-fg"></mat-icon>
        <h3 class="ml-12 fuse-white-fg">Bot 
            <select [(ngModel)]="botType">
                    <option value="default">GENERAL</option>
                    <option value="avacom">AVACOM</option>
            </select>
        </h3>
    </div>

    <div class="actions">
        <button mat-icon-button matTooltip="Reiniciar diagnosis" class="toggle-sidebar-open mr-16" (click)="initChat()">
            <mat-icon class="secondary-text">refresh</mat-icon>
        </button>
        
        <button mat-icon-button class="toggle-sidebar-open mr-8" (click)="resetChat(); toggleSidebarOpen()">
            <mat-icon class="secondary-text">close</mat-icon>
        </button>
    </div>
</div>

<div fxLayout="row" class="content">
    <div id="chat-autodiagnosis" fxLayout="column" fxFlex="1 1 auto">
        <div id="messages-autodiagnosis-bot" class="messages" fxFlex="1 1 auto" fusePerfectScrollbar>

            <ng-container *ngIf="mensajes.length > 0">
                <div *ngFor="let itemMensaje of mensajes; let i = index" class="message-row"
                        [ngClass]="{
                            'me': itemMensaje.who == 'user',
                            'contact': itemMensaje.who == 'contact',
                            'first-of-group': isFirstMessageOfGroup(itemMensaje, i),
                            'last-of-group': isLastMessageOfGroup(itemMensaje, i)
                        }">
                    <img *ngIf="shouldShowContactAvatar(itemMensaje, i)" class="avatar" [src]="itemMensaje.avatar">
                    
                    <div *ngIf="itemMensaje.type == 'message'" class="bubble" [ngClass]="{'red-400-bg': itemMensaje.error, 'green-400-bg': itemMensaje.success}">
                        <div class="message">{{itemMensaje.message}}</div>
                    </div>

                    <div *ngIf="itemMensaje.actions" fxLayout="row wrap" fxLayoutAlign="center center" class="mt-8 mb-24 px-16">
                        <ng-container *ngFor="let action of itemMensaje.actions">
                            <button *ngIf="action.text" mat-button class="btn-aitana" [disabled]="itemMensaje.disabled" (click)="actionMensaje(action.action, i)">{{action.text}}</button>
                            <button *ngIf="action.icon" mat-icon-button class="action-icon mr-40" [matTooltip]="action.title ? action.title : ''"
                                    [ngClass]="{'selected': action.selected, 'disabled': itemMensaje.disabled}" 
                                    [disabled]="itemMensaje.disabled" (click)="actionMensaje(action.action, i, action.id)">
                                <mat-icon fontSet="fas" [fontIcon]="action.icon" class="mr-8"></mat-icon>
                            </button>
                        </ng-container>
                    </div>
                    
                    <div *ngIf="itemMensaje.input == 'chat'" fxLayout="row" fxLayoutAlign="space-between center" class="w-100-p">
                        <mat-form-field appearance="standard" class="form-group mr-16 w-300">
                            <mat-label>Mensaje</mat-label>
                            <input matInput #chatMsgInput name="chatMsg" type="text" [(ngModel)]="chatMsg" autocomplete="off">
                        </mat-form-field>

                        <button mat-button mat-stroked-button [disabled]="!chatMsg || chatMsg.length <= 0" (click)="actionMensaje('sendChat', i)">Enviar</button>
                    </div>
                </div>

            </ng-container>

        </div>
    </div>
</div>