<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/fuse.svg">
                </div>
            </div>

            <!-- <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div> -->

            <div class="pl-8 pl-md-16">
                <app-selector-proyectos></app-selector-proyectos>
            </div>
            
            <div class="pl-8 pl-md-16" *ngIf="mostrarBuscadorTicket">
                <app-buscador-tickets></app-buscador-tickets>
            </div>

            <!-- <div class="pl-8 pl-md-16" *ngIf="mostrarBuscadorNSAutoasignar">
                <app-buscador-ns></app-buscador-ns>
            </div> -->

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <button *ngIf="chatAutoDiagnosisPermitido" mat-button class="navbar-toggle-button btn-autodiagnosis" (click)="toggleSidebarOpen('diagnosisBotGpt')">
                <!-- <mat-icon fontSet="fas" fontIcon="fa-comments" class="secondary-text"></mat-icon> -->
                <img src="assets/images/autodiagnosis-bot/adbot_gpt.png">
            </button>

            <button *ngIf="chatAutoDiagnosisPermitido" mat-button class="navbar-toggle-button btn-autodiagnosis" (click)="toggleSidebarOpen('diagnosisBot')">
                <!-- <mat-icon fontSet="fas" fontIcon="fa-comments" class="secondary-text"></mat-icon> -->
                <img src="assets/images/autodiagnosis-bot/adbot.png">
            </button>

            <button *ngIf="timerTaskPermitido" mat-button class="navbar-toggle-button btn-autodiagnosis" (click)="toggleSidebarOpen('timerTask')">
                <img src="https://cdn0.iconfinder.com/data/icons/time-calendar-2/24/time_timer_stopwatch_cronometer_counter-256.png">
                
                <div *ngIf="timerIcon == 'START'" class="icon-status">
                    <mat-icon fontSet="fas" fontIcon="fa-play" color="accent"></mat-icon>
                </div>
                <div *ngIf="timerIcon == 'LOADING'" class="icon-status">
                    <mat-icon fontSet="fas" class="spinner-toolbar-timer-task" fontIcon="fa-spinner" color="secondary"></mat-icon>
                </div>
                <div *ngIf="timerIcon == 'STOP'" class="icon-status">
                    <mat-icon fontSet="fas" fontIcon="fa-stop" color="warn"></mat-icon>
                </div>
                <div *ngIf="timerIcon == 'PAUSE'" class="icon-status">
                    <mat-icon fontSet="fas" fontIcon="fa-pause" color="warn"></mat-icon>
                </div>
            </button>

            <div class="toolbar-separator"></div>

            <button mat-button [matMenuTriggerFor]="userMenu"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img *ngIf="usuario" class="avatar mr-0 mr-sm-16" src="{{imageSvc.getImageUser(usuario.image_user)}}">
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{usuario?.nombre}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>Mi perfíl</span>
                </button>

                <!-- <button mat-menu-item class="">
                    <mat-icon>mail</mat-icon>
                    <span>Inbox</span>
                </button> -->

                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Cerrar sesión</span>
                </button>

            </mat-menu>

            <!-- <div class="toolbar-separator"></div> -->

            <!-- <app-notificacion></app-notificacion> -->

            <!-- <div class="toolbar-separator"></div>
            
            <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

            <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="cambiarIdioma(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button mat-icon-button 
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon  class="secondary-text">format_list_bulleted</mat-icon>
                <span *ngIf="numNotificacionesNoLeidas > 0" class="bubble-notificacion"></span>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

    </div>

</mat-toolbar>
