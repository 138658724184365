import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { UserRoleService } from 'app/services/user/user-role.service';
import { UserService } from 'app/services/user/user.service';

@Component({
    selector   : 'fuse-nav-vertical-group',
    templateUrl: './group.component.html',
    styleUrls  : ['./group.component.scss']
})
export class FuseNavVerticalGroupComponent implements OnInit, OnDestroy
{
    @HostBinding('class')
    classes = 'nav-group nav-item';

    @Input()
    item: FuseNavigationItem;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private userRoleSvc: UserRoleService,
        private userService: UserService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });

        this.userService.onMenuFiltered.subscribe(
			(data: any) => {
				if(data.itemId == this.item.id) {
                    this.item.hidden = data.hidden;
                    this._changeDetectorRef.detectChanges();
                }
			}
		);
        // if (this.item.permissions) {
        //     this.item.hidden = true;
        //     this.userRoleSvc.checkPermissions(this.item.permissions).then(
        //         (response: boolean) => {
        //             if (response) {
        //                 this.item.hidden = false;
        //             }
        //             else {
        //                 this.item.hidden = true;
        //             }
        //             this._changeDetectorRef.detectChanges();
        //             // console.log("response checkPermissions", this.item.permissions, response);
        //             // console.log("item", this.item);
        //         }
        //     )
        // }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
