
<div id="subir-fichero" class="page-layout simple fullwidth px-12 pt-12">
El fichero a subir tiene que tener extensión .xls y el siguiente formato de columnas:<br><br>
<table>
    <tr><td>ID Proyecto(Número)</td><td>Nombre</td><td>Número de serie</td><td>Part Number</td><td>Comentarios</td><td>Nº Poliza Garantia10</td></tr>
</table>


<div fxLayout="column" class="mb-16 mt-16">
    <mat-label class="secondary-text font-size-14">Inventario</mat-label>
    <div *ngIf="files.length == 0" class="dropzone" appDragAndDrop fxLayout="row" fxLayoutAlign="center center" (fileDropped)="onFileDropped($event)">
        <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" />
        <mat-icon matSuffix class="secondary-text">cloud_upload</mat-icon>
        <h3>Suelta el archivo para adjuntarlo o <label for="fileDropRef">explorar</label></h3>
    </div>
</div>

<div class="files-list">
    <div class="single-file" *ngFor="let file of files; let i = index" [class.error-file]="file.error">
        <mat-icon [inline]="true" class="secondary-text" style="font-size: 40px;">insert_drive_file</mat-icon>
        <div class="info">
            <h4 class="name">{{ file?.name }}</h4>
            <p *ngIf="file.size" class="size">{{ formatBytes(file?.size) }}</p>
            <app-progress *ngIf="file.progress" [progress]="file?.progress"></app-progress>
        </div>
    
    </div>
</div>



</div>
