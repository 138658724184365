import { Component, OnInit, EventEmitter, forwardRef, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColaboradoresService } from 'app/services/colaboradores/colaboradores.service';


@Component({
    selector: 'app-colaborador-dialog',
    templateUrl: './colaborador-dialog.component.html',
    styleUrls: ['./colaborador-dialog.component.scss'],
})
export class ColaboradorDialogComponent implements OnInit {

    closeDialog = new EventEmitter();

    colaboradorForm: FormGroup;

    error: string;
    isNewColaborador: boolean = false;

    estadosColaborador: Array<any>;
    tiposSociedad: Array<any>;
    numeroEmpleados: Array<any>;

	loadingSave: boolean = false;
	disableBtnSave: boolean = false;

    idColaborador: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<ColaboradorDialogComponent>,
        private _snackBar: MatSnackBar,
        private colaboradorService: ColaboradoresService,
    ) {

        this.estadosColaborador = [
            {id: 'COLABORADOR', name: 'COLABORADOR'},
            {id: 'DESCARTADO', name: 'DESCARTADO'},
            {id: 'EN PROCESO', name: 'EN PROCESO'},
            {id: 'INACTIVO', name: 'INACTIVO'},
            {id: 'PROPIO', name: 'PROPIO'},
            {id: 'SOLICITUD', name: 'SOLICITUD'}
        ];

        this.tiposSociedad = [
            {id: '1', name: 'Particular'},
            {id: '2', name: 'Empresa'},
        ];
        this.numeroEmpleados = [
            {id: '0', name: '0'},
            {id: '1', name: '1'},
            {id: '2', name: '2'},
            {id: '3', name: '3'},
            {id: '4', name: '4'},
            {id: '5', name: '5'},
            {id: '6', name: '6'},
            {id: '7', name: '7'},
            {id: '8', name: '8'},
            {id: '9', name: '9'},
            {id: '10', name: '+10'},
        ];

        if (!this.data) {
            this.isNewColaborador = true;
        }
        else {
            this.idColaborador = this.data.ID;
        }

        this.colaboradorForm = this.formBuilder.group({
            estado_colaborador: [this.data ? this.data.estado_colaborador : null],
            tipo_sociedad: [this.data ? this.data.tipo_sociedad : null],
            nombre_apellidos: [this.data ? this.data.nombre_apellidos : null],
            razon_social: [this.data ? this.data.razon_social : null],
            cif_nif: [this.data ? this.data.cif_nif : null],
            telefono: [this.data ? this.data.telefono : null],
            mail: [this.data ? this.data.mail : null],
            email_facturacion: [this.data ? this.data.email_facturacion : null],
            num_empleados: [this.data ? this.data.num_empleados : null],
            realiza_onsite: [this.data ? this.data.realiza_onsite : false],
            comentarios: [this.data ? this.data.comentarios : null],
        });
    }

    ngOnInit(): void { }

    openSnackBar(message, action) {
        this._snackBar.open(message, action, {
            duration: 5000,
        });
    }

    submit() {
        this.disableBtnSave = true;
		this.loadingSave = true;

        console.log(this.colaboradorForm.value);
        if (this.isNewColaborador) {
            this.colaboradorService.crearColaborador(this.colaboradorForm.value).subscribe(
                (data: any) => {
                    if(data.success) {
                        this.openSnackBar("Datos guardados correctamente", "Cerrar");
                        this.dialogRef.close({ });
                        this.loadingSave = false;
                    }
                    else {
                        this.loadingSave = false;
                        this.disableBtnSave = false;
                        if(data.message) {
                            this.openSnackBar(data.message, "Cerrar");
                        }
                    }
                },
                (err) => {
                    this.loadingSave = false;
                    this.disableBtnSave = false;
                    this.openSnackBar(err, "Cerrar");
                }
            );
        } 
        else {
            this.colaboradorService.updateColaborador(this.colaboradorForm.value, this.idColaborador).subscribe(
                (data: any) => {
                    if(data.success) {
                        this.openSnackBar("Datos guardados correctamente", "Cerrar");
                        this.dialogRef.close({ });
                        this.loadingSave = false;
                    }
                    else {
                        this.loadingSave = false;
                        this.disableBtnSave = false;
                        if(data.message) {
                            this.openSnackBar(data.message, "Cerrar");
                        }
                    }
                },
                (err) => {
                    this.loadingSave = false;
                    this.disableBtnSave = false;
                    this.openSnackBar(err, "Cerrar");
                }
            );
        }
    }

}
