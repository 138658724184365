<div id="garantia10-proyectos" class="page-layout simple right-sidebar" fxLayout="row">

    <!-- CENTER -->
    <div class="center" fusePerfectScrollbar>

        <mat-card class="card">
        <div class="header" fxLayout="column" fxLayoutAlign="start"
        fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
            <h2 class="title">Garantia 10 - Analisis de proyecto {{(proyectos)? proyectos[0].proyecto_name: "" }}</h2>
           <!--<app-buscador (filtrar)="applyFilter($event)"></app-buscador>-->
        </div>
    </mat-card>

    <mat-card class="content card">
        <div class="page-layout blank" *ngIf="proyectos">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tabla-detalles-proyecto" width="100%" multiTemplateDataRows>
                <ng-container matColumnDef="proyecto.name">
                    <th mat-header-cell *matHeaderCellDef> Nombre </th>
                    <td mat-cell *matCellDef="let elem"> {{elem.proyecto_name}} </td> 
                </ng-container>

                <ng-container matColumnDef="tipo.name">
                    <th mat-header-cell *matHeaderCellDef class="textCenter"> Tipo </th>
                    <td mat-cell *matCellDef="let elem" class="textCenter"> {{elem.tipo_name}} </td> 
                </ng-container>

                <ng-container matColumnDef="modalidad.name">
                    <th mat-header-cell *matHeaderCellDef class="textCenter"> Modalidad </th>
                    <td mat-cell *matCellDef="let elem" class="textCenter"> {{elem.modalidad_name}} </td> 
                </ng-container>

                <ng-container matColumnDef="totalStockElementos">
                    <th mat-header-cell *matHeaderCellDef class="textCenter"> Uds </th>
                    <td mat-cell *matCellDef="let elem" class="textCenter" class="alignCenter"> {{totalStock_Proyecto}} </td> 
                </ng-container>

                <ng-container matColumnDef="tipo_dispositivo">
                    <th mat-header-cell *matHeaderCellDef class="textCenter"> Elemento </th>
                    <td mat-cell *matCellDef="let elem" class="textCenter"> {{tipoDispositivo_Proyecto}} </td> 
                </ng-container>

                <ng-container matColumnDef="garantia">
                    <th mat-header-cell *matHeaderCellDef class="textCenter"> Fecha FIN Garantía oficial </th>
                    <td mat-cell *matCellDef="let elem" class="alignCenter" class="textCenter"> {{finGarantiaOficial_Proyecto | date:'MM/dd/yyyy'}} </td> 
                </ng-container>

                <ng-container matColumnDef="contrato.fecha_comienzo">
                    <th mat-header-cell *matHeaderCellDef class="textCenter"> Fecha INICIO Contrato </th>
                    <td mat-cell *matCellDef="let elem" class="alignCenter" class="textCenter"> {{elem.contrato_fecha_comienzo | date:'MM/dd/yyyy'}} </td> 
                </ng-container>

                <ng-container matColumnDef="contrato.fecha_vencimiento">
                    <th mat-header-cell *matHeaderCellDef class="textCenter"> Fecha FIN Contrato </th>
                    <td mat-cell *matCellDef="let elem" class="alignCenter" class="textCenter"> {{elem.contrato_fecha_vencimiento | date:'MM/dd/yyyy'}} </td> 
                </ng-container>

                <ng-container matColumnDef="rentabilidad">
                    <th mat-header-cell *matHeaderCellDef class="textCenter"> Rentabilidad acumulada </th>
                    <td mat-cell *matCellDef="let elem" class="alignCenter" class="textCenter"> {{rentabilidad}}% </td> 
                </ng-container>

                <ng-container matColumnDef="rentabilidad_prevista">
                    <th mat-header-cell *matHeaderCellDef class="textCenter"> Rentabilidad estimada </th>
                    <td mat-cell *matCellDef="let elem" class="alignCenter" class="textCenter"> {{rentabilidadEstimada}} %</td> 
                </ng-container>

                <ng-container matColumnDef="totalAverias">
                    <th mat-header-cell *matHeaderCellDef class="textCenter"> Tasa averías (mensual) </th>
                    <td mat-cell *matCellDef="let elem" class="alignCenter" class="textCenter"> {{porcentajeAverias_Proyecto}} %</td> 
                </ng-container>

                <ng-container matColumnDef="contrato.extension_garantia">
                    <th mat-header-cell *matHeaderCellDef class="textCenter"> Meses cobertura sin garantía oficial </th>
                    <td mat-cell *matCellDef="let elem" class="textCenter"> {{elem.contrato_extension_garantia}} <span *ngIf="elem.contrato_extension_garantia">meses</span></td> 
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" class="columnaDivDispositivos" [attr.colspan]="displayedColumns.length">
                        <div >
                            <table mdbTable width="100%" cellspacing="0">
                                <tbody  *ngFor="let proyecto of proyectos">
                                <tr class="headerDispositivos">
                                    <td class="tdCabecera">
                                        <h2>{{proyecto.elemento_inventario_PN}}</h2>
                                    </td>
                                    <td>
                                        <h2>{{proyecto.elemento_inventario_name}}</h2>
                                    </td>
                                    <td colspan="2">
                                        <p>{{proyecto.elementos_pvp_mes}} PVP/Mes</p>
                                        <p>{{proyecto.totalStockElementos}} Uds</p>
                                    </td>
                                    <td align="right" class="tdCabecera">
                                        <p>Fin garantía oficial</p>
                                        <p>{{proyecto.garantia | date:'MM/dd/yyyy'}}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5" style="padding-bottom: 20px;" width="100%">
                                        <table width="100%"  cellspacing="5">
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <b>Enero</b>
                                                </td>
                                                <td>
                                                    <b>Febrero</b>
                                                </td>
                                                <td>
                                                    <b>Marzo</b>
                                                </td>
                                                <td>
                                                    <b>Abril</b>
                                                </td>
                                                <td>
                                                    <b>Mayo</b>
                                                </td>
                                                <td>
                                                    <b>Junio</b>
                                                </td>
                                                <td>
                                                    <b>Julio</b>
                                                </td>
                                                <td>
                                                    <b>Agosto</b>
                                                </td>
                                                <td>
                                                    <b>Septiembre</b>
                                                </td>
                                                <td>
                                                    <b>Octubre</b>
                                                </td>
                                                <td>
                                                    <b>Noviembre</b>
                                                </td>
                                                <td>
                                                    <b>Diciembre</b>
                                                </td>
                                                <td>
                                                    <b>Promedio</b>
                                                </td>
                                                <td>
                                                    <b>Acumulado</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Número incidencias
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[0]] ? arrayGastos[meses[0]].total_tickets : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[1]] ? arrayGastos[meses[1]].total_tickets : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[2]] ? arrayGastos[meses[2]].total_tickets : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[3]] ? arrayGastos[meses[3]].total_tickets : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[4]] ? arrayGastos[meses[4]].total_tickets : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[5]] ? arrayGastos[meses[5]].total_tickets : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[6]] ? arrayGastos[meses[6]].total_tickets : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[7]] ? arrayGastos[meses[7]].total_tickets : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[8]] ? arrayGastos[meses[8]].total_tickets : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[9]] ? arrayGastos[meses[9]].total_tickets : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[10]] ? arrayGastos[meses[10]].total_tickets : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[11]] ? arrayGastos[meses[11]].total_tickets : '-'}}
                                                </td>
                                                <td>
                                                    {{promedio['tickets'] ? promedio['tickets'] : '-'}}
                                                </td>
                                                <td>
                                                    {{totalTickets}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Coste medio (pieza)
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[0]] && arrayGastos[meses[0]].gastos_pieza ? arrayGastos[meses[0]].gastos_pieza+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[1]] && arrayGastos[meses[1]].gastos_pieza ? arrayGastos[meses[1]].gastos_pieza+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[2]] && arrayGastos[meses[2]].gastos_pieza ? arrayGastos[meses[2]].gastos_pieza+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[3]] && arrayGastos[meses[3]].gastos_pieza ? arrayGastos[meses[3]].gastos_pieza+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[4]] && arrayGastos[meses[4]].gastos_pieza ? arrayGastos[meses[4]].gastos_pieza+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[5]] && arrayGastos[meses[5]].gastos_pieza ? arrayGastos[meses[5]].gastos_pieza+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[6]] && arrayGastos[meses[6]].gastos_pieza ? arrayGastos[meses[6]].gastos_pieza+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[7]] && arrayGastos[meses[7]].gastos_pieza ? arrayGastos[meses[7]].gastos_pieza+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[8]] && arrayGastos[meses[8]].gastos_pieza ? arrayGastos[meses[8]].gastos_pieza+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[9]] && arrayGastos[meses[9]].gastos_pieza ? arrayGastos[meses[9]].gastos_pieza+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[10]] && arrayGastos[meses[10]].gastos_pieza ? arrayGastos[meses[10]].gastos_pieza+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[11]] && arrayGastos[meses[11]].gastos_pieza ? arrayGastos[meses[11]].gastos_pieza+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{promedio['pieza'] ? promedio['pieza']+'€' : '-'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Coste medio (transporte)
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[0]] && arrayGastos[meses[0]].gastos_transporte ? arrayGastos[meses[0]].gastos_transporte+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[1]] && arrayGastos[meses[1]].gastos_transporte ? arrayGastos[meses[1]].gastos_transporte+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[2]] && arrayGastos[meses[2]].gastos_transporte ? arrayGastos[meses[2]].gastos_transporte+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[3]] && arrayGastos[meses[3]].gastos_transporte ? arrayGastos[meses[3]].gastos_transporte+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[4]] && arrayGastos[meses[4]].gastos_transporte ? arrayGastos[meses[4]].gastos_transporte+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[5]] && arrayGastos[meses[5]].gastos_transporte ? arrayGastos[meses[5]].gastos_transporte+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[6]] && arrayGastos[meses[6]].gastos_transporte ? arrayGastos[meses[6]].gastos_transporte+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[7]] && arrayGastos[meses[7]].gastos_transporte ? arrayGastos[meses[7]].gastos_transporte+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[8]] && arrayGastos[meses[8]].gastos_transporte ? arrayGastos[meses[8]].gastos_transporte+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[9]] && arrayGastos[meses[9]].gastos_transporte ? arrayGastos[meses[9]].gastos_transporte+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[10]] && arrayGastos[meses[10]].gastos_transporte ? arrayGastos[meses[10]].gastos_transporte+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[11]] && arrayGastos[meses[11]].gastos_transporte ? arrayGastos[meses[11]].gastos_transporte+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{promedio['transporte'] ? promedio['transporte']+'€' : '-'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Coste medio (partner)
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[0]] && arrayGastos[meses[0]].gastos_partner ? arrayGastos[meses[0]].gastos_partner+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[1]] && arrayGastos[meses[1]].gastos_partner ? arrayGastos[meses[1]].gastos_partner+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[2]] && arrayGastos[meses[2]].gastos_partner ? arrayGastos[meses[2]].gastos_partner+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[3]] && arrayGastos[meses[3]].gastos_partner ? arrayGastos[meses[3]].gastos_partner+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[4]] && arrayGastos[meses[4]].gastos_partner ? arrayGastos[meses[4]].gastos_partner+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[5]] && arrayGastos[meses[5]].gastos_partner ? arrayGastos[meses[5]].gastos_partner+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[6]] && arrayGastos[meses[6]].gastos_partner ? arrayGastos[meses[6]].gastos_partner+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[7]] && arrayGastos[meses[7]].gastos_partner ? arrayGastos[meses[7]].gastos_partner+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[8]] && arrayGastos[meses[8]].gastos_partner ? arrayGastos[meses[8]].gastos_partner+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[9]] && arrayGastos[meses[9]].gastos_partner ? arrayGastos[meses[9]].gastos_partner+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[10]] && arrayGastos[meses[10]].gastos_partner ? arrayGastos[meses[10]].gastos_partner+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[11]] && arrayGastos[meses[11]].gastos_partner ? arrayGastos[meses[11]].gastos_partner+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{promedio['partner'] ? promedio['partner']+'€' : '-'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Coste medio (mano de obra)
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[0]] && arrayGastos[meses[0]].gastos_obra ? arrayGastos[meses[0]].gastos_obra+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[1]] && arrayGastos[meses[1]].gastos_obra ? arrayGastos[meses[1]].gastos_obra+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[2]] && arrayGastos[meses[2]].gastos_obra ? arrayGastos[meses[2]].gastos_obra+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[3]] && arrayGastos[meses[3]].gastos_obra ? arrayGastos[meses[3]].gastos_obra+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[4]] && arrayGastos[meses[4]].gastos_obra ? arrayGastos[meses[4]].gastos_obra+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[5]] && arrayGastos[meses[5]].gastos_obra ? arrayGastos[meses[5]].gastos_obra+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[6]] && arrayGastos[meses[6]].gastos_obra ? arrayGastos[meses[6]].gastos_obra+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[7]] && arrayGastos[meses[7]].gastos_obra ? arrayGastos[meses[7]].gastos_obra+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[8]] && arrayGastos[meses[8]].gastos_obra ? arrayGastos[meses[8]].gastos_obra+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[9]] && arrayGastos[meses[9]].gastos_obra ? arrayGastos[meses[9]].gastos_obra+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[10]] && arrayGastos[meses[10]].gastos_obra ? arrayGastos[meses[10]].gastos_obra+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{arrayGastos[meses[11]] && arrayGastos[meses[11]].gastos_obra ? arrayGastos[meses[11]].gastos_obra+'€' : '-'}}
                                                </td>
                                                <td>
                                                    {{promedio['obra'] ? promedio['obra']+'€' : '-'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="13" class="topBorder">
                                                    
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="">
                                                    <b>Coste medio (total)</b>
                                                </td>
                                                <td  class="">
                                                    {{totalGastos[meses[0]] ? totalGastos[meses[0]]+'€' : '-'}}
                                                </td>
                                                <td  class="">
                                                    {{totalGastos[meses[1]] ? totalGastos[meses[1]]+'€' : '-'}}
                                                </td>
                                                <td  class="">
                                                    {{totalGastos[meses[2]] ? totalGastos[meses[2]]+'€' : '-'}}
                                                </td>
                                                <td  class="">
                                                    {{totalGastos[meses[3]] ? totalGastos[meses[3]]+'€' : '-'}}
                                                </td>
                                                <td  class="">
                                                    {{totalGastos[meses[4]] ? totalGastos[meses[4]]+'€' : '-'}}
                                                </td>
                                                <td  class="">
                                                    {{totalGastos[meses[5]] ? totalGastos[meses[5]]+'€' : '-'}}
                                                </td>
                                                <td  class="">
                                                    {{totalGastos[meses[6]] ? totalGastos[meses[6]]+'€' : '-'}}
                                                </td>
                                                <td  class="">
                                                    {{totalGastos[meses[7]] ? totalGastos[meses[7]]+'€' : '-'}}
                                                </td>
                                                <td  class="">
                                                    {{totalGastos[meses[8]] ? totalGastos[meses[8]]+'€' : '-'}}
                                                </td>
                                                <td  class="">
                                                    {{totalGastos[meses[9]] ? totalGastos[meses[9]]+'€' : '-'}}
                                                </td>
                                                <td  class="">
                                                    {{totalGastos[meses[10]] ? totalGastos[meses[10]]+'€' : '-'}}
                                                </td>
                                                <td  class="">
                                                    {{totalGastos[meses[11]] ? totalGastos[meses[11]]+'€' : '-'}}
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"></tr>
            </table>
        </div>
    </mat-card>

    </div>
</div>
