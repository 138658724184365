<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">Crear {{ nombreElemento }}</h2>
</div>

<mat-dialog-content class="mat-typography">
    <form #mainForm="ngForm" [formGroup]="formOption" fxLayout="column">
        <h3><b>Nombre de {{nombreElemento}} *</b></h3>
        <input matInput formControlName="nombre" class="form-control" autocomplete="off" placeholder="Introduce el nombre" maxlength="45">
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button [mat-dialog-close]="undefined" (click)="cancel()">Cancelar</button>
    <button mat-button class="btn-aitana" (click)="accept()">Crear</button>
</mat-dialog-actions>