<mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
    <mat-card class="card-popover">
        <mat-card-title>
            <label style="margin-right: 10px;"><fa-icon icon="edit"></fa-icon>&nbsp;{{title}}</label>
            <fa-icon icon="times" class="pointer" (click)="closePopover()"></fa-icon>
        </mat-card-title>
        <mat-card-content *ngIf="opened">
            <mat-form-field style="width: 100%;" appearance="standard" *ngIf="type == 'select'">
                <mat-select [(ngModel)]="value">
                    <mat-option *ngFor="let option of options" [value]="option.id">{{option.displayName?option.displayName:(option.nombre?option.nombre:option.name?option.name:'')}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 100%;" appearance="standard" *ngIf="type == 'select-filter'">
                <mat-select [(ngModel)]="value">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="selectFilterCtrl"
                        [placeholderLabel]="('COMUN.BUSCAR' | translate) + '..'" [noEntriesFoundLabel]="'COMUN.NO_RESULTADOS' | translate"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let option of selectFiltered | async" [value]="option.id">{{option.displayName?option.displayName:(option.nombre?option.nombre:option.name?option.name:'')}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="form-field" *ngIf="type == 'input'">
                <input matInput [(ngModel)]="value">
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
            <button mat-button class="btn-aitana" (click)="saveChanges()">{{ 'COMUN.GUARDAR' | translate }}</button>
            <button mat-button mat-stroked-button (click)="closePopover()">{{ 'COMUN.CANCELAR' | translate }}</button>
        </mat-card-actions>
    </mat-card>
</mde-popover>

<label *ngIf="tag == 'label'" [style.cursor]="'pointer'" (click)="openPopover()">{{texto?texto:'-'}}
</label>

<mat-chip-list *ngIf="tag == 'mat-chip'">
    <mat-chip [style.cursor]="'pointer'"
    class="nowrap" [style.background]="bg" [style.color]="color" (click)="openPopover()">{{texto?texto:'-'}}</mat-chip>
</mat-chip-list>

<!-- Es necesario informar un elemento para abrir el popover aún desde una función. En este caso usamos este div que no va a verse. -->
<div [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click" id="divHidden"></div>