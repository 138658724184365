import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import {OportunidadModule} from '../../main/oportunidades/oportunidad/oportunidad.module';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DbService } from '../../services/db/db.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-oportunidad-dialog',
  templateUrl: './oportunidad-dialog.component.html',
  styleUrls: ['./oportunidad-dialog.component.scss']
})
export class OportunidadDialogComponent implements OnInit {

    closeDialog = new EventEmitter();

    //oportunidad: OportunidadModule;
    oportunidadForm: FormGroup;
    etapas: Observable<any>;
    embudos: Observable<any>;
    estados: Observable<any>;
    fuentes: Observable<any>;
    personas: Observable<any>;
    buttonsEnable: Boolean;

  constructor(private formBuilder: FormBuilder, private dbservice: DbService, private _snackBar: MatSnackBar) {
        //this.oportunidad = new OportunidadModule;
        this.etapas = dbservice.getEtapas();
        this.embudos = dbservice.getEmbudos();
        this.estados = dbservice.getEstados();
        this.fuentes = dbservice.getFuentes();
        this.personas = dbservice.getPersonas();

        this.buttonsEnable = true;

      this.oportunidadForm = this.formBuilder.group({
        nombre: [null, Validators.required],
        fk_embudo: null,
        fk_contacto: null,
        fk_etapa: [null, Validators.required],
        fk_estado: null,
        fk_fuente: null,
        prioridad: "0",
        valor: null,
        descripcion: null,
        fecha_cierre: [null, Validators.required],
      });
   }

   openSnackBar(message, action) {
    this._snackBar.open(message, action, {
        duration: 5000,
      });
  }

  ngOnInit(): void {
      
  }

  submit() {
      this.buttonsEnable = false;
    if (!this.oportunidadForm.valid) {
        this.buttonsEnable = true;
      return;
    }
    
    this.dbservice.newOportunidad(this.oportunidadForm.value).subscribe(data => {
        this.closeDialog.emit(); 
        this.openSnackBar('Oportunidad creada', 'Cerrar');
    });

    //console.log(JSON.stringify(this.oportunidadForm.value));
  }

}
