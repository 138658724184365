<h2 mat-dialog-title class="title">Seguimientos Ticket: {{idTicket}}</h2>
<div mat-dialog-content>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)" class="w-100-p">

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                <td mat-cell *matCellDef="let row">{{row.date | date:'dd/MM/yyyy HH:mm:ss'}}</td>
            </ng-container>

            <ng-container matColumnDef="author">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Autor </th>
                <td mat-cell *matCellDef="let row">{{row.autor.name}}</td>
            </ng-container>

            <ng-container matColumnDef="content">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Contenido </th>
                <td mat-cell *matCellDef="let row" [innerHTML]="row.contents"></td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron incidencias</td>
            </tr>

        </table>
    </div>
</div>
<div mat-dialog-actions align="end">
    <div style="width: 100%;">
        <mat-paginator [pageSizeOptions]="[5,10,20,30]" [length]="totalElementos" [pageIndex]="0" [pageSize]="pageSize" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
    </div>

    <button class="cerrar" mat-flat-button [mat-dialog-close]="true" type="button">Cerrar</button>
</div> 

