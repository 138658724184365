export const locale = {
    lang: 'es',
    data: {
        'MAT_PAGINATOR': {
            'OF': 'de',
            'FIRST_PAGE': 'Primera página',
            'ITEMS_PER_PAGE': 'Elementos por página',
            'LAST_PAGE': 'Última página',
            'NEXT_PAGE': 'Página siguiente',
            'PREVIOUS_PAGE': 'Página anterior'
        },
        'DATE_RANGES': {
            'ELEGIR_RANGO': 'Elegir rango',
            'HOY': 'Hoy',
            'AYER': 'Ayer',
            'ULTIMOS_7_DIAS': 'Últimos 7 días',
            'ULTIMOS_30_DIAS': 'Últimos 30 días',
            'ESTE_MES': 'Este mes',
            'MES_ANTERIOR': 'Mes anterior'
        },
        'COMUN': {
            'SI': 'Sí',
            'NO': 'No',
            'APLICAR': 'Aplicar',
            'APLICAR_FILTRO': 'Aplicar filtro',
            'GUARDAR': 'Guardar',
            'CANCELAR': 'Cancelar',
            'REINICIAR': 'Reiniciar',
            'EDITAR': 'Editar',
            'BUSCAR': 'Buscar',
            'NO_RESULTADOS': 'No se encontró ningún resultado',
            'ANADIR_FILTRO': 'Añadir filtro',
            'EDITAR_FILTRO': 'Editar filtro',
            'RESTABLECER': 'Restablecer',
            'GUARDAR_FILTRO': 'Guardar filtro',
            'MIS_FILTROS': 'Mis filtros',
            'NOMBRE_FILTRO': 'Nombre del filtro',
            'FILTROS_GUARDADOS': 'Filtros guardados',
            'CONFIGURACION_FILTRO': 'Configuración del filtro',
            'ELIMINAR_FILTRO': 'Eliminar filtro',
            'ELIMINAR_FILTRO_MSG': 'Se va a eliminar el filtro {{filterName}}, ¿está seguro?',
            'FILTRO_GUARDADO': 'Filtro guardado',
            'FILTRO_GUARDAR_ERROR_NOMBRE': 'Debe indicar un nombre para el filtro',
            'NO_FILTROS_GUARDADOS': 'No tienes filtros guardados',
            'GUARDAR_ORDENACION': 'Guardar ordenación',
            'NOMBRE_ORDEN': 'Nombre del orden',
            'ORDEN_GUARDADAS': 'Orden guardadas',
            'CONFIGURACION_ORDEN': 'Configuración del orden',
            'ELIMINAR_ORDEN': 'Eliminar orden',
            'ELIMINAR_ORDEN_MSG': 'Se va a eliminar el orden {{ordenName}}, ¿está seguro?',
            'ORDEN_GUARDADO': 'Orden guardado',
            'ORDEN_GUARDADO_ERROR_NOMBRE': 'Debe indicar un nombre para el orden',
            'NO_ORDEN_GUARDADAS': 'No tienes ordenaciones guardadas',
            'NO_ORDEN_APLICADO': 'No tienes ningún orden aplicado',
            'CONFIGURAR_TABLA': 'Configurar tabla',
            'CONFIGURACION_TABLA': 'Configuración de la tabla',
            'UNA_COLUMNA_VISIBLE': 'Debe haber al menos una columna visible',
            'CONFIGURACION_GUARDADA': 'Configuracion guardada',
            'FILAS_POR_PAGINA': 'Filas por página',
            'FILTRO': 'Filtro',
            'ORDEN': 'Orden',
            'ORDEN_VISIBILIDAD_COLUMNAS': 'Orden y visibilidad de las columnas',
            'COLUMNAS_VISIBLES': 'Columnas visibles',
            'COLUMNAS_OCULTAS': 'Columnas ocultas',
            'CARGANDO_DATOS': 'Cargando datos',
            'DATOS_GUARDADOS': 'Datos guardados correctamente',
            'ERROR_GUARDAR': 'No se pudieron guardar los datos',
        },
        'BUSCADOR': {
            'BUSCAR': "Buscar menú"
        },
        'CLIENTES': {
            'CREAR': "Cliente NETSUITE"
        },
        'PROVEEDORES': {
            'CREAR': "Nuevo proveedor",
            'MODIFICAR': "Modificar proveedor",
        }
    }
};
