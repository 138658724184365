<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">{{ 'COMUN.CONFIGURACION_TABLA' | translate }}</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column" style="width: 40vw;">

        <form fxLayout="row wrap" fxLayoutAlign="flex-start center" #mainForm="ngForm" [formGroup]="formConfig" class="form-config mb-8">
            <mat-form-field appearance="standard" class="form-group mr-16">
                <mat-label>{{ 'COMUN.FILAS_POR_PAGINA' | translate }}</mat-label>
                <mat-select name="pageSize" formControlName="pageSize" class="form-control">
                    <mat-option *ngFor="let item of pageSizeOptions" [value]="item">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="form-group mr-16">
                <mat-label>{{ 'COMUN.FILTRO' | translate }}</mat-label>
                <mat-select name="filtro" formControlName="filtro" class="form-control">
                    <mat-option *ngFor="let item of filtros" [value]="item.id">{{item.name}}</mat-option>
                </mat-select>
                <button *ngIf="checkValue('filtro')" mat-button matSuffix mat-icon-button (click)="clearValue('filtro', $event)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field appearance="standard" class="form-group mr-16">
                <mat-label>{{ 'COMUN.ORDEN' | translate }}</mat-label>
                <mat-select name="sort" formControlName="sort" class="form-control">
                    <mat-option *ngFor="let item of sorts" [value]="item.id">{{item.name}}</mat-option>
                </mat-select>
                <button *ngIf="checkValue('sort')" mat-button matSuffix mat-icon-button (click)="clearValue('sort', $event)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </form>

        <div fxLayout="row" fxLayoutAlign="center center" class="py-8 mb-8 titulo-secion-border">
            <mat-label class="secondary-text font-size-16">{{ 'COMUN.ORDEN_VISIBILIDAD_COLUMNAS' | translate }}</mat-label>
        </div>
        <div cdkDropListGroup fxLayout="row" fxLayoutAlign="space-between flex-start" fxFlex="1 0 auto" class="wrap-list-columns w-100-p">
            <div fxLayout="column" fxLayoutAlign="flex-start center" fxFlex="1 0 auto" class="list-container w-45-p">
                <mat-label class="secondary-text font-size-14 mb-4">{{ 'COMUN.COLUMNAS_VISIBLES' | translate }}</mat-label>
                
                <div class="list-columns w-100-p" fxFlex="1 0 auto" cdkDropList [cdkDropListData]="displayedColumns" (cdkDropListDropped)="dropColumn($event)">
                    <div class="column-box" *ngFor="let item of displayedColumns" cdkDrag [cdkDragDisabled]="item == 'actions'">
                        <mat-icon fontSet="fas" fontIcon="fa-eye" class="mr-12"></mat-icon>
                        {{ gridConfigSvc.getNameColum(columns, item) }}
                    </div>
                </div>
            </div>

            <div class="w-10-p px-12 pt-44" fxLayoutAlign="center flex-start">
                <mat-icon fontSet="fas" fontIcon="fa-arrows-alt-h" style="font-size: 52px;"></mat-icon>
            </div>

            <div fxLayout="column" fxLayoutAlign="flex-start center" fxFlex="1 0 auto" class="list-container w-45-p">
                <mat-label class="secondary-text font-size-14 mb-4">{{ 'COMUN.COLUMNAS_OCULTAS' | translate }}</mat-label>
                
                <div class="list-columns w-100-p" fxFlex="1 0 auto" cdkDropList [cdkDropListData]="hideColumns" (cdkDropListDropped)="dropColumn($event)">
                    <div class="column-box" *ngFor="let item of hideColumns" cdkDrag>
                        <mat-icon fontSet="fas" fontIcon="fa-eye-slash" class="mr-12"></mat-icon>
                        {{ gridConfigSvc.getNameColum(columns, item) }}
                    </div>
                </div>
            </div>
        </div>

    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <!-- <button mat-button mat-stroked-button mat-dialog-close>Cancelar</button> -->
    <button mat-button mat-stroked-button (click)="reiniciar()">
        <mat-icon fontSet="fas" fontIcon="fa-redo" class="mr-8"></mat-icon>
        {{ 'COMUN.REINICIAR' | translate }}
    </button>
    <button mat-button class="btn-aitana" (click)="guardarDatos()">{{ 'COMUN.APLICAR' | translate }}</button>
</mat-dialog-actions>