import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfigAitana, fuseConfigEwaste, fuseConfigCircPass } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { LoginModule } from 'app/main/login/login.module';

import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

//Login google
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';

import { AnalisisProyectoModule } from './main/garantia10/analisis-proyecto/analisis-proyecto.module';
import { OportunidadDialogComponent } from './componentes/oportunidad-dialog/oportunidad-dialog.component';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from "@angular/flex-layout";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
// import { MatTableExporterModule } from 'mat-table-exporter';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { ProyectoDialogComponent } from './componentes/proyecto-dialog/proyecto-dialog.component';
import { EmpresaDialogComponent } from './componentes/empresa-dialog/empresa-dialog.component';
import { PersonaDialogComponent } from './componentes/persona-dialog/persona-dialog.component';
import { ClienteDialogComponent } from './componentes/cliente-dialog/cliente-dialog.component';
import { NotAuthorizedComponent } from './main/error/not-authorized/not-authorized.component';
import { IframeComponent } from './main/iframe/iframe.component';

import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { CheckRolesGuardService } from './services/user/check-roles-guard.service';
import { SocketService } from './services/socket/socket.service';
import { SubirInventarioComponent } from './main/almacen/subir-inventario/subir-inventario.component';
import { ChartsModule } from 'ng2-charts';
import { DiagnosisBotComponent } from './main/diagnosis-bot/diagnosis-bot.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ValidateHashChangePasswordGuardService } from './services/auth/validate-hash-chage-password-guard.service';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { DiagnosisBotGptComponent } from './main/diagnosis-bot-gpt/diagnosis-bot-gpt.component';
import { MatListModule } from '@angular/material/list';
import { TimerTaskSidebarComponent } from './main/timer-task/timer-task-sidebar/timer-task-sidebar.component';


// let entorno: 'AITANA' | 'GTP' | 'EWASTE' | 'CIRCPASS' | 'CIRCPASS_GTP';
if (window.location.host == 'gtp.exxita.com' || window.location.href.includes("gtp.exxita")) {
    environment.entorno = 'gtp';
}
else if(window.location.host.includes('digipass.aitanasolutions.com') || window.location.href.includes("digipass.aitanasolutions")) {
    environment.entorno = 'gtp';
}
else if(window.location.host.includes('app.circpass') || window.location.href.includes("app.circpass")) {
    environment.entorno = 'circpass_gtp';
}
else if (window.location.host == 'ewaste.aitanasolutions.com' || window.location.href.includes("ewaste.aitanasolutions")) {
    environment.entorno = 'ewaste';
}
else if (window.location.host == 'smaaart.aitanasolutions.com' || window.location.href.includes("smaaart.aitanasolutions")) {
    environment.entorno = 'circpass';
}
else if(window.location.host.includes('cria.circpass') || window.location.href.includes("cria.circpass")) {
    environment.entorno = 'circpass';
}
else {
    environment.entorno = 'aitana';
    // environment.entorno = 'ewaste';
}
console.log("ENTORNO MODULE", environment.entorno);

const appRoutesAitana: Routes = [
    // {
    //     path        : 'entrada-cajas',
    //     loadChildren: () => import('./main/entrada-cajas/entrada-cajas.module').then(m => m.EntradaCajasModule),
    //     canActivate: [AuthGuardService, /*CheckRolesGuardService*/],
    // },
    {
        path      : 'panel-control',
        loadChildren: () => import('./main/panel-control/panel-control.module').then(m => m.PanelControlModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'entrada-palecaja',
        loadChildren: () => import('./main/entrada-palecaja/entrada-palecaja.module').then(m => m.EntradaPaleCajaModule),
        canActivate: [AuthGuardService, /*CheckRolesGuardService*/],
    },
    {
        path        : 'almacenes-movimientos',
        loadChildren: () => import('./main/almacenes-movimientos/almacenes-movimientos.module').then(m => m.AlmacenesMovimientosModule),
        canActivate: [AuthGuardService, /*CheckRolesGuardService*/],
        // data: { 
        //     permissions: [
        //         { type: "item", name: "Aitana:Acceso Movimientos Articulos", active: true },
        //     ]
        // }
    },
    {
        path        : 'almacen/subir-inventario',
        loadChildren: () => import('./main/almacen/subir-inventario/subir-inventario.module').then(m => m.SubirInventarioModule),
        canActivate: [AuthGuardService]
    }, 
    {
        path        : 'envio-transporte/expediciones',
        loadChildren: () => import('./main/envio-transporte/expediciones/expediciones.module').then(m => m.ExpedicionesModule),
        canActivate: [AuthGuardService]
    }, 
    {
        path        : 'envio-transporte/pxq',
        loadChildren: () => import('./main/envio-transporte/informe-pxq/informe-pxq.module').then(m => m.InformePxqModule),
        canActivate: [AuthGuardService]
    }, 

    {
        path        : 'tickets/pxq-manobra',
        loadChildren: () => import('./main/informes/informe-pxq-manobra/informe-pxq-manobra.module').then(m => m.InformePxqManobraModule),
        canActivate: [AuthGuardService]
    }, 
    {
        path        : 'transportes/perdidas-siniestros',
        loadChildren: () => import('./main/transportes-perdidas-siniestros/transportes-perdidas-siniestros.module').then(m => m.TransportesPerdidasSiniestrosModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'almacen/maestro-articulos',
        loadChildren: () => import('./main/almacen-maestro-articulos/almacen-maestro-articulos.module').then(m => m.AlmacenMaestroArticulosModule),
        canActivate: [AuthGuardService, CheckRolesGuardService],
        data: { 
            permissions: [
                { type: "item", name: "Aitana:Acceso Stock-Almacen", active: true },
            ]
        }
    },
    {
        path        : 'diagnosis/nueva-diagnosis',
        loadChildren: () => import('./main/diagnosis/diagnosis.module').then(m => m.DiagnosisModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'tickets',
        loadChildren: () => import('./main/tickets/tickets.module').then(m => m.TicketsModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'provisiones',
        loadChildren: () => import('./main/provisiones/provisiones.module').then(m => m.ProvisionesModule),
        canActivate: [AuthGuardService],
    },
    {
        path        : 'recuperacion',
        loadChildren: () => import('./main/recuperacion/recuperacion.module').then(m => m.RecuperacionModule),
        canActivate: [AuthGuardService],
        data: {
			environment: ['ewaste']
		}
    },
    {
        path        : 'interoperabilidad',
        loadChildren: () => import('./main/interoperabilidad/interoperabilidad.module').then(m => m.InteroperabilidadModule),
        canActivate: [AuthGuardService],
        data: {
			environment: ['circpass']
		}
    },
    {
        path        : 'informes/residuos',
        loadChildren: () => import('./main/informes/residuos/residuos.module').then(m => m.ResiduosModule),
        canActivate: [AuthGuardService],
        data: {
            environment: ['ewaste']
        }
    },
    {
        path        : 'informes/log-movimientos',
        loadChildren: () => import('./main/informes/log-movimientos-checkin/log-movimientos-checkin.module').then(m => m.LogMovimientosCheckinModule),
        canActivate: [AuthGuardService],
        data: {
            environment: ['ewaste']
        }
    },
    {
        path        : 'digipass',
        loadChildren: () => import('./main/digipass/digipass.module').then(m => m.DigipassModule),
        canActivate: [AuthGuardService],
    },
    {
        path        : 'checkin',
        loadChildren: () => import('./main/checkin/checkin.module').then(m => m.CheckinModule),
        canActivate: [AuthGuardService],
        data: {
			environment: ['aitana', 'ewaste']
		}
    },
    {
        path        : 'dashboards',
        loadChildren: () => import('./main/dashboards/dashboards.module').then(m => m.DashboardsModule),
        canActivate: [AuthGuardService],
        data: {
			environment: ['aitana', 'ewaste']
		}
    },
    {
        path        : 'cuadro-de-mandos',
        loadChildren: () => import('./main/cuadro-de-mandos/cuadro-de-mandos.module').then(m => m.CuadroDeMandosModule),
        canActivate: [AuthGuardService],
    },
    {
        path        : 'cuadro-mandos-colaborador',
        loadChildren: () => import('./main/cuadro-mandos-colaborador/cuadro-mandos-colaborador.module').then(m => m.CuadroMandosColaboradorModule),
        canActivate: [AuthGuardService],
    },
    {
        path        : 'tendencia',
        loadChildren: () => import('./main/tendencia/tendencia.module').then(m => m.TendenciaModule),
        canActivate: [AuthGuardService, /*CheckRolesGuardService*/],
        // data: { 
        //     permissions: [
        //         { type: "item", name: "Aitana:Acceso provisiones", active: true },
        //     ]
        // }
    },
    {
        path        : 'informes/informe-tickets',
        loadChildren: () => import('./main/informes/informe-tickets/informe-tickets.module').then(m => m.InformeTicketsModule),
        canActivate: [AuthGuardService, /*CheckRolesGuardService*/],
        // data: { 
        //     permissions: [
        //         { type: "item", name: "Aitana:Perfil Manager", active: true },
        //         { type: "item", name: "Aitana:Perfil superAdmin", active: true },
        //         { type: "item", name: "Aitana: Perfil cliente Lenovo WS", active: true },
        //     ]
        // }
    },
    {
        path        : 'informes/informe-productividad-lab',
        loadChildren: () => import('./main/informes/informe-productividad-lab/informe-productividad-lab.module').then(m => m.InformeProductividadLabModule),
        canActivate: [AuthGuardService, CheckRolesGuardService],
        data: { 
            permissions: [
                { type: "item", name: "Aitana:Acceso Informes Tecnicos", active: true },
            ]
        }
    },
    {
        path        : 'informes/informe-operaciones-tecnicos',
        loadChildren: () => import('./main/informes/informe-operaciones-tecnicos/informe-operaciones-tecnicos.module').then(m => m.InformeOperacionesTecnicosModule),
        canActivate: [AuthGuardService, CheckRolesGuardService],
        data: { 
            permissions: [
                { type: "item", name: "Aitana:Acceso Informes Tecnicos", active: true },
            ]
        }
    },
    {
        path        : 'bloq-1',
        loadChildren: () => import('./main/bloq1/bloq1.module').then(m => m.Bloq1Module),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'bloq-2',
        loadChildren: () => import('./main/bloq2/bloq2.module').then(m => m.Bloq2Module),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'chat',
        loadChildren: () => import('./main/chat/chat.module').then(m => m.ChatModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'panel',
        loadChildren: () => import('./main/panel/panel.module').then(m => m.PanelModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'oportunidades',
        loadChildren: () => import('./main/oportunidades/oportunidades.module').then(m => m.OportunidadesModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'metricas',
        loadChildren: () => import('./main/metricas/metricas.module').then(m => m.MetricasModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'metricas-economicas',
        loadChildren: () => import('./main/metricas-economicas/metricas-economicas.module').then(m => m.MetricasEconomicasModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'almacenes-sap',
        loadChildren: () => import('./main/almacenes-sap/almacenes-sap.module').then(m => m.AlmacenesSapModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'almacenes-planta',
        loadChildren: () => import('./main/almacenes-planta/almacenes-planta.module').then(m => m.AlmacenesPlantaModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'contrato-planta',
        loadChildren: () => import('./main/contrato-planta/contrato-planta.module').then(m => m.ContratoPlantaModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'activos-mapa',
        loadChildren: () => import('./main/activos-mapa/activos-mapa.module').then(m => m.ActivosMapaModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'activos-log',
        loadChildren: () => import('./main/activos-log/activos-log.module').then(m => m.ActivosLogModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'shop-items',
        loadChildren: () => import('./main/shop/shop-items.module').then(m => m.ShopItemsModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'metricas-iwoow',
        loadChildren: () => import('./main/metricas-iwoow/metricas-iwoow.module').then(m => m.MetricasIwoowModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'informekpi',
        loadChildren: () => import('./main/informekpi/informekpi.module').then(m => m.InformeKpiModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'garantia-10',
        loadChildren: () => import('./main/garantia10/garantia10.module').then(m => m.Garantia10Module),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'clientes-potenciales',
        loadChildren: () => import('./main/clientes-potenciales/clientes-potenciales.module').then(m => m.ClientesPotencialesModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'proyectos',
        loadChildren: () => import('./main/proyectos/proyectos.module').then(m => m.ProyectosModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'personas',
        loadChildren: () => import('./main/personas/personas.module').then(m => m.PersonasModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'empresas',
        loadChildren: () => import('./main/empresas/empresas.module').then(m => m.EmpresasModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'prueba-informe',
        loadChildren: () => import('./main/prueba-informe/prueba-informe.module').then(m => m.PruebaInformeModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'informes/kpi/indicadores',
        loadChildren: () => import('./main/informes/kpi/indicadores/indicadores.module').then(m => m.IndicadoresModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'recursos-compras',
        loadChildren: () => import('./main/recursos-compras/recursos-compras.module').then(m => m.RecursosComprasModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'financieros',
        loadChildren: () => import('./main/financieros/financieros.module').then(m => m.FinancierosModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'certificaciones',
        loadChildren: () => import('./main/certificaciones/certificaciones.module').then(m => m.CertificacionesModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'colaboradores',
        loadChildren: () => import('./main/colaboradores/colaboradores.module').then(m => m.ColaboradoresModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'dispositivos',
        loadChildren: () => import('./main/dispositivos/dispositivos.module').then(m => m.DispositivosModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'timer-task',
        loadChildren: () => import('./main/timer-task/timer-task.module').then(m => m.TimerTaskModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'informes/kpi/indicadores-unei',
        loadChildren: () => import('./main/informes/kpi/indicadores-unei/indicadores-unei.module').then(m => m.IndicadoresUneiModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'greentech-passport',
        loadChildren: () => import('./main/green-tech-passport/green-tech-passport.module').then(m => m.GreenTechPassportModule),
    },
    {
        path        : 'chequeo-estado-dispositivo',
        loadChildren: () => import('./main/chequeo-estado-dispositivo/chequeo-estado-dispositivo.module').then(m => m.ChequeoEstadoDispositivoModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'informes/kpi/incumplidas',
        loadChildren: () => import('./main/informes/kpi/incumplidas/incumplidas.module').then(m => m.IncumplidasModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'informes/tickets-cr/proyecto-mes',
        loadChildren: () => import('./main/informes/tickets-cr/proyecto-mes/proyecto-mes.module').then(m => m.ProyectoMesModule),
        canActivate: [AuthGuardService]
    },
    { //Metabase
        path      : 'informes/tickets-cr/proyectos-mes',
        loadChildren: () => import('./main/informes/tickets-cr/proyectos-mes/proyectos-mes.module').then(m => m.ProyectosMesModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'informes/tickets-cr/marca-modelo',
        loadChildren: () => import('./main/informes/tickets-cr/marca-modelo/marca-modelo.module').then(m => m.MarcaModeloModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'informes/tickets-cr/top-piezas',
        loadChildren: () => import('./main/informes/tickets-cr/top-piezas/top-piezas.module').then(m => m.TopPiezasModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'informes/reparabilidad',
        loadChildren: () => import('./main/informes/reparabilidad/reparabilidad.module').then(m => m.ReparabilidadModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'informes/calidad-reparacion',
        loadChildren: () => import('./main/informes/calidad-reparacion/calidad-reparacion.module').then(m => m.CalidadReparacionModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'informes/piezas-rma',
        loadChildren: () => import('./main/informes/piezas-rma/piezas-rma.module').then(m => m.PiezasRmaModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'informes/pxq',
        loadChildren: () => import('./main/informes/pxq/pxq.module').then(m => m.PxqModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'informes/productividad-tenico',
        loadChildren: () => import('./main/informes/productividad-tenico/productividad-tenico.module').then(m => m.ProductividadTenicoModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'centro-conocimiento',
        loadChildren: () => import('./main/centro-conocimiento/centro-conocimiento.module').then(m => m.CentroConocimientoModule),
        canActivate: [AuthGuardService],
        data: {
			environment: ['aitana', 'ewaste']
		}
    },
    {
        path      : 'gestion-cajas',
        loadChildren: () => import('./main/gestion-cajas/gestion-cajas.module').then(m => m.GestionCajasModule),
        canActivate: [AuthGuardService]
    },
    {
        path      : 'gestion-cci',
        loadChildren: () => import('./main/gestion-cci/gestion-cci.module').then(m => m.GestionCciModule),
        canActivate: [AuthGuardService]
    },
    {
		path     : 'open-link',
		component: IframeComponent,
		canActivate: [AuthGuardService],
        data: {
			environment: ['aitana', 'ewaste']
		}
	},
    {
		path     : 'error/not-authorized',
		component: NotAuthorizedComponent,
		canActivate: [AuthGuardService]
	},
    {
        path        : 'rememberPassword/:idUser/:date/:hash',
        loadChildren: () => import('./main/remember-password/remember-password.module').then(m => m.RememberPasswordModule),
        canActivate: [ValidateHashChangePasswordGuardService],
    },
    {
        path        : 'usuarios',
        loadChildren: () => import('./main/usuarios/usuarios.module').then(m => m.UsuariosModule),
        canActivate: [AuthGuardService],
    },
    {
        path      : 'envio-transporte/panel-control-et',
        loadChildren: () => import('./main/envio-transporte/panel-control-et/panel-control-et.module').then(m => m.PanelControlEtModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'stock-proyecto',
        loadChildren: () => import('./main/stock-proyecto/stock-proyecto.module').then(m => m.StockProyectoModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'tickets-estado',
        loadChildren: () => import('./main/tickets-estado/tickets-estado.module').then(m => m.TicketsEstadoModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'citas',
        loadChildren: () => import('./main/citas/citas.module').then(m => m.CitasModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'clientes',
        loadChildren: () => import('./main/clientes/clientes.module').then(m => m.ClientesModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'roles',
        loadChildren: () => import('./main/roles/roles.module').then(m => m.RolesModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'informes/mttr',
        loadChildren: () => import('./main/informes/mttr/mttr.module').then(m => m.MttrModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'informes/mtbf',
        loadChildren: () => import('./main/informes/mtbf/mtbf.module').then(m => m.MtbfModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'informes/tiempos-estados',
        loadChildren: () => import('./main/informes/tiempos-estados/tiempos-estados.module').then(m => m.TiemposEstadosModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'informes/supply-chain',
        loadChildren: () => import('./main/informes/supply-chain/supply-chain.module').then(m => m.SupplyChainModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'informes/evolucion-expediciones',
        loadChildren: () => import('./main/informes/evolucion-expediciones/evolucion-expediciones.module').then(m => m.EvolucionExpedicionesModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'informes/pareto',
        loadChildren: () => import('./main/informes/pareto/pareto.module').then(m => m.ParetoModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'informes/presupuestos-costes',
        loadChildren: () => import('./main/informes/presupuestos-costes/presupuestos-costes.module').then(m => m.PresupuestosCostesModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'informes/tipos-servicio',
        loadChildren: () => import('./main/informes/tipos-servicio/tipos-servicio.module').then(m => m.TiposServicioModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'informes/tiempos-grupos',
        loadChildren: () => import('./main/informes/tiempos-grupos/tiempos-grupos.module').then(m => m.TiemposGruposModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'informes/dashboard-dispositivo',
        loadChildren: () => import('./main/informes/dashboard-dispositivo/dashboard-dispositivo.module').then(m => m.DashboardDispositivoModule),
        canActivate: [AuthGuardService]
    },
    {
        path        : 'proveedores',
        loadChildren: () => import('./main/proveedores/proveedores.module').then(m => m.ProveedoresModule),
        canActivate: [AuthGuardService, CheckRolesGuardService],
        data: { 
            permissions: [
                { type: "item", name: "Aitana:Acceso Proveedores Netsuite", active: true },
            ]
        }
    },
    {
        path        : 'subir-netsuite',
        loadChildren: () => import('./main/subir-netsuite/subir-netsuite.module').then(m => m.SubirNetsuiteModule),
        canActivate: [AuthGuardService, CheckRolesGuardService],
        data: { 
            permissions: [
                { type: "item", name: "Aitana:Subir Fichero Netsuite", active: true },
            ]
        }
    },
    {
        path      : 'security-login',
        loadChildren: () => import('./main/security-login/security-login.module').then(m => m.SecurityLoginModule),
        canActivate: [AuthGuardService]
    },
    {
		path: '**',
		redirectTo: environment.entorno === 'ewaste' ? 'dashboards/triaje' : 'dashboards/global-proyectos',
	},
];

const appRoutesGTP: Routes = [
    {
        path        : '',
        loadChildren: () => import('./main/green-tech-passport/green-tech-passport.module').then(m => m.GreenTechPassportModule),
    },
];

let appRoutes = appRoutesAitana;
let fuseConfig = fuseConfigAitana;
// console.log("probando", window.location.href);
if (environment.entorno === 'gtp' || environment.entorno === 'circpass_gtp') {
    appRoutes = appRoutesGTP;
}
else if (environment.entorno === 'ewaste') {
    fuseConfig = fuseConfigEwaste;
}
else if (environment.entorno === 'circpass') {
    fuseConfig = fuseConfigCircPass;
}

@Injectable()
export class HammerConfig extends HammerGestureConfig {
	overrides = <any> {
		swipe: { enable: true, direction: Hammer.DIRECTION_ALL },
	};
}

@NgModule({
    declarations: [
        AppComponent,
        OportunidadDialogComponent,
        ProyectoDialogComponent,
        EmpresaDialogComponent,
        PersonaDialogComponent,
        ClienteDialogComponent,
        DragAndDropDirective,
        AutofocusDirective,
        NotAuthorizedComponent,
        IframeComponent,
        SubirInventarioComponent,
        DiagnosisBotComponent,
        DiagnosisBotGptComponent,
        TimerTaskSidebarComponent,
        AutofocusDirective,
    //    TiemposGruposComponent,
    //    PanelControlEtComponent,
    //    PanelControlComponent,
    //    TendeciaAgrupadosComponent,
    //    InformePxqManobraComponent,
    //    LoginComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),
        FontAwesomeModule,

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        LoginModule,

        AngularFireModule.initializeApp(environment.firebase),
        MatSnackBarModule,
        AnalisisProyectoModule,

        MatCheckboxModule,
        MatFormFieldModule,
        MatListModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
        FlexLayoutModule,
        FormsModule,
        MatDialogModule,
        MatDatepickerModule,
        SnotifyModule,
        ChartsModule,
        MatPaginatorModule,
        MatBottomSheetModule,
        HammerModule
    ],
    providers: [
        { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
	    { provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
        SnotifyService,
        SocketService,
    ],
 //   providers: [AuthService, AuthGuard, SecureInnerPagesGuard],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas, far, fab);
    }
}
