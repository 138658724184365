<div class="header mat-elevation-z4 accent-400" fxLayout="row" fxLayoutAlign="space-between center">

    <div class="title ml-16" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon fontSet="fas" fontIcon="fa-comments" class="s-48 fuse-white-fg"></mat-icon>
        <h3 class="ml-12 fuse-white-fg">{{ 'AUTODIAGNOSIS_BOT.AUTODIAGNOSIS_BOT' | translate }}</h3>
    </div>

    <div class="actions">
        <button mat-icon-button matTooltip="Reiniciar diagnosis" class="toggle-sidebar-open mr-16" (click)="initChat()">
            <mat-icon class="secondary-text">refresh</mat-icon>
        </button>
        
        <button mat-icon-button class="toggle-sidebar-open mr-8" (click)="initChat(); toggleSidebarOpen()">
            <mat-icon class="secondary-text">close</mat-icon>
        </button>
    </div>
</div>

<div fxLayout="row" class="content">
    <div id="chat-autodiagnosis" fxLayout="column" fxFlex="1 1 auto">
        <div id="messages-autodiagnosis" class="messages" fxFlex="1 1 auto" fusePerfectScrollbar>

            <ng-container *ngIf="mensajes.length > 0">
                <div *ngFor="let itemMensaje of mensajes; let i = index" class="message-row"
                        [ngClass]="{
                            'me': itemMensaje.who == 'user',
                            'contact': itemMensaje.who == 'contact',
                            'first-of-group': isFirstMessageOfGroup(itemMensaje, i),
                            'last-of-group': isLastMessageOfGroup(itemMensaje, i)
                        }">
                    <img *ngIf="shouldShowContactAvatar(itemMensaje, i)" class="avatar" [src]="itemMensaje.avatar">
                    
                    <div *ngIf="itemMensaje.type == 'message'" class="bubble" [ngClass]="{'red-400-bg': itemMensaje.error, 'green-400-bg': itemMensaje.success}">
                        <div class="message">{{itemMensaje.message}}</div>
                    </div>

                    <div *ngIf="itemMensaje.actions" fxLayout="row wrap" fxLayoutAlign="center center" class="mt-8 mb-24 px-16">
                        <ng-container *ngFor="let action of itemMensaje.actions">
                            <button *ngIf="action.text" mat-button class="btn-aitana" [disabled]="itemMensaje.disabled" (click)="actionMensaje(action.action, i)">{{action.text}}</button>
                            <button *ngIf="action.icon" mat-icon-button class="action-icon mr-40" [matTooltip]="action.title ? action.title : ''"
                                    [ngClass]="{'selected': action.selected, 'disabled': itemMensaje.disabled}" 
                                    [disabled]="itemMensaje.disabled" (click)="actionMensaje(action.action, i, action.id)">
                                <mat-icon fontSet="fas" [fontIcon]="action.icon" class="mr-8"></mat-icon>
                            </button>
                        </ng-container>
                    </div>
                    
                    <div *ngIf="itemMensaje.input == 'ticket'" fxLayout="row" fxLayoutAlign="space-between center" class="w-100-p">
                        <mat-form-field appearance="standard" class="form-group mr-16 w-150">
                            <mat-label>{{ 'AUTODIAGNOSIS_BOT.TICKET' | translate }}</mat-label>
                            <input matInput name="ticket" type="number" [(ngModel)]="idTicket" [disabled]="itemMensaje.disabled" autocomplete="off">
                        </mat-form-field>

                        <button mat-button mat-stroked-button [disabled]="!idTicket || itemMensaje.disabled" (click)="actionMensaje('iniciar', i)">{{ 'AUTODIAGNOSIS_BOT.CONTINUEMOS' | translate }}</button>
                    </div>
                    
                    <div *ngIf="itemMensaje.input == 'tipoElemento'" fxLayout="row" fxLayoutAlign="space-between center" class="w-100-p mt-8 mb-24 px-16">
                        <button *ngFor="let itemTipoEl of tiposElementos" mat-icon-button class="action-icon mr-40" [matTooltip]="itemTipoEl.nombre"
                                [ngClass]="{'selected': itemTipoEl.selected, 'disabled': itemMensaje.disabled}" 
                                [disabled]="itemMensaje.disabled" (click)="selectTipoElemento(itemTipoEl.id, i)">
                            <mat-icon fontSet="fas" [fontIcon]="itemTipoEl.icon" class="mr-8"></mat-icon>
                        </button>
                    </div>
                    
                    <div *ngIf="itemMensaje.input == 'descripcion'" fxLayout="column" fxLayoutAlign="flex-start" class="w-100-p mt-8 mb-24 px-16">
                        <mat-form-field appearance="standard" class="form-group w-100-p mr-16 w-150">
                            <mat-label>{{ 'AUTODIAGNOSIS_BOT.DESCRIPCION' | translate }}</mat-label>
                            <textarea matInput name="descripcion" [(ngModel)]="descripcionProblema" [disabled]="itemMensaje.disabled" style="height: 120px"></textarea>
                        </mat-form-field>

                        <button mat-button class="btn-aitana" [disabled]="itemMensaje.disabled" (click)="actionMensaje('crearDiagnosis', i)" style="align-self: flex-end;">{{ 'AUTODIAGNOSIS_BOT.CREAR_DIAGNOSIS' | translate }}</button>
                    </div>

                    <div *ngIf="itemMensaje.type == 'list-data'" fxLayout="column" class="w-100-p mt-12 mb-24 px-8">
                        <div *ngFor="let itemData of itemMensaje.data" fxLayout="row" fxLayoutAlign="space-between center" class="diagnosticos py-8">
                            <ng-container *ngIf="itemMensaje.listName == 'diagnosticos'">
                                <div fxLayout="column" class="w-100-p">
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="reparacion">
                                        <div fxLayout="column">
                                            <span class="defecto font-size-16">{{itemData.defecto}}</span>
                                            <span class="num-diagnosis font-size-12">{{ 'AUTODIAGNOSIS_BOT.PORCENTAJE_REPARACIONES' | translate: { porcentaje: getPorcentajeReparacion(itemData.num_diagnosis, totalDiagnosis) } }}</span>
                                            <span *ngIf="itemData.sintomas.length == sintomasAceptados.length && itemData.sintomas.length > 1" class="num-diagnosis font-size-12">
                                                {{ 'AUTODIAGNOSIS_BOT.REPARACION_COMUN' | translate: { num_sintomas: itemData.sintomas.length } }}
                                            </span>
                                        </div>
                                        <button *ngIf="!itemData.selected" mat-icon-button class="ml-8 btn-accion" [disabled]="itemMensaje.disabled" (click)="actionMensaje('toogleDefecto', i, itemData.id_defecto)">
                                            <mat-icon fontSet="far" fontIcon="fa-square" class="mr-8"></mat-icon>
                                        </button>
                                        <button *ngIf="itemData.selected" mat-icon-button class="ml-8 btn-accion" [disabled]="itemMensaje.disabled" (click)="actionMensaje('toogleDefecto', i, itemData.id_defecto)">
                                            <mat-icon fontSet="far" fontIcon="fa-check-square" class="mr-8"></mat-icon>
                                        </button>
                                    </div>
                                    <div *ngIf="itemData.selected && itemData.sintomasDiagnostico?.length > 0" fxLayout="column" class="w-100-p mt-8 sintomasReparacion">
                                        <span class="font-size-14 secondary-text">{{ 'AUTODIAGNOSIS_BOT.REPARACION_SINTOMAS' | translate }}</span>
                                        <div *ngFor="let sintomaDiag of itemData.sintomasDiagnostico" fxLayout="row" fxLayoutAlign="flex-start center" class="pl-8">
                                            <button *ngIf="!sintomaDiag.selected" mat-icon-button class="ml-8 btn-accion" [disabled]="itemMensaje.disabled" (click)="toogleSintomaDiagnostico(itemData.id_defecto, sintomaDiag)">
                                                <mat-icon fontSet="far" fontIcon="fa-square"></mat-icon>
                                            </button>
                                            <button *ngIf="sintomaDiag.selected" mat-icon-button class="ml-8 btn-accion" [disabled]="itemMensaje.disabled" (click)="toogleSintomaDiagnostico(itemData.id_defecto, sintomaDiag)">
                                                <mat-icon fontSet="far" fontIcon="fa-check-square"></mat-icon>
                                            </button>
                                            <span class="font-size-14 secondary-text pl-8" style="margin-top: 1px">{{ sintomaDiag.sintoma }}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            
                            <!-- <ng-contatiner *ngIf="itemMensaje.listName == 'diagnosis-creada'">
                                <div fxLayout="column">
                                    <span class="defecto font-size-16">{{itemData.nombre}}</span>
                                </div>
                            </ng-contatiner> -->
                        </div>

                        <button *ngIf="itemMensaje.listName == 'diagnosticos'" mat-button mat-stroked-button [disabled]="!allSintomasDiagnosticoSelected() || itemMensaje.disabled" class="mt-12"
                            (click)="actionMensaje('solicitarDescripcion', i)" style="align-self: flex-end;">{{ 'AUTODIAGNOSIS_BOT.CONTINUEMOS' | translate }}</button>
                    </div>
                </div>

            </ng-container>

        </div>
    </div>
</div>