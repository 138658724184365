<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 *ngIf="!cliente" class="m-0 mb-8">Nuevo cliente {{crearEnNetsuite ? 'NETSUITE' : ''}}</h2>
    <h2 *ngIf="cliente" class="m-0 mb-8">Editar cliente</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography" style="width: 60vw">

    <div *ngIf="cargandoClienteNetsuite" class="loading-netsuite-bloq" [class.spinner]="cargandoClienteNetsuite"></div>

    <div *ngIf="!cargandoClienteNetsuite" fxLayout="column">
        <form #mainForm="ngForm" [formGroup]="formCliente" class="w-100-p">
            <div fxLayout="row wrap" class="">
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Tipo cliente<span class="required">*</span></mat-label>
                    <mat-select name="tipo_cliente" formControlName="tipo_cliente" class="form-control">
                        <mat-option *ngFor="let item of tiposClienteData | async" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('tipo_cliente')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'tipo_cliente')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                
                <mat-form-field *ngIf="showDistribuidores && !crearEnNetsuite" appearance="standard" class="form-group mr-16">
                    <mat-label>Distribuidor</mat-label>
                    <mat-select name="id_cliente_padre" formControlName="id_cliente_padre" class="form-control">
                        <mat-option *ngFor="let item of distribuidoresData | async" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('id_cliente_padre')" mat-button matSuffix mat-icon-button (click)="clearSelect($event, 'id_cliente_padre')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div fxLayout="row wrap" class="mb-12">
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Nombre<span class="required">*</span></mat-label>
                    <input matInput name="nombre" formControlName="nombre" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Apellido<span *ngIf="crearEnNetsuite && esTipoParticular" class="required">*</span></mat-label>
                    <input matInput name="apellido" formControlName="apellido" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>NIF / CIF<span *ngIf="crearEnNetsuite" class="required">*</span></mat-label>
                    <input matInput name="cif_nif" formControlName="cif_nif" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>E-mail<span *ngIf="!crearEnNetsuite" class="required">*</span></mat-label>
                    <input matInput name="email_contacto" formControlName="email_contacto" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Teléfono fijo</mat-label>
                    <input matInput name="tlf_contacto" formControlName="tlf_contacto" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field *ngIf="!crearEnNetsuite" appearance="standard" class="form-group mr-16">
                    <mat-label>Móvil</mat-label>
                    <input matInput name="movil_contacto" formControlName="movil_contacto" class="form-control" autocomplete="off">
                </mat-form-field>
                
                <mat-form-field *ngIf="!crearEnNetsuite" appearance="standard" class="form-group mr-16">
                    <mat-label>Fax</mat-label>
                    <input matInput name="fax_contacto" formControlName="fax_contacto" class="form-control" autocomplete="off">
                </mat-form-field>
            </div>

            <div *ngIf="crearEnNetsuite" fxLayout="row wrap" class="mb-12">
                <mat-form-field appearance="standard" class="form-group w-95-p mr-16">
                    <mat-label>Dirección<span class="required">*</span></mat-label>
                    <input matInput name="direccion" formControlName="direccion" class="form-control" autocomplete="off">
                </mat-form-field>
            </div>

            <div *ngIf="crearEnNetsuite" fxLayout="row wrap" class="mb-12">
                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Localidad<span class="required">*</span></mat-label>
                    <input matInput name="ciudad" formControlName="ciudad" class="form-control" autocomplete="off">
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Provincia<span class="required">*</span></mat-label>
                    <input matInput name="provincia" formControlName="provincia" class="form-control" autocomplete="off">
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group mr-16">
                    <mat-label>Código Postal<span class="required">*</span></mat-label>
                    <input matInput name="zip" formControlName="zip" class="form-control" autocomplete="off">
                </mat-form-field>
            </div>

            <div *ngIf="!crearEnNetsuite" fxLayout="column" fxLayoutAlign="center flex-start" class="mb-12">
                <h4 class="secondary-text m-0 mb-4">Notificaciones</h4>
                <div fxLayout="row wrap" fxLayoutAlign="space-around center" class="w-100-p">
                    <mat-slide-toggle class="mr-24" formControlName="sms">SMS</mat-slide-toggle>
                    <mat-slide-toggle class="mr-24" formControlName="whatsapp">Whatsapp</mat-slide-toggle>
                    <mat-slide-toggle  *ngIf="!mostrarDatosPropios" class="mr-24" formControlName="publicidad">Comunicaciones Myfixpert</mat-slide-toggle>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-button class="btn-aitana" [class.spinner]="loadingSave" [disabled]="cargandoClienteNetsuite || loadingSave || !formCliente.valid" (click)="guardarCliente()">Guardar</button>
</mat-dialog-actions>