import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';

//Filtros
import { DialogSelectedFiltroInformeAiComponent } from './dialog-selected-filtro/dialog-selected-filtro.component';
import { MatDialog } from '@angular/material/dialog';
import { GridConfigService } from 'app/services/grid/grid-config.service';

//Traduccion
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { takeUntil } from 'rxjs/operators';
import { ExportService } from 'app/services/export/export.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AiStatisticsService } from 'app/services/aistatistics/aistatistics.service';
import { ProyectosService } from 'app/services/proyectos/proyectos.service';

// import { locale as english } from './i18n/en';
// import { locale as spanish } from './i18n/es';

@Component({
    selector: 'app-informe-ai',
    templateUrl: './informe-ai.component.html',
    styleUrls: ['./informe-ai.component.scss']
})
export class InformeAiComponent implements OnInit {
    //Inicializamos los elementos principales de la tabla
    @Input() tipoInforme: any;
    @Input() titulo: string = "Informe";
    @Input('table-height') height: string = "40vh";
    @Input('query') query: any = {};
    @Input('standalone') standalone: boolean = true;

    columnas:Array<any> = [];
    datos:Array<any> = [];
    displayedColumns: Array<any> = [];

    //Filtros
    filtros:Array<any> = [];
    gridName: string = "informe-ai";

    //Inicializamos elementos pagineo
    pagina:number = 0;
    pageSize:number = 20;
    totalElementos:number = 0;
    tieneMas:boolean = false;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    loading: boolean = false;

    //Elementos funcionalidad
    private _unsubscribeAll: Subject<any>;

    entidadesSeleccionadas: Array<any> = [];

    constructor(
        private translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private aiStatisticService: AiStatisticsService,
        private dialog: MatDialog,
        private gridConfigSvc: GridConfigService,
        private exportSvc: ExportService,
		private proyectosService: ProyectosService
    ) {
        moment.locale(this.translateService.currentLang);
		this._unsubscribeAll = new Subject();
		// this._fuseTranslationLoaderService.loadTranslations(english, spanish);
    }

    ngOnInit(): void {
        if(this.standalone) {
            this.loadProjectListener();
        }
    }

    ngOnChanges() {
        if(!this.standalone) {
            this.getDatos();
        }
    }

    ngOnDestroy(): void {
        //Borramos cualquier suscripcion
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

    loadProjectListener() {
		let projectsSelected = this.proyectosService.getProjectsSeleted();

		if (projectsSelected === undefined) {
			this.proyectosService.onProjectsUserInitialize.pipe(takeUntil(this._unsubscribeAll)).subscribe(
				(projectsUser) => {
					this.entidadesSeleccionadas = projectsUser;
                    this.getDatos();
				}
			);
		}
		else {
			this.entidadesSeleccionadas = projectsSelected.projects;
            this.getDatos();
		}

		this.proyectosService.onProjectSelected.pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				this.entidadesSeleccionadas = data.projects;
                this.getDatos();
			}
		);
    }

    //Recogemos los datos del informe
    getDatos(){
        this.loading = true;
        console.log("getDatos PRE", this.query, this.standalone);

        let filtrosAplicados = {};
        if(this.standalone) {
            filtrosAplicados['FK_entities'] = this.entidadesSeleccionadas;

            let filtrosActivos = this.filtros.filter((item) => { return item.active == true });
            for (const itemFiltro of filtrosActivos) {
                filtrosAplicados[itemFiltro.name] = this.gridConfigSvc.getValueFiltro(itemFiltro);
            }
        }
        else {
            filtrosAplicados = this.query;
        }

        this.aiStatisticService.filtrarInforme(this.tipoInforme, this.pagina, this.pageSize, filtrosAplicados).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(res:any) => {
                this.columnas = res.columns;
                this.displayedColumns = res.columns.map((item) => { return item.id; });

                if(this.filtros.length == 0)
                this.filtros = res.columns.map((item) => { return {type: item.tipo, name: item.id, label: item.campo, active: false, value: (res.filters && res.filters[item.id])?res.filters[item.id]:null }});
                
                this.datos = res.data;

                this.totalElementos = res.pageData.totalElementos;
                this.pageSize = res.pageData.pageSize;
                this.pagina = res.pageData.numPage;
                this.loading = false;
			}
		)
    }

    //////////////////////// Funcionalidades
    formatDate(fecha: string, time: boolean = false){
        let result = "";
        if(fecha != ""){
            if(time){
                result = moment(fecha).format('DD/MM/YYYY HH:mm');
            }else{
                result = moment(fecha).format('DD/MM/YYYY');
            }
        }
        return result;
    }
    exportData() {
		let filtrosUsados = {};
		let filtrosActivos = this.filtros.filter((item) => { return item.active == true });
		for (const itemFiltro of filtrosActivos) {
			filtrosUsados[itemFiltro.name] = this.gridConfigSvc.getValueFiltro(itemFiltro);
		}

        this.aiStatisticService.filtrarInforme(this.tipoInforme, this.pagina, this.pageSize, filtrosUsados).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(res:any) => {
                this.exportSvc.exportExcel(res.data, this.titulo);
			}
		)
	}
    filtrar(){
        this.pagina = 0;
		if (this.paginator) {
			this.paginator.firstPage();
		}

        this.getDatos();
    }
    pageChanged(event: PageEvent) {
		this.pagina = event.pageIndex;
		this.pageSize = event.pageSize;
		
        this.getDatos();
	}
    //end funcionalidades

    //////////////////////// Filtros
    isFiltrosActivos(): boolean {
		let filtrosActivos = false;
		let listActivos = this.filtros.filter((item) => { return item.active == true });
		if (listActivos.length > 0) {
			filtrosActivos = true;
		}

		return filtrosActivos;
	}

    quitarFiltro(dataFiltro: any) {
		let filtro = this.filtros.find((item) => { return item.name == dataFiltro.name });
		if (filtro) {
			filtro.value = null;
			filtro.active = false;
		}
	}

    resetFiltros() {
		let filtrosActivos = this.filtros.filter((item) => { return item.active == true });
		for (const filtro of filtrosActivos) {
			filtro.active = false;
			filtro.value = null;
		}
	}

    dialogFiltro(itemFiltro: any) {
		const dialogRef = this.dialog.open(DialogSelectedFiltroInformeAiComponent, {
			data: {
				datosFiltro: itemFiltro
			},
			panelClass: 'panel-aitana',
			disableClose: true,
			autoFocus: false
		});
		dialogRef.updatePosition({ top: '4rem' });

		dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
			if (result && result !== undefined) {
				let dataFiltro = this.filtros.find((item) => { return item.name === itemFiltro.name; });
				if (dataFiltro) {
					dataFiltro.active = result.filterValue == null ? false : true;
					dataFiltro.value = result.filterValue;
				}
			}
		});
	}

    limitSizeMatChip(itemFiltro) {
		return this.gridConfigSvc.getValueTextFiltro(itemFiltro);
	}

    restablecerFiltros() {
		this.resetFiltros();
	}
    //end filtros

}
