import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CygPermission } from '@fuse/types';
import { Observable } from 'rxjs';
import { UserRoleService } from './user-role.service';

@Injectable({
  providedIn: 'root'
})
export class CheckRolesGuardService implements CanActivate {

	constructor(public router: Router, private userRolesSvc: UserRoleService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		// console.log("CheckRolesGuardService canActivate data", route.data);
        const roles = route.data.permissions;
        // console.log("roles", roles);

        return this.checkPermissions(roles);
	}

    private checkPermissions(roles: CygPermission[]): Observable<boolean> {
        return new Observable<boolean>(observer => {
			this.userRolesSvc.checkPermissions(roles).then(
                (authorized: boolean) => {
                    if (!authorized) {
                        this.router.navigate(['error/not-authorized']);
                    }
                    observer.next(authorized);
                }
            );

			return {unsubscribe() {}};
		});
    }
}
