import { FuseNavigation } from '@fuse/types';
import { environment } from 'environments/environment';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
        /*{
                id          : 'panel-control-general',
                title       : 'Panel Control General',
                translate   : 'NAV.CONTROL_GENERAL.TITLE',
                type        : 'item',
                libIcon     : 'fas',
                icon        : 'fa-tachometer-alt',
                url         : environment.linkNewAitana + '/site/index',
                externalUrl : true,
                openInIframe: true,
                permissions: [
                    { type: "item", name: "Aitana:Panel de control", active: true },
                ],
            },*/
           {
                id          : 'panel-control-general',
                title       : 'Panel Control General',
                translate   : 'NAV.CONTROL_GENERAL.TITLE',
                type        : 'item',
                url         : 'panel-control',
                environment: ['aitana','circpass'],
                permissions: [
                    //{ type: "item", name: "Aitana:Perfil superAdmin", active: true },
                    { type: "item", name: "Aitana:Panel de control", active: true },
                ],
            }, 
            {
                id       : 'panel-control-ewaste',
                title    : 'Panel de control',
                translate: 'NAV.CONTROL_GENERAL.TITLE',
                type     : 'item',
                icon     : 'dashboard',
                url      : 'dashboards/triaje',
                environment: ['ewaste','circpass'],
                permissions: [
                    { type: "item", name: "Aitana:Dashboards:Triaje Dispositivos", active: true },
                ],
            },
            {
                 id          : 'dashboard',
                 title       : 'Dashboards',
                 translate   : 'NAV.DASHBOARDS.TITLE',
                 type     : 'collapsable',
                 icon     : 'dashboard',
                 environment: ['aitana','circpass'],
                 permissions: [
                    { type: "item", name: "Aitana:Dashboards", active: true },
                ],
                 children : [
                    {
                        id       : 'supply-chain',
                        title    : 'Supply Chain',
                        translate: 'NAV.GESTION_PROYECTO.SUPPLY_CHAIN.TITLE',
                        type     : 'collapsable',
                        libIcon  : 'fas',
                        icon     : 'fa-boxes',
                        environment: ['aitana','circpass'],
                        permissions: [
                            { type: "item", name: "Aitana:Informe:SupplyChain", active: true }
                        ],
                        children : [
                            {
                                id          : 'supply-chain-aprovisionamiento',
                                title       : 'Aprovisionamiento',
                                translate   : 'NAV.GESTION_PROYECTO.SUPPLY_CHAIN.APROVISIONAMIENTO',
                                type        : 'collapsable',
                                environment : ['aitana','circpass'],
                                children : [
                                    {
                                        id          : 'supply-chain-proveedores-materias-primas',
                                        title       : 'Aprovisionamiento',
                                        translate   : 'NAV.GESTION_PROYECTO.SUPPLY_CHAIN.PROVEEDORES_MATERIAS_PRIMAS',
                                        type        : 'item',
                                        url         : '/informes/supply-chain/aprovisionamiento',
                                        environment : ['aitana','circpass'],
                                    },
                                    {
                                        id          : 'supply-chain-proveedores-componentes',
                                        title       : 'Aprovisionamiento',
                                        translate   : 'NAV.GESTION_PROYECTO.SUPPLY_CHAIN.ACTIVOS_MATERIAS_PRIMAS',
                                        type        : 'item',
                                        url         : '/informes/supply-chain/aprovisionamiento-componentes',
                                        environment : ['aitana','circpass']
                                    },

                                ]
                            },    
                            {
                                id          : 'supply-chain-produccion',
                                title       : 'Producción',
                                translate   : 'NAV.GESTION_PROYECTO.SUPPLY_CHAIN.PRODUCCION',
                                type        : 'item',
                                url         : '/informes/supply-chain/produccion',
                                environment : ['aitana','circpass']
                            },  
                            {
                                id          : 'supply-chain-distribuicion',
                                title       : 'Distribución',
                                translate   : 'NAV.GESTION_PROYECTO.SUPPLY_CHAIN.DISTRIBUICION',
                                type        : 'item',
                                url         : '/informes/supply-chain/distribucion',
                                environment : ['aitana','circpass']
                            },  
                            {
                                id          : 'supply-chain-reciclaje',
                                title       : 'Reciclaje',
                                translate   : 'NAV.GESTION_PROYECTO.SUPPLY_CHAIN.RECLICAJE',
                                type        : 'item',
                                url         : '/informes/supply-chain/reciclaje',
                                environment : ['aitana','circpass']
                            }                     
                        ]
                    },
                    {
                        id       : 'gestion-logistica',
                        title    : 'Logística',
                        translate: 'NAV.DASHBOARDS.GESTION_LOGISTICA',
                        type     : 'item',
                        url      : 'dashboards/gestion-logistica',
                        environment: ['aitana'],
                    },
                    {
                        id       : 'configuracion-alm',
                        title    : 'Configuración',
                        translate: 'NAV.DASHBOARDS.GLOBAL_PROYECTOS',
                        type     : 'item',
                        url      : 'dashboards/global-proyectos',
                        environment: ['aitana','circpass'],
                    },
                    {
                        id       : 'dashboard-activos',
                        title    : 'Dashboard servicios',
                        translate: 'NAV.DASHBOARDS.SERVICIOS',
                        type     : 'item',
                        url      : 'dashboards/activos',
                        environment: ['aitana','circpass'],
                        permissions: [
                           { type: "item", name: "Aitana:Dashboards:Activos", active: true },
                       ],
                    },
                    {
                        id       : 'configuracion-alm',
                        title    : 'Configuración',
                        translate: 'NAV.DASHBOARDS.TRIAJE_DISPOSITIVOS',
                        type     : 'item',
                        url      : 'dashboards/triaje',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Dashboards:Triaje Dispositivos", active: true },
                        ],
                    },
                    {
                        id       : 'configuracion-alm',
                        title    : 'Configuración',
                        translate: 'NAV.DASHBOARDS.VISION_ARTIFICIAL_CLASIFICACION',
                        type     : 'item',
                        url      : 'dashboards/vision-artificial-clasificacion',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Dashboards:Vision Artificial", active: true },
                        ],
                    }
                ]
            }, 
            {
                id       : 'almacen',
                title    : 'Almacén',
                translate: 'NAV.ALMACEN.TITLE',
                type     : 'collapsable',
                libIcon  : 'fas',
                icon     : 'fa-boxes',
                environment: ['aitana', 'circpass'],
                permissions: [
                    { type: "item", name: "Aitana:Acceso Logistica Almacen", active: true },
                ],
                children : [
                    {
                        id       : 'configuracion-alm',
                        title    : 'Configuración',
                        translate: 'NAV.ALMACEN.CONFIGURACION.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana', 'circpass'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Stock-Almacen", active: true },
                        ],
                        children : [
                            {
                                id          : 'almacenes-conf',
                                title       : 'Almacenes',
                                translate   : 'NAV.ALMACEN.CONFIGURACION.ALMACENES',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/almacenes',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Almacenes", active: true },
                                ],
                                tags: ['NAV.TAGS.ALMACEN', 'NAV.TAGS.CONFIGURACION']
                            },
                            {
                                id          : 'almacenes-colaboradores',
                                title       : 'Amacenes - Colaboradores',
                                translate   : 'NAV.ALMACEN.CONFIGURACION.ALMACENES_COLABORADORES',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/almacenes-colaboradores',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Almacenes Colaboradores", active: true },
                                ],
                                tags: ['NAV.TAGS.ALMACEN', 'NAV.TAGS.COLABORADOR']
                            },
                            {
                                id          : 'almacenes-proyectos',
                                title       : 'Amacenes - Proyectos',
                                translate   : 'NAV.ALMACEN.CONFIGURACION.ALMACENES_PROYECTOS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/almacenes-proyectos',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Almacenes-Proyectos", active: true },
                                ],
                            },
                            {
                                id          : 'configurador-ubicaciones',
                                title       : 'Configurador Ubicaciones',
                                translate   : 'NAV.ALMACEN.CONFIGURACION.CONFIGURADOR_UBICACIONES',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/almacenes-ubicaciones-configuracion',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Configuracion Ubicaciones", active: true },
                                ],
                            },
                            {
                                id          : 'maestro-articulos',
                                title       : 'Maestro de Artículos',
                                translate   : 'NAV.ALMACEN.CONFIGURACION.MAESTRO_ARTICULOS',
                                type        : 'item',
                                url         : '/almacen/maestro-articulos',
                                environment: ['aitana', 'circpass'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Inventario", active: true },
                                ],
                            },
                            {
                                id          : 'subir-inventario',
                                title       : 'Carga de Inventario',
                                translate   : 'NAV.ALMACEN.CONFIGURACION.SUBIR_INVENTARIO',
                                type        : 'item',
                                url         : '/almacen/subir-inventario',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Inventario", active: true },
                                    { type: "item", name: "Aitana:Acceso Movimientos Articulos", active: true }
                                ],
                            },
                            // {
                            //     id          : 'inventario-planta',
                            //     title       : 'Inventario planta',
                            //     type        : 'item',
                            //     url         : '/almacenes-planta',
                            //     environment: ['aitana'],
                            //     permissions: [
                            //         { type: "item", name: "Aitana:Acceso Inventario", active: true },
                            //         { type: "item", name: "Aitana:Acceso Movimientos Articulos", active: true }
                            //     ],
                            // },
                        ]
                    },
                    {
                        id          : 'entrada-cajas',
                        title       : 'Entrada Cajas/Palés',
                        translate   : 'NAV.ALMACEN.ENTRADA_CAJAS',
                        type        : 'item',
                        url         : '/entrada-palecaja',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Gestión:Cajas", active: true },
                        ],
                    },
                    {
                        id          : 'entrega-envio-piezas',
                        title       : 'Entrega/Envío de piezas',
                        translate   : 'NAV.ALMACEN.ENTREGA_ENVIO_PIEZAS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/problema-diagnosis-incidencia/entrega',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Logistica:Entrega", active: true },
                        ],
                    },
                    {
                        id          : 'informe-stock-financiero',
                        title       : 'Informe Stock Financiero',
                        translate   : 'NAV.ALMACEN.INFORME_STOCK_FINANCIERO',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/almacenes-movimientos/informe-stock-financiero',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informe Stock Financiero", active: true },
                        ],
                    },
                    {
                        id          : 'movimientos-stock',
                        title       : 'Movimientos de stock',
                        translate   : 'NAV.ALMACEN.MOVIMIENTOS_STOCK',
                        type        : 'item',
                        url         : '/almacenes-movimientos',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Movimientos Articulos", active: true },
                        ],
                    },
                    {
                        id          : 'relacion-pn-sustitutos',
                        title       : 'Relación PN Sustitutos',
                        translate   : 'NAV.ALMACEN.RELACION_PN_SUSTITUTOS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/relacion-pn-sustituto/index',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Inventario", active: true },
                        ],
                    },
                    {
                        id          : 'stock-colaboradores',
                        title       : 'Stock Colaboradores',
                        translate   : 'NAV.ALMACEN.STOCK_COLABORADORES',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/almacenes-movimientos/stock-colaboradores',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Stock Partners", active: true },
                        ],
                    },
                    {
                        id          : 'stock-proyecto',
                        title       : 'Stock por Proyecto',
                        translate   : 'NAV.ALMACEN.STOCK_PROYECTO',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/almacenes-movimientos/stock-proyectos',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Movimientos Almacenes", active: true },
                        ],
                    },
                    {
                        id          : 'ver-stock-alm',
                        title       : 'Ver Stock',
                        translate   : 'NAV.ALMACEN.VER_STOCK',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/werb-services-sap/mostrar-almacen-sap',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Logistica: Consulta Sap Almacén", active: true },
                        ],
                    },
                ]
            },
            {
                id          : 'centro-conocimiento',
                title       : 'Centro de conocimiento',
                translate   : 'NAV.CENTRO_CONOCIMIENTO.TITLE',
                type        : 'item',
                icon        : 'collections_bookmark',
                url         : '/centro-conocimiento',
                environment: ['aitana', 'ewaste', 'circpass'],
                permissions: [
                    { type: "item", name: "Aitana:Recursos", active: true },
                    { 
                        type: "group",
                        op: "OR",
                        items: [
                            { type: "item", name: "Aitana:Acceso Lectura:Admin Video", active: false },
                            { type: "item", name: "Aitana:Acceso Lectura:Admin Documentacion Dispositivos", active: false },
                            { type: "item", name: "Aitana:Acceso Lectura:Admin Recursos Dispositivos", active: false },
                        ]
                    },
                ],
            },
            {
                id          : 'admin-centro-conocimiento',
                title       : 'Gestión Centro de conocimiento',
                translate   : 'NAV.CENTRO_CONOCIMIENTO.TITLE_GESTION',
                type        : 'collapsable',
                icon        : 'collections_bookmark',
                environment: ['aitana', 'ewaste', 'circpass'],
                permissions: [
                    { type: "item", name: "Aitana:Acceso Lectura:Admin Video", active: true },
                    { type: "item", name: "Aitana:Acceso Lectura:Admin Documentacion Dispositivos", active: true },
                    { type: "item", name: "Aitana:Acceso Lectura:Admin Recursos Dispositivos", active: true },
                ],
                children: [
                    {
                        id          : 'centro-conocimiento',
                        title       : 'Centro de conocimiento',
                        translate   : 'NAV.CENTRO_CONOCIMIENTO.TITLE',
                        type        : 'item',
                        url         : '/centro-conocimiento',
                        environment: ['aitana', 'ewaste', 'circpass'],
                        exactMatch  : true,
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Lectura:Admin Recursos Dispositivos", active: true },
                            { type: "item", name: "Aitana:Acceso Lectura:Admin Documentacion Dispositivos", active: true },
                        ],
                    },
                    {
                        id          : 'gestion-videos-documentos',
                        title       : 'Gestión videos y documentos',
                        translate   : 'NAV.CENTRO_CONOCIMIENTO.GESTION_VIDEOS_DOCUMENTOS',
                        type        : 'item',
                        url         : '/centro-conocimiento/gestion-videos-documentos',
                        exactMatch  : true,
                        environment: ['aitana', 'ewaste', 'circpass'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Lectura:Admin Video", active: true },
                            { type: "item", name: "Aitana:Acceso Lectura:Admin Documentacion Dispositivos", active: true },
                            { type: "item", name: "Aitana:Acceso Lectura:Admin Recursos Dispositivos", active: true },
                        ],
                    },
                ]
            },
            {
                id          : 'cajas',
                title       : 'Gestión Cajas',
                translate   : 'NAV.CONFIGURACION.GESTION_CAJAS',
                type        : 'item',
                url         : '/gestion-cajas',
                icon        : 'check_box_outline_blank',
                environment: ['aitana'],
                permissions: [
                    { type: "item", name: "Aitana:Perfil Cliente Unei Gestor", active: true },
                ],
            },
            {
                id       : 'checkin-checkout',
                title    : 'CheckIN - CheckOUT',
                translate: 'NAV.CHECKIN_CHECKOUT.TITLE',
                type     : 'collapsable',
                libIcon  : 'fas',
                icon     : 'fa-dolly-flatbed',
                environment: ['aitana', 'ewaste', 'circpass'],
                permissions: [
                    // { type: "item", name: "Aitana:Logistica:Checkin", active: true },
                    { 
                        type: "group",
                        op: "OR",
                        items: [
                            { type: "item", name: "Aitana:Logistica:Checkin", active: true },
                            { type: "item", name: "Aitana:Vision artificial CM Etiquetado", active: true },
                        ]
                    },
                ],
                children : [
                    {
                        id          : 'artis',
                        title       : 'ARTIS',
                        translate   : 'NAV.CHECKIN_CHECKOUT.ARTIS',
                        type        : 'collapsable',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Lectura:Artis", active: true },
                        ],
                        children    : [
                            {
                                id          : 'artis-grafica',
                                title       : 'Gráfica ARTIS',
                                translate   : 'NAV.CHECKIN_CHECKOUT.ARTIS_GRAFICA',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/artis/index',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Lectura:Artis", active: true },
                                ],
                            },
                            {
                                id          : 'artis-llegadas',
                                title       : 'ARTIS - Llegadas',
                                translate   : 'NAV.CHECKIN_CHECKOUT.ARTIS_LLEGADAS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/artis/index-llegadas',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Logistica:Artis", active: true },
                                ],
                            },
                        ]
                    },
                    {
                        id          : 'checkin-checkout',
                        title       : 'Check IN - Check OUT',
                        translate   : 'NAV.CHECKIN_CHECKOUT.CHECKIN_CHECKOUT',
                        type        : 'item',
                        url         : 'checkin/checkin-checkout',
                        environment: ['aitana', 'ewaste', 'circpass'],
                        permissions: [
                            { type: "item", name: "Aitana:Logistica:Checkin", active: true },
                        ],
                    },
                    {
                        id          : 'informe-checkin-checkout',
                        title       : 'Informe Check IN - Check OUT',
                        translate   : 'NAV.CHECKIN_CHECKOUT.INFORME_CHECKIN_CHECKOUT',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/checkin-checkout/informe-checkin-checkout',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informe Checkin Checkout", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                        ],
                    },
                    {
                        id          : 'check-in-tomar-imagenes',
                        title       : 'Toma de Imágenes',
                        translate   : 'NAV.CHECKIN_CHECKOUT.TOMA_IMAGENES',
                        type        : 'item',
                        url         : 'checkin/tomar-imagen',
                        environment: ['aitana'],
                    },
                    {
                        id          : 'tiempo-medio-entrada-laboratorio',
                        title       : 'Tiempo medio entrada laboratorio',
                        translate   : 'NAV.CHECKIN_CHECKOUT.TIEMPO_MEDIO_ENTRADA_LABORATORIO',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/tickets/tiempo-medio-entrada-en-lab',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informes KPIs", active: true },
                            { type: "item", name: "Aitana:Menu Acceso Informes KPIs", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                        ],
                    },
                    {
                        id          : 'cm-vision-artificial',
                        title       : 'Vision Artificial - Cuadro de mandos',
                        translate   : 'NAV.CHECKIN_CHECKOUT.VISION_ARTIFICIAL_CM',
                        type        : 'item',
                        url         : 'checkin/vision-artificial',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Vision artificial CM Etiquetado", active: true },
                        ],
                    },
                ]
            },
            {
                id       : 'configuracion',
                title    : 'Configuración',
                translate: 'NAV.CONFIGURACION.TITLE',
                type     : 'collapsable',
                libIcon  : 'fa',
                icon     : 'fa-cogs',
                environment: ['aitana'],
                permissions: [ 
                    { 
                        type: "group",
                        op: "OR",
                        items: [
                            { type: "item", name: "Aitana:Informes", active: true },
                            { type: "item", name: "Aitana:Gestion", active: true },
                            { type: "item", name: "Aitana: Menu: Contador Tiempo", active: true },
                            { type: "item", name: "Aitana:Perfil Cliente Unei Gestor", active: true },
                        ]
                    },

                    { 
                        type: "group",
                        op: "OR",
                        items: [
                            { type: "item", name: "Aitana:Acceso Lectura:Contrato", active: true },
                            { type: "item", name: "Aitana:Acceso Informes Seguimiento Monitorizacion", active: true },
                            { type: "item", name: "Aitana: Menu: Contador Tiempo", active: true },
                            { type: "item", name: "Aitana:Perfil Cliente Unei Gestor", active: true },
                        ]
                    },
                ],
                children : [
                    {
                        id          : 'usuarios',
                        title       : 'Usuarios',
                        translate   : 'Usuarios',
                        type        : 'item',
                        url         : 'usuarios',
                        icon        : 'people',
                        environment: ['aitana'],
                        permissions: [
                            { 
                                type: "group",
                                op: "OR",
                                items: [
                                    { type: "item", name: "superadmin", active: true },
                                    { type: "item", name: "Aitana:Perfil Cliente Unei Gestor", active: true },
                                ]
                            },
                        ],
                    },
                    {
                        id          : 'roles',
                        title       : 'Roles',
                        translate   : 'Roles',
                        type        : 'item',
                        url         : 'roles',
                        icon        : 'person_pin',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "superadmin", active: true },
                        ],
                    },
                    {
                        id          : 'contratos',
                        title       : 'Contratos',
                        translate   : 'NAV.CONFIGURACION.CONTRATOS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/contratos',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Lectura:Contrato", active: true },
                        ],
                    },
                    {
                        id       : 'control-actividad',
                        title    : 'Control de actividad',
                        translate: 'NAV.CONFIGURACION.CONTROL_ACTIVIDAD.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { 
                                type: "group",
                                op: "OR",
                                items: [
                                    { type: "item", name: "Aitana:Informes", active: true },
                                    { type: "item", name: "Aitana: Menu: Contador Tiempo", active: true },
                                ]
                            },
                        ],
                        children : [
                            {
                                id          : 'cuadro-mandos-monitorizacion',
                                title       : 'Cuadro de Mandos / Monitorización',
                                translate   : 'NAV.CONFIGURACION.CONTROL_ACTIVIDAD.CUADRO_MANDOS_MONITORIZACION',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/monitorizacion',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento Monitorizacion", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id          : 'informe-tiempo-usuario-proyecto',
                                title       : 'Informe Tiempo / Usuario / Proyecto',
                                translate   : 'NAV.CONFIGURACION.CONTROL_ACTIVIDAD.INFORME_TIEMPO_USUARIO_PROYECTO',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/control-actividad/informe-tiempo-usuario-proyecto',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana: Menu: Contador Tiempo", active: true },
                                ],
                            },
                            {
                                id          : 'log-acceso',
                                title       : 'Log de Acceso',
                                translate   : 'NAV.CONFIGURACION.CONTROL_ACTIVIDAD.LOG_ACCESO',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/user-log',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana: Menu: Contador Tiempo", active: true },
                                ],
                            },
                            {
                                id          : 'log-actividad',
                                title       : 'Log de Actividad',
                                translate   : 'NAV.CONFIGURACION.CONTROL_ACTIVIDAD.LOG_ACTIVIDAD',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/control-actividad/index',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana: Menu: Contador Tiempo", active: true },
                                ],
                            },
                        ]
                    },
                    {
                        id          : 'control-cambios',
                        title       : 'Control de cambios',
                        translate   : 'NAV.CONFIGURACION.CONTROL_DE_CAMBIOS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/control-cambios/index',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Informes:Control de Cambios", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                        ],
                    },
                    {
                        id          : 'sistema-alerta-temprana',
                        title       : 'Sistema de alerta temprana',
                        translate   : 'NAV.CONFIGURACION.SISTEMA_ALERTA_TEMPRANA',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/aitana-alerta-ns/index',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Gestión:Tickets", active: true },
                            { type: "item", name: "Aitana:Gestion:Alertas", active: true },
                        ],
                    },
                ]
            },
            {
                id          : 'digipass',
                title       : 'Digipass',
                translate   : 'NAV.DIGIPASS.TITLE',
                type     : 'collapsable',
                icon     : 'memory',
                environment: ['aitana','circpass'],
                permissions: [
                   { type: "item", name: "Aitana:Digipass", active: true },
                ],
                children : [
                   {
                        id       : 'digipass-dashboard-elemento',
                        title    : 'Dashboard',
                        type     : 'item',
                        url      : 'digipass/dashboard',
                        environment: ['aitana','circpass'],
                        permissions: [
                            { type: "item", name: "Aitana:Digipass", active: true },
                            { type: "item", name: "Aitana:Consulta Digipass", active: true },
                        ],
                   },
                   {
                        id       : 'digipass-consulta-elemento',
                        title    : 'Consulta',
                        translate: 'NAV.DIGIPASS.CONSULTA',
                        type     : 'item',
                        url      : 'digipass/consulta',
                        environment: ['aitana','circpass'],
                        permissions: [
                            { type: "item", name: "Aitana:Digipass", active: true },
                            { type: "item", name: "Aitana:Consulta Digipass", active: true },
                        ],
                   },
                ]
            }, 
            {
                id       : 'activos',
                title    : 'Activos',
                translate: 'NAV.GESTION_ACTIVOS.TITLE',
                type     : 'collapsable',
                icon     : 'stars',
                environment: ['aitana','circpass'],
                permissions: [
                    { type: "item", name: "Aitana: Gestion activos planta", active: true }
                ],
                children : [
                    {
                        id          : 'gestion-activos',
                        title       : 'Gestión',
                        translate   : 'NAV.GESTION_ACTIVOS.GESTION',
                        type        : 'item',
                        url         : '/contrato-planta',
                        environment: ['aitana','circpass'],
                        tags        : ['NAV.TAGS.ACTIVOS', 'NAV.TAGS.PLANTA']
                    },
                    {
                        id          : 'activos-log',
                        title       : 'LOG',
                        translate   : 'NAV.GESTION_ACTIVOS.LOG',
                        type        : 'item',
                        environment: ['aitana'],
                        url         : '/activos-log',
                    },
                    {
                        id          : 'activos-mapa',
                        title       : 'MAPA',
                        translate   : 'NAV.GESTION_ACTIVOS.MAPA',
                        type        : 'item',
                        environment: ['aitana','circpass'],
                        url         : '/activos-mapa',
                    }
                ]
            }, 
            {
                id       : 'tienda',
                title    : 'Tienda',
                translate: 'NAV.GESTION_TIENDA.TITLE',
                type     : 'collapsable',
                icon     : 'shop',
                environment: ['aitana'],
                permissions: [
                    { type: "item", name: "Aitana:Gestion", active: true },
                    { type: "item", name: "Aitana: Gestion tienda", active: true }
                ],
                children : [
                    {
                        id          : 'gestion-products-services',
                        title       : 'Productos y servicios',
                        translate   : 'NAV.GESTION_TIENDA.PRODUCTOS_Y_SERVICIOS',
                        type        : 'item',
                        url         : '/shop-items',
                        tags        : ['NAV.TAGS.TIENDA', 'NAV.TAGS.PRODUCTOS', 'NAV.TAGS.SERVICIOS']
                    },
                ]
            },
            {
                id       : 'gestion-proyecto',
                title    : 'Gestión de Proyecto',
                translate: 'NAV.GESTION_PROYECTO.TITLE',
                type     : 'collapsable',
                // libIcon  : 'fa',
                icon     : 'dvr',
                environment: ['aitana'],
                permissions: [
                    { 
                        type: "group",
                        op: "OR",
                        items: [
                            { type: "item", name: "Aitana:Menu Acceso Informes KPIs", active: true },
                            { type: "item", name: "Aitana:Informes", active: true },
                            { type: "item", name: "Aitana:Acceso Logistica Almacen", active: true },
                            { type: "item", name: "Aitana:Cuadro de Mandos", active: true },
                            { type: "item", name: "Aitana:Perfil Cliente Unei Gestor", active: true },
                            { type: "item", name: "Aitana: Perfil cliente Dedalus", active: false },
                        ]
                    },
                ],
                children : [
                    {
                        id       : 'ans-sla-kpis',
                        title    : 'ANS / SLA / KPIs',
                        translate: 'NAV.GESTION_PROYECTO.ANS_SLA_KPIS.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Menu Acceso Informes KPIs", active: true },

                            { 
                                type: "group",
                                op: "OR",
                                items: [
                                    { type: "item", name: "Aitana:Acceso Informes KPIs", active: true },
                                    { type: "item", name: "Aitana:Acceso Informes Estados TAT", active: true },
                                    { type: "item", name: "Aitana:Perfil Cliente Unei Gestor", active: true },
                                    {
                                        type: "group",
                                        op: "AND",
                                        items: [
                                            { type: "item", name: "Aitana:Acceso Informe Cumplimiento Piezas Enviadas", active: true },
                                            { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                        ]
                                    },

                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                ]
                            },
                        ],
                        children : [
                            {
                                id          : 'cumplimiento-citas-prevista-reales',
                                title       : 'Cumplimiento de citas (Previstas / Reales)',
                                translate   : 'NAV.GESTION_PROYECTO.ANS_SLA_KPIS.CUMPLIMIENTO_CITAS_PREVISTA_REALES',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/informe-citas-previstas-reales',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes KPIs", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id          : 'informe-cumplimiento-piezas-enviadas',
                                title       : 'Informe de Cumplimiento de Piezas Enviadas',
                                translate   : 'NAV.GESTION_PROYECTO.ANS_SLA_KPIS.INFORME_CUMPLIMIENTO_PIEZAS_ENVIADAS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/problema-diagnosis-incidencia/informe-cumplimiento-piezas-enviadas',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informe Cumplimiento Piezas Enviadas", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id          : 'informe-incumplimiento-causas-alegaciones',
                                title       : 'Informe de Incumplimientos/Causas/Alegaciones',
                                translate   : 'NAV.GESTION_PROYECTO.ANS_SLA_KPIS.INFORME_INCUMPLIMIENTOS_CAUSAS_ALEGACIONES',
                                type        : 'item',
                                url         : '/informes/kpi/incumplidas',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                ],
                            },
                            {
                                id       : 'informekpi',
                                title    : 'Informe KPI',
                                translate: 'NAV.GESTION_PROYECTO.ANS_SLA_KPIS.INFORME_KPI',
                                type     : 'item',
                                url      : '/informekpi',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes KPIs", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'rr-tecnico',
                                title       : 'RR / Técnico',
                                translate   : 'NAV.GESTION_PROYECTO.ANS_SLA_KPIS.RR_TECNICO',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/informe-rr-tecnico',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes KPIs", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id          : 'tat-grupo-asigando',
                                title       : 'TAT por Grupo Asignado',
                                translate   : 'NAV.GESTION_PROYECTO.ANS_SLA_KPIS.TAT_GRUPO_ASIGNADO',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/tat-grupo-asignado',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Estados TAT", active: true },
                                ],
                            },
                            {
                                id          : 'tiempo-medio-asignacion-tecnico',
                                title       : 'Tiempo medio asignación técnico',
                                translate   : 'NAV.GESTION_PROYECTO.ANS_SLA_KPIS.TIEMPO_MEDIO_ASIGNACION_TECNICO',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/tiempo-medio-asignacion-tecnicos',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes KPIs", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'tiempo-medio-reparacion',
                                title       : 'Tiempo medio reparación',
                                translate   : 'NAV.GESTION_PROYECTO.ANS_SLA_KPIS.TIEMPO_MEDIO_REPARACION',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/tiempo-reparacion',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes KPIs", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'tiempo-medio-respuesta-cliente',
                                title       : 'Tiempo medio respuesta cliente',
                                translate   : 'NAV.GESTION_PROYECTO.ANS_SLA_KPIS.TIEMPO_MEDIO_RESPUESTA_CLIENTE',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/informe-promedio-respuesta',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes KPIs", active: true },
                                ],
                            },
                        ]
                    },
                    {
                        id          : 'diario-seguimiento',
                        title       : 'Diario de seguimiento',
                        translate   : 'NAV.GESTION_PROYECTO.DIARIO_SEGUIMIENTO',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/entity/informe-diario-seguimiento',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                        ],
                    },
                    {
                        id          : 'estado-proyectos',
                        title       : 'Estado Proyectos',
                        translate   : 'NAV.GESTION_PROYECTO.ESTADO_PROYECTOS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/entity/estado-proyectos',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                            { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                            { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                            { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                        ],
                    },
                    {
                        id       : 'garantia10',
                        title    : 'Garantia 10',
                        translate: 'NAV.GESTION_PROYECTO.GARANTIA10',
                        type     : 'collapsable',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Garantia 10", active: true }
                        ],
                        children : [
                            {
                                id          : 'garantia10-proyectos',
                                title       : 'Proyectos',
                                translate   : 'NAV.GESTION_PROYECTO.PROYECTOS',
                                type        : 'item',
                                url         : '/garantia-10',
                                environment: ['aitana'],
                            },
                            {
                                id          : 'garantia10-solicitudes-cotizacion',
                                title       : 'Solicitudes cotización',
                                translate   : 'NAV.GESTION_PROYECTO.SOLICITUDESCOTIZACION',
                                type        : 'item',
                                url         : '/garantia-10/solicitudes-cotizacion',
                                environment: ['aitana'],
                            },
                        ],
                    },
                    {
                        id          : 'supply-chain',
                        title       : 'Supply Chain',
                        translate   : 'NAV.GESTION_PROYECTO.SUPPLY_CHAIN.TITLE',
                        type        : 'collapsable',
                        icon        : 'dashboard',
                        environment : ['circpass'],
                        permissions : [
                            { type: "item", name: "Aitana:Informe:SupplyChain", active: true },
                        ],
                        children : [
                            {
                                id          : 'supply-chain-aprovisionamiento',
                                title       : 'Aprovisionamiento',
                                translate   : 'NAV.GESTION_PROYECTO.SUPPLY_CHAIN.APROVISIONAMIENTO',
                                type        : 'item',
                                url         : '/informes/supply-chain/aprovisionamiento',
                                environment : ['circpass']
                            },  
                            {
                                id          : 'supply-chain-produccion',
                                title       : 'Producción',
                                translate   : 'NAV.GESTION_PROYECTO.SUPPLY_CHAIN.PRODUCCION',
                                type        : 'item',
                                url         : '/informes/supply-chain/produccion',
                                environment : ['circpass']
                            },  
                            {
                                id          : 'supply-chain-distribuicion',
                                title       : 'Distribución',
                                translate   : 'NAV.GESTION_PROYECTO.SUPPLY_CHAIN.DISTRIBUICION',
                                type        : 'item',
                                url         : '/informes/supply-chain/distribucion',
                                environment : ['circpass']
                            },  
                            {
                                id          : 'supply-chain-reciclaje',
                                title       : 'Reciclaje',
                                translate   : 'NAV.GESTION_PROYECTO.SUPPLY_CHAIN.RECLICAJE',
                                type        : 'item',
                                url         : '/informes/supply-chain/reciclaje',
                                environment : ['circpass']
                            }                     
                        ]
                    },
                    {
                        id       : 'informes',
                        title    : 'Informes',
                        translate: 'NAV.GESTION_PROYECTO.INFORMES.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { 
                                type: "group",
                                op: "OR",
                                items: [
                                    { type: "item", name: "Aitana:Menu Acceso Informes KPIs", active: true },
                                    { type: "item", name: "Aitana:Informes", active: true },
                                    { type: "item", name: "Aitana:Acceso Logistica", active: true },
                                    { type: "item", name: "Aitana:Perfil Cliente Unei Gestor", active: true },
                                ]
                            },
                        ],
                        children : [
                            {
                                id          : 'informe-evolucion-expediciones',
                                title       : 'Informe Evolución Expediciones',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.EVOLEXP',
                                type        : 'item',
                                url         : '/informes/evolucion-expediciones',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Perfil superAdmin", active: true },
                                ],
                            },
                            {
                                id          : 'informe-mttr',
                                title       : 'Informe MTTR',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.MTTR',
                                type        : 'item',
                                url         : '/informes/mttr',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Perfil superAdmin", active: true },
                                ],
                            },
                            // {
                            //     id          : 'informe-mtbf',
                            //     title       : 'Informe MTBF',
                            //     translate   : 'NAV.GESTION_PROYECTO.INFORMES.MTBF',
                            //     type        : 'item',
                            //     url         : '/informes/mtbf',
                            //     environment: ['aitana'],
                            //     permissions: [
                            //         { type: "item", name: "Aitana:Perfil superAdmin", active: true },
                            //     ],
                            // },
                            {
                                id          : 'informe-pareto',
                                title       : 'Informe Pareto',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.PARETO',
                                type        : 'item',
                                url         : '/informes/pareto',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Perfil superAdmin", active: true },
                                ],
                            },
                            {
                                id          : 'informe-tiempos-estados',
                                title       : 'Informe tiempos estados',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.TIEMPOS_ESTADOS',
                                type        : 'item',
                                url         : '/informes/tiempos-estados',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Perfil superAdmin", active: true },
                                ],
                            },
                            {
                                id          : 'informe-presupuestos-costes',
                                title       : 'Informe Presupuestos Costes',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.PPTOVSCOSTE',
                                type        : 'item',
                                url         : '/informes/presupuestos-costes',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Perfil superAdmin", active: true },
                                ],
                            },
                            {
                                id          : 'informe-tipo-servicio',
                                title       : 'Informe tipo servicio',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.TIPOS_SERVICIO',
                                type        : 'item',
                                url         : '/informes/tipos-servicio',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Perfil superAdmin", active: true },
                                ],
                            },
                            {
                                id          : 'informe-tiempos-grupos',
                                title       : 'Informe Tiempos Grupos',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.TIEMPOGRUPO',
                                type        : 'item',
                                url         : '/informes/tiempos-grupos',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informe tiempos por Grupo", active: true },
                                ],
                            },
                            {
                                id          : 'dashboard-dispositivo',
                                title       : 'Informe Unei Dispositivo',
                                translate   : 'Dashboard Unei-Dispositivo',
                                type        : 'item',
                                url         : '/informes/dashboard-dispositivo',
                                environment: ['aitana'],
                                permissions: [
                                    { 
                                        type: "group",
                                        op: "OR",
                                        items: [
                                            { type: "item", name: "Aitana:Permiso ver dashboard unei", active: true },
                                        ]
                                    },
                                ],
                            },
                            {
                                id          : 'kpis',
                                title       : 'Informes KPI',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.KPI',
                                type     : 'collapsable',
                                environment: ['aitana'],
                                permissions: [
                                    { 
                                        type: "group",
                                        op: "OR",
                                        items: [
                                            { type: "item", name: "Aitana:Menu Acceso Informes KPIs", active: true },
                                            { type: "item", name: "Aitana:Perfil Cliente Unei Gestor", active: true },
                                        ]
                                    },
                                ],
                                children : [
                                    {
                                        id          : 'indicadores',
                                        title       : 'Indicadores',
                                        translate   : 'NAV.GESTION_PROYECTO.INFORMES.INDICADORES',
                                        type        : 'item',
                                        url         : '/informes/kpi/indicadores',
                                        environment: ['aitana'],
                                        permissions: [
                                            { type: "item", name: "Aitana:Menu Acceso Informes KPIs", active: true },
                                        ],
                                    },
                                    {
                                        id          : 'unei',
                                        title       : 'Indicadores Unei',
                                        translate   : 'NAV.GESTION_PROYECTO.INFORMES.UNEI',
                                        type        : 'item',
                                        url         : '/informes/kpi/indicadores-unei',
                                        environment: ['aitana'],
                                        permissions: [
                                            { type: "item", name: "Aitana:Perfil Cliente Unei Gestor", active: true },
                                        ],
                                    }
                                ]
                            },
                            {
                                id          : 'tickets-cr',
                                title       : 'Tickets C(r)',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.TICKETS_CR',
                                type     : 'collapsable',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true }
                                ],
                                children : [
                                    {
                                        id       : 'informe-ticket-por-proyecto-mes',
                                        title    : 'Por proyecto y mes',
                                        translate: 'NAV.GESTION_PROYECTO.INFORMES.TICKETS_POR_PROYECTO_MES',
                                        type     : 'item',
                                        url      : '/informes/tickets-cr/proyectos-mes',
                                        environment: ['aitana'],
                                    },
                                    {
                                        id       : 'informe-ticket-marca-modelo',
                                        title    : 'Por marca / modelo',
                                        translate: 'NAV.GESTION_PROYECTO.INFORMES.TICKETS_POR_MARCA_MODELO',
                                        type     : 'item',
                                        url      : '/informes/tickets-cr/marca-modelo',
                                        environment: ['aitana'],
                                    },
                                    {
                                        id       : 'informe-ticket-top-piezas',
                                        title    : 'TOP Piezas',
                                        translate: 'NAV.GESTION_PROYECTO.INFORMES.TOP_PIEZAS',
                                        type     : 'item',
                                        url      : '/informes/tickets-cr/top-piezas',
                                        environment: ['aitana'],
                                    },
                                ]
                            },
                            {
                                id          : 'reparabilidad',
                                title       : 'Reparabilidad',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.REPARABILIDAD',
                                type        : 'item',
                                url         : '/informes/reparabilidad',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Reparaciones", active: true },
                                ],
                            },
                            {
                                id          : 'calidad-reparacion',
                                title       : 'Calidad reparación',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.CALIDAD_REPARACION',
                                type        : 'item',
                                url         : '/informes/calidad-reparacion',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Reparaciones", active: true },
                                ],
                            },
                            {
                                id          : 'bugs',
                                title       : 'Bugs',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.BUGS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/seguimientos/informe-bugs',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informe Bugs Seguimientos", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id          : 'bugs-seguimientos-usuario-proyectos',
                                title       : 'Bugs Seguimientos / Usuario / Proyecto',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.BUGS_SEGUIMIENTOS_USUARIO_PROYECTO',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/seguimientos/informe-bugs-usuarios',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informe Bugs Seguimientos", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id          : 'crear-informe-sql',
                                title       : 'Crear informe vía SQL',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.CREAR_INFORME_VIA_SQL',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/sql/index',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Informes:Informes SQL", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'cuadro-mandos-sla-tickets-bugs',
                                title       : 'Cuadro Mandos / Tickets - SLA - Bugs',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.CUADRO_MANDOS_TICKETS_SLA_BUGS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/tickets-sla-bugs',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informe Tickets SLAs Bugs", active: true },
                                ],
                            },
                            {
                                id          : 'detalle-paradas',
                                title       : 'Detalle Paradas',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.DETALLE_PARADAS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets-paradas/informe-paradas-detalle',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informe Paradas", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id          : 'log-comunicaciones-cliente',
                                title       : 'Log comunicaciones con cliente',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.LOG_COMUNICACIONES_CLIENTE',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/historico-comunicaciones',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana: Acceso Historial Comunicaciones", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id       : 'metricas-economicas',
                                title    : 'Metricas Economico Financieras',
                                translate: 'NAV.GESTION_PROYECTO.INFORMES.METRICAS_ECONOMICAS',
                                type     : 'item',
                                url      : '/metricas-economicas',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id       : 'metricas-iwoow',
                                title    : 'Metricas Economico Financieras IW/OWW',
                                translate: 'NAV.GESTION_PROYECTO.INFORMES.METRICAS_IWOOW',
                                type     : 'item',
                                url      : '/metricas-iwoow',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'num-piezas-servicio',
                                title       : 'Nº piezas / servicio',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.NUM_PIEZAS_SERVICIO',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/resumen-tickets-rma',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id          : 'num-seguimientos-proyecto',
                                title       : 'Nº seguimientos / proyecto',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.NUM_SEGUIMIENTOS_PROYECTO',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/seguimientos-por-proyecto',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            /*{
                                id       : 'metricas',
                                title    : 'Metricas',
                                translate: 'NAV.GESTION_PROYECTO.INFORMES.METRICAS_MYFIXPERT',
                                type     : 'item',
                                url      : '/metricas',
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                ],
                            },*/
                            {
                                id          : 'paradas',
                                title       : 'Paradas',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.PARADAS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets-paradas/informe-paradas',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informe Paradas", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id          : 'piezas-diagnosis',
                                title       : 'Piezas diagnosis',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.PIEZAS_DIAGNOSIS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/informe-piezas-implicadas',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                ],
                            },
                            {
                                id          : 'piezas-proyecto-porcentaje',
                                title       : '% Piezas por Proyecto',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.PIEZAS_PROYECTO_PORCENTAJE',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/piezas-por-proyecto',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },

                            {
                                id          : 'pxq-manobra',
                                title       : 'PxQ Mano de obra',
                                translate   : 'NAV.GESTION_PROYECTO.INFORMES.PXQ_MANOBRA',
                                type        : 'item',
                                url         : '/tickets/pxq-manobra',
                                environment: ['aitana'],
                                permissions: [
                                     { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                     { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },

                            {
                                id       : 'informe-productividad-lab',
                                title    : 'Informe Productividad LAB',
                                translate: 'NAV.GESTION_PROYECTO.INFORMES.INFORME_PROD_LAB',
                                type     : 'item',
                                url      : '/informes/informe-productividad-lab',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Tecnicos", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },

                            {
                                id       : 'informe-operaciones-tecnicos',
                                title    : 'Informe operaciones Técnicos Propios',
                                translate: 'NAV.GESTION_PROYECTO.INFORMES.INFORME_OPERACIONES',
                                type     : 'item',
                                url      : '/informes/informe-operaciones-tecnicos',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Tecnicos", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },

                            {
                                id       : 'informe-piezas-rma',
                                title    : 'Informe piezas por RMA',
                                translate: 'NAV.GESTION_PROYECTO.INFORMES.INFORME_PIEZAS_RMA',
                                type     : 'item',
                                url      : '/informes/piezas-rma',
                                permissions: [
                                    { type: "item", name: "Aitana:Informes", active: true },
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },

                            {
                                id       : 'pxq-partners',
                                title    : 'PxQ Partners',
                                translate: 'NAV.GESTION_PROYECTO.INFORMES.PXQ_PARTNERS',
                                type     : 'item',
                                url      : '/informes/pxq',
                                permissions: [
                                    { type: "item", name: "Aitana:Informes", active: true },
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },

                            {
                                id       : 'productividad-tecnicos-propios',
                                title    : 'Productividad técnicos propios',
                                translate: 'NAV.GESTION_PROYECTO.INFORMES.PRODUCTIVIDAD_TECNICOS_PROPIOS',
                                type     : 'item',
                                url      : '/informes/productividad-tenico',
                                permissions: [
                                    { type: "item", name: "Aitana:Informes", active: true },
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                        ]
                    },                    
                    {
                        id          : 'intercambio-elementos',
                        title       : 'Intercambio elementos',
                        translate   : 'NAV.GESTION_PROYECTO.INTERCAMBIO_ELEMENTOS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/entity/estado-proyectos',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                            { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                            { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                            { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                        ],
                    },
                    {
                        id       : 'servicios',
                        title    : 'Servicios',
                        translate: 'NAV.GESTION_PROYECTO.SERVICIOS.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                        ],
                        children : [
                            {
                                id          : 'citas-x-tickets-cr',
                                title       : 'Citas X Tickets C(R)',
                                translate   : 'NAV.GESTION_PROYECTO.SERVICIOS.CITAS_POR_TICKETS_CR',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/citas-por-ticket',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id          : 'servicios-cliente-solicitante',
                                title       : 'Servicios / Cliente solicitante',
                                translate   : 'NAV.GESTION_PROYECTO.SERVICIOS.SERVICIOS_CLIENTE_SOLICITANTE',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/tickets-por-cliente',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'servicios-marca',
                                title       : 'Servicios / Marca',
                                translate   : 'NAV.GESTION_PROYECTO.SERVICIOS.SERVICIOS_MARCA',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/tickets-por-marca',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'servicios-modelo',
                                title       : 'Servicios / Modelo',
                                translate   : 'NAV.GESTION_PROYECTO.SERVICIOS.SERVICIOS_MODELO',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/tickets-por-modelo',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'servicios-provincia',
                                title       : 'Servicios / Provincia',
                                translate   : 'NAV.GESTION_PROYECTO.SERVICIOS.SERVICIOS_PROVINCIA',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/tickets-por-provincia-es',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                ],
                            },
                            {
                                id          : 'top-partners',
                                title       : 'TOP Partners',
                                translate   : 'NAV.GESTION_PROYECTO.SERVICIOS.TOP_PARTNERS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/informe-partners-experimentados',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id          : 'top-piezas',
                                title       : 'TOP Piezas',
                                translate   : 'NAV.GESTION_PROYECTO.SERVICIOS.TOP_PIEZAS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/informe-piezas-utilizadas',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'top-tecnicos-propios',
                                title       : 'TOP Técnicos Propios',
                                translate   : 'NAV.GESTION_PROYECTO.SERVICIOS.TOP_TECNICOS_PROPIOS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/informe-tecnicos-propios-experimentados',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'x-provincia-pt-serv',
                                title       : 'X Provincia PT',
                                translate   : 'NAV.GESTION_PROYECTO.SERVICIOS.X_PROVINCIA_PT',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/tickets-por-provincia-pt',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'x-proyecto-mes-serv',
                                title       : 'X Proyecto / Mes',
                                translate   : 'NAV.GESTION_PROYECTO.SERVICIOS.X_PROYECTO_MES',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/tickets-por-proyecto',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'x-proyecto-provinica-mes-serv',
                                title       : 'X Proyecto / Provincia / Mes',
                                translate   : 'NAV.GESTION_PROYECTO.SERVICIOS.X_PROYECTO_PROVINCIA_MES',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/tickets-por-proyecto-provincia',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    
                                ],
                            },
                            {
                                id          : 'x-zona-serv',
                                title       : 'X Zona',
                                translate   : 'NAV.GESTION_PROYECTO.SERVICIOS.X_ZONA',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/tickets-por-zona',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                        ]
                    },
                    {
                        id          : 'tendencia',
                        title       : 'Tendencia',
                        translate   : 'NAV.GESTION_PROYECTO.TENDENCIA',
                        type        : 'item',
                        url         : '/tendencia/tendencia-agrupados',
                       // url         : environment.linkNewAitana + '/tickets/resumen-tendencia',
                       // externalUrl : true,
                       // openInIframe: true,
                       environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Cuadro de Mandos:Tendencia", active: true },
                        ],
                    },
                    {
                        id          : 'tickets-abiertos',
                        title       : 'Tickets Abiertos',
                        translate   : 'NAV.GESTION_PROYECTO.TICKETS_ABIERTOS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/tickets/cm-abiertos',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Cuadro de Mandos:Tickets por Estados", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                            { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                            { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                        ],
                    },
                ]
            },
            {
                id       : 'gestion-servicio',
                title    : 'Gestión del Servicio',
                translate: 'NAV.GESTION_SERVICIO.TITLE',
                type     : 'collapsable',
                //libIcon  : 'fa',
                icon     : 'important_devices',
                environment: ['aitana','circpass'],
                permissions: [
                    { 
                        type: "group",
                        op: "OR",
                        items: [
                            { type: "item", name: "Aitana:Gestion", active: true },
                            { type: "item", name: "Aitana:Informes", active: true },
                            { type: "item", name: "Aitana:Recursos", active: true },
                        ]
                    },
                ],
                children : [
                    {
                        id          : 'dashboard',
                        title       : 'Dashboard',
                        translate   : 'NAV.DASHBOARDS.TITLE',
                        type        : 'item',
                        url         : 'dashboards/gestion-servicio',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Gestión:Dashboard", active: true },
                        ],
                    },
                    { 
                        id          : 'certificaciones',
                        title       : 'Certifcaciones',
                        translate   : 'NAV.GESTION_SERVICIO.CERTIFICACIONES',
                        type        : 'item',
                        url         : 'certificaciones',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Gestion:Control Certificaciones Financiero", active: true },
                        ],
                    },
                    { 
                        id          : 'citas',
                        title       : 'Citas',
                        translate   : 'NAV.GESTION_SERVICIO.CITAS',
                        type        : 'item',
                        url         : 'citas',
                        environment: ['aitana', 'circpass'],
                        permissions: [
                            { type: "item", name: "Aitana:Gestión:Citas", active: true },
                        ],
                    },
                    { 
                        id          : 'clientes',
                        title       : 'Clientes',
                        translate   : 'NAV.GESTION_SERVICIO.CLIENTES',
                        type        : 'item',
                        url         : 'clientes',
                        environment: ['aitana', 'circpass'],
                        permissions: [
                            { type: "item", name: "Aitana:Gestión:Clientes", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                            { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                            { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                        ],
                    },
                    { 
                        id          : 'compras',
                        title       : 'Compras',
                        translate   : 'NAV.GESTION_SERVICIO.COMPRAS',
                        type        : 'item',
                        url         : 'recursos-compras',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Recursos:Compras", active: true },
                        ],
                    },
                    { 
                        id          : 'finacieros',
                        title       : 'Financieros',
                        translate   : 'NAV.GESTION_SERVICIO.FINANCIEROS',
                        type        : 'item',
                        url         : 'financieros',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Gestion:Control Proveedor Financiero", active: true },
                        ],
                    },
                    {
                        id          : 'presupuestos',
                        title       : 'Presupuestos',
                        translate   : 'NAV.GESTION_SERVICIO.PRESUPUESTOS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/entity/informe-presupuestos',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informes Seguimiento: Presupuestos", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                            { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                            { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                            { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                        ],
                    },
                    {
                        id          : 'proveedores',
                        title       : 'Proveedores',
                        translate   : 'NAV.GESTION_SERVICIO.PROVEEDORES',
                        type        : 'item',
                        url         : 'proveedores',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Proveedores Netsuite", active: true },
                        ],
                    },
                    {
                        id          : 'seguimiento-rutas',
                        title       : 'Seguimiento de rutas',
                        translate   : 'NAV.GESTION_SERVICIO.SEGUIMIENTO_RUTAS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/citas/planning',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Gestión:Seguimiento de rutas", active: true },
                        ],
                    },
                    {
                        id       : 'tickets',
                        title    : 'Tickets',
                        translate: 'NAV.GESTION_SERVICIO.TICKETS.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana','circpass'],
                        permissions: [
                            { 
                                type: "group",
                                op: "OR",
                                items: [
                                    { type: "item", name: "Aitana:Gestión:Tickets", active: true },
                                    { type: "item", name: "Aitana:Cuadro de Mandos", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ]
                            },
                        ],
                        children : [
                            {
                                id          : 'crear_ticket',
                                title       : 'Crear ticket',
                                translate   : 'NAV.GESTION_SERVICIO.TICKETS.CREAR_TICKET',
                                type        : 'item',
                                url         : 'tickets/gestion-nuevo-ticket',
                                exactMatch  : true,
                                environment: ['aitana','circpass'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Escritura:Tickets", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'crear_ticket_callcenter',
                                title       : 'Crear ticket (Call Center)',
                                translate   : 'NAV.GESTION_SERVICIO.TICKETS.CREAR_TICKET_CALLCENTER',
                                type        : 'item',
                                url         : 'tickets/gestion-nuevo-ticket/call-center',
                                exactMatch  : true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Escritura:Tickets", active: true },
                                ],
                            },
                            {
                                id          : 'crear_tickets_lote',
                                title       : 'Crear tickets (por lotes)',
                                translate   : 'NAV.GESTION_SERVICIO.TICKETS.CREAR_TICKETS_LOTE',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/create-tickets-excel',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana','circpass'],
                                permissions: [
                                    { type: "item", name: "Aitana: Tickets: Creacion Lotes", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'duplicar_ticket',
                                title       : 'Duplicar ticket',
                                translate   : 'NAV.GESTION_SERVICIO.TICKETS.DUPLICAR_TICKET',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/duplicar-tickets',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana: Permiso Duplicar Tickets", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            {
                                id          : 'ver_tickets',
                                title       : 'Ver tickets',
                                translate   : 'NAV.GESTION_SERVICIO.TICKETS.VER_TICKETS',
                                type        : 'item',
                                url         : 'tickets',
                                exactMatch  : true,
                                environment: ['aitana','circpass'],
                                permissions: [
                                    { type: "item", name: "Aitana:Gestión:Tickets:Ver Ticket", active: true },
                                ],
                            },
                            {
                                id          : 'buscador',
                                title       : 'Buscar NS',
                                translate   : 'NAV.GESTION_SERVICIO.BUSCADOR_NS',
                           //     icon        : 'find replace icon',
                                type        : 'item',
                                url         : 'tickets/buscador',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Perfil Cliente Unei técnico (oculto)", active: true },
                                ],
                            },
                            {
                                id          : 'tickets-estado',
                                title       : 'Historico estados',
                                translate   : 'NAV.GESTION_SERVICIO.HISTORICO_ESTADOS',
                                type        : 'item',
                                url         : 'tickets-estado',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Perfil Cliente Unei Gestor", active: true },
                                ],
                            },

                            // {
                            //     id          : 'ver_tickets_agrupdados',
                            //     title       : 'Ver tickets agrupados',
                            //     translate   : 'NAV.GESTION_SERVICIO.TICKETS.VER_TICKETS_AGRUPADOS',
                            //     type        : 'item',
                            //     url         : environment.linkNewAitana + '/tickets/resumen-grupos',
                            //     externalUrl : true,
                            //     openInIframe: true,
                            //     permissions: [
                            //         { type: "item", name: "Aitana:Cuadro de Mandos:Tickets por Grupos", active: true },
                            //     ],
                            // },
                            {
                                id          : 'ver_tickets_agrupados',
                                title       : 'Ver tickets agrupados',
                                translate   : 'NAV.GESTION_SERVICIO.TICKETS.VER_TICKETS_AGRUPADOS',
                                type        : 'item',
                                url         : '/cuadro-de-mandos/cm-tickets-agrupados',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Cuadro de Mandos:Tickets por Grupos", active: true },
                                ],
                            },
                            
                        ]
                    },
                    {
                        id          : 'cci',
                        title       : 'Tarjetas Vodafone',
                        translate   : 'NAV.GESTION_SERVICIO.TARJETA_VODAFONE',
                        type        : 'item',
                        url         : '/gestion-cci',
                        icon        : 'sim_card_outline_blank',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Perfil Cliente Unei Gestor", active: true },
                        ],
                    }

                    
                ]
            },
            {
                id       : 'gestion-economica',
                title    : 'Gestión Económica',
                translate: 'NAV.GESTION_ECONOMICA.TITLE',
                type     : 'collapsable',
                libIcon  : 'fas',
                icon     : 'fa-credit-card',
                environment: ['aitana'],
                permissions: [
                    { 
                        type: "group",
                        op: "OR",
                        items: [
                            { type: "item", name: "Aitana:Gestion", active: true },
                            { type: "item", name: "Aitana:Informes", active: true },
                            { type: "item", name: "Aitana:Recursos", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                            { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                            { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                        ]
                    },
                    { 
                        type: "group",
                        op: "OR",
                        items: [
                            { type: "item", name: "Aitana:Autofacturacion", active: true },
                            { type: "item", name: "Aitana:Acceso Gestion:Control Proveedor Financiero", active: true },
                            { type: "item", name: "Aitana:Acceso Provisiones", active: true },
                            { type: "item", name: "Aitana:Recursos:Rentabilidad", active: true },
                            { type: "item", name: "Aitana: Gestion Subastas", active: true },

                            { 
                                type: "group",
                                op: "AND",
                                items: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ]
                            },

                            { 
                                type: "group",
                                op: "AND",
                                items: [
                                    { type: "item", name: "Aitana: Gestion: Permite autorizar partes", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ]
                            },
                        ]
                    },
                ],
                children: [
                    {
                        id          : 'autofacturacion',
                        title       : 'Autofacturación',
                        translate   : 'NAV.GESTION_ECONOMICA.AUTOFACTURACION',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/autofacturacion',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Autofacturacion", active: true },
                        ],
                    },
                    {
                        id          : 'control-financiero-proveedores',
                        title       : 'Control Financieros Proveedores',
                        translate   : 'NAV.GESTION_ECONOMICA.CONTROL_FINANCIEROS_PROVEEDORES',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/financiero/index',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Gestion:Control Proveedor Financiero", active: true },
                        ],
                    },
                    {
                        id       : 'costes',
                        title    : 'Costes',
                        translate: 'NAV.GESTION_ECONOMICA.COSTES.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                        ],
                        children : [
                            {
                                id          : 'importe-total',
                                title       : 'Importe Total',
                                translate   : 'NAV.GESTION_ECONOMICA.COSTES.IMPORTE_TOTAL',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/gasto-por-proyecto',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                ],
                            },
                            {
                                id          : 'importe-total-porcentaje',
                                title       : 'Importe Total (%)',
                                translate   : 'NAV.GESTION_ECONOMICA.COSTES.IMPORTE_TOTAL_PORCENTAJE',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/gasto-por-proyecto-porcentajes',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                ],
                            },
                            {
                                id          : 'media-proyectos',
                                title       : 'Media por proyectos',
                                translate   : 'NAV.GESTION_ECONOMICA.COSTES.MEDIA_PROYECTOS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/gasto-por-ticket',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                ],
                            },
                            {
                                id          : 'media-provincia',
                                title       : 'Media por provincias',
                                translate   : 'NAV.GESTION_ECONOMICA.COSTES.MEDIA_PROVINCIAS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/gasto-por-ticket-por-provincia',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                ],
                            },
                            {
                                id          : 'tickets-totales',
                                title       : 'Tickets Totales',
                                translate   : 'NAV.GESTION_ECONOMICA.COSTES.TICKET_TOTALES',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/tickets-totales',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                ],
                            },
                            {
                                id          : 'tickets-totales-porcentaje',
                                title       : 'Tickets Totales (%)',
                                translate   : 'NAV.GESTION_ECONOMICA.COSTES.TICKET_TOTALES_PORCENTAJE',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/gasto-por-ticket-porcentajes',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                ],
                            },
                        ]
                    },
                    {
                        id          : 'informe-financieros',
                        title       : 'Informe Finacieros',
                        translate   : 'NAV.GESTION_ECONOMICA.INFORME_FINANCIEROS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/entity/informe-financieros',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                        ],
                    },
                    {
                        id          : 'partes-autorizacion',
                        title       : 'Partes - Autorización',
                        translate   : 'NAV.GESTION_ECONOMICA.PARTES_AUTORIZACION',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/tickets/partes-autorizacion',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana: Gestion: Permite autorizar partes", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                        ],
                    },
                    
                    {
                        id          : 'provisiones',
                        title       : 'Provisiones',
                        translate   : 'NAV.GESTION_ECONOMICA.PROVISIONES.TITLE',
                        type        : 'collapsable',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Provisiones", active: true },
                        ],
                        children    : [
                            {
                                id          : 'informe-pedidos',
                                title       : 'Informe de pedidos',
                                translate   : 'NAV.GESTION_ECONOMICA.PROVISIONES.INFORME_PEDIDOS',
                                type        : 'item',
                                url         : 'provisiones/cm-pedidos',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Provisiones", active: true },
                                ],
                            },
                            {
                                id          : 'provisiones-ofertas',
                                title       : 'Ofertas',
                                translate   : 'NAV.GESTION_ECONOMICA.PROVISIONES.OFERTAS',
                                type        : 'item',
                                url         : 'provisiones/ofertas',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Provisiones", active: true },
                                ],
                            },
                            {
                                id          : 'provisiones-pedidos',
                                title       : 'Pedidos',
                                translate   : 'NAV.GESTION_ECONOMICA.PROVISIONES.PEDIDOS',
                                type        : 'item',
                                url         : 'provisiones/pedidos',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Provisiones", active: true },
                                ],
                            },
                        ]
                    },
                    {
                        id          : 'rentabilidad',
                        title       : 'Rentabilidad',
                        translate   : 'NAV.GESTION_ECONOMICA.RENTABILIDAD',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/entity/rentabilidad',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Recursos:Rentabilidad", active: true },
                        ],
                    },
                    {
                        id          : 'subastas',
                        title       : 'Subastas',
                        translate   : 'NAV.GESTION_ECONOMICA.SUBASTAS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/subastable/gestion-subastas',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana: Gestion Subastas", active: true },
                        ],
                    },
                ]
            },
            {
                id       : 'informes',
                title    : 'Informes',
                translate: 'NAV.INFORMES.TITLE',
                type     : 'collapsable',
                libIcon  : 'fas',
                icon     : 'fa-list-alt',
                environment: ['ewaste', 'circpass'],
                permissions: [
                    { type: "item", name: "Aitana:Informes:Residuos", active: true },
                ],
                children: [
                    {
                        id       : 'residuos',
                        title    : 'Residuos',
                        translate: 'NAV.INFORMES.RESIDUOS.TITLE',
                        type     : 'collapsable',
                        environment: ['ewaste', 'circpass'],
                        permissions: [
                            { type: "item", name: "Aitana:Informes:Residuos", active: true },
                        ],
                        children : [
                            {
                                id          : 'historico-archivo-cronologico',
                                title       : 'Histórico. Archivo Cronológico',
                                translate   : 'NAV.INFORMES.RESIDUOS.HISTORICO_ARCHIVO_CRONOLOGICO',
                                type        : 'item',
                                url         : 'informes/residuos/historico',
                                environment: ['ewaste', 'circpass'],
                                permissions: [
                                    { type: "item", name: "Aitana:Informes:Residuos", active: true },
                                ],
                            },
                        ]
                    },
                    {
                        id       : 'log-movimientos-checkin',
                        title    : 'Movimientos',
                        translate: 'NAV.INFORMES.LOG_MOVIMIENTOS.TITLE',
                        type     : 'item',
                        url      : 'informes/log-movimientos',
                        environment: ['ewaste', 'circpass'],
                        permissions: [
                            { type: "item", name: "Aitana:Informes:Residuos", active: true },
                        ],
                    },
                ]
            },
            {
                id       : 'integraciones',
                title    : 'Integraciones',
                translate: 'NAV.INTEGRACIONES.TITLE',
                type     : 'collapsable',
                icon     : 'widgets',
                environment: ['aitana'],
                permissions: [
                    { type: "item", name: "Aitana:Acceso Integraciones", active: true },
                    /*{ 
                        type: "group",
                        op: "OR",
                        items: [
                            { type: "item", name: "Aitana:Acceso Integraciones", active: true },
                            { type: "item", name: "Aitana:Informes", active: true },
                            { type: "item", name: "Aitana:Acceso Logistica", active: true },
                        ]
                    },*/
                ],
                children : [
                    {
                        id       : 'lenovo',
                        title    : 'Lenovo',
                        translate: 'NAV.INTEGRACIONES.LENOVO.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Integracion Lenovo", active: true },
                        ],
                        children : [
                            {
                                id          : 'import-export-file-batch-lenovo',
                                title       : 'Import/Export file batch Lenovo',
                                translate   : 'NAV.INTEGRACIONES.LENOVO.IMPORT_EXPORT_FILE_BATCH_LENOVO',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/importar-exportar-lenovo',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana: Permite importar/exportar casos lenovo", active: true },
                                ],
                            },
                        ]
                    },
                    {
                        id       : 'mapfre',
                        title    : 'Mapfre',
                        translate: 'NAV.INTEGRACIONES.MAPFRE.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Informes", active: true },
                        ],
                        children : [
                            {
                                id          : 'informe-incidencias-mapfre',
                                title       : 'Informe Incidencias Mapfre',
                                translate   : 'NAV.INTEGRACIONES.MAPFRE.INFORME_INCIDENCIAS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/tickets/informe-incidencias-mapfre',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informe Incidencias Mapfre", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                        ]
                    },
                    {
                        id       : 'samsung',
                        title    : 'Samsung',
                        translate: 'NAV.INTEGRACIONES.SAMSUNG.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { 
                                type: "group",
                                op: "OR",
                                items: [
                                    { type: "item", name: "Aitana:Menu Acceso Informes KPIs", active: true },
                                    { type: "item", name: "Aitana:Acceso Logistica", active: true },
                                ]
                            },
                        ],
                        children : [
                            {
                                id          : 'informe-lte',
                                title       : 'Informe Long Term Pending',
                                translate   : 'NAV.INTEGRACIONES.SAMSUNG.INFORME_LTE',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/informe-ltp',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes KPIs", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id          : 'samsung-gi',
                                title       : 'Samsung - G/I',
                                translate   : 'NAV.INTEGRACIONES.SAMSUNG.SAMSUNG_GI',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/problema-diagnosis-incidencia/indexgi',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Logistica: Samsung - G/I", active: true },
                                ],
                            },
                        ]
                    },
                    {
                        id       : 'toshiba',
                        title    : 'Toshiba',
                        translate: 'NAV.INTEGRACIONES.TOSHIBA.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Integracion Toshiba", active: true },
                        ],
                        children : [
                            {
                                id          : 'importar-casos-wl',
                                title       : 'Importar casos desde WL',
                                translate   : 'NAV.INTEGRACIONES.TOSHIBA.IMPORTAR_CASOS_DESDE_WL',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/integracion-toshiba/importar-casos-weblord',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Permite crear caso weblord", active: true },
                                ],
                            },
                        ]
                    },
                ]
            },
            {
                id       : 'laboratorio',
                title    : 'Laboratorio',
                translate: 'NAV.LABORATORIO.TITLE',
                type     : 'collapsable',
                libIcon  : 'fa',
                icon     : 'fa-flask',
                environment: ['aitana'],
                permissions: [
                    { type: "item", name: "Aitana:Acceso Laboratorio", active: true },
                ],
                children : [
                    {
                        id          : 'panel-control-laboratorio',
                        title       : 'Panel Control Laboratorio',
                        translate   : 'NAV.LABORATORIO.PANEL_CONTROL_LABORATORIO',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/tickets/panel-control-lab',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Panel Control Laboratorio", active: true },
                        ],
                    },
                    {
                        id          : 'calidad-reparacion',
                        title       : 'Calidad Reparacion',
                        translate   : 'NAV.LABORATORIO.CALIDAD_REPARACION',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/tickets/informe-calidad-reparacion',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informes Reparaciones", active: true },
                        ],
                    },
                    {
                        id       : 'diagnosis',
                        title    : 'Diagnosis',
                        translate: 'NAV.LABORATORIO.DIAGNOSIS.TITLE',
                        type     : 'collapsable',
                        icon     : 'assessment',
                        hidden   : false,
                        environment: ['aitana'],
                        // permissions: [
                        //     { type: "item", name: "AdminNuevaAitana", active: true },
                        // ],
                        children : [
                            {
                                id          : 'nueva-diagnosis',
                                title       : 'Nueva diagnosis',
                                translate   : 'NAV.LABORATORIO.DIAGNOSIS.NUEVA_DIAGNOSIS',
                                type        : 'item',
                                url         : 'diagnosis/nueva-diagnosis',
                                environment: ['aitana'],
                                // permissions: [
                                //     { type: "item", name: "AdminNuevaAitana", active: true },
                                // ],
                            }
                        ]
                    },
                    {
                        id       : 'productividad-lab',
                        title    : 'Productividad',
                        translate: 'NAV.LABORATORIO.PRODUCTIVIDAD.TITLE',
                        type     : 'collapsable',
                        icon     : 'assessment',
                        hidden   : false,
                        environment: ['aitana'],
                        permissions: [
                            { 
                                type: "group",
                                op: "OR",
                                items: [
                                    { type: "item", name: "Aitana:Lab:Productividad", active: true },
                                    { type: "item", name: "Aitana:Lab:Productividad Tecnico", active: true },
                                ]
                            },
                        ],
                        children : [
                            {
                                id          : 'configuracion-productividad-lab',
                                title       : 'Configuración Productividad Lab',
                                translate   : 'NAV.LABORATORIO.PRODUCTIVIDAD.CONFIGURACION_PRODUCTIVIDAD',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/configuracion-productividad-lab/index',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Lab:Productividad", active: true },
                                ],
                            },
                            {
                                id          : 'informe-kpi-productividad-lab',
                                title       : 'Informe KPI - Productividad',
                                translate   : 'NAV.LABORATORIO.PRODUCTIVIDAD.INFORME_KPI_PRODUCTIVIDAD',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/configuracion-productividad-lab/informe-kpi-productividad',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { 
                                        type: "group",
                                        op: "OR",
                                        items: [
                                            { type: "item", name: "Aitana:Perfil superAdmin", active: true },
                                            { type: "item", name: "Aitana:Lab:Productividad", active: true },
                                            { type: "item", name: "Aitana:Lab:Productividad Tecnico", active: true },
                                        ]
                                    },
                                ],
                            }
                        ]
                    },
                    {
                        id          : 'reparabilidad',
                        title       : 'Reparabilidad',
                        translate   : 'NAV.LABORATORIO.REPARABILIDAD',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/tickets/informe-reparabilidad',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informes Reparaciones", active: true },
                        ],
                    },
                ]
            },
            {
                id       : 'logistica',
                title    : 'Logística',
                translate: 'NAV.LOGISTICA.TITLE',
                type     : 'collapsable',
                libIcon  : 'fa',
                icon     : 'fa-truck-moving',
                environment: ['aitana'],
                permissions: [
                    { type: "item", name: "Aitana:Acceso Logistica", active: true },
                ],
                children : [
                    {
                        id          : 'panel-control-logistica',
                        title       : 'Panel de Control',
                        translate   : 'NAV.LOGISTICA.PANEL_CONTROL',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/tickets/panel-control-log',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Panel Control Logistica", active: true },
                        ],
                    },
                    {
                        id       : 'logistica-inversa',
                        title    : 'Logística Inversa',
                        translate: 'NAV.LOGISTICA.LOGISTICA_INVERSA.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { 
                                type: "group",
                                op: "OR",
                                items: [
                                    { type: "item", name: "Aitana:Acceso Gestión:Cajas", active: true },
                                    { type: "item", name: "Aitana:Logistica:Devolucion", active: true },
                                    { type: "item", name: "Aitana:Acceso Gestión:Pales", active: true },
                                    
                                ]
                            },
                        ],
                        children : [
                            {
                                id          : 'cajas-log-inv',
                                title       : 'Cajas',
                                translate   : 'NAV.LOGISTICA.LOGISTICA_INVERSA.CAJAS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/cajas',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Gestión:Cajas", active: true },
                                ],
                            },
                            {
                                id          : 'devolucion-log-inv',
                                title       : 'Devolución',
                                translate   : 'NAV.LOGISTICA.LOGISTICA_INVERSA.DEVOLUCION',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/problema-diagnosis-incidencia/devolucion',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Logistica:Devolucion", active: true },
                                ],
                            },
                            {
                                id          : 'pales-log-inv',
                                title       : 'Pales',
                                translate   : 'NAV.LOGISTICA.LOGISTICA_INVERSA.PALES',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/pales',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Gestión:Pales", active: true },
                                ],
                            },
                        ]
                    },
                    {
                        id       : 'transportes',
                        title    : 'Transportes',
                        translate: 'NAV.LOGISTICA.TRANSPORTES.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Transportes:Acceso Lectura", active: true },
                        ],
                        children : [
                            {
                                id       : 'gestion-facturas',
                                title    : 'Almacén',
                                translate: 'NAV.LOGISTICA.TRANSPORTES.GESTION_FACTURAS.TITLE',
                                type     : 'collapsable',
                                icon     : 'dashboard',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Transportes Autorizacion", active: true },
                                ],
                                children : [
                                    {
                                        id          : 'cuadro-mandos-administracion-fact-transp',
                                        title       : 'Cuadro de Mandos Administración',
                                        translate   : 'NAV.LOGISTICA.TRANSPORTES.GESTION_FACTURAS.CUADRO_MANDOS_ADMINISTRACION',
                                        type        : 'item',
                                        url         : environment.linkNewAitana + '/envio-transporte/cm-authorizations',
                                        externalUrl : true,
                                        openInIframe: true,
                                        environment: ['aitana'],
                                        permissions: [
                                            { type: "item", name: "Aitana:Acceso CM Transportes Autorizacion", active: true },
                                        ],
                                    },
                                    {
                                        id          : 'cuadro-mandos-proyectos-fact-transp',
                                        title       : 'Cuadro de Mandos Proyectos',
                                        translate   : 'NAV.LOGISTICA.TRANSPORTES.GESTION_FACTURAS.CUADRO_MANDOS_PROYECTOS',
                                        type        : 'item',
                                        url         : environment.linkNewAitana + '/envio-transporte/cm-authorizations-proyectos',
                                        externalUrl : true,
                                        openInIframe: true,
                                        environment: ['aitana'],
                                        permissions: [
                                            { type: "item", name: "Aitana:Acceso CM Transportes Autorizacion Proyectos", active: true },
                                        ],
                                    },
                                    {
                                        id          : 'lineas-facturas-transp',
                                        title       : 'Lineas de facturas',
                                        translate   : 'NAV.LOGISTICA.TRANSPORTES.GESTION_FACTURAS.LINEAS_FACTURAS',
                                        type        : 'item',
                                        url         : environment.linkNewAitana + '/envio-transporte/facturas-authorizations',
                                        externalUrl : true,
                                        openInIframe: true,
                                        environment: ['aitana'],
                                        permissions: [
                                            { type: "item", name: "Aitana:Acceso Facturas Transportes Autorizacion", active: true },
                                        ],
                                    },
                                ]
                            },
                            {
                                id          : 'perdidas-siniestros',
                                title       : 'Perdidas & Siniestros',
                                translate   : 'NAV.LOGISTICA.TRANSPORTES.PERDIDAS_SINIESTROS',
                                type        : 'item',
                                url         : '/transportes/perdidas-siniestros',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                    { type: "item", name: "Aitana: Perfil cliente Dedalus", active: false },
                                ],
                            },
                            {
                                id          : 'seguimiento-transp',
                                title       : 'Seguimiento',
                                translate   : 'NAV.LOGISTICA.TRANSPORTES.SEGUIMIENTO',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/envio-transporte/seguimiento',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Transportes:Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil Manager SDesk Sareteknika", active: false },
                                    { type: "item", name: "Aitana:Perfil supervisor Sareteknika", active: false },
                                ],
                            },
                            // {
                            //     id          : 'transporte-expediciones',
                            //     title       : 'Transporte - Expediciones',
                            //     translate   : 'NAV.LOGISTICA.TRANSPORTES.TRANSPORTE_EXPEDICIONES',
                            //     type        : 'item',
                            //     url         : environment.linkNewAitana + '/envio-transporte/index',
                            //     externalUrl : true,
                            //     openInIframe: true,
                            //     permissions: [
                            //         { type: "item", name: "Aitana:Transportes:Acceso Lectura", active: true },
                            //     ],
                            // },
                            {
                                id          : 'transporte-expediciones',
                                title       : 'transporte-expediciones',
                                translate   : 'NAV.LOGISTICA.TRANSPORTES.TRANSPORTE_EXPEDICIONES',
                                type        : 'item',
                                url         : '/envio-transporte/expediciones',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Transportes:Acceso Lectura", active: true },
                                ],
                            },
                            {
                                id          : 'panel-control-et',
                                title       : 'Panel de Control Envío transporte',
                                translate   : 'NAV.LOGISTICA.TRANSPORTES.PANEL_CONTROL_ET',
                                type        : 'item',
                                url         : '/envio-transporte/panel-control-et',
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Transportes:Acceso Lectura", active: true },
                                ],
                            },

                        ]
                    },
                    {
                        id          : 'stock-sap',
                        title       : 'Stock SAP',
                        translate   : 'NAV.LOGISTICA.STOCK_SAP',
                        type        : 'item',
                        url         : '/almacenes-sap',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Logistica StockSap", active: true },
                        ],
                    },
                    {
                        id          : 'pxq-logistica',
                        title       : 'PxQ Logística',
                        translate   : 'NAV.LOGISTICA.PXQ',
                        type        : 'item',
                        url         : '/envio-transporte/pxq',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Logistica PXQ Logistica", active: true },
                        ],
                    },
                ]
            },
            {
                id       : 'recuperacion',
                title    : 'Recuperación',
                translate: 'NAV.RECUPERACION.TITLE',
                type     : 'collapsable',
                libIcon  : 'fas',
                icon     : 'fa-toolbox',
                environment: ['ewaste', 'circpass'],
                permissions: [
                    { type: "item", name: "Aitana:Recuperacion", active: true }
                ],
                children: [
                    {
                        id          : 'proceso-recuperacion',
                        title       : 'Proceso de recuperación',
                        translate   : 'NAV.RECUPERACION.PROCESO_RECUPERACION',
                        type        : 'item',
                        url         : '/recuperacion/proceso-recuperacion',
                        exactMatch  : true,
                        environment: ['ewaste', 'circpass'],
                        // permissions: [
                        //     { type: "item", name: "Aitana:Acceso Logistica PXQ Logistica", active: true },
                        // ],
                    },
                ]
            },
            {
                id       : 'interoperabilidad',
                title    : 'Interoperabilidad',
                translate: 'NAV.INTEROPERABILIDAD.TITLE',
                type        : 'item',
                url         : '/interoperabilidad',
                libIcon  : 'fas',
                icon     : 'fa-download',
                environment: ['circpass']
            },
            {
                id       : 'recursos-corporativos',
                title    : 'Recursos corporativos',
                translate: 'NAV.RECURSOS_CORPORATIVOS.TITLE',
                type     : 'collapsable',
                icon     : 'developer_board',
                environment: ['aitana'],
                permissions: [
                    { type: "item", name: "Aitana:Recursos Corporativos", active: true },
                    { type: "item", name: "Aitana:Gestion", active: true }
                    /*{ 
                        type: "group",
                        op: "OR",
                        items: [
                            { type: "item", name: "Aitana:Recursos", active: true },
                            { type: "item", name: "Aitana:Gestion", active: true },
                        ]
                    },*/
                ],
                children : [
                    {
                        id       : 'copper',
                        title    : 'COPPER',
                        translate: 'NAV.RECURSOS_CORPORATIVOS.COPPER.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        children : [
                            {
                                id       : 'panel',
                                title    : 'Panel',
                                translate: 'NAV.RECURSOS_CORPORATIVOS.COPPER.PANEL',
                                type     : 'item',
                                icon     : 'dashboard',
                                url      : '/panel',
                                environment: ['aitana'],
                            },
                            {
                                id       : 'clientes-potenciales',
                                title    : 'Clientes Potenciales',
                                translate: 'NAV.RECURSOS_CORPORATIVOS.COPPER.CLIENTES_POTENCIALES',
                                type     : 'item',
                                icon     : 'gps_fixed',
                                url      : '/clientes-potenciales',
                                environment: ['aitana'],
                            },
                            {
                                id       : 'empresas',
                                title    : 'Empresas',
                                translate: 'NAV.RECURSOS_CORPORATIVOS.COPPER.EMPRESAS',
                                type     : 'item',
                                icon     : 'business',
                                url      : '/empresas',
                                environment: ['aitana'],
                            },
                            {
                                id       : 'oportunidades',
                                title    : 'Oportunidades',
                                translate: 'NAV.RECURSOS_CORPORATIVOS.COPPER.OPORTUNIDADES',
                                type     : 'item',
                                icon     : 'attach_money',
                                url      : '/oportunidades',
                                environment: ['aitana'],
                            },
                            {
                                id       : 'personas',
                                title    : 'Personas',
                                translate: 'NAV.RECURSOS_CORPORATIVOS.COPPER.PERSONAS',
                                type     : 'item',
                                icon     : 'people',
                                url      : '/personas',
                                environment: ['aitana'],
                            },
                            {
                                id       : 'proyectos',
                                title    : 'Proyectos',
                                translate: 'NAV.RECURSOS_CORPORATIVOS.COPPER.PROYECTOS',
                                type     : 'item',
                                icon     : 'work',
                                url      : '/proyectos',
                                environment: ['aitana'],
                            }
                        ]
                    },
                    {
                        id          : 'cotizador-b2b',
                        title       : 'Cotizador B2B',
                        translate   : 'NAV.RECURSOS_CORPORATIVOS.COTIZADOR_B2B',
                        type        : 'collapsable',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Gestión:Cotizador B2B", active: true },
                        ],
                        children    : [
                            {
                                id          : 'catalogo-servicios-b2b',
                                title       : 'Catálogo de servicios',
                                translate   : 'NAV.RECURSOS_CORPORATIVOS.B2B_CATALOGO_SERVICIOS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/cotizador-servicios/index',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Gestión:Cotizador B2B", active: true },
                                ],
                            },
                            {
                                id          : 'familias-b2b',
                                title       : 'Familias',
                                translate   : 'NAV.RECURSOS_CORPORATIVOS.B2B_FAMILIAS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/cotizador-servicios/familias',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Gestión:Cotizador B2B", active: true },
                                ],
                            },
                        ]
                    },
                    {
                        id          : 'rrhh-distribucion-proyectos',
                        title       : 'RRHH - Distribución en Proyectos',
                        translate   : 'NAV.RECURSOS_CORPORATIVOS.RRHH_DISTRIBUCION_PROYECTOS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/distribucion-proyectos-s-a-p',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Distribucion rrhh:Lectura", active: true },
                        ],
                    },
                ]
            },
            {
                id       : 'redServicio',
                title    : 'Red de Servicio',
                translate: 'NAV.RED_SERVICIO.TITLE',
                type     : 'collapsable',
                icon     : 'contacts',
                environment: ['aitana', 'circpass'],
                permissions: [
                    { type: "item", name: "Aitana:Red de Servicio", active: true },
                ],
                children : [
                    {
                        id          : 'ver_cm_colabordor',
                        title       : 'CM Colaborador',
                        translate   : 'CM Colaborador',
                        type        : 'item',
                        url         : '/cuadro-mandos-colaborador/cuadro-mandos-colaborador',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Cuadro de Mandos Colaborador", active: true },
                        ],
                    },
                    {
                        id          : 'envio-correo',
                        title       : 'Envío de correo',
                        translate   : 'NAV.RED_SERVICIO.ENVIO_CORREO',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/colaboradores/envio-correos',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Envio Mensajes Tecnicos", active: true },
                        ],
                    },
                    {
                        id          : 'mapa-tecnicos',
                        title       : 'Mapa de Técnicos',
                        translate   : 'NAV.RED_SERVICIO.MAPA_TECNICOS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/colaboradores/mapa-ultima-posicion',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Cuadro de Mandos:Mapa de tecnicos", active: true },
                        ],
                    },
                    {
                        id       : 'partners',
                        title    : 'Partners',
                        translate: 'NAV.RED_SERVICIO.PARTNERS.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                        ],
                        children : [
                            {
                                id          : 'gasto-colaborador',
                                title       : 'Gasto por colaborador',
                                translate   : 'NAV.RED_SERVICIO.PARTNERS.GASTO_COLABORADOR',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/gasto-por-colaborador',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                            {
                                id          : 'valoraciones-partners',
                                title       : 'Valoraciones Partners',
                                translate   : 'NAV.RED_SERVICIO.PARTNERS.VALORACIONES_PARTNER',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/colaboradores/informe-valoraciones',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                                    { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                                ],
                            },
                        ]
                    },
                    {
                        id          : 'gestion-proveedores',
                        title       : 'Gestión proveedores',
                        translate   : 'NAV.RED_SERVICIO.GESTION_PROVEEDORES',
                        type        : 'item',
                        url         : 'colaboradores',
                        environment: ['aitana', 'circpass'],
                        permissions: [
                            { type: "item", name: "Aitana:Gestión:Colaboradores", active: true },
                        ],
                    },
                    {
                        id       : 'tecnicos',
                        title    : 'Técnicos',
                        translate: 'NAV.RED_SERVICIO.TECNICOS.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Informes Seguimiento", active: true },
                            { type: "item", name: "Aitana:Perfil Manager Demo", active: false },
                        ],
                        children : [
                            {
                                id          : 'actividad-tecnicos',
                                title       : 'Actividad Técnicos',
                                translate   : 'NAV.RED_SERVICIO.TECNICOS.ACTIVIDAD_TECNICOS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/citas/actividad-tecnicos',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Tecnicos", active: true },
                                ],
                            },
                            {
                                id          : 'actividad-tecnicos-proyecto',
                                title       : 'Actividad Técnicos por proyecto',
                                translate   : 'NAV.RED_SERVICIO.TECNICOS.ACTIVIDAD_TECNICOS_PROYECTO',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/citas/actividad-tecnicos-por-proyecto',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Tecnicos", active: true },
                                ],
                            },
                            {
                                id          : 'cuadro-mandos-grupal',
                                title       : 'Cuadro de mandos grupal',
                                translate   : 'NAV.RED_SERVICIO.TECNICOS.CUADRO_MANDOS_GRUPAL',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/citas/actividad-tecnicos-por-proyecto-grupal',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Tecnicos", active: true },
                                ],
                            },
                            {
                                id          : 'productividad-lab',
                                title       : 'Productividad Lab',
                                translate   : 'NAV.RED_SERVICIO.TECNICOS.PRODUCTIVIDAD_LAB',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/entity/productividad-tecnicos-lab',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Tecnicos", active: true },
                                ],
                            },
                            {
                                id          : 'productividad',
                                title       : 'Productividad',
                                translate   : 'NAV.RED_SERVICIO.TECNICOS.PRODUCTIVIDAD',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/citas/operaciones-tecnicos-propios',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso Informes Tecnicos", active: true },
                                ],
                            },
                        ]
                    },
                ]
            },
            {
                id       : 'misc',
                title    : 'MISC',
                translate: 'NAV.MISC.TITLE',
                type     : 'collapsable',
                icon     : 'more_horiz',
                environment: ['aitana'],
                permissions: [
                    { 
                        type: "group",
                        op: "OR",
                        items: [
                            { type: "item", name: "Aitana:Acceso MISC", active: true },
                            { type: "item", name: "Aitana:Acceso BuyBack Lectura", active: true },
                        ]
                    },
                ],
                children : [
                    {
                        id       : 'buyback',
                        title    : 'BuyBack',
                        translate: 'NAV.MISC.BUY_BACK.TITLE',
                        type     : 'collapsable',
                        icon     : 'dashboard',
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso BuyBack Lectura", active: true },
                        ],
                        children : [
                            {
                                id          : 'carga-precios-buyback',
                                title       : 'Carga de precios',
                                translate   : 'NAV.MISC.BUY_BACK.CARGA_PRECIOS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/buy-back/carga-precios',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso BuyBack Escritura", active: true },
                                ],
                            },
                            {
                                id          : 'catalogo-productos-buyback',
                                title       : 'Catálogo Productos',
                                translate   : 'NAV.MISC.BUY_BACK.CATALOGO_PRODUCTOS',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/buy-back/index',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso BuyBack Lectura", active: true },
                                ],
                            },
                            {
                                id          : 'operaciones-buyback',
                                title       : 'Operaciones BuyBack',
                                translate   : 'NAV.MISC.BUY_BACK.OPERACIONES_BUYBACK',
                                type        : 'item',
                                url         : environment.linkNewAitana + '/buy-back/operaciones-buy-back',
                                externalUrl : true,
                                openInIframe: true,
                                environment: ['aitana'],
                                permissions: [
                                    { type: "item", name: "Aitana:Acceso BuyBack Escritura", active: true },
                                ],
                            },
                        ]
                    },
                    {
                        id          : 'control-piezas-ticket',
                        title       : 'Control de piezas/tickets',
                        translate   : 'NAV.MISC.CONTROL_PIEZAS_TICKETS',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/problema-diagnosis-incidencia/control-piezas-ticket',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Logistica:Control piezas", active: true },
                        ],
                    },
                    {
                        id          : 'informe-transformaciones',
                        title       : 'Informe Tranformaciones',
                        translate   : 'NAV.MISC.INFORME_TRANSFORMACIONES',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/almacenes-movimientos/informe-transformaciones',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Acceso Movimiento Almacen Transformaciones", active: true },
                            { type: "item", name: "Aitana:Acceso Stock-Almacen", active: true },
                        ],
                    },
                    {
                        id          : 'packing-list',
                        title       : 'Packing List',
                        translate   : 'NAV.MISC.PACKING_LIST',
                        type        : 'item',
                        url         : environment.linkNewAitana + '/werb-services-sap/cuadro-packing-list',
                        externalUrl : true,
                        openInIframe: true,
                        environment: ['aitana'],
                        permissions: [
                            { type: "item", name: "Aitana:Logistica: Packing List", active: true },
                        ],
                    },
                ]
            },
            {
                id       : 'funcionalidades-jorge',
                title    : 'Funcionalidades-Jorge',
                translate: 'Funcionalidades-Jorge',
                 type     : 'collapsable',
                 libIcon  : 'fas',
                 icon     : 'fa-laptop',
                 environment: ['aitana'],
                 permissions: [
                    { type: "item", name: "AdminNuevaAitana", active: true },
                 ],
                 children : [
                    {
                        id          : 'informexzona',
                         title       : 'Informes por zona',
                         translate   : 'Informes por zona',
                         type        : 'item',
                         url         : '/prueba-informe',
                         environment: ['aitana'],
                         permissions: [
                            { type: "item", name: "AdminNuevaAitana", active: true },
                         ]
                     },
                     {
                         id       : 'dispositivos',
                         title    : 'Dispositivos',
                         translate: 'Dispositivos',
                         type     : 'item',
                         url      : '/dispositivos',
                         environment: ['aitana'],
                         permissions: [
                            { type: "item", name: "AdminNuevaAitana", active: true },
                         ]
                     },
                     {
                         id          : 'compras',
                        title       : 'Compras',
                         translate   : 'Compras',
                         type        : 'item',
                         url         : '/recursos-compras',
                         environment: ['aitana'],
                         permissions: [
                            { type: "item", name: "AdminNuevaAitana", active: true },
                         ]
                     }
                 ],
                
            },


/*            {
                id       : 'bloq1',
                title    : 'Bloque 1',
                translate: 'NAV.BLOQ1.TITLE',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id          : 'page1',
                        title       : 'Pagina 1',
                        translate   : 'NAV.BLOQ1.PAGE1',
                        type        : 'item',
                        url         : '/bloq-1/page-1'
                    },
                    {
                        id          : 'page2',
                        title       : 'Pagina 2',
                        translate   : 'NAV.BLOQ1.PAGE2',
                        type        : 'item',
                        url         : '/bloq-1/page-2'
                    }
                ]
            },
            {
                id       : 'bloq2',
                title    : 'Bloque 2',
                translate: 'NAV.BLOQ2.TITLE',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id          : 'page1',
                        title       : 'Pagina 1',
                        translate   : 'NAV.BLOQ2.PAGE1',
                        type        : 'item',
                        url         : '/bloq-2/page-1'
                    },
                    {
                        id          : 'page2',
                        title       : 'Pagina 2',
                        translate   : 'NAV.BLOQ2.PAGE2',
                        type        : 'item',
                        url         : '/bloq-2/page-2'
                    }
                ]
            },
            {
                id       : 'chat',
                title    : 'Chat',
                translate: 'NAV.CHAT.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/chat'
            },
            {
                id       : 'sample',
                title    : 'Sample',
                translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/sample',
                badge    : {
                    title    : '25',
                    translate: 'NAV.SAMPLE.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },*/
            // {
            //     id       : 'garantia10',
            //     title    : 'Garantia 10',
            //     translate: 'NAV.GARANTIA10.TITLE',
            //     type     : 'item',
            //     icon     : 'home',
            //     url      : '/garantia-10',
            //     // permissions: [
            //     //     {permission: "Aitana:Perfil Manager", active: true},
            //     //     {permission: "Aitana:Perfil superAdmin", active: true},
            //     //     {permission: "Aitana: Perfil cliente Lenovo WS", active: false}
            //     // ],
            // },
            // {
            //     id       : 'metricas',
            //     title    : 'Metricas',
            //     translate: 'NAV.METRICAS.TITLE',
            //     type     : 'item',
            //     icon     : 'home',
            //     url      : '/metricas',
            // },
            // {
            //     id       : 'informekpi',
            //     title    : 'Informe KPI',
            //     translate: 'NAV.INFORMEKPI.TITLE',
            //     type     : 'item',
            //     icon     : 'home',
            //     url      : '/informekpi',
            // },
            // {
            //     id       : 'transporte',
            //     title    : 'Transporte',
            //     translate: 'NAV.TRANSPORTES.TITLE',
            //     type     : 'collapsable',
            //     icon     : 'local_shipping',
            //     children : [
            //         {
            //             id          : 'perdidas-siniestros',
            //             title       : 'Perdidas & Siniestros',
            //             translate   : 'NAV.TRANSPORTES.PERDIDAS',
            //             type        : 'item',
            //             url         : '/transportes/perdidas-siniestros'
            //         }
            //     ]
            // },
            {
                id       : 'informes',
                title    : 'Informes',
                translate: 'NAV.INFORMES.TITLE',
                type     : 'collapsable',
                icon     : 'assessment',
                hidden   : true,
                environment: ['aitana'],
                children : [
                    {
                        id          : 'informe-tickets',
                        title       : 'Informe Tickets',
                        translate   : 'NAV.INFORMES.INFORME_TICKETS',
                        type        : 'item',
                        url         : 'informes/informe-tickets',
                        environment: ['aitana'],
                    }
                ]
            },
            // {
            //     id       : 'diagnosis',
            //     title    : 'Diagnosis',
            //     translate: 'NAV.DIAGNOSIS.TITLE',
            //     type     : 'collapsable',
            //     icon     : 'assessment',
            //     hidden   : false,
            //     children : [
            //         {
            //             id          : 'nueva-diagnosis',
            //             title       : 'Nueva diagnosis',
            //             translate   : 'NAV.DIAGNOSIS.NUEVA_DIAGNOSIS',
            //             type        : 'item',
            //             url         : 'diagnosis/nueva-diagnosis'
            //         }
            //     ]
            // },
            {
                id       : 'telco',
                title    : 'TELCO',
                translate: 'NAV.TELCO.TITLE',
                type     : 'collapsable',
                icon     : 'network_check',
                environment: ['aitana'],
                permissions: [
                    { type: "item", name: "Aitana: Integracion Netsuite Telco", active: true },
                ],
                children : [
                    {
                        id          : 'subir-excel-telco',
                        title       : 'Subida Excel',
                        translate   : 'NAV.TELCO.EXCEL',
                        type        : 'item',
                        url         : '/subir-netsuite',
                        permissions: [
                            { type: "item", name: "Aitana:Subir Fichero Netsuite", active: true },
                        ],
                    },
                ]
            },
        ]
    }
];
