import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Validators, FormGroup, FormControl, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as moment from 'moment';

import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject } from 'rxjs';

@Component({
  selector: 'app-descripcion-edit',
  templateUrl: './descripcion-edit.component.html',
  styleUrls: ['./descripcion-edit.component.scss']
})
export class DescripcionEditComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	descripcionTicket: string;

	loadingSave: boolean;
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: '40rem',
		minHeight: '15rem',
		translate: 'yes',
		placeholder: 'Descripción del ticket ...',
		defaultParagraphSeparator: 'p',
		sanitize: true,
		toolbarPosition: 'top',
		toolbarHiddenButtons: [
		  ['undo', 'redo', 'fontName'],
		  ['insertVideo', 'toggleEditorMode', 'unlink', 'removeFormat']
		]
	};

	formDesc: FormGroup;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
			private dialogRef: MatDialogRef<DescripcionEditComponent>,
			private dialog: MatDialog, 
			private formBuilder: FormBuilder,
			private snackBar: MatSnackBar,
			private translateService: TranslateService,
	) {
		moment.locale(this.translateService.currentLang);
		this._unsubscribeAll = new Subject();

		this.loadingSave = false;
		this.formDesc = this.formBuilder.group({
			descripcion: [''],
		});
	}

	ngOnInit(): void {
		if (this.data.ticket) {
			this.formDesc.get('descripcion').setValue(this.data.ticket.contents);
		}
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	guardarDescripcion() {
		this.dialogRef.close(this.formDesc.get('descripcion').value);
	}

}
