import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Snotify, SnotifyService, SnotifyToast } from 'ng-snotify';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import io from "socket.io-client";
import { AuthService } from '../auth/auth.service';
import { NotificacionesService } from '../notificaciones/notificaciones.service';

export class CmTipos {
	static ticketsPorEstados = 'cm-tickets-estados';
	static ticketsPorGrupos = 'cm-tickets-grupos';
    static ticketsPorUsuarios = 'cm-tickets-usuarios';
	static tendencia = 'cm-tendencia';
}

@Injectable({
  providedIn: 'root'
})
export class SocketService {

	socket;

	onNuevaNotificacion: BehaviorSubject<any>;
	onCmTicketsEstadosUpdate: Observer<any>;
	onCmTicketsGruposUpdate: Observer<any>;
    onCmTicketsUsuariosUpdate: Observer<any>;
	onInformePxqLogisticaUpdate : Observer<any>;
	onInformePxqManobraUpdate : Observer<any>;
	onCmTendenciaUpdate: Observer<any>;
	
	constructor(
		private authSvc: AuthService,
		private notificacionesSvc: NotificacionesService,
		private snotifyService: SnotifyService,
	) { 
		this.onNuevaNotificacion = new BehaviorSubject(null);
	}

	setupSocketConnection() {
		const accessToken = this.authSvc.getAccessToken();
		
		this.socket = io(environment.WEBSOCKET_URL, {
			query: {token: accessToken}
		});

		this.socket.on('connect', () => {
			// this.socket.emit('auth-user', usuario);
		});

		this.socket.on('user-notification', (data) => {
			this.showNotification(data);
			this.onNuevaNotificacion.next(data);
		});
		
		this.socket.on('cm-tickets-estados', (data) => {
			this.onCmTicketsEstadosUpdate.next(data);
		});
		this.socket.on('cm-tickets-grupos', (data) => {
			console.log("evento cm-tickets-grupos", data);
			this.onCmTicketsGruposUpdate.next(data);
		});

        this.socket.on('cm-tickets-usuarios', (data) => {
			console.log("evento cm-tickets-usuarios", data);
			this.onCmTicketsUsuariosUpdate.next(data);
		});

		this.socket.on('informe-pxq-logistica', (data) => {
			this.onInformePxqLogisticaUpdate.next(data);
		});

		this.socket.on('informe-pxq-manobra', (data) => {
			this.onInformePxqManobraUpdate.next(data);
		});

		this.socket.on('cm-tendencia', (data) => {
			console.log("evento cm-tendencia", data);
			this.onCmTendenciaUpdate.next(data);
		});
	}

	disconnect() {
		if (this.socket) {
			this.socket.disconnect();
		}
	}

	registerUserCm(nombreCm, filtros?): Observable<any> {
		console.log("regiserUserCm", nombreCm);
		this.socket.emit('register-user-cm', {cm: nombreCm, filtros: filtros});
		return new Observable(observer => {
			if (nombreCm == CmTipos.ticketsPorEstados) {
				this.onCmTicketsEstadosUpdate = observer;
			}
			else if (nombreCm == CmTipos.ticketsPorGrupos) {
				this.onCmTicketsGruposUpdate = observer;
			}else if (nombreCm == CmTipos.ticketsPorUsuarios){
                this.onCmTicketsUsuariosUpdate = observer;
            } 
			else if (nombreCm == CmTipos.tendencia) {
				this.onCmTendenciaUpdate = observer;
			}
		});
	}
	unregisterUserCm(nombreCm) {
		console.log("unregiserUserCm", nombreCm);
		this.socket.emit('unregister-user-cm', {cm: nombreCm});
	}


	registerUserPxqLogistica(nombrePxq): Observable<any> {
		console.log("regiserUserPxqLogistica", nombrePxq);
		return new Observable(observer => {
			
			this.onInformePxqLogisticaUpdate = observer;
			
			this.socket.emit('register-user-pxqlogisitca', {pxq: nombrePxq});
		});
	}
	unregisterUserPxqLogistica(nombrePxq) {
		console.log("unregiserUserPxqLogistica", nombrePxq);
		this.socket.emit('unregister-user-pxqlogistica', {pxq: nombrePxq});
	}

	registerUserPxqManobra(nombrePxqManobra): Observable<any> {
		console.log("regiserUserPxqManobra", nombrePxqManobra);
		return new Observable(observer => {
			
			this.onInformePxqManobraUpdate = observer;
			
			this.socket.emit('register-user-pxqmanobra', {pxq: nombrePxqManobra});
		});
	}
	unregisterUserPxqManobra(nombrePxqManobra) {
		console.log("unregiserUserPxqManobra", nombrePxqManobra);
		this.socket.emit('unregister-user-pxqmanobra', {pxq: nombrePxqManobra});
	}

	showNotification(data) {
		let toast: SnotifyToast;
		switch (data.type) {
			case 'success':
				toast = this.showNotificationSuccess(data);
				break;
		
			case 'error':
				toast = this.showNotificationError(data);
				break;
		
			case 'warning':
				toast = this.showNotificationWarning(data);
				break;
		
			case 'notice':
				toast = this.showNotificationInfo(data);
				break;
		
			default:
				break;
		}

		if (toast) {
			toast.on('click', (snotify: Snotify) => {
				console.log("Click en notificacion", data);
				this.notificacionesSvc.marcarComoLeida(data.objeto.ID).subscribe(
					(data: any) => {
						// No se hace nada
					}
				);
			});
		}


	}
	
	showNotificationSuccess(data) {
		const htmlNotificacion = '<div class="snotifyToast__title"> '+data.title+' </div><div class="snotifyToast__body">'+data.body+'</div><div class="snotify-icon snotify-icon--success"></div>';

		return this.snotifyService.success(data.body, data.title, {
			html: htmlNotificacion,
			timeout: 4000,
			showProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			titleMaxLength: 250,
			bodyMaxLength: 255
		  });
	}
	showNotificationError(data) {
		const htmlNotificacion = '<div class="snotifyToast__title"> '+data.title+' </div><div class="snotifyToast__body">'+data.body+'</div><div class="snotify-icon snotify-icon--error"></div>';

		return this.snotifyService.error(data.body, data.title, {
			html: htmlNotificacion,
			timeout: 4000,
			showProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			titleMaxLength: 250,
			bodyMaxLength: 255
		  });
	}
	showNotificationWarning(data) {
		const htmlNotificacion = '<div class="snotifyToast__title"> '+data.title+' </div><div class="snotifyToast__body">'+data.body+'</div><div class="snotify-icon snotify-icon--warning"></div>';

		return this.snotifyService.warning(data.body, data.title, {
			html: htmlNotificacion,
			timeout: 4000,
			showProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			titleMaxLength: 250,
			bodyMaxLength: 255
		  });
	}
	showNotificationInfo(data) {
		const htmlNotificacion = '<div class="snotifyToast__title"> '+data.title+' </div><div class="snotifyToast__body">'+data.body+'</div><div class="snotify-icon snotify-icon--info"></div>';

		return this.snotifyService.info(data.body, data.title, {
			html: htmlNotificacion,
			timeout: 4000,
			showProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			titleMaxLength: 250,
			bodyMaxLength: 255
		  });
	}

}
