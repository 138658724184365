import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HttpClientModule} from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MdePopoverModule } from '@material-extended/mde';
import { MatCardModule } from '@angular/material/card';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BarRatingModule } from "ngx-bar-rating";
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginationIntlService } from './MatPaginationIntlService';

import { FuseSharedModule } from '@fuse/shared.module';

import { DialogConfirmComponent } from './util/dialog-confirm/dialog-confirm.component';
import { SelectorProyectosComponent } from './selector-proyectos/selector-proyectos.component';
import { DialogConfiguracionProyectosComponent } from './selector-proyectos/dialog-configuracion-proyectos/dialog-configuracion-proyectos.component';
import { ProgresBarComponent } from './util/progres-bar/progres-bar.component';
import { BuscadorTicketsComponent } from './buscador-tickets/buscador-tickets.component';
import { SeguimientoDialogComponent } from './seguimiento-dialog/seguimiento-dialog.component';
import { BuscarClienteComponent } from './clientes/buscar-cliente/buscar-cliente.component';
import { DialogFormClienteComponent } from './clientes/dialog-form-cliente/dialog-form-cliente.component';
import { DialogFormDireccionClienteComponent } from './clientes/dialog-form-direccion-cliente/dialog-form-direccion-cliente.component';
import { DialogBuscarPiezasComponent } from './diagnosis/dialog-buscar-piezas/dialog-buscar-piezas.component';
import { DialogSintomasComponent } from './diagnosis/dialog-sintomas/dialog-sintomas.component';
import { DialogAlertComponent } from './util/dialog-alert/dialog-alert.component';
import { BuscadorColaboradoresComponent } from './colaboradores/buscador-colaboradores/buscador-colaboradores.component';
import { DescripcionEditComponent } from './ticket/descripcion-edit/descripcion-edit.component';
import { DialogGuardarFiltrosGridComponent } from './util/dialog-guardar-filtros-grid/dialog-guardar-filtros-grid.component';
import { DialogConfiguracionGridComponent } from './util/dialog-configuracion-grid/dialog-configuracion-grid.component';
import { DialogGuardarSortsGridComponent } from './util/dialog-guardar-sorts-grid/dialog-guardar-sorts-grid.component';
import { EditorCampoTablaComponent } from './editor-campo-tabla/editor-campo-tabla.component';
import { DialogFormDiagnosisComponent } from './diagnosis/dialog-form-diagnosis/dialog-form-diagnosis.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { FormularioCamposProyectoDialogComponent } from './formulario-campos-proyecto-dialog/formulario-campos-proyecto-dialog.component';
import { BuscadorNsComponent } from './buscador-ns/buscador-ns.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SeguimientoFormDialogComponent } from './seguimiento-form-dialog/seguimiento-form-dialog.component';
import { QuillModule } from 'ngx-quill';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ColaboradorDialogComponent } from './colaborador-dialog/colaborador-dialog.component';
import { ArbolDecisionDialogComponent } from './arbol-decision-dialog/arbol-decision-dialog.component';
import { DireccionFormDialogComponent } from './direcciones/form/direccion-form-dialog.component';
import { DialogSelectedFiltroDireccionesComponent } from './direcciones/dialog-selected-filtro/dialog-selected-filtro-direcciones.component';
import { BuscadorCallCenterComponent } from './ticket/buscador-call-center/buscador-call-center.component';
import { BuscadorAvanzadoClienteComponent } from './ticket/buscador-avanzado-cliente/buscador-avanzado-cliente.component';
import { DialogQrCodeComponent } from './util/dialog-qr-code/dialog-qr-code.component';
import { DialogAdjuntosTicketComponent } from './ticket/dialog-adjuntos-ticket/dialog-adjuntos-ticket.component';
import { BuscadorMenuComponent } from './buscador-menu/buscador-menu.component';
import { DialogFormTestDeviceResultComponent } from './test-device/dialog-form-test-device-result/dialog-form-test-device-result.component';
import { DialogFormPersonalizadoComponent } from './dialog-form-personalizado/dialog-form-personalizado.component';
import { DialogFormCitaComponent } from './citas/dialog-form-cita/dialog-form-cita.component';
import { InformeAiComponent } from './informe-ai/informe-ai.component';
import { DialogSelectedFiltroInformeAiComponent } from './informe-ai/dialog-selected-filtro/dialog-selected-filtro.component';
import { MatrizAiComponent } from 'app/componentes/matriz-ai/matriz-ai.component';
import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DialogVideoCcComponent } from './centro-conocimiento/dialog-video-cc/dialog-video-cc.component';
import { InfoActivoComponent } from './info-activo/info-activo.component';
import { AvatarPhotoUserComponent } from './util/avatar-photo-user/avatar-photo-user.component';
import { TablaAiComponent } from './tabla-ai/tabla-ai.component';
import { MaterialSelectComponent } from './material-select/material-select.component';
import { DialogOpcionComponent } from './material-select/dialog-opcion/dialog-opcion.component';

@NgModule({
	declarations: [
		DialogConfirmComponent,
		SelectorProyectosComponent,
		DialogConfiguracionProyectosComponent,
		ProgresBarComponent,
		BuscadorTicketsComponent,
		SeguimientoDialogComponent,
		BuscarClienteComponent,
		DialogFormClienteComponent,
		DialogFormDireccionClienteComponent,
		DialogBuscarPiezasComponent,
		DialogSintomasComponent,
		DialogAlertComponent,
		DialogFormTestDeviceResultComponent,
		BuscadorColaboradoresComponent,
		DescripcionEditComponent,
		DialogGuardarFiltrosGridComponent,
		DialogConfiguracionGridComponent,
		DialogGuardarSortsGridComponent,
		EditorCampoTablaComponent,
		DialogFormDiagnosisComponent,
		FormularioCamposProyectoDialogComponent,
		BuscadorNsComponent,
		SeguimientoFormDialogComponent,
        ColaboradorDialogComponent,
		ArbolDecisionDialogComponent,
        DireccionFormDialogComponent,
        DialogSelectedFiltroDireccionesComponent,
		BuscadorCallCenterComponent,
		BuscadorAvanzadoClienteComponent,
		DialogQrCodeComponent,
		DialogAdjuntosTicketComponent,
		BuscadorMenuComponent,
		DialogFormPersonalizadoComponent,
		DialogFormCitaComponent,
		InformeAiComponent,
        DialogSelectedFiltroInformeAiComponent,
        MatrizAiComponent,
        DialogVideoCcComponent,
		InfoActivoComponent,
		AvatarPhotoUserComponent,
		TablaAiComponent,
		MaterialSelectComponent,
		DialogOpcionComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		MatFormFieldModule,
		MatInputModule,
		MatToolbarModule,
		MatSlideToggleModule,
		MatPaginatorModule,
		MatTableModule,
		MatTooltipModule,
		MatSelectModule,
		MatDatepickerModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatGridListModule,
		MatListModule,
		MatCheckboxModule,
		HttpClientModule,
		NgxMatSelectSearchModule,
		BarRatingModule,
		AngularEditorModule,
		DragDropModule,
		FontAwesomeModule,
		MdePopoverModule,
		MatCardModule,
		MatExpansionModule,
		MatTabsModule,
		FuseSharedModule,
		QuillModule,
        MatProgressBarModule,
        ChartsModule,
		NgxChartsModule,
	],
	exports: [
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		MatFormFieldModule,
		MatInputModule,
		MatToolbarModule,
		MatSelectModule,
		MatDatepickerModule,
		MatCheckboxModule,
		MatExpansionModule,
		MatTabsModule,
		NgxMatSelectSearchModule,
		TranslateModule,

		// FuseSharedModule,

		DialogConfirmComponent,
		SelectorProyectosComponent,
		DialogConfiguracionProyectosComponent,
		ProgresBarComponent,
		BuscadorTicketsComponent,
		BuscarClienteComponent,
		DialogFormClienteComponent,
		DialogFormDireccionClienteComponent,
		DialogFormTestDeviceResultComponent,
		DialogBuscarPiezasComponent,
		DialogSintomasComponent,
		DialogAlertComponent,
		BuscadorColaboradoresComponent,
		DescripcionEditComponent,
		DialogGuardarFiltrosGridComponent,
		EditorCampoTablaComponent,
		BuscadorNsComponent,
        ColaboradorDialogComponent,
        DireccionFormDialogComponent,
        DialogSelectedFiltroDireccionesComponent,
		BuscadorCallCenterComponent,
		BuscadorAvanzadoClienteComponent,
		DialogQrCodeComponent,
		DialogAdjuntosTicketComponent,
		BuscadorMenuComponent,
        InformeAiComponent,
		TablaAiComponent,
        DialogSelectedFiltroInformeAiComponent,
        MatrizAiComponent,
		DialogVideoCcComponent,
        ChartsModule,
		NgxChartsModule,
		InfoActivoComponent,
		AvatarPhotoUserComponent,
        MaterialSelectComponent,
        DialogOpcionComponent
	],
	providers: [
		{
			provide: MatPaginatorIntl,
			useClass: MatPaginationIntlService
		}
	]
})
export class CygShareModule { }
