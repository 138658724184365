import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-buscador',
    templateUrl: './buscador.component.html',
    styleUrls: ['./buscador.component.scss']
})
export class BuscadorComponent implements OnInit {
    value = '';

    @Output() filtrar = new EventEmitter<Event>();
    @Output() dialog = new EventEmitter<Event>();

    constructor(private router: Router) { }

    applyFilter(filtro: Event) {
        this.filtrar.emit(filtro);
    }

    openDialog() {
        this.dialog.emit();
    }

    ngOnInit(): void {

    }

    ngOnChanges(): void {

    }

}
