<div fxLayout="column" class="w-100-p">
    <p class="secondary-text m-0 font-size-16">
        Comprueba si el usuario tiene un contrato para su dispositivo
    </p>
    <p class="secondary-text m-0 font-size-14">
        Puedes buscar al cliente por nº de contrato, teléfono o e-mail
    </p>

    <div fxLayout="row" fxLayoutAlign="flex-start center">
        <mat-form-field appearance="standard" class="form-group w-280">
            <input #fieldBuscador matInput name="name" [(ngModel)]="fieldBuscadorCallCenter" placeholder="Buscar cliente" class="form-control" autocomplete="off" (keydown.enter)="buscarCliente()">
        </mat-form-field>

        <button mat-button mat-stroked-button class="ml-16" [class.spinner]="loadingSearch" (click)="buscarCliente()" >
            <mat-icon fontSet="fas" fontIcon="fa-search" class="mr-8"></mat-icon>
            Buscar
        </button>
        
        <button mat-button mat-stroked-button class="ml-16" (click)="openBusquedaAvanzadaCliente()" >Busqueda Avanzada</button>
    </div>

    <div *ngIf="sinClientes" fxLayout="row">
        <span class="m-0 my-16 secondary-text font-size-18">No se ha encontrado ningún cliente</span>
    </div>

    <div *ngIf="clientesEncontrados.length > 0" fxLayout="column">
        <p class="secondary-text m-0 font-size-14">Se ha encontrado mas de un cliente para la busqueda realizada. </p>
        <p class="secondary-text m-0 font-size-14">Verifica algunos datos del cliente para poder seleccionar un cliente del listado. </p>

        <div class="table-container table-cliente">
            <table mat-table [dataSource]="dataSourceClientes" class="w-100-p bloque-form">
                <ng-container matColumnDef="info_cliente">
                    <th mat-header-cell *matHeaderCellDef> Clientes </th>
                    <td mat-cell *matCellDef="let row" class="py-8">
                        <ng-container *ngTemplateOutlet="infoCliente; context:{ datosCliente: row }"></ng-container>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="actions" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <div fxLayout="row wrap" fxLayoutAlign="center center">
                            <button mat-icon-button matTooltip="Seleccionar cliente" (click)="seleccionarCliente(row)">
                                <mat-icon fontSet="fas" fontIcon="fa-user-check"></mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumnsClientes, sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsClientes;"></tr>
            </table>
        </div>
    </div>

    <div *ngIf="cliente" class="bloq-cliente">
        <h4 class="m-0 secondary-text font-weight-600 font-size-18 mb-12">Cliente</h4>
        <ng-container *ngTemplateOutlet="infoCliente; context:{ datosCliente: cliente }"></ng-container>
    </div>

    <ng-template #infoCliente let-cliente="datosCliente">
        <div fxLayout="row wrap">
            <div fxLayout="column" class="mr-20">
                <div fxLayout="row wrap" class="nombre-cliente mb-8">
                    <p class="m-0 mr-8 mb-4 secondary-text font-size-16 font-weight-600">{{cliente.nombre}}</p>
                    <p class="m-0 mb-4 secondary-text font-size-16 font-weight-600">{{cliente.apellido}}</p>
                </div>
                <p class="m-0 mb-4 secondary-text">NIF/CIF: {{cliente.cif_nif !== '' ? cliente.cif_nif : '-'}}</p>
                <p *ngIf="cliente.garantia10 && cliente.garantia10.length > 0" class="m-0 mb-8 secondary-text">Nº contrato: {{ contratosCliente(cliente) }}</p>
                <p *ngIf="cliente.email_contacto !== ''" fxLayot="row" fxLayoutAlign="flex-start center" class="m-0 mr-20 mb-4 secondary-text">
                    <mat-icon fontSet="fas" fontIcon="fa-envelope" class="mr-8"></mat-icon>
                    {{cliente.email_contacto}}
                </p>
                <p *ngIf="cliente.tlf_contacto !== ''" fxLayout="row" fxLayoutAlign="flex-start center" class="m-0 mb-4 secondary-text">
                    <mat-icon fontSet="fas" fontIcon="fa-phone-alt" class="mr-8"></mat-icon>
                    {{cliente.tlf_contacto}}
                </p>
            </div>
            <div *ngIf="cliente.direcciones?.length > 0" fxLayout="column">
                <p class="m-0 mt-4 mb-4 secondary-text font-weight-400">Direcciones</p>
                <p *ngFor="let direccion of cliente.direcciones" fxLayout="row" fxLayoutAlign="flex-start center" class="m-0 ml-8 secondary-text text-direccion">
                    <span fxLayout="row" fxLayoutAlign="flex-start center" class="mr-8" style="min-width: 110px;">
                        <mat-icon [fontSet]="direccion.direccion_tipo?.iconLib" [fontIcon]="direccion.direccion_tipo?.iconName" class="mr-8"></mat-icon>
                        <span class="font-weight-400">{{ direccion.direccion_tipo?.tipo_direccion }}</span>
                    </span>
                    {{ formatDireccion(direccion) }}
                </p>
            </div>
        </div>
    </ng-template>

    <div *ngIf="sinDispositivos" fxLayout="row" class="mt-16">
        <span class="m-0 my-16 secondary-text font-size-18">No se ha encontrado ningún dispositivo para el cliente seleccionado</span>
    </div>

    <div *ngIf="dispositivosEncontrados.length > 0" fxLayout="column" class="mt-16">
        <ng-container *ngIf="dispositivosEncontrados.length > 1">
            <p class="secondary-text m-0 font-size-14">Se ha encontrado mas de un dispositivo asociado al cliente. </p>
            <p class="secondary-text m-0 font-size-14">Verifica los datos del dispositivo para seleccionar uno del listado. </p>
        </ng-container>

        <div class="table-container table-dispositivo">
            <table mat-table [dataSource]="dataSourceDispositivos" class="w-100-p bloque-form">
                <ng-container matColumnDef="info_dispositivo">
                    <th mat-header-cell *matHeaderCellDef> Dispositivos </th>
                    <td mat-cell *matCellDef="let row" class="py-8">
                        <div fxLayout="column" class="py-8">
                            <p class="m-0 mb-8 secondary-text font-size-16 font-weight-600">{{ row.name }}</p>
                            <p class="m-0 mb-4 secondary-text">
                                <span class="font-weight-400 mr-8">Nº de serie: </span>
                                {{ row.serial ? row.serial : ' - ' }}
                            </p>
                            <p *ngIf="row.marca" class="m-0 mb-4 secondary-text">
                                <span class="font-weight-400 mr-8">Marca: </span>
                                {{ row.marca.name }}
                            </p>
                            <p *ngIf="row.modelo" class="m-0 mb-4 secondary-text">
                                <span class="font-weight-400 mr-8">Modelo: </span>
                                {{ row.modelo.name }}
                            </p>
                        </div>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="actions" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <div fxLayout="row wrap" fxLayoutAlign="center center">
                            <button *ngIf="accion === 'call-center'" mat-icon-button matTooltip="Crear ticket" (click)="openConfirmCrearTicket(row)">
                                <mat-icon fontSet="fas" fontIcon="fa-plus-square"></mat-icon>
                            </button>
                            <ng-container *ngIf="accion === 'digipass-consulta'">
                                <button mat-icon-button matTooltip="Generar QR Digipass" (click)="generarQrDigipass(row)">
                                    <mat-icon fontSet="fas" fontIcon="fa-qrcode"></mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Ver Digipass" (click)="verDigipass(row)">
                                    <mat-icon fontSet="far" fontIcon="fa-eye"></mat-icon>
                                </button>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumnsDispositivos, sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsDispositivos;"></tr>
            </table>
        </div>
    </div>
</div>