import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class TestDeviceService {

  	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
	) { }

	getAll(idProyecto): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				proyectos : idProyecto
			}

			const url = environment.API_URL + '/test-device?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getAll", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAll", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getTest(testId : number) : Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/test-device/' + testId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTest", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTest", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	saveTest(formData): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/test-device';
			this.http.post(url, formData, options).subscribe(
				(response: any) => {
					console.log("ws saveTest", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws saveTest", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	saveTestDeviceResult(testDeviceId : number, elementoId : number, formData): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				testDeviceId : testDeviceId,
				movimientoId : elementoId,
				resultValues : formData
			};

			const url = environment.API_URL + '/test-device/result';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws saveTestDeviceResult", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws saveTestDeviceResult", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getAllTestDeviceResultByMovimiento(idMovimiento : number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/test-device/result/find-by-movimiento/' + idMovimiento;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getAllTestDeviceResultByMovimiento", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAllTestDeviceResultByMovimiento", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getTestDeviceResult(deviceTestResultId : number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/test-device/result/' + deviceTestResultId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTestDeviceResult", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTestDeviceResult", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

}