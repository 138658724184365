import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { CygPermission } from '@fuse/types';
import { UserService } from 'app/services/user/user.service';
import { UserRoleService } from 'app/services/user/user-role.service';
import { AuthService } from 'app/services/auth/auth.service';
import { HelperService } from 'app/services/helper/helper.service';

@Component({
  selector: 'app-buscador-tickets',
  templateUrl: './buscador-tickets.component.html',
  styleUrls: ['./buscador-tickets.component.scss']
})
export class BuscadorTicketsComponent implements OnInit {

	typeSearchsConfig = [
		{ text: "ID Ticket", value: 1, iconLib: "fas", iconName: "tags", icon: "4k", environment: ['aitana','circpass', 'ewaste'] },
		{ text: "ID Fabricante", value: 2, iconLib: "fas", iconName: "industry", icon: "360", environment: ['aitana','circpass', 'ewaste'] },
		{ text: "ID Cliente", value: 3, iconLib: "fas", iconName: "user", icon: "accesss_alarms", environment: ['aitana'] },
		{ text: "N/S", value: 4, iconLib: "fas", iconName: "barcode", icon: "4k", environment: ['aitana','circpass', 'ewaste'] },
		{ text: "Factura cliente - ERP", value: 5, iconLib: "fas", iconName: "file-invoice", icon: "4k", environment: ['aitana'] },
		{ text: "Factura proveedor - ERP", value: 6, iconLib: "fas", iconName: "file-invoice", icon: "4k", environment: ['aitana'] },
		{ text: "N/S (todos proyectos)", value: 7, iconLib: "fas", iconName: "barcode", icon: "4k", environment: ['aitana'] },
		{ text: "ET", value: 8, iconLib: "fas", iconName: "truck", icon: "4k", environment: ['aitana'] },
		{ text: "N/S múltiple", value: 9, iconLib: "fas", iconName: "barcode", icon: "4k", environment: ['aitana'] },
	];
	typeSearchs: any[];
	typeSearchSelected: any;
	textSearch: string;

	@ViewChild('searchTickets') inputSearch: ElementRef;

	permisoMostrarEnlaceAngular: CygPermission;
	enlaceticket: boolean;

	constructor(
		private router: Router,
		private sanitizer: DomSanitizer,
		private userSvc: UserService,
		private authSvc: AuthService,
		private userRoleSvc: UserRoleService,
		private helperSvc: HelperService
	) {
		this.typeSearchs = this.typeSearchsConfig.filter((item) => item.environment.includes(environment.entorno));

		console.log("typesearch", this.typeSearchs);
		this.typeSearchSelected = this.typeSearchs[0];
		this.textSearch = "";
		this.permisoMostrarEnlaceAngular = { type: "item", name: "Aitana: Mostrar enlace ticket angular", active: true };
		this.enlaceticket = false;

	}

	ngOnInit(): void {
		// this.userRoleSvc.checkPermissions([this.permisoMostrarEnlaceAngular])
		// 	.then((permiso:boolean) => {
		// 		this.enlaceticket = permiso;
		// });
	}

	selectTypeSearch(item: any) {
		if (item.value === 9) { // N/S multiple
			const url = "/open-link?url="+environment.linkNewAitana+"/tickets/buscar-ns-multiple";

			window.open(url, '_blank');
		}
		else {
			this.typeSearchSelected = item;
			setTimeout(() => {
				this.inputSearch.nativeElement.focus();
			});
		}
	}

	search() {
		// console.log("Text search", this.textSearch);
		// console.log("TypeSearchSelected", this.typeSearchSelected);
		let url = "";
		let parametros = null;

		switch (this.typeSearchSelected.value) {
			case 1: // ID Aitana
				this.enlaceticket = true;
				if (environment.entorno === 'ewaste') {
					parametros = { idTicket: this.textSearch };
					url = "/recuperacion/proceso-recuperacion?" + this.helperSvc.serializeData(parametros);
				}
				else {
					parametros = { ID: this.textSearch };
					url = "/tickets?" + this.helperSvc.serializeData(parametros);
					//url = environment.linkNewAitana+"/tickets/update/"+this.textSearch;
					// url = "/tickets/gestion-nuevo-ticket/"+this.textSearch;
				}
				
				break;
			case 2: // ID Fabricante
				this.enlaceticket = true;
				if (environment.entorno === 'ewaste') {
					parametros = { weblordcase: this.textSearch };
					url = "/recuperacion/proceso-recuperacion?" + this.helperSvc.serializeData(parametros);
				}
				else {
					parametros = { casofabricante: this.textSearch };
					url = "/tickets?" + this.helperSvc.serializeData(parametros);
				}
				// if(this.enlaceticket) {
				// }
				// else {
				// 	url = environment.linkNewAitana+"/tickets/index?SearchTickets[ID]=%26SearchTickets[status]=todos%26SearchTickets[FK_entities]=%26SearchTickets[estado_cliente_fabricante]=%26SearchTickets[estado_interno]=%26SearchTickets[name]=%26SearchTickets[iris_incidencia]=%26SearchTickets[latitudCliente]=%26SearchTickets[date]=%26SearchTickets[date_mod]=%26SearchTickets[closedate]=%26SearchTickets[longitudCliente]=%26SearchTickets[assign]=%26SearchTickets[assign_ent]=%26SearchTickets[FK_group]=%26SearchTickets[assign_group]=%26SearchTickets[provinciaclienteSolicitante]=%26SearchTickets[poblacionclienteSolicitante]=%26SearchTickets[tlfclienteSolicitante]=%26SearchTickets[category]=%26SearchTickets[numserie]=%26SearchTickets[hora_inicio]=%26SearchTickets[marca]=%26SearchTickets[modelo]=%26SearchTickets[ninccliente]=%26SearchTickets[weblordcase]="+this.textSearch+"%26p_reset=1";
				// }
				break;
			case 3: // ID Cliente
				this.enlaceticket = true;
				parametros = { ninccliente: this.textSearch };
				url = "/tickets?" + this.helperSvc.serializeData(parametros);
				// url = environment.linkNewAitana+"/tickets/index?SearchTickets[ID]=%26SearchTickets[status]=todos%26SearchTickets[FK_entities]=%26SearchTickets[estado_cliente_fabricante]=%26SearchTickets[estado_interno]=%26SearchTickets[name]=%26SearchTickets[iris_incidencia]=%26SearchTickets[latitudCliente]=%26SearchTickets[date]=%26SearchTickets[date_mod]=%26SearchTickets[closedate]=%26SearchTickets[longitudCliente]=%26SearchTickets[assign]=%26SearchTickets[assign_ent]=%26SearchTickets[FK_group]=%26SearchTickets[assign_group]=%26SearchTickets[provinciaclienteSolicitante]=%26SearchTickets[poblacionclienteSolicitante]=%26SearchTickets[tlfclienteSolicitante]=%26SearchTickets[category]=%26SearchTickets[numserie]=%26SearchTickets[hora_inicio]=%26SearchTickets[marca]=%26SearchTickets[modelo]=%26SearchTickets[ninccliente]="+this.textSearch+"%26SearchTickets[weblordcase]=%26p_reset=1";
				break;
			case 4: // N/S
				this.enlaceticket = true;
				if (environment.entorno === 'ewaste') {
					parametros = { numserie: this.textSearch };
					url = "/recuperacion/proceso-recuperacion?" + this.helperSvc.serializeData(parametros);
				}
				else {
					parametros = { numeroserie: this.textSearch };
					url = "/tickets?" + this.helperSvc.serializeData(parametros);
				}
				// url = environment.linkNewAitana+"/tickets/index?SearchTickets[ID]=%26SearchTickets[status]=todos%26SearchTickets[FK_entities]=%26SearchTickets[estado_cliente_fabricante]=%26SearchTickets[estado_interno]=%26SearchTickets[name]=%26SearchTickets[iris_incidencia]=%26SearchTickets[latitudCliente]=%26SearchTickets[date]=%26SearchTickets[date_mod]=%26SearchTickets[closedate]=%26SearchTickets[longitudCliente]=%26SearchTickets[assign]=%26SearchTickets[assign_ent]=%26SearchTickets[FK_group]=%26SearchTickets[assign_group]=%26SearchTickets[provinciaclienteSolicitante]=%26SearchTickets[poblacionclienteSolicitante]=%26SearchTickets[tlfclienteSolicitante]=%26SearchTickets[category]=%26SearchTickets[numserie]="+this.textSearch+"%26SearchTickets[hora_inicio]=%26SearchTickets[marca]=%26SearchTickets[modelo]=%26SearchTickets[ninccliente]=%26SearchTickets[weblordcase]=%26p_reset=1";
				break;
			case 5: // Factura cliente - SAP
				url = environment.linkNewAitana+"/werb-services-sap/mostrar-fac-cliente-sap?factura="+this.textSearch;
				break;
			case 6: // Factura proveedor - SAP
				url = environment.linkNewAitana+"/werb-services-sap/mostrar-factura-proveedor-sap?factura="+this.textSearch;
				break;
			case 7: // N/S (todos proyectos)
				url = environment.linkNewAitana+"/tickets/indexns?SearchTickets[numserie]="+this.textSearch+"%26SearchTickets[status]=todos%26p_reset=1";
				break;
			case 8: // ET
				this.enlaceticket = true;
				parametros = { ID: this.textSearch };
				url = "/envio-transporte/expediciones?" + this.helperSvc.serializeData(parametros);
				// url = environment.linkNewAitana+"/envio-transporte/indexet?SearchEnvioTransporte[ID]="+this.textSearch+"%26p_reset=1";
				break;
		
			default:
				break;
		}

		if(this.enlaceticket){
			window.open(url, '_blank');
		}else{
			window.open("/open-link?url=" + url, '_blank');
		}
		
	}

}
