<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 fxLayout="row" fxLayoutAlign="flex-start center" class="m-0 mb-8">
        <mat-icon fontSet="fas" fontIcon="fa-align-left"class="mr-8"></mat-icon>
        Descripción
    </h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="mat-typography">
    <div fxLayout="column">
        <form fxLayout="column" [formGroup]="formDesc" class="w-100-p">
            <div class="form-group">
                <angular-editor id="editorDesc" formControlName="descripcion" [config]="editorConfig"></angular-editor>
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-button class="btn-aitana" [class.spinner]="loadingSave" [disabled]="loadingSave" (click)="guardarDescripcion()">Guardar</button>
</mat-dialog-actions>