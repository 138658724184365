import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { TestDeviceService } from 'app/services/test-device/test-device.service';

@Component({
  selector: 'app-dialog-form-test-device-result',
  templateUrl: './dialog-form-test-device-result.component.html',
  styleUrls: ['./dialog-form-test-device-result.component.scss']
})
export class DialogFormTestDeviceResultComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	loadingSave: boolean;

	formTestDeviceResult: FormGroup;

	testDeviceResultId : number;
	testDeviceId : number;
	elementoId : number;
	testDevice: any;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogFormTestDeviceResultComponent>,
		private dialog: MatDialog, 
		private formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private testDeviceSvc: TestDeviceService,
	) {
		moment.locale(this.translateService.currentLang);
		this._unsubscribeAll = new Subject();

		this.elementoId = data.elementoId;

		if(data.testDeviceResultId)
			this.testDeviceResultId = data.testDeviceResultId;
		else if (data.testDeviceId)
			this.testDeviceId = data.testDeviceId;
	
		this.formTestDeviceResult = this.formBuilder.group({});
	
		this.loadingSave = false;
	}

	ngOnInit(): void {
		if(this.testDeviceResultId) {
			this.loadTestDeviceResult();
		} else
			this.loadTestDeviceConfig();
	}

	ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 1000
		});
	}

	loadTestDeviceConfig() {
		this.testDeviceSvc.getTest(this.testDeviceId).subscribe((testDevice) => {
			this.setForm(testDevice);
		}, (error) => {
			this.showSnack("Ha ocurrido un error al cargar la configuración del test");
		});
	}

	setForm(testDevice) {
		this.testDevice = testDevice;

		for(let item of testDevice.testItems) {
			this.formTestDeviceResult.addControl(String(item.id), this.formBuilder.group({
				passed : true,
				lifeStatusPercentaje : [],
				comment : []
			}));
		}
	}

	loadTestDeviceResult() {
		this.testDeviceSvc.getTestDeviceResult(this.testDeviceResultId).subscribe((testDeviceResult) => {
			if(testDeviceResult && testDeviceResult.id_test_device) {
				this.testDeviceId = testDeviceResult.id_test_device;

				this.testDeviceSvc.getTest(this.testDeviceId).subscribe((testDevice) => {
					this.setForm(testDevice);

					for(let resultValue of testDeviceResult.testDeviceResultValues) {
						this.formTestDeviceResult.get(String(resultValue.test_device_item_id)).get('passed').setValue(resultValue.passed);
						this.formTestDeviceResult.get(String(resultValue.test_device_item_id)).get('lifeStatusPercentaje').setValue(resultValue.lifeStatusPercentaje);
						this.formTestDeviceResult.get(String(resultValue.test_device_item_id)).get('comment').setValue(resultValue.comment);
					}

				}, (error) => {
					this.showSnack("Ha ocurrido un error al cargar la configuración del test");
				});
			}
		}, (error) => {
			this.showSnack("Ha ocurrido un error al cargar el resultado del test");
		});
	}

	saveTestDeviceResult() {
		this.testDeviceSvc.saveTestDeviceResult(this.testDeviceId, this.elementoId, this.formTestDeviceResult.value).subscribe((data) => {
			this.showSnack("Guardado con éxito");
			this.dialogRef.close(data);
		}, (error) => {
			this.showSnack("Ha ocurrido un error al guardar");
		});
	}

}
