import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';
import { CygPermission } from '@fuse/types';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
	) { }

	checkPermissions(roles: CygPermission[]): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			const items = roles.filter((item) => { return item.type === 'item'; });
			const groups = roles.filter((item) => { return item.type === 'group'; });
	
			if (items.length > 0) {
				let accessItems = await this.checkItemsPermission(items);
				if (accessItems && groups.length > 0) {
					let accessGroups = await this.checkGroupsPermission(groups);
					resolve(accessItems && accessGroups);
				}
				else {
					resolve(accessItems);
				}	
			}
			else if (groups.length > 0) {
				// console.log("PERMISOS group", groups);
				let accessGroups = await this.checkGroupsPermission(groups);
				resolve(accessGroups);
			}
			else {
				resolve(true);
			}		
		});
	}

	// checkPermissions2(roles: CygPermission[]): Observable<boolean> {

	// 	return new Observable<boolean>(observer => {
	// 		const rolesActive = roles.filter((item) => {
	// 			return item.active;
	// 		});
	// 		const rolesNoActive = roles.filter((item) => {
	// 			return !item.active;
	// 		});
	// 		// console.log("rolesActive", rolesActive);
	// 		// console.log("rolesNoActive", rolesNoActive);

	// 		if (rolesActive.length > 0) {
	// 			const namesRolesActive = rolesActive.map((rol) => {
	// 				return rol.name;
	// 			});
	// 			this.checkAllRoles(namesRolesActive).subscribe(
	// 				(response: boolean) => {
	// 					// console.log("Response checkAllRoles", response);
	// 					if (response && rolesNoActive.length > 0) {
	// 						const namesRolesNoActive = rolesNoActive.map((rol) => {
	// 							return rol.name;
	// 						});
	// 						this.checkRole(namesRolesNoActive).subscribe(
	// 							(response2: boolean) => {
	// 								observer.next(!response2);
	// 							}
	// 						);
	// 					}
	// 					else {
	// 						observer.next(response);
	// 					}
	// 				}
	// 			)
	// 		}
	// 		else if (rolesNoActive.length > 0) {
	// 			const namesRoles = rolesNoActive.map((rol) => {
	// 				return rol.name;
	// 			});
	// 			this.checkRole(namesRoles).subscribe(
	// 				(response: boolean) => {
	// 					// const autorized = !response;
	// 					// if (!autorized) {
							
	// 					// }
	// 					observer.next(!response);
	// 				}
	// 			);
	// 		}
	// 		else {
	// 			observer.next(true);
	// 		}

	// 		return {unsubscribe() {}};
	// 	});
	// }

	async checkItemsPermission(itemsPermission: CygPermission[], operador?: string): Promise<boolean> {
		if (operador && operador === "OR") {
			return await this.checkItemsPermissionOr(itemsPermission);
		}
		else {
			return await this.checkItemsPermissionAnd(itemsPermission);
		}
	}

	async checkItemsPermissionAnd(itemsPermission: CygPermission[]): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
            const rolesActive = itemsPermission.filter((item) => { return item.active; });
			const rolesNoActive = itemsPermission.filter((item) => { return !item.active; });

			if (rolesActive.length > 0) {
				const namesRolesActive = rolesActive.map((rol) => {
					return rol.name;
				});
				this.checkAllRoles(namesRolesActive).subscribe(
					(response: boolean) => {
						// console.log("Response checkAllRoles", response);
						if (response && rolesNoActive.length > 0) {
							const namesRolesNoActive = rolesNoActive.map((rol) => {
								return rol.name;
							});
							this.checkRole(namesRolesNoActive).subscribe(
								(response2: boolean) => {
									resolve(!response2);
								}
							);
						}
						else {
							resolve(response);
						}
					}
				);
			}
			else if (rolesNoActive.length > 0) {
				const namesRoles = rolesNoActive.map((rol) => {
					return rol.name;
				});
				this.checkRole(namesRoles).subscribe(
					(response: boolean) => {
						resolve(!response);
					}
				);
			}
			else {
				resolve(true);
			}
        });
	}
	async checkItemsPermissionOr(itemsPermission: CygPermission[]): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
            const rolesActive = itemsPermission.filter((item) => { return item.active; });
			const rolesNoActive = itemsPermission.filter((item) => { return !item.active; });

			if (rolesActive.length > 0) {
				const namesRolesActive = rolesActive.map((rol) => { return rol.name; });

				this.checkRole(namesRolesActive).subscribe(
					(response: boolean) => {
						// console.log("Response checkAllRoles", response);
						if (!response && rolesNoActive.length > 0) {
							const namesRolesNoActive = rolesNoActive.map((rol) => { return rol.name; });

							this.checkAllRoles(namesRolesNoActive).subscribe(
								(response2: boolean) => {
									resolve(!response2);
								}
							);
						}
						else {
							resolve(response);
						}
					}
				);
			}
			else if (rolesNoActive.length > 0) {
				const namesRoles = rolesNoActive.map((rol) => { return rol.name; });

				this.checkAllRoles(namesRoles).subscribe(
					(response: boolean) => {
						resolve(!response);
					}
				);
			}
			else {
				resolve(true);
			}
        });
	}
	private checkGroupsPermission(groupsPermission: CygPermission[], operador?: string): Promise<boolean> {
		return new Promise<boolean>(async (resolve) => {
            const groupsOr = groupsPermission.filter((item) => { return item.op === 'OR'; });
            const groupsAnd = groupsPermission.filter((item) => { return item.op === 'AND'; });
			const operadorGroup = operador ? operador : "AND";

			let access = false;
			for (const group of groupsOr) {
				let accessGroup = await this.checkGroupPermissionOr(group);
				// console.log("accessGroup final", accessGroup);
				if (operadorGroup === 'OR') {
					if (accessGroup) {
						access = accessGroup;
						break;
					}
				}
				else {
					access = !accessGroup ? false : true;
					if (!access) {
						break;
					}
				}
			}
			if ((operadorGroup === 'AND' && access) || (operadorGroup === 'OR' && !access)) {
				for (const group of groupsAnd) {
					let accessGroup = await this.checkGroupPermissionAnd(group);
					if (operadorGroup === 'OR') {
						if (accessGroup) {
							access = accessGroup;
							break;
						}
					}
					else {
						access = !accessGroup ? false : true;
						if (!access) {
							break;
						}
					}
				}
			}

			resolve(access);
        });
	}
	private checkGroupPermissionOr(groupPermission: CygPermission): Promise<boolean> {
		return new Promise<boolean>(async (resolve) => {
			const items = groupPermission.items.filter((item) => { return item.type === 'item'; });
			const groups = groupPermission.items.filter((item) => { return item.type === 'group'; });			

			if (items.length > 0) {
				let accessItems = await this.checkItemsPermission(items, groupPermission.op);
				if (!accessItems && groups.length > 0) {
					let accessGroup = await this.checkGroupsPermission(groups, groupPermission.op);
					resolve (accessGroup);
				}
				else {
					resolve(accessItems);
				}
			}
			else if (groups.length > 0) {
				let accessGroup = await this.checkGroupsPermission(groups, groupPermission.op);
				resolve (accessGroup);
			}
			else {
				resolve(false);
			}
        });
	}
	private checkGroupPermissionAnd(groupPermission: CygPermission): Promise<boolean> {
		return new Promise<boolean>(async (resolve) => {
			const items = groupPermission.items.filter((item) => { return item.type === 'item'; });
			const groups = groupPermission.items.filter((item) => { return item.type === 'group'; });			

			if (items.length > 0) {
				let accessItems = await this.checkItemsPermission(items, groupPermission.op);
				if (accessItems && groups.length > 0) {
					let accessGroup = await this.checkGroupsPermission(groups, groupPermission.op);
					resolve (accessItems && accessGroup);
				}
				else {
					resolve(accessItems);
				}
			}
			else if (groups.length > 0) {
				let accessGroup = await this.checkGroupsPermission(groups, groupPermission.op);
				resolve (accessGroup);
			}
			else {
				resolve(false);
			}
        });
	}
	
	// Devuelve true al tener el usuario al menos uno de los roles del array
	checkRole(roles: string[]): Observable<boolean> {
		return new Observable<boolean>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const body = {
				"roles": roles
			}

			const url = environment.API_URL + '/usuarios/check-role';
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					// console.log("ws checkRole", response);
					observer.next(true);
				},
				(err) => {
					console.log("error ws checkRole", err);
					observer.next(false);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	// Devuelve true si el usuario tiene todos los roles del array
	checkAllRoles(roles: string[]): Observable<boolean> {
		return new Observable<boolean>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const body = {
				"roles": roles
			}

			const url = environment.API_URL + '/usuarios/check-all-roles';
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					// console.log("ws checkAllRoles", response);
					observer.next(true);
				},
				(err) => {
					console.log("error ws checkAllRoles", err);
					observer.next(false);
				}
			);

			return {unsubscribe() {}};
		});
	}
}
