export enum EventType {
    VISIT_URL = 'VISIT_URL'
}

export enum AppName {
    DIGIPASS = 'DIGIPASS'
}

export interface EventAistatistics{
    appName : AppName;
    type : EventType;
    entity? : number;
    data : Object;
}