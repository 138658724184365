export const locale = {
    lang: 'en',
    data: {
        'MAT_PAGINATOR': {
            'OF': 'of',
            'FIRST_PAGE': 'First page',
            'ITEMS_PER_PAGE': 'Items per page',
            'LAST_PAGE': 'Last page',
            'NEXT_PAGE': 'Next page',
            'PREVIOUS_PAGE': 'Previous page'
        },
        'DATE_RANGES': {
            'ELEGIR_RANGO': 'Choose range',
            'HOY': 'Today',
            'AYER': 'Yesterday',
            'ULTIMOS_7_DIAS': 'Last 7 days',
            'ULTIMOS_30_DIAS': 'Last 30 days',
            'ESTE_MES': 'This month',
            'MES_ANTERIOR': 'Last month'
        },
        'COMUN': {
            'SI': 'Yes',
            'NO': 'No',
            'APLICAR': 'Apply',
            'APLICAR_FILTRO': 'Apply filter',
            'GUARDAR': 'Save',
            'CANCELAR': 'Cancel',
            'REINICIAR': 'Restart',
            'EDITAR': 'Edit',
            'BUSCAR': 'Search',
            'NO_RESULTADOS': 'No results were found',
            'ANADIR_FILTRO': 'Add filter',
            'EDITAR_FILTRO': 'Edit filter',
            'RESTABLECER': 'Restore',
            'GUARDAR_FILTRO': 'Save filter',
            'MIS_FILTROS': 'My filters',
            'NOMBRE_FILTRO': 'Filter name',
            'FILTROS_GUARDADOS': 'Saved filters',
            'CONFIGURACION_FILTRO': 'Filter Settings',
            'ELIMINAR_FILTRO': 'Remove filter',
            'ELIMINAR_FILTRO_MSG': 'Filter {{filterName}} is going to be deleted, are you sure?',
            'FILTRO_GUARDADO': 'Saved filter',
            'FILTRO_GUARDAR_ERROR_NOMBRE': 'You must provide a name for the filter',
            'NO_FILTROS_GUARDADOS': 'You have no saved filters',
            'GUARDAR_ORDENACION': 'Save sort',
            'NOMBRE_ORDEN': 'Sort name',
            'ORDEN_GUARDADAS': 'Saved sorts',
            'CONFIGURACION_ORDEN': 'Sort Settings',
            'ELIMINAR_ORDEN': 'Remove sort',
            'ELIMINAR_ORDEN_MSG': 'The sort {{ordenName}} is going to be deleted, are you sure?',
            'ORDEN_GUARDADO': 'Saved sort',
            'ORDEN_GUARDADO_ERROR_NOMBRE': 'You must provide a name for the sort',
            'NO_ORDEN_GUARDADAS': 'You have no saved sorts',
            'NO_ORDEN_APLICADO': 'You don\'t have any order applied',
            'CONFIGURAR_TABLA': 'Configure table',
            'CONFIGURACION_TABLA': 'Table settings',
            'UNA_COLUMNA_VISIBLE': 'There must be at least one column visible',
            'CONFIGURACION_GUARDADA': 'Saved configuration',
            'FILAS_POR_PAGINA': 'Rows per page',
            'FILTRO': 'Filter',
            'ORDEN': 'Sort',
            'ORDEN_VISIBILIDAD_COLUMNAS': 'Column order and visibility',
            'COLUMNAS_VISIBLES': 'Visible columns',
            'COLUMNAS_OCULTAS': 'Hidden columns',
            'CARGANDO_DATOS': 'Loading data',
            'DATOS_GUARDADOS': 'Datos guardados correctamente',
            'ERROR_GUARDAR': 'No se pudieron guardar los datos',
        },
        'BUSCADOR': {
            'BUSCAR': "Search menu"
        },
        'CLIENTES': {
            'CREAR': "Cliente NETSUITE"
        },
        'PROVEEDORES': {
            'CREAR': "Nuevo proveedor",
            'MODIFICAR': "Modificar proveedor",
        }
    }
};