<!-- <div id="buscador-ns">
    <div class="buscador-ns" fxLayout="row">

        <div appearance="outline" fxLayout="row" fxLayoutAlign="space-between center" class="">
            <button mat-icon-button class="btn-type-search secondary-text" [matMenuTriggerFor]="searchNssMenu">
                <fa-icon class="secondary-text" [icon]="[typeSearchSelected?.iconLib, typeSearchSelected?.iconName]"></fa-icon>
                <!-- <mat-icon [fontSet]="typeSearchSelected?.iconLib" [fontIcon]="typeSearchSelected?.iconName"></mat-icon> -->
                <!-- <mat-icon>{{typeSearchSelected?.icon}}</mat-icon> -->
            <!-- </button>
            <input #searchNss name="filterNss" class="input-search-Nss secondary-text" [(ngModel)]="textSearch" [placeholder]="typeSearchSelected?.text + '...'" (keydown.enter)="search()">
            <button mat-icon-button class="secondary-text" (click)="search()">
                <mat-icon >search</mat-icon>
            </button>
        </div>

        <mat-menu #searchNssMenu="matMenu" [overlapTrigger]="false">
            <button *ngFor="let item of typeSearchs" fxLayout="row" class="btn-menu-search-Ns" mat-menu-item (click)="selectTypeSearch(item)">
                <fa-icon class="secondary-text" style="width: 30px" [icon]="[item.iconLib, item.iconName]"></fa-icon> -->
                <!-- <mat-icon [fontSet]="item.iconLib" [fontIcon]="item.iconName"></mat-icon> -->
                <!-- <mat-icon>{{item.icon}}</mat-icon> -->
                <!-- <span style="flex: 1">{{ item?.text }}</span>
            </button>
        </mat-menu>

    </div>
</div> --> 


<mat-card>
    <mat-card-header>
    <div id="buscador-ns">
        <label style="font-size: 30px;">Introduzca Número de Serie</label><br>
        <div class="buscador-ns">            
            <input  #searchNss name="filterNss" class="input-search-ns secondary-text" [(ngModel)]="textSearch"  (keydown.enter)="searchv5()" autofocus>
            <button mat-icon-button (click)="searchv5()">
                <mat-icon class="icon-display">search</mat-icon>
            </button>
            <h1 *ngIf="mostrarMensaje">{{mensajeUsuario}}</h1>

        </div>
        <div *ngIf="mostrarOpciones">
            <br>
             <form [formGroup]="formEstadoInterno"> 
                <label style="font-size: 20px;">Selecciona el proceso siguiente</label><br><br>
                <mat-select  *ngIf="mostrarOpciones" formControlName="estado_interno_permitido" class="lista" (selectionChange)="selectEstado($event)">              
                    <mat-option *ngFor="let itemEstadoInterno of estados_internos_permitidos" [value]="itemEstadoInterno.id">{{itemEstadoInterno.name}}</mat-option>
                </mat-select>
        </form> 
        </div>
    </div>
      
    </mat-card-header>
</mat-card>