import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { UserService } from 'app/services/user/user.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

@Component({
  selector: 'app-buscador-menu',
  templateUrl: './buscador-menu.component.html',
  styleUrls: ['./buscador-menu.component.scss']
})
export class BuscadorMenuComponent implements OnInit {

	textSearch: string;
	@ViewChild('searchMenu') inputSearch: ElementRef;

	constructor(
		private _fuseNavigationService: FuseNavigationService,
		private _changeDetectorRef: ChangeDetectorRef,
		private userService: UserService,
		private translateService: TranslateService
	) {
		this.textSearch = "";
	}

	ngOnInit(): void {}

	clearSearch() {
		this.textSearch = '';
		let navigation = this._fuseNavigationService.getCurrentNavigation();
		this.muestraElementosMenu(navigation);
	}

	async search() {
		let navigation = this._fuseNavigationService.getCurrentNavigation();
		if(navigation && this.textSearch) {
			await this.ocultaElementosMenu(navigation, true);
		}
		else if(navigation && !this.textSearch) {
			await this.muestraElementosMenu(navigation);
		}
	}

	async ocultaElementosMenu(elementosMenu, esPadreSuperior) {
		let ocultados = 0;
		for(let item of elementosMenu) {
			if(item.children) {
				let numChildren = item.children.length;
				let hiddenItems = await this.ocultaElementosMenu(item.children, false);
				if(numChildren == hiddenItems && !esPadreSuperior) {
					this.userService.onMenuFiltered.next({itemId: item.id, hidden: true});
					ocultados++
				}
				else {
					this.userService.onMenuFiltered.next({itemId: item.id, hidden: false});
				}
			}
			else {
				let tagsCoincidentes = null;
				if(item.tags) {
					tagsCoincidentes = item.tags.find((itemTag) => {
						return this.translateService.instant(itemTag).toLowerCase().includes(this.textSearch.toLowerCase()) 
					});
				}
				
				let traduccion = item.translate ? this.translateService.instant(item.translate).toLowerCase() : '';
				if(!item.translate || (!traduccion.includes(this.textSearch.toLowerCase()) && !tagsCoincidentes)) {
					this.userService.onMenuFiltered.next({itemId: item.id, hidden: true});
					ocultados++;
				}else {
					this.userService.onMenuFiltered.next({itemId: item.id, hidden: false});
				}
			}
		}

		return ocultados;
	}

	async muestraElementosMenu(elementosMenu) {
		for(let item of elementosMenu) {
			if(item.children) {
				await this.muestraElementosMenu(item.children);
				this.userService.onMenuFiltered.next({itemId: item.id, hidden: false});
			}
			else {
				this.userService.onMenuFiltered.next({itemId: item.id, hidden: false});
			}
		}
	}
}
