<div id="buscador-tickets">
    <div class="buscador-tickets" fxLayout="row">

        <div appearance="outline" fxLayout="row" fxLayoutAlign="space-between center" class="">
            <button mat-icon-button class="btn-type-search secondary-text" [matMenuTriggerFor]="searchTicketsMenu">
                <fa-icon class="secondary-text" [icon]="[typeSearchSelected?.iconLib, typeSearchSelected?.iconName]"></fa-icon>
                <!-- <mat-icon [fontSet]="typeSearchSelected?.iconLib" [fontIcon]="typeSearchSelected?.iconName"></mat-icon> -->
                <!-- <mat-icon>{{typeSearchSelected?.icon}}</mat-icon> -->
            </button>
            <input #searchTickets name="filterTickets" class="input-search-tickets secondary-text" [(ngModel)]="textSearch" [placeholder]="typeSearchSelected?.text + '...'" (keydown.enter)="search()" autocomplete="off">
            <button mat-icon-button class="secondary-text" (click)="search()">
                <mat-icon >search</mat-icon>
            </button>
        </div>

        <mat-menu #searchTicketsMenu="matMenu" [overlapTrigger]="false">
            <button *ngFor="let item of typeSearchs" fxLayout="row" class="btn-menu-search-ticket" mat-menu-item (click)="selectTypeSearch(item)">
                <fa-icon class="secondary-text" style="width: 30px" [icon]="[item.iconLib, item.iconName]"></fa-icon>
                <!-- <mat-icon [fontSet]="item.iconLib" [fontIcon]="item.iconName"></mat-icon> -->
                <!-- <mat-icon>{{item.icon}}</mat-icon> -->
                <span style="flex: 1">{{ item?.text }}</span>
            </button>
        </mat-menu>

    </div>
</div>