import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { GridConfigService } from 'app/services/grid/grid-config.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { ClienteService } from 'app/services/cliente/cliente.service';
import { DireccionesService } from 'app/services/direcciones/direcciones.service';

@Component({
  selector: 'app-dialog-selected-filtro-direcciones',
  templateUrl: './dialog-selected-filtro-direcciones.component.html',
  styleUrls: ['./dialog-selected-filtro-direcciones.component.scss']
})
export class DialogSelectedFiltroDireccionesComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	customRanges: any;
	localeRangeDate: any;

	idColaborador: number;

	tituloDialog: string;
	datosFiltro: any;
	formFiltro: FormGroup;

	direccionTipos: Array<any>;
    tiposVia: Array<any> = [];

	localidades: Array<any>;
	localidadesFilterCtrl: FormControl;
	localidadesSearching: boolean = false;
	localidadesSelected: any;

	provincias: Array<any>;
	provinciasFilterCtrl: FormControl;
	provinciasSearching: boolean = false;
	provinciasSelected: any;

	paises: Array<any>;
	paisesFilterCtrl: FormControl;
	paisesSearching: boolean = false;
	paisesSelected: any;

	comboSiNo: Array<any>;
	valoracionesValor: number = 0;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogSelectedFiltroDireccionesComponent>,
		private formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private gridConfigService: GridConfigService,
		private direccionesService: DireccionesService,
		private clienteService: ClienteService,
	) {
		this._unsubscribeAll = new Subject();
		moment.locale(this.translateService.currentLang);

		if(this.data && this.data.idColaborador)
			this.idColaborador = this.data.idColaborador;

		this.localeRangeDate = {
			applyLabel: 'OK', 
			format: 'DD-MM-YYYY', 
			customRangeLabel: 'Elegir rango',
			daysOfWeek: moment.weekdaysMin(),
    		monthNames: moment.monthsShort(),
		};
		this.customRanges = {
			'Hoy': [moment(), moment()],
			'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
			'Últimos 7 días': [moment().subtract(6, 'days'), moment()],
			'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
			'Este mes': [moment().startOf('month'), moment().endOf('month')],
			'Mes anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
		};

		this.tituloDialog = '';

		this.direccionTipos = [];

		this.localidades = [];
		this.localidadesFilterCtrl = new FormControl();

		this.provincias = [];
		this.provinciasFilterCtrl = new FormControl();

		this.paises = [];
		this.paisesFilterCtrl = new FormControl();

		this.comboSiNo = this.gridConfigService.getDataComboSiNo();
	}

	ngOnInit(): void {
		if (this.data.datosFiltro) {
			this.datosFiltro = this.data.datosFiltro;
			if (this.datosFiltro.value) {
				this.tituloDialog = "Editar filtro";
			}
			else {
				this.tituloDialog = "Añadir filtro";
			}

			let formControls = {};
			formControls[this.datosFiltro.name] = [''];
			this.formFiltro = this.formBuilder.group(formControls);

			if (this.datosFiltro.name == 'valoracion') {
				this.valoracionesValor = this.datosFiltro.value;
			}
			else if (this.datosFiltro.name == 'direccion_tipos') {
				this.loadDireccionTipos();
			}
			else if (this.datosFiltro.name == 'localidad') {
				let localidadCtrl = this.formFiltro.get('localidad');
				localidadCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
					const objectoBuscado = this.localidades.find((item) => {
						return item.id === value;
					});
					this.localidadesSelected = objectoBuscado;
				});
				this.setLocalidad();
			}
			else if (this.datosFiltro.name == 'provincia') {
				let provinciaCtrl = this.formFiltro.get('provincia');
				provinciaCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
					const objectoBuscado = this.provincias.find((item) => {
						return item.id === value;
					});
					this.provinciasSelected = objectoBuscado;
				});
				this.setProvincia();
			}
			else if (this.datosFiltro.name == 'provincias') {
				let provinciaCtrl = this.formFiltro.get('provincias');
				provinciaCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
					const objectoBuscado = this.provincias.find((item) => {
						return item.id === value;
					});
					this.provinciasSelected = objectoBuscado;
				});
				this.setProvincia();
			}
			else if (this.datosFiltro.name == 'pais') {
				let paisCtrl = this.formFiltro.get('pais');
				paisCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
					const objectoBuscado = this.paises.find((item) => {
						return item.id === value;
					});
					this.paisesSelected = objectoBuscado;
				});
				this.setPais();
			}else if (this.datosFiltro.name == 'tipo_via') {
				this.loadTiposVia();
			}
			else {
				if (this.datosFiltro.value) {
					if (this.datosFiltro.type == 'select') {
						this.formFiltro.get(this.datosFiltro.name).setValue(this.datosFiltro.value.id);
					}
					else {
						this.formFiltro.get(this.datosFiltro.name).setValue(this.datosFiltro.value);
					}
				}
			}
		}

		this.localidadesFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((filterValue) => {
			if (filterValue.length > 3) {
				this.localidadesSearching = true;
				this.loadLocalidades(filterValue);
			}
			else {
				this.localidades = [];
				if (this.localidadesSelected) {
					this.localidades.push(this.localidadesSelected);
				}
			}
		});

		this.provinciasFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((filterValue) => {
			if (filterValue.length > 2) {
				this.provinciasSearching = true;
				this.loadProvincias(filterValue);
			}
			else {
				this.provincias = [];
				if (this.provinciasSelected) {
					this.provincias.push(this.provinciasSelected);
				}
			}
		});

		this.paisesFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((filterValue) => {
			if (filterValue.length > 0) {
				this.paisesSearching = true;
				this.loadPaises(filterValue);
			}
			else {
				this.paises = [];
				if (this.paisesSelected) {
					this.paises.push(this.paisesSelected);
				}
			}
		});
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}

	checkValue(formControlName: string) {
		let formControl = this.formFiltro.get(formControlName);
		if (formControl) {
			return formControl.value !== "";
		}
		else {
			return false;
		}
	}
	
	clearValue(formControlName: string, event?) {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		let formControl = this.formFiltro.get(formControlName);
		if (formControl) {
			formControl.setValue('');
		}

		switch (formControlName) {
			case 'localidad':
				this.localidadesSelected = null; break;

			case 'provincia':
			case 'provincias':
				this.provinciasSelected = null; break;

			case 'pais':
				this.paisesSelected = null; break;
		}
	}

	guardarDatos() {
		const formValue = this.formFiltro.getRawValue();
		let result = null;

		switch (this.datosFiltro.type) {
			case 'text':
				const value = formValue[this.datosFiltro.name];
				result = value !== '' ? value : null;
				break;

			case 'date':
				const dateRange = formValue[this.datosFiltro.name];
				result = (dateRange !== '' && dateRange.startDate != null) ? dateRange : null;
				break;

			case 'select':
					if (this.datosFiltro.name == 'valoracion') {
						result = this.valoracionesValor;
					}
					else if (this.datosFiltro.name == 'direccion_tipos') {
						if (formValue.direccion_tipos !== '') {
							const objetoResultado = this.direccionTipos.find((item) => { return item.id == formValue.direccion_tipos });
							result = objetoResultado;
						}
					}
					else if (this.datosFiltro.name == 'localidad') {
						if (formValue.localidad !== '') {
							const objetoResultado = this.localidades.find((item) => { return item.id == formValue.localidad });
							result = objetoResultado;
						}
					}
					else if (this.datosFiltro.name == 'provincia') {
						if (formValue.provincia !== '') {
							const objetoResultado = this.provincias.find((item) => { return item.id == formValue.provincia });
							result = objetoResultado;
						}
					}
					else if (this.datosFiltro.name == 'provincias') {
						if (formValue.provincias !== '') {
							const objetoResultado = this.provincias.find((item) => { return item.id == formValue.provincias });
							result = objetoResultado;
						}
					}
					else if (this.datosFiltro.name == 'pais') {
						if (formValue.pais !== '') {
							const objetoResultado = this.paises.find((item) => { return item.id == formValue.pais });
							result = objetoResultado;
						}
					}
					else {
						if (formValue[this.datosFiltro.name] !== '') {
							const comboItem = this.comboSiNo.find((item) => { return item.id == formValue[this.datosFiltro.name] });
							result = comboItem;
						}
					}
				break;

			// case 'multiple':
			// 	if (this.datosFiltro.name == 'FK_entities') {
			// 		result = this.proyectosSelected;
			// 	}
			// 	break;
		
			default:
				break;
		}

		this.dialogRef.close({ filterValue: result });
	}

	loadDireccionTipos(): void{
		if(this.idColaborador)
			this.direccionesService.getTiposDireccionColaborador(this.idColaborador).subscribe(
				(data: any) => {
					this.direccionTipos = this.gridConfigService.convertDataForFilter(data, 'ID', 'name');
					if (this.datosFiltro.value) {
						this.formFiltro.get('direccion_tipos').setValue(this.datosFiltro.value.id);
					}
				},
				(err) => {
					this.showSnack(err);
				}
			);
		else
			this.direccionesService.getTiposDireccion().subscribe(
				(data: any) => {
					this.direccionTipos = this.gridConfigService.convertDataForFilter(data, 'ID', 'name');
					if (this.datosFiltro.value) {
						this.formFiltro.get('direccion_tipos').setValue(this.datosFiltro.value.id);
					}
				},
				(err) => {
					this.showSnack(err);
				}
			);
	}

    loadTiposVia(): void{
        this.direccionesService.getTiposVia(this.idColaborador?this.idColaborador:null).subscribe(
            (data: any) => {
                this.tiposVia = this.gridConfigService.convertDataForFilter(data, 'ID', 'name');
                if (this.datosFiltro.value) {
                    this.formFiltro.get('tipo_via').setValue(this.datosFiltro.value.id);
                }
            },
            (err) => {
                this.showSnack(err);
            }
        );
    }

	loadLocalidades(filter): void {
		this.clienteService.getLocalidades(filter).subscribe(
			(data:any) => {
				this.localidadesSearching = false;
				this.localidades = this.gridConfigService.convertDataForFilter(data, 'idpoblacion', 'poblacion');
			},
			(err) => {
				this.localidadesSearching = false;
				this.showSnack(err);
			}
		);
	}

	setLocalidad(): void {
		if (this.datosFiltro.value) {
			const objectoBuscado = this.localidades.find((item) => {
				item.id === this.datosFiltro.value.id;
			});
			if (!objectoBuscado) {
				this.localidades.push(this.datosFiltro.value);
			}
			this.localidadesSelected = this.datosFiltro.value;
			this.formFiltro.get('localidad').setValue(this.datosFiltro.value.id);
		}
	}

	loadProvincias(filter): void {
		this.clienteService.getProvincias(null, filter).subscribe(
			(data:any) => {
				this.provinciasSearching = false;
				this.provincias = this.gridConfigService.convertDataForFilter(data, 'idprovincia', 'provincia');
			},
			(err) => {
				this.provinciasSearching = false;
				this.showSnack(err);
			}
		);
	}

	setProvincia(): void {
		if (this.datosFiltro.value) {
			const objectoBuscado = this.provincias.find((item) => {
				item.id === this.datosFiltro.value.id;
			});
			if (!objectoBuscado) {
				this.provincias.push(this.datosFiltro.value);
			}
			this.provinciasSelected = this.datosFiltro.value;

			if(this.datosFiltro.name == 'provincia') {
				this.formFiltro.get('provincia').setValue(this.datosFiltro.value.id);
			}
			else {
				this.formFiltro.get('provincias').setValue(this.datosFiltro.value.id);
			}
		}
	}

	loadPaises(filter): void {
		this.clienteService.getPaises(filter).subscribe(
			(data:any) => {
				this.paisesSearching = false;
				this.paises = this.gridConfigService.convertDataForFilter(data, 'id', 'pais');
			},
			(err) => {
				this.paisesSearching = false;
				this.showSnack(err);
			}
		);
	}

	setPais(): void {
		if (this.datosFiltro.value) {
			const objectoBuscado = this.paises.find((item) => {
				item.id === this.datosFiltro.value.id;
			});
			if (!objectoBuscado) {
				this.paises.push(this.datosFiltro.value);
			}
			this.paisesSelected = this.datosFiltro.value;
			this.formFiltro.get('pais').setValue(this.datosFiltro.value.id);
		}
	}

	changeFiltroValoracion(value) {
		this.valoracionesValor = value;
	}

}
