import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

	onNoLeidasChange: BehaviorSubject<any>;

  	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
	) {
		this.onNoLeidasChange = new BehaviorSubject(null);
	}

	getNoLeidas(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/notificaciones/no-leidas';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getNotificaciones no leidas", response);
					// this.onNoLeidasChange.next(true);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getNotificaciones no leidas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	marcarComoLeida(idNotificacion): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/notificaciones/marcar-leida/'+idNotificacion;
			this.http.post(url, {}, options).subscribe(
				(response: any) => {
					console.log("ws marcarNotificacionLeida", response);
					this.onNoLeidasChange.next(true);
					observer.next(response);
				},
				(err) => {
					console.log("error ws marcarNotificacionLeida", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
}
