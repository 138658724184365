import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';
import { data } from '@tensorflow/tfjs';

@Injectable({
  providedIn: 'root'
})
export class CitasService {

  	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
	) { }

	getTiposCita(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/citas/tipos-cita';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposCitas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposCitas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getAyudantes(idCita:number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/citas/ayudantes/'+idCita;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getAyudantes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAyudantes", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getEstadosCita(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/citas/estados-cita';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getEstadosCitas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getEstadosCitas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getMotivosEstados(idProyecto: number, idEstadoCita): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				idProyecto: idProyecto,
				idEstadoCita: idEstadoCita
			}

			const url = environment.API_URL + '/citas/motivos-estados?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getMotivosEstados", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getMotivosEstados", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getTiposDireccionCita(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/citas/tipos-direccion-cita';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposDireccionCitas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposDireccionCitas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getDireccionesCita(tipoDireccion: number, idTicket: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				tipo_direccion: tipoDireccion,
				id_ticket: idTicket
			}

			const url = environment.API_URL + '/citas/direcciones-cita-by-tipo?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDireccionesCita", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDireccionesCita", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getCitas(idTicket: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				id_ticket: idTicket
			}

			const url = environment.API_URL + '/citas?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getCitas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getCitas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getAllCitas(page: number, size: number, order: any, dir: any, filtros: any, all: boolean = false): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

            console.log("Los filtros", filtros);

			const params = {page: page, pageSize: size, allData: all ? all : false};

            if (dir !== "") {
                params['sortColum'] = order;
                params['sortDirection'] = dir;
            }
            
            if(filtros.fk_entity && filtros.fk_entity != ""){
                params['fk_entity'] = filtros.fk_entity;
            }

            if(filtros.tecnico && filtros.tecnico != ""){
                params['tecnico'] = filtros.tecnico;
            }

            if(filtros.tipo_direccion && filtros.tipo_direccion != ""){
                params['tipo_direccion'] = filtros.tipo_direccion;
            }

            if(filtros.direccion && filtros.direccion != ""){
                params['direccion'] = filtros.direccion;
            }

            if(filtros.ticket && filtros.ticket != ""){
                params['ticket'] = filtros.ticket;
            }

            if(filtros.tat && filtros.tat != ""){
                params['tat'] = filtros.tat;
            }

            if (filtros.fecha_creacion && filtros.fecha_creacion.startDate && filtros.fecha_creacion.startDate !== null 
				&& filtros.fecha_creacion.endDate && filtros.fecha_creacion.endDate !== null) {
				params['fechaCreacionInicio'] = moment(filtros.fecha_creacion.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaCreacionFin'] = moment(filtros.fecha_creacion.endDate).format('YYYY-MM-DD HH:mm:ss');
			}

            if (filtros.fecha_modificacion && filtros.fecha_modificacion.startDate && filtros.fecha_modificacion.startDate !== null 
				&& filtros.fecha_modificacion.endDate && filtros.fecha_modificacion.endDate !== null) {
				params['fechaModificacionInicio'] = moment(filtros.fecha_modificacion.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaModificacionFin'] = moment(filtros.fecha_modificacion.endDate).format('YYYY-MM-DD HH:mm:ss');
			}

            if (filtros.fecha_validacion && filtros.fecha_validacion.startDate && filtros.fecha_validacion.startDate !== null 
				&& filtros.fecha_validacion.endDate && filtros.fecha_validacion.endDate !== null) {
				params['fechaValidacionInicio'] = moment(filtros.fecha_validacion.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaValidacionFin'] = moment(filtros.fecha_validacion.endDate).format('YYYY-MM-DD HH:mm:ss');
			}

            if (filtros.fecha_cita && filtros.fecha_cita.startDate && filtros.fecha_cita.startDate !== null 
				&& filtros.fecha_cita.endDate && filtros.fecha_cita.endDate !== null) {
				params['fechaCitaInicio'] = moment(filtros.fecha_cita.startDate).format('YYYY-MM-DD HH:mm:ss');
				params['fechaCitaFin'] = moment(filtros.fecha_cita.endDate).format('YYYY-MM-DD HH:mm:ss');
			}

            if(filtros.usuario_creacion && filtros.usuario_creacion != ""){
                params['usuario_creacion'] = filtros.usuario_creacion;
            }

            if(filtros.tipo_cita && filtros.tipo_cita != ""){
                params['tipo_cita'] = filtros.tipo_cita;
            }

            if(filtros.estado && filtros.estado != ""){
                params['estado'] = filtros.estado;
            }

            if(filtros.confirmar_reparacion && filtros.confirmar_reparacion != ""){
                params['confirmar_reparacion'] = filtros.confirmar_reparacion;
            }

            if(filtros.comentario && filtros.comentario != ""){
                params['comentario'] = filtros.comentario;
            }

            if(filtros.kms_validados && filtros.kms_validados != ""){
                params['kms_validados'] = filtros.kms_validados;
            }

			const url = environment.API_URL + '/citas/all?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getCitas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getCitas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getDireccionCita(idCita: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/citas/' + idCita + '/direccion';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDireccionCita", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDireccionCita", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	crearCita(dataCita:any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				fk_tracking: dataCita.fk_tracking,
				tipo_cita: dataCita.tipoCita,
				fecha_cita: dataCita.fechaCita,
				fecha_preferente: dataCita.fechaPreferente,
				tipo_direccion_cita: dataCita.tipoDireccionCita,
				fk_direccion_cita: dataCita.direccionCita,
				fk_tecnico: dataCita.tecnicoCita,
				comentario: dataCita.comentarioCita,
				tecAyudantes: dataCita.tecnicosAyudantes,
				diagnosis_cita: dataCita.diagnosis_cita ? dataCita.diagnosis_cita : null
			};

			const url = environment.API_URL + '/citas/create-cita';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearCita", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearCita", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.error === 'citas_onsite_no_checkout') {
						msgError = err.error.error_description;
					}
					else if (err.error.error === 'cita_fecha_pasada') {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	modificarCita(idCita, dataCita: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			console.log("dataCita", dataCita);
			let params = {
				fk_tracking: dataCita.fk_tracking,
				tipo_cita: dataCita.tipoCita,
				fecha_cita: dataCita.fechaCita,
				fecha_preferente: dataCita.fechaPreferente,
				tipo_direccion_cita: dataCita.tipoDireccionCita,
				fk_direccion_cita: dataCita.direccionCita,
				fk_tecnico: dataCita.tecnicoCita,
				comentario: dataCita.comentarioCita,
				tecAyudantes: dataCita.tecnicosAyudantes,
				fk_estado_cita: dataCita.fk_estado_cita,
				checkout_ok: (dataCita.checkout_ok !== null && dataCita.checkout_ok !== "") ? dataCita.checkout_ok : null,
				fk_motivo_estado: dataCita.fk_motivo_estado,
				observaciones_estado: dataCita.observaciones_estado,
				diagnosis_cita: dataCita.diagnosis_cita ? dataCita.diagnosis_cita : null
			};

			console.log("params", params);

			const url = environment.API_URL + '/citas/' + idCita + '/update-cita';
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws modificarCita", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws modificarCita", err);
					let msgError = "Se ha producido un error guardando los datos";

					if (err.error.error === 'sin_permiso_editar') {
						msgError = err.error.error_description;
					}
					else if (err.error.error === 'cita_lab_en_ruta') {
						msgError = err.error.error_description;
					}
					else if (err.error.error === 'error_cita_integracion') {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	eliminarCita(idCita): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/citas/' + idCita;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws eliminarCita", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws eliminarCita", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
}
