import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {

	titulo: string;
	mensaje: string;
	btnLabelOk?: string = "Aceptar";
	btnLabelCancel?: string = "Cancelar";

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogConfirmComponent>,
	) {

	}

	ngOnInit(): void {
		if (this.data.titulo) {
			this.titulo = this.data.titulo;
		}
		if (this.data.mensaje) {
			this.mensaje = this.data.mensaje;
		}
		if (this.data.btnLabelOk) {
			this.btnLabelOk = this.data.btnLabelOk;
		}
		if (this.data.btnLabelCancel) {
			this.btnLabelCancel = this.data.btnLabelCancel;
		}
	}

	accept(): void {
		this.dialogRef.close(true);
	}
	cancel(): void {
		this.dialogRef.close(false);
	}

}
