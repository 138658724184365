import { Component, Input, OnInit, Output, QueryList, ViewChildren, EventEmitter, ViewChild } from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-editor-campo-tabla',
  templateUrl: './editor-campo-tabla.component.html',
  styleUrls: ['./editor-campo-tabla.component.scss']
})
export class EditorCampoTablaComponent implements OnInit {
  @ViewChild(MdePopoverTrigger) trigger: MdePopoverTrigger;
  opened: boolean = false;

  //A mostrar en la columna
  @Input('texto') texto: string = '';
  @Input('tag') tag: string = 'label';
  @Input('bg') bg: string = '';
  @Input('color') color: string = '';

  //Edicion
  @Input('id') id: number = null;
  @Input('campo') campo: string = '';
  @Input('value') value: string | number = '';

  @Input('title') title: string = 'Editar';
  @Input('type') type: string = '';
  @Input('options') options: any = [];
  
  @Output("action") action: EventEmitter<any> = new EventEmitter();
  @Output("action_estados_internos") action_estados_internos: EventEmitter<any> = new EventEmitter();
  

  selectFilterCtrl: FormControl = new FormControl();
  selectFiltered: ReplaySubject<any[]> = new ReplaySubject<any>(1);
  suscriptions: Array<Subscription> = [];

  constructor() { }

  ngOnDestroy(): void {
    this.clearSuscriptions();
  }

  ngOnInit(): void {
    if(this.type == 'select-filter'){
      this.selectFiltered.next(this.options.slice());
    }
  }

  openPopover(){
    this.trigger.openPopover();
    this.opened = true;

    this.action_estados_internos.emit({
      id: this.id,
      campo: this.campo,
      value: this.value
    });
    
    if(this.type == 'select-filter'){
      this.suscriptions.push(this.selectFilterCtrl.valueChanges.subscribe((filterValue) => {
        if (!filterValue) {
          this.selectFiltered.next(this.options.slice());
          return;
        }

        this.selectFiltered.next(
          this.options.filter(item => {
            if(item.displayName){
              return item.displayName.toLowerCase().includes(filterValue.toLowerCase());
            }else if(item.name){
              return item.name.toLowerCase().includes(filterValue.toLowerCase());  
            }else{
              return item.nombre.toLowerCase().includes(filterValue.toLowerCase());
            }
          })
        );
      }));
    }
  }

  closePopover(){
    this.trigger.closePopover();
    //Lo cerramos un degundo después para que de tiempo a la animación de cierre del popover
    setTimeout(()=> {this.opened = false}, 1000);
    this.clearSuscriptions();
  }

  saveChanges(){
    this.action.emit({
      id: this.id,
      campo: this.campo,
      value: this.value
    });

    this.closePopover();
  }

 

  clearSuscriptions(){
    this.suscriptions.forEach(element => {
      element.unsubscribe();
    });
  }

}
