import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { EventAistatistics, EventType, AppName } from 'app/shared/models/event.aistatistics.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class AiStatisticsService {

  	constructor(
		private http: HttpClient,
		private router: Router,
		private route: ActivatedRoute,
		private helperSvc: HelperService
	) { }

	saveEvent(event : EventAistatistics): Observable<any> {
		return new Observable<any>(observer => {

			if(event.type == EventType.VISIT_URL) {
				if (event.appName == AppName.DIGIPASS) {
					let hash = event['data']['hash'];

					if(hash) {
						//Quitamos el hash de la uri
						let uri = this.router.url.replace("/"+hash,"");
						event['data']['uri'] = uri == "" ? "/": uri;
					} else {
						event['data']['uri'] = this.router.url;
					}
				} else {
					event['data']['uri'] = this.router.url;
				}
				//event['data']['geolocation'] = this.getLocation()
				event['data']['url'] = window.location.href;
			}

			const options = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_AISTATISTICS + '/event';
			this.http.post(url, event, options).subscribe(
				(response: any) => {
					console.log("ws saveEvent", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws saveEvent", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	/*
	getLocation() : Observable<Position> {
		return new Observable<any>(observer => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((position: Position) => {
					if (position) {
						observer.next(position);
						observer.complete();
					}
				},
				(error: PositionError) => observer.error(error));
			}
		});
	}
	*/

	filtrarGrafica(grafica, params, proyectoSelected): Observable<any> {
		return new Observable<any>(observer => {
			const options = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			params['dataName'] = grafica;
			params['grafica'] = true;

			this.getParamsReport(params);

			const url = environment.API_AISTATISTICS + '/report?' + this.helperSvc.serializeData(params);

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws filtrarGrafica", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws filtrarGrafica", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	filtrarInforme(informe: any, page: number, size: number, filtros: any, getAll: boolean = false): Observable<any> {
		return new Observable<any>(observer => {

			const options = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			filtros['dataName'] = informe;
			filtros['grafica'] = false;
			filtros['numPage'] = page;
			filtros['pageSize'] = size;

			const url = environment.API_AISTATISTICS + '/report?' + this.helperSvc.serializeData(filtros);

			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws filtrarInforme", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws filtrarInforme", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	filtrarMatriz(): Observable<any> {
		return new Observable<any>(observer => {
			

			// const data = [
				
			// 	{
			// 		"estado1": "(02) Pdte USUARIO",
			// 		"estado2": "(L20) Pdte Transporte (PickUP/Recogida)",
			// 		"tiempo": 65185.666666666664
			// 	},
			// 	{
			// 		"estado1": "(02) Pdte USUARIO",
			// 		"estado2": "(L22) Pdte Transporte (Return/Devolución)",
			// 		"tiempo": 241753
			// 	},
			// 	{
			// 		"estado1": "(10) Pdte Garantía",
			// 		"estado2": "Presupuesto enviado / pdte respuesta cliente.",
			// 		"tiempo": 2178
			// 	},
			// 	{
			// 		"estado1": "(G01) Actúa técnico/pendiente revisión ",
			// 		"estado2": "(L00) Pdte pieza/s ",
			// 		"tiempo": 20038.6
			// 	},
			// 	{
			// 		"estado1": "(G01) Actúa técnico/pendiente revisión ",
			// 		"estado2": "(L02) Pdte Compra",
			// 		"tiempo": 91623
			// 	},
			// 	// {
			// 	// 	"estado1": "(G01) Actúa técnico/pendiente revisión ",
			// 	// 	"estado2": "(L21) Pdte Transporte (Deliver/Entrega)",
			// 	// 	"tiempo": 19160
			// 	// },
			// 	// {
			// 	// 	"estado1": "(L00) Pdte pieza/s ",
			// 	// 	"estado2": "(L22) Pdte Transporte (Return/Devolución)",
			// 	// 	"tiempo": 110302
			// 	// },
			// 	// {
			// 	// 	"estado1": "Presupuestos-Cotizaciones ",
			// 	// 	"estado2": "(02) Pdte USUARIO",
			// 	// 	"tiempo": 48331.333333333336
			// 	// },
				
			
			// ]; 
			const data = [
				{
					"estado1": "(02) Pdte USUARIO",
					"estado2": "(L20) Pdte Transporte (PickUP/Recogida)",
					"tiempo": 65185.666666666664
				},
				{
					"estado1": "(02) Pdte USUARIO",
					"estado2": "(L22) Pdte Transporte (Return/Devolución)",
					"tiempo": 241753
				},
				{
					"estado1": "(02) Pdte USUARIO",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 274345.5
				},
				{
					"estado1": "(02) Pdte USUARIO",
					"estado2": "Check IN ",
					"tiempo": 350950.25
				},
				{
					"estado1": "(02) Pdte USUARIO",
					"estado2": "NULL",
					"tiempo": 309872.95939086296
				},
				{
					"estado1": "(02) Pdte USUARIO",
					"estado2": "SIN GRUPO",
					"tiempo": 30180.908739365816
				},
				{
					"estado1": "(10) Pdte Garantía",
					"estado2": "Presupuesto enviado / pdte respuesta cliente.",
					"tiempo": 2178
				},
				{
					"estado1": "(G01) Actúa técnico/pendiente revisión ",
					"estado2": "(L00) Pdte pieza/s ",
					"tiempo": 20038.6
				},
				{
					"estado1": "(G01) Actúa técnico/pendiente revisión ",
					"estado2": "(L02) Pdte Compra",
					"tiempo": 91623
				},
				{
					"estado1": "(G01) Actúa técnico/pendiente revisión ",
					"estado2": "(L03) Pdte Suministro",
					"tiempo": 13596.964285714286
				},
				{
					"estado1": "(G01) Actúa técnico/pendiente revisión ",
					"estado2": "(L20) Pdte Transporte (PickUP/Recogida)",
					"tiempo": 17869.427672955975
				},
				{
					"estado1": "(G01) Actúa técnico/pendiente revisión ",
					"estado2": "(L21) Pdte Transporte (Deliver/Entrega)",
					"tiempo": 19160
				},
				{
					"estado1": "(G01) Actúa técnico/pendiente revisión ",
					"estado2": "(L22) Pdte Transporte (Return/Devolución)",
					"tiempo": 14920.722222222223
				},
				{
					"estado1": "(G01) Actúa técnico/pendiente revisión ",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 15174.444444444445
				},
				{
					"estado1": "(G01) Actúa técnico/pendiente revisión ",
					"estado2": "Check IN ",
					"tiempo": 12873.666666666666
				},
				{
					"estado1": "(G01) Actúa técnico/pendiente revisión ",
					"estado2": "NULL",
					"tiempo": 20432.30769230769
				},
				{
					"estado1": "(G01) Actúa técnico/pendiente revisión ",
					"estado2": "Pdte. Sdesk",
					"tiempo": 7691
				},
				{
					"estado1": "(G01) Actúa técnico/pendiente revisión ",
					"estado2": "Reparado (4) Facturable NO",
					"tiempo": 565
				},
				{
					"estado1": "(G01) Actúa técnico/pendiente revisión ",
					"estado2": "SIN GRUPO",
					"tiempo": 13057.9
				},
				{
					"estado1": "(G01) Actúa técnico/pendiente revisión ",
					"estado2": "TEST - PRUEBA PLATAFORMA",
					"tiempo": 28449.14285714286
				},
				{
					"estado1": "(L00) Pdte pieza/s ",
					"estado2": "(G01) Actúa técnico/pendiente revisión ",
					"tiempo": 721.75
				},
				{
					"estado1": "(L00) Pdte pieza/s ",
					"estado2": "(L03) Pdte Suministro",
					"tiempo": 4139.894573643411
				},
				{
					"estado1": "(L00) Pdte pieza/s ",
					"estado2": "(L04) Pieza Enviada",
					"tiempo": 426.125
				},
				{
					"estado1": "(L00) Pdte pieza/s ",
					"estado2": "(L22) Pdte Transporte (Return/Devolución)",
					"tiempo": 110302
				},
				{
					"estado1": "(L00) Pdte pieza/s ",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 1205.545146149914
				},
				{
					"estado1": "(L00) Pdte pieza/s ",
					"estado2": "NULL",
					"tiempo": 2610522
				},
				{
					"estado1": "(L00) Pdte pieza/s ",
					"estado2": "SIN GRUPO",
					"tiempo": 7552
				},
				{
					"estado1": "(L00) Pdte pieza/s ",
					"estado2": "TEST - PRUEBA PLATAFORMA",
					"tiempo": 9161
				},
				{
					"estado1": "(L02) Pdte Compra",
					"estado2": "(G01) Actúa técnico/pendiente revisión ",
					"tiempo": 1197714
				},
				{
					"estado1": "(L02) Pdte Compra",
					"estado2": "(L02) Pdte Compra",
					"tiempo": 454961.75
				},
				{
					"estado1": "(L02) Pdte Compra",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 247599
				},
				{
					"estado1": "(L02) Pdte Compra",
					"estado2": "NULL",
					"tiempo": 464132.6666666667
				},
				{
					"estado1": "(L02) Pdte Compra",
					"estado2": "Pdte. Sdesk",
					"tiempo": 49253.5
				},
				{
					"estado1": "(L03) Pdte Suministro",
					"estado2": "(G01) Actúa técnico/pendiente revisión ",
					"tiempo": 230554.32142857142
				},
				{
					"estado1": "(L03) Pdte Suministro",
					"estado2": "(L00) Pdte pieza/s ",
					"tiempo": 144539.86440677967
				},
				{
					"estado1": "(L03) Pdte Suministro",
					"estado2": "(L03) Pdte Suministro",
					"tiempo": 166070
				},
				{
					"estado1": "(L03) Pdte Suministro",
					"estado2": "(L04) Pieza Enviada",
					"tiempo": 247277
				},
				{
					"estado1": "(L03) Pdte Suministro",
					"estado2": "(L22) Pdte Transporte (Return/Devolución)",
					"tiempo": 850337.875
				},
				{
					"estado1": "(L03) Pdte Suministro",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 273075.1429856115
				},
				{
					"estado1": "(L03) Pdte Suministro",
					"estado2": "CYG-LAB",
					"tiempo": 953604
				},
				{
					"estado1": "(L03) Pdte Suministro",
					"estado2": "NULL",
					"tiempo": 1532305
				},
				{
					"estado1": "(L03) Pdte Suministro",
					"estado2": "Presupuestos-Cotizaciones ",
					"tiempo": 3106
				},
				{
					"estado1": "(L03) Pdte Suministro",
					"estado2": "SIN GRUPO",
					"tiempo": 731133
				},
				{
					"estado1": "(L03) Pdte Suministro",
					"estado2": "TEST - PRUEBA PLATAFORMA",
					"tiempo": 373780.2653061224
				},
				{
					"estado1": "(L04) Pieza Enviada",
					"estado2": "(L00) Pdte pieza/s ",
					"tiempo": 4629.5
				},
				{
					"estado1": "(L04) Pieza Enviada",
					"estado2": "(L03) Pdte Suministro",
					"tiempo": 11251
				},
				{
					"estado1": "(L04) Pieza Enviada",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 1602.2
				},
				{
					"estado1": "(L04) Pieza Enviada",
					"estado2": "NULL",
					"tiempo": 54
				},
				{
					"estado1": "(L10) DOA",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 2231
				},
				{
					"estado1": "(L10) DOA",
					"estado2": "Check IN ",
					"tiempo": 4206
				},
				{
					"estado1": "(L10) DOA",
					"estado2": "NULL",
					"tiempo": 37527
				},
				{
					"estado1": "(L11) Check OUT ",
					"estado2": "NULL",
					"tiempo": 16463.18918918919
				},
				{
					"estado1": "(L11) Check OUT ",
					"estado2": "Reparado (4) Facturable NO",
					"tiempo": 37291.95327102804
				},
				{
					"estado1": "(L11) Check OUT ",
					"estado2": "TEST - PRUEBA PLATAFORMA",
					"tiempo": 3053
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "(G01) Actúa técnico/pendiente revisión ",
					"tiempo": 46288.881773399014
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "(L00) Pdte pieza/s ",
					"tiempo": 266332.1818181818
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "(L10) DOA",
					"tiempo": 95446.08333333333
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "(L20) Pdte Transporte (PickUP/Recogida)",
					"tiempo": 173419.48484848486
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "(L21) Pdte Transporte (Deliver/Entrega)",
					"tiempo": 126923.57518621974
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "(L22) Pdte Transporte (Return/Devolución)",
					"tiempo": 235718.57142857142
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "(L221) Pdte Transporte (Return/Devolución) Incidencia",
					"tiempo": 359682
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "(T02) En reparación PLANIFICADA",
					"tiempo": 269330
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 127937.89541930937
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "02 Presupuesto enviado / pdte respuesta cliente.",
					"tiempo": 220876.5
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "03 Presupuesto – Aceptado",
					"tiempo": 153555
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "Check IN ",
					"tiempo": 132522.63143445435
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "NULL",
					"tiempo": 123411.45014084507
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "PRESUPUESTO RECHAZADO",
					"tiempo": 93373
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "Pdte. Sdesk",
					"tiempo": 28774
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "SIN GRUPO",
					"tiempo": 30563.5
				},
				{
					"estado1": "(L20) Pdte Transporte (PickUP/Recogida)",
					"estado2": "TEST - PRUEBA PLATAFORMA",
					"tiempo": 96828
				},
				{
					"estado1": "(L201) Pdte Transporte (PickUP/Recogida) Incidencia",
					"estado2": "(L20) Pdte Transporte (PickUP/Recogida)",
					"tiempo": 456.6
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "(G01) Actúa técnico/pendiente revisión ",
					"tiempo": 126039.43333333333
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "(L00) Pdte pieza/s ",
					"tiempo": 180355.29411764705
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "(L02) Pdte Compra",
					"tiempo": 1250408.4
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "(L10) DOA",
					"tiempo": 183497
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "(L20) Pdte Transporte (PickUP/Recogida)",
					"tiempo": 162396.28070175438
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "(L21) Pdte Transporte (Deliver/Entrega)",
					"tiempo": 179460
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "(T02) En reparación PLANIFICADA",
					"tiempo": 80934.4
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 123917.42064861135
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "Check IN ",
					"tiempo": 98637.08760330579
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "NULL",
					"tiempo": 369043.39215686277
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "Pdte. Sdesk",
					"tiempo": 4052
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "Presupuestos-Cotizaciones ",
					"tiempo": 182117
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "SIN GRUPO",
					"tiempo": 19741
				},
				{
					"estado1": "(L21) Pdte Transporte (Deliver/Entrega)",
					"estado2": "TEST - PRUEBA PLATAFORMA",
					"tiempo": 209589.5
				},
				{
					"estado1": "(L22) Pdte Transporte (Return/Devolución)",
					"estado2": "(G01) Actúa técnico/pendiente revisión ",
					"tiempo": 491461.29411764705
				},
				{
					"estado1": "(L22) Pdte Transporte (Return/Devolución)",
					"estado2": "(L00) Pdte pieza/s ",
					"tiempo": 957150.1428571428
				},
				{
					"estado1": "(L22) Pdte Transporte (Return/Devolución)",
					"estado2": "(L03) Pdte Suministro",
					"tiempo": 257849
				},
				{
					"estado1": "(L22) Pdte Transporte (Return/Devolución)",
					"estado2": "(L11) Check OUT ",
					"tiempo": 65
				},
				{
					"estado1": "(L22) Pdte Transporte (Return/Devolución)",
					"estado2": "(L22) Pdte Transporte (Return/Devolución)",
					"tiempo": 419662
				},
				{
					"estado1": "(L22) Pdte Transporte (Return/Devolución)",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 709397.3103448276
				},
				{
					"estado1": "(L22) Pdte Transporte (Return/Devolución)",
					"estado2": "NULL",
					"tiempo": 1311230.9857819905
				},
				{
					"estado1": "(L22) Pdte Transporte (Return/Devolución)",
					"estado2": "SIN GRUPO",
					"tiempo": 580270
				},
				{
					"estado1": "(L22) Pdte Transporte (Return/Devolución)",
					"estado2": "TEST - PRUEBA PLATAFORMA",
					"tiempo": 965806.4516129033
				},
				{
					"estado1": "(L221) Pdte Transporte (Return/Devolución) Incidencia",
					"estado2": "(L21) Pdte Transporte (Deliver/Entrega)",
					"tiempo": 440
				},
				{
					"estado1": "(T00) Pdte diagnosticar",
					"estado2": "NULL",
					"tiempo": 674684.75
				},
				{
					"estado1": "(T02) En reparación PLANIFICADA",
					"estado2": "(L00) Pdte pieza/s ",
					"tiempo": 4758.333333333333
				},
				{
					"estado1": "(T02) En reparación PLANIFICADA",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 43729.666666666664
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "(G01) Actúa técnico/pendiente revisión ",
					"tiempo": 93603.97448979592
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "(L00) Pdte pieza/s ",
					"tiempo": 64772.84109298315
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "(L03) Pdte Suministro",
					"tiempo": 24066.75
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "(L04) Pieza Enviada",
					"tiempo": 350
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "(L10) DOA",
					"tiempo": 200
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "(L11) Check OUT ",
					"tiempo": 438302
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "(L20) Pdte Transporte (PickUP/Recogida)",
					"tiempo": 256216
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "(L21) Pdte Transporte (Deliver/Entrega)",
					"tiempo": 33320
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "(L22) Pdte Transporte (Return/Devolución)",
					"tiempo": 300918.55555555556
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 137116.2
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "02 Presupuesto enviado / pdte respuesta cliente.",
					"tiempo": 324678
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "Actua técnico / Precierre",
					"tiempo": 94285
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "Check IN ",
					"tiempo": 22526.502673796793
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "NULL",
					"tiempo": 191104.84795321638
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "Presupuestos-Cotizaciones ",
					"tiempo": 89488.48
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "Reparado (4) Facturable NO",
					"tiempo": 52148
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "SIN GRUPO",
					"tiempo": 112395.27777777778
				},
				{
					"estado1": "(T03) En Reparación LAB",
					"estado2": "TEST - PRUEBA PLATAFORMA",
					"tiempo": 123311.56734905415
				},
				{
					"estado1": "03 Presupuesto – Aceptado",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 36
				},
				{
					"estado1": "Actua técnico / Precierre",
					"estado2": "NULL",
					"tiempo": 22602.333333333332
				},
				{
					"estado1": "CYG-Gestión",
					"estado2": "(L00) Pdte pieza/s ",
					"tiempo": 165611
				},
				{
					"estado1": "CYG-Gestión",
					"estado2": "NULL",
					"tiempo": 10923.6
				},
				{
					"estado1": "CYG-LAB",
					"estado2": "(L03) Pdte Suministro",
					"tiempo": 144581
				},
				{
					"estado1": "Check IN ",
					"estado2": "(G01) Actúa técnico/pendiente revisión ",
					"tiempo": 279292.3333333333
				},
				{
					"estado1": "Check IN ",
					"estado2": "(L00) Pdte pieza/s ",
					"tiempo": 122276.48648648648
				},
				{
					"estado1": "Check IN ",
					"estado2": "(L03) Pdte Suministro",
					"tiempo": 438115
				},
				{
					"estado1": "Check IN ",
					"estado2": "(L20) Pdte Transporte (PickUP/Recogida)",
					"tiempo": 17738.2
				},
				{
					"estado1": "Check IN ",
					"estado2": "(L21) Pdte Transporte (Deliver/Entrega)",
					"tiempo": 402
				},
				{
					"estado1": "Check IN ",
					"estado2": "(L22) Pdte Transporte (Return/Devolución)",
					"tiempo": 30204.5
				},
				{
					"estado1": "Check IN ",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 176761.94264240508
				},
				{
					"estado1": "Check IN ",
					"estado2": "CYG-Gestión",
					"tiempo": 326435
				},
				{
					"estado1": "Check IN ",
					"estado2": "NULL",
					"tiempo": 205882.83783783784
				},
				{
					"estado1": "Check IN ",
					"estado2": "Presupuestos-Cotizaciones ",
					"tiempo": 43913
				},
				{
					"estado1": "Check IN ",
					"estado2": "SIN GRUPO",
					"tiempo": 36859
				},
				{
					"estado1": "Check IN ",
					"estado2": "TEST - PRUEBA PLATAFORMA",
					"tiempo": 12659
				},
				{
					"estado1": "Enviado",
					"estado2": "NULL",
					"tiempo": 199924
				},
				{
					"estado1": "PRESUPUESTO RECHAZADO",
					"estado2": "Check IN ",
					"tiempo": 4246
				},
				{
					"estado1": "Pdte. Sdesk",
					"estado2": "(G01) Actúa técnico/pendiente revisión ",
					"tiempo": 333004.5
				},
				{
					"estado1": "Pdte. Sdesk",
					"estado2": "(L20) Pdte Transporte (PickUP/Recogida)",
					"tiempo": 850
				},
				{
					"estado1": "Pdte. Sdesk",
					"estado2": "(L21) Pdte Transporte (Deliver/Entrega)",
					"tiempo": 83290
				},
				{
					"estado1": "Pdte. Sdesk",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 277868
				},
				{
					"estado1": "Pdte. Sdesk",
					"estado2": "NULL",
					"tiempo": 1991
				},
				{
					"estado1": "Presupuestos-Cotizaciones ",
					"estado2": "(02) Pdte USUARIO",
					"tiempo": 48331.333333333336
				},
				{
					"estado1": "Presupuestos-Cotizaciones ",
					"estado2": "(G01) Actúa técnico/pendiente revisión ",
					"tiempo": 147717.33333333334
				},
				{
					"estado1": "Presupuestos-Cotizaciones ",
					"estado2": "(L02) Pdte Compra",
					"tiempo": 30231.157205240175
				},
				{
					"estado1": "Presupuestos-Cotizaciones ",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 22976.125
				},
				{
					"estado1": "Presupuestos-Cotizaciones ",
					"estado2": "01 PDTE presupuesto",
					"tiempo": 7538.5
				},
				{
					"estado1": "Presupuestos-Cotizaciones ",
					"estado2": "02 Presupuesto enviado / pdte respuesta cliente.",
					"tiempo": 36860.002086230874
				},
				{
					"estado1": "Presupuestos-Cotizaciones ",
					"estado2": "04 - Pdte. resolución cliente",
					"tiempo": 19342
				},
				{
					"estado1": "Presupuestos-Cotizaciones ",
					"estado2": "NULL",
					"tiempo": 0
				},
				{
					"estado1": "Presupuestos-Cotizaciones ",
					"estado2": "Pdte. Sdesk",
					"tiempo": 59112
				},
				{
					"estado1": "Presupuestos-Cotizaciones ",
					"estado2": "Presupuestos-Cotizaciones ",
					"tiempo": 756
				},
				{
					"estado1": "Presupuestos-Cotizaciones ",
					"estado2": "SD pdte gestión",
					"tiempo": 5958
				},
				{
					"estado1": "Presupuestos-Cotizaciones ",
					"estado2": "SIN GRUPO",
					"tiempo": 99
				},
				{
					"estado1": "Presupuestos-Cotizaciones ",
					"estado2": "TEST - PRUEBA PLATAFORMA",
					"tiempo": 43
				},
				{
					"estado1": "Reparado (4) Facturable NO",
					"estado2": "(L20) Pdte Transporte (PickUP/Recogida)",
					"tiempo": 340398
				},
				{
					"estado1": "Reparado (4) Facturable NO",
					"estado2": "NULL",
					"tiempo": 44707.5
				},
				{
					"estado1": "Reparado (4) Facturable NO",
					"estado2": "Presupuestos-Cotizaciones ",
					"tiempo": 26
				},
				{
					"estado1": "SD pdte gestión",
					"estado2": "(L20) Pdte Transporte (PickUP/Recogida)",
					"tiempo": 921
				},
				{
					"estado1": "SD pdte gestión",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 3453387.5
				},
				{
					"estado1": "SD pdte gestión",
					"estado2": "NULL",
					"tiempo": 14327.333333333334
				},
				{
					"estado1": "SIN GRUPO",
					"estado2": "(G01) Actúa técnico/pendiente revisión ",
					"tiempo": 6579.680412371134
				},
				{
					"estado1": "SIN GRUPO",
					"estado2": "(L11) Check OUT ",
					"tiempo": 17
				},
				{
					"estado1": "SIN GRUPO",
					"estado2": "(L20) Pdte Transporte (PickUP/Recogida)",
					"tiempo": 18047.64077669903
				},
				{
					"estado1": "SIN GRUPO",
					"estado2": "(L201) Pdte Transporte (PickUP/Recogida) Incidencia",
					"tiempo": 2172.75
				},
				{
					"estado1": "SIN GRUPO",
					"estado2": "(L21) Pdte Transporte (Deliver/Entrega)",
					"tiempo": 30870
				},
				{
					"estado1": "SIN GRUPO",
					"estado2": "(L22) Pdte Transporte (Return/Devolución)",
					"tiempo": 82
				},
				{
					"estado1": "SIN GRUPO",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 56.172413793103445
				},
				{
					"estado1": "SIN GRUPO",
					"estado2": "02 Presupuesto enviado / pdte respuesta cliente.",
					"tiempo": 85
				},
				{
					"estado1": "SIN GRUPO",
					"estado2": "Check IN ",
					"tiempo": 2462.532110091743
				},
				{
					"estado1": "SIN GRUPO",
					"estado2": "NULL",
					"tiempo": 28873.593023255813
				},
				{
					"estado1": "SIN GRUPO",
					"estado2": "Presupuestos-Cotizaciones ",
					"tiempo": 113.25
				},
				{
					"estado1": "SIN GRUPO",
					"estado2": "SIN GRUPO",
					"tiempo": 665
				},
				{
					"estado1": "SIN GRUPO",
					"estado2": "TEST - PRUEBA PLATAFORMA",
					"tiempo": 51.95
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "(L00) Pdte pieza/s ",
					"tiempo": 340
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "(L02) Pdte Compra",
					"tiempo": 657160.4285714285
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "(L03) Pdte Suministro",
					"tiempo": 20.6
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "(L04) Pieza Enviada",
					"tiempo": 75467
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "(L11) Check OUT ",
					"tiempo": 2077.5257142857145
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "(L20) Pdte Transporte (PickUP/Recogida)",
					"tiempo": 63961
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "(L21) Pdte Transporte (Deliver/Entrega)",
					"tiempo": 1240794.3333333333
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "(L22) Pdte Transporte (Return/Devolución)",
					"tiempo": 3919.4270833333335
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "(T00) Pdte diagnosticar",
					"tiempo": 294757.75
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "(T03) En Reparación LAB",
					"tiempo": 4318.808777429467
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "02 Presupuesto enviado / pdte respuesta cliente.",
					"tiempo": 90639.81818181818
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "Actua técnico / Precierre",
					"tiempo": 124
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "Enviado",
					"tiempo": 1616736
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "NULL",
					"tiempo": 605.1001759464215
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "Pdte. Sdesk",
					"tiempo": 841146
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "Presupuestos-Cotizaciones ",
					"tiempo": 783.1601049868766
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "Reparado (4) Facturable NO",
					"tiempo": 16489.19230769231
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "SIN GRUPO",
					"tiempo": 75.22222222222223
				},
				{
					"estado1": "TEST - PRUEBA PLATAFORMA",
					"estado2": "TEST - PRUEBA PLATAFORMA",
					"tiempo": 20017.272727272728
				}
			];
			observer.next(data);
		
			return {unsubscribe() {}};
        });
	}

	async getParamsReport(params) {
		if(params.date) {
			let filtro = moment(params.date.startDate).format('YYYY-MM-DD') + ' - ' + moment(params.date.endDate).format('YYYY-MM-DD');
			params['date'] = filtro;
		}
		if(params.closedate) {
			let filtro = moment(params.closedate.startDate).format('YYYY-MM-DD') + ' - ' + moment(params.closedate.endDate).format('YYYY-MM-DD');
			params['closedate'] = filtro;
		}
		if(params.fecha_solicitud) {
			let filtro = moment(params.fecha_solicitud.startDate).format('YYYY-MM-DD') + ' - ' + moment(params.fecha_solicitud.endDate).format('YYYY-MM-DD');
			params['fecha_solicitud'] = filtro;
		}
	}

}