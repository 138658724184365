import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DbService } from '../../services/db/db.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-empresa-dialog',
  templateUrl: './empresa-dialog.component.html',
  styleUrls: ['./empresa-dialog.component.scss']
})
export class EmpresaDialogComponent implements OnInit {

    closeDialog = new EventEmitter();
    
    empresaForm: FormGroup;
    buttonsEnable: Boolean;

  constructor(private formBuilder: FormBuilder, private dbservice: DbService, private _snackBar: MatSnackBar) { 
    this.buttonsEnable = true;

    this.empresaForm = this.formBuilder.group({
      nombre: [null, Validators.required],
      tlf: null,
      web: null,
      dominio_correo: null,
      red_social: [null],
      direccion: null,
      codigo_postal: null,
      provincia: null,
      pais: null,
      descripcion: [null],
    });
   }

  ngOnInit(): void {
  }

  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
        duration: 5000,
      });
  }

  submit() {
        this.buttonsEnable = false;
        if (!this.empresaForm.valid) {
            this.buttonsEnable = true;
            return;
        }
        
        this.dbservice.newEmpresa(this.empresaForm.value).subscribe(data => {
            this.closeDialog.emit(); 
            this.openSnackBar('Empresa creada', 'Cerrar');
        });

        //console.log(JSON.stringify(this.oportunidadForm.value));
    }

}
