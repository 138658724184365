<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">{{ titulo }}</h2>
</div>
<mat-dialog-content class="mat-typography">
    <p>{{ mensaje }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button (click)="cancel()">{{  btnLabelCancel }}</button>
    <button mat-button class="btn-aitana" (click)="accept()">{{ btnLabelOk }}</button>
</mat-dialog-actions>