import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ImageService } from 'app/services/helper/image.service';
import { Subject } from 'rxjs';

@Component({
	selector: 'avatar-photo-user',
	templateUrl: './avatar-photo-user.component.html',
	styleUrls: ['./avatar-photo-user.component.scss']
})
export class AvatarPhotoUserComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	@Input() public user: any;
	@Input() public size: string;

	showInitials: boolean;
	initials: string;
	circleColor: string;
	photoUser: string;

	private colors: Array<string>;

	constructor(
		private imageSvc: ImageService,

	) { 
		this._unsubscribeAll = new Subject();

		this.showInitials = false;
		this.initials = '';

		this.colors = [
			'#EB7181', // red
			'#468547', // green
			'#FFD558', // yellow
			'#367082', // blue,
			'#8d84e8',
			'#b36bd4'
		]
	}

	ngOnInit(): void {
		this.generateDataUser();
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	ngOnChange(changes: SimpleChanges) {
		console.log("Change avatar", changes);
		if (changes.user && !changes.user.firstChange) {
			if (changes.user.currentValue !== changes.user.previousValue) {
				this.user = changes.user.currentValue;
				this.generateDataUser();
			}
		}
	}

	generateDataUser() {
		if (this.user ) {
			const imageUser = this.user.image_user;
			if (imageUser === null || imageUser === '' || imageUser === 'no_user.png') {
				this.showInitials = true;
				this.createInitials();
	
				const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
				this.circleColor = this.colors[randomIndex];
			}
			else {
				this.photoUser = this.imageSvc.getImageUser(imageUser)
			}
	
			if (!this.size) {
				this.size = 'md';
			}
		}
	}

	private createInitials() {
		const username = this.user.username ? this.user.username : this.user.name;
		this.initials = username ? username.substring(0, 2).toUpperCase() : null;
	}

}
