import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class GridConfigService {

	comboSiNo: Array<any> = [
		{ id: 0, name: "No" },
		{ id: 1, name: "Sí" },
	];

  	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService,
		private translateService: TranslateService,
	) { }

	getDataComboSiNo() {
		return this.comboSiNo;
	}
	getValueSi() {
		return this.comboSiNo.find(item => item.id = 1);
	}
	getValueNo() {
		return this.comboSiNo.find(item => item.id = 0);
	}

	getValueTextFiltro(dataFiltro: any) {
		let value = '';

		if (dataFiltro.value) {
			switch (dataFiltro.type) {
				case 'text':
                case 'String':
					value = dataFiltro.value;
					break;
				case 'date':
                case 'Date':
                case 'Datetime':
					value = moment(dataFiltro.value.startDate).format('DD-MM-YYYY') + ' - ' + moment(dataFiltro.value.endDate).format('DD-MM-YYYY');
					break;
	
				case 'select':
					value = dataFiltro.value.name?dataFiltro.value.name:(dataFiltro.value.nombre?dataFiltro.value.nombre:dataFiltro.value);
					break;
	
				case 'multiple':
					for (const item of dataFiltro.value) {
						value += value != '' ? ', ' : '';
						value += item.name;
					}
					break;
	
				case 'address':
					value = '';
					// for (const item of dataFiltro.subcampos) {
					// 	if(dataFiltro.value[item.name]) {
					// 		value += value != '' ? ', ' : '';
					// 		if(item.type == 'text') {
					// 			value += item.name + ': ' + dataFiltro.value[item.name];
					// 		}
					// 	}
					// }
					break;
                    case 'number':
                    case 'Number':
                        value = dataFiltro.value;
                        break;
			
				default:
					break;
			}
		}

		return value;
	}
	getValueFiltro(dataFiltro: any) {
		let value = '';

		if (dataFiltro.value) {
			switch (dataFiltro.type) {
				case 'text':
					value = dataFiltro.value;
					break;
                case 'number':
                    value = dataFiltro.value;
                    break;
				case 'date':
					value = dataFiltro.value;
					break;
	
				case 'select':
                    if(dataFiltro.value.hasOwnProperty('id')){
                        value = dataFiltro.value.id;
                    }else{
                        value = dataFiltro.value;
                    }
					break;
	
				case 'multiple':
					value = dataFiltro.value.map((item) => { return item.id; });
					break;
			
				default:
                    value = dataFiltro.value;
					break;
			}
		}

		return value;
	}

	getValueTextSortDirection(direction) {
		let textDirection = direction == 'asc' ? 'Ascendente' : (direction == 'desc' ? 'Descendente' : '');
		return textDirection;
	}

	convertDataForFilter(data: any, itemId: string, itemName: string) {
		let dataForFilter = [];

		for (const item of data) {
			let itemData = Object.assign({}, item);
			if (!itemData.hasOwnProperty('id')) {
				itemData['id'] = item[itemId];
			}
			if (!itemData.hasOwnProperty('name')) {
				itemData['name'] = item[itemName];
			}
			dataForFilter.push(itemData);
		}

		return dataForFilter;
	}

	getNameColum(columns: Array<any>, idColumn: string) {
		const column = columns.find((item) => { return item.id === idColumn });
		if (column && column.name != undefined && column.name != '') {
			return this.translateService.instant(column.name);
		}
		else {
			return '';
		}
	}

	getColumnById(columns: Array<any>, idColumn: string) {
		return columns.find((item) => { return item.id === idColumn });
	}

	getAttribute(model : any, idColumn : string, columns : any) {
		const column = columns.find((item) => { return item.id === idColumn });
		const attributes = column.id.split('.');
		let attributeIndex = 0;
		let attributeObject = model;
		
		try{
			do {
				attributeObject = attributeObject[attributes[attributeIndex]];
				attributeIndex++;
			} while (attributeIndex < attributes.length)

			//Formats
			if(column['format']) {
				switch(column['format']) {
					case 'Raw':
						return column['output'](model);
					
					case 'DateTime':
						return this.formatDate(attributeObject, true);

					case 'Map':
						if(column['values'])
							return column['values'][attributeObject];
						break;
				}
			}

			return attributeObject;
		}  catch  (error)  {  
			if(error instanceof TypeError)
				return '(no definido)';
			return 'Error en atributo';
		}
	}

	formatDate(date: string, withTime: boolean): string {
		let dateFormat = "";
		if (date) {
			let momentDate = moment(date);
			if (withTime) {
				dateFormat = momentDate.format('DD/MM/YYYY HH:mm');
			}
			else {
				dateFormat = momentDate.format('DD/MM/YYYY');
			}
		}

		return dateFormat;
	}

	getFiltrosGrid(gridName): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				grid: gridName
			}

			const url = environment.API_URL + '/grid-config/filtros?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getFiltrosGrid", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getFiltrosGrid", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	guardarFiltro(gridName: string, filterName: string, dataFiltros: any, idFiltro?: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let filterValues = {};
			for (const filtro of dataFiltros) {
				if(filtro.subcampos) {
					filterValues = this.setFiltrosSubcampos(filterValues, filtro);
				}
				else {
					filterValues[filtro.name] = filtro.value;
				}
			}

			let params = {
				grid: gridName,
				filter: filterName,
				values: filterValues
			};
			if (idFiltro) {
				params['filter_id'] = idFiltro;
			}

			console.log("params guardarFiltro", params);

			const url = environment.API_URL + '/grid-config/filtro';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws guardarFiltroGrid", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws guardarFiltroGrid", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	setFiltrosSubcampos(filterValues, filtro) {
		let subcampos = filtro.subcampos;
		filterValues[filtro.name] = {};
		for(let subcampo of subcampos) {
			filterValues[filtro.name][subcampo.name] = filtro.value[subcampo.name];
		}

		return filterValues;
	}

	borrarFiltro(idFiltro: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/grid-config/filtro/' + idFiltro;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws borrarFiltroGrid", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws borrarFiltroGrid", err);
					let msgError = "Se ha producido un error eliminando el filtro";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getSortsGrid(gridName): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				grid: gridName
			}

			const url = environment.API_URL + '/grid-config/sorts?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getSortsGrid", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getSortsGrid", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	guardarSort(gridName: string, sortName: string, dataSort: any, idSort?: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				grid: gridName,
				sort: sortName,
				values: dataSort
			};
			if (idSort) {
				params['sort_id'] = idSort;
			}

			console.log("params guardarFiltro", params);

			const url = environment.API_URL + '/grid-config/sort';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws guardarSortGrid", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws guardarSortGrid", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	borrarSort(idSort: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/grid-config/sort/' + idSort;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws borrarSortGrid", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws borrarSortGrid", err);
					let msgError = "Se ha producido un error eliminando la configuración";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	guardarConfiguracion(gridName: string, pageSize: number, filtro: string, sort: string, columns: Array<string>, proyecto?:number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params;

			if (proyecto != null) {
				params = {
					grid: gridName,
					pageSize: pageSize,
					filtro: filtro,
					sort: sort,
					columns: columns,
					proyecto : proyecto
				};
			}else{
				 params = {
					grid: gridName,
					pageSize: pageSize,
					filtro: filtro,
					sort: sort,
					columns: columns
				};
			}

			console.log("params guardarConfiguracion", params);

			const url = environment.API_URL + '/grid-config/configuracion';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws guardarConfiguracionGrid", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws guardarConfiguracionGrid", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getConfiguracion(gridName, proyecto?: number ): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				grid: gridName
			}

			if (proyecto) {
				params['proyecto'] = proyecto;
			}

			const url = environment.API_URL + '/grid-config/configuracion?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getConfiguracionGrid", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getConfiguracionGrid", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
}
