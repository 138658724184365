import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { ClienteService } from 'app/services/cliente/cliente.service';
import { MatTableDataSource } from '@angular/material/table';
import { Garantia10Service } from 'app/services/db/garantia10.service';
import { AlmacenService } from 'app/services/almacen/almacen.service';
import { DialogConfirmComponent } from 'app/componentes/util/dialog-confirm/dialog-confirm.component';
import { BuscadorAvanzadoClienteComponent } from '../buscador-avanzado-cliente/buscador-avanzado-cliente.component';
import { TicketsService } from 'app/services/ticket/tickets.service';
import { Block } from 'notiflix';
import { GreenTechPassportService } from 'app/services/green-tech-passort/green-tech-passport.service';
import { DialogQrCodeComponent } from 'app/componentes/util/dialog-qr-code/dialog-qr-code.component';

@Component({
	selector: 'buscador-call-center',
	templateUrl: './buscador-call-center.component.html',
	styleUrls: ['./buscador-call-center.component.scss']
})
export class BuscadorCallCenterComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	@ViewChild('fieldBuscador') elemFieldBuscador: ElementRef;

	@Input() accion: 'call-center' | 'digipass-consulta';
	@Output() ticketCreated = new EventEmitter<number>();

	fieldBuscadorCallCenter: string;
	garantia10: string;

	loadingSearch: boolean;
	sinClientes: boolean;
	sinDispositivos: boolean;

	cliente: any;
	clientesEncontrados: Array<any>;

	displayedColumnsClientes: string[] = ['info_cliente', 'actions'];
	dataSourceClientes: MatTableDataSource<any>;
	
	dispositivo: any;
	dispositivosEncontrados: Array<any>;

	displayedColumnsDispositivos: string[] = ['info_dispositivo', 'actions'];
	dataSourceDispositivos: MatTableDataSource<any>;

	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
		private snackBar: MatSnackBar,
		private dialog: MatDialog, 
		private translateService: TranslateService,
		private clienteSvc: ClienteService,
		private almacenSvc: AlmacenService,
		private ticketsSvc: TicketsService,
		private gtpSvc: GreenTechPassportService
	) {
		moment.locale(this.translateService.currentLang);
		this._unsubscribeAll = new Subject();

		this.fieldBuscadorCallCenter = "";

		this.loadingSearch = false;
		this.sinClientes = false;
		this.sinDispositivos = false;

		this.clientesEncontrados = [];
		this.dataSourceClientes = new MatTableDataSource();
		this.dispositivosEncontrados = [];
		this.dataSourceDispositivos = new MatTableDataSource();
	}

	ngOnInit(): void {
		console.log("accion buscador call-center", this.accion);
	}

	ngAfterViewInit() {
		this.focusFieldBuscador();
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}

	initLoading(selector, textoLoading?: string) {
		// this.loading = true;
		Block.standard(selector, textoLoading ? textoLoading : '', {
			svgColor: '#666',
			messageColor: '#666',
			messageFontSize: '18px',
			backgroundColor: 'rgba(255,255,255,0.8)'
		});
	}

	stopLoading(selector) {
		// this.loading = false;
		Block.remove(selector);
	}

	focusFieldBuscador() {
		console.log("Poner foco en id entrada");
		setTimeout(() => {
			if (this.elemFieldBuscador) {
				this.elemFieldBuscador.nativeElement.focus();
			}
		}, 200);
	}

	contratosCliente(cliente: any) {
		const listContratos = cliente.garantia10.map((item) => { return item.garantia10; });
		const cadContratos = listContratos.join(", ");
		return cadContratos;
	}

	formatDireccion(dataDireccion: any) {
		let direccionFormat = "";

		if (dataDireccion.direccion !== "") {
			direccionFormat += dataDireccion.direccion;
		}
		if (dataDireccion.num_casa && dataDireccion.num_casa !== "") {
			direccionFormat = direccionFormat !== "" ? direccionFormat + ", " : direccionFormat;
			direccionFormat += dataDireccion.num_casa;
		}
		if (dataDireccion.id_localidad) {
			direccionFormat = direccionFormat !== "" ? direccionFormat + ", " : direccionFormat;
			direccionFormat += "CP: " + dataDireccion.localidad.postal + ", " + dataDireccion.localidad.poblacion;
		}
		if (dataDireccion.id_provincia) {
			direccionFormat = direccionFormat !== "" ? direccionFormat + ", " : direccionFormat;
			direccionFormat += dataDireccion.provincia.provincia;
			
			if (dataDireccion.id_pais) {
				direccionFormat +=  " (" + dataDireccion.provincia.pais.pais + ")";
			}
		}

		return direccionFormat;
	}

	checkTipoFiltro(cadFiltro: string) {
		this.garantia10 = null;

		let filtroContrato = true;
		let filtroTelefono = false;
		let filtroEmail = false;

		const patternTelefono = /[0-9]{9}/;
		if (patternTelefono.test(cadFiltro)) {
			filtroTelefono = true;
		}
		const patternEmail = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
		if (patternEmail.test(cadFiltro)) {
			filtroEmail = true;
		}

		let filtros = {
			conGarantia10: 1
		};
		if (!filtroTelefono && !filtroEmail) {
			filtros['preFiltroCallCenter'] = cadFiltro;
			// filtros['garantia10'] = cadFiltro;
			// this.garantia10 = cadFiltro;
		}
		else {
			if (filtroEmail) {
				filtros['email'] = cadFiltro;
			}
			if (filtroTelefono) {
				filtros['telefono'] = cadFiltro;
			}
		}

		return filtros;
	}

	openBusquedaAvanzadaCliente() {
		const dialogRef = this.dialog.open(BuscadorAvanzadoClienteComponent, {
			minWidth: '60vw',
			panelClass: 'panel-aitana',
			disableClose: true,
			autoFocus: false
		});
		dialogRef.updatePosition({ top: '4rem' });

		dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(cliente => {
			if (cliente && cliente !== undefined) {
				this.sinClientes = false;
				this.sinDispositivos = false;
				this.seleccionarCliente(cliente);
			}
		});
	}

	buscarCliente() {
		console.log("buscar", this.fieldBuscadorCallCenter);

		if (this.fieldBuscadorCallCenter !== "") {
			if (this.fieldBuscadorCallCenter.length < 3) {
				this.showSnack("Para realizar la busqueda de indicar al menos 3 caracteres");
			}
			else {
				const filtros = this.checkTipoFiltro(this.fieldBuscadorCallCenter);
				console.log("Filtros", filtros);
	
				this.cliente = null;
				this.sinClientes = false;
				this.clientesEncontrados = [];
				this.dispositivosEncontrados = [];
				this.sinDispositivos = false;
	
				this.loadingSearch = true;
				this.clienteSvc.buscarClientes(filtros).pipe(takeUntil(this._unsubscribeAll)).subscribe(
					(data: any) => {
						this.loadingSearch = false;
						if (data.elements.length > 0) {
							if (data.elements.length === 1) {
								this.seleccionarCliente(data.elements[0]);
							}
							else {
								this.clientesEncontrados = data.elements.slice();
								this.dataSourceClientes.data = this.clientesEncontrados.slice();
							}
						}
						else {
							this.sinClientes = true;
						}
					},
					(err) => {
						this.showSnack(err);
						this.loadingSearch = false;
					}
				);
			}
		}
	}

	seleccionarCliente(cliente: any) {
		this.cliente = cliente;
		this.clientesEncontrados = [];
		this.garantia10 = this.cliente.garantia10 ? this.cliente.garantia10.garantia10 : null;
		this._changeDetectorRef.detectChanges();
		
		this.buscarDispositivo();
	}

	buscarDispositivo() {
		this.sinDispositivos = false;

		this.initLoading('.bloq-cliente', 'Buscando dispositivos');			
		this.almacenSvc.getDispositivosCliente(this.cliente.ID, this.garantia10).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				this.stopLoading('.bloq-cliente');
				if (data.length > 0) {
					this.dispositivosEncontrados = data.slice();
					this.dataSourceDispositivos.data = this.dispositivosEncontrados.slice();
				}
				else {
					this.sinDispositivos = true;
				}
			},
			(err) => {
				this.stopLoading('.bloq-cliente');
				this.showSnack(err);
			}
		);
	}

	openConfirmCrearTicket(dispositivo: any) {
		this.dispositivo = dispositivo;
		const nombreCliente = this.cliente.nombre + (this.cliente.apellido ? " " + this.cliente.apellido : '');
		const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: { 
				titulo: 'CREAR TICKET', 
				mensaje: 'Se va a crear un ticket para el cliente "' + nombreCliente + '" y el dispositivo "' + this.dispositivo.name + '". ¿Esta seguro?',
				btnLabelOk: this.translateService.instant('COMUN.SI'),
				btnLabelCancel: this.translateService.instant('COMUN.NO')
			},
			panelClass: 'panel-aitana',
			disableClose: true,
		});

		dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
			if (result === true) {
				this.crearTicket();
			}
			else {
				console.log("Eliminacion cancelada");
			}
		});
	}

	crearTicket() {
		const nombreCliente = this.cliente.nombre + (this.cliente.apellido ? " " + this.cliente.apellido : '');
		const tituloTicket = 'Avería ' + nombreCliente + ' - ' + this.dispositivo.name;
		let datosTicket = {
			FK_entities: this.dispositivo.FK_entities,
			name: tituloTicket,
			numserie: this.dispositivo.serial,
			garantia10: this.dispositivo.garantia10
		};
		if (this.dispositivo.id_marca) {
			datosTicket['marca_id'] = this.dispositivo.id_marca;
		}
		if (this.dispositivo.id_modelo) {
			datosTicket['modelo_id'] = this.dispositivo.id_modelo;
		}
		if (this.dispositivo.dispositivo) {
			datosTicket['tipoElemento'] = this.dispositivo.dispositivo;
		}
		if (this.dispositivo.id_movimiento) {
			datosTicket['fk_elemento_almacen'] = this.dispositivo.id_movimiento;
		}

		this.initLoading('.table-dispositivo', 'Creando ticket');
		this.ticketsSvc.crearTicket(datosTicket).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				this.asignarCliente(data.ID);
			},
			(err) => {
				this.stopLoading('.table-dispositivo');
				this.showSnack(err);
			}
		);
	}

	asignarCliente(idTicket: number) {
		this.clienteSvc.asignarClienteTicket(this.cliente.ID, idTicket).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				this.buscarTecnicoParaCliente(data.ID);
			},
			(err) => {
				this.stopLoading('.table-dispositivo');
				this.showSnack(err);
				this.ticketCreated.emit(idTicket);
			}
		);
	}

	buscarTecnicoParaCliente(idTicket: number) {
		this.clienteSvc.asignarTecnicoUbicacionCliente(this.cliente.ID, idTicket).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				this.stopLoading('.table-dispositivo');
				this.ticketCreated.emit(idTicket);
			},
			(err) => {
				this.stopLoading('.table-dispositivo');
				this.showSnack(err);
				this.ticketCreated.emit(idTicket);
			}
		)
	}

	generarQrDigipass(dispositivo: any) {
		this.initLoading('.table-dispositivo', 'Generando QR');
		this.gtpSvc.generarUrl(dispositivo.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				this.stopLoading('.table-dispositivo');
				this.openDialogQrCode(data.url);
			},
			(err) => {
				this.stopLoading('.table-dispositivo');
				this.showSnack(err);
			}
		);
	}
	
	verDigipass(dispositivo: any) {
		this.initLoading('.table-dispositivo', 'Generando URL');
		this.gtpSvc.generarUrl(dispositivo.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				this.stopLoading('.table-dispositivo');
				this.openLink(data.url);
			},
			(err) => {
				this.stopLoading('.table-dispositivo');
				this.showSnack(err);
			}
		);
	}

	openLink(link: string) {
		window.open(link, 'targetWindow', "width=390,height=844");
	}

	openDialogQrCode(url: string): void {
		const dialogRef = this.dialog.open(DialogQrCodeComponent, {
			data: { cadenaQr: url },
			panelClass: 'panel-aitana',
			disableClose: true,
			autoFocus: false
		});
		dialogRef.updatePosition({ top: '4rem' });
	}

}
