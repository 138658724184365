export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'CONTROL_GENERAL': {
                'TITLE': 'Panel Control'
            },
            'DASHBOARDS': {
                'TITLE': 'Dashboards',
                'VISION_ARTIFICIAL_CLASIFICACION': 'Machine Vision - Classification',
                'TRIAJE_DISPOSITIVOS': 'Triage dispositivos',
                'GLOBAL_PROYECTOS': 'Overall summary',
                'GESTION_LOGISTICA': 'Logistics',
                'SERVICIOS': 'Services'
            },
            'DIGIPASS': {
                'TITLE': 'Digipass',
                'CONSULTA': 'See'
            },
            'GESTION_ACTIVOS': {
                'TITLE': 'Activos',
                'GESTION': 'Gestión',
                'LOG': 'LOG',
                'MAPA': 'Mapa'
            },
            'GESTION_TIENDA': {
                'TITLE': 'Shop',
                'PRODUCTOS_Y_SERVICIOS': 'Products and services'
            },
            'GESTION_SERVICIO': {
                'TITLE': 'Gestión del Servicio',
                'CERTIFICACIONES' : 'Certifications',
                'CITAS': 'Citas',
                'CLIENTES': 'Clientes',
                'COMPRAS': 'Compras',
                'FINANCIEROS': 'Financieros',
                'SEGUIMIENTO_RUTAS': 'Seguimientos de rutas',
                'PRESUPUESTOS': 'Presupuestos',
                'PROVEEDORES': 'Proveedores',
                'TICKETS': {
                    'TITLE': 'Tickets',
                    'CREAR_TICKET': 'Crear ticket',
                    'CREAR_TICKET_CALLCENTER': 'Crear ticket (Call Center)',
                    'CREAR_TICKETS_LOTE': 'Crear tickets (por lotes)',
                    'DUPLICAR_TICKET': 'Duplicar ticket',
                    'VER_TICKETS': 'Ver tickets',
                    'VER_TICKETS_AGRUPADOS': 'Ver tickets agrupados'
                },
                'BUSCADOR_NS': 'Buscar x NS',
                'HISTORICO_ESTADOS': 'Historico estados',
                'TARJETA_VODAFONE' : 'Tarjetas Vodafone Unei',
            },
            'GESTION_PROYECTO': {
                'TITLE': 'Gestión de Proyecto',
                'TENDENCIA': 'Tendencia',
                'TICKETS_ABIERTOS': 'Tickets Abiertos',
                'DIARIO_SEGUIMIENTO': 'Diario de seguimiento',
                'ESTADO_PROYECTOS': 'Estado Proyectos',
                'INTERCAMBIO_ELEMENTOS': 'Intercambio Elementos',
                'GARANTIA10': 'Garantía 10',
                'PROYECTOS': 'Proyectos',
                'SOLICITUDESCOTIZACION': 'Solicitudes cotización',
                'SUPPLY_CHAIN': {
                    'TITLE' : 'Supply Chain',
                    'APROVISIONAMIENTO' : 'Aprovisionamiento',
                    'PROVEEDORES_MATERIAS_PRIMAS': 'Proveedores (Materias primas)',
                    'ACTIVOS_MATERIAS_PRIMAS': 'Activos (Materias primas)',
                    'PRODUCCION' : 'Producción',
                    'DISTRIBUICION' : 'Distribución',
                    'RECLICAJE' : 'Reclicaje'
                },
                'INFORMES': {
                    'TITLE': 'Informes',
                    'PIEZAS_POR_RMA': 'Informe piezas por RMA',
                    'KPI': 'Informes KPI',
                    'INDICADORES': 'Indicadores',
                    'UNEI': 'Indicadores Unei',
                    'CREAR_INFORME_VIA_SQL': 'Crear informe vía SQL',
                    'PARADAS': 'Paradas',
                    'DETALLE_PARADAS': 'Detalle Paradas',
                    'BUGS': 'Bugs',
                    'CUADRO_MANDOS_TICKETS_SLA_BUGS': 'Cuadro Mandos / Tickets - SLA - Bugs',
                    'BUGS_SEGUIMIENTOS_USUARIO_PROYECTO': 'Bugs Seguimientos / Usuario / Proyecto',
                    'PIEZAS_DIAGNOSIS': 'Piezas diagnosis',
                    'NUM_PIEZAS_SERVICIO': 'Nº piezas / servicio',
                    'TIEMPOS_ESTADOS': 'Status times',
                    'TIPOS_SERVICIO': 'Service type',
                    'PIEZAS_PROYECTO_PORCENTAJE': '% Piezas por Proyecto',
                    'LOG_COMUNICACIONES_CLIENTE': 'Log comunicaciones con cliente',
                    'NUM_SEGUIMIENTOS_PROYECTO': 'Nº seguimientos / proyecto',
                    'METRICAS_MYFIXPERT': 'Métricas Myfixpert',
                    'METRICAS_ECONOMICAS': 'Métricas Económico Financieras',
                    'METRICAS_IWOOW': 'Métricas Económico Financieras IW/OOW',
                    'PXQ_MANOBRA': 'PxQ Mano de Obra',
                    'INFORME_PROD_LAB': 'Informe Productividad Lab',
                    'INFORME_OPERACIONES': 'Informe Operaciones Técnicos',
                    'TICKETS_POR_PROYECTO_MES': 'Proyecto y mes',
                    'TICKETS_POR_MARCA_MODELO': 'Marca / Modelo',
                    'TICKETS_CR':'Tickets C(r)',
                    'REPARABILIDAD': 'Reparabilidad',
                    'CALIDAD_REPARACION': 'Calidad reparación',
                    'TOP_PIEZAS': 'TOP Piezas',
                    'INFORME_PIEZAS_RMA': 'Informe piezas por RMA',
                    'MTTR': 'Informe MTTR',
                    'MTBF': 'Informe MTBF',
                    'PXQ_PARTNERS': 'PxQ Partners',
                    'PRODUCTIVIDAD_TECNICOS_PROPIOS': 'Productividad técnicos propios',
                    'EVOLEXP': 'Informe Evolución Expediciones',
                    'PPTOVSCOSTE': 'Informe Presupuestos vs Costes',
                    'TIEMPOGRUPO': 'Informe Tiempos por Grupos',
                    'PARETO': 'Informe Pareto',
                },
                'SERVICIOS': {
                    'TITLE': 'Servicios',
                    'SERVICIOS_MARCA': 'Servicios / Marca',
                    'SERVICIOS_MODELO': 'Servicios / Modelo',
                    'SERVICIOS_PROVINCIA': 'Servicios / Provincia',
                    'SERVICIOS_CLIENTE_SOLICITANTE': 'Servicios / Cliente solicitante',
                    'X_PROVINCIA_PT': 'X Provincia PT',
                    'X_PROYECTO_MES': 'X Proyecto / Mes',
                    'X_PROYECTO_PROVINCIA_MES': 'X Proyecto / Provincia / Mes',
                    'X_ZONA': 'X Zona',
                    'CITAS_POR_TICKETS_CR': 'Citas X Tickets C(R)',
                    'TOP_PARTNERS': 'TOP Partners',
                    'TOP_TECNICOS_PROPIOS': 'TOP Técnicos Propios',
                    'TOP_PIEZAS': 'TOP Piezas'
                },
                'ANS_SLA_KPIS': {
                    'TITLE': 'ANS / SLA / KPIs',
                    'CUMPLIMIENTO_INDICADORES_NETO': 'Cumplimiento de indicadores (NETO)',
                    'CUMPLIMIENTO_INDICADORES_BRUTO': 'Cumplimiento de indicadores (BRUTO)',
                    'VER_GRAFICA': 'Ver gráfica',
                    'VER_INFORME': 'Ver informe',
                    'INFORME_INCUMPLIMIENTOS_CAUSAS_ALEGACIONES': 'Informe de Incumplimientos / Causas & Alegaciones',
                    'INFORME_KPI': 'KPI Indicadores',
                    'INFORME_CUMPLIMIENTO_PIEZAS_ENVIADAS': 'Cumplimientos de Piezas Enviadas',
                    'CUMPLIMIENTO_CITAS_PREVISTA_REALES': 'Cumplimiento de citas (Previstas / Reales)',
                    'RR_TECNICO': 'RR / Técnico',
                    'TAT_GRUPO_ASIGNADO': 'TAT por Grupo Asignado',
                    'TIEMPO_MEDIO_RESPUESTA_CLIENTE': 'Tiempo medio respuesta cliente',
                    'TIEMPO_MEDIO_ASIGNACION_TECNICO': 'Tiempo medio asignación técnico',
                    'TIEMPO_MEDIO_REPARACION': 'Tiempo medio reparación'
                }
            },
            'GESTION_ECONOMICA': {
                'TITLE': 'Gestión Económica',
                'SUBASTAS': 'Subastas',
                'CONTROL_FINANCIEROS_PROVEEDORES': 'Control Financieros Proveedores',
                'PARTES_AUTORIZACION': 'Partes - Autorización',
                'AUTOFACTURACION': 'Autofacturación',
                'RENTABILIDAD': 'Rentabilidad',
                'INFORME_FINANCIEROS': 'Informe Financieros',
                'COSTES': {
                    'TITLE': 'Costes',
                    'TICKET_TOTALES': 'Ticket Totales',
                    'TICKET_TOTALES_PORCENTAJE': 'Ticket Totales (%)',
                    'IMPORTE_TOTAL': 'Importe Total',
                    'IMPORTE_TOTAL_PORCENTAJE': 'Importe Total (%)',
                    'MEDIA_PROYECTOS': 'Media por proyectos',
                    'MEDIA_PROVINCIAS': 'Media por provincia'
                },
                'PROVISIONES': {
                    'TITLE': 'Provisiones',
                    'OFERTAS': 'Ofertas',
                    'PEDIDOS': 'Pedidos',
                    'INFORME_PEDIDOS': 'Informe de pedidos'
                },
            },
            'INTEGRACIONES': {
                'TITLE': 'Integraciones',
                'TOSHIBA': {
                    'TITLE': 'Toshiba',
                    'IMPORTAR_CASOS_DESDE_WL': 'Importar casos desde WL'
                },
                'LENOVO': {
                    'TITLE': 'Lenovo',
                    'IMPORT_EXPORT_FILE_BATCH_LENOVO': 'Import/Export file batch Lenovo'
                },
                'SAMSUNG': {
                    'TITLE': 'Samsung',
                    'SAMSUNG_GI': 'Samsung - G/I',
                    'INFORME_LTE': 'Informe Long Term Pending',
                },
                'MAPFRE': {
                    'TITLE': 'Mapfre',
                    'INFORME_INCIDENCIAS': 'Informe Incidencias Mapfre'
                },
                'MÉTRICAS': {
                    'TITLE': 'Métricas',
                    'MYFIXPERT': 'Myfixpert'
                }
            },
            'CHECKIN_CHECKOUT': {
                'TITLE': 'Check IN - Check OUT',
                'ARTIS': 'ARTIS',
                'ARTIS_GRAFICA': 'Gráficas ARTIS',
                'ARTIS_LLEGADAS': 'Llegadas ARTIS',
                'CHECKIN_CHECKOUT': 'Check IN - Check OUT',
                'INFORME_CHECKIN_CHECKOUT': 'Informe Check IN - Check OUT',
                'TIEMPO_MEDIO_ENTRADA_LABORATORIO': 'Tiempo medio entrada laboratorio',
                'VISION_ARTIFICIAL_CM': 'Machine Vision - Dashboard'
            },
            'LABORATORIO': {
                'TITLE': 'Laboratorio',
                'PANEL_CONTROL_LABORATORIO': 'Panel Control Laboratorio',
                'DIAGNOSIS': {
                    'TITLE':'Diagnosis',
                    'NUEVA_DIAGNOSIS': 'Nueva diagnosis'
                },
                'PRODUCTIVIDAD': {
                    'TITLE':'Productividad',
                    'CONFIGURACION_PRODUCTIVIDAD': 'Configuración Productividad Lab',
                    'INFORME_KPI_PRODUCTIVIDAD': 'Informe KPI - Productividad'
                },
                'REPARABILIDAD': 'Reparabilidad',
                'CALIDAD_REPARACION': 'Calidad Reparación',
            },
            'RECUPERACION': {
                'TITLE': 'Recovery',
                'PROCESO_RECUPERACION': 'Recovery process'
            },
            'RED_SERVICIO': {
                'TITLE': 'Red de Servicio',
                'RED_SERVICIO': 'Red de servicio',
                'GESTION_PROVEEDORES': 'Supplier management',
                'ENVIO_CORREO': 'Envío de correo',
                'PARTNERS': {
                    'TITLE': 'Partners',
                    'GASTO_COLABORADOR': 'Gasto por colaborador',
                    'VALORACIONES_PARTNER': 'Valoraciones Partner',
                },
                'TECNICOS': {
                    'TITLE': 'Técnicos',
                    'PRODUCTIVIDAD_LAB': 'Productividad Lab',
                    'PRODUCTIVIDAD': 'Productividad',
                    'ACTIVIDAD_TECNICOS': 'Actividad técnicos',
                    'ACTIVIDAD_TECNICOS_PROYECTO': 'Actividad técnicos por proyecto',
                    'CUADRO_MANDOS_GRUPAL': 'Cuadro de Mandos Grupal'
                },
                'MAPA_TECNICOS': 'Mapa de técnicos'
            },
            'CENTRO_CONOCIMIENTO': {
                'TITLE': 'Centro de conocimiento',
                'TITLE_GESTION': 'Management Knowledge Center',
                'CENTRO_CONOCIMIENTO': 'Centro de conocimiento',
                'GESTION_VIDEOS_DOCUMENTOS': 'Video and document management'
            },
            'CONFIGURACION': {
                'TITLE': 'Configuración',
                'SISTEMA_ALERTA_TEMPRANA': 'Sistema de Alerta Temprana',
                'CONTROL_DE_CAMBIOS': 'Control de cambios',
                'CONTRATOS': 'Contratos',
                'GESTION_CAJAS': 'Gestión cajas',
                'CONTROL_ACTIVIDAD': {
                    'TITLE': 'Control de actividad',
                    'LOG_ACCESO': 'Log de Acceso',
                    'LOG_ACTIVIDAD': 'Log de Actividad',
                    'INFORME_TIEMPO_USUARIO_PROYECTO': 'Informe Tiempo / Usuario / Proyecto',
                    'CUADRO_MANDOS_MONITORIZACION': 'Cuadro de Mandos / Monitorización'
                }
            },
            'RECURSOS_CORPORATIVOS': {
                'TITLE': 'Recuros corporativos',
                'RRHH_DISTRIBUCION_PROYECTOS': 'RRHH - Distribución en Proyectos',
                'COPPER': {
                    'TITLE': 'COPPER',
                    'PANEL': 'Panel',
                    'CLIENTES_POTENCIALES': 'Clientes Potenciales',
                    'PERSONAS': 'Personas',
                    'EMPRESAS': 'Empresas',
                    'OPORTUNIDADES': 'Oportunidades',
                    'PROYECTOS': 'Proyectos'
                },
                'COTIZADOR_B2B': 'Cotizador B2B',
                'B2B_CATALOGO_SERVICIOS': 'Catálogo de servicios',
                'B2B_FAMILIAS': 'Familias'
            },
            'LOGISTICA': {
                'TITLE': 'Logística',
                'PANEL_CONTROL': 'Panel de Control',
                'LOGISTICA_INVERSA': {
                    'TITLE': 'Logística Inversa',
                    'CAJAS': 'Cajas',
                    'PALES': 'Pales',
                    'DEVOLUCION': 'Devolución'
                },
                'TRANSPORTES': {
                    'TITLE': 'Transportes',
                    'SEGUIMIENTO': 'Seguimiento',
                    'TRANSPORTE_EXPEDICIONES': 'Transporte - Expediciones',
                    'PERDIDAS_SINIESTROS': 'Pérdidas & Siniestros',
                    'GESTION_FACTURAS': {
                        'TITLE': 'Gestión de facturas',
                        'CUADRO_MANDOS_ADMINISTRACION': 'Cuadro de mandos Administración',
                        'CUADRO_MANDOS_PROYECTOS': 'Cuadro de Mandos Proyectos',
                        'LINEAS_FACTURAS': 'Lineas de facturas'
                    }
                },
                'STOCK_SAP': 'Stock ERP',
                'PXQ': 'PXQ Logística'
            },
            'ALMACEN': {
                'TITLE': 'Almacén',
                'VER_STOCK': 'Ver Stock',
                'ENTRADA_CAJAS': 'Entrada Cajas/Palés',
                'ENTREGA_ENVIO_PIEZAS': 'Entrega/Envío de piezas',
                'MOVIMIENTOS_STOCK': 'Movimientos de stock',
                'STOCK_PROYECTO': 'Stock por Proyecto',
                'STOCK_COLABORADORES': 'Stock Colaboradores',
                'RELACION_PN_SUSTITUTOS': 'Relación PN Sustitutos',
                'INFORME_STOCK_FINANCIERO': 'Informe Stock Financiero',
                'CONFIGURACION': {
                    'TITLE': 'Configuración',
                    'ALMACENES': 'Almacenes',
                    'CONFIGURADOR_UBICACIONES': 'Configurador Ubicaciones',
                    'ALMACENES_PROYECTOS': 'Almacenes - Proyectos',
                    'ALMACENES_COLABORADORES': 'Almacenes - Colaboradores',
                    'MAESTRO_ARTICULOS': 'Maestro de Artículos',
                    'SUBIR_INVENTARIO': 'Carga de Inventario',
                }
            },
            'MISC': {
                'TITLE': 'MISC',
                'CONTROL_PIEZAS_TICKETS': 'Control de piezas/tickets',
                'PACKING_LIST': 'Packing List',
                'INFORME_TRANSFORMACIONES': 'Informe Transformaciones',
                'BUY_BACK': {
                    'TITLE': 'BuyBack',
                    'CATALOGO_PRODUCTOS': 'Catálogo de productos',
                    'CARGA_PRECIOS': 'Carga de precios',
                    'OPERACIONES_BUYBACK': 'Operaciones BuyBack'
                }
            },
            'BLOQ1' : {
                'TITLE': 'Bloq 1',
                'PAGE1': 'Page 1',
                'PAGE2': 'Page 2'
            },
            'BLOQ2' : {
                'TITLE': 'Bloq 2',
                'PAGE1': 'Page 1',
                'PAGE2': 'Page 2'
            },
            'SAMPLE'        : {
                'TITLE': 'Sample',
                'BADGE': '25'
            },
            'CHAT'        : {
                'TITLE': 'Chat'
            },
            'OPORTUNIDADES' :{
                'TITLE':  'Oportunities',
            },
            'PANEL' :{
                'TITLE':  'Panel',
            },
            'CLIENTESPOTENCIALES':{
                'TITLE':'Clientes Potenciales',
            },
            'GARANTIA10':{
                'TITLE':'Garantía 10'
            },
            'METRICAS':{
                'TITLE':'Metrics'
            },
            'INFORMEKPI':{
                'TITLE':'KPI report'
            },
            'TRANSPORTES':{
                'TITLE':'Shipment',
                'PERDIDAS': 'Lossess & Claims'
            },
            'INFORMES':{
                'TITLE':'Informes',
                'INFORME_TICKETS': 'Informe Tickets',
                'RESIDUOS': {
                    'TITLE': 'Residuos',
                    'HISTORICO_ARCHIVO_CRONOLOGICO': 'Histórico. Archivo Cronológico'
                },
                'LOG_MOVIMIENTOS': {
                    'TITLE': 'Movimientos',
                }
            },
            'DIAGNOSIS':{
                'TITLE':'Diagnosis',
                'NUEVA_DIAGNOSIS': 'Nueva diagnosis'
            },
            'TAGS': {
                'ALMACEN': 'Warehouse',
                'CONFIGURACION': 'Configuration',
                'COLABORADOR': 'Partner',
                'PLANTA': 'Planta',
                'ACTIVOS': 'Activos',
                'TIENDA' : 'Shop',
                'PRODUCTOS' : 'Products',
                'SERVICIOS' : 'Services'
            },
            'TELCO': {
                'TITLE': 'TELCO',
                'EXCEL': 'Upload file',
            }
        }
    }
};
