// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    firebase: {

        apiKey: "AIzaSyDxpa_F0pNQu_U1_FnO2fGV4CYmZAgYfR8",
        authDomain: "aitana-angular.firebaseapp.com",
        databaseURL: "https://aitana-angular.firebaseio.com",
        projectId: "aitana-angular",
        storageBucket: "aitana-angular.appspot. com",
        messagingSenderId: "436354205196",
        appId: "1:436354205196:web:da1eb1c1aadaad798e1029",
        measurementId: "G-V747HPJFBM"
      },
      WEBSOCKET_URL: 'https://wss-app.aitanasolutions.com',
      API_URL: 'https://ws-app.aitanasolutions.com',
      API_AISTATISTICS : 'http://localhost:3010/api',
      URL_AISTATISTICS : 'http://localhost:4300',
      clientSecret: 'MzUzYjMwMmM0NDU3NGY1NjUwNDU2ODdlNTM0ZTdkNmE6Mjg2OTI0Njk3ZTYxNWE2NzJhNjQ2YTQ5MzU0NTY0NmM=',
      linkNewAitana: 'https://new.aitana.cygitsolutions.com',
      entorno: 'aitana'
};

export const prioridades = [
  {
    id: 1,
    nombre: "Muy baja",
    color: "#8bc34a"
  },
  {
    id: 2,
    nombre: "Baja",
    color: "#ffeb3b"
  },
  {
    id: 3,
    nombre: "Media",
    color: "#fb8c00"
  },
  {
    id: 4,
    nombre: "Urgente",
    color: "#ff5722"
  },
  {
    id: 5,
    nombre: "Muy urgente",
    color: "#d50000"
  },
];

export const estados_ticket = [
  {
    id: 'new',
    nombre: 'Nueva'
  },
  {
    id: 'assign',
    nombre: 'En Curso (Asignado)'
  },
  {
    id: 'waiting',
    nombre: 'En espera'
  },
  {
    id: 'plan',
    nombre: 'En curso (Planificado)'
  },
  {
    id: 'old_done',
    nombre: 'Cerrada (Resuelta)'
  },
  {
    id: 'old_notdone',
    nombre: 'Cerrada (No Resuelta)'
  },
]

export const tipos_cliente = [
    {
      id: 1,
      nombre: 'Particular'
    },
    {
      id: 2,
      nombre: 'Empresa'
    }
  ]


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
