<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">Configuracion de proyectos</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column" style="width: 40vw">
        <form #mainForm="ngForm" [formGroup]="formConfiguracion">
            <div fxLayout="row" class="mb-12">
                <mat-form-field appearance="standard" class="form-group w-100-p">
                    <mat-label>Nompre para tu configuración<span class="required">*</span></mat-label>
                    <input matInput name="nombre" formControlName="nombre" class="form-control" autocomplete="off">
                </mat-form-field>
            </div>
            
            <div fxLayout="row" class="mb-12">
                <mat-form-field appearance="standard" class="form-group w-100-p">
                    <mat-label>Proyectos de tu configuración<span class="required">*</span></mat-label>
                    <mat-select name="proyectos" panelClass="selectProyectos" formControlName="proyectos" class="form-control chip-list" [multiple]="true">
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip *ngFor="let pSel of proyectosSelected" [removable]="true" (removed)="deselectProject(pSel)">
                                    {{ pSel.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search [formControl]="proyectosFilterCtrl" [searching]="searchingProyectos"
                            placeholderLabel="Buscar proyecto..." noEntriesFoundLabel="No se encontro ningún proyecto"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let itemProject of proyectos" [value]="itemProject.id">{{itemProject.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="proyectosSelected?.length > 0" class="btn-close" mat-button matSuffix mat-icon-button (click)="clearProyectos($event)">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            
            <div fxLayout="row" class="mb-12">
                <mat-slide-toggle formControlName="esPublica">Configuración pública</mat-slide-toggle>
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button class="btn-aitana" [class.spinner]="loadingSave" [disabled]="loadingSave" (click)="guardarConfiguracion()">
        Guardar
    </button>
</mat-dialog-actions>