<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">Nuevo resultado del test : </h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography" style="width: 60vw">
    <div fxLayout="column">
        <form [formGroup]="formTestDeviceResult" class="w-100-p">
            <div fxLayout="column" *ngFor="let item of testDevice.testItems" class="p-8" style="border:1px solid #ddd">
                <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
                    <fa-icon [icon]="['fas', item.fa_icon]"></fa-icon>
                    <h2 class="m-0">{{item.name}}</h2>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-around center" [formGroup]="formTestDeviceResult.get(''+item.id)">
                    
                    <mat-slide-toggle formControlName="passed">Pasa el test</mat-slide-toggle>

                    <mat-form-field appearance="standard" class="w-20-p mr-16">
                        <mat-label>Estado actual (porcentaje)</mat-label>
                        <input matInput type="number" min="0" max="100" step="0.01" name="lifeStatusPercentaje" formControlName="lifeStatusPercentaje" class="form-control" autocomplete="off">
                    </mat-form-field>

                    <mat-form-field appearance="standard" class="w-40-p mr-16">
                        <mat-label>Comentarios</mat-label>
                        <textarea matInput name="comment" formControlName="comment" class="form-control"></textarea>
                    </mat-form-field>

                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-button *ngIf="!testDeviceResultId" class="btn-aitana" [class.spinner]="loadingSave" [disabled]="loadingSave || !formTestDeviceResult.valid" (click)="saveTestDeviceResult()">Guardar resultado</button>
</mat-dialog-actions>