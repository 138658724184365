import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DbService } from '../../services/db/db.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-cliente-dialog',
  templateUrl: './cliente-dialog.component.html',
  styleUrls: ['./cliente-dialog.component.scss']
})
export class ClienteDialogComponent implements OnInit {
    closeDialog = new EventEmitter();
    
    empresas: Observable<any>;
    clienteForm: FormGroup;
    buttonsEnable: Boolean;

  constructor(private formBuilder: FormBuilder, private dbservice: DbService, private _snackBar: MatSnackBar) { 
    this.buttonsEnable = true;
    this.empresas = dbservice.getEmpresas();

    this.clienteForm = this.formBuilder.group({
        nombre: [null, Validators.required],
        segundo_nombre: null,
        apellido: null,
        prefijo: null,
        sufijo: [null],
        fk_empresa: null,
        titulo: null,
        correo: null,
        telefono: [null],
        linkedin: null,
        web: null,
        direccion: null,
        localidad: null,
        provincia: null,
        pais: null,
        codigo_postal: null,
        descripcion: null,
    });
   }

  ngOnInit(): void {
  }

  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
        duration: 5000,
      });
  }

  submit() {
        this.buttonsEnable = false;
        if (!this.clienteForm.valid) {
            this.buttonsEnable = true;
            return;
        }
        
        this.dbservice.newClientePotencial(this.clienteForm.value).subscribe(data => {
            this.closeDialog.emit(); 
            this.openSnackBar('Cliente creado', 'Cerrar');
        });

        //console.log(JSON.stringify(this.oportunidadForm.value));
    }

}
