import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class ChatGptService {

  	constructor(
		private http: HttpClient, 
		private authSvc: AuthService
	) { }

    sendMsg(userMsg : string, completeChat : string, botType : string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
                userMsg: userMsg,
                completeChat: completeChat
			}
            
			let url = environment.API_URL + '/chat-gpt/send';
			if(botType == 'avacom')
				url = environment.API_URL + '/chat-gpt/send/avacom';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws sendMsg", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws sendMsg", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

}
