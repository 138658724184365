import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class DiagnosisService {

	constructor(
		private http: HttpClient, 
		private authSvc: AuthService, 
		private helperSvc: HelperService
	) { }

	getDiagnosis(idTicket: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				ticketId: idTicket,
			}

			let data = [
				{
					id: 1,
					sintoma: "Fallo eléctrico",
					reproduceProblema: false,
					conditionCode: { ID: 5931, descripcion: "1 - Constante" },
					symptonCode: { ID: 5970, descripcion: "11D - El aparato se desconecta solo" },
					sectionCode: { ID: 7120, descripcion: "INC - Conector interno" },
					piezas: [
						{ pn: "PAD 10.1 LGV700", description: "Artículo TABLET - DG - PAD 10.1 LG-V700"}
					]
				},
				{
					id: 2,
					sintoma: "Hay un problema con la pantalla",
					reproduceProblema: true,
					conditionCode: { ID: 5933, descripcion: "3 - Después de algún tiempo" },
					symptonCode: { ID: 5992, descripcion: "13C - Señal acustica / sin imagen" },
					sectionCode: { ID: 7077, descripcion: "IMG - Unidad de visualización de imágenes" },
					piezas: []
				}
			];

			// observer.next(data);
			observer.next([]);

			// const url = environment.API_URL + '/diagnosis';
			// this.http.get(url, options).subscribe(
			// 	(response: any) => {
			// 		console.log("ws getDiagnosis", response);
			// 		observer.next(response);
			// 	},
			// 	(err) => {
			// 		console.log("error ws getDiagnosis", err);
			// 		let msgError = "Se ha producido un error cargando los datos";

			// 		observer.error(msgError);
			// 	}
			// );

			return {unsubscribe() {}};
		});
	}

	getConditionCodes(ticket?: number, idMarca?: number, idModelo?: number, tipoDispositivo?: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				ticketId: ticket,
				marcaId: idMarca,
				modeloId: idModelo,
				tipo: tipoDispositivo
			}

			const url = environment.API_URL + '/diagnosis/conditions?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getConditionsCodes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getConditionsCodes", err);
					let msgError = "Se ha producido un error cargando los datos";

					if (err.error.error === 'invalid_tipo_elemento') {
						msgError = "En los datos del dispositivo debes indicar un tipo de dispositivo";
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getSymptonCodes(ticket?: number, idMarca?: number, idModelo?: number, tipoDispositivo?: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				ticketId: ticket,
				marcaId: idMarca,
				modeloId: idModelo,
				tipo: tipoDispositivo
			}

			const url = environment.API_URL + '/diagnosis/symptoms?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getSymptonCodes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getSymptonCodes", err);
					let msgError = "Se ha producido un error cargando los datos";

					if (err.error.error === 'invalid_tipo_elemento') {
						msgError = "En los datos del dispositivo debes indicar un tipo de dispositivo";
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getSectionCodes(ticket: number, idMarca: number, idModelo: number, tipoDispositivo: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				ticketId: ticket,
				marcaId: idMarca,
				modeloId: idModelo,
				tipo: tipoDispositivo
			}

			const url = environment.API_URL + '/diagnosis/sections?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getSectionCodes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getSectionCodes", err);
					let msgError = "Se ha producido un error cargando los datos";

					if (err.error.error === 'invalid_tipo_elemento') {
						msgError = "En los datos del dispositivo debes indicar un tipo de dispositivo";
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getModelIndicatorsLenovo(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/diagnosis/model-indicators-lenovo';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getModelIndicatorsLenovo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getModelIndicatorsLenovo", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	getEstadosReparacion(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/diagnosis/estados-reparacion';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getEstadosReparacion", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getEstadosReparacion", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getTiposProblemasDiagnosis(ticket: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				ticketId: ticket,
			}

			const url = environment.API_URL + '/diagnosis/problemas?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposProblemasDiagnosis", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposProblemasDiagnosis", err);
					let msgError = "Se ha producido un error cargando los datos";
					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getPartNumberLenovo(filtro): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				filtro: filtro,
			}

			const url = environment.API_URL + '/diagnosis/pn-lenovo?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPartNumberLenovo", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPartNumberLenovo", err);
					let msgError = "Se ha producido un error cargando los datos";
					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getProblemasComunes(ticket: number, idMarca: number, idModelo: number, tipoDispositivo: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				ticketId: ticket,
				marcaId: idMarca,
				modeloId: idModelo,
				tipo: tipoDispositivo
			}

			const url = environment.API_URL + '/diagnosis/averias-comunes?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getProblemasComunes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getProblemasComunes", err);
					let msgError = "Se ha producido un error cargando los datos";

					if (err.error.error === 'invalid_tipo_elemento') {
						msgError = "En los datos del dispositivo debes indicar un tipo de dispositivo";
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getPiezas(numPage: number, pageSize: number, columnOrder: string, columnDirection: string, ticket: number, idMarca: number, idModelo: number, tipoDispositivo: string, filtros: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				page: numPage,
				pageSize: pageSize,
				ticketId: ticket,
				marcaId: idMarca,
				modeloId: idModelo,
				tipo: tipoDispositivo
			}

			if (columnDirection !== "") {
				params['sortColum'] = columnOrder;
				params['sortDirection'] = columnDirection;
			}

			if (filtros.description && filtros.description !== "") {
				params['description'] = filtros.description;
			}
			if (filtros.pn && filtros.pn !== "") {
				params['pn'] = filtros.pn;
			}
			if (filtros.pn_descripcion && filtros.pn_descripcion !== "") {
				params['pn_descripcion'] = filtros.pn_descripcion;
			}

			const url = environment.API_URL + '/diagnosis/piezas?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPiezas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPiezas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	
	getPiezasDiagnosis(params: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/diagnosis/piezas-diagnosis?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPiezasDiagnosis", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPiezasDiagnosis", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	comprobarStock(pn: string, idProyecto: number, idColaborador: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				pn: pn,
				idProyecto: idProyecto,
				idColaborador: idColaborador
			}

			const url = environment.API_URL + '/diagnosis/comprobar-stock-pieza?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws comprobarStockPieza", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws comprobarStockPieza", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getDoaReasons(ids = ""): Observable<any>{
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

            const params = {
				ids: ids
			}

			const url = environment.API_URL + '/diagnosis/doa-reasons?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDoaReasons", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDoaReasons", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
    }

    getDoaReasonsCategories(ids = ""): Observable<any>{
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

            const params = {
				ids: ids
			}

			const url = environment.API_URL + '/diagnosis/doa-reasons-categories?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDoaReasons", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDoaReasons", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
    }
    getDoaReasonsCategoriesGroup(tipo?: string): Observable<any>{
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

            const params = {
				tipo: tipo
			}

			const url = environment.API_URL + '/diagnosis/doa-reasons-categories-group?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getDoaReasonsCategoriesGroup", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDoaReasonsCategoriesGroup", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
    }
	
	getPiezasMasUsadas(ticket: number, idMarca: number, idModelo: number, tipoDispositivo: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			// let data = [
			// 	{ id: 8, description: "Pieza 8", pn: "00UP646" },
			// 	{ id: 2, description: "Pieza 2", pn: "00UP511" },
			// 	{ id: 9, description: "Pieza 9", pn: "00UP398" },
			// 	{ id: 7, description: "Pieza 7", pn: "00UP520" },
			// 	{ id: 5, description: "Pieza 5", pn: "00UP111" },
			// ]
			// observer.next(data);

			const params = {
				ticketId: ticket,
				marcaId: idMarca,
				modeloId: idModelo,
				tipo: tipoDispositivo
			}

			const url = environment.API_URL + '/diagnosis/top-piezas?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getPiezasMasUsadas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getPiezasMasUsadas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
	
	
	
	getDocumentosRecomendados(idMarca: number, idModelo: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			let data = [
				{
					id: 3,
					nombre: "Documento 3"
				},
				{
					id: 2,
					nombre: "Documento 2"
				},
				{
					id: 5,
					nombre: "Documento 5"
				},
			]
			observer.next(data);

			// const url = environment.API_URL + '/diagnosis/problemas-comunes?' + this.helperSvc.serializeData(params);
			// this.http.get(url, options).subscribe(
			// 	(response: any) => {
			// 		console.log("ws getProblemasComunes", response);
			// 		observer.next(response);
			// 	},
			// 	(err) => {
			// 		console.log("error ws getProblemasComunes", err);
			// 		let msgError = "Se ha producido un error cargando los datos";

			// 		observer.error(msgError);
			// 	}
			// );

			return {unsubscribe() {}};
		});
	}

	uploadAdjunto(file: File): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const headers = new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
			});

			const data = new FormData();
			data.append('file', file);

			// let progress = 0;
			// while (progress < 100) {
			// 	setTimeout(() => {
			// 		observer.next({ progress: progress, res: null});
			// 		progress += 10;
			// 	}, 400);
			// }
			// if (progress >= 100) {
			// }
			const idAdjunto = moment().format('YYYYMMDDHHmmssSSSS');
			observer.next({ progress: 100, res: { id: idAdjunto } });

			// const url = environment.API_URL + '/diagnosis/upload';
			// this.http.post(url, data, {reportProgress: true, observe: 'events', headers: headers}).subscribe(
			// 	(event: HttpEvent<any>) => {
			// 		switch (event.type) {
			// 			case HttpEventType.UploadProgress:
			// 				let progress = Math.round(event.loaded / event.total * 100);
			// 				console.log("Uploaded " + progress + "%");
			// 				observer.next({progress: progress, res: null});
			// 				break;
						
			// 				case HttpEventType.Response:
			// 				console.log("Upload file complete", event.body);
			// 				observer.next({progress: 100, res: event.body});
			// 				break;
			// 		}
			// 	}
			// );

			return {unsubscribe() {}};
		});
	}

	deleteAdjunto(idDoc: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			observer.next(true);

			// const url = environment.API_URL + '/diagnosis/deleteFile/' + idDoc;
			// this.http.put(url, {}, options).subscribe(
			// 	(response: any) => {
			// 		console.log("ws deleteAdjunto", response);
			// 		observer.next(response);
			// 	},
			// 	(err) => {
			// 		console.log("error ws deleteAdjunto", err);
			// 		let msgError = "Se ha producido un error eliminando el documento";

			// 		observer.error(msgError);
			// 	}
			// );

			return {unsubscribe() {}};
		});
	}
	
	solcitarSintomaAutoDiagnosis(idTicket: number, sintomas: any, langUser: string, tipoElemento: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const data = {
				idTicket: idTicket,
				sintomas: sintomas,
				language: langUser,
				tipoElemento: tipoElemento
			}

			console.log("params", data);

			const url = environment.API_URL + '/diagnosis/autodiagnosis-bot';
			this.http.post(url, data, options).subscribe(
				(response: any) => {
					console.log("ws solicitarSintomaAutoDiagnosis", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws solicitarSintomaAutoDiagnosis", err);
					let msgError = "Se ha producido un error";

					if (err.error.error) {
						observer.error(err.error);
					}
					else {
						observer.error(msgError);
					}

				}
			);

			return {unsubscribe() {}};
		});
	}
	
	crearAutoDiagnosis(idTicket: number, dataIris: Array<any>, observaciones?: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const data = {
				idTicket: idTicket,
				dataIris: dataIris,
				observaciones: observaciones,
			}

			// console.log("params", data);

			const url = environment.API_URL + '/diagnosis/create-autodiagnosis';
			this.http.post(url, data, options).subscribe(
				(response: any) => {
					console.log("ws createAutoDiagnosis", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws createAutoDiagnosis", err);
					let msgError = "Se ha producido un error";

					if (err.error.error) {
						observer.error(err.error);
					}
					else {
						observer.error(msgError);
					}

				}
			);

			return {unsubscribe() {}};
		});
	}

	getWeblordAreas(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/diagnosis/weblord-areas';
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getWeblordAreas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getWeblordAreas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getWeblordOccurrences(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/diagnosis/weblord-occurrences';
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getWeblordOccurrences", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getWeblordOccurrences", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getWeblordRepairActions(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/diagnosis/weblord-repair-actions';
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getWeblordRepairActions", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getWeblordRepairActions", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getWeblordSubareas1(area): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				area: area,
			};

			const url = environment.API_URL + '/diagnosis/weblord-subareas1?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getWeblordSubareas1", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getWeblordSubareas1", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getWeblordSubareas2(subarea1): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				subarea1: subarea1,
			};

			const url = environment.API_URL + '/diagnosis/weblord-subareas2?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getWeblordSubareas2", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getWeblordSubareas2", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getWeblordSintomas(subarea1, subarea2): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				subarea1: subarea1,
				subarea2: subarea2,
			};

			const url = environment.API_URL + '/diagnosis/weblord-sintomas?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getWeblordSintomas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getWeblordSintomas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getDireccionesDiagnosis(idincidencia): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				idincidencia: idincidencia,
			};

			const url = environment.API_URL + '/diagnosis/direcciones?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getDireccionesDiagnosis", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getDireccionesDiagnosis", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getIrisDiagnosisConditionCodes(idincidencia): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				idincidencia: idincidencia,
			};

			const url = environment.API_URL + '/diagnosis/condition-codes?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getIrisDiagnosisConditionCodes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getIrisDiagnosisConditionCodes", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getIrisDiagnosisSymptomCodes(idincidencia): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				idincidencia: idincidencia,
			};

			const url = environment.API_URL + '/diagnosis/symptom-codes?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getIrisDiagnosisSymptomCodes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getIrisDiagnosisSymptomCodes", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getIrisDiagnosisSectionCodes(idincidencia): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				idincidencia: idincidencia,
			};

			const url = environment.API_URL + '/diagnosis/section-codes?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getIrisDiagnosisSectionCodes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getIrisDiagnosisSectionCodes", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getIrisDiagnosisDefectCodes(idincidencia): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				idincidencia: idincidencia,
			};

			const url = environment.API_URL + '/diagnosis/defect-codes?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getIrisDiagnosisDefectCodes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getIrisDiagnosisDefectCodes", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getIrisDiagnosisRepairCodes(idincidencia, idDiagnosis): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				idincidencia: idincidencia,
				diagnosis: idDiagnosis
			};

			const url = environment.API_URL + '/diagnosis/repair-codes?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getIrisDiagnosisRepairCodes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getIrisDiagnosisRepairCodes", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getIrisDiagnosisNtfCodes(idincidencia): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				idincidencia: idincidencia,
			};

			const url = environment.API_URL + '/diagnosis/ntf-codes?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					// console.log("ws getIrisDiagnosisNtfCodes", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getIrisDiagnosisNtfCodes", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getIris(idTicket: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/diagnosis/iris_incidencia/' + idTicket;
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getSintomas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getSintomas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	guardarDiagnosis(params:any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

            if(params && params.ID != null && params.ID != ""){
                const url = environment.API_URL + '/diagnosis/update/'+params.ID;
                this.http.put(url, params, options).subscribe(
                    (response: any) => {
                        console.log("ws guardarDiagnosis", response);
                        observer.next(response);
                    },
                    (err) => {
                        console.log("error ws guardarDiagnosis", err);
                        let msgError = "Se ha producido un error";

                        if (err.error.error) {
                            observer.error(err.error);
                        }
                        else {
                            observer.error(msgError);
                        }

                    }
                );
            }else{
                const url = environment.API_URL + '/diagnosis/create';
                this.http.post(url, params, options).subscribe(
                    (response: any) => {
                        console.log("ws guardarDiagnosis", response);
                        observer.next(response);
                    },
                    (err) => {
                        console.log("error ws guardarDiagnosis", err);
                        let msgError = "Se ha producido un error";

                        if (err.error.error) {
                            observer.error(err.error);
                        }
                        else {
                            observer.error(msgError);
                        }

                    }
                );
            }

			return {unsubscribe() {}};
		});
	}
	
	repararDiagnosis(params:any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			params['web'] = 1;

			const url = environment.API_URL + '/diagnosis/repair/'+params.ID;
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws repararDiagnosis", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws repararDiagnosis", err);
					let msgError = "Se ha producido un error";

					if (err.error.error) {
						observer.error(err.error);
					}
					else {
						observer.error(msgError);
					}

				}
			);

			return {unsubscribe() {}};
		});
	}

    crearDiagnosisDesdeSintoma(sintoma, pieza, idTicket): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

            const params = {
                FK_entities: sintoma.FK_entities,
                diag_pn1: pieza.pn,
                diag_desc1: pieza.description,
				oow: pieza.oow ? pieza.oow : 0,
				prob_retornable: pieza.prob_retornable ? pieza.prob_retornable : 0,
				parts_model_indicator: pieza.parts_model_indicator ? pieza.parts_model_indicator : null,
                idincidencia: idTicket,
                condition_code: sintoma.conditionCode.ID,
                symptom_code: sintoma.symptonCode?sintoma.symptonCode.ID:sintoma.symptomCode?sintoma.symptomCode.ID:null,
                section_code: sintoma.sectionCode.ID,
                fk_codigos_iris: sintoma.ID,
                ...{piezaAlmacen: pieza}
            }

			const url = environment.API_URL + '/diagnosis/create';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws guardarDiagnosis", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws guardarDiagnosis", err);
					let msgError = "Se ha producido un error";

					if (err.error.error) {
						observer.error(err.error);
					}
					else {
						observer.error(msgError);
					}

				}
			);

			return {unsubscribe() {}};
		});
	}
    
	crearDiagnosisAsistente(idTicket, sintoma, datosPiezas): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

            const params = {
                idTicket: idTicket,
				sintoma: sintoma,
				piezas: datosPiezas
            }

			const url = environment.API_URL + '/diagnosis/create-diagnosis-asistente';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearDiagnosisAsistente", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearDiagnosisAsistente", err);
					let msgError = "Se ha producido un error";

					if (err.error.error) {
						observer.error(err.error);
					}
					else {
						observer.error(msgError);
					}

				}
			);

			return {unsubscribe() {}};
		});
	}

    eliminarDiagnosis(id: any): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/diagnosis/eliminar-diagnosis/'+id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws eliminarDiagnosis", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws eliminarDiagnosis", err);
					let msgError = "Se ha producido un error guardando los datos";
					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
    }

    eliminarIris(id: any): Observable<any> {
        return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/diagnosis/eliminar-iris/'+id;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws eliminarIris", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws eliminarIris", err);
					let msgError = "Se ha producido un error guardando los datos";
					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
    }

	crearSintoma(dataSintoma:any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			let params = {
				id_ticket: dataSintoma.id_ticket,
				sintoma: dataSintoma.sintoma,
				condition_code: dataSintoma.conditionCode,
				symptom_code: dataSintoma.symptonCode,
				section_code: dataSintoma.sectionCode,
			};

			const url = environment.API_URL + '/diagnosis/create-sintoma';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearSintoma", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearSintma", err);
					let msgError = "Se ha producido un error guardando los datos";
					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	modificarSintoma(idSintoma, dataSintoma: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}
            
			let params = {
				ID: idSintoma,
				reproduceProblema: dataSintoma.reproduceProblema,
				condition_code: dataSintoma.conditionCode,
				section_code: dataSintoma.sectionCode,
				symptom_code: dataSintoma.symptonCode,
                sintoma: dataSintoma.sintoma
			};

			const url = environment.API_URL + '/diagnosis/update-sintoma/'+ idSintoma;
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws modificarSintoma", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws modificarSintoma", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
}
