import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AnalisisProyectoComponent } from './analisis-proyecto.component';

import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {BuscadorModule} from '../../../componentes/buscador/buscador.module';
import { TranslateModule } from '@ngx-translate/core';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes = [
    {
        path     : 'garantia-10/analisis-proyecto/:idProyecto',
        component: AnalisisProyectoComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin }
    }
  ];

@NgModule({
  declarations: [AnalisisProyectoComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    MatButtonModule,
    BuscadorModule,
    TranslateModule,
    MatGridListModule,
    MatTableModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatSortModule,
    FuseSharedModule,
    MatIconModule
  ],
  exports: [
    MatSortModule
  ]
})
export class AnalisisProyectoModule { }
