import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-dialog-alert',
  templateUrl: './dialog-alert.component.html',
  styleUrls: ['./dialog-alert.component.scss']
})
export class DialogAlertComponent implements OnInit {

	titulo: string;
	mensaje: string;
	btnLabelOk?: string = "Aceptar";

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogConfirmComponent>,
	) { }

	ngOnInit(): void {
		if (this.data.titulo) {
			this.titulo = this.data.titulo;
		}
		if (this.data.mensaje) {
			this.mensaje = this.data.mensaje;
		}
		if (this.data.btnLabelOk) {
			this.btnLabelOk = this.data.btnLabelOk;
		}
	}

	accept(): void {
		this.dialogRef.close(true);
	}

}
