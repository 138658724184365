<div fxLayout="column" fxFlex fusePerfectScrollbar>

    <mat-list class="date" cdkFocusRegionStart>

        <h3 matSubheader cdkFocusInit>
            <span>Hoy</span>
        </h3>

        <div class="secondary-text mat-display-1 mb-0 p-16">
            <div class="mb-12 text-capitalize">
                {{formatDate(date, 'dddd')}}
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <span> {{formatDate(date, 'DD')}}</span>
                <!-- <span class="mat-subheading-1">th</span> -->
                <span class="ml-8 text-capitalize"> {{formatDate(date, 'MMMM')}}</span>
            </div>
        </div>
    </mat-list>

    <mat-divider cdkFocusRegionEnd></mat-divider>

    <div fxLayout="column" class="mt-8">
        <div *ngIf="notificaciones.length === 0" class="py-12" fxLayout="row">
            <p class="secondary-text font-size-16 w-100-p text-center">Sin notificaciones</p>
        </div>

        <div fxLayout="row" class="item-notificacion" [ngClass]="notificacion.color" *ngFor="let notificacion of notificaciones">
            <div fxLayout="column" class="w-100-p">
                <div fxLayout="row" class="header-notificacion pr-24 mb-4" >
                    <div fxLayout="column">
                        <h3 class="m-0 font-size-14">{{notificacion.titulo}}</h3>
                    </div>
                    <button mat-icon-button class="btn-close" (click)="marcarNotificacionLeida(notificacion)">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div fxLayout="column" class="body-notificacion">
                    <p class="m-0 secondary-text font-size-12" [innerHTML]="notificacion.cuerpo"></p>
                    <span class="mt-12 font-size-10 secondary-text text-right">{{formatDate(notificacion.fecha, 'DD/MM/YYYY HH:mm')}}</span>
                </div>
            </div>
        </div>
    </div>


</div>