import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  	constructor() { }
	
	serializeData(data) {
		if (typeof data !== 'object') {
			return ((data == null) ? "" : data.toString());
		}

		let buffer = [];

		for (let name in data) {
			if (!data.hasOwnProperty(name)) {
				continue;
			}
			let value = data[name];
			buffer.push(
				encodeURIComponent(name) +
				"=" +
				encodeURIComponent((value == null) ? "" : value)
			);
		}

		let source = buffer
			.join("&")
			.replace(/%20/g, "+")
			;
		return (source);
	}

	sayIt(text: string, lang: string) {
		if (window.speechSynthesis) {
			let language;
			switch (lang) {
				case 'es':
					language = 'es-ES';
					break;
				case 'en':
					language = 'en-US';
					break;
			
				default:
					language = 'es-ES';
					break;
			}
	
			let speech = new SpeechSynthesisUtterance();
			speech.text = text;
			speech.volume = 1;
			speech.rate = 1;
			speech.pitch = 1;  
			speech.lang = language;
	
			window.speechSynthesis.speak(speech);
		}
		else {
			console.log("speechSynthesis no soportado");
		}
	}
}
