import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SeguimientosService } from 'app/services/seguimientos/seguimientos.service';
import { UserRoleService } from 'app/services/user/user-role.service';
import * as moment from 'moment';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-seguimiento-form-dialog',
	templateUrl: './seguimiento-form-dialog.component.html',
	styleUrls: ['./seguimiento-form-dialog.component.scss']
})
export class SeguimientoFormDialogComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	idTicket: number;
	idProyecto: number;
	weblord: boolean;
	seguimiento: any;
	devolucion: string;
	loadingSave: boolean;
	formSeguimiento: FormGroup;

	tiposSeguimientos: Array<any>;
	partesTrabajo: Array<any>;
	partesTrabajoSelected: Array<any>;
	cierresPredefinidos: Array<any>;

	dataPermisosSeguimientosPrivados: any;
	dataPermisosColaboradorColPartner: any;
	dataPermisoLaboratorio1: any;
	dataPermisoSeguimientoPrivadoDefecto: any;
	dataPermisoEnviarSMS: any;
	dataPermisoEnviarSegCorreo: any;
	dataPermisoEnviarPartesTrabajo: any;
	
	permisosSeguimientosPrivados: boolean;
	permisosColaboradorColPartner: boolean;
	permisoLaboratorio1: boolean;
	permisoSeguimientoPrivadoDefecto: boolean;
	permisoEnviarSMS: boolean;
	permisoEnviarSegCorreo: boolean;
	permisoEnviarPartesTrabajo: boolean;

	marcadoSMS : boolean;
	marcadoEmail : boolean;

	tipoSegCierreSelected: boolean;

	@ViewChild('selectPartesTrabajo') selectPartesTrabajo;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<SeguimientoFormDialogComponent>,
		private dialog: MatDialog, 
		private formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private seguimientosSvc: SeguimientosService,
		private userRoleSvc: UserRoleService,
	) {
		moment.locale(this.translateService.currentLang);
		this._unsubscribeAll = new Subject();

		this.weblord = false;

		this.formSeguimiento = this.formBuilder.group({
			tracking: [],
			realtime: [0],
			tipo: [],
			private: [false],
			contents: ['', Validators.required],
			sms: [false],
			telefonoCliente: [],
			notificacion: [false],
			email: [false],
			emailColaborador: [''],
			// env_sgsd: [false],
			env_weblord: [false],
			cierres_predefinidos: [''],
			lista_adjuntos: ['']
		});

		this.loadingSave = false;

		this.tiposSeguimientos = [];
		this.partesTrabajo = [];
		this.partesTrabajoSelected = [];
		this.cierresPredefinidos = [];
		
		this.dataPermisosSeguimientosPrivados = { type: "item", name: "SeguimientosPrivados", active: true };
		this.dataPermisosColaboradorColPartner = { 
			type: "group",
			op: "OR",
			items: [
				{ type: "item", name: "Colaborador", active: true },
				{ type: "item", name: "ColPartner", active: true },
			]
		};
		this.dataPermisoLaboratorio1 = { type: "item", name: "Aitana: Perfil laboratorio 1", active: true };
		this.dataPermisoSeguimientoPrivadoDefecto = { type: "item", name: "Aitana:Seguimiento privado por defecto", active: true };
		this.dataPermisoEnviarSMS = { type: "item", name: "EnviarSMS", active: true };
		this.dataPermisoEnviarSegCorreo = { type: "item", name: "EnviarSegCorreo", active: true };
		this.dataPermisoEnviarPartesTrabajo = { type: "item", name: "Aitana:Seguimientos: Enviar Partes Trabajo", active: true };

		this.permisosSeguimientosPrivados = false;
		this.permisosColaboradorColPartner = false;
		this.permisoLaboratorio1 = false;
		this.permisoSeguimientoPrivadoDefecto = false;
		this.permisoEnviarSMS = false;
		this.permisoEnviarSegCorreo = false;
		this.permisoEnviarPartesTrabajo = false;

		this.tipoSegCierreSelected = false;

		this.marcadoSMS = false;
		this.marcadoEmail = false;
	}

	ngOnInit(): void {
		this.idTicket = this.data.idTicket;
		this.idProyecto = this.data.idProyecto;
		this.weblord = (this.data.weblord !== null && this.data.weblord !== undefined) ? this.data.weblord : false;
		this.seguimiento = this.data.seguimiento;
		this.devolucion = this.data.devolucion;

		if (this.idTicket && !this.seguimiento) {
			this.formSeguimiento.get('tracking').setValue(this.idTicket);
		}
		if (this.devolucion && this.devolucion !== "") {
			this.formSeguimiento.get('contents').setValue('ID Diagnosis: ' + this.devolucion);
		}
		if (this.seguimiento) {
			this.formSeguimiento.get('contents').disable();
		}

		this.checkPermisos();
		this.loadTiposSeguimientos();

		this.formSeguimiento.get('tipo').valueChanges.subscribe((value) => {
			if (value == 1) { //Tipo Seguimiento Cierre
				this.tipoSegCierreSelected = true;
				if (this.permisoEnviarSegCorreo && this.permisoEnviarPartesTrabajo && this.partesTrabajo.length == 0) {
					this.loadPartesTrabajo();
				}
				if (this.cierresPredefinidos.length == 0) {
					this.loadCierresPredefinidos();
				}
			}
			else {
				this.tipoSegCierreSelected = false;
			}
		});
		
		this.formSeguimiento.get('cierres_predefinidos').valueChanges.subscribe((value) => {
			if (value && value !== "") { 
				this.formSeguimiento.get('contents').setValue(value);
			}
		});
		
		this.formSeguimiento.get('lista_adjuntos').valueChanges.subscribe((valueSelect) => {
			this.partesTrabajoSelected = [];
			if (valueSelect) {
				for (const id of valueSelect) {
					const parteTrabajo = this.partesTrabajo.find((item) => {
						return item.id === id;
					});
					this.partesTrabajoSelected.push(parteTrabajo);
				}
			}
		});
	}

	changeModeSms(event) {
		
		if(event.checked){
			this.marcadoSMS = true;
		}else{
			this.marcadoSMS = false;
		}
	}

	changeModeEmail(event) {
		
		if(event.checked){
			this.marcadoEmail = true;
		}else{
			this.marcadoEmail = false;
		}
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 1000
		});
	}

	checkPermisos() {
		const promisePermisoSeguimientosPrivados = this.userRoleSvc.checkPermissions([this.dataPermisosSeguimientosPrivados]);
		const promisePermisoColaborador = this.userRoleSvc.checkPermissions([this.dataPermisosColaboradorColPartner]);
		const promiseLaboratorio1 = this.userRoleSvc.checkPermissions([this.dataPermisoLaboratorio1]);
		const promiseSeguimientoPrivado = this.userRoleSvc.checkPermissions([this.dataPermisoSeguimientoPrivadoDefecto]);
		const promiseEnviarSMS = this.userRoleSvc.checkPermissions([this.dataPermisoEnviarSMS]);
		const promiseEnviarSegCorreo = this.userRoleSvc.checkPermissions([this.dataPermisoEnviarSegCorreo]);
		const promiseEnviarPartesTrabajo = this.userRoleSvc.checkPermissions([this.dataPermisoEnviarPartesTrabajo]);

		Promise.all(
			[
				promisePermisoSeguimientosPrivados, 
				promisePermisoColaborador, 
				promiseLaboratorio1, 
				promiseSeguimientoPrivado,
				promiseEnviarSMS,
				promiseEnviarSegCorreo,
				promiseEnviarPartesTrabajo
			]).then(result => {
			this.permisosSeguimientosPrivados = result[0];
			this.permisosColaboradorColPartner = result[1];
			this.permisoLaboratorio1 = result[2];
			this.permisoSeguimientoPrivadoDefecto = result[3];
			this.permisoEnviarSMS = result[4];
			this.permisoEnviarSegCorreo = result[5];
			this.permisoEnviarPartesTrabajo = result[6];

			if (this.permisosSeguimientosPrivados && !this.permisosColaboradorColPartner && !this.permisoLaboratorio1 && this.permisoSeguimientoPrivadoDefecto) {
				this.setValueDataForm('private', true);
			}
		});
	}

	setValueDataForm(formControlName, value, emitEvent?: boolean) {
		let formControl = this.formSeguimiento.get(formControlName);
		if (formControl) {
			if (value !== '' && value !== null) {
				if (emitEvent !== null) {
					formControl.setValue(value, { emitEvent: emitEvent});
				}
				else {
					formControl.setValue(value);
				}
			}
		}
	}

	checkValue(formControlName: string) {
		let formControl = this.formSeguimiento.get(formControlName);
		if (formControl) {
			if (formControlName == 'lista_adjuntos') {
				return formControl.value.length > 0;
			}
			else {
				return formControl.value !== "";
			}
		}
		else {
			return false;
		}
	}

	clearSelect(event, formControlName: string) {
		event.preventDefault();
		event.stopPropagation();

		let formControl = this.formSeguimiento.get(formControlName);
		if (formControl) {
			formControl.setValue('');

			if (formControlName === 'lista_adjuntos') {
				this.partesTrabajoSelected = [];
			}
		}
	}

	deselectParteTrabajo(parteTrabajo: any): void {
		const index = this.partesTrabajoSelected.findIndex((item) => {
			return item.id === parteTrabajo.id;
		});

		if (index >= 0) {
			this.partesTrabajoSelected.splice(index, 1);
		}
		let idPartes = this.partesTrabajoSelected.map((item) => {
			return item.id;
		});
		this.setValueDataForm('lista_adjuntos', idPartes);
	}

	loadTiposSeguimientos() {
		this.seguimientosSvc.getTipos(this.devolucion).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data:any) => {
				this.tiposSeguimientos = data.slice();

				if (this.seguimiento) {
					console.log("entro con seguimiento");
					this.setValueDataForm('tipo', this.seguimiento.tipo);
				}
				else {
					console.log("sin seguimiento", this.tiposSeguimientos.length);
					if (this.tiposSeguimientos.length > 0) {
						console.log("seteo valor", this.tiposSeguimientos[0].id);
						this.setValueDataForm('tipo', this.tiposSeguimientos[0].id);
					}
				}
			}
		)
	}

	loadCierresPredefinidos() {
		this.seguimientosSvc.getCierresPredefinidos(this.idTicket).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data) => {
				this.cierresPredefinidos = data.map((item) => {
					const id = item.texto_cierre.replace(new RegExp('<br>', 'g'), '\n');
					const name = item.texto_cierre.replace(new RegExp('<br>', 'g'), ' ');
					return { id: id, name: name };
				});
			}
		);
	}

	loadPartesTrabajo() {
		this.seguimientosSvc.getPartesTrabajo(this.idTicket).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data) => {
				this.partesTrabajo = data.map((item) => {
					const fecha = item.date_mod ? moment(item.date_mod).format("DD/MM/YYYY HH:mm") : '';
					return { id: item.ID, name: fecha + " - " + item.filename };
				});
			}
		);
	}

	guardarSeguimiento() {
		const formValue = this.formSeguimiento.value;

		if (this.formSeguimiento.valid) {
			this.loadingSave = true;
			console.log("formValue", formValue);

			if (this.seguimiento) {
				this.seguimientosSvc.modificarSeguimiento(this.seguimiento.ID, formValue).pipe(takeUntil(this._unsubscribeAll)).subscribe(
					(data: any) => {
						this.loadingSave = false;
						this.dialogRef.close(data);
					},
					(err) => {
						this.loadingSave = false;
						this.showSnack(err);
					}
				);
			}
			else {
				this.seguimientosSvc.crearSeguimiento(formValue).pipe(takeUntil(this._unsubscribeAll)).subscribe(
					(data: any) => {
						this.loadingSave = false;
						this.dialogRef.close(data);
					},
					(err) => {
						this.loadingSave = false;
						this.showSnack(err);
					}
				);
			}
		}
		else {
			this.showSnack("Debes completar los campos obligatorios");
		}
	}

}
