<form [formGroup]="proyectoForm" (submit)="submit()">
    <h2 mat-dialog-title>Nuevo Proyecto</h2>
    <div mat-dialog-content>
        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="100%">
                <mat-form-field class="form-field">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="nombre" required>
                    <mat-error *ngIf="proyectoForm.controls.nombre.touched && proyectoForm.controls.nombre.invalid">
                        <span *ngIf="proyectoForm.controls.nombre.errors.required">Este campo es obligatorio</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-flat-button [mat-dialog-close]="true" type="button">Cancelar</button>
        <button *ngIf="buttonsEnable" mat-raised-button color="primary" type="submit">Guardar</button>
        <button *ngIf="!buttonsEnable" mat-raised-button color="primary" type="button">Guardar</button>
    </div>            
</form>