import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Validators, FormGroup, FormControl, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';

import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject } from 'rxjs';

import { ClienteService } from 'app/services/cliente/cliente.service';
import { CygPermission } from '@fuse/types';
import { UserService } from 'app/services/user/user.service';
import { UserRoleService } from 'app/services/user/user-role.service';
import { NetsuiteService } from 'app/services/db/netsuite.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-form-cliente',
  templateUrl: './dialog-form-cliente.component.html',
  styleUrls: ['./dialog-form-cliente.component.scss']
})
export class DialogFormClienteComponent implements OnInit, OnDestroy {

  	private _unsubscribeAll: Subject<any>;

	cliente: any;
	loadingSave: boolean;
	showDistribuidores: boolean;
	formCliente: FormGroup;

	tiposClienteData: ReplaySubject<any[]>;
	distribuidoresData: ReplaySubject<any[]>;

	permisoDatosPropios: CygPermission;
	mostrarDatosPropios: boolean = true;

	crearEnNetsuite: boolean = false;

	cargandoClienteNetsuite: boolean = false;

	esTipoParticular: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogFormClienteComponent>,
		private dialog: MatDialog, 
		private formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private clienteSvc: ClienteService,
		private userRoleSvc: UserRoleService,
		private netsuiteService: NetsuiteService,
	) {
		moment.locale(this.translateService.currentLang);
		this._unsubscribeAll = new Subject();

		this.formCliente = this.formBuilder.group({
			tipo_cliente: ['', Validators.required],
			id_cliente_padre: [],
			nombre: ['', Validators.required],
			apellido: [''],
			cif_nif: [''],
			email_contacto: ['', Validators.required],
			tlf_contacto: [''],
			movil_contacto: [''],
			fax_contacto: [''],
			sms: [0],
			whatsapp: [0],
			publicidad: [0],
		});

		this.loadingSave = false;
		this.showDistribuidores = false;

		this.tiposClienteData = new ReplaySubject<any[]>(1);
		this.distribuidoresData = new ReplaySubject<any[]>(1);

		let tipoClienteCtrl = this.formCliente.get('tipo_cliente');
		tipoClienteCtrl.valueChanges.subscribe((idTipoCliente) => {
			if (idTipoCliente === 3) {
				this.showDistribuidores = true;
			}
			else {
				this.showDistribuidores = false;
			}
		});

		this.permisoDatosPropios = { type: "item", name: "Aitana:Ver solo proyectos propios", active: true };
	}

	ngOnInit(): void {
		if (this.data.netsuite) {
			this.crearEnNetsuite = true;
			this.loadTiposCliente();

			this.formCliente = this.formBuilder.group({
				idCliente: [''],
				tipo_cliente: ['', Validators.required],
				nombre: ['', Validators.required],
				apellido: [''],
				cif_nif: ['', Validators.required],
				email_contacto: [''],
				tlf_contacto: [''],
				direccion: [''],
				ciudad: [''],
				provincia: [''],
				zip: ['']
			});

			if (this.data.cliente) {
				this.cargandoClienteNetsuite = true;
				this.loadClienteNetsuite(this.data.cliente.id);
			}

			let tipoClienteCtrl = this.formCliente.get('tipo_cliente');
			tipoClienteCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((idTipoCliente) => {
				if(idTipoCliente == 'PARTICULAR') {
					this.formCliente.get('apellido').setValidators(Validators.required);
					this.esTipoParticular = true;
				}
				else {
					this.formCliente.get('apellido').setValidators(null);
					this.esTipoParticular = false;
				}
			});
		}
		else {
			this.loadTiposCliente();
			this.loadDistribuidores();

			if (this.data.cliente) {
				this.cliente = this.data.cliente;
				this.setValueDataForm('nombre', this.cliente.nombre);
				this.setValueDataForm('apellido', this.cliente.apellido);
				this.setValueDataForm('cif_nif', this.cliente.cif_nif);
				this.setValueDataForm('email_contacto', this.cliente.email_contacto);
				this.setValueDataForm('tlf_contacto', this.cliente.tlf_contacto);
				this.setValueDataForm('movil_contacto', this.cliente.movil_contacto);
				this.setValueDataForm('fax_contacto', this.cliente.fax_contacto);
				this.setValueDataForm('sms', this.cliente.sms);
				this.setValueDataForm('whatsapp', this.cliente.whatsapp);
				this.setValueDataForm('publicidad', this.cliente.publicidad);
			}
		}

		this.userRoleSvc.checkPermissions([this.permisoDatosPropios])
		.then((permiso:boolean) => {
			this.mostrarDatosPropios = permiso;
		});

	}

	setValueDataForm(formControlName, value) {
		let formControl = this.formCliente.get(formControlName);
		if (formControl) {
			if (value !== '' && value !== 0 && value !== null) {
				formControl.setValue(value);
			}
		}
	}

	loadTiposCliente() {
		if(this.crearEnNetsuite) {
			this.tiposClienteData.next([{id:'PARTICULAR', name:'PARTICULAR'}, {id:'EMPRESA', name:'EMPRESA'}]);
		}
		else {
			this.clienteSvc.getTiposCliente().subscribe(
				(data:any) => {
					this.tiposClienteData.next(data.slice());

					if (this.cliente) {
						this.setValueDataForm('tipo_cliente', this.cliente.tipo_cliente);
					}
				}
			)
		}
	}
	loadDistribuidores() {
		this.clienteSvc.getDistribuidores().subscribe(
			(data:any) => {
				const dadaDistribuidores = data.map(item => {
					return { id: item.ID, name: item.nombre };
				});
				this.distribuidoresData.next(dadaDistribuidores.slice());

				if (this.cliente) {
					this.setValueDataForm('id_cliente_padre', this.cliente.id_cliente_padre);
				}
			}
		)
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 4000
		});
	}

	checkValue(formControlName: string) {
		let formControl = this.formCliente.get(formControlName);
		if (formControl) {
			return formControl.value !== "";
		}
		else {
			return false;
		}
	}

	clearSelect(event, formControlName: string) {
		event.preventDefault();
		event.stopPropagation();

		let formControl = this.formCliente.get(formControlName);
		if (formControl) {
			formControl.setValue('');
		}
	}

	guardarCliente() {
		const formValue = this.formCliente.value;
		this.loadingSave = true;
		if(this.crearEnNetsuite) {
			if(!formValue.nombre || !formValue.cif_nif || !formValue.direccion || !formValue.ciudad || !formValue.zip) {
				this.loadingSave = false;
				this.showSnack("Hay datos sin informar en el formulario");
			}
			else {
				this.netsuiteService.nuevoCliente(formValue).subscribe(
					(data:any) => {
						this.loadingSave = false;
						this.dialogRef.close(data);
					},
					(err) => {
						this.loadingSave = false;
						this.showSnack(err);
					}
				);
			}
		}
		else {
			if (this.cliente) {
				this.clienteSvc.modificarCliente(this.cliente.ID, formValue).subscribe(
					(data:any) => {
						this.loadingSave = false;
						this.dialogRef.close(data);
					},
					(err) => {
						this.loadingSave = false;
						this.showSnack(err);
					}
				);
			}
			else {
				this.clienteSvc.crearCliente(formValue).subscribe(
					(data:any) => {
						this.loadingSave = false;
						this.dialogRef.close(data);
					},
					(err) => {
						this.loadingSave = false;
						this.showSnack(err);
					}
				);
			}
		}
	}

	loadClienteNetsuite(idCliente) {
		this.netsuiteService.getDatosCliente(idCliente).subscribe(
			(data:any) => {
				this.cliente = data;
				this.setValueDataForm('idCliente', this.cliente.idCliente);
				this.setValueDataForm('tipo_cliente', this.cliente.tipo_cliente);
				this.setValueDataForm('nombre', this.cliente.nombre);
				this.setValueDataForm('apellido', this.cliente.apellidos);
				this.setValueDataForm('cif_nif', this.cliente.cif_nif);
				this.setValueDataForm('email_contacto', this.cliente.email);
				this.setValueDataForm('tlf_contacto', this.cliente.tlf_contacto);
				this.setValueDataForm('direccion', this.cliente.direccion);
				this.setValueDataForm('ciudad', this.cliente.ciudad);
				this.setValueDataForm('provincia', this.cliente.provincia);
				this.setValueDataForm('zip', this.cliente.zip);
				this.cargandoClienteNetsuite = false;
			},
			(err) => {
				this.showSnack(err);
			}
		);		
	}
}
