import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { GridConfigService } from 'app/services/grid/grid-config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-dialog-guardar-filtros-grid',
  templateUrl: './dialog-guardar-filtros-grid.component.html',
  styleUrls: ['./dialog-guardar-filtros-grid.component.scss']
})
export class DialogGuardarFiltrosGridComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	acciones: any = {
		guardarFiltro: 'guardar-filtro',
		misFiltros: 'consultar-filtros'
	};

	filtros: Array<any>;
	filtrosAplicados: Array<any>;
	idUsuario: number;
	gridName: string;
	titulo: string;
	accion: string;

	formFiltro: FormGroup;
	filtrosUser: Array<any>;
	filterSelected: any;
	configFiltroSelected: Array<any>;

	displayedColumns: string[] = ['select', 'nombreFiltro', 'actions'];
	dataSource: MatTableDataSource<any>;
	selection: SelectionModel<any>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogGuardarFiltrosGridComponent>,
		private formBuilder: FormBuilder,
		private dialog: MatDialog, 
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private gridConfigSvc: GridConfigService,
	) {
		this._unsubscribeAll = new Subject();
		this.titulo = '';
		this.filtrosUser = [];
		this.configFiltroSelected = [];

		this.formFiltro = this.formBuilder.group({
			nombreFiltro: ['', Validators.required],
		});

		this.dataSource = new MatTableDataSource();
		this.selection = new SelectionModel(false, []);
		this.selection.changed.pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				// console.log("item selected", this.selection.selected);
				if (this.selection.selected.length > 0) {
					this.filterSelected = this.selection.selected[0];
					this.formFiltro.get('nombreFiltro').setValue(this.filterSelected.name);
					this.getConfigFilter();
				}
				else {
					this.filterSelected = null;
					this.configFiltroSelected = [];
				}
			}
		)
	}

	ngOnInit(): void {
		if (this.data.grid) {
			this.gridName = this.data.grid;
			this.idUsuario = this.data.idUser;
			this.filtrosAplicados = this.data.filtrosAplicados;
			this.filtros = this.data.filtros;
			this.accion = this.data.accion;

			if (this.data.accion == this.acciones.guardarFiltro) {
				this.titulo = this.translateService.instant('COMUN.GUARDAR_FILTRO');
			}
			else if (this.data.accion == this.acciones.misFiltros) {
				this.titulo = this.translateService.instant('COMUN.MIS_FILTROS');
			}
			this.loadMisFiltros();
		}
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}

	getConfigFilter() {
		this.configFiltroSelected = [];
		const valueFilterSelected = JSON.parse(this.filterSelected.data);
		// console.log("filtros", this.filtros);
		// console.log("valueFilterSelected", valueFilterSelected);
		for (const nameFilter in valueFilterSelected) {
			const value = valueFilterSelected[nameFilter];
			// console.log("value", value, nameFilter);
			let dataFiltro = this.filtros.find((item) => { return item.name == nameFilter; });
			// console.log("dataFiltro", dataFiltro);
			if (dataFiltro) {
				// console.log("asigno valor");
				dataFiltro.value = value;
				this.configFiltroSelected.push(dataFiltro);
			}
			// if (Object.prototype.hasOwnProperty.call(valueFilterSelected, nameFilter)) {
			// }
		}
		console.log("configFiltroSelecte", this.configFiltroSelected);
	}

	loadMisFiltros() {
		this.gridConfigSvc.getFiltrosGrid(this.gridName).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data:any) => {
				this.filtrosUser = data;
				this.dataSource.data = this.filtrosUser;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	openConfirmDelete(event, filtro: any): void {
		event.stopPropagation();
		
		const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: { 
				titulo: this.translateService.instant('COMUN.ELIMINAR_FILTRO'), 
				mensaje: this.translateService.instant('COMUN.ELIMINAR_FILTRO_MSG', {filterName: filtro.name}),
				btnLabelOk: this.translateService.instant('COMUN.SI'),
				btnLabelCancel: this.translateService.instant('COMUN.NO')
			},
			panelClass: 'panel-aitana',
			disableClose: true,
		});

		dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
			if (result === true) {
				this.deleteFiltro(filtro);
			}
			else {
				console.log("Eliminacion cancelada");
			}
		});
	}

	deleteFiltro(filtro: any): void {
		this.gridConfigSvc.borrarFiltro(filtro.id).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			(data: any) => {
				this.selection.clear();
				this.loadMisFiltros();
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	guardarDatos() {
		const formValue = this.formFiltro.value;

		if (this.formFiltro.valid) {
			let idFiltro = this.filterSelected ? this.filterSelected.id : null;
			
			this.gridConfigSvc.guardarFiltro(this.gridName, formValue.nombreFiltro, this.filtrosAplicados, idFiltro).pipe(takeUntil(this._unsubscribeAll)).subscribe(
				(data: any) => {
					this.showSnack(this.translateService.instant('COMUN.FILTRO_GUARDADO'));
					this.dialogRef.close({ res: true });
				},
				(err) => {
					this.showSnack(err);
				}
			);
		}
		else {
			this.showSnack(this.translateService.instant('COMUN.FILTRO_GUARDADO_ERROR_NOMBRE'));
		}
	}

	aplicarFiltro() {
		this.dialogRef.close({ filtros: this.configFiltroSelected });
	}

}
