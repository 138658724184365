<div class="logindiv">
  <div class="login-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <!-- <div class="w-100-p logo">
      <img src="assets/images/logos/aitana-ewaste.png">
    </div> -->

    <!-- LOGIN -->
    <mat-card *ngIf="!showRemember" class="box">
      <mat-card-header class="mb-12">
        <!-- <mat-card-title>Login</mat-card-title> -->
        <div fxLayout="column" fxLayoutAlign="center center">
          <div *ngIf="entorno === 'ewaste'" class="w-100-p logo">
            <img src="assets/images/ewaste/aitana-ewaste.png">
          </div>
          <div *ngIf="entorno === 'circpass'" class="w-100-p logo">
            <img src="assets/images/circpass/aitana-circpass.png">
          </div>
          <h3 class="m-0 font-size-30">Login</h3>
        </div>
      </mat-card-header>

      <form [formGroup]="loginForm" (ngSubmit)="onLogin()" class="example-form">
        <mat-card-content>
          <mat-form-field class="example-full-width">
            <input type="text" formControlName="username" matInput placeholder="Usuario" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input type="password" formControlName="password" matInput placeholder="Contraseña" required>
          </mat-form-field>
        </mat-card-content>
        <button type="submit" mat-raised-button color="accent" [disabled]="loadingBtn" class="btn-block">
          <span *ngIf="!loadingBtn">Acceder</span>
          <span *ngIf="loadingBtn">Comprobando datos ...</span>
        </button>
      </form>

      <p align="center">
        <button mat-mini-fab color="warn" aria-label="Example icon button with a filter list icon"
          (click)="loginGoogle()">
          <b>G</b>
        </button>
        <button mat-mini-fab color="" style="margin-left:15px;" aria-label="Example icon button with a filter list icon"
          (click)="loginOutlook()">
          <img src="assets/icons/icon-microsoft.png">
        </button>
      </p>
      <div>
        <button mat-stroked-button color="accent" class="btn-block" (click)="showRemember=true">
          <span>Recordar contraseña</span>
        </button>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="h-10" style="position: relative;">
        <mat-error *ngIf="msgErrorLogin && msgErrorLogin !== ''" style="position: absolute; top: 2px">{{msgErrorLogin}}</mat-error>
        <p *ngIf="msgSuccessRemember && msgSuccessRemember !== ''" class="success">{{msgSuccessRemember}}</p>
      </div>
    </mat-card>

    <!-- PASSWORD -->
    <mat-card *ngIf="showRemember" class="box">
      <mat-card-header class="mb-12">
        <mat-card-title>Recordar contraseña</mat-card-title>
      </mat-card-header>

      <form [formGroup]="rememberForm" (ngSubmit)="onRemember()" class="example-form">
        <mat-card-content>
          <p>Escribe tu correo electrónico y se te enviaran las instrucciones para reestablecer tu contraseña.</p>
          <mat-form-field class="example-full-width">
            <input type="text" formControlName="email" matInput placeholder="Email" required>
          </mat-form-field>
        </mat-card-content>

        <button type="submit" mat-raised-button color="accent" class="btn-block">
          <span>Recordar contraseña</span>
        </button>
        <button mat-stroked-button color="accent" class="btn-block mt-12" (click)="showRemember=false">
          <span>Cancelar</span>
        </button>

      </form>

      <div fxLayout="row" fxLayoutAlign="center center" class="h-10" style="position: relative;">
        <mat-error *ngIf="msgErrorRemember && msgErrorRemember !== ''" style="position: absolute; top: 2px">{{msgErrorRemember}}
        </mat-error>
      </div>
    </mat-card>

  </div>
</div>