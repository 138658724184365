<form [formGroup]="colaboradorForm" (submit)="submit()">
    <h2 mat-dialog-title>{{ this.isNewColaborador ? 'Nuevo Colaborador' : 'Editar Colaborador' }}</h2>
    <div mat-dialog-content>

        <mat-error *ngIf="error">
            <span>{{ error }}</span>
        </mat-error>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">

            <div fxFlex="50%">
                <mat-form-field class="form-field">
                    <mat-label>Estado colaborador</mat-label>
                    <mat-select formControlName="estado_colaborador">
                        <mat-option *ngFor="let item of estadosColaborador" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="50%">
                <mat-form-field class="form-field">
                    <mat-label>Tipo sociedad</mat-label>
                    <mat-select formControlName="tipo_sociedad">
                        <mat-option *ngFor="let item of tiposSociedad" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="33%">
                <mat-form-field class="form-field">
                    <mat-label>Nombre y apellidos</mat-label>
                    <input matInput formControlName="nombre_apellidos">
                </mat-form-field>
            </div>
            <div fxFlex="33%">
                <mat-form-field class="form-field">
                    <mat-label>Razon Social</mat-label>
                    <input matInput formControlName="razon_social">
                </mat-form-field>
            </div>
            <div fxFlex="33%">
                <mat-form-field class="form-field">
                    <mat-label>CIF / NIF</mat-label>
                    <input matInput formControlName="cif_nif">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="33%">
                <mat-form-field class="form-field">
                    <mat-label>Teléfono</mat-label>
                    <input matInput formControlName="telefono">
                </mat-form-field>
            </div>
            <div fxFlex="33%">
                <mat-form-field class="form-field">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="mail">
                </mat-form-field>
            </div>
            <div fxFlex="33%">
                <mat-form-field class="form-field">
                    <mat-label>Email facturación</mat-label>
                    <input matInput formControlName="email_facturacion">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left center" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="50%">
                <mat-form-field class="form-field">
                    <mat-label>Número empleados</mat-label>
                    <mat-select formControlName="num_empleados">
                        <mat-option *ngFor="let item of numeroEmpleados" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="50%">
                <mat-slide-toggle ngDefaultControl formControlName="realiza_onsite">Realiza OnSite</mat-slide-toggle>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="100%">
                <mat-form-field class="form-field">
                    <mat-label>Comentarios</mat-label>
                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" formControlName="comentarios"></textarea>
                </mat-form-field>                  
            </div>
        </div>

    </div>

    <div mat-dialog-actions align="end">
        <button mat-flat-button [mat-dialog-close]="true" type="button">Cancelar</button>
        <button mat-raised-button color="primary" [class.spinner]="loadingSave" [disabled]="disableBtnSave" type="submit">Guardar</button>
    </div>

</form>