import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Validators, FormGroup, FormControl, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';

import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject } from 'rxjs';

import { ClienteService } from 'app/services/cliente/cliente.service';

@Component({
  selector: 'app-dialog-form-direccion-cliente',
  templateUrl: './dialog-form-direccion-cliente.component.html',
  styleUrls: ['./dialog-form-direccion-cliente.component.scss']
})
export class DialogFormDireccionClienteComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	idTicket: number;
	cliente: any;
	direccion: any;
	loadingSave: boolean;

	formDireccion: FormGroup;
	tiposDireccionData: ReplaySubject<any[]>;
	paises: Array<any>;
	paisFilterCtrl: FormControl;
	paisesFiltered: ReplaySubject<any[]>;
	provincias: Array<any>;
	provinciaFilterCtrl: FormControl;
	provinciasFiltered: ReplaySubject<any[]>;
	localidades: Array<any>;
	searchingLocalidades: boolean = false;
	localidadFilterCtrl: FormControl;
	localidadSelected: any;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogFormDireccionClienteComponent>,
		private dialog: MatDialog, 
		private formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private clienteSvc: ClienteService,
	) {
		moment.locale(this.translateService.currentLang);
		this._unsubscribeAll = new Subject();
	
		this.formDireccion = this.formBuilder.group({
			tipo_direccion: ['', Validators.required],
			nombre: ['', Validators.required],
			apellido: [''],
			num_telefono: [],
			id_pais: ['', Validators.required],
			id_provincia: ['', Validators.required],
			id_localidad: ['', Validators.required],
			direccion: ['', Validators.required],
			num_casa: [],
			otros_datos: [''],
			direccion_email: [''],
		});
	
		this.loadingSave = false;

		this.tiposDireccionData = new ReplaySubject<any[]>(1);
		this.paises = [];
		this.paisFilterCtrl = new FormControl();
		this.paisesFiltered = new ReplaySubject<any[]>(1);
		this.provincias = [];
		this.provinciaFilterCtrl = new FormControl();
		this.provinciasFiltered = new ReplaySubject<any[]>(1);
		this.localidades = [];
		this.localidadFilterCtrl = new FormControl();

		let paisCtrl = this.formDireccion.get('id_pais');
		let provinciaCtrl = this.formDireccion.get('id_provincia');
		let localidadCtrl = this.formDireccion.get('id_localidad');

		paisCtrl.valueChanges.subscribe((idPais) => {
			this.loadProvincias();
		});
		
		provinciaCtrl.valueChanges.subscribe((idProvincia) => {
			localidadCtrl.setValue("");
			this.localidadSelected = null;
			this.localidades = [];
		});

		localidadCtrl.valueChanges.subscribe((idLocalidad) => {
			const localidad = this.localidades.find((item) => {
				return item.id === idLocalidad;
			});
			this.localidadSelected = localidad;
		});
	}

	ngOnInit(): void {
		this.idTicket = this.data.idTicket;
		this.cliente = this.data.cliente;

		if (this.data.direccion) {
			this.direccion = this.data.direccion;
			console.log("direccion", this.direccion);

			this.loadTiposDireccion();
			this.loadPaises();
			this.loadProvincias();

			this.setValueDataForm('nombre', this.direccion.nombre);
			this.setValueDataForm('apellido', this.direccion.apellido);
			this.setValueDataForm('num_telefono', this.direccion.num_telefono);
			this.setValueDataForm('direccion', this.direccion.direccion);
			this.setValueDataForm('num_casa', this.direccion.num_casa);
			this.setValueDataForm('otros_datos', this.direccion.otros_datos);
			this.setValueDataForm('direccion_email', this.direccion.direccion_email);
			this.setValueLocalidad('id_localidad', this.direccion.id_localidad);
		}
		else {
			if (this.cliente) {
				this.setValueDataForm('nombre', this.cliente.nombre);
				this.setValueDataForm('apellido', this.cliente.apellido);
				this.setValueDataForm('direccion_email', this.cliente.email_contacto);
			}

			this.loadTiposDireccion();
			this.loadPaises();
			this.loadProvincias();
		}

		this.paisFilterCtrl.valueChanges.subscribe((filterValue) => {
			if (!filterValue) {
				this.paisesFiltered.next(this.paises.slice());
				return;
			}

			this.paisesFiltered.next(
				this.paises.filter(item => {
					return item.name.toLowerCase().includes(filterValue.toLowerCase());
				})
			);
		});
		this.provinciaFilterCtrl.valueChanges.subscribe((filterValue) => {
			if (!filterValue) {
				this.provinciasFiltered.next(this.provincias.slice());
				return;
			}

			this.provinciasFiltered.next(
				this.provincias.filter(item => {
					return item.name.toLowerCase().includes(filterValue.toLowerCase());
				})
			);
		});
		this.localidadFilterCtrl.valueChanges.subscribe((filterValue) => {
			if (filterValue.length > 0) {
				this.searchingLocalidades = true;
				this.loadLocalidades(filterValue);
			}
			else {
				this.localidades = [];
				if (this.localidadSelected) {
					this.localidades.push(this.localidadSelected);
				}
			}
		});
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 1000
		});
	}

	loadTiposDireccion() {
		this.clienteSvc.getTiposDireccion().subscribe(
			(data:any) => {
				this.tiposDireccionData.next(data.slice());

				if (this.direccion) {
					this.setValueDataForm('tipo_direccion', this.direccion.tipo_direccion);
				}
			}
		)
	}

	loadPaises() {
		this.clienteSvc.getPaises().subscribe(
			(data: any) => {
				this.paises = data.map(item => {
					return { id: item.id, name: item.pais };
				});
				this.paisesFiltered.next(this.paises.slice());

				if (this.direccion) {
					this.setValueDataForm('id_pais', this.direccion.id_pais);
				}
			},
			(err) => {
				this.showSnack(err);
			}
		);
	}
	loadProvincias() {
		let formControl = this.formDireccion.get('id_pais');

		this.clienteSvc.getProvincias(formControl.value).subscribe(
			(data: any) => {
				this.provincias = data.map(item => {
					return { id: item.idprovincia, name: item.provincia };
				});
				this.provinciasFiltered.next(this.provincias.slice());

				if (this.direccion) {
					this.setValueDataForm('id_provincia', this.direccion.id_provincia, false);
				}
			},
			(err) => {
				this.showSnack(err);
			}
		);
	}
	loadLocalidades(filter?: string): void {
		let formControl = this.formDireccion.get('id_provincia');

		this.clienteSvc.getLocalidades(filter, formControl.value).subscribe(
			(data:any) => {
				this.searchingLocalidades = false;
				this.localidades = data.map(item => {
					return { id: item.idpoblacion, name: item.postal + ' - ' + item.poblacion };
				});
			},
			(err) => {
				this.searchingLocalidades = false;
				this.showSnack(err);
			}
		);
	}

	checkValue(formControlName: string) {
		let formControl = this.formDireccion.get(formControlName);
		if (formControl) {
			return formControl.value !== "";
		}
		else {
			return false;
		}
	}

	clearSelect(event, formControlName: string) {
		event.preventDefault();
		event.stopPropagation();

		let formControl = this.formDireccion.get(formControlName);
		if (formControl) {
			formControl.setValue('');

			if (formControlName === 'id_localidad') {
				this.localidadSelected = null;
				this.localidades = [];
			}
		}
	}

	setValueDataForm(formControlName, value, emitEvent?: boolean) {
		let formControl = this.formDireccion.get(formControlName);
		if (formControl) {
			if (value !== '' && value !== 0 && value !== null) {
				if (emitEvent !== null) {
					formControl.setValue(value, { emitEvent: emitEvent});
				}
				else {
					formControl.setValue(value);
				}
			}
		}
	}
	setValueLocalidad(formControlName, value) {
		if (this.direccion.localidad) {
			const dataLocalidad = {
				id: this.direccion.localidad.idpoblacion,
				name: this.direccion.localidad.postal + " - " + this.direccion.localidad.poblacion,
				postal: this.direccion.localidad.postal,
				poblacion: this.direccion.localidad.poblacion
			}

			const localidad = this.localidades.find((item) => {
				item.id === value;
			});
			if (!localidad) {
				this.localidades.push(dataLocalidad);
			}
			this.setValueDataForm(formControlName, value);
		}
	}

	guardarDireccion() {
		let formValue = this.formDireccion.value;
		formValue['id_rel_tracking_cliente'] = 0;
		formValue['id_tracking'] = this.idTicket;
		formValue['id_cliente_solicitante'] = this.cliente.ID;

        if(this.idTicket){
            if (this.direccion) {
                this.loadingSave = true;
                this.clienteSvc.modificarDireccion(this.direccion.ID, formValue).subscribe(
                    (data:any) => {
                        this.loadingSave = false;
                        this.dialogRef.close(data);
                    },
                    (err) => {
                        this.loadingSave = false;
                        this.showSnack(err);
                    }
                );
            }
            else {
                this.clienteSvc.crearDireccion(formValue).subscribe(
                    (data:any) => {
                        this.loadingSave = false;
                        this.dialogRef.close(data);
                    },
                    (err) => {
                        this.loadingSave = false;
                        this.showSnack(err);
                    }
                );
            }
        }else{
            if (this.direccion) {
                this.loadingSave = true;
                this.clienteSvc.modificarDireccionCliente(this.direccion.ID, formValue).subscribe(
                    (data:any) => {
                        this.loadingSave = false;
                        this.dialogRef.close(data);
                    },
                    (err) => {
                        this.loadingSave = false;
                        this.showSnack(err);
                    }
                );
            }
            else {
                this.clienteSvc.crearDireccionCliente(formValue).subscribe(
                    (data:any) => {
                        this.loadingSave = false;
                        this.dialogRef.close(data);
                    },
                    (err) => {
                        this.loadingSave = false;
                        this.showSnack(err);
                    }
                );
            }
        }
	}

}
