<div class="contenioDialog">
    <div mat-dialog-title fxLayout="column" style="position: relative;">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
            <div style="display: flex; flex-direction: column; gap: 1em; justify-content: flex-start;">
                <img *ngIf="contrato && contrato.logoContrato" [src]="'https://new.aitana.cygitsolutions.com/rest/contratos?id='+contrato.id" style="display: block;
                        max-width:230px;
                        max-height:95px;
                        width: auto;
                        height: auto;">
            
                <h2 class="m-0 mb-8"> <!-- Formulario campos proyecto -->
                    <br>
                    <!-- <h4>{{ticket && ticket.deviceModel?ticket.deviceModel.name+' - ':''}}{{numserie}}</h4> -->
                    <h4>{{fechaActual}}</h4>
                    <h4 *ngIf="numserie && numserie!=''">{{numserie}}</h4>
                    <h4> <span *ngIf="modelo && modelo!=''"><b>Modelo: </b>{{modelo}}</span> {{"    "}} <span *ngIf="imei && imei!=''"><b>IMEI: </b>{{imei}}</span></h4>
                    <h4> <span *ngIf="incidencia && incidencia!=''"><b>Incidencia: </b>{{incidencia}}</span></h4>
                    <h4> <span *ngIf="delegacion && delegacion!=''"><b>Delegación: </b>{{delegacion}}</span></h4>
                    <h4 *ngIf="usuario && usuario!=''"><b>Usuario: </b>{{usuario}}</h4>
                    <h4 *ngIf="historicoEstados.length > 0"><b>Historico estados: </b><span *ngFor="let estado of historicoEstados; let i = index;">{{estado}}{{(i < historicoEstados.length-1)?' / ':''}}</span></h4>
                    <!-- <h5 *ngIf="historicoEstados.length > 0" style="margin: 0px;"></h5> -->
                </h2>
            </div>
            <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <nav mat-tab-nav-bar *ngIf="ticket && ticket.proyecto && ticket.proyecto.contrato && ticket.proyecto.contrato.formulario_por_estado == 1">
            <a *ngFor="let estado of estadosInternos" mat-tab-link (click)="loadFormularioEstados(ticket, estado.id)" [active]="estadoSelected == estado.id"> {{estado.name}} </a>
        </nav>
    </div>
    <mat-dialog-content class="mat-typography">
        <div fxLayout="column" class="w-100-p" style="max-height: 280px; overflow: hidden; overflow-y: auto;">
            <div fxLayout="column" style="width: 100%" *ngIf="campos && !formFinalUnei">
                <form *ngFor="let reg of campos" #mainForm="ngForm" [formGroup]="formCampos" class="filtros">
                    <div>
                        <h2>{{reg.nombre_grupo}}<br><h5 *ngIf="reg.usuario_respuesta">Por {{reg.usuario_respuesta.displayName}}</h5></h2>
                        <hr>
                    </div>
                    <div fxLayout="row wrap" style="column-gap: 2em;">
                        <ng-container *ngFor="let campo of reg.camposContrato">
    
                            <ng-container *ngTemplateOutlet="fields_template; context: {campo : campo, form: formCampos}"></ng-container>
    
                        </ng-container>
                    </div>
                </form>
            </div>
    
            <!-- Motivos estado -->
            <ng-container *ngIf="motivosEstado.length > 0">
                <h2>Motivos estado</h2>
    
                <ng-container *ngFor="let motivo of motivosEstado">
                    <p *ngIf="motivo.campo">{{motivo.campo.label_motivo && motivo.campo.label_motivo != ""?motivo.campo.label_motivo:motivo.value}}</p>
                </ng-container>
                <br>
            </ng-container>
    
            <!-- Campos formulario estado -->
            <div fxLayout="column" style="width: 100%" *ngIf="campos && formFinalUnei">
                <form *ngFor="let reg of campos" #mainForm="ngForm" [formGroup]="formCampos" class="filtros"
                    style="display:flex; flex-direction: row; flex-wrap: wrap; gap: 2em; width: 100%;">
                    <div style="width: 100%;">
                        <h2>{{reg.nombre_grupo}}</h2>
                        <hr>
                    </div>
                    
                    <ng-container *ngFor="let campo of reg.camposContrato">
                        <!-- <mat-form-field *ngIf="((!campo.tipoCampo) || campo.tipoCampo.type_field == 'text') && campo.name == 'pn'" appearance="standard">
                            <mat-label>{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-label>
                            <input matInput [name]="campo.name" [formControlName]="campo.name" class="form-control"
                                autocomplete="off" (change)="pnChanged()">
                        </mat-form-field> -->
        
                        <!-- <mat-form-field *ngIf="campo.tipoCampo.type_field == 'select' && campo.name == 'fk_caja' && formCampos.get('pn').valid && formCampos.get('pn').value.trim() !=''" appearance="standard"> -->
                        <mat-form-field *ngIf="campo.tipoCampo.type_field == 'select' && campo.name == 'fk_caja'" class="select-caja" appearance="standard">
                            <mat-label>{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-label>
                            <mat-select [formControlName]="campo.name" class="form-control" (selectionChange)="cajaSelected()">
                                <mat-option *ngFor="let option of cajas" [value]="option.value.toString()">{{option.texto}}
                                </mat-option>
                            </mat-select>
                            <button *ngIf="!campo.required" class="btn-close" mat-button matSuffix mat-icon-button
                                (click)="clearValue(campo.name, $event)">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
        
                        <!--<div *ngIf="crearGestionPermitido && campo.tipoCampo.type_field == 'select' && campo.name == 'fk_caja' && formCampos.get('pn').valid && formCampos.get('pn').value.trim() !=''" style="display: flex; flex-direction: column; gap: 10px;" (click)="abrirCajas()">
                            <mat-label>&nbsp;</mat-label>
                            <button mat-button color="primary" style="background-color: green; color:white;">Crear caja <mat-icon>queue</mat-icon></button>
                        </div>-->
        
                        <!-- <div *ngIf="crearGestionPermitido && campo.tipoCampo.type_field == 'select' && campo.name == 'fk_caja'" style="display: flex; flex-direction: column; gap: 10px;" (click)="abrirCajas()">
                            <mat-label>&nbsp;</mat-label>
                            <button mat-button color="primary" style="background-color: green; color:white;">Crear caja <mat-icon>queue</mat-icon></button>
                        </div> -->
    
                        <div *ngIf="campo.tipoCampo.type_field == 'select' && campo.name == 'fk_caja'" style="display: flex; flex-direction: column; gap: 10px;" (click)="abrirCajas()">
                            <mat-label>&nbsp;</mat-label>
                            <button mat-button color="primary" style="background-color: green; color:white;">Crear caja <mat-icon>queue</mat-icon></button>
                        </div>

                        <div *ngIf="cajaLlena == true && campo.tipoCampo.type_field == 'select' && campo.name == 'fk_caja'" style="display: flex; flex-direction: column; gap: 10px;" (click)="descargarDocCaja()">
                            <mat-label>&nbsp;</mat-label>
                            <button mat-button color="primary" *ngIf="cajaLlena == true">Generar documento <mat-icon>description</mat-icon></button>
                        </div>

                        <ng-container *ngIf="!campo.name.includes('fk_caja_reutilizable') || (cajaLlena == false && campo.name.includes('fk_caja_reutilizable') && ticketRevisado)">
                            <ng-container *ngTemplateOutlet="fields_template; context: {campo : campo, form: formCampos}"></ng-container>
                        </ng-container>
                    </ng-container>
                </form>
            </div>
    
            <div fxLayout="column" style="width: 100%" *ngIf="sinCampos">
                <h2 *ngIf="contrato && contrato.formulario_por_estado == 1">No se encontraron campos configurados para este estado</h2>
                <h2 *ngIf="!(contrato && contrato.formulario_por_estado == 1)">No se encontraron campos configurados</h2>
            </div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button class="btn-aitana" (click)="guardarDatos()" *ngIf="!estadoSelected || (estadoSelected && estadoSelected == ticket.estadoInterno.ID)"
            [disabled]="guardando || (formCampos && !formCampos.valid)">
            {{ 'COMUN.APLICAR' | translate }}
        </button>
        <button mat-button mat-stroked-button mat-dialog-close>{{ 'COMUN.CANCELAR' | translate }}</button>
    </mat-dialog-actions>
</div>

<!-- MODAL DOBLE VERIFICACION -->
<ng-template #modalValidacion>
    <h1 mat-dialog-title>Por favor, confirme esta información</h1>
    <div mat-dialog-content>
        <!-- <form #mainForm="ngForm" [formGroup]="formCamposVerificacion" class="filtros"> -->
            <ng-container *ngFor="let campo of mostrarEnVerificacion">
                <!-- <ng-container *ngTemplateOutlet="fields_template; context: {campo : campo.campo, form: formCampos, duplicado: true}"></ng-container> -->
                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>{{campo.label}}</mat-label>
                    <mat-select class="form-control" value="1" (selectionChange)="verificacionChange(campo, $event)">
                        <mat-option value="1">Sí</mat-option>
                        <mat-option value="0">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
        <!-- </form> -->
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button class="btn-aitana" (click)="cerrarVerificacion()">
            Confirmar
        </button>
    </div>
</ng-template>

<ng-template #fields_template let-campo="campo" let-form="form" let-field_parent="field_parent">
    <ng-container *ngIf="campo.tipoCampo.type_field == 'relation_many_to_one' || campo.tipoCampo.type_field == 'relation_one_to_many'">
        <div fxLayout="column" class="container-relation">
            <div class="relation-header">
                <h3>{{campo.label}}</h3>
            </div>
            <div *ngIf="campo.respuestas" class="container-elements">
                <mat-card *ngFor="let respuesta of campo.respuestas" class="card-element">
                    <mat-card-content>
                        <div fxLayout="row wrap" style="column-gap: 2em;">
                            <ng-container *ngFor="let field_child of respuesta.fields_child">

                                <ng-container *ngTemplateOutlet="fields_template; context: {campo : field_child, form: formCampos, field_parent : respuesta, form: null}"></ng-container>

                            </ng-container>
                        </div>
                    </mat-card-content>

                    <button (click)="removeElement(campo, respuesta)" mat-icon-button aria-label="Eliminar elemento" class="delete-button">
                        <mat-icon>close</mat-icon>
                    </button>

                </mat-card>
                <button (click)="addElement(campo)" mat-mini-fab aria-label="Añadir nuevo elemento" class="add-button">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div *ngIf="!campo.respuestas" class="container-element" fxLayout="row wrap" style="column-gap: 2em;">
                <ng-container *ngFor="let field_child of campo.fields_child">

                    <ng-container *ngTemplateOutlet="fields_template; context: {campo : field_child, form: formCampos}"></ng-container>

                </ng-container>
            </div>
            <div class="relation-footer" *ngIf="campo.summaryFieldsRelations">
                <div fxLayout="row" dir="rtl">
                    <div *ngFor="let summaryField of campo.summaryFieldsRelations" fxLayout="column" fxLayoutAlign="center center" class="summary-column" style="padding: 12px;" dir="ltr">
                        <span class="text">{{summaryField.name}}</span>
                        <span class="calc">{{summaryField.value}}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <div fxLayout="column" *ngIf="campo.tipoCampo.type_field != 'relation_many_to_one' && campo.tipoCampo.type_field != 'relation_one_to_many'" [formGroup]="form"
        [style.width]="(campo.configuration && campo.configuration.style && campo.configuration.style.width) ? campo.configuration.style.width : '500px'">

        <mat-form-field *ngIf="(!campo.tipoCampo) || campo.tipoCampo.type_field == 'text'" appearance="standard">
            <mat-label>{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-label>
            <input matInput [name]="campo.name" [formControlName]="campo.name" class="form-control"
                autocomplete="off" (change)="campoChanged(campo)" [readonly]="(campo.disabled) ? 'true':'false'" >
            <mat-icon *ngIf="campo.suffixIcon" matSuffix>{{campo.suffixIcon}}</mat-icon>
        </mat-form-field>

        <mat-form-field *ngIf="campo.tipoCampo.type_field == 'summary_function'" appearance="standard">
            <mat-label>{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-label>
            <input matInput [name]="campo.name" [formControlName]="campo.name" class="form-control"
                autocomplete="off" (change)="campoChanged(campo)" [readonly]="(campo.disabled) ? 'true':'false'">
            <mat-icon *ngIf="campo.suffixIcon" matSuffix>{{campo.suffixIcon}}</mat-icon>
        </mat-form-field>

        <mat-form-field *ngIf="campo.tipoCampo.type_field == 'number'" appearance="standard">
            <mat-label>{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-label>
            <input matInput type="number" [name]="campo.name" [formControlName]="campo.name"
                class="form-control" autocomplete="off" (change)="campoChanged(campo)" [readonly]="(campo.disabled) ? 'true':'false'">
            <mat-icon *ngIf="campo.suffixIcon" matSuffix>{{campo.suffixIcon}}</mat-icon>
        </mat-form-field>

        <mat-form-field *ngIf="campo.tipoCampo.type_field == 'textarea'" appearance="standard">
            <mat-label>{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-label>
            <textarea [formControlName]="campo.name" matInput cdkTextareaAutosize
                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="4" (change)="campoChanged(campo)" [readonly]="(campo.disabled) ? 'true':'false'"></textarea>
        </mat-form-field>

        <mat-form-field *ngIf="campo.tipoCampo.type_field == 'date'" appearance="standard">
            <mat-label>{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-label>
            <input matInput [matDatepicker]="picker" [name]="campo.name" [formControlName]="campo.name" class="form-control"
                autocomplete="off" (change)="campoChanged(campo)" [readonly]="(campo.disabled) ? 'true':'false'">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <section *ngIf="campo.tipoCampo.type_field == 'checkbox'">
            <mat-checkbox [name]="campo.name" [formControlName]="campo.name" class="form-control"
                autocomplete="off" (change)="campoChanged(campo)">{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-checkbox>
        </section>

        <section *ngIf="campo.tipoCampo.type_field == 'rich-text'">
            <mat-label>{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-label>
            <quill-editor [name]="campo.name" [formControlName]="campo.name" class="form-control"
                autocomplete="off" (change)="campoChanged(campo)"></quill-editor>
        </section>

        <mat-form-field *ngIf="(campo.tipoCampo.type_field == 'select' || campo.tipoCampo.type_field == 'relation_many_to_one_select') && campo.name != 'fk_caja'" appearance="standard">
            <mat-label>{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-label>
            <mat-select [formControlName]="campo.name" class="form-control" (selectionChange)="campoChanged(campo)">
                <mat-option *ngFor="let option of campo.options" [value]="option.value">{{option.text}}
                </mat-option>
            </mat-select>
            <mat-label *ngIf="(firmware && firmware !== undefined && campo.mostrar_info && campo.name == 'dispositivo_reprogramacion_programacion_319')"><b>{{"  Firmaware: " + firmware}}</b></mat-label>  
            <mat-label *ngIf="(checksum && checksum !== undefined && campo.mostrar_info && campo.name == 'dispositiv_reprogramacion_checksum_320')"><b>{{"  Checksum: " + checksum}}</b></mat-label>
            <mat-label *ngIf="(firmware && firmware !== undefined && campo.mostrar_info && campo.name == 'smarthub_reprogramacion_programacion_446')"><b>{{"  Firmaware: " + firmware}}</b></mat-label>    
            <mat-label *ngIf="(garantia && garantia !== undefined && campo.name == 'ucr_revision_pulsador_garantia_451')"><b>{{"  Fecha Fin Garantía: " + garantia}}</b></mat-label>
            <mat-label *ngIf="(firmware && firmware !== undefined && campo.mostrar_info && campo.name == 'cruzroja_reprogramacion_programacion_470')"><b>{{"  Firmaware: " + firmware}}</b></mat-label>  
            <mat-label *ngIf="(checksum && checksum !== undefined && campo.mostrar_info && campo.name == 'cruzroja_reprogramacion_checksum_471')"><b>{{"  Checksum: " + checksum}}</b></mat-label>    
            
            <button *ngIf="!campo.required" class="btn-close" mat-button matSuffix mat-icon-button
                (click)="clearValue(campo.name, $event); campoChanged(campo);">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <div class="form-group w-100-p" *ngIf="campo.tipoCampo.type_field == 'checklist'">
            <mat-label>{{campo.label}}<span class="required" *ngIf="campo.required">*</span></mat-label>
            <ul>
                <li *ngFor="let option of campo.options">
                    <mat-checkbox (change)="onCheckChange(campo.name, option.value, $event); campoChanged(campo);"
                        [value]="option.value"
                        [checked]="campo.respuesta && campo.respuesta.value.includes(option.value)">
                        {{option.text}}
                    </mat-checkbox>
                </li>
            </ul>
        </div>
    </div>
    <ng-container *ngFor="let dep of campo.depending">
        <div fxLayout="column" *ngIf="formCampos.contains(dep.name+'_depending_field'+campo.ID)" [formGroup]="form" 
            [style.width] = "(dep.configuration && dep.configuration.style && dep.configuration.style.width) ? dep.configuration.style.width : '500px'">
            <mat-form-field *ngIf="(!dep.tipoCampo) || dep.tipoCampo.type_field == 'text'" appearance="standard">
                <mat-label>{{dep.label}}<span class="required" *ngIf="dep.required">*</span></mat-label>
                <input matInput [name]="dep.name+'_depending_field'+campo.ID" [formControlName]="dep.name+'_depending_field'+campo.ID" class="form-control"
                    autocomplete="off" (change)="childChanged(dep, campo.ID)">
            </mat-form-field>

            <mat-form-field *ngIf="dep.tipoCampo.type_field == 'number'" appearance="standard">
                <mat-label>{{dep.label}}<span class="required" *ngIf="dep.required">*</span></mat-label>
                <input matInput type="number" [name]="dep.name+'_depending_field'+campo.ID" [formControlName]="dep.name+'_depending_field'+campo.ID"
                    class="form-control" autocomplete="off" (change)="childChanged(dep, campo.ID)">
            </mat-form-field>

            <mat-form-field *ngIf="dep.tipoCampo.type_field == 'textarea'" appearance="standard">
                <mat-label>{{dep.label}}<span class="required" *ngIf="dep.required">*</span></mat-label>
                <textarea [formControlName]="dep.name+'_depending_field'+campo.ID" matInput cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="4" (change)="childChanged(dep, campo.ID)"></textarea>
            </mat-form-field>

            <mat-form-field *ngIf="dep.tipoCampo.type_field == 'date'" appearance="standard">
                <mat-label>{{dep.label}}<span class="required" *ngIf="dep.required">*</span></mat-label>
                <textarea [formControlName]="dep.name+'_depending_field'+campo.ID" matInput cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="4"></textarea>
            </mat-form-field>

            <section *ngIf="dep.tipoCampo.type_field == 'checkbox'" appearance="standard">
                <mat-checkbox [name]="dep.name+'_depending_field'+campo.ID" [formControlName]="dep.name+'_depending_field'+campo.ID" class="form-control"
                    autocomplete="off">{{dep.label}}<span class="required" *ngIf="dep.required">*</span></mat-checkbox>
            </section>

            <mat-form-field *ngIf="dep.tipoCampo.type_field == 'select'" appearance="standard" [ngClass]="{'red_select' : checkCampoRojo(dep.name+'_depending_field'+campo.ID)}">
                <mat-label>{{dep.label}}<span class="required" *ngIf="dep.required">*</span></mat-label>
                <mat-label *ngIf="(firmware && firmware !== undefined && dep.name == 'dispositivo_reprogramacion_programacion_dep')"><b>{{"  Firmaware: " + firmware}}</b></mat-label>  
                <mat-label *ngIf="(checksum && checksum !== undefined && dep.name == 'dispositiv_reprogramacion_checksum_dep')"><b>{{"  Checksum: " + checksum}}</b></mat-label> 
                <mat-label *ngIf="(checksum && checksum !== undefined && dep.name == 'dispositivo_reparacion_checksum')"><b>{{"  Checksum: " + checksum}}</b></mat-label>  
                <mat-label *ngIf="(firmware && firmware !== undefined && dep.name == 'smarthub_reprogramacion_programacion_ok')"><b>{{"  Firmaware: " + firmware}}</b></mat-label>  
                <mat-label *ngIf="(obsoleto && obsoleto !== undefined && dep.name == 'ucr_revision_pulsador_obsoleto')"><b>{{"  Fecha Fin Vida Útil: " + obsoleto}}</b></mat-label>    
                <mat-label *ngIf="(firmware && firmware !== undefined && dep.name == 'televida_reprogramacion_programacion_dep')"><b>{{"  Firmaware: " + firmware}}</b></mat-label>  
                <mat-label *ngIf="(checksum && checksum !== undefined && dep.name == 'televida_reprogramacion_checksum_dep')"><b>{{"  Checksum: " + checksum}}</b></mat-label> 
                <mat-label *ngIf="(checksum && checksum !== undefined && dep.name == 'televida_reparacion_checksum')"><b>{{"  Checksum: " + checksum}}</b></mat-label>  
                <mat-label *ngIf="(firmware && firmware !== undefined && dep.name == 'cruzroja_reprogramacion_programacion_dep')"><b>{{"  Firmaware: " + firmware}}</b></mat-label>  
                <mat-label *ngIf="(checksum && checksum !== undefined && dep.name == 'cruzroja_reprogramacion_checksum_dep')"><b>{{"  Checksum: " + checksum}}</b></mat-label> 
                <mat-label *ngIf="(checksum && checksum !== undefined && dep.name == 'cruzroja_reparacion_checksum')"><b>{{"  Checksum: " + checksum}}</b></mat-label>
                <mat-select [formControlName]="dep.name+'_depending_field'+campo.ID" class="form-control" (selectionChange)="childChanged(dep, campo.ID)">
                    <mat-option *ngFor="let option of dep.options" [value]="option.value">{{option.text}}
                    </mat-option>
                </mat-select>
                <button *ngIf="!dep.required" class="btn-close" mat-button matSuffix mat-icon-button
                    (click)="clearValue(dep.name+'_depending_field'+campo.ID, $event);  childChanged(dep, campo.ID);">
                    <mat-icon>close</mat-icon>
                </button>
               
            </mat-form-field>

            <div class="form-group w-100-p" *ngIf="dep.tipoCampo.type_field == 'checklist'">
                <mat-label>{{dep.label}}<span class="required" *ngIf="dep.required">*</span></mat-label>
                <ul>
                    <li *ngFor="let option of dep.options">
                        <mat-checkbox (change)="onCheckChange(dep.name+'_depending_field'+campo.ID, option.value, $event); childChanged(dep, campo.ID);"
                            [value]="option.value"
                            [checked]="dep.respuesta && dep.respuesta.value.includes(option.value)">
                            {{option.text}}
                        </mat-checkbox>
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>
</ng-template>
