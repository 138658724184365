<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">{{ tituloDialog }}</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column" style="width: 40vw">
        <form fxLayout="row wrap" fxLayoutAlign="flex-start center" #mainForm="ngForm" [formGroup]="formFiltro" class="filtros">
            <mat-form-field *ngIf="datosFiltro?.type == 'String'" appearance="standard" class="form-group w-100-p">
                <mat-label>{{datosFiltro?.label | translate}}</mat-label>
                <input matInput [name]="datosFiltro?.name" [formControlName]="datosFiltro?.name" class="form-control" autocomplete="off">
            </mat-form-field>

            <mat-form-field *ngIf="datosFiltro?.type == 'Number'" appearance="standard" class="form-group w-100-p">
                <mat-label>{{datosFiltro?.label | translate}}</mat-label>
                <input matInput [name]="datosFiltro?.name" [formControlName]="datosFiltro?.name" class="form-control" autocomplete="off" type="number">
            </mat-form-field>

            <div *ngIf="datosFiltro?.type == 'Date' || datosFiltro?.type == 'Datetime'" fxLayout="row" fxLayoutAlign="space-between center" class="wrap-date-range w-100-p">
                <input type="text" matInput
                    ngxDaterangepickerMd
                    [locale]="localeRangeDate"
                    [autoApply]="true"
                    [showCustomRangeLabel]="true"
                    [alwaysShowCalendars]="true"
                    [linkedCalendars]="true"
                    [ranges]="customRanges"
                    [formControlName]="datosFiltro?.name"
                    [name]="datosFiltro?.name"
                    [placeholder]="datosFiltro?.label | translate"
                    class="rangedate datepicker-calendar-icon"/>
                <mat-icon>calendar_today</mat-icon>
                <button *ngIf="checkValue('fechaCreacionRange')" mat-button matSuffix mat-icon-button (click)="clearValue(datosFiltro?.name, $event)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button class="btn-aitana" (click)="guardarDatos()">
        {{ 'COMUN.APLICAR' | translate }}
    </button>
    <button mat-button mat-stroked-button mat-dialog-close>{{ 'COMUN.CANCELAR' | translate }}</button>
</mat-dialog-actions>
