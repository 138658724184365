import { Component, OnInit, EventEmitter, Inject, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { TicketsService } from 'app/services/ticket/tickets.service';
import { UserRoleService } from 'app/services/user/user-role.service';
import * as moment from 'moment';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CygPermission } from '@fuse/types';
import { Router } from '@angular/router';
import { GestionCajasService } from 'app/services/gestion-cajas/gestion-cajas.service';
import { DialogConfirmComponent } from '../util/dialog-confirm/dialog-confirm.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { enclosingPowerOfTwo } from '@tensorflow/tfjs';
import { Loading } from 'notiflix';
import { AlmacenService } from 'app/services/almacen/almacen.service';

export const MY_DATE_FORMATS = {
	parse: {
	  dateInput: 'YYYY-MM-DD',
	},
	display: {
	  dateInput: 'DD/MM/YYYY',
	monthYearLabel: 'MMMM YYYY',
	  dateA11yLabel: 'LL',
	  monthYearA11yLabel: 'MMMM YYYY'
	},
};

@Component({
    selector: 'app-formulario-campos-proyecto-dialog',
    templateUrl: './formulario-campos-proyecto-dialog.component.html',
    styleUrls: ['./formulario-campos-proyecto-dialog.component.scss'],
    providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
    ]
})
export class FormularioCamposProyectoDialogComponent implements OnInit {
    closeDialog = new EventEmitter();
    private _unsubscribeAll: Subject<any>;
    dataSource: MatTableDataSource<any>;
    formCampos: FormGroup;
    //formCamposDepending: FormGroup;
    campos: any;
    valoresCheck: any = {};
    camposGroup: any = null;
    idTicket: number;
    ticket: any = null;
    estadoSelected: number = null;
    pestanaSelected: number = null;
    guardando: boolean = false;
    sinCampos: boolean = false;
    motivosEstado: Array<any> = [];
    contrato: any = null;
    permisoverticketspropio: CygPermission;
    ticketspropio: boolean;
    currentOrder: string;
    currentDirection: string;
    totalElementos: number;
    numPage: number;
    pageSize: number;
    pageSizeOptions: Array<any> = [10, 20, 30, 50, 100, 300];
    fecha: string;
    textoCaja: string = '';
    formFinalUnei: boolean = false;
    cajas: Array<any> = [];
    estadosInternos: Array<any> = [];
    pestana: any = null;
    pestanas: Array<any> = [];
    parseadosChildJson: Array<any> = [];
    enGarantia: boolean = false;
    historicoEstados: Array<any> = [];
    // hiddenFields: Array<string> = [];

    numserie: string;
    modelo :string;
    imei:string;
    usuario : string;
    fechaActual: string;

    firmware: string;
    checksum: string;
    garantia: string;
    obsoleto: string;

    delegacion: string;

    incidencia: string;

    tickets: Array<any>;
    envios: Array<any>;
    seguimientos: Array<any>;

    permisoCrearGestion: CygPermission = { type: "item", name: "Aitana:Perfil Cliente Unei Gestor", active: true };
    crearGestionPermitido: boolean = false;

    garantiaValidadaEvent:EventEmitter<any>;

    dobleVerificacion = [];
    mostrarEnVerificacion = [];
    cambiosVerificacion: boolean = false;
    dialogVerificacion: any = null;
    cajaLlena: boolean = false;
    ticketRevisado: boolean = false;
    //formCamposVerificacion: FormGroup;

    @ViewChild('modalValidacion') modalValidacion;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<FormularioCamposProyectoDialogComponent>,
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        private router: Router,
        private translateService: TranslateService,
        private ticketsSvc: TicketsService,
        private userRoleSvc: UserRoleService,
        private snackBar: MatSnackBar,
        private gestionCajasSvc: GestionCajasService,
        private almacenService: AlmacenService,
        private _changeDetectorRef: ChangeDetectorRef) {

        this._unsubscribeAll = new Subject();
        moment.locale(this.translateService.currentLang);
        this.idTicket = this.data.ticket.ID;
        this.ticket = this.data.ticket;

        this.pestana = this.data.pestana;

        this.garantiaValidadaEvent = new EventEmitter();

        if(this.ticket && this.ticket.estadoInterno){
            this.estadoSelected = this.ticket.estadoInterno.ID;
        }

        if (this.data.ticket.proyecto && this.data.ticket.proyecto.contrato) {
            this.contrato = this.data.ticket.proyecto.contrato;
        }
        this.permisoverticketspropio = { type: "item", name: "Aitana: Visibilidad tickets a tickets asignados", active: true };
        this.ticketspropio = false;

        this.numPage = 0;
        this.pageSize = 20;
        this.totalElementos = 0;

        this.currentOrder = 'ID';
        this.currentDirection = 'desc';

        this.dataSource = new MatTableDataSource();
        this.tickets = [];
        this.envios = [];
        this.seguimientos = [];
        this.numserie = "";
        this.modelo = "";
        this.firmware = "";
        this.checksum = "";
        this.imei = "";
        this.usuario = "";
        this.incidencia = "";
        this.garantia = "";
        this.obsoleto = "";
        this.delegacion = "";


        //Formulario Unei para Fin
        if (this.data.ticket.estado_interno && [281,282,286].includes(this.data.ticket.estado_interno)) {
            this.formFinalUnei = true;
        }
    }

    ngOnInit(): void {
        if (this.data.ticket) {
            if(this.data.ticket.proyecto?.contrato?.gestion_unei == 1){
                this.obtenerDatosUnei(this.data.ticket);
                this.comprobarGarantiaUCR(this.data.ticket);
            }else{
                console.log('No está configurado para el proyecto');
            }
            
            if(this.ticket && this.ticket.proyecto && this.ticket.proyecto.contrato){
                if(this.ticket.proyecto.contrato.formulario_por_estado == 1){
                    this.loadFormularioEstados(this.data.ticket);
                    this.loadEstadosInternos();
                }else{
                    if(this.ticket.proyecto.contrato.formulario_por_pestanas == 1){
                        this.loadFormularioPestanas(this.data.ticket, this.data.pestana);
                    }
                }
            }
        } else {
            this.dialog.closeAll();
        }
        this.checkPermisos();
        
        // this.garantiaValidadaEvent.pipe(takeUntil(this._unsubscribeAll)).subscribe(
        //     (data:any)=>{

        //         if(this.enGarantia != undefined && this.formCampos.controls['dispositivo_reparacion_garantia_bateria_depending_field399']){

        //             if(this.enGarantia == true){
        //                 //this.formCampos.controls['dispositivo_reparacion_garantia_bateria_depending_field399'].setValue("1");
        //                 this.formCampos.get("dispositivo_reparacion_garantia_bateria_depending_field399").setValue("1",{ emitEvent: true });
        //             // this.formCampos.get("dispositivo_reparacion_garantia_bateria_depending_field399").markAsDirty();
        //             // this.reactiveForm.get("firstname").setValue("", { emitEvent: false });
        //             }else{
        //             // this.formCampos.controls['dispositivo_reparacion_garantia_bateria_depending_field399'].setValue("0");
        //                 this.formCampos.get("dispositivo_reparacion_garantia_bateria_depending_field399").setValue("0", { emitEvent: true });
        //             // this.formCampos.get("dispositivo_reparacion_garantia_bateria_depending_field399").markAsDirty();
        //             }
        //             this.childChanged(data,data.fk_depending_field_parent);

        //         }
        //     }
        // );
       
    }

    comprobarGarantiaUCR(ticket:any){
        
        if(ticket.realtime > 0 && ticket.Fechapres){
            this.garantia = (ticket.Fechapres?moment(ticket.Fechapres).add((ticket.realtime?ticket.realtime:0), 'years').format('DD/MM/YYYY'):'-');
            this.obsoleto = (ticket.Fechapres?moment(ticket.Fechapres).add(7, 'years').format('DD/MM/YYYY'):'-');
            console.log('Mostrar fecha garantia y obsoleto', this.garantia, this.obsoleto);
        }
        
    }

    obtenerDatosUnei(ticket: any){
        if(ticket &&  ticket.modelo && ticket.modelo != ""){
            console.log('Obtener datos Unei');
            this.ticketsSvc.getDatosUnei(ticket.FK_entities, ticket.estado_interno, ticket.modelo, ticket.provincia).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                async (data: any) => {
                    if(data){
                        this.checksum = data.datos_checksum;
                        this.firmware = data.datos_firmware;
                    }                   
                    
                },
                (err) => {
                    this.showSnack(err);
                }
            );
        }        

    }

    checkPermisos() {
        this.userRoleSvc.checkPermissions([this.permisoverticketspropio])
            .then((permiso: boolean) => {
                this.ticketspropio = permiso;

            });

        this.userRoleSvc.checkPermissions([this.permisoCrearGestion])
            .then((permiso: boolean) => {
                this.crearGestionPermitido = permiso;
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    showSnack(mensaje: string) {
        this.snackBar.open(mensaje, null, {
            duration: 2000
        });
    }

    loadEstadosInternos(){
        this.ticketsSvc.getEstadosInternosTicketUnei(this.ticket.ID, this.ticket.FK_entities).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            async (data: any) => {
                this.estadosInternos = [...new Set(data)];
            },
            (err) => {
                this.showSnack(err);
            }
        );
    }

    loadPestanas(){
        this.ticketsSvc.getPestanas(this.ticket.proyecto?.contrato?.id).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            async (data: any) => {
                this.pestanas = [...new Set(data)];
            },
            (err) => {
                this.showSnack(err);
            }
        );
    }

    loadFormularioEstados(ticket: any, idEstado: number = null) {
        if(idEstado){
            this.estadoSelected = idEstado;

            if([281,282,286].includes(this.estadoSelected)){
                this.formFinalUnei = true;
            }else{
                this.formFinalUnei = false;
            }
        }else{
            if (this.data.ticket.estado_interno && [281,282,286].includes(this.data.ticket.estado_interno)) {
                this.formFinalUnei = true;
            }else{
                this.formFinalUnei = false;
            }
        }

        this.sinCampos = false;
        this.motivosEstado = [];

        if (ticket.other_serial) {
            this.numserie = "Número de Serie: " + ticket.numserie + " " + "Número de serie corto: " +ticket.other_serial;
        } else {
            this.numserie = "Número de Serie: " + ticket.numserie;
        }

        if(ticket.imei){
            this.imei = ticket.imei;
        }

        if(ticket.centro){
            this.incidencia = ticket.centro;
        }

        if(ticket.provincia){
            this.delegacion = ticket.provincia;
        }

        if(ticket.assign > 0){
            this.usuario = ticket.tecnico?ticket.tecnico.name:'';
        }

        if(ticket.deviceModel){
            this.modelo = ticket.deviceModel.name;
        }else if(ticket.modelo && ticket.modelo != ""){
            this.modelo = ticket.modelo;
        }

        this.fechaActual = moment().format('DD/MM/YYYY');

        this.valoresCheck = {};

        this.ticketsSvc.getFormularioCamposProyecto(ticket.ID, idEstado).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            async (data: any) => {
                this.formCampos = this.formBuilder.group({});
                //this.formCamposVerificacion = this.formBuilder.group({});
                //let formControlsDependings = {};

                if (data.campos && data.campos.length > 0) {
                    for (var e = 0; e < data.campos.length; e++) {
                        let campos = data.campos[e];
                        this.camposGroup = campos.ID;

                        for (var i = 0; i < campos.camposContrato.length; i++) {
                            this.processCampo(campos.camposContrato[i], null);
                        }
                    }
                } else {
                    this.sinCampos = true;
                    this.camposGroup = null;
                }

                if(data.revisado && data.revisado == true){
                    this.ticketRevisado = data.revisado;
                }

                if(data.motivos && data.motivos.length > 0){
                    this.motivosEstado = data.motivos;
                }

                if(data.historico && data.historico.length > 0)
                this.historicoEstados = data.historico;

                this.campos = data.campos;
                
                //this.formCamposDepending = this.formBuilder.group(formControlsDependings);

                if (this.formFinalUnei) {
                    this.loadCajas();
                }
            },
            (err) => {
                this.showSnack(err);
            }
        );
    }

    loadFormularioPestanas(ticket: any, pestana: any){
        this.ticketsSvc.getFormularioCamposProyecto(ticket.ID, null, pestana.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            async (data: any) => {
                this.formCampos = this.formBuilder.group({});

                if(data.revisado && data.revisado == true){
                    this.ticketRevisado = data.revisado;
                }

                if (data.campos && data.campos.length > 0) {
                    for (var e = 0; e < data.campos.length; e++) {
                        let campos = data.campos[e];
                        this.camposGroup = campos.ID;

                        for (var i = 0; i < campos.camposContrato.length; i++) {
                            this.processCampo(campos.camposContrato[i], null);
                        }
                    }
                } else {
                    this.sinCampos = true;
                    this.camposGroup = null;
                }

                this.campos = data.campos;
            },
            (err) => {
                this.showSnack(err);
            }
        );
    }

    processCampo( campo, campoPadre ) {
        let respuesta = campo.respuesta ? campo.respuesta.value : (campo.default) ? campo.default : '';

        if (campo.options && typeof campo.options === 'string' && campo.options != "")
            campo.options = JSON.parse(campo.options);

        if (campo.configuration && typeof campo.configuration === 'string'  && campo.configuration != "")
            campo.configuration = JSON.parse(campo.configuration);

         
        if(campo.tipoCampo.type_field == 'relation_many_to_one' || campo.tipoCampo.type_field == 'relation_one_to_many') {
            
            if(campo.respuestas) {
                //Elemento padre
                campo.respuestas.map((campoRespuesta) => {
                    this.processCampo(campoRespuesta, campo);
                    
                });

                this.processSummaryFieldsRelations(campo);
            } else {
                if(!respuesta){
                    campo.respuesta = {};
                    campo.respuesta['value'] = this.generateNewUniqueIDStrategy();
                }

                campo.fields_child.map((field_child) => {

                    let fieldRelName;
                    //Si estamos cogiendo respuestas (relation_one_to_many) debemos coger el name del campo contenedor de las respuestas (campoPadre)
                    if ( campoPadre && campo.tipoCampo.type_field == 'relation_one_to_many') {
                        fieldRelName = campoPadre.name + "[" + campo.respuesta['value'] + "]";
                    } else {
                        fieldRelName = campo.name + "[" + campo.respuesta['value'] + "]";
                    }

                    if(field_child.tipoCampo.type_field == 'relation_one_to_many' || field_child.tipoCampo.type_field == 'relation_many_to_one')
                        field_child.name = fieldRelName + ":" + field_child.name;
                    else
                        field_child.name = fieldRelName + "-" + field_child.name;

                    this.processCampo(field_child, campo);
                });
            }
        } else {

            //Add ID campo
            if(campo.name != 'fk_caja')
            campo.name += "_" + campo.ID;

            if (campo.tipoCampo.type_field == 'checklist') {
                campo.name = campo.name;
                respuesta = respuesta.split(',');
                this.valoresCheck[campo.name] = respuesta;
            }

            if (campo.tipoCampo.type_field == 'date') {
                respuesta = new Date(respuesta);
            }
    
            if (campo.required == 1) {
                this.formCampos.addControl(campo.name, new FormControl(respuesta, Validators.required));
            } else {
                this.formCampos.addControl(campo.name, new FormControl(respuesta));
            }

            if(campo.doble_verificacion){
                //this.formCamposVerificacion.addControl(campo.name+"_dobleVerificacion", new FormControl("", Validators.required));
                this.dobleVerificacion.push({label: campo.label_doble_verificacion, valor_activa: campo.value_doble_verificacion, nombre: campo.name, parent: campo, depending: false, campo_id: null});
            }

            if(campo.tipoCampo.type_field == 'summary_function')
                this.processSummaryField(campo, campoPadre);

            //Si tiene campos dependientes
            if(campo.depending && campo.depending.length > 0){
                for(var j = 0; j < campo.depending.length; j++){
                    const auxCampo = campo.depending[j];
                    let respuestaCampo = auxCampo.respuesta ? auxCampo.respuesta.value : (auxCampo.default) ? auxCampo.default : '';
                    const valoresActivan = auxCampo.parent_depend_values?auxCampo.parent_depend_values.split(','):[];
                    
                    if (auxCampo.options && typeof auxCampo.options === 'string' && auxCampo.options != "" )
                        auxCampo.options = JSON.parse(auxCampo.options);

                    if (auxCampo.configuration && typeof auxCampo.configuration === 'string' && auxCampo.configuration != "")
                        auxCampo.configuration = JSON.parse(auxCampo.configuration);
    
                    //Comprobamos si el campo es hijo de otro campo dependiente
                    if(auxCampo.depending_parent){
                        let respuestaParent = [];

                        if(auxCampo.depending_parent.respuesta){
                            if(auxCampo.depending_parent.tipoCampo && auxCampo.depending_parent.tipoCampo.type_field == 'checklist'){
                                respuestaParent = auxCampo.depending_parent.respuesta.value.split(',')
                            }else{
                                respuestaParent.push(auxCampo.depending_parent.respuesta.value);
                            }
                        }
                        
                        if((auxCampo?.parent_depend_values == 'any' && respuestaParent.length > 0) || (respuestaParent && respuestaParent.some(r => valoresActivan.includes(r)))){
                            if (auxCampo.tipoCampo.type_field == 'checklist') {
                                const respuestaCheck = respuestaCampo.split(',');
                                this.valoresCheck[auxCampo.name+'_depending_field'+campo.ID] = respuestaCheck;
                            }

                            if (auxCampo.tipoCampo.type_field == 'date') {
                                respuestaCampo = new Date(respuestaCampo);
                            }
    
                            if (auxCampo.required == 1) {
                                this.formCampos.addControl(auxCampo.name+'_depending_field'+campo.ID, new FormControl(respuestaCampo, Validators.required));
                            } else {
                                this.formCampos.addControl(auxCampo.name+'_depending_field'+campo.ID, new FormControl(respuestaCampo));
                            }

                            if(auxCampo.doble_verificacion){
                                //this.formCamposVerificacion.addControl(auxCampo.name+'_depending_field'+campo.ID+"_dobleVerificacion", new FormControl("", Validators.required));
                                this.dobleVerificacion.push({label: auxCampo.label_doble_verificacion, valor_activa: auxCampo.value_doble_verificacion, nombre: auxCampo.name+'_depending_field'+campo.ID, parent: auxCampo, depending: true, campo_id: campo.ID});
                            }
                        }
                    }else{
                        let auxRespuesta = [];

                        if(Array.isArray(respuesta)){
                            auxRespuesta = respuesta;
                        }else{
                            auxRespuesta.push(respuesta);
                        }

                        if((auxCampo?.parent_depend_values == 'any' && auxRespuesta.length > 0) || (auxRespuesta && auxRespuesta.some(r => valoresActivan.includes(r)))){
                            if (auxCampo.tipoCampo.type_field == 'checklist') {
                                const respuestaCheck = respuestaCampo.split(',');
                                this.valoresCheck[auxCampo.name+'_depending_field'+campo.ID] = respuestaCheck;
                            }

                            if (auxCampo.tipoCampo.type_field == 'date') {
                                respuestaCampo = new Date(respuestaCampo);
                            }
    
                            if (auxCampo.required == 1) {
                                this.formCampos.addControl(auxCampo.name+'_depending_field'+campo.ID, new FormControl(respuestaCampo, Validators.required));
                            } else {
                                this.formCampos.addControl(auxCampo.name+'_depending_field'+campo.ID, new FormControl(respuestaCampo));
                            }

                            if(auxCampo.doble_verificacion){
                                //this.formCamposVerificacion.addControl(auxCampo.name+'_depending_field'+campo.ID+"_dobleVerificacion", new FormControl("", Validators.required));
                                this.dobleVerificacion.push({label: auxCampo.label_doble_verificacion, valor_activa: auxCampo.value_doble_verificacion, nombre: auxCampo.name+'_depending_field'+campo.ID, parent: auxCampo, depending: true, campo_id: campo.ID});
                            }
                        }
                    }
                }
            }
        }
    }

    addElement(campo) {
        //Clonar campo
        let newFieldParent = JSON.parse(JSON.stringify(campo));

        //Indicamos que es un nuevo elemento
        newFieldParent['respuesta'] = {};
        newFieldParent.respuesta['value'] = this.generateNewUniqueIDStrategy();

        if(campo.required && !campo.respuestas)
            campo['respuestas'] = [];
        else
            delete newFieldParent.respuestas;

        campo.respuestas.push(newFieldParent);

        this.processCampo(newFieldParent, null);

        //Actualizamos los summaryFields que puedan tener relación con el elemento añadido.
        this.processSummaryFields(campo);
    }

    removeElement(campo, respuesta) {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: { 
				titulo: "Eliminar elemento", 
				mensaje: "¿Seguro que quiere eliminar este elemento?",
				btnLabelOk: "Sí",
				btnLabelCancel: "No"
			},
			panelClass: 'panel-aitana',
			// maxWidth: '70vw',
			disableClose: true,
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result === true) {
                if(campo.required && campo.respuestas.length == 1) {
                    //Al menos una respuesta se tiene que dar
				    console.log("Eliminacion cancelada");
                }else {
                    //Eliminar del formulario
                    respuesta.fields_child.map((field) => {
                        this.deleteFieldForm(field);
                    });
                    //Eliminar de las respuestas
                    campo.respuestas.splice(campo.respuestas.findIndex(elem => {
                        return elem.respuesta.value === respuesta.respuesta.value;
                    }), 1);

                    //Actualizamos los summaryFields que puedan tener relación con el elemento eliminado.
                    this.processSummaryFields(campo);
                }
			}
			else {
				console.log("Eliminacion cancelada");
			}
		});
    }

    deleteFieldForm(field) {
        if(field.tipoCampo.type_field == 'relation_one_to_many') {
            field.respuestas.map((respuesta) => {
                respuesta.fields_child.map((field) => {
                    this.deleteFieldForm(field);
                });
            });
        } else if (field.tipoCampo.type_field == 'relation_many_to_one') {
            field.fields_child.map((field) => {
                this.deleteFieldForm(field);
            });
        }
        this.formCampos.removeControl(field.name);
    }

    processSummaryField(summaryField, campoPadre) {
        let configuration = (typeof summaryField.configuration === 'string') ? JSON.parse(summaryField.configuration) : summaryField.configuration;
        let functionCalc = configuration.functionCalc;
        let functionResult = (configuration.hasOwnProperty('functionResult')) ? configuration.functionResult : null;

        const propertiesMatch = this.summaryFieldProperties(functionCalc);
        
        //Añade un atributo summaryFields a los campos relacionados para que cuando se añade o se elimine una relación se actualice el summaryField correspondiente
        if(campoPadre)
            for(let propertyMatch of propertiesMatch)
                if(propertyMatch[1].includes('.'))
                    for(let field_child of campoPadre.fields_child)
                        if (field_child.name.includes(propertyMatch[1].split('.')[0]))
                            if(field_child.hasOwnProperty('summaryFields')) field_child['summaryFields'].push(summaryField);
                            else field_child['summaryFields'] = [summaryField];

        //Añade el evento valueChanges a los campos del formulario implicados para que se actualize el summaryField cuando cambien de valor.
        let controlNameChilds = this.getControlNameChilds(summaryField.name, functionCalc);
        for(let controlNameChild of controlNameChilds)
            this.formCampos.controls[controlNameChild].valueChanges.subscribe(() => {
                this.updateFormControlSummaryField(summaryField.name, functionCalc, functionResult);
            });

        //Calcula el campo summaryField (por si tiene valores ya introducidos).
        this.updateFormControlSummaryField(summaryField.name, functionCalc, functionResult);
    }

    getControlNameChilds(summaryFieldName, functionCalc) {
        let controlNameChilds = [];
        const propertiesMatch = this.summaryFieldProperties(functionCalc);

        for(let propertyMatch of propertiesMatch){
            let controlNameFound = [];
            if(propertyMatch[1].includes('.')) {
                let controlNameRelation = summaryFieldName.split("-")[0] + ':' + propertyMatch[1].split('.')[0];
                //Scape special characters for RegExp
                const regExp = new RegExp(controlNameRelation.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + "\\[.+\\]" + "-" + propertyMatch[1].split('.')[1]);
                controlNameFound = Object.keys(this.formCampos.controls)
                    .filter((controlName) => 
                        controlName.match(regExp));
            } else {
                let controlNameRelation = summaryFieldName.split("-")[0];
                controlNameFound = Object.keys(this.formCampos.controls)
                    .filter((controlName) => 
                        controlName.includes(controlNameRelation + '-' + propertyMatch[1]));
            }
            controlNameChilds = controlNameChilds.concat(controlNameFound);
        }

        return controlNameChilds;
    }

    processSummaryFieldsRelations(relationField) {
        let configuration = (typeof relationField.configuration === 'string') ? JSON.parse(relationField.configuration) : relationField.configuration;
        if(configuration.hasOwnProperty('summary_fields_relations')) {
            let summaryFieldsRelations = configuration.summary_fields_relations;

            relationField['summaryFieldsRelations'] = summaryFieldsRelations;

            this.calcSummaryFieldsRelations(relationField);
        }
    }

    processSummaryFields(field) {
        if(field.hasOwnProperty('summaryFields')) {
            for(let summaryField of field['summaryFields']) {

                this.processSummaryField(summaryField, null);

            }
        }
    }

    updateFormControlSummaryField(formControlNameSummaryField, functionCalc, functionResult) {

        let result = this.calcFunctionConfiguration(formControlNameSummaryField, functionCalc);

        //Función extra al resultado (redondeo por ejemplo)
        if(functionResult) {
            if(functionResult == 'fixed')
                result = result.toFixed(2);
            else if(functionResult == 'round')
                result = Math.round(result * 100) / 100;
        }

        this.formCampos.controls[formControlNameSummaryField].setValue(result);

    }

    calcFunctionConfiguration(summaryFieldName, functionCalc){

        if(functionCalc.includes('SUM')) {
            const reg = /SUM\[(.+)\] /;
            let subFunctionsCalc = functionCalc.match(reg)[1];

            let propertiesMatch = [...this.summaryFieldProperties(subFunctionsCalc)];

            let sumTotal = 0;

            //Obtener todos los nombres de campos hijos
            if(propertiesMatch.length > 0) {
                let controlNameChilds = [];
                for(let propertyMatch of propertiesMatch){
                    if(propertyMatch[1].includes('.')) {
                        let controlNameRelation = summaryFieldName.split("-")[0] + ':' + propertyMatch[1].split('.')[0];
                        //Scape special characters for RegExp
                        //TODO Codigo duplicado (obtener los campos del formulario relacionados)
                        const regExp = new RegExp(controlNameRelation.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + "\\[.+\\]" + "-" + propertyMatch[1].split('.')[1]);
                        controlNameChilds = Object.keys(this.formCampos.controls)
                            .filter((controlName) => 
                                controlName.match(regExp));

                        subFunctionsCalc = subFunctionsCalc.replace(propertyMatch[1].split('.')[0]+'.', '');
                    }
                }

                for(let controlNameChild of controlNameChilds) {
                    sumTotal += this.calcFunctionConfiguration(controlNameChild.split("-")[0], subFunctionsCalc);
                }
            }

            functionCalc = functionCalc.replace("SUM[" + functionCalc.match(reg)[1] + "]", sumTotal);

            return this.calcFunctionConfiguration(summaryFieldName, functionCalc);
        } else {

            let propertiesMatch = this.summaryFieldProperties(functionCalc);

            for(let propertyMatch of propertiesMatch){
                let fieldNameFormControl = summaryFieldName.split("-")[0] + '-' + propertyMatch[1];
                functionCalc = functionCalc.replace(propertyMatch[0], this.formCampos.controls[fieldNameFormControl].value); 
            }

            let result;
            try{
                result = eval(functionCalc);
            }catch {
                result = undefined;
            }

            return result;
        }
    }

    calcSummaryFieldsRelations(relationField) {
        /*if(relationField.hasOwnProperty('summaryFieldsRelations')) {
            for(let summaryFieldRelation of relationField['summaryFieldsRelations']){
                this.calcSummaryFieldRelations(summaryFieldRelation, relationField);
            }
        }*/
    }

    calcSummaryFieldRelations(summaryField, relationField) {
        let functionCalc = summaryField.functionCalc;

        const propertiesMatchArray = [...this.summaryFieldProperties(functionCalc)];

        if(summaryField.functionCalc.includes('SUM')) {

            let totalSum = 0;

            for(let respuesta of relationField.respuestas) {
                let functionCalcRespuesta = functionCalc;
                for(let propertyMatch of propertiesMatchArray) {

                        for(let field_child_respuesta of respuesta.fields_child) {
                            if(field_child_respuesta.name.includes(propertyMatch[1])){
                                this.formCampos.controls[field_child_respuesta.name].valueChanges.subscribe((value) => this.campoChanged(relationField));

                                functionCalcRespuesta = functionCalcRespuesta.replace(propertyMatch[0], this.formCampos.controls[field_child_respuesta.name].value);
                            }
                        }
                }

                const reg = /SUM\(([0-9]+)\)/g;
                totalSum += eval(functionCalcRespuesta.replace(reg,'$1'));
            }

            let result = totalSum;
            if(summaryField.hasOwnProperty('suffix'))
                result += summaryField.suffix;
            if(summaryField.hasOwnProperty('prefix'))
                result = summaryField.prefix + result;

            summaryField['value'] = result;
        }
    }

    summaryFieldProperties(functionCalc) {
        const re = new RegExp(/\${(?<fieldName>[a-zA-Z0-9_.]+)}/, "gi");
        return functionCalc.matchAll(re);
    }

    generateNewUniqueIDStrategy() {
        return 'new'+Date.now();
    }

    guardarDatos() {
        const formValue = this.formCampos.value;
        //const formDependingValue = this.formCamposDepending.value;

        if (this.formCampos.valid) {
            if(this.dobleVerificacion.length > 0 && this.cambiosVerificacion == false){
                this.guardando = false;

                //mostrarEnVerificacion

                for(var i = 0; i < this.dobleVerificacion.length; i++){
                    if(this.formCampos.controls[this.dobleVerificacion[i].nombre] && this.formCampos.get(this.dobleVerificacion[i].nombre).value.toString() == this.dobleVerificacion[i].valor_activa.toString()){
                        this.mostrarEnVerificacion.push(this.dobleVerificacion[i]);
                    }
                }

                if(this.mostrarEnVerificacion.length > 0){
                    this.dialogVerificacion = this.dialog.open(this.modalValidacion, {
                        maxWidth: '90%'
                    });
                }else{
                    this.cambiosVerificacion = true;
                }
            }else{
                this.cambiosVerificacion = true;
            }

            if(this.cambiosVerificacion == true){
                this.guardando = true;
                this.ticketsSvc.guardarCamposFormularioProyecto(formValue, this.idTicket, this.camposGroup).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                    (data: any) => {
                        this.showSnack(this.translateService.instant('COMUN.DATOS_GUARDADOS'));

                        //Si la caja se ha llenado generamos el documento
                        if(data && data.cajaCompleta){
                            this.descargarDocCaja();
                        }

                        //Si tiene perfil Unei técnico, des-asignar el caso
    
                        if (this.ticketspropio) {
                            this.ticketsSvc.modificarEstadoSiguienteUnei(this.idTicket, this.camposGroup).subscribe(
                                (data:any) => {
                                    
                                    if(data.estado && [281, 282, 286].includes(data.estado)){
                                        console.log('Estados siguientes modificados',data);
                                        this.dialogRef.close({ res: true });                                        
                                        this.guardando = false;
                                        
                                    }else{
                                        console.log('Estados siguientes modificados no cerrados',data);
                                        this.dialogRef.close({ res: false });
                                      //  this.recargarTicket()
                                    }
                                },
                                (err) => {
                                    console.log('Estados siguientes no modificados',err);
                                    this.showSnack(err);
                                    this.dialogRef.close({ res: false });
                                    // this.recargarTicket()
                                }
                            );
                        }else{
                            this.dialogRef.close({ res: true });                            
                            this.guardando = false;
                            // this.recargarTicket()
                        }
                       
                    },
                    (err) => {
                        this.showSnack(err);
                        this.guardando = false;
                    }
                );
            }
        }
        else {
            this.showSnack(this.translateService.instant('COMUN.ERROR_GUARDAR'));
            this.guardando = false;
           // this.recargarTicket()
        }
    }

    recargarTicket(){
        window.location.reload();
    }

    descargarDocCaja(){
        Loading.circle("Generando documento cierre");
        
        this.ticketsSvc.generarDocumentoCajaUnei(this.idTicket).subscribe(
            (fileUrl: string) => {
                window.open(fileUrl, '_blank');
                Loading.remove(500);
            },
            (err) => {
                this.showSnack("No se ha podido generar el documento");
                Loading.remove(500);
            }
        )
    }

    cerrarVerificacion(){
        //if(this.formCamposVerificacion.valid){
            this.cambiosVerificacion = true;
            this.dialogVerificacion.close();
        //}
    }

    async guardarEstadoSiguiente(ticket){

        console.log('Estado actual', ticket.estado_interno);

    }

    onCheckChange(campo, valor, event) {
        if (event.checked) {
            if (this.valoresCheck[campo]) {
                this.valoresCheck[campo].push(valor);
            } else {
                this.valoresCheck[campo] = [valor];
            }
        } else {
            if (this.valoresCheck[campo]) {
                const index = this.valoresCheck[campo].indexOf(valor);
                if (index > -1) {
                    this.valoresCheck[campo].splice(index, 1);
                }
            }
        }

        this.formCampos.get(campo).setValue(this.valoresCheck[campo]);
    }

    clearValue(formControlName: string, event?) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (formControlName == 'fk_caja')
            this.textoCaja = '';

        let formControl = this.formCampos.get(formControlName);
        if (formControl) {
            formControl.setValue('');
        }
    }

    loadTicketsPropios(): void {
        let filtros = {};


        this.ticketsSvc.getTicketsPropios(this.numPage, this.pageSize, this.fecha, this.currentOrder, this.currentDirection, filtros).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (data: any) => {
                this.tickets = data.elements;
                this.totalElementos = data.totalElements;
                this.dataSource.data = this.tickets;

                this.obtenerTrackings();

                this.obtenerSeguimientos();


            },
            (err) => {
                this.showSnack(err);
            }
        );
    }

    obtenerTrackings() {

        for (const itemTicket of this.tickets) {
            this.ticketsSvc.getEnvios(itemTicket.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (data: any) => {
                    this.envios = data.elements;
                    //	this.dataSource.data = this.envios;
                    itemTicket.tracking = this.envios;
                    this.dataSource.data = this.tickets;
                },
                (err) => {
                    this.showSnack(err);
                }
            );
        }



    }

    obtenerSeguimientos() {
        for (const itemTicket of this.tickets) {
            this.ticketsSvc.getSeguimientosTicket(itemTicket.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (data: any) => {
                    this.seguimientos = data.elements;
                    //	this.dataSource.data = this.envios;
                    itemTicket.seguimientos = this.seguimientos;
                    this.dataSource.data = this.tickets;
                },
                (err) => {
                    this.showSnack(err);
                }
            );
        }
    }

    async comprobarCajaLlena(){
        //Consultamos la capacidad disponible y ocupada de la caja
        const opcion = await this.cajas.filter(item => {
            return item.value == this.formCampos.get('fk_caja')?.value;
        });

        if (opcion && opcion[0] && opcion[0].capacidad) {
            if(parseInt(opcion[0].capacidad) <= parseInt(opcion[0].contiene)){
                this.cajaLlena = true;
            }else{
                this.cajaLlena = false;
            }
        }
    }

    async cajaSelected() {
        this.textoCaja = '';
        // if (this.cajas.length <= 0)
        //     this.textoCaja = "No se encontraron cajas para este PN";

        //Consultamos la capacidad disponible y ocupada de la caja
        const opcion = await this.cajas.filter(item => {
            return item.value == this.formCampos.get('fk_caja')?.value;
        });

        if (opcion && opcion[0] && opcion[0].capacidad) {
            this.textoCaja = 'Elementos caja ' + (opcion[0].contiene ? parseInt(opcion[0].contiene) + 1 : 1) + '/' + opcion[0].capacidad;
        }
    }

    /*pnChanged() {
        this.loadCajas();
    }*/

    loadCajas() {
        this.textoCaja = "";
        /**if (this.formCampos.get('pn').valid && this.formCampos.get('pn').value.trim() != "") {
            this.gestionCajasSvc.getForSelect(this.formCampos.get('pn').value.trim(), this.data.ticket.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (data: any) => {
                    this.cajas = data;
                    if (data.length <= 0) {
                        this.textoCaja = "No se encontraron cajas para este PN";
                        this.formCampos.get('fk_caja').setValue(null);
                    }

                    this.cajaSelected();
                },
                (err) => {
                    this.showSnack(err);
                }
            );*/

            this.gestionCajasSvc.getForSelect(this.data.ticket.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (data: any) => {
                    this.cajas = data;
                    if (data.length <= 0) {
                        this.textoCaja = "No se encontraron cajas con espacio";
                        this.formCampos.get('fk_caja').setValue(null);
                    }else{
                        if(data.length == 1 && data[0].value){
                            this.formCampos.get('fk_caja').setValue(data[0].value.toString());
                        }
                    }

                    this.comprobarCajaLlena();
                    this.cajaSelected();
                },
                (err) => {
                    this.showSnack(err);
                }
            );
        /*} else {
            this.cajas = [];
            this.cajaSelected();
            this.formCampos.get('fk_caja').setValue(null);
        }*/
    }

    abrirCajas() {
        window.open("/gestion-cajas?new=1", '_blank');
    }

    campoChanged(campo: any, duplicado: boolean = false){

        if(campo.depending && campo.depending.length > 0){
            let valorCampo = this.formCampos.get(campo.name).value;

            if(valorCampo && !Array.isArray(valorCampo)){
                valorCampo = [valorCampo];
            }

            if(duplicado){
                this.cambiosVerificacion = true;
            }
            
            for(var j = 0; j < campo.depending.length; j++){
                const auxCampo = campo.depending[j];
                let respuestaCampo = auxCampo.respuesta ? auxCampo.respuesta.value : (auxCampo.default) ? auxCampo.default : '';
                const valoresActivan = auxCampo.parent_depend_values?auxCampo.parent_depend_values.split(','):[];
                
                if((!auxCampo.depending_parent)  && valorCampo && (valorCampo.some(r => valoresActivan.includes(r)) || (auxCampo?.parent_depend_values == 'any' && valorCampo.length > 0))){
                    if (auxCampo.tipoCampo.type_field == 'checklist') {
                        const respuestaCheck = respuestaCampo.split(',');
                        this.valoresCheck[auxCampo.name+'_depending_field'+campo.ID] = respuestaCheck;
                    }

                    if (auxCampo.tipoCampo.type_field == 'date') {
                        respuestaCampo = new Date(respuestaCampo);
                    }

                    if (auxCampo.required == 1) {   
                        this.formCampos.addControl(auxCampo.name+'_depending_field'+campo.ID, new FormControl(respuestaCampo, Validators.required));
                    } else {
                        this.formCampos.addControl(auxCampo.name+'_depending_field'+campo.ID, new FormControl(respuestaCampo));

                        // Añadir valor por defecto de firmaware y checksum calculados previamente, si responde si a las preguntas de los campos padres
                        if(this.firmware != undefined && this.formCampos.controls['dispositivo_reprogramacion_version_firmware_dep_depending_field319'] && this.formCampos.controls['dispositivo_reprogramacion_version_firmware_dep_depending_field319'].value ==""){
                            this.formCampos.controls['dispositivo_reprogramacion_version_firmware_dep_depending_field319'].setValue(this.firmware);
                        }

                        if(this.checksum != undefined && this.formCampos.controls['dispositivo_reprogrmacion_checksum_dep_depending_field320'] && this.formCampos.controls['dispositivo_reprogrmacion_checksum_dep_depending_field320'].value ==""){
                            this.formCampos.controls['dispositivo_reprogrmacion_checksum_dep_depending_field320'].setValue(this.checksum);
                        }
                    }

                    if(auxCampo.doble_verificacion){
                        //this.formCamposVerificacion.addControl(auxCampo.name+'_depending_field'+campo.ID+"_dobleVerificacion", new FormControl("", Validators.required));
                        this.dobleVerificacion.push({label: auxCampo.label_doble_verificacion, valor_activa: auxCampo.value_doble_verificacion, nombre: auxCampo.name+'_depending_field'+campo.ID, parent: auxCampo, depending: true, campo_id: campo.ID});
                    }
                }else{
                    if(this.formCampos.contains(auxCampo.name+'_depending_field'+campo.ID)){//formulario contiene
                        if (auxCampo.tipoCampo.type_field == 'checklist') {
                            //const respuestaCheck = respuestaCampo.split(',');
                            this.valoresCheck[auxCampo.name+'_depending_field'+campo.ID] = [];
                        }

                        if (this.valoresCheck[auxCampo.name+'_depending_field'+campo.ID]) {
                            const index = this.valoresCheck[auxCampo.name+'_depending_field'+campo.ID].indexOf(this.formCampos.get(auxCampo.name+'_depending_field'+campo.ID).value);
                            if (index > -1) {
                                this.valoresCheck[auxCampo.name+'_depending_field'+campo.ID].splice(index, 1);
                            }
                        }

                        this.formCampos.removeControl(auxCampo.name+'_depending_field'+campo.ID);
                    }
                }
            }
        }

        console.log('Campo change', campo);

        if(campo.name === "gsm_preparacion_icc_tarjeta_453"){
            this.comprobarTarjetaICC(campo, null);
        }
        // if(campo.hiden && campo.hiden.length > 0){
        //     let valorCampo = this.formCampos.get(campo.name).value;

        //     if(valorCampo && !Array.isArray(valorCampo)){
        //         valorCampo = [valorCampo];
        //     }

        //     for(var i = 0; i < campo.hiden.length; i++){
        //         const hidde = campo.hiden[i];

        //         const valoresActivan = hidde.value_hide?hidde.value_hide.split(','):[];

        //         const nombreCampo = hidde.fieldHide?hidde.fieldHide.name:(hidde.fieldDependingHide?hidde.fieldDependingHide.name+'_depending_field'+campo.ID:null);
                
        //         if(nombreCampo){
        //             if((valorCampo.some(r => valoresActivan.includes(r)) || (valoresActivan == 'any' && valorCampo.length > 0))){
        //                 this.hiddenFields.push(nombreCampo);
        //                 this.formCampos.controls[nombreCampo].disable();
        //             }else{
        //                 if(this.hiddenFields.includes(nombreCampo)){
        //                     let borrado = false;

        //                     for(var e = 0; e < this.hiddenFields.length && !borrado; e++){
        //                         if(this.hiddenFields[e] == nombreCampo){
        //                             this.hiddenFields.splice(e,1);
        //                             borrado = true;
        //                         }
        //                     }

        //                     if(!this.hiddenFields.includes(nombreCampo)){
        //                         this.formCampos.controls[nombreCampo].enable();
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    childChanged(campo: any, parent_id: number, duplicado: boolean = false){

        
        let valorCampo = this.formCampos.get(campo.name+'_depending_field'+parent_id).value;

        if(valorCampo && !Array.isArray(valorCampo)){
            valorCampo = [valorCampo];
        }

        

        if(duplicado){
            this.cambiosVerificacion = true;
        }

        if(campo.childs && campo.childs.length > 0){    
            for(var i = 0; i < campo.childs.length; i++){
                const auxCampo = campo.childs[i];
                let respuestaCampo = auxCampo.respuesta ? auxCampo.respuesta.value : (auxCampo.default) ? auxCampo.default : '';
                
                const valoresActivan = auxCampo.parent_depend_values?auxCampo.parent_depend_values.split(','):[];
                
                if(auxCampo.fk_depending_field_parent == campo.ID && valorCampo && (valorCampo.some(r => valoresActivan.includes(r)) || (auxCampo?.parent_depend_values == 'any' && valorCampo.length > 0))){
                    if (auxCampo.tipoCampo.type_field == 'checklist') {
                        const respuestaCheck = respuestaCampo.split(',');
                        this.valoresCheck[auxCampo.name+'_depending_field'+parent_id] = respuestaCheck;
                    }

                    if (auxCampo.tipoCampo.type_field == 'date') {
                        respuestaCampo = new Date(respuestaCampo);
                    }

                    if(!this.formCampos.contains(auxCampo.name+'_depending_field'+parent_id)){
                        if (auxCampo.required == 1) {
                            this.formCampos.addControl(auxCampo.name+'_depending_field'+parent_id, new FormControl(respuestaCampo, Validators.required));
                        } else {
                            this.formCampos.addControl(auxCampo.name+'_depending_field'+parent_id, new FormControl(respuestaCampo));
                        }

                        if(auxCampo.doble_verificacion){
                            //this.formCamposVerificacion.addControl(auxCampo.name+'_depending_field'+parent_id+'_depending_field'+campo.ID+"_dobleVerificacion", new FormControl("", Validators.required));
                            this.dobleVerificacion.push({label: auxCampo.label_doble_verificacion, valor_activa: auxCampo.value_doble_verificacion, nombre: auxCampo.name+'_depending_field'+parent_id, parent: auxCampo, depending: true, campo_id: parent_id});
                        }

                        this._changeDetectorRef.markForCheck();

                        this._changeDetectorRef.detectChanges();   

                        // if(campo.name === "dispositivo_reparacion_medicion_sin_carga" && auxCampo.name === 'dispositivo_reparacion_garantia_bateria'){
                        //     this.validacionCarga(campo,auxCampo,parent_id);
                        // }
                    }else{
                        if (auxCampo.tipoCampo.type_field == 'checklist') {
                            this.valoresCheck[auxCampo.name+'_depending_field'+parent_id] = [];
                        }

                        this.formCampos.get(auxCampo.name+'_depending_field'+parent_id).setValue('');
                    }


                    //FORM DISPOSTIVO
                    if (campo.name === 'dispositivo_reparacion_numserie_bateria' && auxCampo.name === 'dispositivo_reparacion_garantia_bateria') {

                        this.comprobarGarantia(campo, auxCampo, parent_id);
                        
                    }

                    if (campo.name === 'dispositivo_reparacion_tipo_bateria' && auxCampo.name === 'dispositivo_reparacion_cambiar_bateria_litio') {

                       // this.comprobarPNLitio(campo, auxCampo, parent_id);
                        this.comprobarPNLitio(auxCampo, auxCampo.name+'_depending_field'+parent_id, valorCampo);
                        
                    }

                    if(this.firmware != undefined && auxCampo.name === "dispositivo_reprogramacion_version_firmware_dep"){
                        this.formCampos.get(auxCampo.name+'_depending_field'+parent_id).setValue(this.firmware);
                    }

                    if(this.checksum != undefined && auxCampo.name === "dispositivo_reprogrmacion_checksum_dep"){
                        this.formCampos.get(auxCampo.name+'_depending_field'+parent_id).setValue(this.checksum);
                    }


                    //FORM DISPOSITIVO SMARTHUB

                    if (campo.name === 'smarthub_reparacion_numserie_bateria' && auxCampo.name === 'smarthub_reparacion_garantia_bateria') {

                        this.comprobarGarantia(campo, auxCampo, parent_id);
                        
                    }

                    if(this.firmware != undefined && auxCampo.name === "smarthub_reprogramacion_firmware"){
                        this.formCampos.get(auxCampo.name+'_depending_field'+parent_id).setValue(this.firmware);
                    }

                    //FORM MOVIL

                    if(this.firmware != undefined && auxCampo.name === "movil_reprogramacion_firmware"){
                        this.formCampos.get(auxCampo.name+'_depending_field'+parent_id).setValue(this.firmware);
                    }

                    //if(campo.name === 'dispositivo_reprogramacion_bateria_litio_dep' || campo.name === 'dispositiv_reprogramacion_checksum_dep'){
                    if(campo.name === 'dispositivo_reprogramacion_bateria_litio_dep'){
                        this.comprobarPN(auxCampo, auxCampo.name+'_depending_field'+parent_id, valorCampo);
                    }


                    //FORM TELEVIDA
                    if (campo.name === 'televida_reparacion_numserie_bateria' && auxCampo.name === 'televida_reparacion_garantia_bateria') {

                        this.comprobarGarantiaTelevida(campo, auxCampo, parent_id);
                        
                    }

                    if (campo.name === 'televida_reparacion_tipo_bateria' && auxCampo.name === 'televida_reparacion_cambiar_bateria_litio') {

                       // this.comprobarPNLitio(campo, auxCampo, parent_id);
                        this.comprobarPNLitio(auxCampo, auxCampo.name+'_depending_field'+parent_id, valorCampo);
                        
                    }

                    if(this.firmware != undefined && auxCampo.name === "televida_reprogramacion_version_firmware_dep"){
                        this.formCampos.get(auxCampo.name+'_depending_field'+parent_id).setValue(this.firmware);
                    }

                    if(this.checksum != undefined && auxCampo.name === "televida_reprogrmacion_checksum_dep"){
                        this.formCampos.get(auxCampo.name+'_depending_field'+parent_id).setValue(this.checksum);
                    }

                    //FORM CRUZ ROJA

                    if(this.firmware != undefined && auxCampo.name === "cruzroja_reprogramacion_version_firmware_dep"){
                        this.formCampos.get(auxCampo.name+'_depending_field'+parent_id).setValue(this.firmware);
                    }

                    if(this.checksum != undefined && auxCampo.name === "cruzroja_reprogrmacion_checksum_dep"){
                        this.formCampos.get(auxCampo.name+'_depending_field'+parent_id).setValue(this.checksum);
                    }


                }else{
                    if(this.formCampos.contains(auxCampo.name+'_depending_field'+parent_id)){//formulario contiene
                        if (auxCampo.tipoCampo.type_field == 'checklist') {
                            this.valoresCheck[auxCampo.name+'_depending_field'+parent_id] = [];
                        }

                        if (this.valoresCheck[auxCampo.name+'_depending_field'+parent_id]) {
                            const index = this.valoresCheck[auxCampo.name+'_depending_field'+parent_id].indexOf(this.formCampos.get(auxCampo.name+'_depending_field'+parent_id).value);
                            if (index > -1) {
                                this.valoresCheck[auxCampo.name+'_depending_field'+parent_id].splice(index, 1);
                            }
                        }

                       
                        // if(campo.name === '"dispositivo_reparacion_medicion_sin_carga"' && auxCampo.name === 'dispositivo_reparacion_garantia_bateria'){
                        //     console.log('Validacion voltaje',valorCampo);
                        //     this.validacionCarga(campo,auxCampo,parent_id);
                        // }

                        this.formCampos.removeControl(auxCampo.name+'_depending_field'+parent_id);
                    }
                }
            }
        }

        console.log('entra campo childchanged', campo.name);

        if(campo.name === 'smarthub_reprogramacion_icc'){
            this.comprobarTarjetaICC(campo,parent_id);
        }

        if(campo.name === "dispositivo_reparacion_medicion_sin_carga"){
            this.validacionCarga(campo, parent_id);
        }

        if(campo.name === "smarthub_reparacion_medicion_sin_carga"){
            this.validacionCarga(campo, parent_id);
        }

        if(campo.name === "televida_reparacion_medicion_sin_carga"){
            this.validacionCarga(campo, parent_id);
        }

        // if(campo.hiden && campo.hiden.length > 0){
        //     let valorCampo = this.formCampos.get(campo.name+'_depending_field'+parent_id).value;

        //     if(valorCampo && !Array.isArray(valorCampo)){
        //         valorCampo = [valorCampo];
        //     }

        //     for(var i = 0; i < campo.hiden.length; i++){
        //         const hidde = campo.hiden[i];

        //         const valoresActivan = hidde.value_hide?hidde.value_hide.split(','):[];

        //         const nombreCampo = hidde.fieldHide?hidde.fieldHide.name:(hidde.fieldDependingHide?hidde.fieldDependingHide.name+'_depending_field'+campo.ID:null);
                
        //         if(nombreCampo){
        //             if((valorCampo.some(r => valoresActivan.includes(r)) || (valoresActivan == 'any' && valorCampo.length > 0))){
        //                 this.hiddenFields.push(nombreCampo);
        //                 this.formCampos.controls[nombreCampo].disable();
        //             }else{
        //                 if(this.hiddenFields.includes(nombreCampo)){
        //                     let borrado = false;

        //                     for(var e = 0; e < this.hiddenFields.length && !borrado; e++){
        //                         if(this.hiddenFields[e] == nombreCampo){
        //                             this.hiddenFields.splice(e,1);
        //                             borrado = true;
        //                         }
        //                     }

        //                     if(!this.hiddenFields.includes(nombreCampo)){
        //                         this.formCampos.controls[nombreCampo].enable();
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    verificacionChange(datos, respuesta){
        if(this.formCampos.controls[datos.nombre]){
            this.formCampos.get(datos.nombre).setValue(respuesta.value);
            
            if(datos.depending == true){
                this.childChanged(datos.parent, datos.campo_id);
            }else{
                this.campoChanged(datos.parent);
            }
        }
    }

    comprobarGarantia(campo: any, childCampo: any, parentId) {
        let numserie = this.formCampos.controls[campo.name + '_depending_field' + parentId].value;
        let tipoBateria;

        // FORM DISPOSITIVO
        if (this.formCampos.contains('dispositivo_reparacion_tipo_bateria' + '_depending_field' + parentId)) {
            tipoBateria = this.formCampos.controls['dispositivo_reparacion_tipo_bateria' + '_depending_field' + parentId].value;
        }

        // FORM DISPOSITIVO SMARTHUB
        if (this.formCampos.contains('smarthub_reparacion_tipo_bateria' + '_depending_field' + parentId)) {
            tipoBateria = this.formCampos.controls['smarthub_reparacion_tipo_bateria' + '_depending_field' + parentId].value;
        }

        console.log('Número de serie bateria',numserie);
        console.log('Tipo de Batería',tipoBateria);

        let enGarantia;
        if(numserie && numserie != undefined && numserie != "" && tipoBateria) {
            let InicioGarantia;
            // 0317-08584
            // let semanaanio = numserie.split("-");
            let semanaanio = numserie.substring(0, 4);

            if(semanaanio.length == 4){        
                const fechaBateria = moment(semanaanio,"wwYY");

                if(tipoBateria == 1){ //litio
                    const fechaInicioGarantia = moment().subtract(7, 'years').format('wwYY');
                    InicioGarantia = moment(fechaInicioGarantia,'wwYY');
                }else{ //Normal
                    const fechaInicioGarantia = moment().subtract(5, 'years').format('wwYY');
                    InicioGarantia = moment(fechaInicioGarantia,'wwYY');
                }
           
                if(InicioGarantia <= fechaBateria){
                    console.log('En garantía');
                    enGarantia = true;
                }else{
                    console.log('Fuera de garantia');
                    enGarantia = false;
                }
            }
        }

        if (enGarantia !== null) {
            let dataCampoChild;
            //console.log("Campos", this.campos);

            if (this.campos) {
                for (const grupo of this.campos) {                
                    const campoParent = grupo.camposContrato.find((item) => { return item.ID === parentId; });                    
                    if (campoParent && campoParent.depending) {                    
                        dataCampoChild = campoParent.depending.find((item) => { return item.ID === childCampo.ID; });                    
                        break;                    
                    }                
                }                
            }

            if (dataCampoChild && this.formCampos.contains(dataCampoChild.name + '_depending_field' + parentId)) {
                const fieldCtrl = this.formCampos.get(dataCampoChild.name + '_depending_field' + parentId);
                if (fieldCtrl) {
                    fieldCtrl.setValue(enGarantia === true ? "1" : "0");
                }

                // this.formCampos.controls[dataCampoChild.name + '_depending_field' + parentId].setValue(enGarantia === true ? 1 : 0);

                setTimeout(() => {
                    this.childChanged(dataCampoChild, parentId);
                }, 100);
            }
        }
    }

    comprobarGarantiaTelevida(campo: any, childCampo: any, parentId) {
        let numserie = this.formCampos.controls[campo.name + '_depending_field' + parentId].value;
        let tipoBateria;

        // FORM TELEVIDA
        if (this.formCampos.contains('televida_reparacion_tipo_bateria' + '_depending_field' + parentId)) {
            tipoBateria = this.formCampos.controls['televida_reparacion_tipo_bateria' + '_depending_field' + parentId].value;
        }

        console.log('Número de serie bateria',numserie);
        console.log('Tipo de Batería',tipoBateria);

        let enGarantia;
        if(numserie && numserie != undefined && numserie != "" && tipoBateria) {
            let InicioGarantia;
            // 0317-08584
            // let semanaanio = numserie.split("-");
            let semanaanio = numserie.substring(0, 4);

            if(semanaanio.length == 4){        
                const fechaBateria = moment(semanaanio,"wwYY");

                if(tipoBateria == 1){ //litio
                    const fechaInicioGarantia = moment().subtract(7, 'years').format('wwYY');
                    InicioGarantia = moment(fechaInicioGarantia,'wwYY');
                }else{ //Normal
                    const fechaInicioGarantia = moment().subtract(4, 'years').format('wwYY');
                    InicioGarantia = moment(fechaInicioGarantia,'wwYY');
                }
           
                if(InicioGarantia <= fechaBateria){
                    console.log('En garantía');
                    enGarantia = true;
                }else{
                    console.log('Fuera de garantia');
                    enGarantia = false;
                }
            }
        }

        if (enGarantia !== null) {
            let dataCampoChild;
            //console.log("Campos", this.campos);

            if (this.campos) {
                for (const grupo of this.campos) {                
                    const campoParent = grupo.camposContrato.find((item) => { return item.ID === parentId; });                    
                    if (campoParent && campoParent.depending) {                    
                        dataCampoChild = campoParent.depending.find((item) => { return item.ID === childCampo.ID; });                    
                        break;                    
                    }                
                }                
            }

            if (dataCampoChild && this.formCampos.contains(dataCampoChild.name + '_depending_field' + parentId)) {
                const fieldCtrl = this.formCampos.get(dataCampoChild.name + '_depending_field' + parentId);
                if (fieldCtrl) {
                    fieldCtrl.setValue(enGarantia === true ? "1" : "0");
                }

                // this.formCampos.controls[dataCampoChild.name + '_depending_field' + parentId].setValue(enGarantia === true ? 1 : 0);

                setTimeout(() => {
                    this.childChanged(dataCampoChild, parentId);
                }, 100);
            }
        }
    }



     comprobarPN(childCampo: any, nombreCampo: string, valorCampo: any){
        if (valorCampo == 1 || valorCampo == '1') {
            if (this.ticket && this.ticket.pn_unei_superior == true) {
                this.checksum = '5CAA';
                this.firmware = '309V0R7_50';
            } else {
                this.checksum = '0062';
                this.firmware = '309V0R7_60';
            }

 

            switch(childCampo.name){
                case 'dispositivo_reprogramacion_version_firmware_dep':
                    this.formCampos.get(nombreCampo).setValue(this.firmware);
                    break;
                case 'dispositivo_reprogrmacion_checksum_dep':
                    this.formCampos.get(nombreCampo).setValue(this.checksum);
                break;
            }
        } else {
            this.checksum = '5C7A'
            this.firmware = '309V0R7.38'
            switch(childCampo.name){
                case 'dispositivo_reprogramacion_version_firmware_dep':
                    this.formCampos.get(nombreCampo).setValue(this.firmware);
                    break;
                case 'dispositivo_reprogrmacion_checksum_dep':
                    this.formCampos.get(nombreCampo).setValue(this.checksum);
                break;
            }
        }
    }



    comprobarPNLitio(childCampo: any, nombreCampo: string, valorCampo: any){
        if((valorCampo == 1 || valorCampo == '1') && this.ticket && this.ticket.pn_unei_superior == true){
          //  console.log('No eliminar cambiar bateria', nombreCampo );
        }else{        

          //  console.log('eliminar cambiar bateria', nombreCampo );
            this.formCampos.removeControl(nombreCampo);
        }
    }

    comprobarTarjetaICC(campo: any, parentId: any) {


        console.log('Campo comprobar tarjeta icc', campo);
        let iccTarjeta;
        if (campo && campo.name === 'smarthub_reprogramacion_icc'){
            iccTarjeta = this.formCampos.controls['smarthub_reprogramacion_icc_depending_field' + parentId].value;
        }

        if (campo && campo.name === 'gsm_preparacion_icc_tarjeta_453'){

            iccTarjeta = this.formCampos.controls['gsm_preparacion_icc_tarjeta_453'].value;
         //   iccTarjeta = this.formCampos.get('gsm_preparacion_icc_tarjeta').value;
        } 
        
      //  iccTarjeta = this.formCampos.controls['gsm_preparacion_icc_tarjeta'].value;
        //let iccTarjet = campo.value;
        
        let id_almacen = 403; //Almacen Unei

        this.almacenService.getSerialByPN(iccTarjeta,id_almacen).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (data: any) => {
                console.log('Resultado',data);
                if(data[0]){
                    console.log(data);
                    if( this.formCampos.get('smarthub_reprogramacion_telefono_icc_depending_field' + parentId)){
                        this.formCampos.get('smarthub_reprogramacion_telefono_icc_depending_field' + parentId).setValue(data[0].serial);
                    }
                    if(this.formCampos.get('gsm_preparacion_num_telefono_454')){
                        this.formCampos.get('gsm_preparacion_num_telefono_454').setValue(data[0].serial);
                    }
                    
                }else{
                    this.showSnack("El icc introducido no tiene telefono asociado disponible");
                }
               
            },
            (err) => {
                this.showSnack(err);
            }
        );
       
    }
    validacionCarga(campo: any, parentId: any) {

        let cambiarBateria = false;

        let tipoBateria;
        
        if(this.data.ticket){
            if(this.data.ticket.FK_entities == 691){ //Assda Dispositivo
                //let medicionCarga = Number(this.formCampos.controls['dispositivo_reparacion_medicion_carga_depending_field' + parentId].value);
                //let medicionSinCarga =  Number(this.formCampos.controls['dispositivo_reparacion_medicion_sin_carga_depending_field' + parentId].value);

                let medicionCarga = parseFloat((this.formCampos.controls['dispositivo_reparacion_medicion_carga_depending_field' + parentId].value).replace(/,/g, '.'));
                let medicionSinCarga =  parseFloat((this.formCampos.controls['dispositivo_reparacion_medicion_sin_carga_depending_field' + parentId].value).replace(/,/g, '.'));

                if (this.formCampos.contains('dispositivo_reparacion_tipo_bateria' + '_depending_field' + parentId)) {
                    tipoBateria = this.formCampos.controls['dispositivo_reparacion_tipo_bateria' + '_depending_field' + parentId].value;
                }
            
                console.log('Medición con carga',medicionCarga);
                console.log('Medicion SIN carga',medicionSinCarga);      

                if(tipoBateria == '0'){ //Normal
                    if(medicionCarga > 8.6 || medicionSinCarga < 6 || medicionCarga - medicionSinCarga > 0.3) {
                        cambiarBateria = true
                    }
                }else if (tipoBateria == '1'){ //Litio
                    if(medicionCarga > 5 || medicionSinCarga < 3 || medicionCarga - medicionSinCarga > 0.5) {
                        cambiarBateria = true
                    }
                }
                console.log('Valido',cambiarBateria);
                
            }else if(this.data.ticket.FK_entities == 709){ //Dispositivo SmartHub

                    //Batería Normal Assda Dispositivo
    
                    /// CAMABIAR NOMBRE DE CAMPOS ////
                    let medicionCarga = parseFloat((this.formCampos.controls['smarthub_reparacion_medicion_carga_depending_field' + parentId].value).replace(/,/g, '.'));
                    let medicionSinCarga =  parseFloat((this.formCampos.controls['smarthub_reparacion_medicion_sin_carga_depending_field' + parentId].value).replace(/,/g, '.'));
    
    
                    if (this.formCampos.contains('smarthub_reparacion_tipo_bateria' + '_depending_field' + campo.ID)) {
                        tipoBateria = this.formCampos.controls['smarthub_reparacion_tipo_bateria' + '_depending_field' + campo.ID].value;
                    }
                
                    console.log('Medición con carga',medicionCarga);
                    console.log('Medicion SIN carga',medicionSinCarga);      
    
                    if(tipoBateria == '0'){ //Normal
                        if(medicionCarga > 8.6 || medicionSinCarga < 6 || medicionCarga - medicionSinCarga > 0.3) {
                            cambiarBateria = true
                        }
                    }else if (tipoBateria == '1'){ //Litio
                        if(medicionCarga > 5 || medicionSinCarga < 3 || medicionCarga - medicionSinCarga > 0.5) {
                            cambiarBateria = true
                        }
                    }
    
                    console.log('Valido',cambiarBateria);
                }else if(this.data.ticket.FK_entities == 736){ //Televida
                    //let medicionCarga = Number(this.formCampos.controls['dispositivo_reparacion_medicion_carga_depending_field' + parentId].value);
                    //let medicionSinCarga =  Number(this.formCampos.controls['dispositivo_reparacion_medicion_sin_carga_depending_field' + parentId].value);
    
                    let medicionCarga = parseFloat((this.formCampos.controls['televida_reparacion_medicion_carga_depending_field' + parentId].value).replace(/,/g, '.'));
                    let medicionSinCarga =  parseFloat((this.formCampos.controls['televida_reparacion_medicion_sin_carga_depending_field' + parentId].value).replace(/,/g, '.'));
    
                    if (this.formCampos.contains('televida_reparacion_tipo_bateria' + '_depending_field' + parentId)) {
                        tipoBateria = this.formCampos.controls['televida_reparacion_tipo_bateria' + '_depending_field' + parentId].value;
                    }
                
                    console.log('Medición con carga',medicionCarga);
                    console.log('Medicion SIN carga',medicionSinCarga);      
    
                    if(tipoBateria == '0'){ //Normal
                        if(medicionCarga > 8.6 || medicionSinCarga < 6 || medicionCarga - medicionSinCarga > 0.3) {
                            cambiarBateria = true
                        }
                    }else if (tipoBateria == '1'){ //Litio
                        if(medicionCarga > 5 || medicionSinCarga < 3 || medicionCarga - medicionSinCarga > 0.5) {
                            cambiarBateria = true
                        }
                    }
                    console.log('Valido',cambiarBateria);
                    
                }
            // else if (this.data.ticket.FK_entities == 690){ //Assda Movil

            //     //Batería Normal Assda Dispositivo
            //     /// CAMABIAR NOMBRE DE CAMPOS ////

            //     let medicionCarga = parseFloat(this.formCampos.controls['dispositivo_reparacion_medicion_carga_depending_field' + parentId].value);
            //     let medicionSinCarga =  parseFloat(this.formCampos.controls['dispositivo_reparacion_medicion_sin_carga_depending_field' + parentId].value);

            //     if (this.formCampos.contains('dispositivo_reparacion_tipo_bateria' + '_depending_field' + parentId)) {
            //         tipoBateria = this.formCampos.controls['dispositivo_reparacion_tipo_bateria' + '_depending_field' + parentId].value;
            //     }
            
            //     console.log('Medición con carga',medicionCarga);
            //     console.log('Medicion SIN carga',medicionSinCarga);      

            //     if(tipoBateria == '0'){ //Normal
            //         if(medicionCarga < 8.6 && medicionSinCarga > 6) {
            //             if(medicionCarga - medicionSinCarga > 0.3)
            //                 buenEstadoVisual = false
            //         }else{
            //             buenEstadoVisual = false
            //         }
            //     }else if (tipoBateria == '1'){ //Litio
            //         if(medicionCarga < 4.2 && medicionSinCarga > 3) {
            //             if(medicionCarga - medicionSinCarga > 0.3)
            //                 buenEstadoVisual = false
            //         }else{
            //             buenEstadoVisual = false
            //         }
            //     }

            // }else if(this.data.ticket.FK_entities == 709){ //Dispositivo SmartHub

            //     //Batería Normal Assda Dispositivo

            //     /// CAMABIAR NOMBRE DE CAMPOS ////
            //     let medicionCarga = parseFloat(this.formCampos.controls[campo.name + '_depending_field' + campo.ID].value);
            //     let medicionSinCarga =  parseFloat(this.formCampos.controls[campo.name + '_depending_field' + campo.ID].value);

            //     if (this.formCampos.contains('dispositivo_reparacion_tipo_bateria' + '_depending_field' + campo.ID)) {
            //         tipoBateria = this.formCampos.controls['dispositivo_reparacion_tipo_bateria' + '_depending_field' + campo.ID].value;
            //     }
            
            //     console.log('Medición con carga',medicionCarga);
            //     console.log('Medicion SIN carga',medicionSinCarga);      

            //     if(tipoBateria == '0'){ //Normal
            //         if(medicionCarga < 8.6 && medicionSinCarga > 6) {
            //             if(medicionCarga - medicionSinCarga > 0.3)
            //                 buenEstadoVisual = false
            //         }else{
            //             buenEstadoVisual = false
            //         }
            //     }else if (tipoBateria == '1'){ //Litio
            //         if(medicionCarga < 4.2 && medicionSinCarga > 3) {
            //             if(medicionCarga - medicionSinCarga > 0.3)
            //                 buenEstadoVisual = false
            //         }else{
            //             buenEstadoVisual = false
            //         }
            //     }

            // }

        }

        let dataCampoChild;

        if (this.campos) {
            for (const grupo of this.campos) {
                const campoParent = grupo.camposContrato.find((item) => { return item.ID === parentId; });
                if (campoParent && campoParent.depending) {
                    //dataCampoChild = campoParent.depending.find((item) => { return item.name == "dispositivo_reparacion_buen_estado_vsual"; });  
                    dataCampoChild = campoParent.depending.find((item) => { return item.name == "dispositivo_reparacion_cambiar_bateria"; });
                    dataCampoChild = campoParent.depending.find((item) => { return item.name == "televida_reparacion_cambiar_bateria"; }); 
                    dataCampoChild = campoParent.depending.find((item) => { return item.name == "smarthub_reparacion_cambiar_bateria"; });                 
                    break;
                }
            }
        }

        if (dataCampoChild && this.formCampos.contains(dataCampoChild.name + '_depending_field' + parentId)) {
            const fieldCtrl = this.formCampos.get(dataCampoChild.name + '_depending_field' + parentId);
            if (fieldCtrl) {
                //fieldCtrl.setValue(buenEstadoVisual === true ? "1" : "0");
                fieldCtrl.setValue(cambiarBateria === true ? "1" : "0");
            }

            setTimeout(() => {
                this.childChanged(dataCampoChild, parentId);
            }, 100);
        }
    }

    validacionDatos(numserie: any, tipoBateria : any){       
        
 
      //  return new Promise(async resolve => {
            if(numserie && numserie != undefined && numserie != "" && tipoBateria){
                let InicioGarantia;
    
                // 0317-08584
            //    let semanaanio = numserie.split("-");
                let semanaanio = numserie.substring(0, 4);
    
                if(semanaanio.length == 4){
                        
                    const fechaBateria = moment(semanaanio,"wwYY");

                    if(tipoBateria == 1){ //litio
                        const fechaInicioGarantia = moment().subtract(7, 'years').format('wwYY');
                        InicioGarantia = moment(fechaInicioGarantia,'wwYY');
                    }else{ //Normal
                        const fechaInicioGarantia = moment().subtract(5, 'years').format('wwYY');
                        InicioGarantia = moment(fechaInicioGarantia,'wwYY');
                    }
                
                    if(InicioGarantia <= fechaBateria){
                        this.enGarantia = true;
                    }else{
                        this.enGarantia = false;
                    }
                        
                }
    
            }
            
           // resolve(true);
         // });
    }

    checkCampoRojo(nombre_campo){
        if((nombre_campo.includes('dispositivo_reparacion_garantia_bateria') || nombre_campo.includes('smarthub_dispositivo_reparacion_garantia_bateria')) && this.formCampos.contains(nombre_campo) && this.formCampos.get(nombre_campo).value.toString() == '0'){
            return true;
        }else{
            return false;
        }
    }

    

}
