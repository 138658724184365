<form [formGroup]="personaForm" (submit)="submit()">
    <h2 mat-dialog-title>Nueva Persona</h2>
    <div mat-dialog-content>
        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="40%">
                <mat-form-field class="form-field">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="nombre" required>
                    <mat-error *ngIf="personaForm.controls.nombre.touched && personaForm.controls.nombre.invalid">
                        <span *ngIf="personaForm.controls.nombre.errors.required">Este campo es obligatorio</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Segundo nombre</mat-label>
                    <input matInput formControlName="segundo_nombre">
                </mat-form-field>
            </div>

            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Apellidos</mat-label>
                    <input matInput formControlName="apellido">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="33%">
                <mat-form-field class="form-field">
                    <mat-label>Prefijo</mat-label>
                    <input matInput formControlName="prefijo">
                </mat-form-field>
            </div>
            
            <div fxFlex="33%">
                <mat-form-field class="form-field">
                    <mat-label>Sufijo</mat-label>
                    <input matInput formControlName="sufijo">
                </mat-form-field>
            </div>

            <div fxFlex="34%">
                <mat-form-field class="form-field">
                    <mat-label>Teléfono</mat-label>
                    <input matInput formControlName="telefono">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Empresa</mat-label>
                    <mat-select formControlName="fk_empresa">
                        <ng-container *ngFor="let empresa of empresas | async">
                            <mat-option value="{{empresa.id}}" ng-value="empresa.id">{{empresa.nombre}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="70%">
                <mat-form-field class="form-field">
                    <mat-label>Titulo</mat-label>
                    <input matInput formControlName="sufijo">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="40%">
                <mat-form-field class="form-field">
                    <mat-label>Contacto</mat-label>
                    <mat-select [value]="'cyg'">
                        <mat-option value="cyg" selected>C&G</mat-option>
                        <mat-option value="feedbalia">Feedbalia</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Correo</mat-label>
                    <input matInput formControlName="correo">
                </mat-form-field>
            </div>

            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Linkedin</mat-label>
                    <input matInput formControlName="linkedin">
                </mat-form-field>
            </div>

        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Web</mat-label>
                    <input matInput formControlName="web">
                </mat-form-field>
            </div>

        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="100%">
                <mat-form-field class="form-field">
                    <mat-label>Dirección</mat-label>
                    <input matInput formControlName="sufijo">
                </mat-form-field>
            </div>
        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Localidad</mat-label>
                    <input matInput formControlName="sufijo">
                </mat-form-field>
            </div>

            <div fxFlex="30%">
                <mat-form-field class="form-field">
                    <mat-label>Provincia</mat-label>
                    <input matInput formControlName="sufijo">
                </mat-form-field>
            </div>

            <div fxFlex="20%">
                <mat-form-field class="form-field">
                    <mat-label>Pais</mat-label>
                    <input matInput formControlName="sufijo">
                </mat-form-field>
            </div>

            <div fxFlex="20%">
                <mat-form-field class="form-field">
                    <mat-label>Código postal</mat-label>
                    <input matInput formControlName="sufijo">
                </mat-form-field>
            </div>

        </div>

        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="100%">
                <mat-form-field class="form-field">
                    <mat-label>Descripción</mat-label>
                    <textarea formControlName="descripcion" matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-flat-button [mat-dialog-close]="true" type="button">Cancelar</button>
        <button *ngIf="buttonsEnable" mat-raised-button color="primary" type="submit">Guardar</button>
        <button *ngIf="!buttonsEnable" mat-raised-button color="primary" type="button">Guardar</button>
    </div>            
</form>