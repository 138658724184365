<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 *ngIf="!cita" class="m-0 mb-8">Nueva cita</h2>
    <h2 *ngIf="cita" class="m-0 mb-8">Editar cita</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography" style="width: 60vw">
    <div fxLayout="column">

        <form fxLayout="column" [formGroup]="formNuevaCita">
            <div fxLayout="row wrap" class="">
                <mat-form-field appearance="standard" class="form-group w-40-p mr-16">
                    <mat-label>Ticket<span class="required">*</span></mat-label>
                    <input matInput formControlName="fk_tracking" maxlength="11">
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group w-40-p mr-16">
                    <mat-label>Tipo Cita<span class="required">*</span></mat-label>
                    <mat-select name="tipoCita" formControlName="tipoCita" class="form-control">
                        <mat-option *ngFor="let item of tiposCita" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('tipoCita')" mat-button matSuffix mat-icon-button
                        (click)="clearSelect($event, 'tipoCita')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group w-50-p mr-16">
                    <mat-label>Fecha<span class="required">*</span></mat-label>
                    <input matInput type="datetime-local" name="fechaCita" formControlName="fechaCita"
                        class="form-control" autocomplete="off">
                </mat-form-field>
                
                <div>
                    <mat-label>&nbsp;</mat-label><br>
                    <mat-slide-toggle formControlName="fechaPreferente" [checked]="activeFechaPreferente">Cita preferente</mat-slide-toggle>
                </div>
            </div>

            <div *ngIf="cita">
                <div fxLayout="row" class="mb-12">
                    <mat-form-field appearance="standard" class="form-group w-95-p mr-16">
                        <mat-label>Estado Cita</mat-label>
                        <mat-select name="fk_estado_cita" formControlName="fk_estado_cita" class="form-control">
                            <mat-option *ngFor="let item of estadosCita" [value]="item.id"
                                [disabled]="checkDisableEstadoCita(item)">{{item.name}}</mat-option>
                        </mat-select>
                        <button *ngIf="checkValue('fk_estado_cita')" mat-button matSuffix mat-icon-button
                            (click)="clearSelect($event, 'fk_estado_cita')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div fxLayout="row" class="mb-12">
                    <mat-form-field *ngIf="showCampoCita('checkout_ok')" appearance="standard"
                        class="form-group w-20-p mr-16">
                        <mat-label>Reparado</mat-label>
                        <mat-select name="checkout_ok" formControlName="checkout_ok" class="form-control">
                            <mat-option *ngFor="let item of comboSiNo" [value]="item.id">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="showCampoCita('fk_motivo_estado')" appearance="standard"
                        class="form-group w-70-p mr-16" [ngClass]="{'w-95-p' : !showCampoCita('checkout_ok')}">
                        <mat-label>Motivo estado</mat-label>
                        <mat-select name="fk_motivo_estado" formControlName="fk_motivo_estado" class="form-control">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="motivoEstadoFilterCtrl"
                                    placeholderLabel="Selecciona un motivo"
                                    noEntriesFoundLabel="No se encontró ningún valor"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of motivosEstadosFiltered | async" [value]="item.id">
                                {{item.name}}</mat-option>
                        </mat-select>
                        <button *ngIf="checkValue('fk_motivo_estado')" mat-button matSuffix mat-icon-button
                            (click)="clearSelect($event, 'fk_motivo_estado')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div fxLayout="row" class="mb-12">
                    <mat-form-field *ngIf="showCampoCita('observaciones_estado')" appearance="standard"
                        class="form-group w-95-p mr-16">
                        <mat-label>Observaciones estado</mat-label>
                        <textarea matInput name="observaciones_estado" formControlName="observaciones_estado"
                            class="form-control" style="height: 80px"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row wrap" class="mb-12">
                <mat-form-field appearance="standard" class="form-group w-30-p mr-16">
                    <mat-label>Tipo Dirección<span class="required">*</span></mat-label>
                    <mat-select name="tipoDireccionCita" formControlName="tipoDireccionCita" class="form-control">
                        <mat-option *ngFor="let item of tiposDireccionCita" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('tipoDireccionCita')" mat-button matSuffix mat-icon-button
                        (click)="clearSelect($event, 'tipoDireccionCita')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field appearance="standard" class="form-group w-60-p mr-16">
                    <mat-label>Dirección<span class="required">*</span></mat-label>
                    <mat-select name="direccionCita" formControlName="direccionCita" class="form-control" [disabled]="this.formNuevaCita.get('fk_tracking').value.trim() == ''">
                        <mat-option *ngFor="let item of direccionesCita" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('direccionCita')" mat-button matSuffix mat-icon-button
                        (click)="clearSelect($event, 'direccionCita')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div fxLayout="row wrap" class="mb-12">
                <mat-form-field appearance="standard" class="form-group w-45-p mr-16">
                    <mat-label>Técnico<span class="required">*</span></mat-label>
                    <mat-select name="tecnicoCita" formControlName="tecnicoCita" class="form-control">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="tecnicoCitaFilterCtrl"
                                [searching]="searchingTecnicosCita" placeholderLabel="Buscar Técnico..."
                                noEntriesFoundLabel="No se encontró ningún técnico"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let itemTecnico of tecnicosCita" [value]="itemTecnico.id">
                            <div fxLayout="row" fxLayoutAlign="flex-start center"
                                style="align-items: center; white-space: nowrap;">
                                <div class="image-user sel-option">
                                    <img [src]="imageSvc.getImageUser(itemTecnico.image_user)" />
                                </div>
                                <label style="overflow: hidden; text-overflow: ellipsis;">
                                    {{'@'+itemTecnico.username + ' - ' + itemTecnico.nombre + ' ' +
                                    itemTecnico.apellidos}}
                                </label>
                            </div>
                        </mat-option>
                    </mat-select>
                    <button *ngIf="checkValue('tecnicoCita')" mat-button matSuffix mat-icon-button
                        (click)="clearSelect($event, 'tecnicoCita')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field appearance="standard" class="form-group w-45-p mr-16">
                    <mat-label>Ayudantes</mat-label>
                    <mat-select name="tecnicosAyudantes" formControlName="tecnicosAyudantes"
                        class="form-control chip-list" [multiple]="true">
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip *ngFor="let tecAyudante of tecnicosAyudantesSelected" [removable]="true"
                                    (removed)="deselectTecnicoAyudante(tecAyudante)"
                                    matTooltip="{{'@'+tecAyudante.username + ' - ' + tecAyudante.nombre + ' ' + tecAyudante.apellidos}}">
                                    <mat-icon matChipRemove style="min-width: 18px !important; margin-right: 4px;">
                                        cancel</mat-icon>
                                    {{ '@'+tecAyudante.username + ' - ' + tecAyudante.nombre + ' ' +
                                    tecAyudante.apellidos }}
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search [formControl]="tecnicoAyudanteFilterCtrl"
                                [searching]="searchingTecnicosCita" placeholderLabel="Buscar técnico ..."
                                noEntriesFoundLabel="No se encontró ningún técnico"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let itemTecnico of tecnicosAyudantes" [value]="itemTecnico.id">
                            <div fxLayout="row" fxLayoutAlign="flex-start center" style="align-items: center;">
                                <div class="image-user sel-option">
                                    <img [src]="imageSvc.getImageUser(itemTecnico.image_user)" />
                                </div>
                                <label style="overflow: hidden; text-overflow: ellipsis;">
                                    {{'@'+itemTecnico.username + ' - ' + itemTecnico.nombre + ' ' +
                                    itemTecnico.apellidos}}
                                </label>
                            </div>
                        </mat-option>
                    </mat-select>
                    <button *ngIf="tecnicosAyudantesSelected?.length > 0" class="btn-close" mat-button matSuffix
                        mat-icon-button (click)="clearSelect($event, 'tecnicosAyudantes')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

            </div>

            <div fxLayout="row wrap" class="mb-12">
                <mat-form-field appearance="standard" class="form-group w-95-p mr-16">
                    <mat-label>Comentarios</mat-label>
                    <textarea matInput name="comentarioCita" formControlName="comentarioCita" class="form-control"
                        style="height: 80px"></textarea>
                </mat-form-field>
            </div>

        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-button class="btn-aitana" [class.spinner]="loadingSave" [disabled]="loadingSave || !formNuevaCita.valid" (click)="guardarCita()">Guardar</button>
</mat-dialog-actions>