<div id="tiempos" class="page-layout simple fullwidth px-12 pt-12">

     <div class="wrap-table" fxLayout="column" fxFlex="1 0 auto" >
         <div class="table-container" fxFlex="1 0 auto">
            <table mat-table [dataSource]="datos" class="w-100-p">
               <ng-container *ngFor="let col of columnas" [matColumnDef]="col">
                  <th mat-header-cell *matHeaderCellDef> {{col == "columna1"?'':col}} </th>
                  
                  <td  mat-cell *matCellDef="let row">
                     <div *ngIf="col == 'columna1'">{{row.estado2}}</div>
                     <!-- <div *ngIf="row.tiempo.estado1 == col && row.tiempo.estado1  !=''">{{row.tiempo.tiempo}}</div>  -->
                     <!-- <div>{{col == "columna1"?'':row.tiempo}}</div> -->
                     <div *ngIf="col!=='columna1'">{{row.info.estado1 === col ? row.info.tiempo: 0}}</div>
                  </td>
               </ng-container>

               <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
               <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

               <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No se encontraron resultados</td>
               </tr>
            </table>
         </div>

         <!-- <mat-paginator [pageSizeOptions]="[10,20,30,50,100,300]" [length]="totalElementos" [pageIndex]="0" [pageSize]="pageSize" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator> -->
    
      </div>
</div>

