<div id="selector-proyectos">

    <div class="selector-proyectos" fxLayout="row">

        <div *ngIf="showMenuProject" class="menu-projects">
            <div class="list-projects">

                <ng-container *ngIf="proyectos.length > 0">
                    <div class="title-section secondary-text">Proyectos</div>
                    <div class="proyectos">
                        <div *ngFor="let itemProject of proyectos" fxlayout="row" fxLayoutAlign="flex-start center" class="item-project" (click)="selectProject(itemProject)" >
                            <span class="secondary-text m-0 font-size-16"><b class="mr-8">{{ itemProject.id }}</b> {{ itemProject.name }}</span>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="configuracionesProyectos.length > 0">
                    <div class="title-section secondary-text">Tus Configuraciones</div>
                    <div class="configuraciones">
                        <div *ngFor="let itemConfig of configuracionesProyectos" fxlayout="row" fxLayoutAlign="flex-start center" class="item-project config" (click)="selectConfigProject(itemConfig)" >
                            <span class="secondary-text m-0 font-size-16">{{ itemConfig.nombre }}</span>
                            <ng-container *ngIf="itemConfig.usuario == usuario?.id">
                                <button mat-icon-button class="secondary-text" matTooltip="Editar configuración" (click)="openModalConfigProyectos($event, itemConfig)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button class="secondary-text" matTooltip="Eliminar configuración" (click)="openConfirmDeleteConfig($event, itemConfig)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>

                <div fxlayout="row" fxLayoutAlign="flex-start center" class="item-project add" (click)="openModalConfigProyectos($event)" >
                    <mat-icon class="secondary-text mr-12">add</mat-icon>
                    <span class="secondary-text m-0 font-size-16">Crear configuración</span>
                </div>

            </div>
        </div>

        <button *ngIf="!showMenuProject" mat-button class="projectButton secondary-text" [class.pr-16]="!projectSelected || (projectSelected && projectSelected.name === 'Proyectos por defecto')" (click)="openMenuProject()">
            <div fxLayout="row" fxLayoutAlign="flex-end center">
                <span class="mr-12">{{ projectSelected ? projectSelected.name : 'Seleccionar proyecto ...' }}</span>
                <mat-icon class="s-16">keyboard_arrow_down</mat-icon>

                <button *ngIf="projectSelected && projectSelected.name !== 'Proyectos por defecto'" mat-icon-button class="secondary-text" (click)="selectDefaultProjects($event)">
                    <mat-icon >close</mat-icon>
                </button>
            </div>
        </button>

        <div *ngIf="showMenuProject" appearance="outline" fxLayout="row" fxLayoutAlign="space-between center" class="w-400">
            <input #searchProject name="proyecto" class="search-project secondary-text" placeholder="Buscar proyecto" (input)="search($event)" autocomplete="off">
            <button *ngIf="!filtrandoProyectos" mat-icon-button class="secondary-text" (click)="closeMenuProject()">
                <mat-icon >close</mat-icon>
            </button>
            <div *ngIf="filtrandoProyectos" class="loading-filter-projects spinner"></div>
        </div>

        

        <!-- <mat-menu #projectMenu="matMenu" [overlapTrigger]="false">

            <mat-form-field appearance="outline" class="form-group">
                <mat-label>Proyectos ...</mat-label>
                <input matInput name="proyecto" class="form-control" autocomplete="off">
            </mat-form-field>

        </mat-menu> -->

    </div>

</div>