<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">{{ titulo }}</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column" style="width: 40vw">

        <ng-container *ngIf="accion == acciones.guardarFiltro">
            <form fxLayout="row wrap" fxLayoutAlign="flex-start center" #mainForm="ngForm" [formGroup]="formFiltro" class="filtros">
                <mat-form-field appearance="standard" class="form-group w-100-p">
                    <mat-label>{{ 'COMUN.NOMBRE_FILTRO' | translate }}<span class="required">*</span></mat-label>
                    <input matInput name="nombreFiltro" formControlName="nombreFiltro" class="form-control" autocomplete="off">
                </mat-form-field>
            </form>
        </ng-container>

        <div fxLayout="column">
            <mat-label class="secondary-text font-size-16 label-border">{{ 'COMUN.FILTROS_GUARDADOS' | translate }}</mat-label>

            <div *ngIf="filtrosUser.length > 0" class="listFiltros wrap-table">
                <div class="table-container">
                    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)" class="w-100-p">
                        <ng-container matColumnDef="select">
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="nombreFiltro">
                            <td mat-cell *matCellDef="let row">{{row.name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <td mat-cell *matCellDef="let row">
                                <div fxLayout="row wrap" fxLayoutAlign="center center">
                                    <button mat-icon-button [matTooltip]="'COMUN.ELIMINAR_FILTRO' | translate" (click)="openConfirmDelete($event, row)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
                    </table>
                </div>

                <div *ngIf="filterSelected" fxLayout="column" class="mt-16">
                    <mat-label class="secondary-text font-size-16 label-border">{{ 'COMUN.CONFIGURACION_FILTRO' | translate }}</mat-label>
                    <div fxLayout="column" class="config-filtro">
                        <span *ngFor="let itemConfig of configFiltroSelected">
                            {{ '- ' + (itemConfig.label | translate) + ': ' + gridConfigSvc.getValueTextFiltro(itemConfig) }}
                        </span>
                    </div>
                </div>
            </div>

            <div *ngIf="filtrosUser.length == 0">
                <span class="secondary-text">{{ 'COMUN.NO_FILTROS_GUARDADOS' | translate }}</span>
            </div>
        </div>

    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngIf="accion == acciones.guardarFiltro" mat-button class="btn-aitana" (click)="guardarDatos()">{{ 'COMUN.GUARDAR' | translate }}</button>
    <button *ngIf="accion == acciones.misFiltros" mat-button class="btn-aitana" [disabled]="!filterSelected" (click)="aplicarFiltro()">{{ 'COMUN.APLICAR_FILTRO' | translate }}</button>
    <button mat-button mat-stroked-button mat-dialog-close>{{ 'COMUN.CANCELAR' | translate }}</button>
</mat-dialog-actions>