import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HelperService } from '../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class GestionCajasService {

  constructor(private http: HttpClient, 
    private authSvc: AuthService, 
    private helperSvc: HelperService) { }

    getAll(numPage: number, pageSize: number, columnOrder: string, columnDirection: string, filtros: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
                page: numPage,
				pageSize: pageSize
			}

            if(filtros.fk_entity && filtros.fk_entity != null && filtros.fk_entity != ""){
                params['fk_entity'] = filtros.fk_entity;
            }

			

            /*if(filtros.fk_elemento && filtros.fk_elemento != null && filtros.fk_elemento != ""){
                params['fk_elemento'] = filtros.fk_elemento;
            }*/

            if(filtros.fk_tipo && filtros.fk_tipo.ID && filtros.fk_tipo.ID != null && filtros.fk_tipo.ID != ""){
                params['fk_tipo'] = filtros.fk_tipo.ID;
            }

			if(filtros.tipo_caja && filtros.tipo_caja.ID && filtros.tipo_caja.ID != null && filtros.tipo_caja.ID != ""){
                params['tipo_caja'] = filtros.tipo_caja.ID;
            }

			if(filtros.fk_provincia && filtros.fk_provincia.idprovincia && filtros.fk_provincia.idprovincia != null && filtros.fk_provincia.idprovincia != ""){
                params['fk_provincia'] = filtros.fk_provincia.idprovincia;
            }

            if(filtros.max_piezas && filtros.max_piezas != null && filtros.max_piezas != ""){
                params['max_piezas'] = filtros.max_piezas;
            }

            if(filtros.id && filtros.id != null && filtros.id != ""){
                params['id'] = filtros.id;
            }

            if (columnDirection !== "") {
				params['sortColum'] = columnOrder;
				params['sortDirection'] = columnDirection;
			}

            if (columnDirection !== "") {
				params['sortColum'] = columnOrder;
				params['sortDirection'] = columnDirection;
			}

			const url = environment.API_URL + '/gestion-cajas?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getAll", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getAll", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getForSelect(/*pn: string, */idTicket: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
                //pn: pn,
                idTicket: idTicket
			}

			const url = environment.API_URL + '/gestion-cajas/get-for-select?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getForSelect", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getForSelect", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getCajasFilter(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const url = environment.API_URL + '/gestion-cajas/get-for-select';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getCajasFilter", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getForSelect", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    getTipos(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/gestion-cajas/tipos';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTipos", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTipos", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

	getTiposSalidas(): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/gestion-cajas/tipos-salida';
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getTiposSalida", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getTiposSalida", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    /*getElementos(filtro: string): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			};

			const params = {
				filtro: filtro,
			}

			const url = environment.API_URL + '/gestion-cajas/elementos?' + this.helperSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					console.log("ws getEtiquetas", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws getEtiquetas", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}*/

    modificarGestionCaja(id, dataGestionCaja: any): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				id:id,
                datos: dataGestionCaja
			};

			const url = environment.API_URL + '/gestion-cajas/' + id;
			this.http.put(url, params, options).subscribe(
				(response: any) => {
					console.log("ws modificarGestionCaja", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws modificarGestionCaja", err);
                    let msgError = "Se ha producido un error guardando los datos";
                    if(err.error && err.error.message){
                        msgError = err.error.message;
                    }

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    deleteGestionCaja(idCaja: number): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const url = environment.API_URL + '/gestion-cajas/' + idCaja;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					console.log("ws borrarGestiónCaja", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws borrarGestiónCaja", err);
					let msgError = "Se ha producido un error eliminando la gestión";

					if (err.error.error === 'gestion_not_deleted') {
						msgError = err.error.error_description;
					}

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}

    crearGestionCaja(data): Observable<any> {
		return new Observable<any>(observer => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					'Authentication': 'Bearer ' + accessToken,
					'Content-Type': 'application/json; charset=utf-8'
				})
			}

			const params = {
				datos: data
			};

			// console.log("params", params);

			const url = environment.API_URL + '/gestion-cajas';
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					console.log("ws crearGestionCaja", response);
					observer.next(response);
				},
				(err) => {
					console.log("error ws crearGestionCaja", err);
					let msgError = "Se ha producido un error guardando los datos";

					observer.error(msgError);
				}
			);

			return {unsubscribe() {}};
		});
	}
}
