<div id="error-401" fxLayout="column" fxLayoutAlign="center center">

    <div class="content" fxLayout="column" fxLayoutAlign="center center">

        <div class="error-code">401</div>

        <div class="message">¡Lo sentimos!</div>

        <div class="sub-message">
            No dispone de permisos para acceder a esta página
        </div>

        <!-- <a class="report-link" [routerLink]="'/apps/dashboards/project'">Report this problem</a> -->

    </div>

</div>