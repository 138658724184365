import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CitasService } from 'app/services/citas/citas.service';
import { ImageService } from 'app/services/helper/image.service';
import { TecnicosService } from 'app/services/tecnicos/tecnicos.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
    selector: 'app-dialog-form-cita',
    templateUrl: './dialog-form-cita.component.html',
    styleUrls: ['./dialog-form-cita.component.scss']
})
export class DialogFormCitaComponent implements OnInit {
    private _unsubscribeAll: Subject<any> = new Subject();

    cita: any = null;
    formNuevaCita: FormGroup;
    loadingSave: boolean = false;

    tiposCita: Array<any> = [];

    tecnicosCita: Array<any> = [];
    tecnicoCitaSelected: any;
    tecnicoCitaFilterCtrl: FormControl = new FormControl();
    searchingTecnicosCita: boolean = false;

    tecnicosAyudantes: Array<any> = []
    tecnicoAyudanteFilterCtrl: FormControl = new FormControl();
    tecnicosAyudantesSelected: Array<any> = [];

    estadosCita: Array<any> = [];

    motivosEstado: Array<any> = [];
    motivoEstadoFilterCtrl: FormControl = new FormControl();
    motivosEstadosFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    tiposDireccionCita: Array<any> = [];
    direccionesCita: Array<any> = [];

    activeFechaPreferente: boolean = false;

    constructor(private formBuilder: FormBuilder, private citasSvc: CitasService, private snackBar: MatSnackBar,
        private tecnicosSvc: TecnicosService, private imageSvc: ImageService, @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DialogFormCitaComponent>,) {
        this.formNuevaCita = this.formBuilder.group({
            fk_tracking: ['', Validators.required],
            tipoCita: ['', Validators.required],
            fechaCita: ['', Validators.required],
            fechaPreferente: [false],
            tipoDireccionCita: ['', Validators.required],
            direccionCita: ['', Validators.required],
            tecnicoCita: ['', Validators.required],
            tecnicosAyudantes: [''],
            comentarioCita: [''],
            fk_estado_cita: [],
            checkout_ok: [''],
            fk_motivo_estado: [],
            observaciones_estado: ['']
        });

        this.tecnicoCitaFilterCtrl.valueChanges.subscribe((filterValue) => {
			if (filterValue.length >= 2) {
				this.searchingTecnicosCita = true;
				this.loadTecnicos('tecnicoCita', filterValue);
			}
			else {
				this.tecnicosCita = [];
				if (this.tecnicoCitaSelected) {
					this.tecnicosCita.push(this.tecnicoCitaSelected);
				}
			}
		});
		this.tecnicoAyudanteFilterCtrl.valueChanges.subscribe((filterValue) => {
			if (filterValue.length >= 2) {
				this.searchingTecnicosCita = true;
				this.loadTecnicos('tecnicosAyudantes', filterValue);
			}
			else {
				this.tecnicosAyudantes = [];
				if (this.tecnicosAyudantesSelected) {
					this.tecnicosAyudantes = this.tecnicosAyudantesSelected.slice();
				}
			}
		});

        this.motivoEstadoFilterCtrl.valueChanges.subscribe((filterValue) => {
			if (!filterValue) {
				this.motivosEstadosFiltered.next(this.motivosEstado.slice());
				return;
			}

			this.motivosEstadosFiltered.next(
				this.motivosEstado.filter(item => {
					return item.name.toLowerCase().includes(filterValue.toLowerCase());
				})
			);
		});

        this.loadTiposDireccionCita();
        this.loadTiposCita();
        this.loadEventosCombos();

    }

    ngOnInit(): void {
        if (this.data.cita) {
			this.cita = this.data.cita;

            this.getAyudantes();
            this.loadEstadosCita();
            this.loadDireccionesCita(true, this.cita.fk_direccion_cita, this.cita.tipo_direccion_cita, this.cita.fk_tracking);
            
			this.setValueDataForm('fk_tracking', this.cita.fk_tracking);
			this.setValueDataForm('tipoCita', this.cita.tipo_cita);
			this.formNuevaCita.get('fechaCita').setValue(moment(this.cita.fecha_cita).format('YYYY-MM-DD[T]HH:mm'));
			this.setValueDataForm('fechaPreferente', this.cita.fecha_preferente);
			this.setValueDataForm('tipoDireccionCita', this.cita.tipo_direccion_cita);
			// this.setValueDataForm('direccionCita', this.cita.movil_contacto);
			// this.setValueDataForm('tecnicosAyudantes', this.cita.sms);
			this.setValueDataForm('comentarioCita', this.cita.comentario);
			this.setValueDataForm('fk_estado_cita', this.cita.fk_estado_cita);
            // this.setValueDataForm('checkout_ok', this.cita.sms);
            this.formNuevaCita.get('checkout_ok').setValue(this.cita.checkout_ok !== null ? this.cita.checkout_ok : '', {emitEvent: false});
            this.setTecnicoCita(this.cita.tecnico);
            //this.setTecnicosAyudantes(this.cita.tecnicosAyudantes);
			this.setValueDataForm('fk_motivo_estado', this.cita.fk_motivo_estado);
			this.setValueDataForm('observaciones_estado', this.cita.observaciones_estado);
		}
    }

    getAyudantes(){
        this.citasSvc.getAyudantes(this.cita.ID).subscribe(
			(data: any) => {
				this.cita['tecnicosAyudantes'] = data;
                this.setTecnicosAyudantes(this.cita.tecnicosAyudantes);
			},
			(err) => {
				this.showSnack(err);
			}
		);
    }

    loadTiposCita() {
		this.citasSvc.getTiposCita().subscribe(
			(data: any) => {
				this.tiposCita = data;
			},
			(err) => {
				this.showSnack(err);
			}
		);
	}

    loadTecnicos(tipo: string, filter?: string): void {
		this.tecnicosSvc.getTecnicos(null, filter, null).subscribe(
			(data: any) => {
				if (tipo === 'tecnicoCita') {
					this.searchingTecnicosCita = false;
					this.tecnicosCita = data.slice();
				}
				else if (tipo === 'tecnicosAyudantes') {
					this.searchingTecnicosCita = false;
					if (this.tecnicosAyudantesSelected) {
						this.tecnicosAyudantes = this.tecnicosAyudantesSelected.slice();
						for (const itemTecnico of data) {
							const itemAux = this.tecnicosAyudantesSelected.find((item:any) => {
								return item.id === itemTecnico.id;
							});
	
							if (!itemAux) {
								this.tecnicosAyudantes.push(itemTecnico);
							}
						}
					}
					else {
						this.tecnicosAyudantes = data.slice();
					}
				}
			},
			(err) => {
				this.searchingTecnicosCita = false;
				this.showSnack(err);
			}
		);
	}

    loadTiposDireccionCita() {
		this.citasSvc.getTiposDireccionCita().subscribe(
			(data: any) => {
				this.tiposDireccionCita = data;
			},
			(err) => {
				this.showSnack(err);
			}
		);
	}

    loadDireccionesCita(setDireccionCita?: boolean, idDireccion?: number, idTipoDir?: number, idTicket?:number) {
		const tipoDireccion = idTipoDir?idTipoDir:this.formNuevaCita.get('tipoDireccionCita').value;
		if (tipoDireccion) {
			this.citasSvc.getDireccionesCita(tipoDireccion, idTicket?idTicket:this.formNuevaCita.get('fk_tracking').value).subscribe(
				(data: any) => {
					this.direccionesCita = this.formatDireccionesCita(data);

					if (setDireccionCita) {
						if (idDireccion) {
							const dirCli = this.direccionesCita.find(item => { return item.id == idDireccion; });
							if (dirCli) {
								this.setValueDataForm('direccionCita', dirCli.id);
							}
						}
						else if (tipoDireccion == 1 && this.direccionesCita.length > 0) {
							this.setValueDataForm('direccionCita', this.direccionesCita[0].id);
						}
					}
				},
				(err) => {
					this.showSnack(err);
				}
			);
		}
		else {
            if(!setDireccionCita)
			this.showSnack("Debe seleccionar antes un tipo de dirección");
		}
	}

    deselectTecnicoAyudante(dataTecnico: any): void {
		const index = this.tecnicosAyudantesSelected.findIndex((item) => {
			return item.id === dataTecnico.id;
		});

		if (index >= 0) {
			this.tecnicosAyudantesSelected.splice(index, 1);
		}
		let idTecnicos = this.tecnicosAyudantesSelected.map((item) => {
			return item.id;
		});
		const tecnicosAyudantesCtrl = this.formNuevaCita.get('tecnicosAyudantes');
		tecnicosAyudantesCtrl.setValue(idTecnicos);
	}

    setValueDataForm(formControlName, value) {
		let formControl = this.formNuevaCita.get(formControlName);
		if (formControl) {
			if (value !== '' && value !== 0 && value !== null) {
				formControl.setValue(value, {emitEvent: false});
			}
			
			if (value === 0 && (formControlName === 'FK_group' || formControlName === 'assign_group')) {
				formControl.setValue(value, {emitEvent: false});
			}
		}
	}

	formatDireccionesCita(datosDirecciones: Array<any>): Array<any> {
		let direccionesCita = [];

		for (const itemDir of datosDirecciones) {
			const tipoDireccion = itemDir.tipoDireccion ? itemDir.tipoDireccion.tipo_direccion : '';
			const nombre = itemDir.nombre ? itemDir.nombre : '';
			const direccion = itemDir.direccion ? itemDir.direccion : '';
			const cp = itemDir.localidad ? itemDir.localidad.postal : '';
			const localidad = itemDir.localidad ? itemDir.localidad.poblacion : '';
			const provincia = itemDir.provincia ? itemDir.provincia.provincia : '';

			let cadDireccion = tipoDireccion;
			cadDireccion += (cadDireccion !== '' ? ' - ' : '') + nombre + ' ' + direccion + ' ' + cp + ' ' + localidad + ' ' + provincia;

			const objDir = {
				id: itemDir.ID,
				name: cadDireccion
			};
			direccionesCita.push(objDir);
		}

		return direccionesCita;
	}

	formatDireccionCita(direccionCita) {
		const direccion = direccionCita.direccion ? direccionCita.direccion : '';
		const cp = direccionCita.localidad ? direccionCita.localidad.postal : '';
		const localidad = direccionCita.localidad ? direccionCita.localidad.poblacion : '';
		const provincia = direccionCita.provincia ? direccionCita.provincia.provincia : '';

		const cadDireccion = direccion + ' ' + cp + ' ' + localidad + ' ' + provincia;
		return cadDireccion;
	}

	getCadenaEstadoCita(cita) {
		let cadEstadoCita = " - ";

		if (cita.estadoCita) {
			cadEstadoCita = cita.estadoCita.estado;

			if (cita.fk_estado_cita === 5 && cita.checkout_ok === 0) {
				cadEstadoCita += " (No reparado)";
			}
		}

		return cadEstadoCita;
	}

	getCadenaMotivo(cita) {
		let cadMotivo = " - ";

		if (cita.motivoEstado) {
			cadMotivo = cita.motivoEstado.nombre;
		}
		else if (cita.motivoRechazo) {
			cadMotivo = cita.motivoRechazo.motivo;
		}

		return cadMotivo;
	}

    loadEstadosCita() {
		this.citasSvc.getEstadosCita().subscribe(
			(data: any) => {
				this.estadosCita = data.map((item) => {
					return { id: item.ID, name: item.estado };
				});

				if (this.cita) {
					this.setValueDataForm('fk_estado_cita', this.cita.fk_estado_cita);

					const checkoutOkCtrl = this.formNuevaCita.get('checkout_ok');
					checkoutOkCtrl.setValue(this.cita.checkout_ok !== null ? this.cita.checkout_ok : '', {emitEvent: false});
					
					this.loadMotivosEstadosCita();
				}
			},
			(err) => {
				this.showSnack(err);
			}
		);
	}

    loadMotivosEstadosCita() {
		const estadoCita = this.formNuevaCita.get('fk_estado_cita').value;
		this.citasSvc.getMotivosEstados(this.formNuevaCita.get('fk_tracking').value, estadoCita).subscribe(
			(data: any) => {
				this.motivosEstado = data.map((item) => {
					return { id: item.ID, name: item.nombre };
				});
				this.motivosEstadosFiltered.next(this.motivosEstado.slice());

                if(this.cita){
                    this.setValueDataForm('fk_motivo_estado', this.cita.fk_motivo_estado);
				    this.setValueDataForm('observaciones_estado', this.cita.observaciones_estado);
                }
			},
			(err) => {
				this.showSnack(err);
			}
		);
	}

    loadEventosCombos() {
		let tipoDireccionCitaCtrl = this.formNuevaCita.get('tipoDireccionCita');
		let tecnicoCitaCtrl = this.formNuevaCita.get('tecnicoCita');
		let tecnicosAyudantesCtrl = this.formNuevaCita.get('tecnicosAyudantes');
		let fechaPreferenteCtrl = this.formNuevaCita.get('fechaPreferente');
		let estadoCitaCtrl = this.formNuevaCita.get('fk_estado_cita');
		let checkoutOkCtrl = this.formNuevaCita.get('checkout_ok');

        this.formNuevaCita.get('fk_tracking').valueChanges.subscribe((idTicket) => {
            this.direccionesCita = [];
            this.clearSelect(null, 'direccionCita');
            if(tipoDireccionCitaCtrl.value){
                this.loadDireccionesCita();
            }
        });

		tipoDireccionCitaCtrl.valueChanges.subscribe((idTipoDireccion) => {
			this.direccionesCita = [];
			this.clearSelect(null, 'direccionCita');
			if (idTipoDireccion && idTipoDireccion != "") {
				this.loadDireccionesCita();
			}
		});
		
		tecnicoCitaCtrl.valueChanges.subscribe((idTecnico) => {
			const tecnico = this.tecnicosCita.find((item) => {
				return item.id === idTecnico;
			});
			this.tecnicoCitaSelected = tecnico;
		});

		tecnicosAyudantesCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((valueSelect) => {
			this.tecnicosAyudantesSelected = [];
			if (valueSelect) {
				for (const idTecnico of valueSelect) {
					const tecnico = this.tecnicosAyudantes.find((item) => {
						return item.id === idTecnico;
					});
					this.tecnicosAyudantesSelected.push(tecnico);
				}
			}
		});

		estadoCitaCtrl.valueChanges.subscribe((idEstado) => {
			if (idEstado == 2 || idEstado == 6) { // Rechazada o cancelada
				this.loadMotivosEstadosCita();
			}
			else {
				this.clearSelect(null, 'fk_motivo_estado');
				this.clearSelect(null, 'checkout_ok');
				this.clearSelect(null, 'observaciones_estado');
			}
		});
		checkoutOkCtrl.valueChanges.subscribe((value) => {
			const estadoCita = this.formNuevaCita.get('fk_estado_cita').value;
			if (value == 0 && estadoCita == 5) { // Checkout NO OK
				this.loadMotivosEstadosCita();
			}
		});

		fechaPreferenteCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
			console.log("cambio fecha preferente", value);
			this.activeFechaPreferente = value;
		});
	}

    checkDisableEstadoCita(objEstadoCita: any): boolean {
		let disabled = false;
		const tipoCita = this.formNuevaCita.get('tipoCita').value;
		if (tipoCita === 'LAB' && objEstadoCita.id === 3)  {
			disabled = true;
		}

		return disabled;
	}

    showCampoCita(nombreCampo: string): boolean {
		let show = true;

		const estadoCitaCtrl = this.formNuevaCita.get('fk_estado_cita');
		const motivoEstadoCtrl = this.formNuevaCita.get('fk_motivo_estado');
		const checkoutOkCtrl = this.formNuevaCita.get('checkout_ok');
		const obsEstadoCtrl = this.formNuevaCita.get('observaciones_estado');

		const estadoCita = estadoCitaCtrl.value;
		const reparado = this.formNuevaCita.get('checkout_ok').value;

		if (estadoCita === 2) { // Cita rechazada
			switch (nombreCampo) {
				case 'fk_motivo_estado':
					show = true;
					break;
				case 'checkout_ok':
					show = false;
					checkoutOkCtrl.setValue('');
					break;
				case 'observaciones_estado':
					show = true;
					break;
				default:
					break;
			}
		}
		else if (estadoCita === 5) { // Checkout
			switch (nombreCampo) {
				case 'fk_motivo_estado':
					if (reparado === 0) {
						show = true;
					}
					else {
						show = false;
						motivoEstadoCtrl.setValue('');
					}
					break;
				case 'checkout_ok':
					show = true;
					break;
				case 'observaciones_estado':
					show = false;
					obsEstadoCtrl.setValue('');
					break;
				default:
					break;
			}
		}
		else if (estadoCita === 6) {
			switch (nombreCampo) {
				case 'fk_motivo_estado':
					show = true;
					break;
				case 'checkout_ok':
					show = false;
					checkoutOkCtrl.setValue('');
					break;
				case 'observaciones_estado':
					show = true;
					break;
				default:
					break;
			}
		}
		else {
			switch (nombreCampo) {
				case 'fk_motivo_estado':
					motivoEstadoCtrl.setValue('');
					show = false;
					break;
				case 'checkout_ok':
					show = false;
					checkoutOkCtrl.setValue('');
					break;
				case 'observaciones_estado':
					show = false;
					obsEstadoCtrl.setValue('');
					break;
				default:
					break;
			}
		}

		return show;
	}

    checkValue(formControlName: string) {
		let formControl = this.formNuevaCita.get(formControlName);
		if (formControl) {
			if (formControlName === 'category' || formControlName === 'priority' || formControlName === 'tecnico') {
				return formControl.value !== 0;
			}	
			else {
				return formControl.value !== "" && formControl.value !== null;
			}
		}
		else {
			return false;
		}
	}

    clearSelect(event, formControlName: string) {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		let formControl = this.formNuevaCita.get(formControlName);
		if (formControl) {
			if (formControlName == 'FK_group' || formControlName == 'assign_group' || formControlName == 'category' || formControlName === 'priority' || formControlName === 'tecnico') {
				formControl.setValue(0);
			}
			else if(formControlName == 'marca_id' || formControlName == 'modelo_id' || formControlName == 'tipoElemento' ||
					formControlName == 'estado_cliente_fabricante' || formControlName == 'estado_interno' || formControlName == 'fk_motivo_estado' ||
					formControlName == 'fk_estado_cita') {
				formControl.setValue(null);
			}
			else if (formControlName == 'tecnicosAyudantes') {
				formControl.setValue([]);
			}
			else {
				formControl.setValue('');
			}
			formControl.markAsDirty();
		}

		switch (formControlName) {
			case 'tecnicoCita':
				this.tecnicoCitaSelected = null;
				this.tecnicosCita = [];
				break;
		
			case 'tecnicosAyudantes':
				this.tecnicosAyudantesSelected = [];
				this.tecnicosAyudantes = [];
				break;
		
			default:
				break;
		}
	}

    setTecnicoCita(tecnico: any) {
		const tecnicoFind = this.tecnicosCita.find((item) => { item.id = tecnico.ID });
		if (!tecnicoFind) {
            this.tecnicosCita.push({id: tecnico.ID, username: tecnico.name, nombre: tecnico.firstname, apellidos: tecnico.realname, image_user: tecnico.image_user});
		}
        
        this.tecnicoCitaSelected = {id: tecnico.ID, username: tecnico.name, nombre: tecnico.firstname, apellidos: tecnico.realname, image_user: tecnico.image_user};
        this.setValueDataForm('tecnicoCita', tecnico.ID);
	}

    setTecnicosAyudantes(tecnicos: any) {
        console.log("HOLA MUY BUENAS");
        for (const ayudante of tecnicos) {
            if(ayudante.tecnico){
                const itemAux = this.tecnicosAyudantes.find((item:any) => {
                    return item.id === ayudante.ID;
                });
    
                if (!itemAux) {
                    this.tecnicosAyudantes.push({id: ayudante.ID, username: ayudante.tecnico.name, nombre: ayudante.tecnico.firstname, apellidos: ayudante.tecnico.realname, image_user: ayudante.tecnico.image_user});
                }

                this.tecnicosAyudantesSelected.push({id: ayudante.ID, username: ayudante.tecnico.name, nombre: ayudante.tecnico.firstname, apellidos: ayudante.tecnico.realname, image_user: ayudante.tecnico.image_user});
            }
        }

        console.log("LOS TECNICOS AYUDANTES", this.tecnicosAyudantesSelected);

        // this.tecnicoCitaSelected = {id: tecnico.ID, username: tecnico.name, nombre: tecnico.firstname, apellidos: tecnico.realname, image_user: tecnico.image_user};
        // this.setValueDataForm('tecnicoCita', tecnico.ID);
	}

    guardarCita(){
        const validFormCita = this.validateNuevaCita();
		console.log("validFormCita", validFormCita);
		if (!validFormCita.result) {
			this.showSnack(validFormCita.msg);
		}
		else {
			let formValue = this.formNuevaCita.value;
			console.log("formValueCita", formValue);

			if (this.cita) {
				this.citasSvc.modificarCita(this.cita.ID, formValue).pipe(takeUntil(this._unsubscribeAll)).subscribe(
					(data:any) => {
                        this.dialogRef.close(data);
					},
					(err) => {
						this.showSnack(err);
					}
				)
			}
			else {
				this.citasSvc.crearCita(formValue).pipe(takeUntil(this._unsubscribeAll)).subscribe(
					(data: any) => {
                        this.dialogRef.close(data);
					},
					(err) => {
						this.showSnack(err);
					}
				);
			}
		}
    }

    validateNuevaCita() {
		const formValue = this.formNuevaCita.value;
		let valid = {
			result: true,
			msg: ''
		};

		if (formValue.tipoCita == "") {
			valid.result = false;
			valid.msg += "Debes indicar el tipo de cita\n";
		}
		if (formValue.fechaCita == "") {
			valid.result = false;
			valid.msg += "Debes indicar una fecha para la cita\n";
		}
		if (formValue.tipoDireccion == "") {
			valid.result = false;
			valid.msg += "Debes indicar el tipo de dirección de la cita\n";
		}
		if (formValue.direccionCita == "") {
			valid.result = false;
			valid.msg += "Debes indicar una dirección para la cita\n";
		}
		if (formValue.tecnicoCita == "") {
			valid.result = false;
			valid.msg += "Debes indicar un técnico para la cita\n";
		}

		return valid;
	}

    showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 2000
		});
	}
}
