<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
    <h2 class="m-0 mb-8">Diagnosis / Reparación</h2>
    <button mat-icon-button mat-dialog-close style="color: #666; position: absolute; top: -20px; right: -20px" [disabled]="guardandoDiagnosis">
        <mat-icon>close</mat-icon>
    </button>
</div>
  
<mat-dialog-content class="mat-typography custom-dialog-content">
    <div fxLayout="column" style="width: 100%">

        <div class="form_diagnosis">
            <mat-accordion class="mt-12">
                <!-- <mat-expansion-panel #panelFormDiagnosis (closed)="!panelFormDiagnosisExpanded" (opened)="panelFormDiagnosisExpanded" class="formulario-expasion"> -->
                <mat-expansion-panel #panelFormDiagnosis [expanded]="panelFormDiagnosisExpanded" class="formulario-expasion">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Formulario {{ panelMainTitle }}</mat-panel-title>
                        <mat-panel-description>{{ panelTitleDescription }} - {{ panelSubTitleDescription }}</mat-panel-description>
                    </mat-expansion-panel-header>

                    <form *ngIf="!esReparacion" #mainForm="ngForm" [formGroup]="formDiagnosis">
        
                        <div fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-40-p ml-16">
                                <mat-label>Tipo de problema</mat-label>
                                <mat-select name="problema1" formControlName="problema1" class="form-control">
                                <mat-option *ngFor="let item of tiposProblemas" [value]="item.ID">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field *ngIf="!esProyectoLenovo" appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>PN</mat-label>
                                <input matInput name="diag_pn1" formControlName="diag_pn1" class="form-control" (keydown)="showRejillaPiezas('pn', $event)">
                            </mat-form-field>
        
                            <mat-form-field *ngIf="esProyectoLenovo" appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>PN</mat-label>
                                <mat-select name="diag_pn1" formControlName="diag_pn1" class="form-control">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="pnFilterCtrl" [searching]="searchingPN"
                                            placeholderLabel="Buscar PN..." noEntriesFoundLabel="No se encontró ningún PN"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let item of PNsLenovo" [value]="item.ID">{{item.pn}}</mat-option>
                                </mat-select>
                                <button *ngIf="checkValue('diag_pn1')" mat-button matSuffix mat-icon-button (click)="clearValue('diag_pn1', $event)">
                                    <mat-icon>close</mat-icon>
                                </button>                                
                            </mat-form-field>
        
                            <mat-form-field *ngIf="filtrarNumeroSeriePermitido" appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>N/S</mat-label>
                                <input matInput name="diag_ns" formControlName="diag_ns" class="form-control" (keydown)="showRejillaPiezas('sn', $event)">
                            </mat-form-field>
                        </div>
                
        
                        <div fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-40-p ml-16">
                                <mat-label>Almacén ERP/Estado</mat-label>
                                <input matInput name="almacen_estado" formControlName="almacen_estado" class="form-control">
                            </mat-form-field>
                        </div>
                
        
                        <div *ngIf="seleccionarPiezasPermitido && mostrarRejillaPiezas" fxLayout="row" class="mb-12 rejilla-piezas">
                            <mat-tab-group class="rejillas-piezas-diagnosis" (selectedIndexChange)="changeTabPiezasDiagnosis($event)">
                                
                                <mat-tab label="Piezas en almacenes de proyectos"> 

                                    <div class="table-container" fxFlex="1 0 auto">
                                    <table mat-table [dataSource]="dataSourceTabOne" class="w-100-p">

                                        <ng-container matColumnDef="seleccionado">
                                            <th mat-header-cell *matHeaderCellDef> </th>
                                            <td mat-cell *matCellDef="let row">
                                                <mat-checkbox (change)="seleccionarElemento($event, row)"></mat-checkbox>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="almacen">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'almacen') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.almacen ? row.almacen : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="valorado">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'valorado') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.valorado ? row.valorado : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="serial">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'serial') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.serial ? row.serial : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="otherserial">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'otherserial') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.otherserial ? row.otherserial : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="cantidad">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'cantidad') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.cantidad ? row.cantidad : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="estado">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'estado') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.estado ? row.estado : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="pn">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'pn') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.pn ? row.pn : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="descripcion">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'descripcion') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.descripcion ? row.descripcion : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="precio">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'precio') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.precio ? row.precio : ' - '}}</td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsTabOne"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsTabOne;"></tr>
                                    </table>
                                    </div>

                                </mat-tab>


                                <mat-tab label="Piezas en almacén del colaborador asignado"> 

                                    <div class="table-container" fxFlex="1 0 auto">
                                    <table mat-table [dataSource]="dataSourceTabTwo" class="w-100-p">

                                        <ng-container matColumnDef="seleccionado">
                                            <th mat-header-cell *matHeaderCellDef> </th>
                                            <td mat-cell *matCellDef="let row">
                                                <mat-checkbox (change)="seleccionarElemento($event, row)"></mat-checkbox>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="almacen">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'almacen') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.almacen ? row.almacen : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="valorado">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'valorado') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.valorado ? row.valorado : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="serial">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'serial') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.serial ? row.serial : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="otherserial">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'otherserial') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.otherserial ? row.otherserial : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="cantidad">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'cantidad') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.cantidad ? row.cantidad : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="estado">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'estado') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.estado ? row.estado : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="pn">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'pn') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.pn ? row.pn : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="descripcion">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'descripcion') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.descripcion ? row.descripcion : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="precio">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'precio') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.precio ? row.precio : ' - '}}</td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsTabOne"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsTabOne;"></tr>
                                    </table>
                                    </div>

                                </mat-tab>


                                <mat-tab label="Piezas en el resto de almacénes">

                                    <div class="table-container" fxFlex="1 0 auto">
                                    <table mat-table [dataSource]="dataSourceTabThree" class="w-100-p">

                                        <ng-container matColumnDef="seleccionado">
                                            <th mat-header-cell *matHeaderCellDef> Seleccionar </th>
                                            <td mat-cell *matCellDef="let row">
                                                <mat-checkbox (change)="seleccionarElemento($event, row)" [checked]="piezaSeleccionada == row"></mat-checkbox>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="almacen">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'almacen') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.almacen ? row.almacen : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="valorado">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'valorado') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.valorado ? row.valorado : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="serial">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'serial') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.serial ? row.serial : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="otherserial">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'otherserial') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.otherserial ? row.otherserial : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="cantidad">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'cantidad') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.cantidad ? row.cantidad : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="estado">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'estado') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.estado ? row.estado : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="pn">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'pn') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.pn ? row.pn : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="descripcion">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'descripcion') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.descripcion ? row.descripcion : ' - '}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="precio">
                                            <th mat-header-cell *matHeaderCellDef> {{ gridConfigSvc.getNameColum(columnasPiezas, 'precio') }} </th>
                                            <td mat-cell *matCellDef="let row">{{row.precio ? row.precio : ' - '}}</td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsTabOne"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsTabOne;"></tr>
                                    </table>
                                    </div>

                                </mat-tab>

                            </mat-tab-group>
                        </div>

                        <div fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-60-p ml-16">
                                <mat-label>Descripción</mat-label>
                                <input matInput name="diag_desc1" formControlName="diag_desc1" class="form-control" (keydown)="showRejillaPiezas('desc', $event)">
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-20-p ml-16">
                                <mat-label>Nº Confirmación</mat-label>
                                <input matInput name="numConfirmacion" formControlName="numConfirmacion" class="form-control">
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-20-p ml-16">
                                <mat-label>Cantidad</mat-label>
                                <input matInput name="cantidadFactura" formControlName="cantidadFactura" class="form-control">
                            </mat-form-field>
                        </div>
                        
        
                        <div fxLayout="row" class="mb-12">
                            <div fxLayout="column" class="w-70-p">
                                <div fxLayout="row" class="mb-12">
                                    <mat-form-field appearance="standard" class="form-group w-100-p ml-16">
                                        <mat-label>Observaciones</mat-label>
                                        <textarea matInput rows="3" name="observaciones" formControlName="observaciones" class="form-control"></textarea>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="esProyectoLenovoLab" fxLayout="row" class="mb-12">
                                    <mat-form-field appearance="standard" class="form-group w-100-p ml-16">
                                        <mat-label>Model Indicator (Lenovo)</mat-label>
                                        <mat-select name="parts_model_indicator" formControlName="parts_model_indicator" class="form-control">
                                        <mat-option *ngFor="let item of partsModelIndicator" [value]="item.id">{{item.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div fxLayout="column" class="w-30-p bloque-checkbox">
                                <div fxLayout="row" class="mb-12">
                                    <mat-checkbox  name="prob_sinpieza" formControlName="prob_sinpieza" class="form-control">No se requiere pieza</mat-checkbox>
                                </div>
                                <div fxLayout="row" class="mb-12">
                                    <mat-checkbox  (change)="checkPedirPieza($event.checked)" name="prob_pedirpieza" formControlName="prob_pedirpieza" class="form-control">Solicitud envío pieza</mat-checkbox>
                                </div>
                                <div fxLayout="row" class="mb-12">
                                    <mat-checkbox  name="prob_requiereLab" formControlName="prob_requiereLab" class="form-control">Requiere Lab</mat-checkbox>
                                </div>
                                <div fxLayout="row" class="mb-12">
                                    <mat-checkbox  name="oow" formControlName="oow" class="form-control">OOW</mat-checkbox>
                                </div>
                                <div fxLayout="row" class="mb-12">
                                    <mat-checkbox  name="prob_retornable" formControlName="prob_retornable" class="form-control">Pieza Retornable</mat-checkbox>
                                </div>
                            </div>
                        </div>
        
                        <div *ngIf="esProyectoSamsung" fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>ShipTo GR</mat-label>
                                <mat-select name="fk_shipToOrigen" formControlName="fk_shipToOrigen" class="form-control">
                                    <mat-option *ngFor="let item of clientesShipto" [value]="item.ID">{{item.codigo_cliente}} - {{item.nombre}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>ShipTo GI</mat-label>
                                <mat-select name="fk_shipToDestino" formControlName="fk_shipToDestino" class="form-control">
                                    <mat-option *ngFor="let item of clientesShipto" [value]="item.ID">{{item.codigo_cliente}} - {{item.nombre}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-20-p ml-16">
                                <mat-label>Fra. Samsung</mat-label>
                                <input matInput name="numFactura" formControlName="numFactura" class="form-control">
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-20-p ml-16">
                                <mat-label>Num Albaran</mat-label>
                                <input matInput name="numAlbaran" formControlName="numAlbaran" class="form-control">
                            </mat-form-field>
                        </div>
        
                        <div *ngIf="esProyectoWeblord" fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>Area</mat-label>
                                <mat-select name="averia_area" formControlName="averia_area" class="form-control">
                                    <mat-option *ngFor="let item of weblordAreas" [value]="item.ID">{{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>Subarea 1</mat-label>
                                <mat-select [disabled]="!weblordAreaSelected" name="averia_subarea1" formControlName="averia_subarea1" class="form-control">
                                    <mat-option *ngFor="let item of weblordSubareas1" [value]="item.ID">{{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-40-p ml-16">
                                <mat-label>Subarea 2</mat-label>
                                <mat-select [disabled]="!weblordSubarea1Selected" name="averia_subarea2" formControlName="averia_subarea2" class="form-control">
                                    <mat-option *ngFor="let item of weblordSubareas2" [value]="item.ID">{{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
        
                        <div *ngIf="esProyectoWeblord" fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>Sintoma</mat-label>
                                <mat-select [disabled]="!weblordSubarea2Selected" name="averia_symptom" formControlName="averia_symptom" class="form-control">
                                    <mat-option *ngFor="let item of weblordSintomas" [value]="item.ID">{{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>Frecuencia</mat-label>
                                <mat-select name="averia_occurrence" formControlName="averia_occurrence" class="form-control">
                                    <mat-option *ngFor="let item of weblordOccurrences" [value]="item.ID">{{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-40-p ml-16">
                                <mat-label>Reparación</mat-label>
                                <mat-select name="averia_repairaction" formControlName="averia_repairaction" class="form-control">
                                    <mat-option *ngFor="let item of weblordRepairActions" [value]="item.ID">{{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>


                        <div *ngIf="!esProyectoWeblord" fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>Condition Code</mat-label>
                                <mat-select name="iris_condition_code" formControlName="iris_condition_code" class="form-control">
                                    <mat-option *ngFor="let item of conditionCodes" [value]="item.ID">{{item.code}} - {{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>Symptom Code</mat-label>
                                <mat-select name="iris_symptom_code" formControlName="iris_symptom_code" class="form-control">
                                    <mat-option *ngFor="let item of symptomCodes" [value]="item.ID">{{item.code}} - {{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-40-p ml-16">
                                <mat-label>Section Code</mat-label>
                                <mat-select name="iris_section_code" formControlName="iris_section_code" class="form-control">
                                    <mat-option *ngFor="let item of sectionCodes" [value]="item.ID">{{item.code}} - {{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div *ngIf="showDirecciones" fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-100-p ml-16">
                                <mat-label>Direcciones</mat-label>
                                <mat-select name="direccion_diagnosis" formControlName="direccion_diagnosis" class="form-control">
                                    <mat-option *ngFor="let item of direccionesDiagnosis" [value]="item">{{item.direccion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </form>
                    
                    <form *ngIf="esReparacion" #mainForm="ngForm" [formGroup]="formReparacion">
        
                        <div fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-40-p ml-16">
                                <mat-label>PN</mat-label>
                                <input matInput name="diag_pn1" formControlName="diag_pn1" class="form-control">
                            </mat-form-field>

                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>P/N entregada-enviada</mat-label>
                                <input matInput name="prob_pnentenv" formControlName="prob_pnentenv" class="form-control">
                            </mat-form-field>

                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>N/S entregada-enviada</mat-label>
                                <input matInput name="prob_nsentenv" formControlName="prob_nsentenv" class="form-control">
                            </mat-form-field>
                        </div>
        
                        <div fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-40-p ml-16">
                                <mat-label>Estado pieza nueva/entregada</mat-label>
                                <mat-select name="ep" formControlName="ep" class="form-control">
                                    <mat-option *ngFor="let item of estadosReparacion" [value]="item.id">{{item.nombre}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>P/N retirado</mat-label>
                                <input matInput name="prob_pnretirado" formControlName="prob_pnretirado" class="form-control">
                            </mat-form-field>

                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>N/S retirado</mat-label>
                                <input matInput name="prob_nsretirado" formControlName="prob_nsretirado" class="form-control">
                            </mat-form-field>
                        </div>
        
                        <div fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-50-p ml-16">
                                <mat-label>Categorización Estado Pieza</mat-label>
                                <mat-select name="FK_reason_doa" formControlName="FK_reason_doa" class="form-control">
                                    <mat-option *ngFor="let item of doaReasons" [value]="item.id">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="standard" class="form-group w-50-p ml-16">
                                <mat-label>Categorización Estado Placa</mat-label>
                                <mat-select name="FK_reason_used" formControlName="FK_reason_used" class="form-control">
                                    <mat-option *ngFor="let item of doaReasonsUsed" [value]="item.id">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
        
                        <div fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-50-p ml-16">
                                <mat-label>Observaciones</mat-label>
                                <textarea matInput rows="3" name="observaciones2" formControlName="observaciones2" class="form-control"></textarea>
                            </mat-form-field>
                        </div>

                        <div *ngIf="esProyectoWeblord" fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>Area</mat-label>
                                <mat-select name="averia_area" formControlName="averia_area" class="form-control">
                                    <mat-option *ngFor="let item of weblordAreas" [value]="item.ID">{{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>Subarea 1</mat-label>
                                <mat-select [disabled]="!weblordAreaSelected" name="averia_subarea1" formControlName="averia_subarea1" class="form-control">
                                    <mat-option *ngFor="let item of weblordSubareas1" [value]="item.ID">{{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-40-p ml-16">
                                <mat-label>Subarea 2</mat-label>
                                <mat-select [disabled]="!weblordSubarea1Selected" name="averia_subarea2" formControlName="averia_subarea2" class="form-control">
                                    <mat-option *ngFor="let item of weblordSubareas2" [value]="item.ID">{{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
        
                        <div *ngIf="esProyectoWeblord" fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>Sintoma</mat-label>
                                <mat-select [disabled]="!weblordSubarea2Selected" name="averia_symptom" formControlName="averia_symptom" class="form-control">
                                    <mat-option *ngFor="let item of weblordSintomas" [value]="item.ID">{{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>Frecuencia</mat-label>
                                <mat-select name="averia_occurrence" formControlName="averia_occurrence" class="form-control">
                                    <mat-option *ngFor="let item of weblordOccurrences" [value]="item.ID">{{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-40-p ml-16">
                                <mat-label>Reparación</mat-label>
                                <mat-select name="averia_repairaction" formControlName="averia_repairaction" class="form-control">
                                    <mat-option *ngFor="let item of weblordRepairActions" [value]="item.ID">{{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
        
                        <div *ngIf="!esProyectoWeblord" fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>Defect Code</mat-label>
                                <mat-select name="iris_defect_code" formControlName="iris_defect_code" class="form-control">
                                    <mat-option *ngFor="let item of defectCodes" [value]="item.ID">{{item.code}} - {{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-30-p ml-16">
                                <mat-label>Repair Code</mat-label>
                                <mat-select name="iris_repair_code" formControlName="iris_repair_code" class="form-control">
                                    <mat-option *ngFor="let item of repairCodes" [value]="item.ID">{{item.code}} - {{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
        
                            <mat-form-field appearance="standard" class="form-group w-40-p ml-16">
                                <mat-label>Ntf Code</mat-label>
                                <mat-select name="iris_ntf_code" formControlName="iris_ntf_code" class="form-control">
                                    <mat-option *ngFor="let item of ntfCodes" [value]="item.ID">{{item.code}} - {{item.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" class="mb-12">
                            <mat-form-field appearance="standard" class="form-group w-50-p ml-16">
                                <mat-label>Síntoma</mat-label>
                                <textarea matInput rows="3" name="sintoma" formControlName="sintoma" class="form-control"></textarea>
                            </mat-form-field>
                        </div>


                    </form>

                    <mat-action-row>
                        <button mat-button class="btn-aitana" [class.spinner]="guardandoDiagnosis" [disabled]="guardandoDiagnosis" (click)="guardarDiagnosisReparacion()">GUARDAR</button>
                        <button mat-button (click)="cancelForm()" [disabled]="guardandoDiagnosis">CANCELAR</button>
                    </mat-action-row>

                </mat-expansion-panel>
            </mat-accordion>

        </div>


        <div class="rejilla">
            <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
              <h2 class="m-0 mb-8">Diagnosis</h2>
            </div>
            
            <div  class="table-container mt-8">
                <table mat-table [dataSource]="dataSourceDiagnosis" class="w-95-p bloque-form">
                
                    <ng-container matColumnDef="ID">
                        <th mat-header-cell *matHeaderCellDef> ID diag. </th>
                        <td mat-cell *matCellDef="let row">{{row.ID !== "" ? row.ID : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="datetime_creacion">
                        <th mat-header-cell *matHeaderCellDef> Fecha diagnosis </th>
                        <td mat-cell *matCellDef="let row"> {{formatDate(row.datetime_creacion, true)}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="FK_users">
                        <th mat-header-cell *matHeaderCellDef> Autor </th>
                        <td mat-cell *matCellDef="let row">{{row.autor ? row.autor.name : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="problema1">
                        <th mat-header-cell *matHeaderCellDef> Tipo de problema </th>
                        <td mat-cell *matCellDef="let row">{{row.tipo_problema ?  row.tipo_problema.name : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="diag_desc1">
                        <th mat-header-cell *matHeaderCellDef> Descripción </th>
                        <td mat-cell *matCellDef="let row">{{row.diag_desc1 ? row.diag_desc1 : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="diag_pn1">
                        <th mat-header-cell *matHeaderCellDef> PN </th>
                        <td mat-cell *matCellDef="let row">{{row.diag_pn1 ? row.diag_pn1 : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="prob_sinpieza">
                        <th mat-header-cell *matHeaderCellDef> PIEZA </th>
                        <td mat-cell *matCellDef="let row">{{row.prob_sinpieza ? 'NO' : 'SI'}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="prob_pedirpieza">
                        <th mat-header-cell *matHeaderCellDef> TRANSPORTE </th>
                        <td mat-cell *matCellDef="let row">{{row.prob_pedirpieza ? 'SI' : 'NO'}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="ep">
                        <th mat-header-cell *matHeaderCellDef> Estado pieza nueva/entregada </th>
                        <td mat-cell *matCellDef="let row">{{getEstadoReparacion(row.ep)}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="estadosLab">
                        <th mat-header-cell *matHeaderCellDef> LAB </th>
                        <td mat-cell *matCellDef="let row">{{row.prob_requiereLab ? 'SI' : 'NO'}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="almacen_estado">
                        <th mat-header-cell *matHeaderCellDef> Almacén ERP/Estado </th>
                        <td mat-cell *matCellDef="let row">{{row.almacen_estado ? row.almacen_estado : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="oow">
                        <th mat-header-cell *matHeaderCellDef> OOW </th>
                        <td mat-cell *matCellDef="let row">{{row.oow ? 'SI' : 'NO'}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="retornable">
                        <th mat-header-cell *matHeaderCellDef> Retornable </th>
                        <td mat-cell *matCellDef="let row">{{row.prob_retornable ? 'SI' : 'NO'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="acciones" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row">
                            <div fxLayout="row wrap" fxLayoutAlign="center center">
                                <button mat-icon-button matTooltip="Editar" (click)="editDiagnosis(row)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Editar" (click)="editRepairDiagnosis(row)">
                                    <mat-icon>build</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsDiagnosis"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsDiagnosis;"></tr>
                </table>
            </div>
        </div>

        <div class="rejilla">
            <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;">
              <h2 class="m-0 mb-8">Reparación</h2>
            </div>
            
            <div  class="table-container mt-8">
                <table mat-table [dataSource]="dataSourceReparaciones" class="w-95-p bloque-form">
                
                    <ng-container matColumnDef="ID">
                        <th mat-header-cell *matHeaderCellDef> ID diag. </th>
                        <td mat-cell *matCellDef="let row">{{row.ID ? row.ID : ' - '}}</td>
                    </ng-container>

                    <ng-container matColumnDef="colaborador">
                        <th mat-header-cell *matHeaderCellDef> Colaborador </th>
                        <td mat-cell *matCellDef="let row">{{ticket.tecnicocolaborador ? ticket.tecnicocolaborador.name : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="tecnico">
                        <th mat-header-cell *matHeaderCellDef> Técnico </th>
                        <td mat-cell *matCellDef="let row">{{ticket.tecnico ? ticket.tecnico.name : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="prob_pnentenv">
                        <th mat-header-cell *matHeaderCellDef> P/N entregada-enviada </th>
                        <td mat-cell *matCellDef="let row">{{row.prob_pnentenv ? row.prob_pnentenv : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="prob_nsentenv">
                        <th mat-header-cell *matHeaderCellDef> N/S entregada-enviada </th>
                        <td mat-cell *matCellDef="let row">{{row.prob_nsentenv ? row.prob_nsentenv : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="ep">
                        <th mat-header-cell *matHeaderCellDef> Estado pieza nueva/entregada </th>
                        <td mat-cell *matCellDef="let row">{{getEstadoReparacion(row.ep)}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="prob_pnretirado">
                        <th mat-header-cell *matHeaderCellDef> P/N retirado </th>
                        <td mat-cell *matCellDef="let row">{{row.prob_pnretirado ? row.prob_pnretirado : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="prob_nsretirado">
                        <th mat-header-cell *matHeaderCellDef> N/S retirado </th>
                        <td mat-cell *matCellDef="let row">{{row.prob_nsretirado ? row.prob_nsretirado : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="fecha_reparacion">
                        <th mat-header-cell *matHeaderCellDef> Fecha reparación </th>
                        <td mat-cell *matCellDef="let row">{{formatDate(row.fecha_reparacion, true)}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="prob_pndevolucion">
                        <th mat-header-cell *matHeaderCellDef> P/N Devolución </th>
                        <td mat-cell *matCellDef="let row">{{row.prob_pndevolucion ? row.prob_pndevolucion : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="prob_nsdevolucion">
                        <th mat-header-cell *matHeaderCellDef> N/S Devolución </th>
                        <td mat-cell *matCellDef="let row">{{row.prob_nsdevolucion ? row.prob_nsdevolucion : ' - '}}</td>
                    </ng-container>
                
                    <ng-container matColumnDef="prob_fecha_devolucion">
                        <th mat-header-cell *matHeaderCellDef> Fecha devolución </th>
                        <td mat-cell *matCellDef="let row">{{formatDate(row.fecha_devolucion, true)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="acciones" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row">
                        </td>
                     </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsReparaciones"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsReparaciones;"></tr>
                </table>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close(false)" [disabled]="guardandoDiagnosis">CERRAR</button>
</mat-dialog-actions>  