import { Component, OnInit, EventEmitter, Inject, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TicketsService } from 'app/services/ticket/tickets.service';
import * as moment from 'moment';

@Component({
  selector: 'app-seguimiento-dialog',
  templateUrl: './seguimiento-dialog.component.html',
  styleUrls: ['./seguimiento-dialog.component.scss']
})
export class SeguimientoDialogComponent implements OnInit {

    closeDialog = new EventEmitter();
    idTicket: number;

    displayedColumns: string[] = ['date', 'author', 'content'];
    dataSource: MatTableDataSource<any>;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    seguimientos: Array<any>;
    numPage: number;
    pageSize: number;
    currentOrder: string;
    currentDirection: string;
    totalElementos: number;

	constructor(
		private snackBar: MatSnackBar,
		private translateService: TranslateService,
    	private ticketService: TicketsService,
    @Inject(MAT_DIALOG_DATA) data: {id: number}) {
        moment.locale(this.translateService.currentLang);
        this.numPage = 0;
        this.pageSize = 5;
        this.totalElementos = 0;
        this.dataSource = new MatTableDataSource();
        this.idTicket = data.id;
    }

  ngOnInit(): void {
    this.loadSeguimientos();
  }

  showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 1000
		});
	}

	sortChange(event) {
		console.log("sortchange", event);
		if (event.direction !== "") {
			this.currentOrder = event.active;
			this.currentDirection = event.direction;
		}
		else {
			this.currentDirection = "";
			this.currentOrder = "";
		}
		this.loadSeguimientos();
	}

	pageChanged(event: PageEvent) {
		console.log("pageChange", event);
		this.numPage = event.pageIndex;
		this.pageSize = event.pageSize;
		this.loadSeguimientos();
	}


  loadSeguimientos(): void {
    this.ticketService.getSeguimientosTicket(this.idTicket, this.numPage, this.pageSize).subscribe(
			(data: any) => {
				this.seguimientos = data.elements;
				this.totalElementos = data.totalElements;
				this.dataSource.data = this.seguimientos;
				console.log(this.seguimientos)
			},
			(err) => {
				this.showSnack(err);
			}
		);
  }

}
