import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TicketsService } from 'app/services/ticket/tickets.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { DiagnosisService } from 'app/services/diagnosis/diagnosis.service';
import { UserRoleService } from 'app/services/user/user-role.service';
import { CygPermission } from '@fuse/types';
import { GridConfigService } from 'app/services/grid/grid-config.service';
import { ClienteService } from 'app/services/cliente/cliente.service';

@Component({
  selector: 'app-dialog-form-diagnosis',
  templateUrl: './dialog-form-diagnosis.component.html',
  styleUrls: ['./dialog-form-diagnosis.component.scss']
})
export class DialogFormDiagnosisComponent implements OnInit {

	static PROYECTO_LENOVO_LAB = 571;
	static PROYECTO_LENOVO_LAB_ITALIA = 623;
	static PROYECTO_LENOVO = 633;
	static PROYECTOS_SAMSUNG = [209,244,82,233,339, 629, 665,666];

	esReparacion: boolean;
	cargandoDiagnosis: boolean;
	diagnosisUpdate:any;

	esProyectoLenovo: boolean;
	esProyectoLenovoLab: boolean;
	esProyectoSamsung: boolean;
	esProyectoWeblord: boolean;

	private _unsubscribeAll: Subject<any>;
	@ViewChild('panelFormDiagnosis') panelFormDiagnosis: MatExpansionPanel;

	panelFormDiagnosisExpanded: boolean;
	panelMainTitle: string;
	panelTitleDescription: string;
	panelSubTitleDescription: string;

	ticket: any;
	diagnosis: Array<any>;
	reparaciones: Array<any>;

	//TODO
	// columns: Array<any> = [
	// 	{ id: 'ID', name: 'GRID.HEADER.name'}, 
	// 	{ id: 'datetime_creacion', name: 'GRID.HEADER.name'}, 
	// 	{ id: 'FK_users', name: 'GRID.HEADER.name'}, 
	// 	{ id: 'problema1', name: 'GRID.HEADER.name'}, 
	// 	{ id: 'diag_desc1', name: 'GRID.HEADER.name'}, 
	// 	{ id: 'diag_pn1', name: 'GRID.HEADER.name'}, 
	// 	{ id: 'prob_sinpieza', name: 'GRID.HEADER.name'}, 
	// 	{ id: 'prob_pedirpieza', name: 'GRID.HEADER.name'}, 
	// 	{ id: 'ep', name: 'GRID.HEADER.name'}, 
	// 	{ id: 'estadosLab', name: 'GRID.HEADER.name'}, 
	// 	{ id: 'almacen_estado', name: 'GRID.HEADER.name'}, 
	// 	{ id: 'oow', name: 'GRID.HEADER.name'}, 
	// 	{ id: 'retornable', name: 'GRID.HEADER.name'}, 
	// 	{ id: 'acciones', name: 'GRID.HEADER.name'}
	// ];

	displayedColumnsDiagnosis: string[] = ['ID', 'datetime_creacion', 'FK_users', 'problema1', 'diag_desc1', 'diag_pn1', 'prob_sinpieza', 'prob_pedirpieza', 'ep', 'estadosLab', 'almacen_estado', 'oow', 'retornable', 'acciones'];
	dataSourceDiagnosis: MatTableDataSource<any>;

	displayedColumnsReparaciones: string[] = ['ID', 'colaborador', 'tecnico', 'prob_pnentenv', 'prob_nsentenv', 'ep', 'prob_pnretirado', 'prob_nsretirado', 'fecha_reparacion', 'prob_pndevolucion', 'prob_nsdevolucion', 'prob_fecha_devolucion'];
	dataSourceReparaciones: MatTableDataSource<any>;

	columnasPiezas: Array<any> = [
		{id: 'seleccionado', name: 'Seleccionar'},
		{id: 'almacen', name: 'Almacén'},
		{id: 'valorado', name: 'Valorado'},
		{id: 'serial', name: 'Número Serie'},
		{id: 'otherserial', name: 'Número Serie Interno'},
		{id: 'cantidad', name: 'Ticket Reservado o Cantidad'},
		{id: 'estado', name: 'Estado'},
		{id: 'pn', name: 'PN'},
		{id: 'descripcion', name: 'Descripción'},
		{id: 'precio', name: 'Precio'}
	];

	displayedColumnsTabOne: string[];
	dataSourceTabOne: MatTableDataSource<any>;
	dataSourceTabTwo: MatTableDataSource<any>;
	dataSourceTabThree: MatTableDataSource<any>;

	formDiagnosis: FormGroup;
	formReparacion: FormGroup;
	guardandoDiagnosis: boolean;

	tiposProblemas: Array<any>;

	pnFilterCtrl: FormControl;
	searchingPN: boolean;
	PNsLenovo: Array<any>;
	pnLenovoSelected: any;

	permisoFiltrarNumeroSerie: CygPermission;
	filtrarNumeroSeriePermitido: boolean;

	permisoSeleccionarPiezas: CygPermission;
	seleccionarPiezasPermitido: boolean;

	partsModelIndicator: Array<any>;
	mostrarRejillaPiezas: boolean;

	pnBuscado:string;
	nsBuscado:string;
	descBuscado:string;

	piezasDiagnosis: Array<any>;
	tabActual: string;

	tabOneBuscado:boolean;
	tabTwoBuscado:boolean;
	tabThreeBuscado:boolean;

	clientesShipto: Array<any>;

	weblordAreas: Array<any>;
	weblordAreaSelected:any;
	weblordSubareas1: Array<any>;
	weblordSubarea1Selected:any;
	weblordSubareas2: Array<any>;
	weblordSubarea2Selected:any;
	weblordSintomas: Array<any>;
	weblordOccurrences: Array<any>;
	weblordRepairActions: Array<any>;
	
	showDirecciones: boolean;
	direccionesDiagnosis: Array<any>;
	direccionSelected:any;

	estadosReparacion: Array<any>;

	conditionCodes: Array<any>;
	symptomCodes: Array<any>;
	sectionCodes: Array<any>;
	defectCodes: Array<any>;
	repairCodes: Array<any>;
	ntfCodes: Array<any>;
    doaReasons: Array<any> = [];
    doaReasonsUsed: Array<any> = [];

	piezaSeleccionada: any;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<DialogFormDiagnosisComponent>,

		private snackBar: MatSnackBar,
		private translateService: TranslateService,
		private ticketService: TicketsService,
		private formBuilder: FormBuilder,
		private diagnosisService:DiagnosisService,
		private userRoleService: UserRoleService,
		private gridConfigSvc: GridConfigService,
		private clienteService: ClienteService,
		) {
		this._unsubscribeAll = new Subject();
		this.esReparacion = false;
		this.cargandoDiagnosis = false;
		this.diagnosisUpdate = null;

		this.diagnosis = [];
		this.reparaciones = [];

		this.dataSourceDiagnosis = new MatTableDataSource();
		this.dataSourceReparaciones = new MatTableDataSource();

		this.dataSourceTabOne = new MatTableDataSource();
		this.dataSourceTabTwo = new MatTableDataSource();
		this.dataSourceTabThree = new MatTableDataSource();

		this.formDiagnosis = this.formBuilder.group({
            ID: [null],
			name: [''],
			problema1: [null],
			diag_desc1: [''],
			diag_pn1: [''],
			prob_sinpieza: [0],
			prob_pedirpieza: [0],
			prob_requiereLab: [0],
			prob_retornable: [0],
			estadosLab: [''],
			almacen_estado: [''],
			oow: [0],
			retornable: [''],
			diag_ns: [''],
			numConfirmacion: [''],
			cantidadFactura: [''],
			observaciones: [''],
			parts_model_indicator: [null],
			fk_shipToOrigen: [null],
			fk_shipToDestino: [null],
			numFactura: [''],
			numAlbaran: [''],
			averia_area: [null],
			averia_subarea1: [null],
			averia_subarea2: [null],
			averia_symptom: [null],
			averia_occurrence: [null],
			averia_repairaction: [null],

			iris_condition_code: [null],
			iris_symptom_code: [null],
			iris_section_code: [null],

			direccion_diagnosis: [null],
		});

		this.formReparacion = this.formBuilder.group({
			diag_pn1: [''],
			prob_pnentenv: [''],
			prob_nsentenv: [''],
			ep: [null],
			prob_pnretirado: [''],
			prob_nsretirado: [''],
			FK_reason_doa: [''],
			FK_reason_used: [''],
			observaciones2: [''],
			averia_area: [null],
			averia_subarea1: [null],
			averia_subarea2: [null],
			averia_symptom: [null],
			averia_occurrence: [null],
			averia_repairaction: [null],
			iris_defect_code: [null],
			iris_repair_code: [null],
			iris_ntf_code: [null],
			sintoma: [''],
		});

		this.guardandoDiagnosis = false;
		this.panelFormDiagnosisExpanded = false;
		this.tiposProblemas = [];

		this.pnFilterCtrl = new FormControl();
		this.searchingPN = false;
		this.PNsLenovo = [];

		this.permisoFiltrarNumeroSerie = { type: "item", name: "Aitana:Filtro numero serie diagnosis", active: true };
		this.filtrarNumeroSeriePermitido = false;

		this.permisoSeleccionarPiezas = { type: "item", name: "Aitana:Gestion:Tickets:Seleccionar piezas al crearlas", active: true };
		this.seleccionarPiezasPermitido = false;

		this.partsModelIndicator = [];
		this.mostrarRejillaPiezas = false;

		this.displayedColumnsTabOne = this.columnasPiezas.map((item) => { return item.id; });
		this.tabActual = 'proyecto';

		this.clientesShipto = [];
		this.weblordAreas = [];
		this.weblordSubareas1 = [];
		this.weblordSubareas2 = [];
		this.weblordSintomas = [];
		this.weblordOccurrences = [];
		this.weblordRepairActions = [];
		
		this.showDirecciones = false;
		this.direccionesDiagnosis = [];

		this.resetTabsPiezasDiagnosis();

		this.estadosReparacion = [
			{id: 1, nombre: 'USADA'},
			{id: 2, nombre: 'NO USADA'},
			{id: 3, nombre: 'USADA-DOA'},
			{id: 4, nombre: 'NTF - No trouble found'},
			{id: 5, nombre: 'CID - Customer Induced Damage'},
			{id: 6, nombre: 'CKP - Customer Kept'},
		];

		this.conditionCodes = [];
		this.symptomCodes = [];
		this.sectionCodes = [];
		this.defectCodes = [];
		this.repairCodes = [];
		this.ntfCodes = [];

		this.piezaSeleccionada = null;
	}

	ngOnInit(): void {
		this.ticket = this.data.ticket;
		this.panelMainTitle = "Diagnosis";
		this.panelTitleDescription = "Nueva Diagnosis";
		this.panelSubTitleDescription = "Ticket " + this.ticket.ID;

		this.checkProyectos();
		this.loadDiagnosis();
		this.loadReparaciones();
		this.loadClientesShipto();
		this.loadTiposProblemasDiagnosis();
		this.loadDireccionesDiagnosis(this.ticket.ID);
		this.checkPermisos();
		this.initControlValues();
        this.loadDoaReasons();
        this.loadDoaReasonsUsed();

		if(this.esProyectoWeblord) {
			this.loadWeblordAreas();
			this.loadWeblordOccurrences();
			this.loadWeblordRepairActions();
		}
		else {
			this.loadDiagnosisCodigosIris();
		}
	}

	ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	checkProyectos() {
		this.esProyectoLenovo = this.ticket.FK_entities == DialogFormDiagnosisComponent.PROYECTO_LENOVO;
		
		let proyectosLenovoLab = [DialogFormDiagnosisComponent.PROYECTO_LENOVO, DialogFormDiagnosisComponent.PROYECTO_LENOVO_LAB, DialogFormDiagnosisComponent.PROYECTO_LENOVO_LAB_ITALIA];
		this.esProyectoLenovoLab = proyectosLenovoLab.includes(this.ticket.FK_entities);

		this.esProyectoSamsung = DialogFormDiagnosisComponent.PROYECTOS_SAMSUNG.includes(this.ticket.FK_entities);

		let contratoTicket = this.ticket.proyecto.contrato;
		this.esProyectoWeblord = contratoTicket && contratoTicket.activo && !contratoTicket.deleted && contratoTicket.weblord_access;
	}

	checkPermisos() {
		this.userRoleService.checkPermissions([this.permisoFiltrarNumeroSerie])
			.then((permiso:boolean) => {
				this.filtrarNumeroSeriePermitido = permiso;
			});

		this.userRoleService.checkPermissions([this.permisoSeleccionarPiezas])
			.then((permiso:boolean) => {
				this.seleccionarPiezasPermitido = permiso;
			});
	}

	initControlValues() {
		this.initControlValuesDirecciones();
		this.initControlValuesPnLenovo();
		this.initControlValuesPnLenovoFiltro();
		this.initControlValuesWeblordArea();
		this.initControlValuesWeblordSubareas1();
		this.initControlValuesWeblordSubareas2();
	}

	initControlValuesDirecciones() {
		let direccionCtrl = this.formDiagnosis.get('direccion_diagnosis');
		direccionCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((direccionSelected) => {
			const direccion = this.direccionesDiagnosis.find((item) => { return item.ID === direccionSelected?.ID && item.tipo == direccionSelected?.tipo; });
			this.direccionSelected = direccion;
		});
	}

	initControlValuesPnLenovo() {
		let pnLenovoCtrl = this.formDiagnosis.get('diag_pn1');
		pnLenovoCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((idPN) => {
			if(this.esProyectoLenovo) {
				const pn = this.PNsLenovo.find((item) => { return item.ID === idPN; });
				this.pnLenovoSelected = pn;
				this.showRejillaPiezas('pn', null);
			}
		});
	}

	initControlValuesPnLenovoFiltro() {
		this.pnFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((filterValue) => {
			if (filterValue.length > 2) {
				this.searchingPN = true;
				this.loadPNLenovo(filterValue);
			}
			else {
				this.PNsLenovo = [];
				if (this.pnLenovoSelected) {
					this.PNsLenovo.push(this.pnLenovoSelected);
				}
			}
		});
	}

	initControlValuesWeblordArea() {
		let weblordAreaCtrl = this.formDiagnosis.get('averia_area');
		weblordAreaCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((area) => {
			const weblordArea = this.weblordAreas.find((item) => { return item.ID === area; });
			if(weblordArea) {
				this.weblordAreaSelected = weblordArea;
				this.loadWeblordSubareas1(weblordArea.ID);
				this.resetWeblordSelectsDiagnosis(true, true);
			}
		});

		let weblordAreaCtrl2 = this.formReparacion.get('averia_area');
		weblordAreaCtrl2.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((area) => {
			const weblordArea = this.weblordAreas.find((item) => { return item.ID === area; });
			if(weblordArea) {
				this.weblordAreaSelected = weblordArea;
				this.loadWeblordSubareas1(weblordArea.ID);
				this.resetWeblordSelectsRepairDiagnosis(true, true);
			}
		});
	}

	initControlValuesWeblordSubareas1() {
		let weblordSubarea1Ctrl = this.formDiagnosis.get('averia_subarea1');
		weblordSubarea1Ctrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((subarea1) => {
			const weblordSubarea1 = this.weblordSubareas1.find((item) => { return item.ID === subarea1; });
			if(weblordSubarea1) {
				this.weblordSubarea1Selected = weblordSubarea1;
				this.loadWeblordSubareas2(weblordSubarea1.ID);
				this.resetWeblordSelectsDiagnosis(false, true);
			}
		});

		let weblordSubarea1Ctrl2 = this.formReparacion.get('averia_subarea1');
		weblordSubarea1Ctrl2.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((subarea1) => {
			const weblordSubarea1 = this.weblordSubareas1.find((item) => { return item.ID === subarea1; });
			if(weblordSubarea1) {
				this.weblordSubarea1Selected = weblordSubarea1;
				this.loadWeblordSubareas2(weblordSubarea1.ID);
				this.resetWeblordSelectsDiagnosis(false, true);
			}
		});
	}

	initControlValuesWeblordSubareas2() {
		let weblordSubarea2Ctrl = this.formDiagnosis.get('averia_subarea2');
		weblordSubarea2Ctrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((subarea2) => {
			const weblordSubarea2 = this.weblordSubareas2.find((item) => { return item.ID === subarea2; });
			if(weblordSubarea2) {
				this.weblordSubarea2Selected = weblordSubarea2;
				this.loadWeblordSintomas(this.weblordSubarea1Selected.ID, weblordSubarea2.ID);
				this.resetWeblordSelectsDiagnosis(false, false);
			}
		});

		let weblordSubarea2Ctrl2 = this.formReparacion.get('averia_subarea2');
		weblordSubarea2Ctrl2.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((subarea2) => {
			const weblordSubarea2 = this.weblordSubareas2.find((item) => { return item.ID === subarea2; });
			if(weblordSubarea2) {
				this.weblordSubarea2Selected = weblordSubarea2;
				this.loadWeblordSintomas(this.weblordSubarea1Selected.ID, weblordSubarea2.ID);
				this.resetWeblordSelectsDiagnosis(false, false);
			}
		});
	}

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, null, {
			duration: 1000
		});
	}

	formatDate(date: string, withTime: boolean): string {
		let dateFormat = "";
		if (date) {
			let momentDate = moment(date);
			if (withTime) {
				dateFormat = momentDate.format('DD/MM/YYYY HH:mm');
			}
			else {
				dateFormat = momentDate.format('DD/MM/YYYY');
			}
		}

		return dateFormat;
	}

    setDireccion(diagnosis) {
		if (diagnosis.direccionEnvio) {
            const direccion = this.direccionesDiagnosis.find((item) => { return item.ID === diagnosis.direccionEnvio?.id_origen_datos && item.tipo == diagnosis.direccionEnvio?.tipo_origen_datos; });
			this.direccionSelected = direccion;
            
			this.formDiagnosis.get('direccion_diagnosis').setValue(this.direccionSelected);
		}
	}

    setDireccionDataDireccion(direccionEnvio) {
		let direccion = '';
		let direccion_tipo = direccionEnvio.direccion_tipo;
		let localidad = direccionEnvio.localidad;
		let provincia = direccionEnvio.provincia;

		if(direccionEnvio.tipo_origen_datos == 'sede') {
			direccion += 'Sede -';
		}
		else if(direccionEnvio.tipo_origen_datos == 'direccion_colaborador') {
			direccion += 'Colaborador -';
		}
		else {
			direccion += 'Cliente -';
		}

		if(direccion_tipo) {
			direccion += ' '+direccion_tipo.tipo_direccion + '-';
		}

        direccion += ' '+direccionEnvio.id_origen_datos;

        direccion += direccionEnvio.nombre?' '+direccionEnvio.nombre:'';
        direccion += direccionEnvio.apellido?' '+direccionEnvio.apellido:'';
        direccion += direccionEnvio.direccion?' '+direccionEnvio.direccion:'';
        direccion += direccionEnvio.num_casa?' nº '+direccionEnvio.num_casa:'';

        direccion += direccionEnvio.otros_datos?' '+direccionEnvio.otros_datos:'';
        direccion += (localidad && localidad.poblacion)?' '+localidad.poblacion:'';
        direccion += (provincia && provincia.provincia)?' '+provincia.provincia:'';
        direccion += (direccionEnvio && direccionEnvio.tlf_contacto)?' (Tlf: '+direccionEnvio.tlf_contacto+')':'';
        
		return direccion;
	}

    loadDoaReasons(){
        this.diagnosisService.getDoaReasonsCategories().pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.doaReasons = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
    }

    loadDoaReasonsUsed(){
        this.diagnosisService.getDoaReasons("2,7,10").pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.doaReasonsUsed = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
    }

	loadDiagnosis() {
		this.ticketService.getDiagnosisTicket(this.ticket.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.diagnosis = data.elements;
				console.log("this.diag", this.diagnosis);
				this.dataSourceDiagnosis.data = this.diagnosis;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadReparaciones() {
		this.ticketService.getReparacionesTicket(this.ticket.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.reparaciones = data.elements;
				console.log("this.reparaciones", this.reparaciones);
				this.dataSourceReparaciones.data = this.reparaciones;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadClientesShipto() {
		this.clienteService.getClientesShipto().pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.clientesShipto = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	getEstadoReparacion(estado) {
		const ep = this.estadosReparacion.find((item) => { return item.ID === estado; });
		if(ep) {
			return ep.nombre;
		}
		else {
			return " - ";
		}
    }

	loadTiposProblemasDiagnosis() {
		this.diagnosisService.getTiposProblemasDiagnosis(this.ticket.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.tiposProblemas = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadPNLenovo(filtro) {
		this.diagnosisService.getPartNumberLenovo(filtro).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.PNsLenovo = data;
				this.searchingPN = false;
			},
			(err) => {
				this.searchingPN = false;
				this.showSnack(err);
			}
		)
	}

	checkValue(formControlName: string) {
		let formControl = this.formDiagnosis.get(formControlName);
		if (formControl) {
			return formControl.value !== "";
		}
		else {
			return false;
		}
	}

	clearValue(formControlName: string, event?) {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		let formControl = this.formDiagnosis.get(formControlName);
		if (formControl) {
			formControl.setValue('');
		}

		switch (formControlName) {
			case 'diag_pn1':
				this.pnLenovoSelected = null;
				break;

			default:
				break;
		}
	}

	loadPartsModelIndicator() {
		this.partsModelIndicator = [
			{ id: 'N', name: 'N - Normal'},
			{ id: 'C', name: 'C - Consignment'},
			{ id: 'L', name: 'L - Local Source'},
			{ id: 'CN', name: 'CN - Consignment but Parts not available'},
			{ id: 'PGI', name: 'PGI - Parts consumed'},
			{ id: 'HOLD', name: 'HOLD - Parts shortage'},
			{ id: 'RESV', name: 'RESV - Parts in stock but not used due to other parts shortage'}
		];
	}

	showRejillaPiezas(campo, event) {
		if(event){
			let valorInput = event.target.value;
			if(event.key == 'Enter' || event.key == 'Tab') {
				if(campo == 'pn') {
					if(valorInput != this.pnBuscado) {
						this.pnBuscado = valorInput;
						this.resetTabsPiezasDiagnosis();
						this.loadPiezasDiagnosis();
					}
				}
				else if(campo == 'sn') {
					if(valorInput != this.nsBuscado) {
						this.nsBuscado = valorInput;
						this.resetTabsPiezasDiagnosis();
						this.loadPiezasDiagnosis();
					}
				}
				else if(campo == 'desc') {
					if(valorInput != this.descBuscado) {
						this.descBuscado = valorInput;
						this.resetTabsPiezasDiagnosis();
						this.loadPiezasDiagnosis();
					}
				}
				this.mostrarRejillaPiezas = true;
			}
		}
		else {
			let valorInput = this.pnLenovoSelected;
			if(valorInput != this.pnBuscado) {
				this.pnBuscado = valorInput;
				this.resetTabsPiezasDiagnosis();
				this.loadPiezasDiagnosis();
			}
		}
	}

	resetTabsPiezasDiagnosis() {
		this.tabOneBuscado = false;
		this.dataSourceTabOne.data = [];

		this.tabTwoBuscado = false;
		this.dataSourceTabTwo.data = [];
		
		this.tabThreeBuscado = false;
		this.dataSourceTabThree.data = [];
	}

	loadPiezasDiagnosis() {
		if(this.pnBuscado || this.descBuscado || this.nsBuscado) {
			let params = {
				pn: this.pnBuscado,
				nombre: this.descBuscado,
				diag_ns: this.nsBuscado,
				idColaborador: this.ticket.assign_ent,
				idProyecto: this.ticket.FK_entities,
				tab: this.tabActual,
			}
			
			this.diagnosisService.getPiezasDiagnosis(params).pipe(takeUntil(this._unsubscribeAll)).subscribe(
				async (data: any) => {
					this.piezasDiagnosis = data;
					this.tratarDatosPiezasDiagnosis();
				},
				(err) => {
					this.showSnack(err);
				}
			)
		}
	}

	async tratarDatosPiezasDiagnosis() {
		let piezasAitana = this.piezasDiagnosis['piezasAitana'] ? this.piezasDiagnosis['piezasAitana'] : [];
		let piezasSap = this.piezasDiagnosis['piezasSap'] ? this.piezasDiagnosis['piezasSap'] : [];;

		let piezasFinal = [];
		for (let pieza of piezasAitana) {
			piezasFinal.push({
				ID: pieza.ID,
				almacen: pieza.Almacén,
				valorado: pieza.valorado,
				serial: pieza.MnfSerial,
				otherserial: pieza.DistNumber,
				cantidad: pieza.OnHand,
				estado: pieza.Estado,
				pn: pieza.ItemCode,
				descripcion: pieza.ItemName,
				precio: pieza.StockValue,
			});
		}

		for (let pieza of piezasSap) {
			let idPieza = pieza.ItemCode + '___' + pieza.MnfSerial;
			piezasFinal.push({
				ID: idPieza,
				almacen: pieza.Almacén,
				valorado: null,
				serial: pieza.MnfSerial,
				otherserial: pieza.DistNumber,
				cantidad: pieza.OnHand,
				estado: pieza.Estado,
				pn: pieza.ItemCode,
				descripcion: pieza.ItemName,
				precio: pieza.StockValue,
			});
		}

		switch(this.tabActual) {
			case 'proyecto':
				this.dataSourceTabOne.data = piezasFinal;
				this.tabOneBuscado = true;
				break;
			case 'colaborador':
				this.dataSourceTabTwo.data = piezasFinal;
				this.tabTwoBuscado = true;
				break;
			case 'resto':
				this.dataSourceTabThree.data = piezasFinal;
				this.tabThreeBuscado = true;
				break;
		}

		console.log("tratarDatosPiezasDiagnosis", this.dataSourceTabOne.data, this.dataSourceTabTwo.data, this.dataSourceTabThree.data)

	}

	changeTabPiezasDiagnosis(event) {
		console.log("tabsBuscados", this.tabActual, this.tabOneBuscado, this.tabTwoBuscado, this.tabThreeBuscado);
		if(event == 0) {
			this.tabActual = 'proyecto';
			if(!this.tabOneBuscado) {
				this.loadPiezasDiagnosis();
			}
		}
		else if(event == 1) {
			this.tabActual = 'colaborador';
			if(!this.tabTwoBuscado) {
				this.loadPiezasDiagnosis();
			}
		}
		else {
			this.tabActual = 'resto';
			if(!this.tabThreeBuscado) {
				this.loadPiezasDiagnosis();
			}
		}
	}

	cancelForm() {
		this.diagnosisUpdate = null;
		this.esReparacion = false;
		this.piezaSeleccionada = null;
		this.direccionSelected = null;

		this.panelMainTitle = "Diagnosis";
		this.panelTitleDescription = "Nueva Diagnosis";
		this.panelFormDiagnosis.close();
		this.panelFormDiagnosisExpanded = false;

		this.resetTabsPiezasDiagnosis();
		this.mostrarRejillaPiezas = false;
		this.tabActual = 'proyecto';

		this.pnBuscado = '';
		this.nsBuscado = '';
		this.descBuscado = '';

		//DIAGNOSIS
        this.formDiagnosis.get('ID').setValue(null);
		this.formDiagnosis.get('problema1').setValue(null);
		this.formDiagnosis.get('diag_desc1').setValue('');
		this.formDiagnosis.get('diag_pn1').setValue('');
		this.formDiagnosis.get('prob_sinpieza').setValue(0);
		this.formDiagnosis.get('prob_pedirpieza').setValue(0);
		this.formDiagnosis.get('prob_requiereLab').setValue(0);
		this.formDiagnosis.get('prob_retornable').setValue(0);
		this.formDiagnosis.get('estadosLab').setValue('');
		this.formDiagnosis.get('almacen_estado').setValue('');
		this.formDiagnosis.get('oow').setValue(0);
		this.formDiagnosis.get('retornable').setValue('');
		this.formDiagnosis.get('diag_ns').setValue('');
		this.formDiagnosis.get('numConfirmacion').setValue('');
		this.formDiagnosis.get('cantidadFactura').setValue('');
		this.formDiagnosis.get('observaciones').setValue('');
		this.formDiagnosis.get('parts_model_indicator').setValue(null);

		this.formDiagnosis.get('fk_shipToOrigen').setValue(null);
		this.formDiagnosis.get('fk_shipToDestino').setValue(null);
		this.formDiagnosis.get('numFactura').setValue('');
		this.formDiagnosis.get('numAlbaran').setValue('');

		this.formDiagnosis.get('averia_area').setValue(null);
		this.formDiagnosis.get('averia_subarea1').setValue(null);
		this.formDiagnosis.get('averia_subarea2').setValue(null);
		this.formDiagnosis.get('averia_symptom').setValue(null);
		this.formDiagnosis.get('averia_occurrence').setValue(null);
		this.formDiagnosis.get('averia_repairaction').setValue(null);
		this.formDiagnosis.get('iris_condition_code').setValue(null);
		this.formDiagnosis.get('iris_symptom_code').setValue(null);
		this.formDiagnosis.get('iris_section_code').setValue(null);

		this.formDiagnosis.get('direccion_diagnosis').setValue(null);

		//REPARACION
		this.formReparacion.get('diag_pn1').setValue('');
		this.formReparacion.get('prob_pnentenv').setValue('');
		this.formReparacion.get('prob_nsentenv').setValue('');
		this.formReparacion.get('ep').setValue(null);
		this.formReparacion.get('prob_pnretirado').setValue('');
		this.formReparacion.get('prob_nsretirado').setValue('');
		this.formReparacion.get('FK_reason_doa').setValue('');
		this.formReparacion.get('FK_reason_used').setValue('');
		this.formReparacion.get('observaciones2').setValue('');

		this.formReparacion.get('averia_area').setValue(null);
		this.formReparacion.get('averia_subarea1').setValue(null);
		this.formReparacion.get('averia_subarea2').setValue(null);
		this.formReparacion.get('averia_symptom').setValue(null);
		this.formReparacion.get('averia_occurrence').setValue(null);
		this.formReparacion.get('averia_repairaction').setValue(null);

		this.formReparacion.get('iris_defect_code').setValue(null);
		this.formReparacion.get('iris_repair_code').setValue(null);
		this.formReparacion.get('iris_ntf_code').setValue(null);
		this.formReparacion.get('sintoma').setValue('');

		this.weblordSubareas1 = [];
		this.weblordSubareas2 = [];
		this.weblordSintomas = [];
		this.weblordAreaSelected = null;
		this.weblordSubarea1Selected = null;
		this.weblordSubarea2Selected = null;
	}

	async editDiagnosis(diagnosis) {
		await this.cancelForm();

		this.diagnosisUpdate = diagnosis;
		this.cargandoDiagnosis = true;
		setTimeout(() => { 
			this.cargandoDiagnosis = false;
		}, 2000);

		this.panelTitleDescription = "Editar Diagnosis " + diagnosis.ID;
		this.panelFormDiagnosis.open();
		this.panelFormDiagnosisExpanded = true;

        this.formDiagnosis.get('ID').setValue(diagnosis.ID);
		this.formDiagnosis.get('problema1').setValue(diagnosis.problema1);
		this.formDiagnosis.get('diag_desc1').setValue(diagnosis.diag_desc1);
		this.formDiagnosis.get('diag_pn1').setValue(diagnosis.diag_pn1);
		this.formDiagnosis.get('prob_sinpieza').setValue(diagnosis.prob_sinpieza);
		this.formDiagnosis.get('prob_pedirpieza').setValue(diagnosis.prob_pedirpieza);
		this.checkPedirPieza(diagnosis.prob_pedirpieza);

		this.formDiagnosis.get('prob_requiereLab').setValue(diagnosis.prob_requiereLab);
		this.formDiagnosis.get('prob_retornable').setValue(diagnosis.prob_retornable);
		this.formDiagnosis.get('estadosLab').setValue(diagnosis.estadosLab);
		this.formDiagnosis.get('almacen_estado').setValue(diagnosis.almacen_estado);
		this.formDiagnosis.get('oow').setValue(diagnosis.oow);
		this.formDiagnosis.get('retornable').setValue(diagnosis.retornable);
		this.formDiagnosis.get('numConfirmacion').setValue(diagnosis.numConfirmacion);
		this.formDiagnosis.get('cantidadFactura').setValue(diagnosis.cantidadFactura);
		this.formDiagnosis.get('observaciones').setValue(diagnosis.observaciones);
		this.formDiagnosis.get('parts_model_indicator').setValue(diagnosis.parts_model_indicator);

		this.formDiagnosis.get('fk_shipToOrigen').setValue(diagnosis.fk_shipToOrigen);
		this.formDiagnosis.get('fk_shipToDestino').setValue(diagnosis.fk_shipToDestino);
		this.formDiagnosis.get('numFactura').setValue(diagnosis.numFactura);
		this.formDiagnosis.get('numAlbaran').setValue(diagnosis.numAlbaran);

		this.formDiagnosis.get('averia_area').setValue(diagnosis.averia_area);
		this.formDiagnosis.get('averia_occurrence').setValue(diagnosis.averia_occurrence);
		this.formDiagnosis.get('averia_repairaction').setValue(diagnosis.averia_repairaction);

        //this.formDiagnosis.get('direccion_diagnosis').setValue(diagnosis.direccionEnvio);

        this.setDireccion(diagnosis);
		
		if(diagnosis.iris_diagnosis) {
			this.formDiagnosis.get('iris_condition_code').setValue(diagnosis.iris_diagnosis.condition_code);
			this.formDiagnosis.get('iris_symptom_code').setValue(diagnosis.iris_diagnosis.symptom_code);
			this.formDiagnosis.get('iris_section_code').setValue(diagnosis.iris_diagnosis.section_code);
		}
	}

	loadWeblordAreas() {
		this.diagnosisService.getWeblordAreas().pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.weblordAreas = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadWeblordOccurrences() {
		this.diagnosisService.getWeblordOccurrences().pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.weblordOccurrences = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadWeblordRepairActions() {
		this.diagnosisService.getWeblordRepairActions().pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.weblordRepairActions = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadWeblordSubareas1(area) {
		this.diagnosisService.getWeblordSubareas1(area).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.weblordSubareas1 = data;
				if(this.cargandoDiagnosis) {
					this.formDiagnosis.get('averia_subarea1').setValue(this.diagnosisUpdate.averia_subarea1);
				}
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadWeblordSubareas2(subarea1) {
		this.diagnosisService.getWeblordSubareas2(subarea1).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.weblordSubareas2 = data;
				if(this.cargandoDiagnosis) {
					this.formDiagnosis.get('averia_subarea2').setValue(this.diagnosisUpdate.averia_subarea2);
				}
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadWeblordSintomas(subarea1, subarea2) {
		this.diagnosisService.getWeblordSintomas(subarea1, subarea2).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.weblordSintomas = data;
				if(this.cargandoDiagnosis) {
					this.formDiagnosis.get('averia_symptom').setValue(this.diagnosisUpdate.averia_symptom);
				}
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadDireccionesDiagnosis(idincidencia) {
		this.diagnosisService.getDireccionesDiagnosis(idincidencia).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.direccionesDiagnosis = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	resetWeblordSelectsDiagnosis(subarea1, subarea2) {
		if(subarea1 && !this.cargandoDiagnosis) {
			if(this.weblordSubarea1Selected) {
				this.formDiagnosis.get('averia_subarea1').setValue('');
				this.weblordSubarea1Selected = null;
			}
		}

		if(subarea2 && !this.cargandoDiagnosis) {
			if(this.weblordSubarea2Selected) {
				this.formDiagnosis.get('averia_subarea2').setValue('');
				this.weblordSubarea2Selected = null;
			}
		}

		if(!this.cargandoDiagnosis) {
			this.formDiagnosis.get('averia_symptom').setValue('');
		}
	}

	resetWeblordSelectsRepairDiagnosis(subarea1, subarea2) {
		if(subarea1 && !this.cargandoDiagnosis) {
			if(this.weblordSubarea1Selected) {
				this.formReparacion.get('averia_subarea1').setValue('');
				this.weblordSubarea1Selected = null;
			}
		}

		if(subarea2 && !this.cargandoDiagnosis) {
			if(this.weblordSubarea2Selected) {
				this.formReparacion.get('averia_subarea2').setValue('');
				this.weblordSubarea2Selected = null;
			}
		}

		if(!this.cargandoDiagnosis) {
			this.formReparacion.get('averia_symptom').setValue('');
		}
	}

	checkPedirPieza(checked) {
		if(checked) {
			this.showDirecciones = true;
		}
		else {
			this.showDirecciones = false;
			this.formDiagnosis.get('direccion_diagnosis').setValue('');
		}
	}

	async editRepairDiagnosis(diagnosis) {
		await this.cancelForm();

		this.diagnosisUpdate = diagnosis;
		this.esReparacion = true;
		this.cargandoDiagnosis = true;
		setTimeout(() => { 
			this.cargandoDiagnosis = false;
		}, 2000);

		this.loadDiagnosisRepairCodes();

		this.panelMainTitle = "Reparación";
		this.panelTitleDescription = "Editar Reparación " + diagnosis.ID;
		this.panelFormDiagnosis.open();
		this.panelFormDiagnosisExpanded = true;

		this.formReparacion.get('diag_pn1').setValue(diagnosis.diag_pn1);
		this.formReparacion.get('prob_pnentenv').setValue(diagnosis.prob_pnentenv);
		this.formReparacion.get('prob_nsentenv').setValue(diagnosis.prob_nsentenv);
		this.formReparacion.get('ep').setValue(diagnosis.ep);
		this.formReparacion.get('prob_pnretirado').setValue(diagnosis.prob_pnretirado);
		this.formReparacion.get('prob_nsretirado').setValue(diagnosis.prob_nsretirado);
		this.formReparacion.get('FK_reason_doa').setValue(diagnosis.FK_reason_doa);
		this.formReparacion.get('FK_reason_used').setValue(diagnosis.FK_reason_used);
		this.formReparacion.get('observaciones2').setValue(diagnosis.observaciones2);
		this.formReparacion.get('averia_area').setValue(diagnosis.averia_area);
		this.formReparacion.get('averia_subarea1').setValue(diagnosis.averia_subarea1);
		this.formReparacion.get('averia_subarea2').setValue(diagnosis.averia_subarea2);
		this.formReparacion.get('averia_symptom').setValue(diagnosis.averia_symptom);
		this.formReparacion.get('averia_occurrence').setValue(diagnosis.averia_occurrence);
		this.formReparacion.get('averia_repairaction').setValue(diagnosis.averia_repairaction);

		if(diagnosis.iris_diagnosis) {
			this.formReparacion.get('iris_defect_code').setValue(diagnosis.iris_diagnosis.defect_code);
			this.formReparacion.get('iris_repair_code').setValue(diagnosis.iris_diagnosis.repair_code);
			this.formReparacion.get('iris_ntf_code').setValue(diagnosis.iris_diagnosis.ntf_code);
			this.formReparacion.get('sintoma').setValue(diagnosis.iris_diagnosis.sintoma);
		}
	}

	loadDiagnosisCodigosIris() {
		this.loadDiagnosisConditionCodes();
		this.loadDiagnosisSymptomCodes();
		this.loadDiagnosisSectionCodes();
		this.loadDiagnosisDefectCodes();
		this.loadDiagnosisRepairCodes();
		this.loadDiagnosisNtfCodes();
	}

	loadDiagnosisConditionCodes() {
		this.diagnosisService.getIrisDiagnosisConditionCodes(this.ticket.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.conditionCodes = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadDiagnosisSymptomCodes() {
		this.diagnosisService.getIrisDiagnosisSymptomCodes(this.ticket.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.symptomCodes = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadDiagnosisSectionCodes() {
		this.diagnosisService.getIrisDiagnosisSectionCodes(this.ticket.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.sectionCodes = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadDiagnosisDefectCodes() {
		this.diagnosisService.getIrisDiagnosisDefectCodes(this.ticket.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.defectCodes = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadDiagnosisRepairCodes() {
		let idDiagnosis = this.diagnosisUpdate ? this.diagnosisUpdate.ID : 0;
		this.diagnosisService.getIrisDiagnosisRepairCodes(this.ticket.ID, idDiagnosis).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.repairCodes = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	loadDiagnosisNtfCodes() {
		this.diagnosisService.getIrisDiagnosisNtfCodes(this.ticket.ID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				this.ntfCodes = data;
			},
			(err) => {
				this.showSnack(err);
			}
		)
	}

	seleccionarElemento(event, pieza) {
		if(event.checked) {
			this.piezaSeleccionada = pieza;
			this.formDiagnosis.get('prob_pedirpieza').setValue(1);
		}
		else {
			this.piezaSeleccionada = null;
			this.formDiagnosis.get('prob_pedirpieza').setValue(0);
		}
		this.checkPedirPieza(event.checked);
	}

	async guardarDiagnosisReparacion() {
		this.guardandoDiagnosis = true;
		
		let params = await this.getParamsSaveDiagnosis();
		this.saveDiagnosis(params);
	}

	getParamsSaveDiagnosis() {
		let params;
		if(this.esReparacion){
			params = this.formReparacion.value;
			params.ID = this.diagnosisUpdate.ID;
		}
		else {
			params = this.formDiagnosis.value;
			params.piezaAlmacen = this.piezaSeleccionada;
			params.direccionDiagnosis = this.direccionSelected;
		}

		params.idincidencia = this.ticket.ID;

		return params;
	}

	saveDiagnosis(params) {
		this.diagnosisService.guardarDiagnosis(params).pipe(takeUntil(this._unsubscribeAll)).subscribe(
			async (data: any) => {
				console.log("diagnosis guardada", this.data);
				this.guardandoDiagnosis = false;
				this.actionsAfterSave();
			},
			(err) => {
				this.showSnack(err);
				this.guardandoDiagnosis = false;
			}
		)		
	}

	actionsAfterSave() {
		this.cancelForm();
		if(this.esReparacion) {
			this.loadReparaciones();
		}
		else {
			this.loadDiagnosis();
		}
	}
}