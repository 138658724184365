import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';

@Injectable({
  	providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

	constructor(public router: Router, public auth: AuthService) {  }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		// console.log("route", route);
		
		if (!this.auth.isUserLoggedIn()) {
			this.router.navigate(['login']);
			return false;
		}
		else {
            if(this.auth.needConfirmSecurityCode()){
                if(route.routeConfig.path !== 'security-login'){
                    this.router.navigate(['login']);
                    return false;
                }
            }else{
                if (route.routeConfig.path !== 'error/not-authorized') {
                    const entornosRoute = route.data && route.data.environment ? route.data.environment : [];
        
                    if (environment.entorno === 'ewaste' && !entornosRoute.includes(environment.entorno)) {
                        this.router.navigate(['error/not-authorized']);
                        return false;
                    }
                }
            }
		}
		return true;
	}
}
